import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';



export const VIDEOContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1265px;
  margin: 0 auto;
  padding: 0 20px;
`;

export const VIDEOContaine1r = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  min-height: 30px;
`;

export const VIDEOContaine1aar = styled.a`
text-decoration: none;
transition: color .2s;
display: block;
background-position: 50%;
background-repeat: no-repeat;
background-size: contain;
height: 60px;
color: transparent;
width: 150px;
`;

export const AdsLiveImageLogo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;



export const VIDEOConaataine1aar = styled.div`
box-sizing: border-box;
margin: 0;
padding: 0;
border: 0;
font: inherit;
display: flex;
height: 100%;
border-radius: 18px;
-webkit-box-orient: horizontal;
-webkit-box-direction: normal;
flex-direction: row;
background-color: rgba(0,0,0,.5);
box-shadow: 0 2px 15px rgba(0,0,0,.5);
`;

export const ViewCamWrapper = styled.div`
display: flex;
overflow: hidden;
-webkit-box-flex: 1;
flex-grow: 1;
background-color: #000;
-webkit-box-align: center;
align-items: center;
-webkit-box-pack: center;
justify-content: center;
color: #af262f;
height: 400px;
border-radius: 18px;
width: calc(100% - 390px);
`;

export const Containe2 = styled.a`
text-decoration: none;
transition: color .2s;
color: inherit;
position: relative;
display: block;
height: 100%;
width: 100%;
overflow: hidden;
background-position: 50%;
background-size: cover;
transform: translateZ(0);
font-family: Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;
`;


export const CamViewImage1 = styled.img`
backdrop-filter: blur(5px);
height: 100%;
width: 100%;
object-fit: cover;
`;


export const Container2 = styled.div`
background-color: rgba(0,0,0,.32);
color: #fff;
display: inline-block;
position: relative;
z-index: 2;
font-weight: 600;
padding: 5px 12px;
font-size: 15px;
border-radius: 3px;

color: inherit;
box-sizing: border-box;
margin: 0;
padding: 0;
border: 0;
font: inherit;
top: 6px;
position: absolute;
left: 0;
right: 0;
`;

export const OnlineView1 = styled.div`
-webkit-box-direction: normal;
color: #fff;
box-sizing: border-box;
margin: 0;
padding: 0;
border: 0;
font: inherit;
display: block;
border-radius: 50%;
margin-right: 5px;
width: 13px;
height: 13px;
background-color: rgb(130, 234, 40);
`;

export const LiveText1 = styled.p`
-webkit-box-direction: normal;
color: #fff;
box-sizing: border-box;
margin: 0;
padding: 0;
border: 0;
font: inherit;
font-size: smaller;
`;


export const UsernameView1 = styled.div`
bottom: 0;
display: flex;
-webkit-box-align: center;
align-items: center;
font-size: 12px;
position: absolute;
width: 100%;
z-index: 1;
padding: 0 25px 10px;


position: absolute;
bottom: 0;
left: 0;
`;

export const UsernameText1 = styled.p`
max-width: calc(100% - 17px);
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
margin-right: 5px;
`;

export const LiveInfoView = styled.div`
color: #FFFFFF;
box-sizing: border-box;
margin: 0;
border: 0;
font: inherit;
-webkit-box-flex: 0;
flex-grow: 0;
display: flex;
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
flex-direction: column;
-webkit-box-pack: center;
justify-content: center;
padding: 55px 40px;
width: 390px;
`;

export const LiveInfoTitleView = styled.h3`
color: #FFFFFF;
box-sizing: border-box;
margin: 0;
padding: 0;
border: 0;
font: inherit;
font-size: 50px;
font-weight: 700;
letter-spacing: -.76px;
line-height: .9;
-webkit-box-flex: 1;
flex-grow: 1;
display: flex;
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
flex-direction: column;
-webkit-box-pack: center;
justify-content: center;
`;

export const LiveInfoTitle = styled.h3`
letter-spacing: -.76px;
-webkit-box-direction: normal;
color: #ff112f;
box-sizing: border-box;
margin: 0;
padding: 0;
border: 0;
font: inherit;
`;

export const LiveInfoTitleColor = styled.span`
  color: ${Colors.white};
`;

export const LiveInfoSubtitle = styled.p`
color: #FFFFFF;
letter-spacing: -.76px;
-webkit-box-direction: normal;
box-sizing: border-box;
margin: 0;
padding: 0;
border: 0;
font: inherit;


margin-top: 10px;
font-size: 22px;
`;


export const OpenRoomButton = styled.a`
-webkit-box-direction: normal;
box-sizing: border-box;
margin: 0;
border: 0;
font: inherit;
text-decoration: none;
transition: color .2s;
-webkit-box-align: center;
align-items: center;
-webkit-box-pack: center;
justify-content: center;
border-radius: 8px;
background-image: linear-gradient(180deg,hsl(351, 75%, 52%),hsl(351, 95%, 32%));
color: #FFFFFF;
font-weight: 700;
height: 64px;
font-size: 21px;
padding: 0;
display: flex;


margin-top: 10px;
`;


export const OpenRoomText = styled.p`
-webkit-box-direction: normal;
color: #FFFFFF;
box-sizing: border-box;
margin: 0;
padding: 0;
border: 0;
font: inherit;
`;



export const ContainerInfoRoom = styled.div`
color: #FFFFFF;
box-sizing: border-box;
border: 0;
font: inherit;
max-width: 1265px;
margin: 0 auto;
padding: 0 20px;
`;


export const ContainerInfoItems = styled.div`
color: #FFFFFF;
box-sizing: border-box;
border: 0;
font: inherit;
list-style: none;
padding: 0;
display: flex;
justify-content: space-around;
-webkit-box-align: start;
align-items: flex-start;
flex-wrap: wrap;
margin: 50px 0 10px;
overflow: hidden;
list-style-type: none;
margin-top: 65px;
`;


export const ContainerInfoItemView = styled.div`
color: #FFFFFF;
list-style: none;
list-style-type: none;
box-sizing: border-box;
margin: 0;
padding: 0;
border: 0;
font: inherit;
max-width: 530px;
margin-bottom: 30px;
-webkit-box-align: start;
align-items: flex-start;
-webkit-box-pack: center;
justify-content: center;
display: flex;
width: 33.3333333333%;
`;

export const ContainerInfoItemIcon = styled.div`
color: #FFFFFF;
list-style: none;
list-style-type: none;
box-sizing: border-box;
margin: 0;
padding: 0;
border: 0;
font: inherit;
display: flex;
margin-right: 25px;
min-width: 70px;
width: 70px;
`;

export const ContainerInfoItemInfo = styled.div`
color: #FFFFFF;
list-style: none;
list-style-type: none;
box-sizing: border-box;
margin: 0;
padding: 0;
border: 0;
font: inherit;
-webkit-box-pack: center;
justify-content: center;
display: flex;
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
flex-direction: column;
padding-right: 30px;
max-width: 230px;
`;

export const ContainerInfoItemTitle = styled.h3`
list-style: none;
list-style-type: none;
-webkit-box-direction: normal;
box-sizing: border-box;
margin: 0;
padding: 0;
border: 0;
font: inherit;
margin-bottom: 10px;
color: hsl(351, 95%, 57%);
font-size: 18px;
font-weight: 700;
line-height: 24px;
`;

export const ContainerInfoItemText = styled.p`
list-style: none;
list-style-type: none;
-webkit-box-direction: normal;
box-sizing: border-box;
margin: 0;
padding: 0;
border: 0;
font: inherit;
color: #FFFFFF;
font-size: 14px;
font-weight: 500;
line-height: 20px;
opacity: .8;
`;



export const MoreModelOnlineView = styled.div`
color: #FFFFFF;
box-sizing: border-box;
padding: 0;
border: 0;
font: inherit;
margin: 25px 0;
margin-bottom: 45px;
`;

export const MoreModelOnlineTitle = styled.h3`
box-sizing: border-box;
margin: 0;
padding: 0;
border: 0;
font: inherit;
opacity: .5;
color: #FFFFFF;
font-weight: 500;
letter-spacing: -.13px;
text-transform: lowercase;
margin-bottom: 30px;
font-size: 20px;
`;

export const MoreModelOnlineGrid = styled.div`
color: #FFFFFF;
box-sizing: border-box;
margin: 0;
padding: 0;
border: 0;
font: inherit;
display: flex;
flex-wrap: wrap;
-webkit-box-pack: justify;
justify-content: space-between;
`;



export const AdsWebFooter = styled.div`
color: #FFFFFF;
box-sizing: border-box;
border: 0;
font: inherit;
max-width: 1265px;
margin: 0 auto;
padding: 0 20px;
`;

export const AdsWebFooterWrapper = styled.div`
box-sizing: border-box;
margin: 0;
padding: 0;
border: 0;
font: inherit;
text-align: center;
font-size: 13px;
font-weight: 500;
line-height: 21px;
margin-bottom: 50px;
color: hsla(0, 0%, 100%, .3);
`;

export const AdsWebFooter1 = styled.div`
text-align: center;
box-sizing: border-box;
margin: 0;
padding: 0;
border: 0;
font: inherit;
color: #FFFFFF;
display: flex;
flex-wrap: wrap;
justify-content: space-evenly;
margin-bottom: 35px;
-webkit-box-align: center;
align-items: center;
`;

export const AdsWeb18Item = styled.div`
text-align: center;
color: #FFFFFF;
box-sizing: border-box;
margin: 0;
padding: 0;
border: 0;
font: inherit;
width: auto;
`;


export const AdsWebFooterDescription = styled.div`
`;

export const AdsWebFooterDescriptionText = styled.div`
text-align: center;
`;
