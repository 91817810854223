import React from 'react';

import ButtonAction from '../Buttons/ButtonAction';

import {
  EmptyContentView,
  EmptyImage,
  EmptyTitle,
  EmptyDescription,
  EmptyButtonView,
} from './styled';



export interface IProps {
  center?: boolean;
  image?: any;

  title?: string;
  titleSize?: number;

  description?: string;
  descriptionSize?: number;


  buttonText?: any;
  buttonColor?: any;
  onPress?: any;
}



const EmptyContent: React.FC<IProps> = (props: IProps) => {
  return (

    <EmptyContentView
      centerItems={props.center}>

      {props.image && (
        <EmptyImage
          alt={'Empty Image'}
          src={props.image}
        />
      )}


      {props.title && (
        <EmptyTitle
          fontSize={props.titleSize}>
          {props.title}
        </EmptyTitle>
      )}


      {props.description && (
        <EmptyDescription
          fontSize={props.descriptionSize}>
          {props.description}
        </EmptyDescription>
      )}


      {props.buttonText && (
        <EmptyButtonView>
          <ButtonAction
            backgroundColor={props.buttonColor}
            title={props.buttonText}
            onPress={props.onPress}
          />
        </EmptyButtonView>
      )}

    </EmptyContentView>

  );
};



EmptyContent.defaultProps = {
  center: true,
};



export default EmptyContent;
