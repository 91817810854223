import styled from 'styled-components';

import Color from 'color';

import {
  Colors,
} from '../../../shared/constants';



interface IProps {
  active?: boolean;
  backgroundColor?: string;
}



export const MessageUserContainer = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  height: 85px;
  padding: 12px;
  background-color: ${(props: IProps) => props.active ? '#2a3942' : Colors.cardBackground};
  border-left: 1px solid rgba(26,26,26,.3);
  transition: .2s linear;

  :hover {
    background-color: #202c33;
  }
`;


export const ChatListUserPhoto = styled.div`
  overflow: hidden;
  width: 55px;
  height: 55px;
  margin-right: 12px;
  border-radius: 100%;
`;

export const ChatUserImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;


export const ChatUserInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ChatUserUsernameText = styled.p`
  color: ${Colors.white};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
`;

export const ChatUserMessageText = styled.p`
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;


export const ChatUserCountContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const ChatUserTodayText = styled.p`
  color: rgba(255, 255, 255, 0.75);
  padding-left: 12px;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
`;

export const ChatUserCountBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-top: 3px;
  background-color: ${(props: IProps) => props.backgroundColor
    ? Color(props.backgroundColor).darken(0.3).toString()
    : Colors.primary};
  border-radius: 100%;
`;

export const ChatUserCountText = styled.p`
  color: ${Colors.white};
`;


export const ChatOptionsButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-left: 12px;
  background-color: #434343;
  transition: .2s linear;
  box-shadow: 0 4px 6px rgba(26, 26, 26, 0.75);
  border-radius: 100%;
`;

export const ChatSwapControlsContent = styled.div`
  position: absolute;
  top: 50px;
  right: -120px;
  width: 150px;
  padding: 5px 0;
  background: #333333;
  border-radius: 10px;
  box-shadow: 0 10px 20px #0009;
`;

export const ChatSwapButton = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
  transition: .3s;
`;

export const ChatSwapIcon = styled.div`
  width: 30px;
  height: 30px;
  background-color: #FF0000;
  margin-right: 10px;
`;

export const ChatSwapText = styled.p`
  color: ${Colors.white};
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5em;
`;
