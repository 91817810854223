import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';



export const CardLanguageContainer = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
`;


export const CardLanguageImage = styled.img`
  width: 45px;
  height: 45px;
  margin-right: 12px;
  object-fit: contain;
  border-radius: 3px;
`;



export const CardLanguageInfoView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;

export const CardLanguageTitle = styled.h3`
  color: ${Colors.white};
  font-size: 16px;
  font-weight: bold;
  line-height: 21px;
`;

export const CardLanguageSubTitle = styled.p`
  color: ${Colors.gray};
  font-size: 13px;
  line-height: 18px;
`;
