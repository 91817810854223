import React from 'react';

import {
  IconCheck,
  IconClose,
} from '../../../../components/Icons';

import {
  Colors,
} from '../../../../shared/constants';

import {
  VideoContentRightHome,
  WarningApproveText,
  WrapperItemContent,
  ApproveSplitView,
  ApproveSplitIconView,
  ApproveSplitText,
  ApproveItemInfoView,
  ApproveItemSplitText,
} from './styled';



const InfoPrincipal: React.FC = () => {
  return (

    <VideoContentRightHome>

      <WarningApproveText>
        Siga estas regras para acelerar o processo de aprovação.
      </WarningApproveText>



      <WrapperItemContent>

        <ApproveSplitView>
          <ApproveSplitIconView>
            <IconCheck
              color={Colors.black}
              size={20}
            />
          </ApproveSplitIconView>


          <ApproveSplitText>
            Aceito
          </ApproveSplitText>
        </ApproveSplitView>



        <ApproveItemInfoView>
          <ApproveItemSplitText>
            Nítida e de alta qualidade.
          </ApproveItemSplitText>

          <ApproveItemSplitText>
            Fotos que mostrem o seu corpo por completo, especialmente sem roupas, com ou sem roupas íntimas.
          </ApproveItemSplitText>
        </ApproveItemInfoView>

      </WrapperItemContent>



      <WrapperItemContent>

        <ApproveSplitView>
          <ApproveSplitIconView>
            <IconClose
              color={Colors.black}
              size={20}
            />
          </ApproveSplitIconView>


          <ApproveSplitText>
            Não aceito
          </ApproveSplitText>
        </ApproveSplitView>



        <ApproveItemInfoView>
          <ApproveItemSplitText>
            Folhetos, banners, fotos com texto ou bandeiras de cartões.
          </ApproveItemSplitText>

          <ApproveItemSplitText>
            Fotos com molduras, faixas ou com marcas d`água.
          </ApproveItemSplitText>
        </ApproveItemInfoView>

      </WrapperItemContent>

    </VideoContentRightHome>

  );
};



export default InfoPrincipal;
