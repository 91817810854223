import React from 'react';

import {
  TypeRoomsSubtitleView,
} from './styled';



interface IProps {
  show?: boolean;
}



const ChatTypesSubtitles: React.FC<IProps> = (props) => {
  return (

    <TypeRoomsSubtitleView
      show={props.show}>

      <b className="color-nude">Chat Básico:</b>

      <p>
        Você e outros usuários podem conversar e assistir ao show.
      </p>


      <b className="color-nude">Chat Festa:</b>

      <p>
      </p>


      <b className="color-nude">Chat em Grupo:</b>

      <p>
      </p>


      <b className="color-private">Chat Privado:</b>

      <p>
        Só você conversa e pode ligar a webcam se quiser, mas outros usuários também podem espiar o show. Usuários que espiam o show não podem ler a conversa ou ver sua webcam.
      </p>


      <b className="color-true_private">Chat Exclusivo:</b>

      <p>
        O show é exclusivo para você. Ninguém pode espiar o que acontece aqui e você pode ligar sua webcam se quiser.
      </p>

    </TypeRoomsSubtitleView>

  );
};



export default ChatTypesSubtitles;
