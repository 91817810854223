import styled from 'styled-components';
import { Images } from '../../../../../shared/constants';



export interface IProps{
  position?:string;
}



// margin-bottom: 15px;
export const Fotysectiontitle = styled.h2`


display: block;
font-family: 'Open Sans', sans-serif !important;
position: relative;
font-size: 48px !important;
font-weight: 800 !important;
letter-spacing: 1px;
line-height: 1;
text-transform: uppercase;
background: -webkit-linear-gradient(40deg, #3A426E, #4A8AA8 45%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
color: #CCC !important;

${(props:IProps)=>props.position === 'right'&&`
padding-left: 0;
padding-right: 34px;

:after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 136px;
  height: 28px;
  background-image: url(${Images.fotyunderlineleft});
  z-index: -1;

  right: -10px;
  transform: scaleX(-1);
}
`}

${(props:IProps)=>props.position === 'left'&&`
padding-left: 34px;
padding-right: 0;

:before {
  content: '';
  position: absolute;
  bottom: 0;
  width: 136px;
  height: 28px;
  background-image: url(${Images.fotyunderlineleft});
  z-index: -1;

  left: -10px;
}

`}






`;
