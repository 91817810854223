import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';



export const CardFavoriteContainer = styled.a`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 24px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;

  @media ${PlatformDevices.maxTabletL} {
    flex-direction: column;
  }
`;

export const FavoriteLeftView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;



export const FavoriteLeftHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const UserFavoriteInfoView = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 14px;
`;

export const UserFavoriteName = styled.p`
  color: ${Colors.white};
  font-size: 20px;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  word-break: break-word;
`;

export const FavoriteLocationView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 6px;
  min-height: 20px;
`;

export const FavoriteMapPinIcon = styled.div`
  display: flex;
  flex-direction: column;
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

export const ModelesPlaceCountry = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const FavoriteUserAliasNameText = styled.p`
  margin-bottom: 2px;
  color: #afaba9;
  font-size: 13px;
  line-height: 13px;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  word-break: break-word;
`;

export const FavoriteUserAliasText = styled.p`
  color: ${Colors.white};
  font-size: 14px;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  word-break: break-word;
`;

export const UserFavoriteVerifiedView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const UserFavoriteIcon = styled.div`
  display: flex;
  margin-left: 8px;
`;



export const FavoriteAvailableView = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
`;

export const FavoriteItemAvailableView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 30px;
`;

export const FavoriteItemAvailableIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 5px;
`;

export const FavoriteItemAvailableInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const FavoriteTypeAvailableText = styled.p`
  color: ${Colors.white};
  font-size: 15px;
  font-weight: bold;
  line-height: 16px;
`;

export const FavoriteUserLocationText = styled.p`
  margin-top: 3px;
  color: #afaba9;
  font-size: 11px;
  line-height: 13px;
  white-space: nowrap;
`;



export const FavoritesButtonText = styled.p`
  margin-top: 5px;
  color: ${Colors.primary};
  font-size: 10px;
  font-style: italic;

  @media ${PlatformDevices.maxTabletL} {
    text-align: center;
  }
`;



export const RightItemView = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 15px;
  gap: 5px;

  @media ${PlatformDevices.maxTabletL} {
    margin-top: 10px;
    margin-left: 0;
    justify-content: center;
  }
`;

export const RightItemImageView = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  max-height: 140px;
  aspect-ratio: 1;
  border-radius: ${Sizes.cardRadius}px;
`;

export const RightItemImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
`;
