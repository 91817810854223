import React from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  RootState,
} from '../../../redux';

import SectionServices from './SectionServices';

import {
  RentServicesContent,
  RentServicesHeader,
  RentServicesTitle,
  RentServicesDivider,
  RentServicesItemsContent,
} from './styled';



export interface IProps {
  disabled?: boolean;
  title?: string;
  color?: string;
  edit?: boolean;
  data?: Array<any>; // Array<SectionServicesProps>
  onCheckboxChange?: (idService: number, doItem?: boolean, speciallity?: boolean) => void;
}



const RentServices: React.FC<IProps> = (props: IProps) => {
  const arrayReceived = props.data || [];


  const genderColorChoose = useSelector((state: RootState) => state.choose.genderColorApp);
  const colorService = props.color || genderColorChoose;



  function renderCardService(item: any, index: number) {
    return (

      <SectionServices
        key={index}
        edit={props.edit}
        disabled={props.disabled}
        idService={item?.id}
        colorService={colorService}
        title={item?.title}
        text={item?.description}
        doItem={item?.doItem}
        speciallity={item?.speciallity}
        warning={item?.warning}
        onCheckboxChange={(idService: number, doItem?: boolean, speciallity?: boolean) => {
          props.onCheckboxChange(idService, doItem, speciallity);
        }}
      />

    );
  }



  return (

    <RentServicesContent>

      {props.title && (
        <RentServicesHeader>

          <RentServicesTitle>
            {props.title}
          </RentServicesTitle>


          <RentServicesDivider
            backgroundColor={colorService}
          />

        </RentServicesHeader>
      )}



      {arrayReceived?.length > 0 && (
        <RentServicesItemsContent>
          {arrayReceived.map((item, index: number) => (
            renderCardService(item, index)
          ))}
        </RentServicesItemsContent>
      )}

    </RentServicesContent>

  );
};



export default RentServices;
