import SecretioWS from '../index';



export const GetHelpFaq = async (useralias: string) => {
  const url = `/help/${useralias}`;

  const response = await SecretioWS.get(url);
  return response.data;
};
