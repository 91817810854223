import styled from 'styled-components';

import color from 'color';

import {
  Colors, Sizes,
} from '../../../../shared/constants';

import {
  PlatformDevices,
} from '../../../../shared/utils/platforms.utils';



export interface IProps {
  backgroundColor?: string;
  color?: string;
}



export const UpgradeDetaislContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
`;

export const VipHeroContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 480px;
  background-color: ${(props: IProps) => props.backgroundColor
    ? color(props.backgroundColor).darken(0.45).toString()
    : Colors.primary};
`;

export const VipHeroBackground = styled.img`
  position: absolute;
  top: 0;
  height: 280px;
  width: 100%;
  object-fit: cover;
  border-bottom: 5px solid ${Colors.black};
`;


export const MainHeader1600 = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  max-width: ${Sizes.contentMaxWidth}px;
  width: 100%;

  @media ${PlatformDevices.minLaptop} {
    overflow: auto;
    justify-content: space-between;
    padding-left: 140px;
    padding-right: 140px;
  }
`;

export const VipContentCoinWebView = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;

  @media ${PlatformDevices.maxLaptop} {
    display: none;
  }
`;

export const Col6Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  max-width: 400px;
  height: 100%;
  padding-top: 20px;
  z-index: 1;
`;

export const ModelPromoImg = styled.img`
  height: 100%;
  object-fit: contain;
`;


export const VipBenefitsView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 18px;
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 18px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
  box-shadow: 0 2px 2px ${Colors.black};
  z-index: 1;
`;


export const VipBenefitsImage = styled.img`
  width: 100%;
  aspect-ratio: 16 / 5;
  margin-bottom: 18px;
  object-fit: contain;
`;

export const BecomeTitle = styled.h1`
  margin-bottom: 7px;
  color: ${Colors.white};
  font-size: 30px;
  font-weight: 300;
  letter-spacing: -1px;
  text-shadow: 0 1px 1px ${Colors.black};
  text-transform: uppercase;
`;

export const LevelTitle = styled.span`
  color: ${Colors.primary};
  font-weight: 600;
`;

export const HeaderSubtitle = styled.h2`
  margin-bottom: 20px;
  color: ${Colors.white};
  font-size: 20px;
  font-weight: 300;
  line-height: 28px;
  text-shadow: 0 1px 1px ${Colors.black};
  text-align: center;
`;


export const VipPriceText = styled.h2`
  color: ${Colors.primary};
  font-size: 22px;
  line-height: 30px;
  font-weight: 500;
  text-align: center;
`;


export const VipSeeMoreText = styled.p`
  margin-top: 3px;
  color: ${Colors.gray};
  font-size: 15px;
  cursor: pointer;
`;

export const ButtonVipActionContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
`;



export const VipBonusViewFeatures = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
`;

export const VipContentCoinMobileView = styled.div`
  display: none;
  flex-direction: column;
  width: 100%;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;

  @media ${PlatformDevices.maxLaptop} {
    display: flex;
  }
`;


export const MainContainer1600 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: ${Sizes.contentMaxWidth}px;
  justify-content: space-between;
  width: 100%;
  padding-left: 1%;
  padding-right: 1%;

  @media ${PlatformDevices.maxLaptop} {
    flex-direction: column;
  }
`;

export const VipFeatContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;


export const VipBonusFeaturesInfoView = styled.h2`
  display: inline;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 20px;
  font-weight: 300;
  font-size: 26px;
  line-height: 32px;
  text-align: center;

  @media ${PlatformDevices.maxTablet} {
    margin-left: 15px;
    margin-right: 15px;
  }
`;

export const VipBonusFeaturesText = styled.span`
  color: ${Colors.white};
`;

export const VipBonusFeaturesColorText = styled.span`
  color: ${(props: IProps) => props.color || Colors.primary};
`;



export const BonusVipGrid = styled.div`
  display: grid;
  grid-gap: 5px;

  @media ${PlatformDevices.maxTablet} {
    grid-template-columns: 1fr;
  }

  @media ${PlatformDevices.minTablet} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${PlatformDevices.minTabletL} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media ${PlatformDevices.minLaptop} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;


export const CardItemGridVipContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
`;

export const BonusFeatureRowIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  margin-right: 16px;
`;

export const BonusFeatureRowImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const BonusFeatureTextView = styled.div`
  display: flex;
  flex-direction: column;
`;


export const BonusFeatureTitle = styled.h3`
  margin-bottom: 8px;
  color: ${(props: IProps) => props.color || Colors.primary};
  font-size: 17px;
  font-weight: bold;
`;

export const BonusFeatureSubTitle = styled.p`
  color: ${Colors.textDescription};
  font-size: 14px;
  font-style: italic;
`;

export const ButtonViewActionContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;
