import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../shared/constants';



export const LoadingProgressStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 150px;
  height: 150px;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.black};
  border-radius: ${Sizes.cardRadius}px;
  border-color: ${Colors.white};
  border-style: solid;
  border-width: 2px;
  transform: scale(0.8);
`;
