export const mannequinArray = [
  { id: 1, label: '30', value: 'MANNEQUIN_30' },
  { id: 2, label: '31', value: 'MANNEQUIN_31' },
  { id: 3, label: '32', value: 'MANNEQUIN_32' },
  { id: 4, label: '33', value: 'MANNEQUIN_33' },
  { id: 5, label: '34', value: 'MANNEQUIN_34' },
  { id: 6, label: '35', value: 'MANNEQUIN_35' },
  { id: 7, label: '36', value: 'MANNEQUIN_36' },
  { id: 8, label: '37', value: 'MANNEQUIN_37' },
  { id: 9, label: '38', value: 'MANNEQUIN_38' },
  { id: 10, label: '39', value: 'MANNEQUIN_39' },
  { id: 11, label: '40', value: 'MANNEQUIN_40' },
  { id: 12, label: '41', value: 'MANNEQUIN_41' },
  { id: 13, label: '42', value: 'MANNEQUIN_42' },
  { id: 14, label: '43', value: 'MANNEQUIN_43' },
  { id: 15, label: '44', value: 'MANNEQUIN_44' },
  { id: 16, label: '45', value: 'MANNEQUIN_45' },
  { id: 17, label: '46', value: 'MANNEQUIN_46' },
  { id: 18, label: '47', value: 'MANNEQUIN_47' },
  { id: 19, label: '48', value: 'MANNEQUIN_48' },
  { id: 20, label: '49', value: 'MANNEQUIN_49' },
  { id: 21, label: '50', value: 'MANNEQUIN_50' },
  { id: 22, label: '51', value: 'MANNEQUIN_51' },
  { id: 23, label: '52', value: 'MANNEQUIN_52' },
  { id: 24, label: '53', value: 'MANNEQUIN_53' },
  { id: 25, label: '54', value: 'MANNEQUIN_54' },
  { id: 26, label: '55', value: 'MANNEQUIN_55' },
  { id: 27, label: '56', value: 'MANNEQUIN_56' },
  { id: 28, label: '57', value: 'MANNEQUIN_57' },
  { id: 29, label: '58', value: 'MANNEQUIN_58' },
  { id: 30, label: '59', value: 'MANNEQUIN_59' },
  { id: 31, label: '60', value: 'MANNEQUIN_60' },
  { id: 32, label: '61', value: 'MANNEQUIN_61' },
  { id: 33, label: '62', value: 'MANNEQUIN_62' },
  { id: 34, label: '63', value: 'MANNEQUIN_63' },
  { id: 35, label: '64', value: 'MANNEQUIN_64' },
  { id: 36, label: '65', value: 'MANNEQUIN_65' },
  { id: 37, label: '66', value: 'MANNEQUIN_66' },
  { id: 38, label: '67', value: 'MANNEQUIN_67' },
  { id: 39, label: '68', value: 'MANNEQUIN_68' },
  { id: 40, label: '69', value: 'MANNEQUIN_69' },
  { id: 41, label: '70', value: 'MANNEQUIN_70' },
];
