import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';



export const DashboardAddCreditCard = styled.a`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 2px dashed ${Colors.white};
  border-radius: ${Sizes.cardRadius}px;
  transition: .3s ease-out;

  @media ${PlatformDevices.maxTabletL} {
    width: 100%;
    max-width: 100%;
  }

  @media ${PlatformDevices.minTabletL} {
    width: 260px;
    max-width: 260px;
  }

  @media ${PlatformDevices.minLaptop} {
    width: 340px;
    max-width: 340px;
  }

  @media ${PlatformDevices.minLaptopL} {
    width: 360px;
    max-width: 360px;
  }
`;

export const DashboardAddCreditCardIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
`;

export const DashboardAddCreditCardText = styled.p`
  margin-top: 15px;
  color: ${Colors.white};
  font-size: 17px;
  font-weight: 500;
  text-align: center;
`;
