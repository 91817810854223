import styled from 'styled-components';

import {
  ContentPadder,
} from '../../../components/Contents/ContentPadder';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';



export const RentHowWorkContainer = styled(ContentPadder)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const RentAdsArticleContainer = styled(ContentPadder)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1170px;
  padding-top: 15px;
  padding-bottom: 20px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;


export const RentAdsArticleView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 35px;
`;

export const RentAdsArticleText = styled.p`
  margin-top: 8px;
  margin-bottom: 8px;
  padding-left: 6px;
  padding-right: 6px;
  color: ${Colors.white};
  font-size: 15px;
  line-height: 22px;
`;

export const RentBoldText = styled.span`
  font-weight: bold;
`;

export const RentAdsArticleBoldText = styled.span`
  font-weight: bold;
`;



export const RentAdsContView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const RentAdsArticleSubText = styled.h2`
  margin-top: 12px;
  margin-bottom: 10px;
  padding-left: 6px;
  padding-right: 6px;
  color: ${Colors.primaryDark};
  font-size: 20px;
`;



export const SuitItemImage = styled.img`
  width: 25px;
  height: 25px;
  margin-left: 3px;
  margin-right: 3px;
  margin-bottom: -5px;
`;

export const RentAdsArticleSuit = styled.p`
  padding-left: 6px;
  padding-right: 6px;
  color: ${Colors.white};
  font-size: 17px;
`;

export const RentAdsArticlePrice = styled.p`
  margin-top: 5px;
  margin-bottom: 8px;
  padding-left: 6px;
  padding-right: 6px;
  color: ${Colors.inputLabel};
  font-size: 12px;
`;


export const RentAdsArticleButtonView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  margin-top: 20px;
`;

export const BeModelButtonOrText = styled.p`
  margin-top: 20px;
  margin-bottom: 20px;
  color: ${Colors.white};
  font-size: 15px;
  text-align: center;
`;
