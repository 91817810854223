import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../../shared/constants';

const checkColumnWidth = 300;



export const PriceTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  padding: 30px 20px;
  max-width: ${Sizes.messageMaxWidth}px;
  border-radius: ${Sizes.cardRadius}px;
`;

export const PaymentsHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #1a1a1a;
`;

export const MembershipDescView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  padding-top: 24px;
  padding-left: 34px;
  padding-right: 24px;
  padding-bottom: 24px;
`;

export const MembershipTextBig = styled.h3`
  margin-bottom: 0.3em;
  color: ${Colors.white};
  font-size: 26px;
  font-weight: 700;
`;

export const MembershipTextNormal = styled.p`
  color: ${Colors.white};
  font-size: 18px;
`;

export const MembershipHeaderCheck = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${checkColumnWidth}px;
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 34px;
  transition: .2s linear;
`;


export const PaymentsTitleBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
`;

export const PaymentsTitleLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PaymentsTitleClient = styled.h3`
  margin-bottom: 5px;
  color: #fff9;
  font-weight: 500;
  font-size: 29px;
  text-align: left;
`;

export const PaymentsSubTitleClient = styled.p`
  color: #fff9;
  text-align: left;
  font-weight: 500;
  font-size: 16px;
`;

export const PaymentsTitleRight = styled.div`
  display: flex;
  flex-direction: column;
`;


export const PaymentsPrice = styled.p`
  margin: 4px 0 2px;
  color: #fff9;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
`;

export const PaymentsPriceTime = styled.p`
  color: #fff9;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
`;

export const PaymentsCurrentButtonSecond = styled.div`
  display: flex;
  padding: 4px 32px;
  margin-top: 1.5em;
  width: 100%;
`;

export const MembershipTableColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;



export const MembershipRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  background-color: #292828;
  border-bottom: 1px solid #333333;
  transition: .2s linear;
`;

export const MembershipInfoView = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MembershipDesc = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  padding-top: 24px;
  padding-left: 34px;
  padding-right: 24px;
  padding-bottom: 24px;
`;

export const MembershipTitle = styled.h3`
  color: ${Colors.white};
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
`;

export const MembershipText = styled.p`
  margin-top: 8px;
  color: ${Colors.white};
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
`;

export const MembershipCheckView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: ${checkColumnWidth}px;
  background-color: rgba(0, 0, 0, 0.12);
`;
