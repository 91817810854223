import React from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  RootState,
} from '../../../../redux';

import {
  USER_BRAND_PROMOTER_MODAL,
  USER_VERIFIED_MODAL,
} from '../../../../redux/reducers/app-modals.store';

import {
  Colors,
  Images,
} from '../../../../shared/constants';

import {
  EPlansUser,
} from '../../../../shared/enums';

import UserAvatar from '../../../Contents/UserAvatar';

import {
  IconVerified,
  IconVerifiedPromoter,
} from '../../../Icons';

import {
  CardHomeOnlineContainer,
  HomeOnlineUserOnlineImage,
  SmallProfileLabelItem,
  HomeOnlineContent,
  HomeSmallViewContainer,
  HomeOnlineInfoContent,
  HomeOnlineUsernameText,
  HomeOnlineUserIcon,
  HomeOnlineNameText,
  BorderStatusView,
} from './styled';



export interface IProps {
  online?: boolean;
  exclusive?: boolean;
  plan?: EPlansUser;
  image?: string;
  username?: string;
  name?: string;
  verified?: boolean;
  promoter?: boolean;
  onPress?: any;
}



const CardSuggestionSmall: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();


  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);


  const userImage = (props.image === '' || props.image === null || props.image === undefined)
    ? Images.avatarUserImage
    : props.image;



  return (

    <CardHomeOnlineContainer>

      <HomeOnlineUserOnlineImage
        onClick={() => {
          if (props.onPress) {
            props.onPress();
          }
        }}>
        <UserAvatar
          width={158}
          image={userImage}
          plan={props.plan}
        />
      </HomeOnlineUserOnlineImage>



      {props.exclusive && (
        <SmallProfileLabelItem
          backgroundColor={Colors.menuOverlay}
          color={Colors.primary}
          onClick={() => {
            if (props.onPress) {
              props.onPress();
            }
          }}>
          Exclusivo
        </SmallProfileLabelItem>
      )}



      <HomeSmallViewContainer>

        <HomeOnlineContent>

          <HomeOnlineInfoContent>

            <HomeOnlineUsernameText
              onClick={() => {
                if (props.onPress) {
                  props.onPress();
                }
              }}>
              @{props.username || '-'}
            </HomeOnlineUsernameText>


            {props.verified && (
              <HomeOnlineUserIcon
                onClick={() => {
                  dispatch({ type: USER_VERIFIED_MODAL, payload: true });
                }}>
                <IconVerified
                  size={13}
                  color={Colors.accent}
                />
              </HomeOnlineUserIcon>
            )}


            {props.promoter && (
              <HomeOnlineUserIcon
                onClick={() => {
                  dispatch({ type: USER_BRAND_PROMOTER_MODAL, payload: true });
                }}>
                <IconVerifiedPromoter
                  size={13}
                  color={Colors.white}
                />
              </HomeOnlineUserIcon>
            )}

          </HomeOnlineInfoContent>



          <HomeOnlineNameText
            onClick={() => {
              if (props.onPress) {
                props.onPress();
              }
            }}>
            {props.name || '-'}
          </HomeOnlineNameText>

        </HomeOnlineContent>



        {projectParams?.canBeOnline && (
          <BorderStatusView
            online={props.online}
          />
        )}

      </HomeSmallViewContainer>

    </CardHomeOnlineContainer>

  );
};



export default CardSuggestionSmall;
