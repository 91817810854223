import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../shared/constants';



export interface IProps {
  width?: number;
  maxWidth?: number;
  height?: number;
}



export const CategoryChooseContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background-color: ${Colors.appBackground};
  border-radius: ${Sizes.cardRadius}px;

  ${(props: IProps) => props.width && `width: ${props.width}px`};
  ${(props: IProps) => props.width && `max-width: ${props.maxWidth}px`};
  ${(props: IProps) => props.height && `height: ${props.height}px`};
`;



export const LocationCountryButton = styled.a`
  width: 36px;
  height: 36px;
`;

export const LocationCountryImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
