export enum EAppTranslations {
  NAME_APP = 'NAME_APP',
  SHORTNAME_APP = 'SHORTNAME_APP',
  SLOGAN_APP = 'SLOGAN_APP',
  COMPANY_NAME = 'COMPANY_NAME',
  COMPANY_APP = 'COMPANY_APP',
  APP_STREAM = 'APP_STREAM',
  APP_SHOP = 'APP_SHOP',
};


export enum EAppTutorialTranslations {
  TUTORIAL_WARNING = 'TUTORIAL_WARNING',
  TUTORIAL_AGREE = 'TUTORIAL_AGREE',
};


export enum EButtonsTranslations {
  ACTION_APP_ENTER = 'ACTION_APP_ENTER',
  ACTION_ACCESS = 'ACTION_ACCESS',
  ACTION_ACCESS_TO_CONTINUE = 'ACTION_ACCESS_TO_CONTINUE',
  ACTION_REGISTER = 'ACTION_REGISTER',
  ACTION_CANCEL = 'ACTION_CANCEL',
  ACTION_CONFIRM = 'ACTION_CONFIRM',
  ACTION_FORWARD = 'ACTION_FORWARD',
  ACTION_BACK = 'ACTION_BACK',
  ACTION_CLOSE = 'ACTION_CLOSE',
  ACTION_CLICK_OPEN = 'ACTION_CLICK_OPEN',
  ACTION_CLICK_CLOSE = 'ACTION_CLICK_CLOSE',
  ACTION_CLICK_CANCEL = 'ACTION_CLICK_CANCEL',
  ACTION_MORE_DETAILS = 'ACTION_MORE_DETAILS',
  ACTION_NO = 'ACTION_NO',
  ACTION_YES = 'ACTION_YES',
  ACTION_SEE_HISTORY = 'ACTION_SEE_HISTORY',
  ACTION_SAVE = 'ACTION_SAVE',
  ACTION_CHOOSE = 'ACTION_CHOOSE',
  ACTION_WRITE = 'ACTION_WRITE',
  ACTION_CARD_ADD = 'ACTION_CARD_ADD',
  ACTION_ADDRESS_REGISTER = 'ACTION_ADDRESS_REGISTER',
  ACTION_CLICK_EDIT = 'ACTION_CLICK_EDIT',
  ACTION_LOCATION_ENABLED = 'ACTION_LOCATION_ENABLED',
  ACTION_COPY_LINK = 'ACTION_COPY_LINK',
  ACTION_COPY_PHONE = 'ACTION_COPY_PHONE',
  ACTION_SEARCH = 'ACTION_SEARCH',
  ACTION_RESEARCH = 'ACTION_RESEARCH',
  ACTION_PAY_FORMS_SEE = 'ACTION_PAY_FORMS_SEE',
  ACTION_LOCATION_MY = 'ACTION_LOCATION_MY',
  ACTION_PROFILE_SEE = 'ACTION_PROFILE_SEE',
  ACTION_TO_SEE_PROFILE = 'ACTION_TO_SEE_PROFILE',
  ACTION_SKIP = 'ACTION_SKIP',
  ACTION_PROFILE_MANAGE = 'ACTION_PROFILE_MANAGE',
  ACTION_CHECK_ALL = 'ACTION_CHECK_ALL',
  ACTION_UNCHECK_ALL = 'ACTION_UNCHECK_ALL',
  ACTION_CHOOSE_LOCATION = 'ACTION_CHOOSE_LOCATION',
  ACTION_BE_MODEL = 'ACTION_BE_MODEL',
  ACTION_LEVEL_CHANGE = 'ACTION_LEVEL_CHANGE',
  ACTION_LEVEL_CHANGE_HIGHLIGHT = 'ACTION_LEVEL_CHANGE_HIGHLIGHT',
  ACTION_LEVEL_SHAKE_ATTENTION = 'ACTION_LEVEL_SHAKE_ATTENTION',
  ACTION_LEVEL_UP_HIGHLIGHT = 'ACTION_LEVEL_UP_HIGHLIGHT',
  ACTION_COMMENT_MAKE = 'ACTION_COMMENT_MAKE',
  ACTION_SEE_TRAVELS = 'ACTION_SEE_TRAVELS',
  ACTION_SCHEDULE_TIME = 'ACTION_SCHEDULE_TIME',
  ACTION_ROOM_TIME = 'ACTION_ROOM_TIME',
  ACTION_SEE_ALL = 'ACTION_SEE_ALL',
  ACTION_SEE_ALL_PHOTOS = 'ACTION_SEE_ALL_PHOTOS',
  ACTION_SEE_ALL_VIDEOS = 'ACTION_SEE_ALL_VIDEOS',
  ACTION_SEE_ALL_MODELES = 'ACTION_SEE_ALL_MODELES',
  ACTION_SEE_ALL_SPONSORS = 'ACTION_SEE_ALL_SPONSORS',
  ACTION_SEE_OTHERS_MODELES = 'ACTION_SEE_OTHERS_MODELES',
  ACTION_CHANGE_LOCATION_TIMEZONE = 'ACTION_CHANGE_LOCATION_TIMEZONE',
  ACTION_OPEN_MORE = 'ACTION_OPEN_MORE',
  ACTION_OPEN_OTHER_MORE = 'ACTION_OPEN_OTHER_MORE',
  ACTION_SUGGESTIONS_SEE_MORE = 'ACTION_SUGGESTIONS_SEE_MORE',
  ACTION_BE_PREMIUM = 'ACTION_BE_PREMIUM',
  ACTION_SEND_GIFT = 'ACTION_SEND_GIFT',
  ACTION_EDIT = 'ACTION_EDIT',
  ACTION_DELETE = 'ACTION_DELETE',
  ACTION_SHOW_PROFILE = 'ACTION_SHOW_PROFILE',
  ACTION_HIDE_PROFILE = 'ACTION_HIDE_PROFILE',
  ACTION_PUSBLISH_AUTO_ON = 'ACTION_PUSBLISH_AUTO_ON',
  ACTION_PUSBLISH_AUTO_OFF = 'ACTION_PUSBLISH_AUTO_OFF',
  ACTION_PHOTO_ADD = 'ACTION_PHOTO_ADD',
  ACTION_GO_TO_HOME = 'ACTION_GO_TO_HOME',
  ACTION_LEARN_MORE = 'ACTION_LEARN_MORE',
  ACTION_ADD_FAVORITE = 'ACTION_ADD_FAVORITE',
  ACTION_REMOVE_FAVORITE = 'ACTION_REMOVE_FAVORITE',
  ACTION_ADD_NOTIFY_MODELE_ONLINE = 'ACTION_ADD_NOTIFY_MODELE_ONLINE',
  ACTION_REMOVE_NOTIFY_MODELE_ONLINE = 'ACTION_REMOVE_NOTIFY_MODELE_ONLINE',
  ACTION_BE_YOUR_ESCORT = 'ACTION_BE_YOUR_ESCORT',
  ACTION_BLOCK = 'ACTION_BLOCK',
  ACTION_FAN_CLUB_SUBSCRIBE = 'ACTION_FAN_CLUB_SUBSCRIBE',
  ACTION_GIFT_ANONYMOUS = 'ACTION_GIFT_ANONYMOUS',
  ACTION_CHAT_DELETE = 'ACTION_CHAT_DELETE',
};


export enum ECountriesTranslations {
  BRAZIL = 'BRAZIL',
  PORTUGAL = 'PORTUGAL',
  UNITED_STATES = 'UNITED_STATES',
  SPAIN = 'SPAIN',
  ITALY = 'ITALY',
  FANCE = 'FANCE',
};


export enum EFiltersTranslations {
  FILTERS = 'FILTERS',
  FILTER_COCK = 'FILTER_COCK',
  FILTER_POSITION = 'FILTER_POSITION',
  FILTER_WHERE_FROM = 'FILTER_WHERE_FROM',
  FILTER_LOCATION = 'FILTER_LOCATION',
};


export enum EInterpolatesTranslations {
  HELLO = 'HELLO',
  IN = 'IN',
  FROM = 'FROM',
  TO = 'TO',
  AT = 'AT',
  OR = 'OR',
  AND = 'AND',
  THERE_IS = 'THERE_IS',
  ACCESS_USER_REGISTER = 'ACCESS_USER_REGISTER',
  ACCESS_USER_TERMS_USE = 'ACCESS_USER_TERMS_USE',
  ACCESS_USER_TERMS_PRIVACY = 'ACCESS_USER_TERMS_PRIVACY',
  ACCESS_USER_TERMS_COOKIES = 'ACCESS_USER_TERMS_COOKIES',
  PRICE_BETWEEN_MIN_MAX = 'PRICE_BETWEEN_MIN_MAX',
  PRICE_INSTALLMENT_VALUE = 'PRICE_INSTALLMENT_VALUE',
  LIVE_UPDATE_ROOM = 'LIVE_UPDATE_ROOM',
};


export enum ELabelsTranslations {
  ALL = 'ALL',
  CUSTOM = 'CUSTOM',
  PUBLIC_OPEN = 'PUBLIC_OPEN',
  FAN_CLUB = 'FAN_CLUB',
  FAN_CLUBS = 'FAN_CLUBS',
  BUYED = 'BUYED',
  PRIVATES = 'PRIVATES',
  MODELE = 'MODELE',
  MODELES = 'MODELES',
  PROFILE = 'PROFILE',
  PROFILES = 'PROFILES',
  PERSONAL_DATA = 'PERSONAL_DATA',
  PERSONAL_INFORMATIONS = 'PERSONAL_INFORMATIONS',
  COUNTRY = 'COUNTRY',
  LOADING = 'LOADING',
  USER = 'USER',
  NAME = 'NAME',
  NAME_FULL = 'NAME_FULL',
  DATE_BORN = 'DATE_BORN',
  GENRE = 'GENRE',
  GENRE_MEN = 'GENRE_MEN',
  GENRE_WOMEN = 'GENRE_WOMEN',
  GENRE_TRANS_MEN = 'GENRE_TRANS_MEN',
  GENRE_TRANS_WOMEN = 'GENRE_TRANS_WOMEN',
  GENRE_SINGLE_MAN = 'GENRE_SINGLE_MAN',
  GENRE_SINGLE_WOMAN = 'GENRE_SINGLE_WOMAN',
  GENRE_SINGLE_TRANS_MAN = 'GENRE_SINGLE_TRANS_MAN',
  GENRE_SINGLE_TRANS_WOMAN = 'GENRE_SINGLE_TRANS_WOMAN',
  GENRE_LIGHT_MEN = 'GENRE_LIGHT_MEN',
  GENRE_LIGHT_WOMEN = 'GENRE_LIGHT_WOMEN',
  GENRE_LIGHT_TRANS_MEN = 'GENRE_LIGHT_TRANS_MEN',
  GENRE_LIGHT_TRANS_WOMEN = 'GENRE_LIGHT_TRANS_WOMEN',
  DDI_NUMBER_FIRST = 'DDI_NUMBER_FIRST',
  DDI_NUMBER_SECOND = 'DDI_NUMBER_SECOND',
  DDI = 'DDI',
  PERSONS = 'PERSONS',
  USERNAME = 'USERNAME',
  USERNAME_COMPANY = 'USERNAME_COMPANY',
  EMAIL = 'EMAIL',
  EMAIL_REGISTERED = 'EMAIL_REGISTERED',
  PASSWORD = 'PASSWORD',
  CNPJ = 'CNPJ',
  CPF = 'CPF',
  FORMS = 'FORMS',
  OPTIONAL = 'OPTIONAL',
  PUBLIC_ID = 'PUBLIC_ID',
  PUBLIC_PARTNER_ID = 'PUBLIC_PARTNER_ID',
  NUMBER_PRIMARY = 'NUMBER_PRIMARY',
  NUMBER_SECONDARY = 'NUMBER_SECONDARY',
  PARTNER_ABOUT = 'PARTNER_ABOUT',
  PARTNER_PHONES = 'PARTNER_PHONES',
  PHONE = 'PHONE',
  PHONE_ONLY_ONE = 'PHONE_ONLY_ONE',
  PHONE_FIRST = 'PHONE_FIRST',
  PHONE_SECOND = 'PHONE_SECOND',
  PARTNER_RULES = 'PARTNER_RULES',
  PARTNER_TYPES_KITCHEN = 'PARTNER_TYPES_KITCHEN',
  PARTNER_OPEN_HOURS = 'PARTNER_OPEN_HOURS',
  WIFI = 'WIFI',
  KIDS_AREA = 'KIDS_AREA',
  PARKING = 'PARKING',
  SMOKING = 'SMOKING',
  AIR_CONDITIONER = 'AIR_CONDITIONER',
  ACCESSIBILITY = 'ACCESSIBILITY',
  MORE_POPULAR = 'MORE_POPULAR',
  NUMBER_OR_NAME = 'NUMBER_OR_NAME',
  INPUT_MODAL_ADD_USER_PLACEHOLDER = 'INPUT_MODAL_ADD_USER_PLACEHOLDER',
  CONFIRM_CPF = 'CONFIRM_CPF',
  CONFIRM_CNPJ = 'CONFIRM_CNPJ',
  CONFIRM_INFO = 'CONFIRM_INFO',
  NICKNAME = 'NICKNAME',
  DESCRIPTION = 'DESCRIPTION',
  FAVORED = 'FAVORED',
  ACCOUNT_USE_MAIN = 'ACCOUNT_USE_MAIN',
  ADDRESS_USE_MAIN = 'ADDRESS_USE_MAIN',
  INPUT_MODAL_ADD_USER_CARGO = 'INPUT_MODAL_ADD_USER_CARGO',
  CODE = 'CODE',
  DIGIT = 'DIGIT',
  ACCOUNT = 'ACCOUNT',
  ID = 'ID',
  ID_MEMBER = 'ID_MEMBER',
  ID_PRIVATE = 'ID_PRIVATE',
  ID_PUBLIC = 'ID_PUBLIC',
  COMPANY_STATUS_APP = 'COMPANY_STATUS_APP',
  COMPANY_STATUS_ACTIVE = 'COMPANY_STATUS_ACTIVE',
  COMPANY_STATUS_INACTIVE = 'COMPANY_STATUS_INACTIVE',
  PLACE_NAME = 'PLACE_NAME',
  ZIP_CODE = 'ZIP_CODE',
  STREET = 'STREET',
  STREETS = 'STREETS',
  DISTRICT = 'DISTRICT',
  DISTRICTS = 'DISTRICTS',
  ATTRACTIONS = 'ATTRACTIONS',
  COMPLEMENT = 'COMPLEMENT',
  CITY = 'CITY',
  CITIES = 'CITIES',
  STATE = 'STATE',
  STATE_REGION = 'STATE_REGION',
  SOCIAL = 'SOCIAL',
  SLOGAN = 'SLOGAN',
  CELLPHONE = 'CELLPHONE',
  ABOUT = 'ABOUT',
  ABOUT_APP = 'ABOUT_APP',
  PROFILE_TYPE = 'PROFILE_TYPE',
  PARTNER_TYPE = 'PARTNER_TYPE',
  INFORMATIONS = 'INFORMATIONS',
  CARD_HOLDER_NAME = 'CARD_HOLDER_NAME',
  CARD_NICKNAME = 'CARD_NICKNAME',
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  SOCIAL_NAME = 'SOCIAL_NAME',
  ABOUT_ME = 'ABOUT_ME',
  LEVEL = 'LEVEL',
  BIRTHDAY = 'BIRTHDAY',
  PICTURE = 'PICTURE',
  PICTURES = 'PICTURES',
  USER_PRE_REGISTER = 'USER_PRE_REGISTER',
  DATAS = 'DATAS',
  SHOPS = 'SHOPS',
  SHOP_APP = 'SHOP_APP',
  OPTIONS = 'OPTIONS',
  MORE_OPTIONS = 'MORE_OPTIONS',
  ME_AND_GUESTS = 'ME_AND_GUESTS',
  ONLY_YOU = 'ONLY_YOU',
  ONLY_FOR_ME = 'ONLY_FOR_ME',
  PEOPLE = 'PEOPLE',
  RESERVE_DATE = 'RESERVE_DATE',
  DATE_SELECTED = 'DATE_SELECTED',
  ENVIRONMENT = 'ENVIRONMENT',
  PEOPLE_NUMBER = 'PEOPLE_NUMBER',
  MORE_INFORMATIONS = 'MORE_INFORMATIONS',
  OBSERVATIONS = 'OBSERVATIONS',
  INITIAL_DATE = 'INITIAL_DATE',
  INITIAL_HOUR = 'INITIAL_HOUR',
  FINAL_DATE = 'FINAL_DATE',
  FINAL_HOUR = 'FINAL_HOUR',
  INITIAL_VALUE = 'INITIAL_VALUE',
  FINAL_VALUE = 'FINAL_VALUE',
  PARAMETERS = 'PARAMETERS',
  NAME_OR_USERNAME = 'NAME_OR_USERNAME',
  MODALITIES = 'MODALITIES',
  VERSION = 'VERSION',
  MY_ACCOUNT = 'MY_ACCOUNT',
  ACCOUNT_SEE = 'ACCOUNT_SEE',
  ACCOUNT_ACTIONS = 'ACCOUNT_ACTIONS',
  PAYMENTS_FORMS = 'PAYMENTS_FORMS',
  TRAVELS = 'TRAVELS',
  LOCATION = 'LOCATION',
  LOCATION_NO_ADDRESS = 'LOCATION_NO_ADDRESS',
  TICKETS_SALES_UNTIL = 'TICKETS_SALES_UNTIL',
  PARTICIPATION = 'PARTICIPATION',
  WAIT_YOU = 'WAIT_YOU',
  ITEMS = 'ITEMS',
  HELP = 'HELP',
  TYPE = 'TYPE',
  CANCELED = 'CANCELED',
  FINISHED = 'FINISHED',
  OTHER = 'OTHER',
  ADD = 'ADD',
  ACTUAL = 'ACTUAL',
  COUNTRIES = 'COUNTRIES',
  NO = 'NO',
  YES = 'YES',
  HI = 'HI',
  LIST_NAME = 'LIST_NAME',
  GUIDES_INFORMATIONS = 'GUIDES_INFORMATIONS',
  PHONES_USEFUL = 'PHONES_USEFUL',
  CASADINHA = 'CASADINHA',
  OPEN_BAR = 'OPEN_BAR',
  LIST = 'LIST',
  VIP = 'VIP',
  MEETING = 'MEETING',
  COMMENT_DID_YOU_MEET = 'COMMENT_DID_YOU_MEET',
  COMMENT_PERFORMANCE = 'COMMENT_PERFORMANCE',
  COMMENT_PERFORMANCE_FROM = 'COMMENT_PERFORMANCE_FROM',
  COMMENT_NOTE = 'COMMENT_NOTE',
  COMMENT_NOTE_FROM = 'COMMENT_NOTE_FROM',
  COMMENT_EXPERIENCE = 'COMMENT_EXPERIENCE',
  COMMENT_EXPERIENCE_FROM = 'COMMENT_EXPERIENCE_FROM',
  COMMENT_DATE_MEET = 'COMMENT_DATE_MEET',
  COMMENT_PICTURES = 'COMMENT_PICTURES',
  COMMENT_RECOMMEND = 'COMMENT_RECOMMEND',
  COMMENT_RECOMMEND_MODELE = 'COMMENT_RECOMMEND_MODELE',
  LOCATION_MY = 'LOCATION_MY',
  DAYS = 'DAYS',
  FOLLOWER = 'FOLLOWER',
  FOLLOWERS = 'FOLLOWERS',
  FOLLOWERS_NONE = 'FOLLOWERS_NONE',
  PHONE_NONE = 'PHONE_NONE',
  COMMENT = 'COMMENT',
  COMMENTS = 'COMMENTS',
  UPDATE_PENDENT = 'UPDATE_PENDENT',
  UPDATES_PENDENTS = 'UPDATES_PENDENTS',
  ONLINE = 'ONLINE',
  DISCONNECTED = 'DISCONNECTED',
  EXCLUSIVE = 'EXCLUSIVE',
  PORNSTAR = 'PORNSTAR',
  MORE = 'MORE',
  DETAILS = 'DETAILS',
  TRAVEL = 'TRAVEL',
  SEX_CONDOM = 'SEX_CONDOM',
  ASK_ME = 'ASK_ME',
  VIDEO = 'VIDEO',
  VIDEOS = 'VIDEOS',
  LIVE = 'LIVE',
  MASSAGE = 'MASSAGE',
  BLOCK = 'BLOCK',
  BLOCKS = 'BLOCKS',
  MEDIA = 'MEDIA',
  MEDIAS = 'MEDIAS',
  LIKE = 'LIKE',
  LIKED = 'LIKED',
  LIKED_MEDIA_COUNT = 'LIKED_MEDIA_COUNT',
  LIKED_MEDIA_COUNTS = 'LIKED_MEDIA_COUNTS',
  NO_LIKED_MEDIA_COUNT = 'NO_LIKED_MEDIA_COUNT',
  GENERAL_INFO = 'GENERAL_INFO',
  STATUS = 'STATUS',
  CONTENT = 'CONTENT',
  ADULT = 'ADULT',
};


export enum ELabelsAdsTranslations {
  AD = 'AD',
  ADS = 'ADS',
  ADS_FREE = 'ADS_FREE',
  ADS_RENT_FREE_1 = 'ADS_RENT_FREE_1',
  ADS_HIGHLIGHT = 'ADS_HIGHLIGHT',
  ADS_BENEFITS = 'ADS_BENEFITS',
  ADS_RENT_HIGHLIGHT_TEXT_1 = 'ADS_RENT_HIGHLIGHT_TEXT_1',
  ADS_RENT_EVIDENCE = 'ADS_RENT_EVIDENCE',
  ADS_RENT_EVIDENCE_1 = 'ADS_RENT_EVIDENCE_1',
  ADS_TYPES_HIGHLIGHT = 'ADS_TYPES_HIGHLIGHT',
  ADS_TYPES_HIGHLIGHT_1 = 'ADS_TYPES_HIGHLIGHT_1',
  ADS_TYPES_HIGHLIGHT_2 = 'ADS_TYPES_HIGHLIGHT_2',
  ADS_TYPES_HIGHLIGHT_3 = 'ADS_TYPES_HIGHLIGHT_3',
  ADS_TYPES_HIGHLIGHT_4 = 'ADS_TYPES_HIGHLIGHT_4',
  ADS_LOCATION_HIGHLIGHT_1 = 'ADS_LOCATION_HIGHLIGHT_1',
  ADS_LOCATION_HIGHLIGHT_2 = 'ADS_LOCATION_HIGHLIGHT_2',
  ADS_LOCATION_HIGHLIGHT_3 = 'ADS_LOCATION_HIGHLIGHT_3',
  ADS_LOCATION_HIGHLIGHT_4 = 'ADS_LOCATION_HIGHLIGHT_4',
  ADS_RENT_TIME = 'ADS_RENT_TIME',
  ADS_RENT_HIGH_NUMBER_1 = 'ADS_RENT_HIGH_NUMBER_1',
  ADS_RENT_HIGH_NUMBER_2 = 'ADS_RENT_HIGH_NUMBER_2',
  ADS_RENT_HIGH_NUMBER_3 = 'ADS_RENT_HIGH_NUMBER_3',
  ADS_RENT_HIGH_NUMBER_4 = 'ADS_RENT_HIGH_NUMBER_4',
  ADS_RENT_HIGH_NUMBER_5 = 'ADS_RENT_HIGH_NUMBER_5',
  ADS_RENT_HIGH_NUMBER_MORE = 'ADS_RENT_HIGH_NUMBER_MORE',
  ADS_RENT_VALIDITY = 'ADS_RENT_VALIDITY',
  ADS_RENT_VALIDITY_1 = 'ADS_RENT_VALIDITY_1',
  ADS_RENT_VALIDITY_DAYS_1 = 'ADS_RENT_VALIDITY_DAYS_1',
  ADS_RENT_VALIDITY_2 = 'ADS_RENT_VALIDITY_2',
  ADS_RENT_VALIDITY_3 = 'ADS_RENT_VALIDITY_3',
  ADS_RENT_HOW_PROCEED = 'ADS_RENT_HOW_PROCEED',
  ADS_RENT_HOW_PROCEED_1 = 'ADS_RENT_HOW_PROCEED_1',
};


export enum ELabelsBeModelTranslations {
  BE_MODELE_ITEM_1 = 'BE_MODELE_ITEM_1',
  BE_MODELE_ITEM_2 = 'BE_MODELE_ITEM_2',
  BE_MODELE_ITEM_3 = 'BE_MODELE_ITEM_3',
  BE_MODELE_ITEM_4 = 'BE_MODELE_ITEM_4',
  BE_MODELE_ITEM_5 = 'BE_MODELE_ITEM_5',
  BE_MODELE_ITEM_6 = 'BE_MODELE_ITEM_6',
  SUBTITLES_BE_MODELE_1 = 'SUBTITLES_BE_MODELE_1',
  SUBTITLES_BE_MODELE_2 = 'SUBTITLES_BE_MODELE_2',
  SUBTITLES_BE_MODELE_3 = 'SUBTITLES_BE_MODELE_3',
  SUBTITLES_BE_MODELE_4 = 'SUBTITLES_BE_MODELE_4',
  SUBTITLES_BE_MODELE_5 = 'SUBTITLES_BE_MODELE_5',
  LANDING_TITLE_THE = 'LANDING_TITLE_THE',
  LANDING_TITLE_BEST = 'LANDING_TITLE_BEST',
  LANDING_TITLE_CONTENT = 'LANDING_TITLE_CONTENT',
  LANDING_DESCRIPTION = 'LANDING_DESCRIPTION',
  LANDING_SECTION_TITLE_1 = 'LANDING_SECTION_TITLE_1',
  LANDING_SECTION_DESCRIPTION_1 = 'LANDING_SECTION_DESCRIPTION_1',
  LANDING_ADULT_JOB = 'LANDING_ADULT_JOB',
};


export enum ELabelsCharsTranslations {
  AGE = 'AGE',
  AGE_SHOW = 'AGE_SHOW',
  HEIGHT = 'HEIGHT',
  WEIGHT = 'WEIGHT',
  ETHNICITY = 'ETHNICITY',
  HAIR_SIZE = 'HAIR_SIZE',
  HAIR_COLOR = 'HAIR_COLOR',
  EYE_COLOR = 'EYE_COLOR',
  MANNEQUIN = 'MANNEQUIN',
  BUST = 'BUST',
  WAIST = 'WAIST',
  HIP = 'HIP',
  FEET = 'FEET',
  PENIS = 'PENIS',
  POSITION = 'POSITION',
  FORESKIN = 'FORESKIN',
  STYLE = 'STYLE',
  BODY = 'BODY',
  BODY_HAIR = 'BODY_HAIR',
  ORIENTATION_SEXUAL = 'ORIENTATION_SEXUAL',
  ZODIAC = 'ZODIAC',
  SMOKE = 'SMOKE',
  DRINK = 'DRINK',
  TATTOOS = 'TATTOOS',
  PIERCING = 'PIERCING',
  BREASTS = 'BREASTS',
  HEALTH = 'HEALTH',
  HIV = 'HIV',
  SPOKEN_LANGS = 'SPOKEN_LANGS',
  TRIBES = 'TRIBES',
  IM_IN = 'IM_IN',
  ATTENDANCE = 'ATTENDANCE',
  ATTENDANCE_PLACE = 'ATTENDANCE_PLACE',
  PLACE_MY = 'PLACE_MY',
  PLACE_YOUR = 'PLACE_YOUR',
  HOTEL = 'HOTEL',
  MOTEL = 'MOTEL',
  SERVICE_24H = 'SERVICE_24H',
  SERVICE_DONT_DO = 'SERVICE_DONT_DO',
  AVAILABLE_FOR = 'AVAILABLE_FOR',
};


export enum ELabelsCommentsTranslations {
  COMMENT_AUTO_TEXT_1 = 'COMMENT_AUTO_TEXT_1',
  COMMENT_AUTO_TEXT_2 = 'COMMENT_AUTO_TEXT_2',
  COMMENT_RULE_1 = 'COMMENT_RULE_1',
  COMMENT_RULE_2 = 'COMMENT_RULE_2',
  COMMENT_RULE_3 = 'COMMENT_RULE_3',
  COMMENT_LIVE_INTERPOLATE_1 = 'COMMENT_LIVE_INTERPOLATE_1',
  COMMENT_LIVE_INTERPOLATE_2 = 'COMMENT_LIVE_INTERPOLATE_2',
};


export enum ELabelsLiveTranslations {
  CAMS = 'CAMS',
  LIVE_PROMOS = 'LIVE_PROMOS',
  LIVE_ROOM = 'LIVE_ROOM',
  LIVE_ROOMS = 'LIVE_ROOMS',
  LIVE_ROOMS_ONDEMAND = 'LIVE_ROOMS_ONDEMAND',
  LIVE_ROOM_OPENED = 'LIVE_ROOM_OPENED',
  LIVE_ROOMS_OPENED = 'LIVE_ROOMS_OPENED',
  LIVE_IMAGES = 'LIVE_IMAGES',
  LIVE_IMAGE_ROOM = 'LIVE_IMAGE_ROOM',
  LIVE_SHOW_MODELS_ONLINE = 'LIVE_SHOW_MODELS_ONLINE',
  LIVE_SHOW_MODELS_PHONE = 'LIVE_SHOW_MODELS_PHONE',
  LIVE_SHOW_MODELS_FLAG = 'LIVE_SHOW_MODELS_FLAG',
  LIVE_SHOW_MODELS_HIGH_CAM = 'LIVE_SHOW_MODELS_HIGH_CAM',
  LIVE_GRID_SMALL = 'LIVE_GRID_SMALL',
  LIVE_GRID_MEDIUM = 'LIVE_GRID_MEDIUM',
  LIVE_GRID_LARGE = 'LIVE_GRID_LARGE',
  LIVE_CHAT_EXCLUSIVE = 'LIVE_CHAT_EXCLUSIVE',
  LIVE_CHAT_PARTICULAR = 'LIVE_CHAT_PARTICULAR',
  LIVE_CHAT_PARTY = 'LIVE_CHAT_PARTY',
  LIVE_GROUP_JOIN = 'LIVE_GROUP_JOIN',
  LIVE_GROUP_START = 'LIVE_GROUP_START',
  LIVE_GROUP_STARTED = 'LIVE_GROUP_STARTED',
  LIVE_TOPIC = 'LIVE_TOPIC',
};


export enum ELabelsReportsTranslations {
  STATISTICS = 'STATISTICS',
  PROFILE_VISITS = 'PROFILE_VISITS',
  PROFILE_CHANGED_IN = 'PROFILE_CHANGED_IN',
  ACCESS_LAST = 'ACCESS_LAST',
  ACCESS_LAST_SHOW = 'ACCESS_LAST_SHOW',
  MEMBER_SINCE = 'MEMBER_SINCE',
  CHATS_MY = 'CHATS_MY',
  CHATS_SIMPLE = 'CHATS_SIMPLE',
  CHATS_PRIVATE = 'CHATS_PRIVATE',
  SHOW_MY = 'SHOW_MY',
  MY_TRAVEL_LOCATION = 'MY_TRAVEL_LOCATION',
  MY_TRAVEL_LOCATION_SHOW = 'MY_TRAVEL_LOCATION_SHOW',
  PROFILE_ON_SEARCH = 'PROFILE_ON_SEARCH',
  PROFILE_ON_SEARCH_SHOW = 'PROFILE_ON_SEARCH_SHOW',
  PROFILE_ON_SEARCH_TEXT = 'PROFILE_ON_SEARCH_TEXT',
};


export enum ELanguagesTranslations {
  PT_BR = 'PT_BR',
  EN_US = 'EN_US',
  ES_AR = 'ES_AR',
  IT_IT = 'IT_IT',
  FR_FR = 'FR_FR',
};


export enum EMediasTranslations {
  MEDIA_APPROVE_TITLE = 'MEDIA_APPROVE_TITLE',
  MEDIIA_ACCEPT_TITLE = 'MEDIIA_ACCEPT_TITLE',
  MEDIIA_ACCEPT_1 = 'MEDIIA_ACCEPT_1',
  MEDIIA_ACCEPT_2 = 'MEDIIA_ACCEPT_2',
  MEDIIA_NOT_ACCEPT_TITLE = 'MEDIIA_NOT_ACCEPT_TITLE',
  MEDIIA_NOT_ACCEPT_1 = 'MEDIIA_NOT_ACCEPT_1',
  MEDIIA_NOT_ACCEPT_2 = 'MEDIIA_NOT_ACCEPT_2',
  MEDIA_RULES = 'MEDIA_RULES',
  MEDIA_PROFILE_EDIT = 'MEDIA_PROFILE_EDIT',
};


export enum EMenuOrderTranslations {
  COMBO = 'COMBO',
  ITEM_DOUBLE = 'ITEM_DOUBLE',
  AVAILABLES = 'AVAILABLES',
  TABLE = 'TABLE',
  NUMBER = 'NUMBER',
  NUMBER_TABLE_ENTRANCE = 'NUMBER_TABLE_ENTRANCE',
  TABLE_ENTRANCE = 'TABLE_ENTRANCE',
  MIN_ORDERSHEET = 'MIN_ORDERSHEET',
  WAITER_CALL = 'WAITER_CALL',
  WAITER_CALLING = 'WAITER_CALLING',
  WAITER_GOING = 'WAITER_GOING',
  ORDERSHEET_CLOSING_ORDERSHEET = 'ORDERSHEET_CLOSING_ORDERSHEET',
  ORDERSHEET_CLOSED = 'ORDERSHEET_CLOSED',
  ORDERSHEET_OPENED = 'ORDERSHEET_OPENED',
  TOTAL_CONSUMPTION = 'TOTAL_CONSUMPTION',
  ALCOHOLIC = 'ALCOHOLIC',
  SPICY = 'SPICY',
  VEGETARIAN = 'VEGETARIAN',
  VEGAN = 'VEGAN',
  ORGANIC = 'ORGANIC',
  NO_LACTOSE = 'NO_LACTOSE',
  NO_GLUTEN = 'NO_GLUTEN',
  REQUESTS = 'REQUESTS',
  REQUESTS_MINIMAL = 'REQUESTS_MINIMAL',
  REQUESTS_UNTIL = 'REQUESTS_UNTIL',
  DELIVERY = 'DELIVERY',
  REMOVAL_ITEM = 'REMOVAL_ITEM',
  PRESENTIAL = 'PRESENTIAL',
  ORDER_PLACED = 'ORDER_PLACED',
  ORDER_PREPARING = 'ORDER_PREPARING',
  ORDER_TO_DELIVER = 'ORDER_TO_DELIVER',
  ORDER_REFUSED = 'ORDER_REFUSED',

  PROFILE_LEVEL_MODELE = 'PROFILE_LEVEL_MODELE',
  PROFILE_SHOW = 'PROFILE_SHOW',
  PROFILE_HIDE = 'PROFILE_HIDE',
};


export enum EMessagesEmptysTranslations {
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  EMPTY_LOCATION = 'EMPTY_LOCATION',
  EMPTY_ADS = 'EMPTY_ADS',
  EMPTY_MODELES = 'EMPTY_MODELES',
  EMPTY_MODELES_FOUND = 'EMPTY_MODELES_FOUND',
  EMPTY_MODELES_TEXT = 'EMPTY_MODELES_TEXT',
  EMPTY_MODELES_UNAVAILABLE = 'EMPTY_MODELES_UNAVAILABLE',
  EMPTY_MODELES_UNAVAILABLE_TEXT = 'EMPTY_MODELES_UNAVAILABLE_TEXT',
  EMPTY_GALLERY_PHOTOS = 'EMPTY_GALLERY_PHOTOS',
  EMPTY_GALLERY_VIDEOS = 'EMPTY_GALLERY_VIDEOS',
  EMPTY_COMPANY_NOT_FOUND = 'EMPTY_COMPANY_NOT_FOUND',
  EMPTY_COMPANY_EVENTS = 'EMPTY_COMPANY_EVENTS',
  EMPTY_EVENTS_NOT_FOUND = 'EMPTY_EVENTS_NOT_FOUND',
  EMPTY_HIGHLIGHTS_ITEMS = 'EMPTY_HIGHLIGHTS_ITEMS',
  EMPTY_HIGHLIGHTS_EVENTS = 'EMPTY_HIGHLIGHTS_EVENTS',
  EMPTY_HIGHLIGHTS_PARTNERS = 'EMPTY_HIGHLIGHTS_PARTNERS',
  EMPTY_CATEGORIES = 'EMPTY_CATEGORIES',
  EMPTY_ITEMS = 'EMPTY_ITEMS',
  EMPTY_MENUSHEET_CATEGORIES = 'EMPTY_MENUSHEET_CATEGORIES',
  EMPTY_NOTIFICATIONS = 'EMPTY_NOTIFICATIONS',
  EMPTY_ORDERSHEET_HISTORY = 'EMPTY_ORDERSHEET_HISTORY',
  EMPTY_BANKS = 'EMPTY_BANKS',
  EMPTY_COMPANIES_REGISTERED = 'EMPTY_COMPANIES_REGISTERED',
  EMPTY_HOME = 'EMPTY_HOME',
  EMPTY_FAVORITES = 'EMPTY_FAVORITES',
  EMPTY_FAVORITES_PHOTOS = 'EMPTY_FAVORITES_PHOTOS',
  EMPTY_FAVORITES_VIDEOS = 'EMPTY_FAVORITES_VIDEOS',
  EMPTY_PAYMENT = 'EMPTY_PAYMENT',
  EMPTY_ADDRESS = 'EMPTY_ADDRESS',
  EMPTY_USER_DATA = 'EMPTY_USER_DATA',
  EMPTY_USER_NO_RECOG = 'EMPTY_USER_NO_RECOG',
  EMPTY_LISTS = 'EMPTY_LISTS',
  EMPTY_LISTS_PARTICIPATE = 'EMPTY_LISTS_PARTICIPATE',
  EMPTY_LISTS_FINISHED = 'EMPTY_LISTS_FINISHED',
  EMPTY_TICKET = 'EMPTY_TICKET',
  EMPTY_TICKET_IN_EVENT = 'EMPTY_TICKET_IN_EVENT',
  EMPTY_EVENT_EXPLORE = 'EMPTY_EVENT_EXPLORE',
  EMPTY_CARD_REGISTERED = 'EMPTY_CARD_REGISTERED',
  EMPTY_CARD_NO_ADDED = 'EMPTY_CARD_NO_ADDED',
  EMPTY_RESERVE_REGISTERED = 'EMPTY_RESERVE_REGISTERED',
  EMPTY_RESERVE_USER = 'EMPTY_RESERVE_USER',
  EMPTY_LINK_SEE = 'EMPTY_LINK_SEE',
  EMPTY_SEARCH = 'EMPTY_SEARCH',
  EMPTY_ORDERS = 'EMPTY_ORDERS',
  EMPTY_EVENT_FINISHED = 'EMPTY_EVENT_FINISHED',
  EMPTY_PLACES = 'EMPTY_PLACES',
  EMPTY_CARNIVAL_ATTRACTIONS = 'EMPTY_CARNIVAL_ATTRACTIONS',
  EMPTY_CARNIVAL_NONE = 'EMPTY_CARNIVAL_NONE',
  EMPTY_CARNIVAL_DISTRICTS = 'EMPTY_CARNIVAL_DISTRICTS',
  EMPTY_CARNIVAL_CABINS = 'EMPTY_CARNIVAL_CABINS',
  EMPTY_CARNIVAL_LOCATIONS = 'EMPTY_CARNIVAL_LOCATIONS',
  EMPTY_CARNIVAL_STREETS = 'EMPTY_CARNIVAL_STREETS',
  EMPTY_CARNIVAL_INFOS = 'EMPTY_CARNIVAL_INFOS',
  EMPTY_PHONES = 'EMPTY_PHONES',
  EMPTY_CARNIVAL_VOTES = 'EMPTY_CARNIVAL_VOTES',
  EMPTY_LANGUAGES = 'EMPTY_LANGUAGES',
  EMPTY_LIST_AVAILABLE = 'EMPTY_LIST_AVAILABLE',
  EMPTY_TICKET_AVAILABLE = 'EMPTY_TICKET_AVAILABLE',
  EMPTY_SCHEDULE_TIME = 'EMPTY_SCHEDULE_TIME',
  EMPTY_ROOM_TIME = 'EMPTY_ROOM_TIME',
  EMPTY_COMMENTS = 'EMPTY_COMMENTS',
  EMPTY_COMMENTS_TEXT = 'EMPTY_COMMENTS_TEXT',
  EMPTY_DO_COMMENTS = 'EMPTY_DO_COMMENTS',
  EMPTY_DO_COMMENTS_TEXT = 'EMPTY_DO_COMMENTS_TEXT',
  EMPTY_HELP = 'EMPTY_HELP',
  EMPTY_HELP_CONTACT = 'EMPTY_HELP_CONTACT',
  EMPTY_SUGGESTIONS = 'EMPTY_SUGGESTIONS',
  EMPTY_BLOCK_1 = 'EMPTY_BLOCK_1',
  EMPTY_BLOCK_2 = 'EMPTY_BLOCK_2',
  EMPTY_BUYED_ITEMS = 'EMPTY_BUYED_ITEMS',
  EMPTY_DASH_BLOCK = 'EMPTY_DASH_BLOCK',
  EMPTY_DASH_MEDIA_BUYED = 'EMPTY_DASH_MEDIA_BUYED',
  EMPTY_DASH_MEDIA_LIKED = 'EMPTY_DASH_MEDIA_LIKED',
  EMPTY_DASH_PICTURE = 'EMPTY_DASH_PICTURE',
  EMPTY_DASH_VIDEO = 'EMPTY_DASH_VIDEO',
  EMPTY_DASH_COMMENT = 'EMPTY_DASH_COMMENT',
  EMPTY_DASH_FAN_CLUB = 'EMPTY_DASH_FAN_CLUB',
  EMPTY_DASH_PAYMENT = 'EMPTY_DASH_PAYMENT',
  EMPTY_DASH_UPDATES = 'EMPTY_DASH_UPDATES',
  EMPTY_LIVE_TOPIC = 'EMPTY_LIVE_TOPIC',
  EMPTY_CONVERSATIONS_1 = 'EMPTY_CONVERSATIONS_1',
  EMPTY_CONVERSATIONS_2 = 'EMPTY_CONVERSATIONS_2',
};


export enum EMessagesErrosTranslations {
  ERROR = 'ERROR',
  ERROR_OOPS = 'ERROR_OOPS',
  ERROR_SORRY = 'ERROR_SORRY',
  ERROR_ACCESS = 'ERROR_ACCESS',
  ERROR_ACCESS_UNAVAILABLE = 'ERROR_ACCESS_UNAVAILABLE',
  ERROR_ACCESS_MAINTENANCE = 'ERROR_ACCESS_MAINTENANCE',
  ERROR_PAY = 'ERROR_PAY',
  ERROR_PAY_APP = 'ERROR_PAY_APP',
  ERROR_CHECKIN_ASK = 'ERROR_CHECKIN_ASK',
  ERROR_CHECKIN_CANCEL = 'ERROR_CHECKIN_CANCEL',
  ERROR_HAD = 'ERROR_HAD',
  ERROR_SEARCH = 'ERROR_SEARCH',
  TRY_AGAIN = 'TRY_AGAIN',
  ACCESS_DENIED = 'ACCESS_DENIED',
  PASSWORD_WRONG = 'PASSWORD_WRONG',
  ERROR_PARAMS_INVALID = 'ERROR_PARAMS_INVALID',
  ERROR_COMPANY_CREATE = 'ERROR_COMPANY_CREATE',
  ERROR_USER_CREATE = 'ERROR_USER_CREATE',
  ERROR_DATE = 'ERROR_DATE',
  ERROR_NO_DATA_FOUND = 'ERROR_NO_DATA_FOUND',
  ERROR_EMPTY_FIELDS = 'ERROR_EMPTY_FIELDS',
  ERROR_ACCOUNT_TRANSFER = 'ERROR_ACCOUNT_TRANSFER',
  ERROR_ACCOUNT_SEARCH = 'ERROR_ACCOUNT_SEARCH',
  ERROR_ACCOUNT_MAIN_CHANGE = 'ERROR_ACCOUNT_MAIN_CHANGE',
  ERROR_ACCOUNT_DELETE = 'ERROR_ACCOUNT_DELETE',
  ERROR_SAVE = 'ERROR_SAVE',
  ERROR_CREATE = 'ERROR_CREATE',
  ERROR_UPDATE = 'ERROR_UPDATE',
  ERROR_USER_DISABLE = 'ERROR_USER_DISABLE',
  ERROR_COMPANY_DISABLE = 'ERROR_COMPANY_DISABLE',
  ERROR_MAKE_REGISTER = 'ERROR_MAKE_REGISTER',
  ERROR_PAYMENT_MAIN_CHOOSED = 'ERROR_PAYMENT_MAIN_CHOOSED',
  ERROR_PAYMENT_DELETE = 'ERROR_PAYMENT_DELETE',
  ERROR_ADDRESS_DELETE = 'ERROR_ADDRESS_DELETE',
  ERROR_LIST_USER_ADDED = 'ERROR_LIST_USER_ADDED',
  ERROR_LIST_ENTER = 'ERROR_LIST_ENTER',
  ERROR_LIST_GET_DATA = 'ERROR_LIST_GET_DATA',
  ERROR_GET_DATA = 'ERROR_GET_DATA',
  ERROR_TICKET_UNAVAILABLES = 'ERROR_TICKET_UNAVAILABLES',
  ERROR_LISTS_UNAVAILABLES = 'ERROR_LISTS_UNAVAILABLES',
  ERROR_USER_DOESNOT_EXIST = 'ERROR_USER_DOESNOT_EXIST',
  ERROR_USER_IN_LIST = 'ERROR_USER_IN_LIST',
  ERROR_LIKE_ADD = 'ERROR_LIKE_ADD',
  ERROR_LIKE_REMOVE = 'ERROR_LIKE_REMOVE',
  ERROR_FAVORITE_ADD = 'ERROR_FAVORITE_ADD',
  ERROR_FAVORITE_REMOVE = 'ERROR_FAVORITE_REMOVE',
  ERROR_COMMENT_CREATED = 'ERROR_COMMENT_CREATED',
  ERROR_COMMENT_EDITED = 'ERROR_COMMENT_EDITED',
  ERROR_COMMENT_DELETED = 'ERROR_COMMENT_DELETED',
  ERROR_CHANGE_PROFILE = 'ERROR_CHANGE_PROFILE',
  ERROR_VIDEO_PLAY = 'ERROR_VIDEO_PLAY',
  ERROR_404 = 'ERROR_404',
};


export enum EMessagesHelpsTranslations {
  HELP_INPUT_REQUIRED = 'HELP_INPUT_REQUIRED',
  HELP_SOME = 'HELP_SOME',
  HELP_HAVE_DOUBTS = 'HELP_HAVE_DOUBTS',
  HELP_TALK_TO_US = 'HELP_TALK_TO_US',
  HELP_LOGIN_TO_SEE = 'HELP_LOGIN_TO_SEE',
  HELP_TO_COMMENT = 'HELP_TO_COMMENT',
  HELP_COMMENT_ONLY_USER = 'HELP_COMMENT_ONLY_USER',
  HELP_INFO_HELPED = 'HELP_INFO_HELPED',
  HELP_FAQ_TEXT = 'HELP_FAQ_TEXT',
  HELP_COMMON_QUESTIONS = 'HELP_COMMON_QUESTIONS',
  HELP_CATEGORIES = 'HELP_CATEGORIES',
};


export enum EMessagesQuestionsTranslations {
  ANY_QUESTION = 'ANY_QUESTION',
  ANY_QUESTION_CONTACT = 'ANY_QUESTION_CONTACT',
  PAY_ATTENTION = 'PAY_ATTENTION',
  QUESTION_DELETE_CARD = 'QUESTION_DELETE_CARD',
  QUESTION_DELETE_CARD_TEXT = 'QUESTION_DELETE_CARD_TEXT',
  QUESTION_ORDERSHEET_CLOSE = 'QUESTION_ORDERSHEET_CLOSE',
  QUESTION_ADD_CREDIT_CARD = 'QUESTION_ADD_CREDIT_CARD',
  QUESTION_ACCOUNT_MAIN_CHANGE = 'QUESTION_ACCOUNT_MAIN_CHANGE',
  QUESTION_ACCOUNT_MAIN_CHANGE_TEXT = 'QUESTION_ACCOUNT_MAIN_CHANGE_TEXT',
  QUESTION_ACCOUNT_DELETE = 'QUESTION_ACCOUNT_DELETE',
  QUESTION_ACCOUNT_DELETE_TEXT = 'QUESTION_ACCOUNT_DELETE_TEXT',
  QUESTION_USER_DISABLE = 'QUESTION_USER_DISABLE',
  QUESTION_COMPANY_DISABLE = 'QUESTION_COMPANY_DISABLE',
  QUESTION_ADDRESS_REGISTER_TEXT = 'QUESTION_ADDRESS_REGISTER_TEXT',
  QUESTION_ADDRESS_DELETE = 'QUESTION_ADDRESS_DELETE',
  QUESTION_ADDRESS_DELETE_TEXT = 'QUESTION_ADDRESS_DELETE_TEXT',
  QUESTION_BAG_ITEM_REMOVE = 'QUESTION_BAG_ITEM_REMOVE',
  QUESTION_BAG_CLEAR = 'QUESTION_BAG_CLEAR',
  QUESTION_EXIT = 'QUESTION_EXIT',
  QUESTION_FAVORITE_REMOVE = 'QUESTION_FAVORITE_REMOVE',
  QUESTION_COMMENT_DELETED = 'QUESTION_COMMENT_DELETED',
  QUESTION_COMMENT_HIDE = 'QUESTION_COMMENT_HIDE',
  QUESTION_PROFILE_HIDE = 'QUESTION_PROFILE_HIDE',
};


export enum EMessagesSuccessTranslations {
  SUCCESS = 'SUCCESS',
  SUCCESS_CHECKIN_ASK = 'SUCCESS_CHECKIN_ASK',
  SUCCESS_CHECKIN_CANCEL = 'SUCCESS_CHECKIN_CANCEL',
  SUCCESS_ADD_ITEM_BAG = 'SUCCESS_ADD_ITEM_BAG',
  SUCCESS_ORDER_DONE = 'SUCCESS_ORDER_DONE',
  SUCCESS_PAYED = 'SUCCESS_PAYED',
  SUCEESS_COMPANY_CREATE = 'SUCEESS_COMPANY_CREATE',
  SUCEESS_USER_CREATE = 'SUCEESS_USER_CREATE',
  SUCEESS_USER_CREATE_TEXT = 'SUCEESS_USER_CREATE_TEXT',
  SUCCESS_ACCOUNT_TRANSFER = 'SUCCESS_ACCOUNT_TRANSFER',
  SUCCESS_ACCOUNT_MAIN_CHANGE = 'SUCCESS_ACCOUNT_MAIN_CHANGE',
  SUCCESS_ACCOUNT_DELETE = 'SUCCESS_ACCOUNT_DELETE',
  SUCCESS_CREATE = 'SUCCESS_CREATE',
  SUCCESS_UPDATE = 'SUCCESS_UPDATE',
  SUCCESS_USER_DISABLE = 'SUCCESS_USER_DISABLE',
  SUCCESS_COMPANY_DISABLE = 'SUCCESS_COMPANY_DISABLE',
  SUCCESS_PASSWORD_RECOVER = 'SUCCESS_PASSWORD_RECOVER',
  SUCCESS_PASSWORD_RECOVER_TEXT = 'SUCCESS_PASSWORD_RECOVER_TEXT',
  SUCCESS_CARD_REGISTER = 'SUCCESS_CARD_REGISTER',
  SUCCESS_PAYMENT_MAIN_CHOOSED = 'SUCCESS_PAYMENT_MAIN_CHOOSED',
  SUCCESS_PAYMENT_DELETE = 'SUCCESS_PAYMENT_DELETE',
  SUCCESS_ADDRESS_DELETE = 'SUCCESS_ADDRESS_DELETE',
  SUCCESS_EMAIL_CHANGE = 'SUCCESS_EMAIL_CHANGE',
  SUCCESS_LIST_USER_ADDED = 'SUCCESS_LIST_USER_ADDED',
  SUCCESS_PASSWORD_COPY = 'SUCCESS_PASSWORD_COPY',
  SUCCESS_USER_ADDED = 'SUCCESS_USER_ADDED',
  SUCCESS_LIKE_ADD = 'SUCCESS_LIKE_ADD',
  SUCCESS_LIKE_REMOVE = 'SUCCESS_LIKE_REMOVE',
  SUCCESS_FAVORITE_ADD = 'SUCCESS_FAVORITE_ADD',
  SUCCESS_FAVORITE_REMOVE = 'SUCCESS_FAVORITE_REMOVE',
  SUCCESS_COPIED_ADDRESS = 'SUCCESS_COPIED_ADDRESS',
  SUCCESS_COPIED_NUMBER = 'SUCCESS_COPIED_NUMBER',
  SUCCESS_COPIED_LINK = 'SUCCESS_COPIED_LINK',
  SUCCESS_COMMENT_CREATED = 'SUCCESS_COMMENT_CREATED',
  SUCCESS_COMMENT_EDITED = 'SUCCESS_COMMENT_EDITED',
  SUCCESS_COMMENT_DELETED = 'SUCCESS_COMMENT_DELETED',
  SUCCESS_CHANGE_PROFILE = 'SUCCESS_CHANGE_PROFILE',
  SUCCESS_SAVE = 'SUCCESS_SAVE',
};


export enum EMessagesWarningTranslations {
  ATTENTION = 'ATTENTION',
  ATTENTION_TEXT_1 = 'ATTENTION_TEXT_1',
  ATTENTION_TEXT_2 = 'ATTENTION_TEXT_2',
  ATTENTION_CONTACT = 'ATTENTION_CONTACT',
  ATTENTION_NOTICE = 'ATTENTION_NOTICE',
  ATTENTION_TEXT_3 = 'ATTENTION_TEXT_3',
  ATTENTION_TEXT_4 = 'ATTENTION_TEXT_4',
};


export enum EPaymentsTranslations {
  BANK = 'BANK',
  AGENCY = 'AGENCY',
  CARD_SELECTED = 'CARD_SELECTED',
  DEBIT_CARD = 'DEBIT_CARD',
  CREDIT_CARD = 'CREDIT_CARD',
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
  CASH = 'CASH',
  BANK_CHECK = 'BANK_CHECK',
  PIX = 'PIX',
  ELO = 'ELO',
  MASTERCARD = 'MASTERCARD',
  VISA = 'VISA',
  AMERICAN = 'AMERICAN',
  DINERS = 'DINERS',
  HIPERCARD = 'HIPERCARD',
  CHANGE = 'CHANGE',
  DISCOUNT = 'DISCOUNT',
  TIP = 'TIP',
  TOTAL_PAY = 'TOTAL_PAY',
  TOTAL = 'TOTAL',
  SUBTOTAL = 'SUBTOTAL',
  TAXES = 'TAXES',
  WAITING_PAYMENT = 'WAITING_PAYMENT',
  ALL_BANKS = 'ALL_BANKS',
  ACCOUNT_CURRENT = 'ACCOUNT_CURRENT',
  ACCOUNT_SAVING = 'ACCOUNT_SAVING',
  ACCOUNT_NICKNAME = 'ACCOUNT_NICKNAME',
  ACCOUNT_BELONGING = 'ACCOUNT_BELONGING',
  ACCOUNT_CURRENT_SAVING_SAME_NUMBER = 'ACCOUNT_CURRENT_SAVING_SAME_NUMBER',
  CARD_ACCEPTED = 'CARD_ACCEPTED',
  PAYMENT_FORM = 'PAYMENT_FORM',
  PAYMENT_AND_VALUES = 'PAYMENT_AND_VALUES',
  PAYMENT_HISTORY = 'PAYMENT_HISTORY',
  CARD_NUMBER = 'CARD_NUMBER',
  DATE_VALID_UNTIL = 'DATE_VALID_UNTIL',
  PLAN = 'PLAN',
  PLAN_CHARGING = 'PLAN_CHARGING',
  PLAN_PAY_EXEPTION = 'PLAN_PAY_EXEPTION',
  CARD = 'CARD',
  CARDS = 'CARDS',
  CARD_CVV = 'CARD_CVV',
  CARD_VALIDITY = 'CARD_VALIDITY',
  CARD_CURRENCY = 'CARD_CURRENCY',
  CARD_DEFINE_CURRENCY = 'CARD_DEFINE_CURRENCY',
  CARD_DELETE = 'CARD_DELETE',
  CARD_CHOOSE_MAIN = 'CARD_CHOOSE_MAIN',
  CARD_CHOOSE_DEBIT_TOO = 'CARD_CHOOSE_DEBIT_TOO',
  CARD_CHOOSE_CREDIT_TOO = 'CARD_CHOOSE_CREDIT_TOO',
  GIFTS = 'GIFTS',
  CREDITS = 'CREDITS',
  CREDITS_AVAILABLE = 'CREDITS_AVAILABLE',
  CREDITS_BUY_AVAILABLE = 'CREDITS_BUY_AVAILABLE',
  FINANCIAL = 'FINANCIAL',
  PROFILE_PLAN_UPGRADE = 'PROFILE_PLAN_UPGRADE',
  PLAN_MANAGE = 'PLAN_MANAGE',
};


export enum ESocialsTranslations {
  RESUME = 'RESUME',
  DATA_PRINCIPAL = 'DATA_PRINCIPAL',
  DATA_PRINCIPAL_EDIT = 'DATA_PRINCIPAL_EDIT',
  SOCIAL_MEDIA = 'SOCIAL_MEDIA',
  WHATSAPP = 'WHATSAPP',
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  TWITTER = 'TWITTER',
  YOUTUBE = 'YOUTUBE',
  LINKEDIN = 'LINKEDIN',
  TIKTOK = 'TIKTOK',
  TELEGRAM = 'TELEGRAM',
  CONTACT_US = 'CONTACT_US',
  CONTACT_US_PROFILE_PUBLISH = 'CONTACT_US_PROFILE_PUBLISH',
  SEND_MESSAGE = 'SEND_MESSAGE',
  DONT_CONFIRM_PROFILE_TITLE = 'DONT_CONFIRM_PROFILE_TITLE',
  DONT_CONFIRM_PROFILE_TEXT = 'DONT_CONFIRM_PROFILE_TEXT',
};


export enum ETimeTranslations {
  NOW = 'NOW',
  DATE = 'DATE',
  HOUR = 'HOUR',
  HOURS = 'HOURS',
  DATE_HOUR = 'DATE_HOUR',
  DATES_AVAILABLE = 'DATES_AVAILABLE',
  OPENED_UNTIL = 'OPENED_UNTIL',
  OPENEDS = 'OPENEDS',
  CLOSE_IN = 'CLOSE_IN',
  CLOSED = 'CLOSED',
  CLOSEDS = 'CLOSEDS',
  TODAY = 'TODAY',
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  NEXT = 'NEXT',
  PAST = 'PAST',
  UPDATED_AT = 'UPDATED_AT',
  TIMEZONE_LOCATION = 'TIMEZONE_LOCATION',
  MINUTES = 'MINUTES',
  OVERNIGHT_STAY = 'OVERNIGHT_STAY',
  DAILY = 'DAILY',
  DAILY_TRAVEL = 'DAILY_TRAVEL',
  WEEKLY = 'WEEKLY',
  WEEKLY_TRAVEL = 'WEEKLY_TRAVEL',
  MONTHLY = 'MONTHLY',
  MONTHLY_TRAVEL = 'MONTHLY_TRAVEL',
};


export enum ETitlesTranslations {
  HOME = 'HOME',
  FAVORITES = 'FAVORITES',
  FAVORITEDS = 'FAVORITEDS',
  COLLECTIONS = 'COLLECTIONS',
  ESCORT = 'ESCORT',
  ESCORTS = 'ESCORTS',
  COMMENT_DO = 'COMMENT_DO',
  COMMENT_EDIT = 'COMMENT_EDIT',
  COMMENTED_AT = 'COMMENTED_AT',
  COMMENTED_BY = 'COMMENTED_BY',
  ALL_MODELES = 'ALL_MODELES',
  EXIT = 'EXIT',
  UNDERSTAND_ADS = 'UNDERSTAND_ADS',
  UNDERSTAND_ROOMS = 'UNDERSTAND_ROOMS',
  FORGOT_PASSWORD_QUESTION = 'FORGOT_PASSWORD_QUESTION',
  RECOVER_PASSWORD = 'RECOVER_PASSWORD',
  REGISTER = 'REGISTER',
  REGISTER_FREE = 'REGISTER_FREE',
  ACCESS = 'ACCESS',
  CHECKIN = 'CHECKIN',
  CHECKOUT = 'CHECKOUT',
  CHECKIN_WAIT = 'CHECKIN_WAIT',
  QR_READER = 'QR_READER',
  QR_MY_CODE = 'QR_MY_CODE',
  GALLERY = 'GALLERY',
  COMPANY_DETAILS = 'COMPANY_DETAILS',
  ALL_EVENTS = 'ALL_EVENTS',
  LISTS = 'LISTS',
  LISTS_EVENT = 'LISTS_EVENT',
  LISTS_ALL = 'LISTS_ALL',
  LISTS_AVAILABLES = 'LISTS_AVAILABLES',
  LISTS_SPECIALS = 'LISTS_SPECIALS',
  MENUSHEET = 'MENUSHEET',
  HIGHLIGHTS = 'HIGHLIGHTS',
  MORE_PROFILES = 'MORE_PROFILES',
  PROFILES_SUGGESTED = 'PROFILES_SUGGESTED',
  PARTNER = 'PARTNER',
  PARTNERS = 'PARTNERS',
  PARTNERS_HIGHLIGHTS = 'PARTNERS_HIGHLIGHTS',
  EVENTS = 'EVENTS',
  EVENTS_HIGHTLIGHTS = 'EVENTS_HIGHTLIGHTS',
  EVENTS_MORE = 'EVENTS_MORE',
  EVENTS_STREET = 'EVENTS_STREET',
  MAP = 'MAP',
  MAPS = 'MAPS',
  NOTIFICATIONS = 'NOTIFICATIONS',
  NOTIFICATIONS_CONFIG = 'NOTIFICATIONS_CONFIG',
  ORDERSHEET = 'ORDERSHEET',
  ORDERSHEET_PAY = 'ORDERSHEET_PAY',
  PAYMENT_OPTIONS = 'PAYMENT_OPTIONS',
  ORDERBAG = 'ORDERBAG',
  HISTORIC = 'HISTORIC',
  COMPANY_NEW = 'COMPANY_NEW',
  COMPANY_REGISTER = 'COMPANY_REGISTER',
  BANKS = 'BANKS',
  MODAL_ADD_USER = 'MODAL_ADD_USER',
  ACCOUNT_TRANSFER = 'ACCOUNT_TRANSFER',
  ACCOUNT_TRANSFER_ADD = 'ACCOUNT_TRANSFER_ADD',
  COMPANY_ADDRESS_EDIT = 'COMPANY_ADDRESS_EDIT',
  CONTACT = 'CONTACT',
  COMPANY_EDIT = 'COMPANY_EDIT',
  COMPANY_DATAS = 'COMPANY_DATAS',
  PROFILE_PICTURE = 'PROFILE_PICTURE',
  LOGOTIPO = 'LOGOTIPO',
  LOGOTIPO_COMPANY = 'LOGOTIPO_COMPANY',
  ADDRESS = 'ADDRESS',
  ADDRESS_CREATE = 'ADDRESS_CREATE',
  ADDRESS_EDIT = 'ADDRESS_EDIT',
  ADDRESSES = 'ADDRESSES',
  USER_DISABLE = 'USER_DISABLE',
  COMPANY_DISABLE = 'COMPANY_DISABLE',
  REDEFINE_PASSWORD = 'REDEFINE_PASSWORD',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  PREFERENCES = 'PREFERENCES',
  SETTINGS = 'SETTINGS',
  TOOLS = 'TOOLS',
  LANGUAGE = 'LANGUAGE',
  CARD_ADD = 'CARD_ADD',
  ADDRESS_REGISTER = 'ADDRESS_REGISTER',
  PAYMENTS_SUBSCRIPTIONS = 'PAYMENTS_SUBSCRIPTIONS',
  PROFILE_MY_ABOUT = 'PROFILE_MY_ABOUT',
  PROFILE_DETAILS = 'PROFILE_DETAILS',
  PROFILE_INFORMATIOS = 'PROFILE_INFORMATIOS',
  PROFILE_PUBLIC = 'PROFILE_PUBLIC',
  PROFILE_VISIBLE = 'PROFILE_VISIBLE',
  PROFILE_INVISIBLE = 'PROFILE_INVISIBLE',
  PROFILE_MAIN = 'PROFILE_MAIN',
  PERMISSIONS_GROUP = 'PERMISSIONS_GROUP',
  INVITE_TO_LIST = 'INVITE_TO_LIST',
  INVITE_PEOPLE = 'INVITE_PEOPLE',
  PARTICIPATE = 'PARTICIPATE',
  LIST_PARTICIPATE = 'LIST_PARTICIPATE',
  DETAILS_ITEM = 'DETAILS_ITEM',
  MY_ORDERS = 'MY_ORDERS',
  RESERVES = 'RESERVES',
  RESERVE_DETAILS = 'RESERVE_DETAILS',
  SEARCH = 'SEARCH',
  OTHER_INFORMATIONS = 'OTHER_INFORMATIONS',
  TERMS_POLICY = 'TERMS_POLICY',
  LICENCES = 'LICENCES',
  KNOW_APP = 'KNOW_APP',
  PROFILE_UNMANAGE = 'PROFILE_UNMANAGE',
  PROFILE_MANAGE = 'PROFILE_MANAGE',
  SHARE = 'SHARE',
  CALENDAR = 'CALENDAR',
  RANKINGS = 'RANKINGS',
  SUGGESTIONS = 'SUGGESTIONS',
  SPONSORS = 'SPONSORS',
  MY_PRIVE = 'MY_PRIVE',
  BE_MODELE = 'BE_MODELE',
  BLOCK_USER = 'BLOCK_USER',
  BUYED_ITEMS = 'BUYED_ITEMS',
  CHARACTERISTICS = 'CHARACTERISTICS',
  DASHBOARD = 'DASHBOARD',
  MEDIAS_LIKED = 'MEDIAS_LIKED',
  COMMENTS_RULES = 'COMMENTS_RULES',
  COMMENTS_DONE = 'COMMENTS_DONE',
  FAN_CLUB_DONE = 'FAN_CLUB_DONE',
  PROFILE_IMAGE = 'PROFILE_IMAGE',
  MESSAGES = 'MESSAGES',
  CONVERSATIONS = 'CONVERSATIONS',
  PANEL_PROFISSIONAL = 'PANEL_PROFISSIONAL',
  PROFILE_PROFISSIONAL_DATA = 'PROFILE_PROFISSIONAL_DATA',
  RENT_PROFILE = 'RENT_PROFILE',
  ANTI_FAKE_VERIFY = 'ANTI_FAKE_VERIFY',
  GALLERY_PRIVATE = 'GALLERY_PRIVATE',
  VISIBILITY_PRIVACY = 'VISIBILITY_PRIVACY',
  TRY_OTHER_MORE = 'TRY_OTHER_MORE',
  PROFILE_BROWSE = 'PROFILE_BROWSE',
};


export enum EServicesTranslations {
  SERVICES = 'SERVICES',
  PARTNER_SERVICES = 'PARTNER_SERVICES',
  PARTNER_SERVICES_NOT = 'PARTNER_SERVICES_NOT',
  ACTIVATE_SERVICES = 'ACTIVATE_SERVICES',
  RENT_ON = 'RENT_ON',
  RENT_OFF = 'RENT_OFF',
  FAN_CLUB_ON = 'FAN_CLUB_ON',
  FAN_CLUB_OFF = 'FAN_CLUB_OFF',
  LIVE_ON = 'LIVE_ON',
  LIVE_OFF = 'LIVE_OFF',
  MASSAGE_ON = 'MASSAGE_ON',
  MASSAGE_OFF = 'MASSAGE_OFF',
  STREAM_ON = 'STREAM_ON',
  STREAM_OFF = 'STREAM_OFF',
  CONTACTS_AVAILABILITY = 'CONTACTS_AVAILABILITY',
  LINKBIO = 'LINKBIO',
};


export enum ESubtitlesTranslations {
  SUBTITLES_SETTINGS = 'SUBTITLES_SETTINGS',
  SUBTITLES_CARDS = 'SUBTITLES_CARDS',
  SUBTITLES_PROFILE_PUBLIC = 'SUBTITLES_PROFILE_PUBLIC',
  SUBTITLES_HIGHLIGHT_MAP = 'SUBTITLES_HIGHLIGHT_MAP',
  SUBTITLES_HIGHLIGHT_MAP_EXPERIENCE = 'SUBTITLES_HIGHLIGHT_MAP_EXPERIENCE',
  SUBTITLES_CHARACTERISTICS = 'SUBTITLES_CHARACTERISTICS',
  SUBTITLES_STYLE = 'SUBTITLES_STYLE',
  SUBTITLES_HEALTH = 'SUBTITLES_HEALTH',
  SUBTITLES_SPOKEN_LANGS = 'SUBTITLES_SPOKEN_LANGS',
  SUBTITLES_TRIBES = 'SUBTITLES_TRIBES',
  SUBTITLES_IM_IN = 'SUBTITLES_IM_IN',
};


export enum EValidationsTranslations {
  ONLY_NUMBERS = 'ONLY_NUMBERS',
  VALIDATE_MIN_DIGITS = 'VALIDATE_MIN_DIGITS',
  VALIDATE_MAX_DIGITS = 'VALIDATE_MAX_DIGITS',
  VALIDATE_EXACT_DIGITS = 'VALIDATE_EXACT_DIGITS',
  VALIDATE_MIN_CHAR = 'VALIDATE_MIN_CHAR',
  VALIDATE_MAX_CHAR = 'VALIDATE_MAX_CHAR',
  VALIDATE_EXACT_CHAR = 'VALIDATE_EXACT_CHAR',
  VALIDATE_ALL_FIELDS = 'VALIDATE_ALL_FIELDS',
  VALIDATE_ALL_SELECTED = 'VALIDATE_ALL_SELECTED',
  VALIDATE_HAS_USER = 'VALIDATE_HAS_USER',
  VALIDATE_ONLY_ONE = 'VALIDATE_ONLY_ONE',
  VALIDATE_ONE_MORE = 'VALIDATE_ONE_MORE',
  VALIDATE_EMAIL_REQUIRED = 'VALIDATE_EMAIL_REQUIRED',
  VALIDATE_EMAIL_VALID = 'VALIDATE_EMAIL_VALID',
  VALIDATE_EMAIL_DOMAIN = 'VALIDATE_EMAIL_DOMAIN',
  VALIDATE_PASSWORD_REQUIRED = 'VALIDATE_PASSWORD_REQUIRED',
  VALIDATE_ID_SHORT_REQUIRED = 'VALIDATE_ID_SHORT_REQUIRED',
  VALIDATE_CPF_VALID = 'VALIDATE_CPF_VALID',
  VALIDATE_USER_CPF_USERNAME = 'VALIDATE_USER_CPF_USERNAME',
  VALIDATE_USERNAME_CHAR_SPECIAL = 'VALIDATE_USERNAME_CHAR_SPECIAL',
  VALIDATE_USER_REQUIRED = 'VALIDATE_USER_REQUIRED',
  VALIDATE_HAS_PLAN = 'VALIDATE_HAS_PLAN',
  VALIDATE_BLOCKED_PLAN = 'VALIDATE_BLOCKED_PLAN',
  VALIDATE_MODULE_DISABLED = 'VALIDATE_MODULE_DISABLED',
  VALIDATE_ANTI_FAKE_MEDIA = 'VALIDATE_ANTI_FAKE_MEDIA',
};



const TranslateInteface = {
  ...EAppTranslations,
  ...EAppTutorialTranslations,
  ...EButtonsTranslations,
  ...ECountriesTranslations,
  ...EFiltersTranslations,
  ...EInterpolatesTranslations,
  ...ELabelsTranslations,
  ...ELabelsAdsTranslations,
  ...ELabelsBeModelTranslations,
  ...ELabelsCharsTranslations,
  ...ELabelsCommentsTranslations,
  ...ELabelsLiveTranslations,
  ...ELabelsReportsTranslations,
  ...ELanguagesTranslations,
  ...EMediasTranslations,
  ...EMenuOrderTranslations,
  ...EMessagesEmptysTranslations,
  ...EMessagesErrosTranslations,
  ...EMessagesHelpsTranslations,
  ...EMessagesQuestionsTranslations,
  ...EMessagesSuccessTranslations,
  ...EMessagesWarningTranslations,
  ...EPaymentsTranslations,
  ...ESocialsTranslations,
  ...ETimeTranslations,
  ...ETitlesTranslations,
  ...EServicesTranslations,
  ...ESubtitlesTranslations,
  ...EValidationsTranslations,
};



export default TranslateInteface;
