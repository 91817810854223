import React from 'react';

import ButtonAction from '../../../../components/Buttons/ButtonAction';

import {
  IconAds,
} from '../../../../components/Icons';

import {
  Colors,
} from '../../../../shared/constants';

import {
  RentManOfTheDayContainer,
  RentManOfTheDayPreview,
  RentManOfTheDayImageExample,
  RentManOfTheDayInfo,
  RentManDayHeader,
  RentManDayTitle,
  RentManDayrulContees,
  RentManDayrules,
  RentManDayApartirrules,
  RentManDaysize,
  RentMandescription,
} from './styled';



const TabsPersonalBanner: React.FC = () => {
  return (

    <RentManOfTheDayContainer>

      <RentManOfTheDayPreview>

        <RentManOfTheDayImageExample
        />

        {/* <AvrtExampleHeader/>
                
                
                <Avrtuserexample>
                  <Avrtuserfooter>
                    <Avrtuserflabel>RentMan Of The Day</Avrtuserflabel>
                    <Avrtuseisflexel>
                      <span className="jsx-2512053135 username"><span className="jsx-2512053135 text-capitalize">miguelsims</span> • 20</span><span className="jsx-2512053135 location">Sao Paulo, Brazil</span>
                    </Avrtuseisflexel>
                  </Avrtuserfooter>
                </Avrtuserexample> */}

      </RentManOfTheDayPreview>



      <RentManOfTheDayInfo>

        <RentManDayHeader>

          <i className="icon-font icon-calendar-box-star-solid title-icon"></i>

          <RentManDayTitle>
            Personal Banner
          </RentManDayTitle>

        </RentManDayHeader>


        <RentManDayrulContees>

          <RentManDayrules>
            Destaque-se da multidão com um banner criado especialmente para você por nossos designers e exiba seu perfil em todo o mundo na página inicial. Adquira seu banner hoje mesmo!
          </RentManDayrules>

          <RentManDayrules>
            Você também obtém um banner horizontal adicional na página de pesquisa para alcançar um público ainda mais amplo.
          </RentManDayrules>

          <RentManDayrules>
            Você pode escolher as fotos e outras preferências para o seu banner para personalizá-lo exatamente da maneira que deseja.
          </RentManDayrules>

        </RentManDayrulContees>


        <RentManDayApartirrules>
          A partir de
        </RentManDayApartirrules>

        <RentManDaysize>
          US$350.00 por mês
        </RentManDaysize>


        {/* <RentMandescription>
          O pedido mínimo é de 12 horas
        </RentMandescription> */}


        <ButtonAction
          iconLeft={
            <IconAds
              color={Colors.white}
            />
          }
          title={'Selecionar'}
        />

      </RentManOfTheDayInfo>

    </RentManOfTheDayContainer>

  );
};



export default TabsPersonalBanner;
