import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../shared/constants';



interface IProps {
  cursorDefault?: boolean;
}



export const MessageContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  background-color: rgb(16, 26, 32);
  border-radius: ${Sizes.cardRadius}px;
`;


export const MessageLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  background-color: #0a1014;
`;

export const MessageHeaderLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 85px;
  padding: 10px 16px;
  background-color: #111b21;
`;

export const MessageHeaderQuantityText = styled.p`
  margin-bottom: 2px;
  color: ${Colors.white};
  font-size: 15px;
  font-weight: bold;
`;

export const MessageHeaderSearchButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  cursor: ${(props: IProps) => props.cursorDefault ? 'default' : 'pointer'};
`;



export const MessageRightContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  border-left: 1px solid rgba(134,150,160, 0.15);
`;

export const FirstSayChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;


export const FirstChatContent = styled.div`
  
`;

export const FirstChatTitle = styled.h2`
  width: 400px;
  color: ${Colors.white};
  font-size: 56px;
  font-weight: 700;
`;

export const CircleMessageContent = styled.a`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 130px;
  margin: 48px 0 12px;
`;

export const CircleMessageIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  margin-right: 20px;
  margin-left: 8px;
  background-color: ${Colors.white};
  border-radius: 100%;
`;

export const CircleMessageText = styled.div`
  color: ${Colors.white};
`;

export const UserChatHeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  height: 85px;
  padding: 10px 16px;
  background-color: #202c33;
`;

export const UserChatHeaderInfoContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  margin-right: 20px;
`;

export const UserChatHeaderImage = styled.img`
  width: 55px;
  height: 55px;
  margin-right: 20px;
  border-radius: 50%;
  object-fit: cover;
`;

export const ChatInnerInfoContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const ChatInnerUsernameButton = styled.a`
`;

export const ChatInnerUsernameText = styled.p`
  margin-bottom: 4px;
  color: ${Colors.white};
  font-size: 16px;
  font-weight: bold;
`;

export const ChatInnerActivityText = styled.p`
  color: ${Colors.white};
  font-size: 14px;
`;


export const ChatInnerActionButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90px;
  height: 100%;
`;

export const ChatInnerActionIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  margin-bottom: 7px;
`;

export const ChatInnerActionText = styled.p`
  color: ${Colors.white};
  font-size: 12px;
  text-align: center;
`;



export const UserChatMessagesContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
