import React from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  useNavigate,
} from 'react-router-dom';

import Color from 'color';

import {
  IGenderHighlight,
} from '../../../../apis/interfaces/Genders/gender-highlight.interface';

import ButtonAction from '../../../../components/Buttons/ButtonAction';

import {
  Content,
  Screen,
  Title,
} from '../../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../../components/Controls';

import FooterApp from '../../../../components/Footers/FooterApp';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';

import NameRoutes from '../../../../navigation/names';

import {
  RootState,
} from '../../../../redux';

import {
  Colors,
  Images,
  Sizes,
} from '../../../../shared/constants';

import {
  UpgradeDetaislContainer,
  VipHeroContainer,
  VipHeroBackground,
  MainHeader1600,
  VipContentCoinWebView,
  Col6Container,
  ModelPromoImg,
  VipBenefitsView,
  VipBenefitsImage,
  BecomeTitle,
  LevelTitle,
  HeaderSubtitle,
  VipPriceText,
  VipSeeMoreText,
  ButtonVipActionContent,
  VipBonusViewFeatures,
  VipContentCoinMobileView,
  MainContainer1600,
  VipFeatContainer,
  VipBonusFeaturesInfoView,
  VipBonusFeaturesText,
  VipBonusFeaturesColorText,
  BonusVipGrid,
  CardItemGridVipContainer,
  BonusFeatureRowIcon,
  BonusFeatureRowImg,
  BonusFeatureTextView,
  BonusFeatureTitle,
  BonusFeatureSubTitle,
  ButtonViewActionContent,
} from './styled';



const UpgradeUserShowScreen: React.FC = () => {
  const history = useNavigate();


  const genderChoose = useSelector((state: RootState) => state.choose.genderChoose);
  const genderColorChoose = useSelector((state: RootState) => state.choose.genderColorApp);

  const modelesStore = useSelector((state: RootState) => state.modeles.modelesHighlights);
  const findModeleStore = modelesStore?.find((itemState: IGenderHighlight) => itemState?.gender === genderChoose);



  function navigateUserUpgrade() {
    // history.replace(NameRoutes.UpgradeUserDoScreen);
  }


  function renderContentCoinVip() {
    return (

      <VipBenefitsView>

        <VipBenefitsImage
          src={Images.premiumWhiteMemberLogo}
        />


        <BecomeTitle>
          Seja
          {' '}
          <LevelTitle>
            Premium
          </LevelTitle>
        </BecomeTitle>


        <HeaderSubtitle>
          Inscreva-se para tratamento VIP hoje
        </HeaderSubtitle>


        <VipPriceText>
          10 créditos na sua conta ao fazer upgrade
        </VipPriceText>


        <VipSeeMoreText
          onClick={() => {
            history(NameRoutes.UpgradeUserDoScreen);
          }}>
          Saiba mais
        </VipSeeMoreText>


        <ButtonVipActionContent>
          <ButtonAction
            title={'Garantir Premium'}
            height={Sizes.buttonHeight}
            backgroundColor={genderColorChoose}
            onPress={() => {
              navigateUserUpgrade();
            }}
          />
        </ButtonVipActionContent>

      </VipBenefitsView>

    );
  }


  function renderItemGridVip(icon: string, title: string, subtitle: string) {
    const colorLight = Color(genderColorChoose).lighten(0.2).toString();


    return (

      <CardItemGridVipContainer>
        <BonusFeatureRowIcon>
          <BonusFeatureRowImg
            alt={title}
            src={icon}
          />
        </BonusFeatureRowIcon>


        <BonusFeatureTextView>
          <BonusFeatureTitle
            color={colorLight}>
            {title}
          </BonusFeatureTitle>

          <BonusFeatureSubTitle>
            {subtitle}
          </BonusFeatureSubTitle>
        </BonusFeatureTextView>
      </CardItemGridVipContainer>

    );
  }



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        disableGender
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            color={Colors.white}
            size={27}
          />
        }
        centerContent={
          <Title>
            Upgrade
          </Title>
        }
      />



      <Content>

        <UpgradeDetaislContainer>

          <VipHeroContainer
            backgroundColor={genderColorChoose}>

            <VipHeroBackground
              alt={'HeroBackground'}
              src={Images.backgroundUpgrade}
            />


            <MainHeader1600>
              <Col6Container>
                <ModelPromoImg
                  alt={''}
                  src={findModeleStore?.imagePremium}
                />
              </Col6Container>



              <VipContentCoinWebView>
                {renderContentCoinVip()}
              </VipContentCoinWebView>

            </MainHeader1600>
          </VipHeroContainer>



          <VipBonusViewFeatures>

            <VipContentCoinMobileView>
              {renderContentCoinVip()}
            </VipContentCoinMobileView>



            <MainContainer1600>

              <VipFeatContainer>

                <VipBonusFeaturesInfoView>
                  <VipBonusFeaturesText>
                    Obtenha conteúdo exclusivo e ofertas disponíveis apenas para VIPs
                  </VipBonusFeaturesText>
                  {' '}
                  <VipBonusFeaturesColorText
                    color={genderColorChoose}>
                    mais todos os seguintes:
                  </VipBonusFeaturesColorText>
                </VipBonusFeaturesInfoView>


                <BonusVipGrid>

                  {renderItemGridVip(
                    Images.iconBucket,
                    'Alterar cor da fonte',
                    'para ouro ou branco',
                  )}


                  {renderItemGridVip(
                    Images.iconChat,
                    'Participar',
                    'Nos fóruns de modelos',
                  )}


                  {renderItemGridVip(
                    Images.iconDirectorChair,
                    'Pré-visualizações de vídeo gratuitas',
                    'em todos os vídeos',
                  )}


                  {renderItemGridVip(
                    Images.iconGift,
                    'Envie e-mails com dicas e presentes',
                    'Para modelos',
                  )}


                  {renderItemGridVip(
                    Images.iconGroup,
                    'Ganhe créditos GRÁTIS!',
                    'Para clientes de referência',
                  )}


                  {renderItemGridVip(
                    Images.iconUnlimitedChat,
                    'Bate-papo GRATUITO Ilimitado',
                    'E apelidos pessoais',
                  )}


                  {renderItemGridVip(
                    Images.iconSendPhoto,
                    'Enviar e receber fotos',
                    'Diretamente dos modelos',
                  )}


                  {renderItemGridVip(
                    Images.iconShutoff,
                    'Desligue o controle',
                    'Para conversadores cinza nas salas de bate-papo',
                  )}


                  {renderItemGridVip(
                    Images.iconTv,
                    'Tamanhos de tela maiores',
                    'E uso de emoticons',
                  )}


                  {renderItemGridVip(
                    Images.iconGroupShow,
                    'Festas/Grupos',
                    'Programas para vários usuários',
                  )}


                  {renderItemGridVip(
                    Images.iconRate,
                    'Review e avaliações',
                    'Modelos e vídeos',
                  )}


                  {renderItemGridVip(
                    Images.iconRewards,
                    'Ganhe e gaste',
                    'Pontos de jogo de paquera',
                  )}


                  {renderItemGridVip(
                    Images.iconNew,
                    'Ganhe créditos GRÁTIS!',
                    'Para indicar novos artistas',
                  )}

                  {renderItemGridVip(
                    Images.iconCam,
                    'Privado 1 em 1',
                    'Shows de câmera para câmera',
                  )}


                  {renderItemGridVip(
                    Images.iconVipRope,
                    'Acesso ao fã-clube',
                    'Junte-se a vários fã-clubes de modelos',
                  )}


                  {renderItemGridVip(
                    Images.iconNotification,
                    'Receber notificações',
                    'Quando suas modelos favoritas estão online',
                  )}


                  {renderItemGridVip(
                    Images.iconDirector,
                    'Mensagem direta',
                    'Mensagens privadas aprimoradas com nossos modelos',
                  )}


                  {renderItemGridVip(
                    Images.iconFree,
                    'Vídeos gratuitos',
                    '200 vídeos grátis por dia',
                  )}


                  {renderItemGridVip(
                    Images.iconTime,
                    'Tempo livre',
                    'Programas gratuitos de uma hora de duração',
                  )}


                  {renderItemGridVip(
                    Images.iconGift,
                    'Diretor',
                    'Acesso ilimitado a shows gravados',
                  )}


                  {renderItemGridVip(
                    Images.iconVipAccess,
                    'Acesso VIP',
                    'Acesso a promoções apenas VIP',
                  )}


                  {renderItemGridVip(
                    Images.iconDeal,
                    'Cashback',
                    '5% de desconto em todas as compras de vídeo',
                  )}

                </BonusVipGrid>



                <ButtonViewActionContent>
                  <ButtonAction
                    title={'Garantir Premium'}
                    width={220}
                    height={Sizes.buttonHeight}
                    backgroundColor={genderColorChoose}
                    onPress={() => {
                      navigateUserUpgrade();
                    }}
                  />
                </ButtonViewActionContent>

              </VipFeatContainer>

            </MainContainer1600>

          </VipBonusViewFeatures>

        </UpgradeDetaislContainer>


        <FooterApp />

      </Content>

    </Screen>

  );
};



export default UpgradeUserShowScreen;
