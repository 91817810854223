import styled from 'styled-components';

import {
  Sizes,
} from '../../../shared/constants';

import {
  Switch,
} from '../../Composh/web';



export const PublishedContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 3px;
  padding-right: 3px;
`;

export const SwitchStyled = styled(Switch)`
  display: flex;
  min-height: ${Sizes.buttonHeight}px;
`;
