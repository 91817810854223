import React from 'react';

import Color from 'color';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import {
  CreditBuyView,
  PercentFullFree,
  CreditTipImage,
  CreditTipInfoView,
  CreditTipPrice,
  CreditTipFreePrice,
  PercentFullPrice,
  CreditTextPrice,
} from './styled';



export interface IProps {
  packageCoin?: number;
  price?: string;
  onPress?: any;
}



const CreditsBuy: React.FC<IProps> = (props: IProps) => {
  return (

    <CreditBuyView
      backgroundColor={Color(Colors.primary).darken(0.3).toString()}
      onClick={() => {
        if (props.onPress) {
          props.onPress();
        }
      }}>

      <PercentFullFree>

        <CreditTipImage
          src={Images.credit50}
        />


        <CreditTipInfoView>
          <CreditTipPrice>
            {props.packageCoin} moedas
          </CreditTipPrice>

          <CreditTipFreePrice>
            5 moedas grátis
          </CreditTipFreePrice>
        </CreditTipInfoView>

      </PercentFullFree>



      <PercentFullPrice
        backgroundColor={Color(Colors.primary).lighten(0.6).toString()}>

        <CreditTextPrice
          color={Colors.primaryDark}>
          {'$'}
        </CreditTextPrice>


        <CreditTextPrice
          color={Colors.primaryDark}>
          {props.price}
        </CreditTextPrice>

      </PercentFullPrice>

    </CreditBuyView>

  );
};



export default CreditsBuy;
