import styled,
{
  css,
} from 'styled-components';

import {
  Scrolls,
} from '../../components/Composh/web';

import {
  GetPlatform,
} from '../../config/plataform.config';

import {
  Colors,
  Sizes,
} from '../../shared/constants';

import {
  ScrollbarHideStyle,
} from '../../styles/scrolls.layout';



export interface IProps {
  active?: boolean;
  disabled?: boolean;
  widthPercent?: number;
}



export const ViewMediaOverlay = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.menuOverlay};
  z-index: 9998;
`;

export const ViewMediaContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${Colors.black};
`;



export const ViewImageShowAllView = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
`;



export const ViewProgressBarContent = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: ${Colors.cardBackground};
  transition: opacity 0.08s ease 0s;
  opacity: 1;
  z-index: 100;
`;

export const ViewBarProgress = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props: IProps) => props.widthPercent}%;
  height: 5px;
  background-color: ${Colors.primary};
`;



export const ViewToolbarContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 47px;
  padding-top: 3px;
  padding-left: 15px;
  padding-right: 5px;
  background-color: rgba(0, 0, 0, 0.45);
  opacity: 1;
  transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  /* opacity: 0;
  transform: translate3d(0, -10px, 0); */
  z-index: 90;
`;

export const ViewToolbarCountText = styled.p`   
  color: ${Colors.white};
  font-size: 16px;
`;


export const ViewToolbarItemsContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
`;

export const ViewToolbarItemButtom = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 100%;
  margin-left: 8px;
  opacity: ${(props: IProps) => props.disabled ? Sizes.opacityOff : Sizes.opacityOn};
`;



export const ViewImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  flex: 1;
`;

export const ViewToSeeImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const PhotoGridVideo = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const ViewImageControlsStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 35px;
  height: 35px;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 50%;
  z-index: 100;
`;

export const ViewImageControlsLeftButton = styled.a`
  ${ViewImageControlsStyle};
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
`;

export const ViewImageControlsRightButton = styled.a`
  ${ViewImageControlsStyle};
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
`;



export const ViewImageFooterBarView = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding-top: 2px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: rgba(0, 0, 0, 0.45);
  transition: bottom 0.25s ease;
`;


export const ViewImageFooterView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 38px;
  padding-left: 15px;
  padding-right: 5px;
  padding-bottom: 10px;
`;

export const ViewImageFooterTextView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ViewFooterText = styled.p`
  color: ${Colors.white};
  font-size: 16px;
`;



export const ScrollbarHorizontalStyle = css`
  ::-webkit-scrollbar {
    height: 14px;
  }

  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar {
    background: #0D0A0A;
  }

  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px ${Colors.appScrollbar};

    border-color: #0D0A0A;
    border-style: solid;
    border-top-width: 2px;
    border-left-width: 7px;
    border-right-width: 7px;
    border-bottom-width: 5px;
  }
`;

export const ViewFooterGridView = styled(Scrolls.Horizontal)`
  align-items: center;
  background-color: #0D0A0A;
  width: 100%;
  height: 130px;
  padding-left: 3px;
  padding-right: 3px;
  /* transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s; */

  ${GetPlatform() === 'WEB' ? ScrollbarHorizontalStyle : ScrollbarHideStyle}
`;

export const ViewFooterStyle = css`
  border: 2px solid ${Colors.primary};
`;

export const ViewFooterGridImage = styled.a`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  height: 80px;
  margin-left: 3px;
  margin-right: 3px;
  border-radius: 4px;

  ${(props: IProps) => props.active && ViewFooterStyle}
`;

export const ViewFooterImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ViewFooterVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
