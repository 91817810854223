import React from 'react';

import {
  RenderRibbonImage,
} from '../../../config/plan.config';

import {
  Images,
} from '../../../shared/constants';

import {
  EPlansUser,
} from '../../../shared/enums';

import {
  UserImageContainer,
  UserImage,
  RibbonPlanUserImage,
} from './styled';



export interface IProps {
  plan?: EPlansUser;
  image: string;

  width?: number;
  height?: number;
}



const UserAvatar: React.FC<IProps> = (props: IProps) => {
  const planReceived = props.plan as EPlansUser;

  const userImage = props.image === '' || props.image === null || props.image === undefined
    ? Images.avatarUserImage
    : props.image;



  return (

    <UserImageContainer
      width={props.width}
      height={props.height ? props.height : props.width}>

      <UserImage
        width={props.width}
        height={props.height ? props.height : props.width}
        alt={''}
        src={userImage}
      />


      {RenderRibbonImage(planReceived) && (
        <RibbonPlanUserImage
          width={props.width}
          alt={''}
          src={RenderRibbonImage(planReceived)}
        />
      )}

    </UserImageContainer>

  );
};



UserAvatar.defaultProps = {
  width: 80,
};



export default UserAvatar;
