import styled from 'styled-components';

import color from 'color';

import {
  Colors,
  Sizes,
} from '../../../../shared/constants';

import {
  PlatformDevices,
} from '../../../../shared/utils/platforms.utils';



export interface IProps {
  disabled?: boolean;
  backgroundColor?: string;
}



export const MediaGridContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 4px;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 4px;
  aspect-ratio: 1;

  @media ${PlatformDevices.maxTabletM} {
    width: calc(100% / 3);
    min-width: calc(100% / 3);
    max-width: calc(100% / 3);
  }

  @media ${PlatformDevices.minTabletM} {
    width: 25%;
    min-width: 25%;
    max-width: 25%;
  }
  
  @media ${PlatformDevices.minLaptop} {
    width: 20%;
    min-width: 20%;
    max-width: 20%;
  }
  
  @media ${PlatformDevices.minDesktop} {
    width: calc(100% / 6);
    min-width: calc(100% / 6);
    max-width: calc(100% / 6);
  }
`;

export const MediaGridContent = styled.a`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: ${Colors.cardBackground};
`;

export const PhotoGridImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
`;



// Media Closed Styles

export const PhotoGridOverlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
`;



export const PhotoGridIconContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
`;

export const PhotoGridSpecialText = styled.p`
  margin-top: 20px;
  color: #FFFFFF;
  font-size: 25px;
`;



export const PhotoGridFanClubContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const PhotoGridFanClubText = styled.p`
  color: #FFFFFF;
  font-size: 17px;
`;

export const PhotoGridFanClubImagesContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

export const PhotoGridFanClubOrText = styled.p`
  margin-left: 5px;
  margin-right: 5px;
  color: #FFFFFF;
  font-size: 14px;
`;

export const PhotoGridFanClubImage = styled.img`
  height: 20px;
  margin-left: 5px;
  margin-right: 5px;
  object-fit: contain;
`;


export const PhotoGridSpecialCoinContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding: 5px 12px 4px;
  background-color: ${(props: IProps) => props.backgroundColor
    ? color(props.backgroundColor).darken(0.6).toString()
    : Colors.primary};
  border-radius: 3px;
  opacity: ${(props: IProps) => props.disabled ? Sizes.opacityOff : Sizes.opacityOn};
`;

export const PhotoGridSpecialCoinImage = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 4px;
  object-fit: contain;
`;

export const PhotoGridSpecialBadgeText = styled.p`
  margin-left: 5px;
  margin-right: 5px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
`;
