import React from 'react';

// import { Container } from './styles';



const TabMeansCharge: React.FC = () => {
  return <div />;
};



export default TabMeansCharge;
