import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';



export interface IProps {
  fontSize?: number;
}



export const WhatsAppContainerButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 32px;
  max-height: 32px;
  padding-left: 13px;
  padding-right: 13px;
  background-color: #009205;
  border-radius: 50px;
`;

export const WhatsAppButtonText = styled.p`
  width: fit-content;

  color: ${Colors.white};
  font-size: ${(props: IProps) => props.fontSize}px;
  
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  word-break: break-word;
  text-overflow: ellipsis;
`;

export const WhatsAppButtonIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;
