import React from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  openSite,
} from '../../../config/linking.config';

import {
  RootState,
} from '../../../redux';

import {
  ETokenAsync,
} from '../../../shared/enums';

import Vars from '../../../shared/environments/variables';

import {
  getLocalStorageItem,
} from '../../../shared/utils/local-storage.utils';

import {
  PanelValuesContent,
  PanelValuesInfoContent,
  PanelValuesLabel,
  PanelValuesText,
  PanelValuesEditButton,
  PanelValuesEditText,
} from './styled';



const ButtonPanelItem: React.FC = () => {
  const user = useSelector((state: RootState) => state.user.actualUser);


  const token = getLocalStorageItem(ETokenAsync.USER_TOKEN);


  function openExternalSite(link: string) {
    const commonParams = `?token=${token}`;
    openSite(`${link}${commonParams}`);
  }



  return (

    <PanelValuesContent>

      <PanelValuesInfoContent>

        <PanelValuesLabel>
          Alias:
          {' '}
          <PanelValuesText>
            {user?.alias || '-'}
          </PanelValuesText>
        </PanelValuesLabel>


        <PanelValuesLabel>
          Alias de usuário:
          {' '}
          <PanelValuesText>
            @{user?.useralias || '-'}
          </PanelValuesText>
        </PanelValuesLabel>


        <PanelValuesLabel>
          ID do membro:
          {' '}
          <PanelValuesText>
            {user?.clientNumber || '-'}
          </PanelValuesText>
        </PanelValuesLabel>

      </PanelValuesInfoContent>



      <PanelValuesEditButton
        onClick={() => {
          openExternalSite(Vars().globalAccountUser);
        }}>

        <PanelValuesEditText>
          Para editar seus dados de cadastro, clique aqui.
        </PanelValuesEditText>

      </PanelValuesEditButton>

    </PanelValuesContent>

  );
};



export default ButtonPanelItem;
