import React from 'react';

import {
  Colors,
} from '../../../shared/constants';

import {
  ERoomType,
} from '../../../shared/enums';

import {
  Switch,
} from '../../Composh/web';

import {
  TipMenuAnonymousButtonView,
  TipMenuContainer,
  TipMenuDescriptionHeader,
  TipMenuDescriptionHeaderTitle,
  TipMenuHeader,
  TipMenuHeaderText,
  TipMenuHeaderTitle,
  TipMenuListButton,
  TipMenuListButtonText,
  TipMenuListButtonView,
  TipMenuListCoinButton,
  TipMenuListCoinButtonText,
  TipMenuListCoinContent,
  TipMenuListCoinInput,
  TipMenuListCoinInputContent,
  TipMenuListCoinLabel,
  TipMenuListCoinScroll,
  TipMenuListCoinView,
  TipMenuListContent,
  TipMenuListTitle,
  TipMenuListValueContent,
} from './styled';



export interface IProps {
  type: ERoomType.PARTY_QUESTION | ERoomType.PARTY_AUCTION;
}



const PartyTips: React.FC<IProps> = (props) => {
  function renderItemMenu(active: boolean, title: string, coins: number) {
    return (

      <TipMenuListValueContent
        active={active}>
        <TipMenuListTitle>
          {title}
        </TipMenuListTitle>

        <TipMenuListTitle>
          {coins}
        </TipMenuListTitle>
      </TipMenuListValueContent>

    );
  }



  return (

    <TipMenuContainer>

      <TipMenuHeader>
        <TipMenuHeaderTitle>
          {props.type === ERoomType.PARTY_QUESTION ? 'Pergunta' : 'Leilão'}
        </TipMenuHeaderTitle>

        <TipMenuHeaderText>
          O que devo tirar primeiro?
        </TipMenuHeaderText>
      </TipMenuHeader>


      <TipMenuDescriptionHeader>
        <TipMenuDescriptionHeaderTitle>
          {props.type === ERoomType.PARTY_QUESTION ? 'Opções' : 'Atividade'}
        </TipMenuDescriptionHeaderTitle>

        <TipMenuDescriptionHeaderTitle>
          Moedas
        </TipMenuDescriptionHeaderTitle>
      </TipMenuDescriptionHeader>


      <TipMenuListContent>

        {renderItemMenu(
          false,
          'Chupar dedo',
          35,
        )}

        {renderItemMenu(
          true,
          'Felácio',
          70,
        )}

        {renderItemMenu(
          false,
          'Show de óleo',
          85,
        )}

        {renderItemMenu(
          false,
          'Ahegao',
          115,
        )}

        {renderItemMenu(
          false,
          'Dança sem roupa',
          200,
        )}

        {renderItemMenu(
          false,
          'Poledance',
          325,
        )}

        {renderItemMenu(
          false,
          'Finalização',
          555,
        )}
      </TipMenuListContent>


      <TipMenuListCoinContent>
        <TipMenuListCoinScroll>
          {[
            20,
            50,
            100,
            200,
            250,
            500,
          ].map((itemButton: any, index: number) => (
            <TipMenuListCoinButton
              key={index}>
              <TipMenuListCoinButtonText>
                {itemButton}
              </TipMenuListCoinButtonText>
            </TipMenuListCoinButton>
          ))}
        </TipMenuListCoinScroll>
      </TipMenuListCoinContent>


      <TipMenuListCoinView>

        <TipMenuListCoinInputContent>
          <TipMenuListCoinLabel>
            Escolher outra quantia:
          </TipMenuListCoinLabel>

          <TipMenuListCoinInput
            type="number"
            value="20"
            pattern="\d*"
            placeholder="Coins"
            min="0"
          />
        </TipMenuListCoinInputContent>


        <TipMenuListButtonView>

          <TipMenuListButton>
            <TipMenuListButtonText>
              Enviar 20 tk
            </TipMenuListButtonText>
          </TipMenuListButton>


          <TipMenuAnonymousButtonView>
            <Switch
              selected={false}
              size={28}
              color={Colors.white}
              selectedColor={Colors.primary}
              textOn={'Anônimo'}
              textOff={'Anônimo'}
            // onValueChange={toggleSwitch}
            // value={switchValue}
            />
          </TipMenuAnonymousButtonView>

        </TipMenuListButtonView>

      </TipMenuListCoinView>

    </TipMenuContainer>

  );
};



export default PartyTips;
