import {
  EAppTranslations,
  EAppTutorialTranslations,
  EButtonsTranslations,
  ECountriesTranslations,
  EFiltersTranslations,
  EInterpolatesTranslations,
  ELabelsTranslations,
  ELabelsAdsTranslations,
  ELabelsBeModelTranslations,
  ELabelsCharsTranslations,
  ELabelsCommentsTranslations,
  ELabelsLiveTranslations,
  ELabelsReportsTranslations,
  ELanguagesTranslations,
  EMediasTranslations,
  EMenuOrderTranslations,
  EMessagesEmptysTranslations,
  EMessagesErrosTranslations,
  EMessagesHelpsTranslations,
  EMessagesQuestionsTranslations,
  EMessagesSuccessTranslations,
  EMessagesWarningTranslations,
  EPaymentsTranslations,
  ESocialsTranslations,
  ETimeTranslations,
  ETitlesTranslations,
  EServicesTranslations,
  ESubtitlesTranslations,
  EValidationsTranslations,
} from './translates.keys';



export const APP: Record<EAppTranslations, string> = {
  NAME_APP: 'Secretio',
  SHORTNAME_APP: 'Secretio',
  SLOGAN_APP: 'Seu segredo está a salvo!',
  COMPANY_NAME: 'Cartoir',
  COMPANY_APP: 'Um mundo de possibilidades!',
  APP_STREAM: 'Secretio Stream',
  APP_SHOP: 'Secretio Shop',
};


export const APP_TUTORIAL: Record<EAppTutorialTranslations, string> = {
  TUTORIAL_WARNING: 'Ao entrar neste site, concordo que sou maior de 18 anos, li e aceito os',
  TUTORIAL_AGREE: 'Acessando o Secretio você concorda com nossos',
};


export const BUTTONS: Record<EButtonsTranslations, string> = {
  ACTION_APP_ENTER: 'Concordo ao entrar',
  ACTION_ACCESS: 'Acessar',
  ACTION_ACCESS_TO_CONTINUE: 'Entre ou registre-se para continuar',
  ACTION_REGISTER: 'Registrar',
  ACTION_CANCEL: 'Cancelar',
  ACTION_CONFIRM: 'Confirmar',
  ACTION_FORWARD: 'Avançar',
  ACTION_BACK: 'Voltar',
  ACTION_CLOSE: 'Fechar',
  ACTION_CLICK_OPEN: 'Clique para abrir',
  ACTION_CLICK_CLOSE: 'Clique para fechar',
  ACTION_CLICK_CANCEL: ' Clique para cancelar',
  ACTION_MORE_DETAILS: 'Clique para mais detalhes',
  ACTION_NO: 'Não',
  ACTION_YES: 'Sim',
  ACTION_SEE_HISTORY: 'Ver Histórico Completo',
  ACTION_SAVE: 'Salvar',
  ACTION_CHOOSE: 'Clique para escolher',
  ACTION_WRITE: 'Escreva algo',
  ACTION_CARD_ADD: 'Adicionar cartão',
  ACTION_ADDRESS_REGISTER: 'Cadastrar endereço',
  ACTION_CLICK_EDIT: 'Clique para editar',
  ACTION_LOCATION_ENABLED: 'Ativar localização',
  ACTION_COPY_LINK: 'Copiar link',
  ACTION_COPY_PHONE: 'Copiar telefone',
  ACTION_SEARCH: 'Buscar',
  ACTION_RESEARCH: 'Refazer Buscar',
  ACTION_PAY_FORMS_SEE: 'Ver formas de pagamentos',
  ACTION_LOCATION_MY: 'Usar minha Localização Atual',
  ACTION_PROFILE_SEE: 'Ver Perfil',
  ACTION_TO_SEE_PROFILE: 'Clique para ver seu perfil',
  ACTION_SKIP: 'Pular',
  ACTION_PROFILE_MANAGE: 'Clique para solicitar esse perfil',
  ACTION_CHECK_ALL: 'Marcar tudo',
  ACTION_UNCHECK_ALL: 'Desmarcar tudo',
  ACTION_CHOOSE_LOCATION: 'Escolher outro local',
  ACTION_BE_MODEL: 'Quero ser modelo',
  ACTION_LEVEL_CHANGE: 'Mudar destaque',
  ACTION_LEVEL_CHANGE_HIGHLIGHT: 'Quero mudar meu nível de destaque',
  ACTION_LEVEL_SHAKE_ATTENTION: 'Chamar atenção',
  ACTION_LEVEL_UP_HIGHLIGHT: 'Quero aumentar o meu destaque',
  ACTION_COMMENT_MAKE: 'Fazer comentário',
  ACTION_SEE_TRAVELS: 'Veja a programação completa de viagens',
  ACTION_SCHEDULE_TIME: 'Agendar horário',
  ACTION_ROOM_TIME: 'Ver Sala',
  ACTION_SEE_ALL: 'Ver tudo',
  ACTION_SEE_ALL_PHOTOS: 'Ver todas as fotos',
  ACTION_SEE_ALL_VIDEOS: 'Ver todas os vídeos',
  ACTION_SEE_ALL_MODELES: 'Ver todos os modelos',
  ACTION_SEE_ALL_SPONSORS: 'Ver todos os patrocinadores',
  ACTION_SEE_OTHERS_MODELES: 'Veja outros modelos',
  ACTION_CHANGE_LOCATION_TIMEZONE: 'Alterar a localização e o fuso horário',
  ACTION_OPEN_MORE: 'Clique para ver mais',
  ACTION_OPEN_OTHER_MORE: 'Clique para ver outras opções',
  ACTION_SUGGESTIONS_SEE_MORE: 'Ver mais sugestões',
  ACTION_BE_PREMIUM: 'Seja Premium',
  ACTION_SEND_GIFT: 'Enviar Presente',
  ACTION_EDIT: 'Editar',
  ACTION_DELETE: 'Excluir',
  ACTION_SHOW_PROFILE: 'Mostrar no perfil',
  ACTION_HIDE_PROFILE: 'Esconder do perfil',
  ACTION_PUSBLISH_AUTO_ON: 'Publicação automática ativada',
  ACTION_PUSBLISH_AUTO_OFF: 'Publicar automáticamente',
  ACTION_PHOTO_ADD: 'Adicionar imagem',
  ACTION_GO_TO_HOME: 'Ir para o início',
  ACTION_LEARN_MORE: 'Saiba mais',
  ACTION_ADD_FAVORITE: 'Adicionar aos favoritos',
  ACTION_REMOVE_FAVORITE: 'Remover dos favoritos',
  ACTION_ADD_NOTIFY_MODELE_ONLINE: 'Avise-me ao ficar Online',
  ACTION_REMOVE_NOTIFY_MODELE_ONLINE: 'Remover aviso de Online',
  ACTION_BE_YOUR_ESCORT: 'Ser seu acompanhante',
  ACTION_BLOCK: 'Bloquear',
  ACTION_FAN_CLUB_SUBSCRIBE: 'Assinar Fã Clube',
  ACTION_GIFT_ANONYMOUS: 'Presentear anonimamente',
  ACTION_CHAT_DELETE: 'Deletar conversa',
};


export const COUNTRIES: Record<ECountriesTranslations, string> = {
  BRAZIL: 'Brasil',
  PORTUGAL: 'Portugal',
  UNITED_STATES: 'Estados Unidos',
  SPAIN: 'Espanha',
  ITALY: 'Itália',
  FANCE: 'França',
};


export const FILTERS: Record<EFiltersTranslations, string> = {
  FILTERS: 'Filtros',
  FILTER_COCK: 'Mostrar tamanho do pênis',
  FILTER_POSITION: 'Mostrar posição',
  FILTER_WHERE_FROM: 'Mostrar de onde é',
  FILTER_LOCATION: 'Mostrar localização',
};


export const INTERPOLATES: Record<EInterpolatesTranslations, string> = {
  HELLO: 'Olá',
  IN: 'Em',
  FROM: 'De',
  TO: 'Até',
  AT: 'às',
  OR: 'ou',
  AND: 'e',
  THERE_IS: 'Há',
  ACCESS_USER_REGISTER: 'Ao se registrar no Secretio, você aceita os',
  ACCESS_USER_TERMS_USE: 'Termos de Uso',
  ACCESS_USER_TERMS_PRIVACY: 'Políticas de Privacidade',
  ACCESS_USER_TERMS_COOKIES: 'Política de Cookies',
  PRICE_BETWEEN_MIN_MAX: 'Entre {minPrice} e {maxPrice}',
  PRICE_INSTALLMENT_VALUE: 'Em até {installment} vezes no cartão',
  LIVE_UPDATE_ROOM: 'Atualização das salas em {second} segundos',
};


export const LABELS: Record<ELabelsTranslations, string> = {
  ALL: 'Tudo',
  CUSTOM: 'Customizar',
  PUBLIC_OPEN: 'Abertas',
  FAN_CLUB: 'Fã-clube',
  FAN_CLUBS: 'Fã-clubes',
  BUYED: 'Compradas',
  PRIVATES: 'Privadas',
  MODELE: 'Modelo',
  MODELES: 'Modelos',
  PROFILE: 'Perfil',
  PROFILES: 'Perfis',
  PERSONAL_DATA: 'Dados pessoais',
  PERSONAL_INFORMATIONS: 'Informações pessoais',
  COUNTRY: 'País',
  LOADING: 'Carregando',
  USER: 'Usuário',
  NAME: 'Nome',
  NAME_FULL: 'Nome completo',
  DATE_BORN: 'Data de nascimento',
  GENRE: 'Gênero',
  GENRE_MEN: 'Homens',
  GENRE_WOMEN: 'Mulheres',
  GENRE_TRANS_MEN: 'Homens Trans',
  GENRE_TRANS_WOMEN: 'Mulheres Trans',
  GENRE_SINGLE_MAN: 'Homem',
  GENRE_SINGLE_WOMAN: 'Mulher',
  GENRE_SINGLE_TRANS_MAN: 'Homem Trans',
  GENRE_SINGLE_TRANS_WOMAN: 'Mulher Trans',
  GENRE_LIGHT_MEN: 'Garotos',
  GENRE_LIGHT_WOMEN: 'Garotas',
  GENRE_LIGHT_TRANS_MEN: 'Garotos Trans',
  GENRE_LIGHT_TRANS_WOMEN: 'Garotas Trans',
  DDI_NUMBER_FIRST: 'DDI do telefone principal',
  DDI_NUMBER_SECOND: 'DDI do telefone secundário',
  DDI: 'DDI',
  PERSONS: 'Pessoa (s)',
  USERNAME: 'Nome de usuário',
  USERNAME_COMPANY: 'Nome de usuário da empresa',
  EMAIL: 'E-mail',
  EMAIL_REGISTERED: 'E-mail cadastrado',
  PASSWORD: 'Senha',
  CNPJ: 'CNPJ',
  CPF: 'CPF',
  FORMS: 'Formas',
  OPTIONAL: 'Opcional',
  PUBLIC_ID: 'Id público',
  PUBLIC_PARTNER_ID: 'Id público do parceiro',
  NUMBER_PRIMARY: 'Número principal',
  NUMBER_SECONDARY: 'Número secundário',
  PARTNER_ABOUT: 'Sobre o parceiro',
  PARTNER_PHONES: 'Telefones',
  PHONE: 'Telefone',
  PHONE_ONLY_ONE: 'Número com DDD',
  PHONE_FIRST: 'Telefone principal com DDD',
  PHONE_SECOND: 'Telefone secundário com DDD',
  PARTNER_RULES: 'Regras',
  PARTNER_TYPES_KITCHEN: 'Tipos de cozinha',
  PARTNER_OPEN_HOURS: 'Horário de funcionamento',
  WIFI: 'Wi-Fi',
  KIDS_AREA: 'Área Kids',
  PARKING: 'Estacionamento',
  SMOKING: 'Área de Fumantes',
  AIR_CONDITIONER: 'Ar Condicionado',
  ACCESSIBILITY: 'Acessibilidade',
  MORE_POPULAR: 'Mais populares',
  NUMBER_OR_NAME: 'Nome ou número',
  INPUT_MODAL_ADD_USER_PLACEHOLDER: 'Pesquisar nome...',
  CONFIRM_CPF: 'Confirmar CPF do titular',
  CONFIRM_CNPJ: 'Confirmar CNPJ da empresa',
  CONFIRM_INFO: 'Confirmar informação',
  NICKNAME: 'Apelido',
  DESCRIPTION: 'Descrição',
  FAVORED: 'Favorecido(a)',
  ACCOUNT_USE_MAIN: 'Usar esta conta como atual',
  ADDRESS_USE_MAIN: 'Usar este endereço como atual',
  INPUT_MODAL_ADD_USER_CARGO: 'Email ou nome de usuário',
  CODE: 'Código',
  DIGIT: 'Dígito',
  ACCOUNT: 'Conta',
  ID: 'ID',
  ID_MEMBER: 'ID do membro',
  ID_PRIVATE: 'ID Privado',
  ID_PUBLIC: 'ID Público',
  COMPANY_STATUS_APP: 'Situação no Secretio',
  COMPANY_STATUS_ACTIVE: 'Empresa ativa',
  COMPANY_STATUS_INACTIVE: 'Empresa desabilitada',
  PLACE_NAME: 'Nome do local',
  ZIP_CODE: 'CEP',
  STREET: 'Rua', // 'Avenida / Rua',
  STREETS: 'Ruas',
  DISTRICT: 'Bairro',
  DISTRICTS: 'Bairros',
  ATTRACTIONS: 'Atrações',
  COMPLEMENT: 'Complemento',
  CITY: 'Cidade',
  CITIES: 'Cidades',
  STATE: 'Estado',
  STATE_REGION: 'Estado / Região',
  SOCIAL: 'Social',
  SLOGAN: 'Slogan',
  CELLPHONE: 'Celular',
  ABOUT: 'Sobre',
  ABOUT_APP: 'Sobre o Secretio',
  PROFILE_TYPE: 'Tipo de perfil',
  PARTNER_TYPE: 'Tipo do parceiro',
  INFORMATIONS: 'Informações',
  CARD_HOLDER_NAME: 'Nome do Titular',
  CARD_NICKNAME: 'Apelido do Cartão',
  MALE: 'Masculino',
  FEMALE: 'Feminino',
  SOCIAL_NAME: 'Nome social',
  ABOUT_ME: 'Sobre mim',
  LEVEL: 'Nível',
  BIRTHDAY: 'Aniversário',
  USER_PRE_REGISTER: 'Usuário pré-registrado',
  DATAS: 'Dados',
  SHOPS: 'Compras',
  SHOP_APP: 'Shop',
  OPTIONS: 'Opções',
  MORE_OPTIONS: 'Mais opções',
  ME_AND_GUESTS: 'Eu e convidados',
  ONLY_YOU: 'Apenas você',
  ONLY_FOR_ME: 'Apenas para mim',
  PEOPLE: 'pessoas',
  RESERVE_DATE: 'Data da reserva',
  DATE_SELECTED: 'Data selecionada',
  ENVIRONMENT: 'Ambiente',
  PEOPLE_NUMBER: 'Número de pessoas',
  MORE_INFORMATIONS: 'Mais Informações',
  OBSERVATIONS: 'Observações',
  INITIAL_DATE: 'Data do início',
  INITIAL_HOUR: 'Hora do início',
  FINAL_DATE: 'Data do final',
  FINAL_HOUR: 'Hora do final',
  INITIAL_VALUE: 'Valor inicial',
  FINAL_VALUE: 'Valor final',
  PARAMETERS: 'Parâmetros',
  NAME_OR_USERNAME: 'Nome ou Nome de usuário',
  MODALITIES: 'Modalidades',
  VERSION: 'Versão',
  MY_ACCOUNT: 'Minha conta',
  ACCOUNT_SEE: 'Ver conta',
  ACCOUNT_ACTIONS: 'Ações da conta',
  PAYMENTS_FORMS: 'Formas de Pagamento',
  TRAVELS: 'Viagens',
  LOCATION: 'Localização',
  LOCATION_NO_ADDRESS: 'Sem Informações sobre o Endereço',
  TICKETS_SALES_UNTIL: 'Vendas até',
  PARTICIPATION: 'Participação',
  WAIT_YOU: 'Espera por você',
  ITEMS: 'Itens',
  HELP: 'Ajuda',
  TYPE: 'Tipo',
  CANCELED: 'Cancelado',
  FINISHED: 'Finalizado',
  OTHER: 'Outro',
  ADD: 'Adicionar',
  ACTUAL: 'Atual',
  COUNTRIES: 'Países',
  NO: 'Não',
  YES: 'Sim',
  HI: 'Oi',
  LIST_NAME: 'Nome da lista',
  GUIDES_INFORMATIONS: 'Guias e informações',
  PHONES_USEFUL: 'Telefones úteis',
  CASADINHA: 'Casadinha',
  OPEN_BAR: 'Open Bar',
  LIST: 'Lista',
  VIP: 'VIP',
  MEETING: 'Encontro',
  COMMENT_DID_YOU_MEET: 'Onde vocês se conheceram?',
  COMMENT_PERFORMANCE: 'Avalie o desempenho',
  COMMENT_PERFORMANCE_FROM: 'Avalie o desempenho de',
  COMMENT_NOTE: 'Nota',
  COMMENT_NOTE_FROM: 'Nota para',
  COMMENT_EXPERIENCE: 'Descreva sua experiência',
  COMMENT_EXPERIENCE_FROM: 'Descreva sua experiência com',
  COMMENT_DATE_MEET: 'Data do encontro',
  COMMENT_PICTURES: 'As fotos são precisas?',
  COMMENT_RECOMMEND: 'Recomendaria?',
  COMMENT_RECOMMEND_MODELE: 'Recomenda este modelo?',
  LOCATION_MY: 'Localização atual',
  DAYS: 'dias',
  FOLLOWER: 'Seguidor',
  FOLLOWERS: 'Seguidores',
  FOLLOWERS_NONE: 'Sem seguidores',
  PHONE_NONE: 'Sem telefone',
  COMMENT: 'Comentário',
  COMMENTS: 'Comentários',
  UPDATE_PENDENT: 'Atualização pendente',
  UPDATES_PENDENTS: 'Atualizações pendentes',
  ONLINE: 'Online',
  DISCONNECTED: 'Desconectado',
  EXCLUSIVE: 'Exclusivo',
  PORNSTAR: 'Pornstar',
  MORE: 'Mais',
  DETAILS: 'Detalhes',
  TRAVEL: 'Viagem',
  SEX_CONDOM: 'Sexo e Camisinha',
  ASK_ME: 'Me pergunte',
  PICTURE: 'Foto',
  PICTURES: 'Fotos',
  VIDEO: 'Vídeo',
  VIDEOS: 'Vídeos',
  LIVE: 'Live',
  MASSAGE: 'Massagem',
  BLOCK: 'Bloqueio',
  BLOCKS: 'Bloqueios',
  MEDIA: 'Mídia',
  MEDIAS: 'Mídias',
  LIKE: 'Curtir',
  LIKED: 'Curtido',
  LIKED_MEDIA_COUNT: 'Curtida',
  LIKED_MEDIA_COUNTS: 'Curtidas',
  NO_LIKED_MEDIA_COUNT: 'Curtidas',
  GENERAL_INFO: 'Informações gerais',
  STATUS: 'Status',
  CONTENT: 'Conteúdo',
  ADULT: 'Adulto',
};


export const LABELS_ADS: Record<ELabelsAdsTranslations, string> = {
  AD: 'Anúncio',
  ADS: 'Anúncios',
  ADS_FREE: 'Anúncios gratuitos',
  ADS_RENT_FREE_1: 'Anúncios gratuitos são separados por modelos que mostram os rostos ou não. Não há classificação nos anúncios gratuitos.',
  ADS_HIGHLIGHT: 'Anúncios destaques',
  ADS_BENEFITS: 'Vantagens',
  ADS_RENT_HIGHLIGHT_TEXT_1: 'Anúncios destaque estarão sempre no topo, portanto, quem está em destaque é sempre visto primeiro e recebe muito mais cliques em seu anúncio.',
  ADS_RENT_EVIDENCE: 'Evidência',
  ADS_RENT_EVIDENCE_1: 'é a palavra que define o anúncio destaque.',
  ADS_TYPES_HIGHLIGHT: 'Tipos de Destaque',
  ADS_TYPES_HIGHLIGHT_1: 'Contamos com níveis de destaque, que são caracterizados por um naipe de cartas',
  ADS_TYPES_HIGHLIGHT_2: 'Os níveis funcionam assim: Tendo 1',
  ADS_TYPES_HIGHLIGHT_3: 'naipe como o primeiro nível, 2 naipes juntos',
  ADS_TYPES_HIGHLIGHT_4: 'é um nível mais alto que o nivel de naipe 1 e assim por diante.',
  ADS_LOCATION_HIGHLIGHT_1: 'Verifique em qual destaque os modelos da sua cidade estão anunciando para ajudar a decidir em qual vale à pena escolher. Por exemplo, caso os modelos da sua cidade ainda estejam anunciando no DESTAQUE 1, de 1 naipe',
  ADS_LOCATION_HIGHLIGHT_2: 'você pode',
  ADS_LOCATION_HIGHLIGHT_3: 'escolher o DESTAQUE 2',
  ADS_LOCATION_HIGHLIGHT_4: 'e ficar acima dos outros modelos.',
  ADS_RENT_TIME: 'Valores para 30 dias no destaque',
  ADS_RENT_HIGH_NUMBER_1: 'Destaque 1',
  ADS_RENT_HIGH_NUMBER_2: 'Destaque 2',
  ADS_RENT_HIGH_NUMBER_3: 'Destaque 3',
  ADS_RENT_HIGH_NUMBER_4: 'Destaque 4',
  ADS_RENT_HIGH_NUMBER_5: 'Destaque 5',
  ADS_RENT_HIGH_NUMBER_MORE: 'E assim por diante caso haja mais de cinco destaques.',
  ADS_RENT_VALIDITY: 'Validade do Destaque',
  ADS_RENT_VALIDITY_1: 'Em qualquer um dos níveis de Destaque, o período válido é por',
  ADS_RENT_VALIDITY_DAYS_1: '30 dias',
  ADS_RENT_VALIDITY_2: 'e após esse prazo, se o pagamento não for efetivado, o anúncio volta para o nível de GRATUITO e para sua respectiva separação (que mostra o rosto ou não).',
  ADS_RENT_VALIDITY_3: 'Caso você faça uma viagem e registre na sua área de viagens, seu destaque acompanha você a essa cidade. Se você for destaque 5 na sua localização atual, na sua viagem você também será destaque 5.',
  ADS_RENT_HOW_PROCEED: 'Como Proceder?',
  ADS_RENT_HOW_PROCEED_1: 'Para solicitar seu DESTAQUE você deve se cadastrar e escolher o destaque na area do assinante.',
};


export const LABELS_BE_MODELE: Record<ELabelsBeModelTranslations, string> = {
  BE_MODELE_ITEM_1: 'Empresa inovadora',
  BE_MODELE_ITEM_2: 'Compartilhando valores entre todos',
  BE_MODELE_ITEM_3: 'Bloqueio de privacidade = ambiente seguro',
  BE_MODELE_ITEM_4: 'Suporte personalizado apenas para modelos',
  BE_MODELE_ITEM_5: 'Direcionamos tráfego para você',
  BE_MODELE_ITEM_6: 'Concursos, viagens e prêmios',
  SUBTITLES_BE_MODELE_1: 'Seja um super modelo Secretio',
  SUBTITLES_BE_MODELE_2: 'Torne-se um modelo e comece a ganhar dinheiro!',
  SUBTITLES_BE_MODELE_3: 'Só com o melhor!',
  SUBTITLES_BE_MODELE_4: 'Quanto dinheiro posso ganhar?',
  SUBTITLES_BE_MODELE_5: 'Modelos que estão prontos para trabalhar mais horas e se comprometer com a atualização do perfil e utilização das ferramentas provavelmente se tornarão os que ganham mais em nosso site. Quanto mais você dar atenção ao Secretio, maior será seu recebimento e maior será seu potencial de crescimento.',
  LANDING_TITLE_THE: 'A',
  LANDING_TITLE_BEST: 'melhor plataforma',
  LANDING_TITLE_CONTENT: 'de conteúdo adulto.',
  LANDING_DESCRIPTION: 'Respeito, segurança, tecnologia, prazer e descrição em um único lugar.',
  LANDING_SECTION_TITLE_1: 'Se divirta com mais segurança e praticidade',
  LANDING_SECTION_DESCRIPTION_1: 'No Secretio, você encontra os melhores modelos e sem complicações.',
  LANDING_ADULT_JOB: 'Profissões de conteúdo adulto são legalizadas em grande parte do mundo e devem ser respeitadas.',
};


export const LABELS_CHARS: Record<ELabelsCharsTranslations, string> = {
  AGE: 'Idade',
  AGE_SHOW: 'Mostrar idade',
  HEIGHT: 'Altura',
  WEIGHT: 'Peso',
  ETHNICITY: 'Etnia',
  HAIR_SIZE: 'Tamanho do cabelo',
  HAIR_COLOR: 'Cor do cabelo',
  EYE_COLOR: 'Cor dos olhos',
  MANNEQUIN: 'Manequim',
  BUST: 'Busto',
  WAIST: 'Cintura',
  HIP: 'Quadril',
  FEET: 'Pés',
  PENIS: 'Pênis',
  POSITION: 'Posição',
  FORESKIN: 'Prepúcio',
  STYLE: 'Estilo',
  BODY: 'Corpo',
  BODY_HAIR: 'Pêlos corporais',
  ORIENTATION_SEXUAL: 'Orientação sexual',
  ZODIAC: 'Zodíaco',
  SMOKE: 'Fuma',
  DRINK: 'Bebe',
  TATTOOS: 'Tatuagens',
  PIERCING: 'Piercing',
  BREASTS: 'Silicone',
  HEALTH: 'Saúde',
  HIV: 'HIV',
  SPOKEN_LANGS: 'Linguas faladas',
  TRIBES: 'Tribos',
  IM_IN: 'Estou dentro',
  ATTENDANCE: 'Atendimento',
  ATTENDANCE_PLACE: 'Locais de atendimento',
  PLACE_MY: 'Meu local',
  PLACE_YOUR: 'Seu local',
  HOTEL: 'Hotel',
  MOTEL: 'Motel',
  SERVICE_24H: 'Atendimento 24h',
  SERVICE_DONT_DO: 'Não realiza',
  AVAILABLE_FOR: 'Disponível para',
};


export const LABELS_COMMENTS: Record<ELabelsCommentsTranslations, string> = {
  COMMENT_AUTO_TEXT_1: 'Ao ativar essa opção, todos os comentários revisados serão automaticamente mostrados na sua página.',
  COMMENT_AUTO_TEXT_2: 'Apenas comentários visíveis são contabilizados na sua nota',
  COMMENT_RULE_1: 'Você não pode enviar um comentário sobre o mesmo modelo se já tiver enviado uma nos últimos 5 dias.',
  COMMENT_RULE_2: 'Contate o modelo e o conheça antes de fazer um comentário. Levamos muito a sério os comentários feitos na plataforma e não toleramos falsos comentários de nenhum tipo!',
  COMMENT_RULE_3: 'Recomendamos fazer um comentário até no máximo 2 semanas após você ter contato com o modelo.',
  COMMENT_LIVE_INTERPOLATE_1: 'está avaliado em',
  COMMENT_LIVE_INTERPOLATE_2: 'de 5 baseados em',
};


export const LABELS_LIVE: Record<ELabelsLiveTranslations, string> = {
  CAMS: 'Câmeras',
  LIVE_PROMOS: 'Promos',
  LIVE_ROOM: 'Sala',
  LIVE_ROOMS: 'Salas',
  LIVE_ROOMS_ONDEMAND: 'Salas ao vivo',
  LIVE_ROOM_OPENED: 'Sala aberta',
  LIVE_ROOMS_OPENED: 'Salas abertas',
  LIVE_IMAGES: 'Imagens ao vivo',
  LIVE_IMAGE_ROOM: 'Imagem da sala',
  LIVE_SHOW_MODELS_ONLINE: 'Mostrar modelos online',
  LIVE_SHOW_MODELS_PHONE: 'Mostrar modelos pelo celular',
  LIVE_SHOW_MODELS_FLAG: 'Mostrar país dos modelos',
  LIVE_SHOW_MODELS_HIGH_CAM: 'Mostrar modelos com ótima qualidade de câmera',
  LIVE_GRID_SMALL: 'Mostrar modelos em grade pequena',
  LIVE_GRID_MEDIUM: 'Mostrar modelos em grade média',
  LIVE_GRID_LARGE: 'Mostrar modelos em grade grande',
  LIVE_CHAT_EXCLUSIVE: 'Chat Exclusivo',
  LIVE_CHAT_PARTICULAR: 'Chat Particular',
  LIVE_CHAT_PARTY: 'Chat Festa',
  LIVE_GROUP_JOIN: 'Se juntar ao Grupo',
  LIVE_GROUP_START: 'Inicia em:',
  LIVE_GROUP_STARTED: 'Iniciado',
  LIVE_TOPIC: 'Tópico',
};


export const LABELS_REPORTS: Record<ELabelsReportsTranslations, string> = {
  STATISTICS: 'Estatísticas',
  PROFILE_VISITS: 'Visitas de perfil',
  PROFILE_CHANGED_IN: 'Perfil alterado em',
  ACCESS_LAST: 'Último acesso',
  ACCESS_LAST_SHOW: 'Mostrar meu último acesso',
  MEMBER_SINCE: 'Membro desde',
  CHATS_MY: 'Meus chats',
  CHATS_SIMPLE: 'Chat simples',
  CHATS_PRIVATE: 'Chat Privado / Exclusivo',
  SHOW_MY: 'Meu show',
  MY_TRAVEL_LOCATION: 'Minha localização/viagens',
  MY_TRAVEL_LOCATION_SHOW: 'Mostrar minha localização/viagens',
  PROFILE_ON_SEARCH: 'Perfil nas buscas',
  PROFILE_ON_SEARCH_SHOW: 'Mostrar meu perfil nas buscas',
  PROFILE_ON_SEARCH_TEXT: 'Esse parametro faz com que seu perfil seja mostrado ou não nos resultados de pesquisas como filtros ou buscas de perfis',
};


export const LANGUAGES: Record<ELanguagesTranslations, string> = {
  PT_BR: 'Português (Brasil)',
  EN_US: 'Inglês (USA)',
  ES_AR: 'Espanhol (Espanha)',
  IT_IT: 'Italiano (Itália)',
  FR_FR: 'Francês (França)',
};


export const MEDIAS: Record<EMediasTranslations, string> = {
  MEDIA_APPROVE_TITLE: 'Siga estas regras para acelerar o processo de aprovação.',
  MEDIIA_ACCEPT_TITLE: 'Aceito',
  MEDIIA_ACCEPT_1: 'Nítida e de alta qualidade.',
  MEDIIA_ACCEPT_2: 'Fotos que mostrem o seu corpo por completo, especialmente sem roupas, com ou sem roupas íntimas.',
  MEDIIA_NOT_ACCEPT_TITLE: 'Não aceito',
  MEDIIA_NOT_ACCEPT_1: 'Folhetos, banners, fotos com texto ou bandeiras de cartões.',
  MEDIIA_NOT_ACCEPT_2: 'Fotos com molduras, faixas ou com marcas d`água.',
  MEDIA_RULES: 'Regras de mídia',
  MEDIA_PROFILE_EDIT: 'Alterar mídias do perfil',
};


export const MENU_ORDER: Record<EMenuOrderTranslations, string> = {
  COMBO: 'Combo',
  ITEM_DOUBLE: 'Item dobrado',
  AVAILABLES: 'Disponíveis',
  TABLE: 'Mesa',
  NUMBER: 'Número',
  NUMBER_TABLE_ENTRANCE: 'Número da mesa / entrada',
  TABLE_ENTRANCE: 'Mesa / entrada',
  MIN_ORDERSHEET: 'Pedido mínimo',
  WAITER_CALL: 'Chamar o garçom',
  WAITER_CALLING: 'Chamando garçom',
  WAITER_GOING: 'Garçom a caminho',
  ORDERSHEET_CLOSING_ORDERSHEET: 'Fechando comanda',
  ORDERSHEET_CLOSED: 'Comanda fechada',
  ORDERSHEET_OPENED: 'Comanda aberta',
  TOTAL_CONSUMPTION: 'Consumo total',
  ALCOHOLIC: 'Alcoólico',
  SPICY: 'Picante',
  VEGETARIAN: 'Vegetariano',
  VEGAN: 'Vegano',
  ORGANIC: 'Orgânico',
  NO_LACTOSE: 'Sem lactose',
  NO_GLUTEN: 'Sem glutem',
  REQUESTS: 'Pedidos',
  REQUESTS_MINIMAL: 'Pedidos minimos',
  REQUESTS_UNTIL: 'Pedidos Até',
  DELIVERY: 'Delivery',
  REMOVAL_ITEM: 'Retirada',
  PRESENTIAL: 'Presencial',
  ORDER_PLACED: 'Pedido realizado',
  ORDER_PREPARING: 'Preparando',
  ORDER_TO_DELIVER: 'A Entregar',
  ORDER_REFUSED: 'Pedido Recusado',

  PROFILE_LEVEL_MODELE: 'Nível do anúncio de acompanhante',
  PROFILE_SHOW: 'Mostrar perfil',
  PROFILE_HIDE: 'Ocultar perfil',
};


export const MESSAGES_EMPTYS: Record<EMessagesEmptysTranslations, string> = {
  NOT_AVAILABLE: 'Não disponível',
  EMPTY_LOCATION: 'Sem localização definida',
  EMPTY_ADS: 'Sem anúncios',
  EMPTY_MODELES: 'Não há modelos aqui, ainda!',
  EMPTY_MODELES_FOUND: 'Modelo não encontrado',
  EMPTY_MODELES_TEXT: 'Para encontrar mais modelos você pode escolher outro gênero no canto direito da tela ou outra cidade!',
  EMPTY_MODELES_UNAVAILABLE: 'Este usuário está temporariamente indisponível',
  EMPTY_MODELES_UNAVAILABLE_TEXT: 'Ele estará de volta em um futuro próximo - fique ligado!',
  EMPTY_GALLERY_PHOTOS: 'Não há imagens aqui, ainda!',
  EMPTY_GALLERY_VIDEOS: 'Não há vídeos aqui, ainda!',
  EMPTY_COMPANY_NOT_FOUND: 'Desculpe, perfil não encontrado!',
  EMPTY_COMPANY_EVENTS: 'Não há eventos aqui, ainda!',
  EMPTY_EVENTS_NOT_FOUND: 'Desculpe, evento não disponível!',
  EMPTY_HIGHLIGHTS_ITEMS: 'Desculpe, nenhum destaque encontrado hoje!',
  EMPTY_HIGHLIGHTS_EVENTS: 'Não há eventos aqui, ainda!',
  EMPTY_HIGHLIGHTS_PARTNERS: 'Nenhum Parceiro está no Secretio aqui!',
  EMPTY_CATEGORIES: 'Sem categoria',
  EMPTY_ITEMS: 'Não há itens aqui, ainda!',
  EMPTY_MENUSHEET_CATEGORIES: 'Desculpe, sem itens nesta categoria!',
  EMPTY_NOTIFICATIONS: 'Não há notificações aqui, ainda!',
  EMPTY_ORDERSHEET_HISTORY: 'Sem itens no histórico, ainda!',
  EMPTY_BANKS: 'Sem bancos aqui, desculpe!',
  EMPTY_COMPANIES_REGISTERED: 'Não há empresas cadastradas!',
  EMPTY_HOME: 'Sem dados a mostrar!',
  EMPTY_FAVORITES: 'Não há favoritos aqui!',
  EMPTY_FAVORITES_PHOTOS: 'Nenhuma foto salva aqui, ainda!',
  EMPTY_FAVORITES_VIDEOS: 'Nenhum vídeo salvo aqui, ainda!',
  EMPTY_PAYMENT: 'Não há formas de pagamento cadastrado!',
  EMPTY_ADDRESS: 'Sem endereços cadastradas!',
  EMPTY_USER_DATA: 'Usuário sem dados',
  EMPTY_USER_NO_RECOG: 'Usuário não identificado',
  EMPTY_LISTS: 'Sem listas cadastradas',
  EMPTY_LISTS_PARTICIPATE: 'Nenhuma lista para participar, ainda!',
  EMPTY_LISTS_FINISHED: 'Nenhuma lista que você participou, ainda!',
  EMPTY_TICKET: 'Não há ingressos ainda!',
  EMPTY_TICKET_IN_EVENT: 'Não há ingressos neste evento',
  EMPTY_EVENT_EXPLORE: 'Explore outros eventos como esse',
  EMPTY_CARD_REGISTERED: 'Sem cartão cadastrado',
  EMPTY_CARD_NO_ADDED: 'Você ainda não adicionou um cartão a sua conta',
  EMPTY_RESERVE_REGISTERED: 'Sem reservas disponíveis ou cadastradas!',
  EMPTY_RESERVE_USER: 'Sem reservas ainda para curtir a noite!',
  EMPTY_LINK_SEE: 'Sem link para visualizar',
  EMPTY_SEARCH: 'Desculpe, nada encontrado na busca!',
  EMPTY_ORDERS: 'Sem Pedidos',
  EMPTY_EVENT_FINISHED: 'O evento já encerrou!',
  EMPTY_PLACES: 'Nenhum lugar cadastrado, ainda!',
  EMPTY_CARNIVAL_ATTRACTIONS: 'Desculpe, nenhuma atração hoje!',
  EMPTY_CARNIVAL_NONE: 'Desculpe, não há carnaval nem micaretas, ainda!',
  EMPTY_CARNIVAL_DISTRICTS: 'Desculpe, sem festa nos bairros hoje!',
  EMPTY_CARNIVAL_CABINS: 'Desculpe, os camarotes estão fechados hoje!',
  EMPTY_CARNIVAL_LOCATIONS: 'Desculpe, nenhuma localização aqui!',
  EMPTY_CARNIVAL_STREETS: 'Desculpe, sem festa na rua hoje!',
  EMPTY_CARNIVAL_INFOS: 'Desculpe, sem nenhum guia aqui!',
  EMPTY_PHONES: 'Telefone não disponível',
  EMPTY_CARNIVAL_VOTES: 'Desculpe, sem votações, ainda!',
  EMPTY_LANGUAGES: 'Nenhuma linguagem, ainda!',
  EMPTY_LIST_AVAILABLE: 'Não há listas disponíveis',
  EMPTY_TICKET_AVAILABLE: 'Não há ingressos disponíveis',
  EMPTY_SCHEDULE_TIME: 'Horários indisponível',
  EMPTY_ROOM_TIME: 'Sala indisponível',
  EMPTY_COMMENTS: 'Sem comentários ainda.',
  EMPTY_COMMENTS_TEXT: 'Se você já conheceu esse modelo, pode fazer um comentário',
  EMPTY_DO_COMMENTS: 'Você ainda não enviou nenhuma avaliação.',
  EMPTY_DO_COMMENTS_TEXT: 'Todos os seus comentários enviados aparecerão aqui.',
  EMPTY_HELP: 'Não há FAQs aqui, ainda!',
  EMPTY_HELP_CONTACT: 'Mas, qualquer dúvida entre em contato!',
  EMPTY_SUGGESTIONS: 'Sem sugestões aqui, ainda!',
  EMPTY_BLOCK_1: 'Você ainda não bloqueou nenhum usuário.',
  EMPTY_BLOCK_2: 'Quando você bloqueia alguém, o perfil dela será exibido aqui.',
  EMPTY_BUYED_ITEMS: 'Nenhum item comprado aqui ainda.',
  EMPTY_DASH_BLOCK: 'Nenhum usuário bloqueado',
  EMPTY_DASH_MEDIA_BUYED: 'Nenhuma mídia comprada',
  EMPTY_DASH_MEDIA_LIKED: 'Nenhuma mídia curtida',
  EMPTY_DASH_PICTURE: 'Sem fotos',
  EMPTY_DASH_VIDEO: 'Sem vídeos',
  EMPTY_DASH_COMMENT: 'Sem comentários',
  EMPTY_DASH_FAN_CLUB: 'Nenhum Fã-clube assinado',
  EMPTY_DASH_PAYMENT: 'Nenhum pagamento feito',
  EMPTY_DASH_UPDATES: 'Sem atualizações pendentes',
  EMPTY_LIVE_TOPIC: 'Nenhum tópico definido nesta sala',
  EMPTY_CONVERSATIONS_1: 'Nenhuma conversa iniciada!',
  EMPTY_CONVERSATIONS_2: 'Inicie sua primeira conversa',
};


export const MESSAGES_ERRORS: Record<EMessagesErrosTranslations, string> = {
  ERROR: 'Erro',
  ERROR_OOPS: 'Ooops...',
  ERROR_SORRY: 'Desculpe!',
  ERROR_ACCESS: 'Erro ao acessar',
  ERROR_ACCESS_UNAVAILABLE: 'O serviço para acessar o Secretio está indisponível no momento!',
  ERROR_ACCESS_MAINTENANCE: 'O Secretio está em manutenção nesse momento!',
  ERROR_PAY: 'Erro ao pagar',
  ERROR_PAY_APP: 'Tivemos um problema, faça o pagamento no estabelecimento',
  ERROR_CHECKIN_ASK: 'Não foi possível solicitar o Check-in',
  ERROR_CHECKIN_CANCEL: 'Não foi possível cancelar o Check-in',
  ERROR_HAD: 'Houve um erro',
  ERROR_SEARCH: 'Erro ao buscar dados',
  TRY_AGAIN: 'Erro, tente novamente',
  ACCESS_DENIED: 'Não existe permissão de acesso para esse usuário.',
  PASSWORD_WRONG: 'Usuário e/ou senha inválidos.',
  ERROR_PARAMS_INVALID: 'Parametros inválidos',
  ERROR_COMPANY_CREATE: 'Erro ao criar empresa',
  ERROR_USER_CREATE: 'Erro ao criar perfil',
  ERROR_DATE: 'Erro na data',
  ERROR_NO_DATA_FOUND: 'Dados não encontrados, verifique as informações e tente novamente.',
  ERROR_EMPTY_FIELDS: 'Preencha todos os campos corretamente antes de continuar',
  ERROR_ACCOUNT_TRANSFER: 'Erro ao criar conta de repasse',
  ERROR_ACCOUNT_SEARCH: 'Erro ao buscar contas de repasse',
  ERROR_ACCOUNT_MAIN_CHANGE: 'Houve um erro ao mudar conta',
  ERROR_ACCOUNT_DELETE: 'Houve um erro ao deletar conta',
  ERROR_SAVE: 'Erro ao salvar dados!',
  ERROR_CREATE: 'Erro ao criar',
  ERROR_UPDATE: 'Erro ao atualizar',
  ERROR_USER_DISABLE: 'Erro ao desabilitar usuário.',
  ERROR_COMPANY_DISABLE: 'Erro ao desabilitar empresa.',
  ERROR_MAKE_REGISTER: 'Erro ao fazer o cadastro',
  ERROR_PAYMENT_MAIN_CHOOSED: 'Houve um erro ao escolher o pagamento principal',
  ERROR_PAYMENT_DELETE: 'Houve um erro ao deletar o pagamento',
  ERROR_ADDRESS_DELETE: 'Houve um erro ao deletar sucesso',
  ERROR_LIST_USER_ADDED: 'Erro ao adicionar usuários',
  ERROR_LIST_ENTER: 'Erro ao tentar ingressar na lista',
  ERROR_LIST_GET_DATA: 'Erro ao obter listas',
  ERROR_GET_DATA: 'Erro ao obter dados',
  ERROR_TICKET_UNAVAILABLES: 'Ingressos indisponíveis',
  ERROR_LISTS_UNAVAILABLES: 'Listas indisponíveis',
  ERROR_USER_DOESNOT_EXIST: 'Usuário não existe!',
  ERROR_USER_IN_LIST: 'Usuário já está na lista',
  ERROR_LIKE_ADD: 'Erro ao curtir',
  ERROR_LIKE_REMOVE: 'Erro ao descurtir',
  ERROR_FAVORITE_ADD: 'Erro ao adicionar aos favoritos',
  ERROR_FAVORITE_REMOVE: 'Erro ao remover aos favoritos',
  ERROR_COMMENT_CREATED: 'Erro ao fazer comentário',
  ERROR_COMMENT_EDITED: 'Erro ao editar comentário',
  ERROR_COMMENT_DELETED: 'Erro ao deletar comentário',
  ERROR_CHANGE_PROFILE: 'Erro ao mudar perfil',
  ERROR_VIDEO_PLAY: 'Seu navegador não suporta a reprodução de vídeos desse tipo',
  ERROR_404: 'A página solicitada não pode ser encontrada.',
};


export const MESSAGES_HELP: Record<EMessagesHelpsTranslations, string> = {
  HELP_INPUT_REQUIRED: 'Campos obrigatórios',
  HELP_SOME: 'Alguma dúvida?',
  HELP_HAVE_DOUBTS: 'Ainda tem dúvidas?',
  HELP_TALK_TO_US: 'Fale com o Secretio',
  HELP_LOGIN_TO_SEE: 'Entre para ver suas informações',
  HELP_TO_COMMENT: 'Saiba o que comentar',
  HELP_COMMENT_ONLY_USER: 'Apenas usuários podem fazer comentários',
  HELP_INFO_HELPED: 'Essa informação te ajudou?',
  HELP_FAQ_TEXT: 'Aqui você tira todas as suas dúvidas sobre o Secretio. Precisa de ajuda? Estamos aqui!',
  HELP_COMMON_QUESTIONS: 'Perguntas frequentes',
  HELP_CATEGORIES: 'Categorias',
};


export const MESSAGES_QUESTIONS: Record<EMessagesQuestionsTranslations, string> = {
  ANY_QUESTION: 'Qualquer dúvida',
  ANY_QUESTION_CONTACT: 'contate-nos',
  PAY_ATTENTION: 'Fique Atento',
  QUESTION_DELETE_CARD: 'Deletar cartão?',
  QUESTION_DELETE_CARD_TEXT: 'Você gostaria de deletar esse cartão?',
  QUESTION_ORDERSHEET_CLOSE: 'Fechar comanda?',
  QUESTION_ADD_CREDIT_CARD: 'Nenhum cartão de crédito na sua conta, deseja adicionar ?',
  QUESTION_ACCOUNT_MAIN_CHANGE: 'Mudar conta bancária principal?',
  QUESTION_ACCOUNT_MAIN_CHANGE_TEXT: 'Você gostaria de colocar essa conta como principal?',
  QUESTION_ACCOUNT_DELETE: 'Deletar conta bancária?',
  QUESTION_ACCOUNT_DELETE_TEXT: 'Você gostaria de deletar essa conta bancária?',
  QUESTION_USER_DISABLE: 'Tem certeza que deseja desativar sua conta?',
  QUESTION_COMPANY_DISABLE: 'Tem certeza que deseja desativar essa empresa?',
  QUESTION_ADDRESS_REGISTER_TEXT: 'Para adicionar um método de pagamento é necessário cadastrar um endereço principal. Fazer isso agora?',
  QUESTION_ADDRESS_DELETE: 'Deletar endereço?',
  QUESTION_ADDRESS_DELETE_TEXT: 'Você gostaria de deletar esse item?',
  QUESTION_BAG_ITEM_REMOVE: 'Deseja remover {itemSaleName} do seu carrinho?',
  QUESTION_BAG_CLEAR: 'Esvaziar a sacola?',
  QUESTION_EXIT: 'Deseja sair',
  QUESTION_FAVORITE_REMOVE: 'Remover favorito?',
  QUESTION_COMMENT_DELETED: 'Deseja deletar o comentário?',
  QUESTION_COMMENT_HIDE: 'Deseja esconder o comentário do seu perfil?',
  QUESTION_PROFILE_HIDE: 'Deseja ocultar o perfil?',
};


export const MESSAGES_SUCCESS: Record<EMessagesSuccessTranslations, string> = {
  SUCCESS: 'Sucesso',
  SUCCESS_CHECKIN_ASK: 'Check-in confirmado. Aproveite!',
  SUCCESS_CHECKIN_CANCEL: 'Pedido de checkin cancelado!',
  SUCCESS_ADD_ITEM_BAG: 'Item adicionado com sucesso!',
  SUCCESS_ORDER_DONE: 'Pedido Feito!',
  SUCCESS_PAYED: 'Pago!',
  SUCEESS_COMPANY_CREATE: 'Empresa criada com sucesso!',
  SUCEESS_USER_CREATE: 'Perfil criado com sucesso!',
  SUCEESS_USER_CREATE_TEXT: 'Enviamos um e-mail de confirmação para {user_email}. Abra o e-mail, confirme a criação do perfil e você poderá entrar usando suas credenciais cadastradas. Seu número de cliente também está no e-mail',
  SUCCESS_ACCOUNT_TRANSFER: 'Conta de repasse criada com sucesso',
  SUCCESS_ACCOUNT_MAIN_CHANGE: 'Nova conta principal mudada',
  SUCCESS_ACCOUNT_DELETE: 'Conta deletada com sucesso',
  SUCCESS_CREATE: 'Criado com sucesso',
  SUCCESS_UPDATE: 'Atualização realizada com sucesso',
  SUCCESS_USER_DISABLE: 'Usuário desativado com sucesso',
  SUCCESS_COMPANY_DISABLE: 'Empresa desabilitada com sucesso',
  SUCCESS_PASSWORD_RECOVER: 'Pedido de recuperar senha feito com sucesso!',
  SUCCESS_PASSWORD_RECOVER_TEXT: 'Enviamos um e-mail de confirmação para {user_email}. Abra o e-mail, clique no link e você poderá mudar sua senha.',
  SUCCESS_CARD_REGISTER: 'Cartão cadastrado com sucesso',
  SUCCESS_PAYMENT_MAIN_CHOOSED: 'Pagamento principal escolhido',
  SUCCESS_PAYMENT_DELETE: 'Pagamento deletado com sucesso',
  SUCCESS_ADDRESS_DELETE: 'Endereço deletado com sucesso',
  SUCCESS_EMAIL_CHANGE: 'Email de troca enviado com sucesso',
  SUCCESS_LIST_USER_ADDED: 'Usuários adicionado com sucesso',
  SUCCESS_PASSWORD_COPY: 'Senha copiada',
  SUCCESS_USER_ADDED: 'Usuário adicionado com sucesso',
  SUCCESS_LIKE_ADD: 'Curtida removida',
  SUCCESS_LIKE_REMOVE: 'Curtido com sucesso',
  SUCCESS_FAVORITE_ADD: 'Favorito adicionado com sucesso',
  SUCCESS_FAVORITE_REMOVE: 'Favorito removido com sucesso',
  SUCCESS_COPIED_ADDRESS: 'Endereço copiado',
  SUCCESS_COPIED_NUMBER: 'Número copiado',
  SUCCESS_COPIED_LINK: 'Link copiado',
  SUCCESS_COMMENT_CREATED: 'Comentário feito com sucesso',
  SUCCESS_COMMENT_EDITED: 'Comentário editado com sucesso',
  SUCCESS_COMMENT_DELETED: 'Comentário deletado com sucesso',
  SUCCESS_CHANGE_PROFILE: 'Você agora é um modelo',
  SUCCESS_SAVE: 'Dados salvos com sucesso!',
};


export const MESSAGES_WARNING: Record<EMessagesWarningTranslations, string> = {
  ATTENTION: 'Atenção',
  ATTENTION_TEXT_1: 'Entramos em contato apenas pelo nosso número de WhatsApp oficial para envio de mensagens de atualizações do seu perfi.',
  ATTENTION_TEXT_2: 'O Secretio não pede nenhum código de confirmação, caso alguém peça algo relacionado a súa conta, cuidado pois pode ser golpe.',
  ATTENTION_CONTACT: 'Todos os contatos do Secretio estão aqui.',
  ATTENTION_NOTICE: 'Aviso',
  ATTENTION_TEXT_3: 'Divulgamos a comunicação entre as pessoas sem nenhum vínculo.',
  ATTENTION_TEXT_4: 'Os acompanhantes cadastrados são responsáveis pelo conteúdo. Para sua segurança, comprovamos por meio de ferramentas e documentos a sua maioridade e que também são reais.',
};


export const PAYMENTS: Record<EPaymentsTranslations, string> = {
  BANK: 'Banco',
  AGENCY: 'Agência',
  CARD_SELECTED: 'Cartão selecionado',
  DEBIT_CARD: 'Cartão de Débito',
  CREDIT_CARD: 'Cartão de Crédito',
  CREDIT: 'Crédito',
  DEBIT: 'Débito',
  CASH: 'Dinheiro',
  BANK_CHECK: 'Cheque',
  PIX: 'Pix',
  ELO: 'Elo',
  MASTERCARD: 'Mastercard',
  VISA: 'Visa',
  AMERICAN: 'American Express',
  DINERS: 'Diners',
  HIPERCARD: 'Hipercard',
  CHANGE: 'Troco',
  DISCOUNT: 'Desconto',
  TIP: 'Gorgeta',
  TOTAL_PAY: 'Total Pago',
  TOTAL: 'Total',
  SUBTOTAL: 'Subtotal',
  TAXES: 'Taxas e encargos',
  WAITING_PAYMENT: 'Esperando pagamento',
  ALL_BANKS: 'Todos os bancos',
  ACCOUNT_CURRENT: 'Conta Corrente',
  ACCOUNT_SAVING: 'Conta Poupança',
  ACCOUNT_NICKNAME: 'Apelido da conta',
  ACCOUNT_BELONGING: 'Conta pertencente ao',
  ACCOUNT_CURRENT_SAVING_SAME_NUMBER: 'Conta corrente e poupança com mesmo número',
  CARD_ACCEPTED: 'Cartões aceitos no Secretio',
  PAYMENT_FORM: 'Forma de pagamento',
  PAYMENT_AND_VALUES: 'Valores e Pagamento',
  PAYMENT_HISTORY: 'Histórico de pagamentos',
  CARD_NUMBER: 'Número do Cartão',
  DATE_VALID_UNTIL: 'Válido até',
  PLAN: 'Plano',
  PLAN_CHARGING: 'Informações de plano e cobrança',
  PLAN_PAY_EXEPTION: 'Com isenção de pagamento',
  CARD: 'Cartão',
  CARDS: 'Cartões',
  CARD_CVV: 'CVV',
  CARD_VALIDITY: 'Validade',
  CARD_CURRENCY: 'Cartão atual',
  CARD_DEFINE_CURRENCY: 'Definir cartão atual',
  CARD_DELETE: 'Apagar Cartão',
  CARD_CHOOSE_MAIN: 'Usar este cartão como atual',
  CARD_CHOOSE_DEBIT_TOO: 'Se a cobrança no crédito falhar, tentar na forma débito (caso a opção esteja disponível)',
  CARD_CHOOSE_CREDIT_TOO: 'Se a cobrança no débito falhar, tentar na forma crédito (caso a opção esteja disponível)',
  GIFTS: 'Presentes',
  CREDITS: 'Créditos',
  CREDITS_AVAILABLE: 'Créditos disponíveis',
  CREDITS_BUY_AVAILABLE: 'Clique em um dos itens abaixo para comprar créditos.',
  FINANCIAL: 'Financeiro',
  PROFILE_PLAN_UPGRADE: 'Upgrade de perfil',
  PLAN_MANAGE: 'Gerenciar plano',
};


export const SOCIALS: Record<ESocialsTranslations, string> = {
  RESUME: 'Resumo',
  DATA_PRINCIPAL: 'Dados principais',
  DATA_PRINCIPAL_EDIT: 'Editar dados principais',
  SOCIAL_MEDIA: 'Redes sociais',
  WHATSAPP: 'WhatsApp',
  FACEBOOK: 'Facebook',
  INSTAGRAM: 'Instagram',
  TWITTER: 'X Twitter',
  YOUTUBE: 'Youtube',
  LINKEDIN: 'LinkedIn',
  TIKTOK: 'Tiktok',
  TELEGRAM: 'Telegram',
  CONTACT_US: 'Entre em contato',
  CONTACT_US_PROFILE_PUBLISH: 'Entre em contato se seu perfil está publicado mas você não tem acesso a esse perfil',
  SEND_MESSAGE: 'Mande uma mensagem',
  DONT_CONFIRM_PROFILE_TITLE: 'Você não confirmou sua identidade',
  DONT_CONFIRM_PROFILE_TEXT: 'Para ser um modelo é necessário confirmar sua identidade enviando um documento com foto. Deseja enviar agora?',
};


export const TIME: Record<ETimeTranslations, string> = {
  NOW: 'Agora',
  DATE: 'Data',
  HOUR: 'Hora',
  HOURS: 'Horas',
  DATE_HOUR: 'Data e Hora',
  DATES_AVAILABLE: 'Datas disponíveis',
  OPENED_UNTIL: 'Aberto até',
  OPENEDS: 'Abertos',
  CLOSE_IN: 'Fecha em',
  CLOSED: 'Fechado',
  CLOSEDS: 'Fechados',
  TODAY: 'Hoje',
  SUNDAY: 'Domingo',
  MONDAY: 'Segunda',
  TUESDAY: 'Terça',
  WEDNESDAY: 'Quarta',
  THURSDAY: 'Quinta',
  FRIDAY: 'Sexta',
  SATURDAY: 'Sábado',
  NEXT: 'Próximos',
  PAST: 'Passados',
  UPDATED_AT: 'Atualizado em',
  TIMEZONE_LOCATION: 'Fuso horário para sua localidade',
  MINUTES: 'Minutos',
  OVERNIGHT_STAY: 'Pernoite',
  DAILY: 'Diária',
  DAILY_TRAVEL: 'Diária de viagem',
  WEEKLY: 'Semanal',
  WEEKLY_TRAVEL: 'Semanal de viagem',
  MONTHLY: 'Mensal',
  MONTHLY_TRAVEL: 'Mensal de viagem',
};


export const TITLES: Record<ETitlesTranslations, string> = {
  HOME: 'Início',
  FAVORITES: 'Favoritos',
  FAVORITEDS: 'Favoritados',
  COLLECTIONS: 'Coleções',
  ESCORT: 'Acompanhante',
  ESCORTS: 'Acompanhantes',
  COMMENT_DO: 'Fazar um comentário',
  COMMENT_EDIT: 'Editar um comentário',
  COMMENTED_AT: 'Comentado em',
  COMMENTED_BY: 'Comentário feito por',
  ALL_MODELES: 'Todos os modelos',
  EXIT: 'Sair',
  UNDERSTAND_ADS: 'Entenda os anúncios',
  UNDERSTAND_ROOMS: 'Entenda as salas',
  FORGOT_PASSWORD_QUESTION: 'Esqueceu a senha?',
  RECOVER_PASSWORD: 'Recuperar senha',
  REGISTER: 'Registro',
  REGISTER_FREE: 'Registre-se grátis',
  ACCESS: 'Entrar',
  CHECKIN: 'Check-in',
  CHECKOUT: 'Check-Out',
  CHECKIN_WAIT: 'Aguardando Check-in',
  QR_READER: 'Não quer perder tempo?',
  QR_MY_CODE: 'Meu código QR',
  GALLERY: 'Galeria',
  COMPANY_DETAILS: 'Detalhes do parceiro',
  ALL_EVENTS: 'Todos os eventos',
  LISTS: 'Listas',
  LISTS_EVENT: 'Listas do evento',
  LISTS_ALL: 'Todas as listas',
  LISTS_AVAILABLES: 'Listas disponíveis',
  LISTS_SPECIALS: 'Listas especiais',
  MENUSHEET: 'Cardápio',
  HIGHLIGHTS: 'Em destaques',
  MORE_PROFILES: 'Mais perfis',
  PROFILES_SUGGESTED: 'Perfis sugeridos',
  PARTNER: 'Parceiro',
  PARTNERS: 'Parceiros',
  PARTNERS_HIGHLIGHTS: 'Perfis em destaques hoje',
  EVENTS: 'Eventos',
  EVENTS_HIGHTLIGHTS: 'Eventos em destaque hoje',
  EVENTS_MORE: 'Eventos para você',
  EVENTS_STREET: 'Eventos de rua',
  MAP: 'Mapa',
  MAPS: 'Mapas',
  NOTIFICATIONS: 'Notificações',
  NOTIFICATIONS_CONFIG: 'Configurar notificações',
  ORDERSHEET: 'Comanda',
  ORDERSHEET_PAY: 'Pagar Comanda',
  PAYMENT_OPTIONS: 'Opções de pagamento',
  ORDERBAG: 'Sacola',
  HISTORIC: 'Histórico',
  COMPANY_NEW: 'Nova empresa',
  COMPANY_REGISTER: 'Registrar empresa',
  BANKS: 'Bancos',
  MODAL_ADD_USER: 'Adicionar usuario á empresa',
  ACCOUNT_TRANSFER: 'Contas para repasse',
  ACCOUNT_TRANSFER_ADD: 'Adicionar conta para repasse',
  COMPANY_ADDRESS_EDIT: 'Editar endereço da empresa',
  CONTACT: 'Contato',
  COMPANY_EDIT: 'Editar empresa',
  COMPANY_DATAS: 'Dados da empresa',
  PROFILE_PICTURE: 'Foto do perfil',
  LOGOTIPO: 'Logotipo',
  LOGOTIPO_COMPANY: 'Logotipo do perfil',
  ADDRESS: 'Endereço',
  ADDRESS_CREATE: 'Criar endereço',
  ADDRESS_EDIT: 'Editar endereço',
  ADDRESSES: 'Endereços',
  USER_DISABLE: 'Desativar perfil',
  COMPANY_DISABLE: 'Desativar empresa',
  REDEFINE_PASSWORD: 'Redefinir senha',
  CHANGE_PASSWORD: 'Alterar senha',
  PREFERENCES: 'Preferências',
  SETTINGS: 'Configurações',
  TOOLS: 'Ferramentas',
  LANGUAGE: 'Língua',
  CARD_ADD: 'Adicionar cartão',
  ADDRESS_REGISTER: 'Cadastrar endereço',
  PAYMENTS_SUBSCRIPTIONS: 'Pagamentos e Assinaturas',
  PROFILE_MY_ABOUT: 'Sobre meu perfil',
  PROFILE_DETAILS: 'Detalhes do perfil',
  PROFILE_INFORMATIOS: 'Informações do perfil',
  PROFILE_PUBLIC: 'Perfil público',
  PROFILE_VISIBLE: 'Perfil visível',
  PROFILE_INVISIBLE: 'Perfil não visível',
  PROFILE_MAIN: 'A conta principal',
  PERMISSIONS_GROUP: 'Grupos e permissões',
  INVITE_TO_LIST: 'Convidar para a lista',
  INVITE_PEOPLE: 'Convidar Pessoas',
  PARTICIPATE: 'Participar',
  LIST_PARTICIPATE: 'Listas participantes',
  DETAILS_ITEM: 'Detalhe do Item',
  MY_ORDERS: 'Meus pedidos',
  RESERVES: 'Reservas',
  RESERVE_DETAILS: 'Detalhes da reserva',
  SEARCH: 'Buscas',
  OTHER_INFORMATIONS: 'Outras Informações',
  TERMS_POLICY: 'Termos e Políticas',
  LICENCES: 'Licenças',
  KNOW_APP: 'Conheça o Secretio',
  PROFILE_UNMANAGE: 'Perfil não gerenciado',
  PROFILE_MANAGE: 'Perfil gerenciado',
  SHARE: 'Compartilhar',
  CALENDAR: 'Calendário',
  RANKINGS: 'Rankings',
  SUGGESTIONS: 'Sugestões',
  SPONSORS: 'Patrocinadores',
  MY_PRIVE: 'My Privê',
  BE_MODELE: 'Seja um modelo',
  BLOCK_USER: 'Usuários bloqueados',
  BUYED_ITEMS: 'Itens Comprados',
  CHARACTERISTICS: 'Características',
  DASHBOARD: 'Dashboard',
  MEDIAS_LIKED: 'Mídias curtidas',
  COMMENTS_RULES: 'Regras para comentários',
  COMMENTS_DONE: 'Comentários feitos',
  FAN_CLUB_DONE: 'Fã-clubes assinados',
  PROFILE_IMAGE: 'Imagens do perfil',
  MESSAGES: 'Mensagens',
  CONVERSATIONS: 'Conversas',
  PANEL_PROFISSIONAL: 'Painel profissional',
  PROFILE_PROFISSIONAL_DATA: 'Dados do perfil profissional',
  RENT_PROFILE: 'Perfil acompanhante',
  ANTI_FAKE_VERIFY: 'Verificaçao anti-fake',
  GALLERY_PRIVATE: 'Solicitações de galeria privada',
  VISIBILITY_PRIVACY: 'Visibiliidade e privacidade',
  TRY_OTHER_MORE: 'Tente outras opções',
  PROFILE_BROWSE: 'Navegue entre os perfis',
};


export const SERVICES: Record<EServicesTranslations, string> = {
  SERVICES: 'Serviços',
  PARTNER_SERVICES: 'Serviços oferecidos',
  PARTNER_SERVICES_NOT: 'Serviços não oferecidos',
  ACTIVATE_SERVICES: 'Ativar serviços',
  RENT_ON: 'Acompanhante ativado',
  RENT_OFF: 'Ativar acompanhante',
  FAN_CLUB_ON: 'Fã-clube ativado',
  FAN_CLUB_OFF: 'Ativar fã-clube',
  LIVE_ON: 'Live ativado',
  LIVE_OFF: 'Ativar Live',
  MASSAGE_ON: 'Massagem ativado',
  MASSAGE_OFF: 'Ativar massagem',
  STREAM_ON: 'Stream ativado',
  STREAM_OFF: 'Ativar Stream',
  CONTACTS_AVAILABILITY: 'Contatos / Disponibilidade',
  LINKBIO: 'LinkBio',
};


export const SUBTITLES: Record<ESubtitlesTranslations, string> = {
  SUBTITLES_SETTINGS: 'Configurações da sua conta global',
  SUBTITLES_CARDS: 'Cartões de crédito', // 'Cartões de crédito e débito',
  SUBTITLES_PROFILE_PUBLIC: 'Essas são as informações que aparecem neste perfil que é público',
  SUBTITLES_HIGHLIGHT_MAP: 'Permita a utilização da localização para ver modelos próximos',
  SUBTITLES_HIGHLIGHT_MAP_EXPERIENCE: 'Assim conseguimos melhorar sua experiência no Secretio',
  SUBTITLES_CHARACTERISTICS: 'Descreva sua aparência e hábitos.',
  SUBTITLES_STYLE: 'Descreva seu estilo.',
  SUBTITLES_HEALTH: 'Por favor, ajude nossa comunidade a ficar segura.',
  SUBTITLES_SPOKEN_LANGS: 'Diga aos membros do Secretio quais idiomas você fala.',
  SUBTITLES_TRIBES: 'Diga quais tribos você se identifica.',
  SUBTITLES_IM_IN: 'Aqui você pode enlouquecer e selecionar toda a excentricidade que adora entrar.',
};


export const VALIDATIONS: Record<EValidationsTranslations, string> = {
  ONLY_NUMBERS: 'Apenas Números',
  VALIDATE_MIN_DIGITS: 'Deve conter pelo menos {count} dígitos',
  VALIDATE_MAX_DIGITS: 'Deve conter até {count} dígitos',
  VALIDATE_EXACT_DIGITS: 'Precisa conter {count} dígitos',
  VALIDATE_MIN_CHAR: 'Deve conter pelo menos {count} caracteres',
  VALIDATE_MAX_CHAR: 'Deve conter até {count} caracteres',
  VALIDATE_EXACT_CHAR: 'Precisa conter {count} caracteres',
  VALIDATE_ALL_FIELDS: 'Preencha todos os campos corretamente antes de continuar',
  VALIDATE_ALL_SELECTED: 'Verifique se todos os campos estão selecionados',
  VALIDATE_HAS_USER: 'Já há um perfil com esse usuário/email',
  VALIDATE_ONLY_ONE: 'Marque apenas um',
  VALIDATE_ONE_MORE: 'Marque um ou vários',
  VALIDATE_EMAIL_REQUIRED: 'O e-mail é obrigatório',
  VALIDATE_EMAIL_VALID: 'Digite um e-mail válido',
  VALIDATE_EMAIL_DOMAIN: 'Domínio de e-mail incompleto',
  VALIDATE_PASSWORD_REQUIRED: 'A Senha é obrigatória',
  VALIDATE_ID_SHORT_REQUIRED: 'O ID público é obrigatório',
  VALIDATE_CPF_VALID: 'Insira um CPF válido',
  VALIDATE_USER_CPF_USERNAME: 'Insira um CPF válido ou um usuário com apenas letras, números, "_" e "."',
  VALIDATE_USERNAME_CHAR_SPECIAL: 'Usuário só pode conter letras, números, "_" e "."',
  VALIDATE_USER_REQUIRED: 'O Usuário é obrigatório',
  VALIDATE_HAS_PLAN: 'Você não tem plano',
  VALIDATE_BLOCKED_PLAN: 'Não disponível para seu plano',
  VALIDATE_MODULE_DISABLED: 'Módulo desativado',
  VALIDATE_ANTI_FAKE_MEDIA: 'Há uma mídia em revisão',
};



const Translates = {
  ...APP,
  ...APP_TUTORIAL,
  ...BUTTONS,
  ...COUNTRIES,
  ...FILTERS,
  ...INTERPOLATES,
  ...LABELS,
  ...LABELS_ADS,
  ...LABELS_BE_MODELE,
  ...LABELS_CHARS,
  ...LABELS_COMMENTS,
  ...LABELS_LIVE,
  ...LABELS_REPORTS,
  ...LANGUAGES,
  ...MEDIAS,
  ...MENU_ORDER,
  ...MESSAGES_EMPTYS,
  ...MESSAGES_ERRORS,
  ...MESSAGES_HELP,
  ...MESSAGES_QUESTIONS,
  ...MESSAGES_SUCCESS,
  ...MESSAGES_WARNING,
  ...PAYMENTS,
  ...SOCIALS,
  ...TIME,
  ...TITLES,
  ...SERVICES,
  ...SUBTITLES,
  ...VALIDATIONS,
};



export default Translates;
