import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';

import ButtonPanelHover from '../ButtonPanelHover';



export interface IProps {
  color?: string;
}



export const PanelProfileContainer = styled(ButtonPanelHover)`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 49%;
  flex: 1;
  padding: 16px;

  @media ${PlatformDevices.maxTabletM} {
    flex-direction: column;
    align-items: flex-start;
    min-width: 240px;
  }
`;



export const PanelProfileIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 14px;
  align-items: center;
  justify-content: center;
  width: 45px;

  @media ${PlatformDevices.maxTabletM} {
    margin-bottom: 10px;
  }
`;



export const PanelProfileDescriptionView = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  flex: 1;
`;

export const PanelProfileDescriptionTitle = styled.h3`
  margin-bottom: 3px;
  color: ${Colors.white};
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  text-align: left;
`;

export const PanelProfileDescriptionText = styled.p`
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  color: ${Colors.subtextApp};
`;
