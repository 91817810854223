import {
  Images,
} from '../../constants';



const TipCreditsArray = [
  {
    tip: 10,
    image: Images.credit10,
  },
  {
    tip: 25,
    image: Images.credit25,
  },
  {
    tip: 50,
    image: Images.credit50,
  },
  {
    tip: 100,
    image: Images.credit100,
  },
  {
    tip: 500,
    image: Images.credit500,
  },
  {
    tip: 700,
    image: Images.credit700,
  },
  {
    tip: 1000,
    image: Images.credit1000,
  },
  {
    tip: 5000,
    image: Images.credit5000,
  },
  {
    tip: 10000,
    image: Images.credit10000,
  },
  {
    tip: 25000,
    image: Images.credit25000,
  },
  {
    tip: 50000,
    image: Images.credit50000,
  },
  {
    tip: 'CUSTOM',
    image: Images.creditCustom,
  },
];



export default TipCreditsArray;
