const environment = {
  production: false,
  environment: 'DEV',
  title: 'Dev Environment Heading',
  apiAccountGlobalUrl: 'https://api-dev.noitada.app',
  apiUrl: 'https://api-dev.cartoir.com',
  firebaseWebConfig: {
    apiKey: 'AIzaSyBo1QOAiqTimhdmb29NkV5jIWhijDXoeZk',
    authDomain: 'secretio-app-dev.firebaseapp.com',
    projectId: 'secretio-app-dev',
    storageBucket: 'secretio-app-dev.appspot.com',
    messagingSenderId: '145722738192',
    appId: '1:145722738192:web:d3e23c926dca8895a5ec95',
    measurementId: 'G-P0L0J8WW2F',
  },
};



export default environment;
