import styled,
{
  keyframes,
} from 'styled-components';

import Color from 'color';

import {
  Colors,
  Sizes,
} from '../../shared/constants';

import {
  PlatformDevices,
} from '../../shared/utils/platforms.utils';

import {
  Button,
  Badge,
} from '../Composh/web';



export interface IProps {
  size?: number;
  color?: string;
}



export const ButtonControl = styled(Button.Control)`
  position: relative;
`;


export const PlaceButtonFull = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;



function returnWebPulse(colorReceived: string) {
  const pulseWeb = keyframes`
    0% {
      box-shadow: 0 0 0 0 ${Color(colorReceived || Colors.primary).alpha(0.7).toString()};
    }

    70% {
      box-shadow: 0 0 0 7px ${Colors.transparent};
    }

    100% {
      box-shadow: 0 0 0 0 ${Colors.transparent};
    }
  `;

  return pulseWeb;
}

export const PlaceButtonContainer = styled.div`
  display: none;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-left: 7px;
  margin-right: 13px;
  box-shadow: 0 0 0 0${(props: IProps) => props.color || Colors.primary} ;
	animation: ${(props: IProps) => returnWebPulse(props.color || Colors.primary)} 2s infinite;
  border-radius: ${Sizes.cardRadius}px;

  @media ${PlatformDevices.minTabletM} {
    display: flex;
  }
`;



function returnMobilePulse(colorReceived: string) {
  const pulseMobile = keyframes`
    0% {
      transform: scale(0.85);
      box-shadow: 0 0 0 0 ${Color(colorReceived || Colors.primary).alpha(0.7).toString()};
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 7px ${Colors.transparent};
    }

    100% {
      transform: scale(0.85);
      box-shadow: 0 0 0 0 ${Colors.transparent};
    }
  `;

  return pulseMobile;
}

export const PlaceButtonControl = styled(Button.Control)`
  display: flex;
  flex-direction: column;
  margin-bottom: 3px;
  border-radius: 50%;
  box-shadow: 0 0 0 0${(props: IProps) => props.color || Colors.primary} ;
	transform: scale(1);
	animation: ${(props: IProps) => returnMobilePulse(props.color || Colors.primary)} 2s infinite;

  @media ${PlatformDevices.minTabletM} {
    display: none;
  }
`;



export const ControlCreditCoinImage = styled.img`
  width: ${(props: IProps) => props.size || 28}px;
  height: ${(props: IProps) => props.size || 28}px;
  object-fit: contain;
`;


export const BadgeNotificationView = styled(Badge.View)`
  position: absolute;
  top: 5px;
  left: 22px;
  padding-left: 0;
  padding-right: 0;
  box-shadow: 0 0 10px ${Colors.danger};
`;
