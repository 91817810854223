import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';



export interface IProps {
  active?: boolean;
}



export const CardTravelContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 5px;
  padding-bottom: 5px;

  @media ${PlatformDevices.maxTablet} {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  @media ${PlatformDevices.minTablet} {
    width: 50%;
    min-width: 50%;
    max-width: 50%;
    padding-left: 4px;
    padding-right: 4px;
  }

  @media ${PlatformDevices.minTabletL} {
    width: calc(100% / 3);
    min-width: calc(100% / 3);
    max-width: calc(100% / 3);
    padding-left: 4px;
    padding-right: 4px;
  }
  
  @media ${PlatformDevices.minDesktop} {
    width: 25%;
    min-width: 25%;
    max-width: 25%;
    padding-left: 4px;
    padding-right: 4px;
  }
  
  @media ${PlatformDevices.minDesktopL} {
    width: calc(100% / 5);
    min-width: calc(100% / 5);
    max-width: calc(100% / 5);
    padding-left: 4px;
    padding-right: 4px;
  }
`;

export const TravelTabButton = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  padding-left: 13px;
  padding-right: 10px;
  padding-bottom: 8px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;

export const TravelHeaderView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  margin-bottom: 5px;
`;

export const TravelTabHeaderView = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const TravelTabButtonText = styled.p`
  color: ${Colors.white};
  font-size: 18px;
  font-weight: bold;
`;

export const TravelTabCurrentText = styled.p`
  align-self: flex-start;
  margin-left: 10px;
  padding-top: 3px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 2px;
  background-color: ${Colors.tertiary};
  color: ${Colors.white};
  font-size: 12px;
  border-radius: 20px;
`;

export const TravelDeleteButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 40px;
  height: 40px;
`;


export const TravelDateView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
`;

export const TravelDateIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
`;

export const TravelDateText = styled.p`
  margin-top: 3px;
  margin-bottom: 3px;
  color: ${Colors.grayLight};
  font-size: 14px;
  line-height: 17px;
`;

export const TravelDateSpan = styled.span`
  color: ${Colors.white};
  font-weight: bold;
`;



export const TravelLocationsFlag = styled.img`
  margin-right: 6px;
  width: 30px;
  height: 30px;
  object-fit: contain;
`;

export const TravelInfoView = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

export const TravelPlaceText = styled.p`
  margin-top: 2px;
  margin-bottom: 2px;
  color: ${Colors.white};
  font-size: 13px;
  line-height: 15px;
`;



export const TravelPlaceObsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;

export const TravelNotationText = styled.p`
  margin-top: 2px;
  margin-bottom: 2px;
  color: ${Colors.white};
  font-size: 14px;
  line-height: 15px;
`;

export const TravelPlacePlaceBaseText = styled.p`
  margin-top: 4px;
  margin-bottom: 3px;
  color: ${(props: IProps) => props.active ? Colors.primary : Colors.primaryDark};
  font-size: 12px;
  line-height: 14px;
`;
