import EAppMediaVisibility from '../../enums/Status/app-media-visibility.enum';



export const picureVisibilityGenders = [
  {
    id: 1,
    value: EAppMediaVisibility.PUBLIC,
    label: 'Público',
  },
  {
    id: 2,
    value: EAppMediaVisibility.FAN_CLUB,
    label: 'Fã-clube',
  },
  {
    id: 3,
    value: EAppMediaVisibility.PRIVATE,
    label: 'Privado',
  },
];
