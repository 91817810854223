import React from 'react';

import ButtonAction from '../../../../components/Buttons/ButtonAction';

import {
  Content, Screen, Title,
} from '../../../../components/Composh/web';

import { ButtonBack } from '../../../../components/Controls';
import FooterApp from '../../../../components/Footers/FooterApp';

import HeaderScreen from '../../../../components/Headers/HeaderScreen';
import {
  IconCheck,
} from '../../../../components/Icons';

import {
  Colors,
} from '../../../../shared/constants';

import {
  MembershipHeaderCheck,
  MembershipCheckView,
  MembershipDesc,
  MembershipDescView,
  MembershipInfoView,
  MembershipRow,
  MembershipTableColumn,
  MembershipText,
  MembershipTextBig,
  MembershipTextNormal,
  MembershipTitle,
  PaymentsCurrentButtonSecond,
  PaymentsHeaderContainer,
  PaymentsPrice,
  PaymentsPriceTime,
  PaymentsSubTitleClient,
  PaymentsTitleBox,
  PaymentsTitleClient,
  PaymentsTitleLeft,
  PaymentsTitleRight,
  PriceTableContainer,
} from './styled';



const UpgradeUserDoScreen: React.FC = () => {
  function renderRowTable(
    title: string,
    details: string,
    item1Show: boolean,
    item2Show: boolean,
  ) {
    return (

      <MembershipRow>
        <MembershipDesc>
          <MembershipInfoView>
            <MembershipTitle>
              {title}
            </MembershipTitle>

            <MembershipText>
              {details}
            </MembershipText>
          </MembershipInfoView>
        </MembershipDesc>


        <MembershipCheckView>
          {item1Show && (
            <IconCheck
              color={Colors.white}
              size={38}
            />
          )}
        </MembershipCheckView>


        <MembershipCheckView>
          {item2Show && (
            <IconCheck
              color={Colors.white}
              size={38}
            />
          )}
        </MembershipCheckView>
      </MembershipRow>

    );
  }



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        disableGender
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            color={Colors.white}
            size={27}
          />
        }
        centerContent={
          <Title>
            Upgrade
          </Title>
        }
      />

      <Content>
        <PriceTableContainer>


          <PaymentsHeaderContainer>

            <MembershipDescView>
              <MembershipTextBig>
                Características
              </MembershipTextBig>


              <MembershipTextNormal>
                Selecione o plano que melhor se adapta às suas necessidades
              </MembershipTextNormal>
            </MembershipDescView>


            <MembershipHeaderCheck>
              <PaymentsTitleBox>
                <PaymentsTitleLeft>
                  <PaymentsTitleClient>
                    Basic
                  </PaymentsTitleClient>

                  <PaymentsSubTitleClient>
                    Membership
                  </PaymentsSubTitleClient>
                </PaymentsTitleLeft>

                <PaymentsTitleRight>
                  <PaymentsPrice>
                    Free
                  </PaymentsPrice>

                  <PaymentsPriceTime>
                    all time
                  </PaymentsPriceTime>
                </PaymentsTitleRight>
              </PaymentsTitleBox>

              <PaymentsCurrentButtonSecond>
                <ButtonAction
                  title='Plano Atual'
                />
              </PaymentsCurrentButtonSecond>
            </MembershipHeaderCheck>


            <MembershipHeaderCheck>
              <PaymentsTitleBox>
                <PaymentsTitleLeft>
                  <PaymentsTitleClient>
                    Premium
                  </PaymentsTitleClient>

                  <PaymentsSubTitleClient>
                    Membership
                  </PaymentsSubTitleClient>
                </PaymentsTitleLeft>

                <PaymentsTitleRight>
                  <PaymentsPrice>
                    US$9.95
                  </PaymentsPrice>

                  <PaymentsPriceTime>
                    per month
                  </PaymentsPriceTime>
                </PaymentsTitleRight>
              </PaymentsTitleBox>

              <PaymentsCurrentButtonSecond>
                <ButtonAction
                  title='Escolher plano'
                />
              </PaymentsCurrentButtonSecond>
            </MembershipHeaderCheck>

          </PaymentsHeaderContainer>



          <MembershipTableColumn>

            {renderRowTable(
              'Página pessoal',
              'Obtenha acesso aos perfis do Secretio.',
              true,
              true,
            )}


            {renderRowTable(
              'Acesso ilimitado as mídias',
              'Obtenha acesso ilimitado aos vídeos de todos os artistas no Secretio.',
              true,
              true,
            )}


            {renderRowTable(
              'Status de membro verificado',
              'Seja um cliente Secretio especial e verificado. A maioria dos artistas prefere se comunicar apenas com membros Premium com um album criado.',
              false,
              true,
            )}


            {renderRowTable(
              'Review submission',
              'Providing this feature only for Premium users is helping us to prevent the publishing of fake reviews as much as possible.',
              false,
              true,
            )}

          </MembershipTableColumn>

        </PriceTableContainer>



        <FooterApp />

      </Content>

    </Screen>

  );
};



export default UpgradeUserDoScreen;
