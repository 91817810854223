import styled,
{
  css,
} from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';



export interface IProps {
  active?: boolean;
}



export const TipMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  right: 30px;
  height: 580px;
  width: 380px;
  background-color: #3b3b3b;
  border-radius: ${Sizes.cardRadius}px;
`;


export const BorderBottomStyle = css`
  border-bottom-color: rgba(255,255,255,.18);
  border-bottom-style: solid;
  border-bottom-width: 1px;
`;

export const TipMenuHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
  width: 100%;
  min-height: 60px;
  padding: 14px 20px;
  background-color: #1d1d1d;
  ${BorderBottomStyle};
`;

export const TipMenuHeaderTitle = styled.p`
  color: ${Colors.white};
  font-size: 1.75rem;
  font-weight: 400;
  letter-spacing: .031rem;
`;

export const TipMenuHeaderText = styled.p`
  margin-top: 5px;
  color: ${Colors.white};
  font-size: 17px;
`;


export const TipMenuDescriptionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #1d1d1d;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 15px;
  ${BorderBottomStyle};
`;

export const TipMenuDescriptionHeaderTitle = styled.p`
  color: rgba(255,255,255,.6);
  font-size: .875rem;
  font-weight: 400;
  letter-spacing: .031rem;
`;

export const TipMenuListContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  background-color: #1d1d1d;
`;

export const TipMenuListValueContent = styled.a`
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 12px;
  transition: all ease .25s;
  background-color: ${(props: IProps) => props.active ? '#a1232b' : Colors.transparent};
  border-bottom: 1px solid rgba(248,248,248,.05);
`;

export const TipMenuListTitle = styled.p`
  color: ${Colors.white};
  font-size: 1.125rem;
  font-weight: 400;
  letter-spacing: .031rem;
`;

export const TipMenuListCoinContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TipMenuListCoinScroll = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow-x: auto;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 2px;
`;

export const TipMenuListCoinButtonSelected = css`
  background-color: #4e6438;
  border-color: #9ce256;
`;

export const TipMenuListCoinButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 36px;
  padding: 0 20px;
  background-color: #575757;
  border: 2px solid transparent;
  border-radius: ${Sizes.cardRadius}px;
  transition: all 90ms ease-in-out 0s;

  ${false && TipMenuListCoinButtonSelected};
`;

export const TipMenuListCoinButtonText = styled.p`
  color: #f8f8f8;
  font-size: 17px;
  font-weight: 700;
  text-align: center;
`;


export const TipMenuListCoinView = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 15px;
`;


export const TipMenuListCoinInputContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
`;

export const TipMenuListCoinLabel = styled.p`
  display: flex;
  flex: 1;
  color: rgba(255,255,255,.6);
  font-size: 13px;
  font-weight: 400;
  text-align: left;
`;

export const TipMenuListCoinInput = styled.input`
  display: flex;
  flex-grow: 1;
  height: 40px;
  max-width: 200px;
  margin-left: 12px;
  padding: 10px 20px;
  background-color: ${Colors.black};
  color: rgba(248,248,248,.6);
  font-size: 15px;
  font-weight: 500;
  border: none;
  text-align: center;
  border-radius: 20px;
`;


export const TipMenuListButtonView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const TipMenuListButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  position: relative;
  max-width: 245px;
  height: 40px;
  padding-left: 45px;
  padding-right: 45px;
  background-color: #61922f;
  border-radius: 40px;
  transition: all 90ms ease-in-out 0s;
`;

export const TipMenuListButtonText = styled.p`
  color: #f8f8f8;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: .013rem;
  text-align: center;
  text-overflow: ellipsis;
`;

export const TipMenuAnonymousButtonView = styled.a`
  display: flex;
  flex-direction: column;
  margin-left: 25px;
`;
