import styled from 'styled-components';

import {
  Sizes,
} from '../../../shared/constants';



export interface IProps {
  backgroundColor?: string;
  color?: string;
}



export const InputBooleanContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 42px;
  padding-top: 8px;
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 8px;
  background-color: ${(props: IProps) => props.backgroundColor};
  border-radius: ${Sizes.cardRadius}px;
`;

export const InputBooleanButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
`;

export const InputBooleanText = styled.p`
  margin-left: 8px;
  font-size: 15px;
  color: ${(props: IProps) => props.color};
`;
