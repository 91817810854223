import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
} from 'react-redux';

import {
  useNavigate,
} from 'react-router-dom';

import {
  IconRentOn,
  IconSuggestions,
} from '../../../components/Icons';

import NameRoutes from '../../../navigation/names';

import {
  RootState,
} from '../../../redux';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import {
  TranslateConfig,
} from '../../../shared/translations';

import {
  HeroContainer,
  HeroHeaderView,
  HeroImageLogo,
  HeroHeaderInfo,
  HeroHeaderTitle,
  HeroHeaderColor,
  HeroHeaderDescription,
  HeroOptionsView,
  HeroOptionsButton,
  HeroOptionsHeaderButton,
  HeroOptionsTitleButton,
  HeroOptionsDescButton,
} from './styled';



const HomeHeroSection: React.FC = () => {
  const history = useNavigate();
  const { t: translate } = useTranslation();


  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);

  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;



  function renderButtonOptions(icon: any, title: string, description: string, onPress: any) {
    return (

      <HeroOptionsButton
        onClick={onPress}>

        <HeroOptionsHeaderButton>

          {icon}

          <HeroOptionsTitleButton>
            {title}
          </HeroOptionsTitleButton>

        </HeroOptionsHeaderButton>



        <HeroOptionsDescButton>
          {description}
        </HeroOptionsDescButton>

      </HeroOptionsButton>

    );
  }



  return (

    <HeroContainer>

      <HeroHeaderView>

        <HeroHeaderInfo>

          <HeroHeaderTitle>
            {translate(TranslateConfig.LANDING_TITLE_THE)} <HeroHeaderColor>{translate(TranslateConfig.LANDING_TITLE_BEST)}</HeroHeaderColor> {translate(TranslateConfig.LANDING_TITLE_CONTENT)}
          </HeroHeaderTitle>


          <HeroHeaderDescription>
            {translate(TranslateConfig.LANDING_DESCRIPTION)}
          </HeroHeaderDescription>

        </HeroHeaderInfo>



        <HeroImageLogo
          src={Images.logoIconApp}
        />

      </HeroHeaderView>



      <HeroOptionsView>

        {renderButtonOptions(
          <IconRentOn
            size={25}
            color={Colors.white}
          />,
          translate(TranslateConfig.ESCORTS),
          translate(TranslateConfig.ACTION_OPEN_MORE),
          () => {
            history(NameRoutes.RentHomeScreen);
          },
        )}



        {projectParams?.canAccess && availableUser && renderButtonOptions(
          <IconSuggestions
            size={25}
            color={Colors.white}
          />,
          translate(TranslateConfig.SUGGESTIONS),
          translate(TranslateConfig.ACTION_OPEN_MORE),
          () => {
            history(NameRoutes.RentHomeScreen);
          },
        )}

      </HeroOptionsView>

    </HeroContainer>

  );
};



export default HomeHeroSection;
