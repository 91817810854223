import React from 'react';

import {
  AppBar,
  Toolbar,
  SubToolbar,
} from '../../Composh/web';

import {
  ToolbarViewContainer,
} from './styled';



interface IProps {
  transparent?: boolean;
  absolute?: boolean;
  
  leftIcon?: any;
  rightIcon?: any;
  centerContent?: any;

  backgroundColor?: string;
  subBackgroundColor?: string;

  noSubToolbarPadding?: boolean;

  middleToolbar?: any;
  children?: any;
}



const Header: React.FC<IProps> = (props: any) => {
  return (

    <AppBar
      transparent={props.transparent}
      backgroundColor={props.transparent ? 'transparent' : props.backgroundColor}
      absolute={props.absolute}
      borderBottomColor={props.borderBottomColor}>

      <ToolbarViewContainer>

        {(props.leftIcon || props.centerContent || props.rightIcon) && (
          <Toolbar
            backgroundColor={props.transparent ? 'transparent' : props.backgroundColor}
            leftIcon={props.leftIcon}
            centerContent={props.centerContent}
            rightIcon={props.rightIcon}
          />
        )}


        {props.middleToolbar && (
          <SubToolbar
            backgroundColor={props.transparent ? 'transparent' : props.subBackgroundColor}
            noSubToolbarPadding={props.noSubToolbarPadding}>
            {props.middleToolbar}
          </SubToolbar>
        )}


        {props.children && (
          <SubToolbar
            backgroundColor={props.transparent ? 'transparent' : props.subBackgroundColor}
            noSubToolbarPadding={props.noSubToolbarPadding}>
            {props.children}
          </SubToolbar>
        )}

      </ToolbarViewContainer>

    </AppBar>

  );
};



export default Header;
