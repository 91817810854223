import {
  css,
} from 'styled-components';

import {
  Colors,
} from '../shared/constants';



export const ScrollbarHideStyle = css`
  ::-webkit-scrollbar {
    display: none;
  }
`;


export const ScrollbarHorizontalStyle = css`
  ::-webkit-scrollbar {
    height: 17px;
  }

  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar {
    background: ${Colors.appBackground};
  }

  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px ${Colors.appScrollbar};

    border-color: ${Colors.appBackground};
    border-style: solid;
    border-top-width: 9px;
    border-left-width: 15px;
    border-right-width: 15px;
    border-bottom-width: 1px;
  }
`;


export const HomeScrollbarVertival = css`
  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar {
    background: ${Colors.black};
  }

  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px ${Colors.appScrollbar};
  }
`;
