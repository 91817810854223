import styled,
{
  css,
} from 'styled-components';

import {
  Card,
  Input,
} from '../../../components/Composh/web';

import InputSelect from '../../../components/Inputs/InputSelect';
import InputText from '../../../components/Inputs/InputText';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';



export interface IProps {
  color?: string;
}



export const CardReviewForm = styled(Card.View)`
  padding-top: ${Sizes.paddingContainer}px;
  padding-left: ${Sizes.paddingFlatList}px;
  padding-right: ${Sizes.paddingFlatList}px;
  padding-bottom: ${Sizes.paddingContainer}px;
`;



export const DoReviewHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 25px;
`;

export const DoReviewTitleHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;



export const InputStyle = css`
  margin-left: 5px;
  margin-right: 5px;
`;


export const DoReviewInfoHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  ${InputStyle};
`;

export const DoReviewImageInfoHeader = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 85px;
  height: 85px;
  border-radius: ${Sizes.cardRadius}px;
`;

export const DoReviewHeaderUserImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;



export const DoReviewHeaderUserInfoView = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 14px;
`;

export const DoReviewNameTitle = styled.p`
  color: ${Colors.white};
  font-size: 20px;
  font-weight: 700;
`;

export const DoReviewNamePlaceTitle = styled.p`
  margin-top: 10px;
  color: ${Colors.gray};
  font-size: 16px;
`;



export const DoReviewRateContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const DoReviewRateHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
`;



export const InputResponse = css`
  @media ${PlatformDevices.maxTablet} {
    width: 100%;
  }

  @media ${PlatformDevices.minTablet} {
    width: calc(50% - 10px);
  }

  @media ${PlatformDevices.minLaptop} {
    width: calc(25% - 10px);
  }
`;


export const InputFullSelectStyled = styled(InputSelect)`
  ${InputStyle};
  width: calc(100% - 10px);
`;


export const InputViewStyled = styled(Input.View)`
  ${InputStyle};
  width: calc(100% - 10px);
  margin-bottom: 15px;
`;

export const DoReviewRateStar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 5px;
`;

export const DoReviewRateRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DoReviewRateCountIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`;

export const DoReviewRateCountText = styled.p`
  color: ${(props: IProps) => props.color};
  font-size: 29px;
  font-weight: 500;
`;


export const InputTextAreaStyled = styled(InputText)`
  ${InputStyle};
  width: calc(100% - 10px);
`;


export const InputPartSelectStyled = styled(InputSelect)`
  ${InputStyle};
  ${InputResponse};
`;


export const InputDateStyled = styled(InputText)`
  ${InputStyle};
  ${InputResponse};
`;
