import styled from 'styled-components';



// Height: 815: FullScreen
export const ModeleVideoPlayer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  aspect-ratio: 16/9;
  height: 590px;
  background-color: green;
`;


export const CurtainMessages = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 140px 100px 40px;
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0 5px 15px rgb(0 0 0 / 70%);
  border-radius: 20px;
`;


export const ModeleCurtainImage = styled.img`
  position: absolute;
  top: -60px;
  margin: 0;
  width: 180px;
  height: 180px;
  border: 5px solid;
  border-radius: 50%;
  object-fit: cover;
`;


export const ModeleScreenName = styled.h3`
  color: #00A7E1;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 28px;
`;

export const CurtainMessageSecond = styled.p`
  margin-top: 3px;
  margin-bottom: 3px;
  color: #FFFFFF;
  font-size: 18px;
`;

export const CurtainMessageThird = styled.p`
  color: #FFFFFF;
  font-weight: 800;
  font-size: 26px;
`;



export const CurtainButtonContainer = styled.div`
  margin-top: 15px;
`;

export const CurtainButtonText = styled.p`
  max-width: 300px;
  margin-bottom: 20px;
  color: #FFFFFF;
  font-size: 16px;
  text-align: center;
`;


export const CurtainPrimaryButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00ccff;
  padding: 0.55em 1.5em;
  transition: background-color .3s;
  border-radius: 5px;
`;

export const CurtainPrimaryTextButton = styled.p`
  color: #FFFFFF;
  font-size: 16px;
`;
