import styled from 'styled-components';



export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ArrowContainer = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
`;

export const YearText = styled.h2`
  margin-left: 15px;
  margin-right: 15px;
  color: #ffffff;
  font-size: 24px;
`;
