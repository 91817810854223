import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UserAvatar from '../../../../../../../components/Contents/UserAvatar';
import SectionTitle from '../../../../../../../components/Titles/SectionTitle';
import { RootState } from '../../../../../../../redux';
import { LINKBIO_UPDATE_PROFILE } from '../../../../../../../redux/reducers/linkbio.store';
import linkbioThemesArray from '../../../../../../../shared/arrays/LinkBio/linkbio-themes.array';
import Input from '../../../../components/Input';
import { AppearanceTabRoot, ContentBox, HeadingBox, InputsBox, ThemeMediabox, ThemesGrid } from './styled';



const AppearanceTab: React.FC = () => {
  const dispatch = useDispatch();
  const profileAA = useSelector((state: RootState) => state.linkbio.appearance.profile);

  const profile = profileAA as any;

  const user = useSelector((state: RootState) => state.user.actualUser);

  const reduxAppearance = useSelector((state: RootState) => state.linkbio.appearance.themes);
  const themes = reduxAppearance?.list as any;
  const selectedTheme = reduxAppearance?.selected as any;



  const handleChangeInput = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = ev.target;
    dispatch({ type: LINKBIO_UPDATE_PROFILE, payload: { [name]: value } });
  };



  return (
    <AppearanceTabRoot>
      <section>
        <SectionTitle
          title={'Perfil'}
        />

        {/* <Spacing size={30} /> */}

        <ContentBox>
          <HeadingBox>

            <UserAvatar
              plan={user?.plan?.planCurrent}
              image={user?.picture}
              width={60}
            />


          </HeadingBox>
          {/* <Spacing size={24} /> */}


          <InputsBox>

            <div>
              <SectionTitle
                title={'Descripcion'}
              />
              {/* <Spacing size={8} /> */}
              <Input
                as='textarea'
                rows={8}
                placeholder='descripcion'
                fullWidth
                value={profile?.description}
                onChange={handleChangeInput}
                name='description'
              />
            </div>
          </InputsBox>
        </ContentBox>
      </section>



      <section>
        <SectionTitle
          title={'Temas'}
        />
        {/* <Spacing size={30} /> */}
        <ContentBox>
          <ThemesGrid>
            {linkbioThemesArray.map((theme) => (
              <li
                key={theme?.id}
                onClick={() => dispatch({ type: 'update:theme', payload: theme })}
              >
                <ThemeMediabox
                  src={theme?.image}
                  isActive={
                    theme?.label === (selectedTheme?.name || themes[0]?.name)
                  }
                />
                <p style={{ color: '#fff' }}>{theme?.label}</p>
              </li>
            ))}
          </ThemesGrid>
        </ContentBox>
      </section>

    </AppearanceTabRoot>
  );
};

export default AppearanceTab;
