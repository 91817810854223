import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import ButtonAction from '../../components/Buttons/ButtonAction';
import ButtonLanguages from '../../components/Buttons/ButtonLanguages';

import {
  IconMore18,
} from '../../components/Icons';

import {
  openSite,
} from '../../config/linking.config';

import {
  Colors,
  Images,
} from '../../shared/constants';

import Vars from '../../shared/environments/variables';

import {
  TranslateConfig,
} from '../../shared/translations';

import {
  WarningOverlayStyled,
  WarningOverlay,
  WarningInfoContent,
  ContainerAdultHeader,
  WarningModelImageContent,
  WarningInfoLogo,
  WarningInfoSlogan,
  ContainerAdultContent,
  ContainerAdultIcon,
  ContainerAdultInfoTitle,
  ContainerAdultTitle,
  ContainerAdultTitleBold,
  WarningInfoAccessContent,
  WarningInfoTermsButton,
  WarningLangButtonView,
  ContainerAdultFooter,
  ContainerAdultFooterText,
  ContainerAdultFooterKnowMore,
  WarningInfoAggreeButton,
} from './styled';



export interface IProps {
  visible?: boolean;
  onClose?: any;
}



const AdultContentModal: React.FC<IProps> = (props: IProps) => {
  const visibleModal = props.visible;
  if (!visibleModal) {
    return null;
  }


  const { t: translate } = useTranslation();



  return (

    <WarningOverlayStyled>

      <WarningOverlay />



      <WarningInfoContent>

        <ContainerAdultHeader>

          <WarningModelImageContent>
            <WarningInfoLogo
              src={Images.logoAppFullWhite}
            />
          </WarningModelImageContent>

          <WarningInfoSlogan>
            {translate(TranslateConfig.SLOGAN_APP)}
          </WarningInfoSlogan>

        </ContainerAdultHeader>



        <ContainerAdultContent>

          <ContainerAdultIcon>
            <IconMore18
              color={Colors.primary}
              size={58}
            />
          </ContainerAdultIcon>


          <ContainerAdultInfoTitle>
            <ContainerAdultTitle>
              {translate(TranslateConfig.CONTENT).toUpperCase()}
            </ContainerAdultTitle>

            <ContainerAdultTitleBold>
              {translate(TranslateConfig.ADULT).toUpperCase()}
            </ContainerAdultTitleBold>
          </ContainerAdultInfoTitle>

        </ContainerAdultContent>



        <WarningInfoAccessContent>
          {translate(TranslateConfig.TUTORIAL_WARNING)}
          {' '}
          <WarningInfoTermsButton
            onClick={() => {
              openSite(Vars().termsUseLink);
            }}>
            {translate(TranslateConfig.ACCESS_USER_TERMS_USE)}
          </WarningInfoTermsButton>,
          {' '}
          <WarningInfoTermsButton
            onClick={() => {
              openSite(Vars().privacityPolicyLink);
            }}>
            {translate(TranslateConfig.ACCESS_USER_TERMS_PRIVACY)}
          </WarningInfoTermsButton>
          {' '}
          {translate(TranslateConfig.AND)}
          {' '}
          <WarningInfoTermsButton
            onClick={() => {
              openSite(Vars().termCookiesLink);
            }}>
            {translate(TranslateConfig.ACCESS_USER_TERMS_COOKIES)}
          </WarningInfoTermsButton>
          .
        </WarningInfoAccessContent>



        <WarningLangButtonView>
          <ButtonLanguages />
        </WarningLangButtonView>



        <ContainerAdultFooter>

          <ContainerAdultFooterText>
            {translate(TranslateConfig.LANDING_ADULT_JOB)}
            {' '}
            {false && (
              // TODO
              <ContainerAdultFooterKnowMore
                onClick={() => {
                  //
                }}>
                {translate(TranslateConfig.ACTION_LEARN_MORE)}
              </ContainerAdultFooterKnowMore>
            )}
            {/* . */}
          </ContainerAdultFooterText>


          <WarningInfoAggreeButton>
            <ButtonAction
              title={translate(TranslateConfig.ACTION_APP_ENTER)}
              onPress={() => {
                if (props.onClose()) {
                  props.onClose();
                }
              }}
            />
          </WarningInfoAggreeButton>

        </ContainerAdultFooter>

      </WarningInfoContent>

    </WarningOverlayStyled>

  );
};



export default AdultContentModal;
