import styled from 'styled-components';

import {
  Card,
} from '../../../components/Composh/web';

import {
  Sizes,
} from '../../../shared/constants';



export const CardVisibilityForm = styled(Card.View)`
  padding-top: ${Sizes.paddingContainer}px;
  padding-left: ${Sizes.paddingFlatList}px;
  padding-right: ${Sizes.paddingFlatList}px;
  padding-bottom: ${Sizes.paddingContainer}px;
`;


export const CardVisibilityView = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
`;
