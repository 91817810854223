import styled from 'styled-components';



export const DetailsSectionView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
`;
