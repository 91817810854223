import React from 'react';

import {
  countriesOption,
} from '../../../shared/arrays/App/countries.array';

import {
  Colors,
} from '../../../shared/constants';

import {
  stringRawDataText,
} from '../../../shared/utils/string.utils';

import {
  IconMap,
  IconMapPin,
} from '../../Icons';

import {
  PanelLocationsContainer,
  PanelLocationsIcon,
  PanelLocationsView,
  PanelLocationsTitle,
  PanelLocationsInfoView,
  PanelLocationsIconView,
  PanelLocationsFlag,
  PanelLocationsText,
} from './styled';



export interface IProps {
  title?: any;
  place?: string;
  country?: string;
  onPress?: any;
}



const ButtonPanelLocation: React.FC<IProps> = (props: IProps) => {
  const filterLangCountry = countriesOption.find((country) => stringRawDataText(country?.code) === stringRawDataText(props.country));



  return (

    <PanelLocationsContainer
      onPress={() => {
        if (props.onPress) {
          props.onPress();
        }
      }}>

      <PanelLocationsView>

        <PanelLocationsIcon>
          <IconMap
            color={Colors.white}
            size={27}
          />
        </PanelLocationsIcon>


        <PanelLocationsTitle>
          {props.title || '-'}
        </PanelLocationsTitle>

      </PanelLocationsView>



      <PanelLocationsInfoView>

        <PanelLocationsIconView>

          {props.country && (
            <PanelLocationsFlag
              src={filterLangCountry?.image}
            />
          )}


          {!props.country && (
            <IconMapPin
              color={Colors.inputLabel}
              size={14}
            />
          )}

        </PanelLocationsIconView>


        <PanelLocationsText>
          {props.place || '-'}
        </PanelLocationsText>

      </PanelLocationsInfoView>

    </PanelLocationsContainer>

  );
};



export default ButtonPanelLocation;
