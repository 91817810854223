import {
  ILanguages,
} from '../../../apis/interfaces/App/country.interface';

import {
  Images,
} from '../../constants';

import {
  ELanguage,
} from '../../enums';

import {
  ELanguagesTranslations,
} from '../../translations/translates.keys';



export const languagesOption: Array<ILanguages & { label: any, languageIso: ELanguage }> = [
  {
    code: 'BRA',
    namePt: 'Brasil',
    label: ELanguagesTranslations.PT_BR,
    languageIso: ELanguage.PT_BR,
    flagImage: Images.countryFlagBrazil,
  },
  // {
  //   code: 'USA',
  //   namePt: 'Estados Unidos',
  //   label: ELanguagesTranslations.EN_US,
  //   languageIso: ELanguage.EN_US,
  //   flagImage: Images.countryFlagUnitedStates,
  // },
  // {
  //   code: 'ESP',
  //   namePt: 'Espanha',
  //   label: ELanguagesTranslations.ES_AR,
  //   languageIso: ELanguage.ES_AR,
  //   flagImage: Images.countryFlagSpain,
  // },
  // {
  //   code: 'ITA',
  //   namePt: 'Itália',
  //   label: ELanguagesTranslations.IT_IT,
  //   languageIso: ELanguage.IT_IT,
  //   flagImage: Images.countryFlagItaly,
  // },
  // {
  //   code: 'FRA',
  //   namePt: 'França',
  //   label: ELanguagesTranslations.FR_FR,
  //   languageIso: ELanguage.FR_FR,
  //   flagImage: Images.countryFlagFrance,
  // },
];
