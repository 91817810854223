import styled,
{
  css,
} from 'styled-components';

import {
  Scrolls,
} from '../../../../../components/Composh/web';

import {
  Colors,
} from '../../../../../shared/constants';

import {
  PlatformDevices,
} from '../../../../../shared/utils/platforms.utils';



export interface IProps {
  color?: string;
}



export const ScrollbarHideStyle = css`
  ::-webkit-scrollbar {
    display: none;
  }
`;



export const TravelCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 15px;
`;



export const WebTravelsContainer = styled(Scrolls.Horizontal)`
  display: flex;
  flex-direction: row;

  @media ${PlatformDevices.maxLaptop} {
    display: none;
  }
`;

export const MobileTravelsContainer = styled.div`
  display: none;
  flex-direction: column;
  width: 100%;

  @media ${PlatformDevices.maxTabletM} {
    align-items: center;
  }

  @media ${PlatformDevices.maxLaptop} {
    display: flex;
  }
`;


export const TravelCurrentContent = styled.a`
  display: flex;
  flex-direction: row;
`;


export const TravelCurrentIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  margin-right: 10px;
`;


export const TravelCurrentInfoContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TravelCurrentText = styled.p`
  color: ${Colors.white};
  font-size: 15px;
  font-weight: 700;
  
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  word-break: break-word;
  text-overflow: ellipsis;
`;

export const TravelLabelCurrentText = styled.p`
  margin-top: 4px;
  color: ${Colors.white};
  font-size: 13px;
  
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  word-break: break-word;
  text-overflow: ellipsis;

  @media ${PlatformDevices.maxTabletM} {
    text-align: center;
  }
`;



export const TravelViewSeparator = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding-right: 10px;
`;

export const TravelSeparator = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 24px;
  height: 1px;
  margin-top: 12px;
  margin-left: 15px;
  margin-right: 15px;
  background-color: #5D5D5D;
`;



export const TravelNextPlaceText = styled.p`
  color: ${Colors.white};
  font-size: 15px;
  
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  word-break: break-word;
  text-overflow: ellipsis;
`;

export const TravelNextPlaceLabelText = styled.p`
  margin-top: 4px;
  color: ${Colors.grayLight};
  font-size: 13px;
  
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  word-break: break-word;
  text-overflow: ellipsis;
`;



export const TravelAllPlaceContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 12px;

  @media ${PlatformDevices.maxTabletM} {
    align-items: center;
  }

  @media ${PlatformDevices.maxLaptop} {
    flex-direction: column-reverse;
  }
`;

export const TravelModelFromView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${PlatformDevices.maxLaptop} {
    margin-top: 5px;
  }
`;

export const TravelModelFromImage = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-left: 3px;
  margin-right: 10px;
`;

export const TravelModelFromText = styled.p`
  color: ${Colors.white};
  font-size: 13px;
  transition: all .2s ease-out;
`;


export const TravelAllPlaceButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TravelAllPlaceText = styled.p`
  color: ${Colors.white};
  font-size: 14px;
  transition: all .2s ease-out;

  ${TravelAllPlaceButton}:hover & {
    color: ${(props: IProps) => props.color};
  }

  @media ${PlatformDevices.maxTabletM} {
    text-align: center;
  }
`;
