import styled from 'styled-components';



interface IProps {
  show?: boolean;
}



export const TypeRoomsSubtitleView = styled.div`
  display: ${(props: IProps) => props.show ? 'flex' : 'none'};
  flex-direction: column;
  width: 500px;
  position: absolute;
  right: 20px;
  bottom: 20px;
  background-color: #bababa;
`;
