import React from 'react';

import {
  PersonalStatisticsContainer,
  PersonalStatisticsView,
  PersonalStatisticsIcon,
  PersonalStatisticsInfoContent,
  PersonalItemTitle,
  PersonalItemText,
  PersonalItemDescriptionText,
} from './styled';



export interface IProps {
  disabled?: boolean;
  icon?: any;
  title?: string;
  text?: string;
  description?: string;
  onPress?: any;
}



const ItemDescription: React.FC<IProps> = (props: IProps) => {
  return (

    <PersonalStatisticsContainer
      clickable={Boolean(props.onPress)}
      onClick={() => {
        if (props.onPress) {
          props.onPress();
        }
      }}>

      <PersonalStatisticsView>

        <PersonalStatisticsIcon>
          {props.icon}
        </PersonalStatisticsIcon>


        <PersonalStatisticsInfoContent>

          <PersonalItemTitle>
            {props.title}
          </PersonalItemTitle>


          <PersonalItemText>
            {props.text}
          </PersonalItemText>

        </PersonalStatisticsInfoContent>

      </PersonalStatisticsView>



      {props.description && (
        <PersonalItemDescriptionText>
          {props.description}
        </PersonalItemDescriptionText>
      )}

    </PersonalStatisticsContainer>

  );
};



export default ItemDescription;
