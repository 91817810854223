// String

export function validateString(value: string | null | undefined) {
  if (value && (value !== '' || value !== null || value !== undefined)) {
    return (value).toString();
  }
  else {
    return null;
  }
}



export function stringLowCase(value: string | null | undefined) {
  if (value && validateString(value)) {
    return value?.toLowerCase();
  }
  else {
    return null;
  }
}



export function stringCapitalize(value: string | null | undefined) {
  if (value && validateString(value)) {
    return (value.slice(0, 1).toUpperCase() + value.toLowerCase().slice(1, value.length)).toString();
  }
  else {
    return null;
  }
}



export function stringWordCapitalize(value: string) {
  if (value && validateString(value)) {
    return value.toLowerCase()
      .split(' ')
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  }
  else {
    return null;
  }
}



export function stringUpperCase(value: string | null | undefined) {
  if (value && validateString(value)) {
    return value.toUpperCase();
  }
  else {
    return null;
  }
}



export function stringRawDataText(value: string | null | undefined) {
  if (value && validateString(value)) {
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replaceAll(/\s/g, '').replace(/[^\w\s]/gi, '').trim();
  }
  else {
    return null;
  }
}



export function normalizeRawText(value: string | null | undefined) {
  if (value && validateString(value)) {
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^\w\s]/gi, '').trim();
  }
  else {
    return null;
  }
}



// Numbers

export function validateNumber(value: string | number | null | undefined) {
  if (value && (Number(value) === 0 || value !== '' || value !== null || value !== undefined)) {
    return Number(value);
  }
  else {
    return null;
  }
}


export function formatNumber(value: number) {
  // TODO
  return value;
}
