import {
  IPhoneDDI,
} from '../../../apis/interfaces/Phones/phone-ddi.interface';

import {
  Images,
} from '../../constants';



export const ddiArray: Array<IPhoneDDI> = [
  {
    id: 1,
    image: Images.countryFlagAfghanistan,
    value: 93,
    label: 'Afeganistão',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagSouthAfrica,
    value: 27,
    label: 'África do Sul',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagAlbania,
    value: 355,
    label: 'Albânia',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagGermany,
    value: 49,
    label: 'Alemanha',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagAndorra,
    value: 376,
    label: 'Andorra',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagAngola,
    value: 244,
    label: 'Angola',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagAnguilla,
    value: 1,
    label: 'Anguilla',
    continent: 'América Central',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagAntiguaAndBarbuda,
    value: 1,
    label: 'Antígua e Barbuda',
    continent: 'América Central',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagNetherlandsAntilles,
    value: 599,
    label: 'Antilhas Holandesas',
    continent: 'América Central',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagSaudiArabia,
    value: 966,
    label: 'Arábia Saudita',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagAlgeria,
    value: 213,
    label: 'Argélia',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagArgentina,
    value: 54,
    label: 'Argentina',
    continent: 'América do Sul',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagArmenia,
    value: 374,
    label: 'Armênia',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagAruba,
    value: 297,
    label: 'Aruba',
    continent: 'América Central',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagflagAscensionIsland,
    value: 247,
    label: 'Ascensão',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagAustralia,
    value: 61,
    label: 'Austrália',
    continent: 'Oceania',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagAustria,
    value: 43,
    label: 'Áustria',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagAzerbaijan,
    value: 994,
    label: 'Azerbaijão',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagBahamas,
    value: 1,
    label: 'Bahamas',
    continent: 'América Central',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagBangladesh,
    value: 880,
    label: 'Bangladesh',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagBarbados,
    value: 1,
    label: 'Barbados',
    continent: 'América Central',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagBahrain,
    value: 973,
    label: 'Bahrein',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagBelgium,
    value: 32,
    label: 'Bélgica',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagBelize,
    value: 501,
    label: 'Belize',
    continent: 'América Central',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagBenin,
    value: 229,
    label: 'Benim',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagBermuda,
    value: 1,
    label: 'Bermudas',
    continent: 'América Central',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagBelarus,
    value: 375,
    label: 'Bielorrússia',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagBolivia,
    value: 591,
    label: 'Bolívia',
    continent: 'América do Sul',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagBosnian,
    value: 387,
    label: 'Bósnia e Herzegovina',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagBotswana,
    value: 267,
    label: 'Botswana',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagBrazil,
    value: 55,
    label: 'Brasil',
    continent: 'América do Sul',
    frequent: true,
  },
  {
    id: 1,
    image: Images.countryFlagBrunei,
    value: 673,
    label: 'Brunei',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagBulgaria,
    value: 359,
    label: 'Bulgária',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagBurkinaFaso,
    value: 226,
    label: 'Burkina Faso',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagBurundi,
    value: 257,
    label: 'Burundi',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagBhutan,
    value: 975,
    label: 'Butão',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagCapeVerde,
    value: 238,
    label: 'Cabo Verde',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagCameroon,
    value: 237,
    label: 'Camarões',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagCambodia,
    value: 855,
    label: 'Camboja',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagCanada,
    value: 1,
    label: 'Canadá',
    continent: 'América do Norte',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagKazakhstan,
    value: 7,
    label: 'Cazaquistão',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagChad,
    value: 235,
    label: 'Chade',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagChile,
    value: 56,
    label: 'Chile',
    continent: 'América do Sul',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagChina,
    value: 86,
    label: 'República Popular da China',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagCyprus,
    value: 357,
    label: 'Chipre',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagColombia,
    value: 57,
    label: 'Colômbia',
    continent: 'América do Sul',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagComoros,
    value: 269,
    label: 'Comores',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagCongo,
    value: 242,
    label: 'Congo Brazzaville',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagCongoKinshasa,
    value: 243,
    label: 'CongoKinshasa',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagNorthKorea,
    value: 850,
    label: 'Coreia do Norte',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagKorea,
    value: 82,
    label: 'Coreia do Sul',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagIvoryCoast,
    value: 225,
    label: 'Costa do Marfim',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagCostaRica,
    value: 506,
    label: 'Costa Rica',
    continent: 'América Central',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagCroatian,
    value: 385,
    label: 'Croácia',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagCuba,
    value: 53,
    label: 'Cuba',
    continent: 'América Central',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagDenmark,
    value: 45,
    label: 'Dinamarca',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagDjibouti,
    value: 253,
    label: 'Djibuti',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagDominica,
    value: 1,
    label: 'Dominica',
    continent: 'América Central',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagEgypt,
    value: 20,
    label: 'Egipto',
    continent: 'África/Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagElSalvador,
    value: 503,
    label: 'El Salvador',
    continent: 'América Central',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagUnitedArabEmirates,
    value: 971,
    label: 'Emirados Árabes Unidos',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagEcuador,
    value: 593,
    label: 'Equador',
    continent: 'América do Sul',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagEritrea,
    value: 291,
    label: 'Eritreia',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagSlovakia,
    value: 421,
    label: 'Eslováquia',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagSlovenia,
    value: 386,
    label: 'Eslovénia',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagSpain,
    value: 34,
    label: 'Espanha',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagUnitedStates,
    value: 1,
    label: 'Estados Unidos',
    continent: 'América do Norte',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagEstonia,
    value: 372,
    label: 'Estónia',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagEthiopia,
    value: 251,
    label: 'Etiópia',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagFiji,
    value: 679,
    label: 'Fiji',
    continent: 'Oceania',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagPhilippines,
    value: 63,
    label: 'Filipinas',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagFinland,
    value: 358,
    label: 'Finlândia',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagFrance,
    value: 33,
    label: 'França',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagGabon,
    value: 241,
    label: 'Gabão',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagGambia,
    value: 220,
    label: 'Gâmbia',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagGhana,
    value: 233,
    label: 'Gana',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagGeorgia,
    value: 995,
    label: 'Geórgia',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagGibraltar,
    value: 350,
    label: 'Gibraltar',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagGrenada,
    value: 1,
    label: 'Granada',
    continent: 'América Central',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagGreece,
    value: 30,
    label: 'Grécia',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagGreenland,
    value: 299,
    label: 'Groenlândia',
    continent: 'América do Norte',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagGuadeloupe,
    value: 590,
    label: 'Guadalupe',
    continent: 'América Central',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagGuam,
    value: 1,
    label: 'Guam',
    continent: 'Oceania',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagGuatemala,
    value: 502,
    label: 'Guatemala',
    continent: 'América Central',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagGuyana,
    value: 592,
    label: 'Guiana',
    continent: 'América do Sul',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagFrenchGuiana,
    value: 594,
    label: 'Guiana Francesa',
    continent: 'América do Sul',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagGuinea,
    value: 224,
    label: 'Guiné',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagGuineaBissau,
    value: 245,
    label: 'GuinéBissau',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagEquatorialGuinea,
    value: 240,
    label: 'Guiné Equatorial',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagHaiti,
    value: 509,
    label: 'Haiti',
    continent: 'América Central',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagHonduras,
    value: 504,
    label: 'Honduras',
    continent: 'América Central',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagHongKong,
    value: 852,
    label: 'Hong Kong',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagHungary,
    value: 36,
    label: 'Hungria',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagYemen,
    value: 967,
    label: 'Iêmen',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagCaymanIslands,
    value: 1,
    label: 'Ilhas Cayman',
    continent: 'América Central',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagChristmasIsland,
    value: 672,
    label: 'Ilha Christmas',
    continent: 'Oceania',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagCocosIslands,
    value: 672,
    label: 'Ilhas Cocos',
    continent: 'Oceania',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagCookIslands,
    value: 682,
    label: 'Ilhas Cook',
    continent: 'Oceania',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagFaroeIslands,
    value: 298,
    label: 'Ilhas Féroe',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagFlagHeardIslandAndMcdonaldIslands,
    value: 672,
    label: 'Ilha Heard e Ilhas McDonald',
    continent: 'Oceania',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagMaldives,
    value: 960,
    label: 'Maldivas',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagFalklandIslands,
    value: 500,
    label: 'Ilhas Malvinas',
    continent: 'América do Sul',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagNorthernMarianaIslands,
    value: 1,
    label: 'Ilhas Marianas do Norte',
    continent: 'Oceania',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagMarshallIslands,
    value: 692,
    label: 'Ilhas Marshall',
    continent: 'Oceania',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagNorfolkIsland,
    value: 672,
    label: 'Ilha Norfolk',
    continent: 'Oceania',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagSolomonIslands,
    value: 677,
    label: 'Ilhas Salomão',
    continent: 'Oceania',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagVirginIslands,
    value: 1,
    label: 'Ilhas Virgens Americanas',
    continent: 'América Central',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagBritishVirginIslands,
    value: 1,
    label: 'Ilhas Virgens Britânicas',
    continent: 'América Central',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagIndia,
    value: 91,
    label: 'Índia',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagIndonesia,
    value: 62,
    label: 'Indonésia',
    continent: 'Ásia/Oceania',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagIran,
    value: 98,
    label: 'Irã',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagIraq,
    value: 964,
    label: 'Iraque',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagIreland,
    value: 353,
    label: 'Irlanda',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagIceland,
    value: 354,
    label: 'Islândia',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagIsrael,
    value: 972,
    label: 'Israel',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagItaly,
    value: 39,
    label: 'Itália',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagJamaica,
    value: 1,
    label: 'Jamaica',
    continent: 'América Central',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagJapan,
    value: 81,
    label: 'Japão',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagJordan,
    value: 962,
    label: 'Jordânia',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagKiribati,
    value: 686,
    label: 'Kiribati',
    continent: 'Oceania',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagKosovo,
    value: 383,
    label: 'Kosovo',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagKuwait,
    value: 965,
    label: 'Kuwait',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagLaos,
    value: 856,
    label: 'Laos',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagLesotho,
    value: 266,
    label: 'Lesoto',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagLatvia,
    value: 371,
    label: 'Letônia',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagLebanon,
    value: 961,
    label: 'Líbano',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagLiberia,
    value: 231,
    label: 'Libéria',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagLibya,
    value: 218,
    label: 'Líbia',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagLiechtenstein,
    value: 423,
    label: 'Liechtenstein',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagLithuania,
    value: 370,
    label: 'Lituânia',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagLuxembourg,
    value: 352,
    label: 'Luxemburgo',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagMacau,
    value: 853,
    label: 'Macau',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagMacedonia,
    value: 389,
    label: 'República da Macedônia',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagMadagascar,
    value: 261,
    label: 'Madagascar',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagMalaysia,
    value: 60,
    label: 'Malásia',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagMalawi,
    value: 265,
    label: 'Malawi',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagMali,
    value: 223,
    label: 'Mali',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagMalta,
    value: 356,
    label: 'Malta',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagMorocco,
    value: 212,
    label: 'Marrocos',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagMartinique,
    value: 596,
    label: 'Martinica',
    continent: 'América Central',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagMauritius,
    value: 230,
    label: 'Maurícia',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagMauritania,
    value: 222,
    label: 'Mauritânia',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagMayotte,
    value: 269,
    label: 'Mayotte',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagMexico,
    value: 52,
    label: 'México',
    continent: 'América do Norte',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagMicronesia,
    value: 691,
    label: 'Estados Federados da Micronésia',
    continent: 'Oceania',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagMozambique,
    value: 258,
    label: 'Moçambique',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagMoldova,
    value: 373,
    label: 'Moldávia',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagMonaco,
    value: 377,
    label: 'Mônaco',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagMongolia,
    value: 976,
    label: 'Mongólia',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagMontenegro,
    value: 382,
    label: 'Montenegro',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagMontserrat,
    value: 1,
    label: 'Montserrat',
    continent: 'América Central',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagMyanmar,
    value: 95,
    label: 'Myanmar',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagNamibia,
    value: 264,
    label: 'Namíbia',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagNauru,
    value: 674,
    label: 'Nauru',
    continent: 'Oceania',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagNepal,
    value: 977,
    label: 'Nepal',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagNicaragua,
    value: 505,
    label: 'Nicarágua',
    continent: 'América Central',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagNiger,
    value: 227,
    label: 'Níger',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagNigeria,
    value: 234,
    label: 'Nigéria',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagNiue,
    value: 683,
    label: 'Niue',
    continent: 'Oceania',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagNorway,
    value: 47,
    label: 'Noruega',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagNewCaledonia,
    value: 687,
    label: 'Nova Caledônia',
    continent: 'Oceania',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagNewZealand,
    value: 64,
    label: 'Nova Zelândia',
    continent: 'Oceania',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagOman,
    value: 968,
    label: 'Omã',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagNetherlands,
    value: 31,
    label: 'Países Baixos',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagPalau,
    value: 680,
    label: 'Palau',
    continent: 'Oceania',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagPalestinianTerritory,
    value: 970,
    label: 'Palestina',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagPanama,
    value: 507,
    label: 'Panamá',
    continent: 'América Central',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagPapuaNewGuinea,
    value: 675,
    label: 'PapuaNova Guiné',
    continent: 'Oceania',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagPakistan,
    value: 92,
    label: 'Paquistão',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagParaguay,
    value: 595,
    label: 'Paraguai',
    continent: 'América do Sul',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagPeru,
    value: 51,
    label: 'Peru',
    continent: 'América do Sul',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagFrenchPolynesia,
    value: 689,
    label: 'Polinésia Francesa',
    continent: 'Oceania',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagPoland,
    value: 48,
    label: 'Polônia',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagPuertoRico,
    value: 1,
    label: 'Porto Rico',
    continent: 'América Central',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagPortugal,
    value: 351,
    label: 'Portugal',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagQatar,
    value: 974,
    label: 'Qatar',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagKenya,
    value: 254,
    label: 'Quênia',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagKyrgyzstan,
    value: 996,
    label: 'Quirguistão',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagUnitedKingdom,
    value: 44,
    label: 'Reino Unido',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagCentraAfricanRepublic,
    value: 236,
    label: 'República CentroAfricana',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagDominicanRepublic,
    value: 1,
    label: 'República Dominicana',
    continent: 'América Central',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagCzechRepublic,
    value: 420,
    label: 'República Tcheca',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagReunion,
    value: 262,
    label: 'Reunião',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagRomania,
    value: 40,
    label: 'Romênia',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagRwanda,
    value: 250,
    label: 'Ruanda',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagRussia,
    value: 7,
    label: 'Rússia',
    continent: 'Europa/Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagWesternSahara,
    value: 212,
    label: 'Saara Ocidental',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagSamoa,
    value: 685,
    label: 'Samoa',
    continent: 'Oceania',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagAmericanSamoa,
    value: 1,
    label: 'Samoa Americana',
    continent: 'Oceania',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagSaintHelena,
    value: 290,
    label: 'Santa Helena território',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagSaintLucia,
    value: 1,
    label: 'Santa Lúcia',
    continent: 'América Central',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagSaintKittsAndNevis,
    value: 1,
    label: 'São Cristóvão e Nevis',
    continent: 'América Central',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagSanMarino,
    value: 378,
    label: 'São Marinho',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagSaintPierreAndMiquelon,
    value: 508,
    label: 'SaintPierre e Miquelon',
    continent: 'América do Norte',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagSaoTomeAndPrincipe,
    value: 239,
    label: 'São Tomé e Príncipe',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagSaintVincentAndTheGrenadines,
    value: 1,
    label: 'São Vicente e Granadinas',
    continent: 'América Central',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagSeychelles,
    value: 248,
    label: 'Seicheles',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagSenegal,
    value: 221,
    label: 'Senegal',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagSierraLeone,
    value: 232,
    label: 'Serra Leoa',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagSerbia,
    value: 381,
    label: 'Sérvia',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagSingapore,
    value: 65,
    label: 'Singapura',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagSyria,
    value: 963,
    label: 'Síria',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagSomalia,
    value: 252,
    label: 'Somália',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagSriLanka,
    value: 94,
    label: 'Sri Lanka',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagSwaziland,
    value: 268,
    label: 'Essuatíni / Suazilândia',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagSudan,
    value: 249,
    label: 'Sudão',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagSouthSudan,
    value: 211,
    label: 'Sudão do Sul',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagSweden,
    value: 46,
    label: 'Suécia',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagSwitzerland,
    value: 41,
    label: 'Suíça',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagSuriname,
    value: 597,
    label: 'Suriname',
    continent: 'América do Sul',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagTajikistan,
    value: 992,
    label: 'Tadjiquistão',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagThailand,
    value: 66,
    label: 'Tailândia',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagRepublicOfChina,
    value: 886,
    label: 'República da China',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagTanzania,
    value: 255,
    label: 'Tanzânia',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagBiot,
    value: 246,
    label: 'Território Britânico do Oceano Índico',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagTimorLeste,
    value: 670,
    label: 'TimorLeste',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagTogo,
    value: 228,
    label: 'Togo',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagTokelau,
    value: 690,
    label: 'Tokelau',
    continent: 'Oceania',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagTonga,
    value: 676,
    label: 'Tonga',
    continent: 'Oceania',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagTrinidadAndTobago,
    value: 1,
    label: 'Trinidad e Tobago',
    continent: 'América Central',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagTunisia,
    value: 216,
    label: 'Tunísia',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagTurksAndCaicosIslands,
    value: 1,
    label: 'Turcas e Caicos',
    continent: 'América Central',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagTurkmenistan,
    value: 993,
    label: 'Turquemenistão',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagTurkey,
    value: 90,
    label: 'Turquia',
    continent: 'Ásia//Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagTuvalu,
    value: 688,
    label: 'Tuvalu',
    continent: 'Oceania',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagUkraine,
    value: 380,
    label: 'Ucrânia',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagUganda,
    value: 256,
    label: 'Uganda',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagUruguay,
    value: 598,
    label: 'Uruguai',
    continent: 'América do Sul',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagUzbekistan,
    value: 998,
    label: 'Uzbequistão',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagVanuatu,
    value: 678,
    label: 'Vanuatu',
    continent: 'Oceania',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagVaticanCity,
    value: 379,
    label: 'Vaticano',
    continent: 'Europa',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagVenezuela,
    value: 58,
    label: 'Venezuela',
    continent: 'América do Sul',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagVietnam,
    value: 84,
    label: 'Vietnã',
    continent: 'Ásia',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagWallisAndFutuna,
    value: 681,
    label: 'Wallis e Futuna',
    continent: 'Oceania',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagZambia,
    value: 260,
    label: 'Zâmbia',
    continent: 'África',
    frequent: false,
  },
  {
    id: 1,
    image: Images.countryFlagZimbabwe,
    value: 263,
    label: 'Zimbábue',
    continent: 'África',
    frequent: false,
  },
];
