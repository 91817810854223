import {
  IInputSelect,
} from '../../../apis/interfaces/App/input-select.interface';



export const waistArray: Array<IInputSelect> = [
  { id: 1, label: '30 cm - US 14.1', value: 'WAIST_30CM' },
  { id: 2, label: '31 cm - US 14.2', value: 'WAIST_31CM' },
  { id: 3, label: '32 cm - US 14.3', value: 'WAIST_32CM' },
  { id: 4, label: '33 cm - US 14.4', value: 'WAIST_33CM' },
  { id: 5, label: '34 cm - US 16.1', value: 'WAIST_34CM' },
  { id: 6, label: '35 cm - US 16.2', value: 'WAIST_35CM' },
  { id: 7, label: '36 cm - US 16.3', value: 'WAIST_36CM' },
  { id: 8, label: '37 cm - US 16.4', value: 'WAIST_37CM' },
  { id: 9, label: '38 cm - US 18.1', value: 'WAIST_38CM' },
  { id: 10, label: '39 cm - US 18.2', value: 'WAIST_39CM' },
  { id: 11, label: '40 cm - US 18.3', value: 'WAIST_40CM' },
  { id: 12, label: '41 cm - US 18.4', value: 'WAIST_41CM' },
  { id: 13, label: '42 cm - US 20.1', value: 'WAIST_42CM' },
  { id: 14, label: '43 cm - US 20.2', value: 'WAIST_43CM' },
  { id: 15, label: '44 cm - US 20.3', value: 'WAIST_44CM' },
  { id: 16, label: '45 cm - US 20.4', value: 'WAIST_45CM' },
  { id: 17, label: '46 cm - US 16.1', value: 'WAIST_46CM' },
  { id: 18, label: '47 cm - US 16.2', value: 'WAIST_47CM' },
  { id: 19, label: '48 cm - US 16.3', value: 'WAIST_48CM' },
  { id: 20, label: '49 cm - US 16.4', value: 'WAIST_49CM' },
  { id: 21, label: '50 cm - US 18.1', value: 'WAIST_50CM' },
  { id: 22, label: '51 cm - US 18.2', value: 'WAIST_51CM' },
  { id: 23, label: '52 cm - US 18.3', value: 'WAIST_52CM' },
  { id: 24, label: '53 cm - US 18.4', value: 'WAIST_53CM' },
  { id: 25, label: '54 cm - US 20.1', value: 'WAIST_54CM' },
  { id: 26, label: '55 cm - US 20.2', value: 'WAIST_55CM' },
  { id: 27, label: '56 cm - US 20.3', value: 'WAIST_56CM' },
  { id: 28, label: '57 cm - US 20.4', value: 'WAIST_57CM' },
  { id: 29, label: '58 cm - US 22.1', value: 'WAIST_58CM' },
  { id: 30, label: '59 cm - US 22.2', value: 'WAIST_59CM' },
  { id: 31, label: '60 cm - US 22.3', value: 'WAIST_60CM' },
  { id: 32, label: '61 cm - US 22.4', value: 'WAIST_61CM' },
  { id: 33, label: '62 cm - US 24.1', value: 'WAIST_62CM' },
  { id: 34, label: '63 cm - US 24.2', value: 'WAIST_63CM' },
  { id: 35, label: '64 cm - US 24.3', value: 'WAIST_64CM' },
  { id: 36, label: '65 cm - US 25.1', value: 'WAIST_65CM' },
  { id: 37, label: '66 cm - US 25.2', value: 'WAIST_66CM' },
  { id: 38, label: '67 cm - US 26', value: 'WAIST_67CM' },
  { id: 39, label: '68 cm - US 27.1', value: 'WAIST_68CM' },
  { id: 40, label: '69 cm - US 27.2', value: 'WAIST_69CM' },
  { id: 41, label: '70 cm - US 28.1', value: 'WAIST_70CM' },
  { id: 42, label: '71 cm - US 28.2', value: 'WAIST_71CM' },
  { id: 43, label: '72 cm - US 29.1', value: 'WAIST_72CM' },
  { id: 44, label: '73 cm - US 29.2', value: 'WAIST_73CM' },
  { id: 45, label: '74 cm - US 29.3', value: 'WAIST_74CM' },
  { id: 46, label: '75 cm - US 30.1', value: 'WAIST_75CM' },
  { id: 47, label: '76 cm - US 30.2', value: 'WAIST_76CM' },
  { id: 48, label: '77 cm - US 31.1', value: 'WAIST_77CM' },
  { id: 49, label: '78 cm - US 31.2', value: 'WAIST_78CM' },
  { id: 50, label: '79 cm - US 32.1', value: 'WAIST_79CM' },
  { id: 51, label: '80 cm - US 32.2', value: 'WAIST_80CM' },
  { id: 52, label: '81 cm - US 32.3', value: 'WAIST_81CM' },
  { id: 53, label: '82 cm - US 33.1', value: 'WAIST_82CM' },
  { id: 54, label: '83 cm - US 33.2', value: 'WAIST_83CM' },
  { id: 55, label: '84 cm - US 34.1', value: 'WAIST_84CM' },
  { id: 56, label: '85 cm - US 34.2', value: 'WAIST_85CM' },
  { id: 57, label: '86 cm - US 35.1', value: 'WAIST_86CM' },
  { id: 58, label: '87 cm - US 35.2', value: 'WAIST_87CM' },
  { id: 59, label: '88 cm - US 35.3', value: 'WAIST_88CM' },
  { id: 60, label: '89 cm - US 36.1', value: 'WAIST_89CM' },
  { id: 61, label: '90 cm - US 36.2', value: 'WAIST_90CM' },
  { id: 62, label: '91 cm - US 37.1', value: 'WAIST_91CM' },
  { id: 63, label: '92 cm - US 37.2', value: 'WAIST_92CM' },
  { id: 64, label: '93 cm - US 38.1', value: 'WAIST_93CM' },
  { id: 65, label: '94 cm - US 38.2', value: 'WAIST_94CM' },
  { id: 66, label: '95 cm - US 39.1', value: 'WAIST_95CM' },
  { id: 67, label: '96 cm - US 39.2', value: 'WAIST_96CM' },
  { id: 68, label: '97 cm - US 40.1', value: 'WAIST_97CM' },
  { id: 69, label: '98 cm - US 40.2', value: 'WAIST_98CM' },
  { id: 70, label: '99 cm - US 41.1', value: 'WAIST_99CM' },
  { id: 71, label: '100 cm - US 41.2', value: 'WAIST_100CM' },
];
