import styled from 'styled-components';



export const SuggestionH4 = styled.h4`
  margin-top: 14px;
  margin-bottom: 10px;
  font-size: calc(14px + .3vw);
  font-weight: normal;
`;

export const SuggestionTitleSpan = styled.span`
  color: #00a9e7;
`;

export const SuggestionSubTitleSpan = styled.span`
  color: #ccc;
`;
