import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  UpdateUserPicture,
} from '../../../../../apis/endpoints/users-medias.endpoint';

import {
  Overlay,
} from '../../../../../components/Composh/web';

import FooterChoose from '../../../../../components/Footers/FooterChoose';
import HeaderModal from '../../../../../components/Headers/HeaderModal';
import LoadingScreen from '../../../../../components/Loadings/LoadingScreen';
import ImageUpload from '../../../../../components/Picture/ImageUpload';

import {
  RootState,
} from '../../../../../redux';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../../../redux/reducers/app-modals.store';

import {
  USER_SET,
} from '../../../../../redux/reducers/user.store';

import {
  ArrayColors,
} from '../../../../../shared/arrays';

import {
  Colors,
  Values,
} from '../../../../../shared/constants';

import {
  EUploadType,
} from '../../../../../shared/enums';

import EAppMediaTypeRelation from '../../../../../shared/enums/Picture/app-media-type-relation.enum';
import EAppMediaVisibility from '../../../../../shared/enums/Status/app-media-visibility.enum';

import {
  TranslateConfig,
} from '../../../../../shared/translations';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../../../shared/utils/errors.utils';

import {
  ButtonText,
} from '../../../../../styles/styled.layout';

import {
  GCPictureContainer,
  GCPictureTitle,
  GCPictureImageView,
  MediaChooseContainer,
} from './styled';



export interface IProps {
  type: EAppMediaTypeRelation;
  visible?: boolean;
  title?: string;
  description?: string;
  accept: string;
  onClose?: any;
}



const ImageUserAddModal: React.FC<IProps> = (props: IProps) => {
  const visibleModal = props.visible;
  if (!visibleModal) {
    return null;
  }


  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);
  const userId = user?.id;


  const [picture, setPicture] = useState(null);
  const [itemPicture, setItemPicture] = useState(false);

  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function updateForm() {
    setTimeout(() => {
      setMounted(true);
    }, Values.mountTime);
  }


  function picCloseModal() {
    setPicture(null);
    setItemPicture(null);

    if (props.onClose) {
      props.onClose();
    }
  }


  async function editImageUser() {
    setLoading(true);
    dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

    const payload = {
      typeRelation: props.type,
      visibility: EAppMediaVisibility.PUBLIC,
    };

    try {
      const response = await UpdateUserPicture(userId, picture, payload);

      if (!response?.data) {
        showSnackBarProps(Colors.danger, translate(TranslateConfig.ERROR_UPDATE));
      }

      dispatch({ type: USER_SET, payload: response?.data });
      showSnackBarProps(Colors.accept, translate(TranslateConfig.SUCCESS_UPDATE));
      picCloseModal();
    }
    catch (error: any) {
      console.error(error);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function onPressPictureChange(event: any) {
    const eventImageFile = event.target.files[0];

    if (event) {
      setPicture(eventImageFile);
      setItemPicture(true);
    }
    else {
      setPicture(null);
      setItemPicture(false);
    }
  };



  useEffect(() => {
    updateForm();
  }, []);



  return (

    <Overlay
      visible={visibleModal}
      showBackground={true}
      overlayColor={Colors.menuOverlay}
      onOverlayPress={() => {
        picCloseModal();
      }}>

      <MediaChooseContainer>

        <HeaderModal
          title={props.title || '-'}
          onPress={() => {
            picCloseModal();
          }}
        />



        {!mounted && (
          <LoadingScreen
            type={'SCREEN'}
            color={Colors.primary}
          />
        )}



        {mounted && (
          <GCPictureContainer>

            <GCPictureTitle>
              {props.description || '-'}
            </GCPictureTitle>



            <GCPictureImageView>

              <ImageUpload
                disabled={loading}
                accept={props.accept}
                idButton={EUploadType.PICTURE_USER_UPLOAD}
                fileImage={picture}
                onPress={(event: any) => {
                  onPressPictureChange(event);
                }}
              />

            </GCPictureImageView>

          </GCPictureContainer>
        )}



        <FooterChoose
          cancelColor={ArrayColors.arrayCancel}
          cancelLabel={
            <ButtonText
              color={Colors.white}>
              {translate(TranslateConfig.ACTION_CANCEL)}
            </ButtonText>
          }
          cancelPress={() => {
            picCloseModal();
          }}

          okDisabled={!picture || !itemPicture || loading}
          okColor={!picture || !itemPicture || loading
            ? ArrayColors.arrayDisabled
            : ArrayColors.arrayOk
          }
          okLabel={
            <ButtonText
              color={Colors.white}>
              {translate(TranslateConfig.ACTION_SAVE)}
            </ButtonText>
          }
          okPress={() => {
            editImageUser();
          }}
        />

      </MediaChooseContainer>

    </Overlay>

  );
};



export default ImageUserAddModal;
