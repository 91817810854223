import React,
{
  useState,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
} from 'react-redux';

import {
  Container,
  Content,
  Screen,
  Title,
} from '../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../components/Controls';

import FooterApp from '../../../components/Footers/FooterApp';
import HeaderHelp from '../../../components/Headers/HeaderHelp';
import HeaderScreen from '../../../components/Headers/HeaderScreen';

import {
  IconEdit,
} from '../../../components/Icons';

import SectionTitle from '../../../components/Titles/SectionTitle';

import {
  RootState,
} from '../../../redux';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import EAppMediaTypeRelation from '../../../shared/enums/Picture/app-media-type-relation.enum';

import {
  TranslateConfig,
} from '../../../shared/translations';

import InfoUserImage from './Infos';

import ImageUserAddModal from './Modals/ImageUserAdd';
import ImageUserInfoModal from './Modals/ImageUserInfo';

import {
  PrincipalContentPadder,
  ContentCenterFlex,
  PrincipalImagesContainer,
  PrincipalAvatarContainer,
  PrincipalAvatarContent,
  PrincipalAvatarImage,
  PrincipalAvatarIcon,
  MediaTypeContent,
} from './styled';



const UserImageProfileScreen: React.FC = () => {
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);

  const userPicture = user?.picture || Images.avatarUserImage;


  const [showImageModal, setShowImageModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet prioritizeSeoTags>
        <title>
          {`${translate(TranslateConfig.PROFILE_IMAGE)} | ${translate(TranslateConfig.NAME_APP)}`}
        </title>

        <meta name="robots" content="noindex, nofollow" />
      </Helmet>



      <Container>

        <HeaderScreen
          disableGender
          showBalance
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={
            <ButtonBack
              color={Colors.white}
              size={27}
            />
          }
          centerContent={
            <Title>
              {translate(TranslateConfig.PROFILE_IMAGE)}
            </Title>
          }
        />



        <Content>

          <PrincipalContentPadder>

            <ContentCenterFlex>

              <HeaderHelp
                title={translate(TranslateConfig.MEDIA_PROFILE_EDIT)}
                onHelpPress={() => {
                  setShowInfoModal(true);
                }}
              />



              <PrincipalImagesContainer>

                <PrincipalAvatarContainer>

                  <SectionTitle
                    title={translate(TranslateConfig.PROFILE_PICTURE)}
                    color={Colors.primary}
                  />


                  <PrincipalAvatarContent
                    onClick={() => {
                      setShowImageModal(true);
                    }}>
                    <PrincipalAvatarImage
                      src={userPicture}
                    />

                    <PrincipalAvatarIcon>
                      <IconEdit
                        color={Colors.white}
                        size={20}
                      />
                    </PrincipalAvatarIcon>
                  </PrincipalAvatarContent>

                </PrincipalAvatarContainer>

              </PrincipalImagesContainer>

            </ContentCenterFlex>



            <MediaTypeContent>
              <InfoUserImage />
            </MediaTypeContent>

          </PrincipalContentPadder>



          <FooterApp />

        </Content>

      </Container>



      <ImageUserAddModal
        type={EAppMediaTypeRelation.USER_IMAGE}
        visible={showImageModal}
        title={translate(TranslateConfig.ACTION_PHOTO_ADD)}
        description={translate(TranslateConfig.PROFILE_PICTURE)}
        accept={'image/jpeg, image/png'}
        onClose={() => {
          setShowImageModal(false);
        }}
      />



      <ImageUserInfoModal
        visible={showInfoModal}
        onClose={() => {
          setShowInfoModal(false);
        }}>
        <InfoUserImage />
      </ImageUserInfoModal>

    </Screen>

  );
};



export default UserImageProfileScreen;
