import styled from 'styled-components';

import {
  ListFlat,
  Scrolls,
} from '../../../components/Composh/web';

import {
  ContentPadder,
} from '../../../components/Contents/ContentPadder';

import {
  GetPlatform,
} from '../../../config/plataform.config';

import {
  Sizes,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';

import {
  ScrollbarHideStyle,
  ScrollbarHorizontalStyle,
} from '../../../styles/scrolls.layout';

const paddingFlatMobile = Sizes.paddingFlatList + 'px';



export const ContentWrapperRowFull = styled(ContentPadder)`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;

  @media ${PlatformDevices.maxLaptop} {
    padding-left: 0;
    padding-right: 0;
  }
`;


export const ContentCenterFlex = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;


export const MediaHeaderPadderTop = styled(Scrolls.Horizontal)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;

  @media ${PlatformDevices.maxTablet} {
    padding-left: 10px;
    padding-right: 10px;
  }

  ${GetPlatform() === 'WEB' ? ScrollbarHorizontalStyle : ScrollbarHideStyle}
`;



export const MediaPhotoGridView = styled(ListFlat).attrs({
  contentContainerStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: paddingFlatMobile,
    paddingLeft: 1.5,
    paddingRight: 1.5,
  },
})`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
