import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';



export interface IProps {
  backgroundColor?: string;
  color?: string;
}



export const BadgeSkewX = styled.p`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  margin-left: 5px;
  padding-top: 5px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 5px;
  background-color: ${(props: IProps) => props.backgroundColor || Colors.primaryDark};
  color: ${(props: IProps) => props.color || Colors.white};
  font-size: 11px;
  line-height: 24px;
  text-align: center;
  white-space: nowrap;
  transform: skewX(-15deg);
`;
