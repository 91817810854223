import SecretioWS from '../index';



export const GetAllRents = async (genderSearch: string, location: any) => {
  const url = '/app/rent';

  const payload = {
    gender: genderSearch,
    ...location,
  };

  const response = await SecretioWS.get(url, {
    params: payload,
  });
  return response.data;
};



export const PutRentChangeLevel = async (useralias: string, payload: any) => {
  const url = `/app/rent-modele/level/${useralias}`;

  const response = await SecretioWS.put(url, payload);
  return response.data;
};



export const CancelRentChangeLevel = async (useralias: string, payload: any) => {
  const url = `/app/rent-modele/level/${useralias}`;

  const response = await SecretioWS.put(url, payload);
  return response.data;
};



export const PutRentCallAttention = async (useralias: string, payload: any) => {
  const url = `/app/rent-modele/attention/${useralias}`;

  const response = await SecretioWS.put(url, payload);
  return response.data;
};
