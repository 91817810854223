import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';



export const FinancialValuesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;

  @media ${PlatformDevices.maxTablet} {
    flex-direction: column;
  }
`;


export const WrapperItemContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 25px;
`;

export const CreditValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 140px;
  max-height: 140px;
  padding-top: 5px;
  padding-bottom: 5px;
  transition: 0.1s all ease-in-out;

  @media ${PlatformDevices.maxTablet} {
    min-width: 100%;
    max-width: 100%;
  }

  @media ${PlatformDevices.minTablet} {
    min-width: 50%;
    max-width: 50%;
    padding-left: 5px;
    padding-right: 5px;
  }

  @media ${PlatformDevices.minLaptop} {
    min-width: 25%;
    max-width: 25%;
    padding-left: 9px;
    padding-right: 9px;

    :hover {
      transform: scale(1.05);
    }
  }
`;

export const CreditValueContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;

  @media ${PlatformDevices.maxTabletM} {
    width: 100%;
  }
`;


export const CreditValueHeader = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CreditValueLabel = styled.p`
  display: flex;
  flex: 1;
  margin-top: 0;
  color: ${Colors.white};
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 1.5rem;
`;

export const ShowValueButton = styled.a`
  display: flex;
  flex-direction: row;
`;

export const CreditValuePriceView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CreditValueIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`;

export const CreditCoinImage = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
  object-fit: contain;
`;

export const CreditValueText = styled.p`
  color: ${Colors.white};
  font-size: 26px;

  line-height: 1.2;
  font-weight: 300;
`;


export const PayFinancialContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
  gap: 20px;

  @media ${PlatformDevices.maxTabletL} {
    flex-direction: column;
  }
`;
