import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../shared/constants';

import {
  PlatformDevices,
} from '../../shared/utils/platforms.utils';



export const TravelContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 705px;
  height: 550px;
  background-color: ${Colors.alertBackground};
  border-radius: ${Sizes.cardRadius}px;

  @media ${PlatformDevices.maxTabletM} {
    width: 300px;
    height: 550px;
  }
`;



export const TravelBodyContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-items: center;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
`;


export const TravelCurrentLocationContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 15px 10px;
  border-bottom: 1px solid ${Colors.toolbarLine};

  @media ${PlatformDevices.maxTabletM} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const TravelLocationContent = styled(TravelCurrentLocationContent)`
  @media ${PlatformDevices.maxTabletM} {
    height: 115px;
  }
`;

export const TravelCurrentView = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

export const TravelViewIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 12px;
`;

export const TravelCurrentInfoView = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TravelCurrentInfoTitle = styled.p`
  margin-bottom: 4px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
`;

export const TravelCurrentInfoText = styled.p`
  color: ${Colors.white};
  font-size: 18px;
  font-weight: 500;
`;

export const TravelCountTitle = styled.p`
  margin-top: 4px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 13px;
`;

export const TravelCurrentDateText = styled.p`
  max-width: 180px;
  color: ${Colors.white};
  font-size: 15px;
  line-height: 20px;
  text-align: right;

  @media ${PlatformDevices.maxTabletM} {
    margin-top: 5px;
    text-align: center;
  }
`;
