import {
  Colors,
} from '../shared/constants';

import {
  ERoomType,
} from '../shared/enums';



export function SetLiveRoomStatusColor(type: ERoomType) {
  switch (type) {
    case ERoomType.BASIC:
      return Colors.accept;

    case ERoomType.BREAK:
      return Colors.attention;

    case ERoomType.PARTY_AUCTION:
      return Colors.warning;

    case ERoomType.PARTY_QUESTION:
      return Colors.warning;

    case ERoomType.PREGROUP:
      return Colors.warning;

    case ERoomType.PARTY:
      return Colors.transMale;

    case ERoomType.GROUP:
      return Colors.male;

    case ERoomType.PRIVATE:
      return Colors.sketch;

    case ERoomType.EXCLUSIVE:
      return Colors.danger;

    case ERoomType.OFFLINE:
      return Colors.disabled;

    case ERoomType.PROMO:
      return Colors.primaryDark;

    default:
      return Colors.disabled;
  }
}

