import styled from 'styled-components';



export const ContentBox = styled.div`
  background-color: ${'#fff'};;
  border-radius: ${({ theme }) => '12px'};
  padding: 20px;
  margin-top: 30px;
  box-shadow: 0px 4px 4px ${'#D7DCE1'};

  & > div {
    display: grid;
    gap: 26px;
  }
`;
