import styled,
{
  css,
} from 'styled-components';



const widthTermometer = 175;



export const TipMeterWrapContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: ${widthTermometer}px;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0,0,0,0.35) 0%, rgba(0,0,0,0.18) 73%, rgba(0,0,0,0.01) 87%, transparent 100%);
  z-index: 6;
`;



// Winner

export const TipMeterWinners = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 105px;
  padding: 15px 5px 5px 12px;
  background: linear-gradient(to bottom, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0.47) 27%, transparent 100%);
`;

export const TipMeterTrophy = styled.img`
  width: 26px;
  height: 26px;
  margin-right: 10px;
  object-fit: center;
`;

export const TipMeterInfoWin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const TipMeterTopText = styled.h4`
  margin-bottom: 3px;
  color: #f0feda;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 2px;
  text-shadow: 0px 0px 4px #8ef472;
`;

export const TipMeterUsernameText = styled.p`
  font-weight: bold;
  color: #73f84f;
  font-size: 11px;
  text-shadow: 1px 1px #000001;
`;

export const TipMeterStatusView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4px;
  line-height: 1.3;
`;

export const TipMeterCurrentlyText = styled.p`
  color: #FFFFFF;
  font-size: 11px;
`;

export const TipMeterSuccessText = styled.p`
  margin-top: 2px;
  font-size: 18px;
  color: #73f84f !important;
`;

export const TipMeterCreditsText = styled.p`
  color: #FFFFFF;
  font-size: 13px;
`;



// Tip Meter

export const TipMeterContent = styled.div`
  width: 18px;
  height: 60%;
  border-radius: 0px 0px 20px 20px;
  background: linear-gradient(to left, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.32) 49%, rgba(0,0,0,0.4) 97%, rgba(0,0,0,0.6) 100%);
  position: relative;
  top: 21px;
  margin: 35px auto 0 auto;
  border-left: 1px solid rgba(255,255,255,0.2);
  border-right: 1px solid rgba(255,255,255,0.4);
  z-index: 2;
  box-shadow: 5px 5px 4px rgb(0 0 0 / 30%);
`;



// Goal Shadows

export const TipSectionShadow = css`
  position: absolute;
  background: linear-gradient(to bottom, rgba(0,0,0,0.15) 0%, transparent 100%);
  height: 40px;
  width: ${widthTermometer}px;
  left: -${(widthTermometer / 2) - 8}px;
`;

export const TipShadow1 = styled.div`
  ${TipSectionShadow};

  display: block;
  bottom: calc(33% - 38px);
`;

export const TipShadow2 = styled.div`
  ${TipSectionShadow};

  display: block;
  bottom: calc(66% - 38px);
`;

export const TipShadow3 = styled.div`
  ${TipSectionShadow};

  display: block;
  bottom: calc(100% - 42px);
`;



// Meter Bar

export const TipMeterBar = styled.div`
`;

export const TipMeterGradientBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 95%;
  width: 100%;
  background: linear-gradient(to bottom, #f0feda 7%, #f0feda 7%, #8dc63f 35%, #00a7e1 57%, #4a4dc3 79%, #4a4dc3 79%, #9957b8 100%);
  border-radius: 0px 0px 20px 20px;
  transition: all 1s ease-in-out;
`;

// background: #f0feda;
// background: linear-gradient(to left, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.32) 49%, rgba(0,0,0,0.4) 97%, rgba(0,0,0,0.45) 100%);
export const TipMeterTopBar = styled.div`
  position: absolute;
  top: -48px;
  left: -15px;
  height: 50px;
  width: 50px;
  background: #ff0000;
  border-radius: 50px;
  border-left: 1px solid rgba(255,255,255,0.4);
  border-right: 1px solid rgba(255,255,255,0.6);
  transition: all 1s ease-in-out;

  :before {
    content: "";
    position: absolute;
    z-index: 3;
    top: 6px;
    left: -8px;
    height: 20px;
    width: 20px;
    background: radial-gradient(ellipse at center, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 40%);
    border-radius: 10px;
    animation-name: meterShine;
    animation-duration: 5s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
  }

  :after {
    content: "";
    position: absolute;
    top: 8px;
    left: 4px;
    height: 30px;
    width: 30px;
    background: radial-gradient(ellipse at center, rgba(255,255,255,0.4) 0%, rgba(255,255,255,0) 70%);
    border-radius: 30px;
  }
`;

export const TipMeterShineBar = styled.div`
  position: absolute;
  bottom: 3px;
  left: 2px;
  height: calc(100% - 6px);
  border-radius: 0 0 8px 8px;
  width: 8px;
  background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0.01) 1%, rgba(255,255,255,0.19) 19%, rgba(255,255,255,0.3) 100%);
  transition: all 1s ease-in-out;
  z-index: 3;
`;



// Goals

export const TipSectionGoal = css`
  border-top: 1px solid rgba(0,0,0,0.7);
  position: absolute;
  width: 100%;
  left: 0;
  height: 2px;
  z-index: 2;
`;

export const TipSection1 = styled.div`
  ${TipSectionGoal};

  display: block;
  bottom: 33%;
`;

export const TipSection2 = styled.div`
  ${TipSectionGoal};

  display: block;
  bottom: 66%;
`;

export const TipSection3 = styled.div`
  ${TipSectionGoal};

  display: block;
  bottom: calc(100% - 6px);
`;


export const TipSectionTitle = styled.h4`
  position: absolute;
  top: 5px;
  left: -80px;
  width: 60px;
  min-width: 100px;
  height: auto;
  margin-top: 46px;
  color: #FFFFFF;
  font-size: 12px;
  text-align: center;
  text-shadow: 1px 1px #000001;
  transform: rotate(-90deg);
`;

export const TipSectionAmountView = styled.div`
  position: absolute;
  left: 32px;
  width: 50px;
  margin-top: 250%;
`;

export const TipSectionAmount = styled.p`
  color: #FFFFFF;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  text-shadow: 1px 1px #000001;
`;

export const TipSectionCredits = styled.p`
  color: #FFFFFF;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  text-shadow: 1px 1px #000001;
`;
