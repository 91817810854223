import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';



export const RentMavrttypeslist = styled.div`
  display: flex;
  flex-direction: column;
  width: 340px;
  padding: 14px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;

export const RentMavrselection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;



export const RentMaavcatype = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 15px;
  border-radius: ${Sizes.cardRadius}px;
  border-color: ${Colors.white};
  border-style: solid;
  border-width: 2px;
`;

export const RentAdsIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`;

export const RentMatypeass = styled.p`
  color: ${Colors.white};
  font-size: 17px;
  text-transform: uppercase;
  text-align: center;
`;



export const AdsModeleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  padding-top: ${Sizes.paddingContainer + 7}px;
  padding-left: ${Sizes.paddingContainer}px;
  padding-right: ${Sizes.paddingContainer}px;
  padding-bottom: ${Sizes.paddingContainer + 7}px;

  @media ${PlatformDevices.maxLaptop} {
    padding-top: ${Sizes.paddingFlatList + 7}px;
    padding-left: ${Sizes.paddingFlatList}px;
    padding-right: ${Sizes.paddingFlatList}px;
    padding-bottom: ${Sizes.paddingFlatList + 7}px;
  }
`;


export const AdsPreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 25px;
`;
