import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useNavigate,
} from 'react-router-dom';

import CardAdsSuggestion from '../../../../components/Ads';
import CardModeleHallPhoto from '../../../../components/Cards/CardModeleHallPhoto';
import CardModeleHallRecord from '../../../../components/Cards/CardModeleHallRecord';
import CardModeleSuggestion from '../../../../components/Cards/CardModeleSuggestion';
import Content from '../../../../components/Composh/web/Content';
import FooterApp from '../../../../components/Footers/FooterApp';
import SectionTitle from '../../../../components/Titles/SectionTitle';
import TitleHallFame from '../../../../components/Titles/TitleHallFame';
import {
  Images,
} from '../../../../shared/constants';
import {
  ContentCenterFlex,
  ContentWrapperRowFull,
} from '../../../../styles/styled.layout';

import {
  HallFameBodyBackgroundView,
  HallFameBodyContent,
  HallFameBodyWrapperView,
  HallFameHeaderBackground,
  HallFameHeaderInfoText,
  HallFameHeaderInfoTitle,
  HallFameHeaderInfoView,
  HallFameHeaderLogo,
  HallFameHeaderView,
  HallFameRecordsGrid,
  HallFameStarsGrid,
} from './styled';



const TabHallOfFame: React.FC = () => {
  const history = useNavigate();

  const [loading, setLoading] = useState(false);

  const [modeles, setModeles] = useState(null);



  async function loadHome() {
    // const url = '/modeles';
    // const url = 'https://api.npoint.io/4a91d567c122eb0be824';
    // const url = FAKE_LIST;
    // const url = [
    //   {
    //     'id': 3,
    //     'plan': 'GOLD',
    //     'alias': 'Alex Santorinni',
    //     'cover': ProfileScreen2,
    //     'gender': 'MALE',
    //     'verified': true,
    //     'available': false,
    //     'useralias': 'alex_santorinni',
    //   },
    //   {
    //     'id': 4,
    //     'plan': 'GOLD',
    //     'alias': 'Alex Santorinni',
    //     'cover': ProfileScreen2,
    //     'gender': 'MALE',
    //     'verified': false,
    //     'available': false,
    //     'useralias': 'alex_santorinni',
    //   },
    //   {
    //     'id': 5,
    //     'plan': 'GOLD',
    //     'alias': 'Alex Santorinni',
    //     'cover': ProfileScreen2,
    //     'gender': 'MALE',
    //     'verified': true,
    //     'available': false,
    //     'useralias': 'alex_santorinni',
    //   },
    //   {
    //     'id': 6,
    //     'plan': 'GOLD',
    //     'alias': 'Alex Santorinni',
    //     'cover': ProfileScreen2,
    //     'gender': 'MALE',
    //     'verified': false,
    //     'available': false,
    //     'useralias': 'alex_santorinni',
    //   },
    //   {
    //     'id': 7,
    //     'plan': 'GOLD',
    //     'alias': 'Alex Santorinni',
    //     'cover': ProfileScreen2,
    //     'gender': 'MALE',
    //     'verified': false,
    //     'available': false,
    //     'useralias': 'alex_santorinni',
    //   },
    //   {
    //     'id': 8,
    //     'plan': 'GOLD',
    //     'alias': 'Alex Santorinni',
    //     'cover': ProfileScreen2,
    //     'gender': 'MALE',
    //     'verified': false,
    //     'available': false,
    //     'useralias': 'alex_santorinni',
    //   },
    //   {
    //     'id': 9,
    //     'plan': 'GOLD',
    //     'alias': 'Alex Santorinni',
    //     'cover': ProfileScreen2,
    //     'gender': 'MALE',
    //     'verified': false,
    //     'available': false,
    //     'useralias': 'alex_santorinni',
    //   },
    // ];

    try {
      setLoading(true);

      // const response = await SecretioWS.get(url, {
      //   cancelToken: source.token,
      // });

      // setModeles(response.data);

      // const response = url;

      // setModeles(response);
    }
    catch (err) {
      console.error(err);
    }
    finally {
      setLoading(false);
    }
  };



  function renderBigLongItem(item, index) {
    return (

      <CardModeleSuggestion
      />

    );
  }



  useEffect(() => {
    loadHome();
  }, []);



  return (

    <Content>

      <ContentWrapperRowFull>

        <ContentCenterFlex>
          <SectionTitle
            title={'Hall da fama'}
          />


          <HallFameHeaderView>
            <HallFameHeaderBackground
              alt={'Webcam Hall of Fame'}
              src={Images.backgroundRankingHallfameHeader}
            />


            <HallFameHeaderInfoView>
              <HallFameHeaderLogo
                alt={'Webcam Hall of Fame'}
                src={Images.hallfameLogo}
              />

              <HallFameHeaderInfoTitle>
                Help your favorites make the list!
              </HallFameHeaderInfoTitle>

              <HallFameHeaderInfoText>
                The adult webcam Hall of Fame is where we recognize the models who have achieved greatness on Flirt4Free. While records are made to be broken, the bar set by the performers below will not be easy to surpass. Help your favorites make the list!
              </HallFameHeaderInfoText>
            </HallFameHeaderInfoView>
          </HallFameHeaderView>



          {[
            {
              title: 'Hall of Fame Records for Most Users',
            },
            {
              title: 'Hall of Fame Records for Most Credits',
            },
            {
              title: 'Additional Hall of Fame Records',
            },
          ].map((section, index) => (
            <HallFameBodyContent
              key={index}>

              <HallFameBodyBackgroundView>
                <HallFameBodyWrapperView>

                  <TitleHallFame
                    title={section.title}
                  />

                </HallFameBodyWrapperView>
              </HallFameBodyBackgroundView>



              <HallFameRecordsGrid>

                <CardModeleHallRecord
                />
                <CardModeleHallRecord
                />
                <CardModeleHallRecord
                />
                <CardModeleHallRecord
                />
                <CardModeleHallRecord
                />

              </HallFameRecordsGrid>

            </HallFameBodyContent>
          ))}



          <HallFameBodyContent>

            <HallFameBodyBackgroundView>
              <HallFameBodyWrapperView>

                <TitleHallFame
                  title={'Estrelas'}
                />

              </HallFameBodyWrapperView>
            </HallFameBodyBackgroundView>



            <HallFameStarsGrid>

              <CardModeleHallPhoto
              />
              <CardModeleHallPhoto
              />
              <CardModeleHallPhoto
              />
              <CardModeleHallPhoto
              />
              <CardModeleHallPhoto
              />
              <CardModeleHallPhoto
              />

            </HallFameStarsGrid>

          </HallFameBodyContent>

        </ContentCenterFlex>



        <CardAdsSuggestion />

      </ContentWrapperRowFull>



      <FooterApp />

    </Content>

  );
};



export default TabHallOfFame;
