import IAppRoute from '../../apis/interfaces/App/app-route.interface';

import AllModeleScreen from '../../screens/All/AllModeles';
import CalendarScreen from '../../screens/Calendar';
import FavoriteScreen from '../../screens/Favorites';
import HelpFaqScreen from '../../screens/Help/FAQ';
import LiveHomeScreen from '../../screens/Live/LiveHome';
import MessagesScreen from '../../screens/Messages';
import FinancialScreen from '../../screens/Modele/Financial';
import PanelModeleScreen from '../../screens/Modele/Panel';
import MyPriveScreen from '../../screens/MyPrive';
import NotificationsScreen from '../../screens/Notifications';
import RentHomeScreen from '../../screens/Rent/RentHome';
import SettingsAppScreen from '../../screens/Settings/Preferences';
import DashboardModeleScreen from '../../screens/User/Dashboard';

import NameRoutes from '../names';



const DrawerRoutes: IAppRoute[] = [

  // Highlights

  {
    path: NameRoutes.MyPriveScreen,
    page: MyPriveScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.RentHomeScreen,
    page: RentHomeScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.LiveHomeScreen,
    page: LiveHomeScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.AllModeleScreen,
    page: AllModeleScreen,
    isPublic: false,
    exact: true,
  },



  // Profile

  {
    path: NameRoutes.DashboardModeleScreen,
    page: DashboardModeleScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.FavoriteScreen,
    page: FavoriteScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.NotificationsScreen,
    page: NotificationsScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.MessagesScreen,
    page: MessagesScreen,
    isPublic: false,
    exact: true,
  },



  // Modele Tools

  {
    path: NameRoutes.PanelModeleScreen,
    page: PanelModeleScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.FinancialScreen,
    page: FinancialScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.CalendarScreen,
    page: CalendarScreen,
    isPublic: false,
    exact: true,
  },



  // Settings

  {
    path: NameRoutes.SettingsAppScreen,
    page: SettingsAppScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.HelpFaqScreen,
    page: HelpFaqScreen,
    isPublic: false,
    exact: true,
  },

];



export default DrawerRoutes;
