import React from 'react';

import {
  IconZodiacAquarius,
  IconZodiacAries,
  IconZodiacCancer,
  IconZodiacCapricorn,
  IconZodiacGemini,
  IconZodiacLeo,
  IconZodiacLibra,
  IconZodiacPisces,
  IconZodiacSagittarius,
  IconZodiacScorpio,
  IconZodiacTaurus,
  IconZodiacVirgo,
} from '../components/Icons';

import {
  Colors,
} from '../shared/constants';



export function returnIconZodiac(value: string, color?: string, size?: number) {
  const colorProp = color || Colors.white;
  const sizeProp = size;


  switch (value) {
    case 'ARIES':
      return (
        <IconZodiacAries
          color={colorProp}
          size={sizeProp}
        />
      );

    case 'TAURUS':
      return (
        <IconZodiacTaurus
          color={colorProp}
          size={sizeProp}
        />
      );

    case 'GEMINI':
      return (
        <IconZodiacGemini
          color={colorProp}
          size={sizeProp}
        />
      );

    case 'CANCER':
      return (
        <IconZodiacCancer
          color={colorProp}
          size={sizeProp}
        />
      );

    case 'LEO':
      return (
        <IconZodiacLeo
          color={colorProp}
          size={sizeProp}
        />
      );

    case 'VIRGO':
      return (
        <IconZodiacVirgo
          color={colorProp}
          size={sizeProp}
        />
      );
    case 'LIBRA':
      return (
        <IconZodiacLibra
          color={colorProp}
          size={sizeProp}
        />
      );

    case 'SCORPIO':
      return (
        <IconZodiacScorpio
          color={colorProp}
          size={sizeProp}
        />
      );

    case 'SAGITTARIUS':
      return (
        <IconZodiacSagittarius
          color={colorProp}
          size={sizeProp}
        />
      );

    case 'CAPRICORN':
      return (
        <IconZodiacCapricorn
          color={colorProp}
          size={sizeProp}
        />
      );

    case 'AQUARIUS':
      return (
        <IconZodiacAquarius
          color={colorProp}
          size={sizeProp}
        />
      );

    case 'PISCES':
      return (
        <IconZodiacPisces
          color={colorProp}
          size={sizeProp}
        />
      );

    default:
      break;
  }
};
