import {
  IInputPlaceSelect,
} from '../../../apis/interfaces/App/input-select-place.interface';



const CitiesFullBraRo: Array<IInputPlaceSelect> = [
  {
    id: 1,
    label: 'Ariquemes',
    value: 'ARIQUEMES',
  },
  {
    id: 2,
    label: 'Cacoal',
    value: 'CACOAL',
  },
  {
    id: 3,
    label: 'Ji-Paraná',
    value: 'JI-PARANA',
  },
  {
    id: 4,
    label: 'Porto Velho',
    value: 'PORTO_VELHO',
  },
  {
    id: 5,
    label: 'Vilhena',
    value: 'VILHENA',
  },
  {
    id: 6,
    label: 'Guajará-Mirim',
    value: 'GUAJARA-MIRIM',
  },
  {
    id: 7,
    label: 'Rolim de Moura',
    value: 'ROLIM_DE_MOURA',
  },
  {
    id: 8,
    label: 'Jaru',
    value: 'JARU',
  },
  {
    id: 9,
    label: 'Pimenta Bueno',
    value: 'PIMENTA_BUENO',
  },
  {
    id: 10,
    label: 'Ouro Preto do Oeste',
    value: 'OURO_PRETO_DO_OESTE',
  },
];



export default CitiesFullBraRo;
