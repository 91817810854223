import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import { reviewAmbienceArray } from '../../../shared/arrays/Reviews/review-ambience.array';
import { reviewMediasArray } from '../../../shared/arrays/Reviews/review-medias.array';
import { reviewMeetArray } from '../../../shared/arrays/Reviews/review-meet.array';
import { reviewRecommendArray } from '../../../shared/arrays/Reviews/review-recommend.array';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import {
  EPlansUser,
} from '../../../shared/enums';

import EStatusVisible from '../../../shared/enums/Status/status-visibility.enum';

import {
  TranslateConfig,
} from '../../../shared/translations';

import {
  FormatIsoDateWithPattern,
} from '../../../shared/utils/date.utils';

import UserAvatar from '../../Contents/UserAvatar';

import {
  IconComment,
  IconDelete,
  IconEdit,
  IconEyeOff,
  IconEyeOn,
  IconStar,
} from '../../Icons';

import {
  CardReviewContainer,
  UserReviewContainer,
  UserReviewsBoxHeader,
  UserReviewsInfoBoxHeader,
  UserReviewsInfoHeader,
  UserReviewUserPhoto,
  UserReviewInfoContent,
  UserReviewInfoTitle,
  UserReviewInfoPlaceText,
  UserReviewTimeText,
  UserReviewsBoxHeaderRight,
  UserStarRatingBox,
  UserReviewMakeContainer,
  UserReviewMakeLabel,
  UserReviewMakeInfoContainer,
  UserReviewMakeInfoView,
  UserReviewMakeAliasText,
  UserReviewDescriptionText,
  UserReviewTypeContent,
  UserReviewTypeItemContent,
  UserReviewTypeItemTitle,
  UserReviewTypeItemText,
  ReviewActionsContainer,
  ReviewActionsButton,
  ReviewActionsIcon,
  ReviewActionsText,
} from './styled';



export interface IProps {
  edit?: boolean;
  type?: 'MODELE' | 'USER';

  visible?: EStatusVisible;
  revised?: boolean;
  alias?: any;
  plan?: EPlansUser;
  image?: any;
  createdAt?: string;
  rating?: string;
  dateMeet?: string;
  comment?: string;
  meet?: string;
  ambience?: string;
  medias?: string;
  recommend?: string;

  makeAlias?: string;
  makePlan?: EPlansUser;
  makeImage?: string;
  onImagePress?: any;

  onRespondPress?: any; // Modele
  onVisibleOrRevisdPress?: any; // Modele, Support

  onEditPress?: any; // User
  onDeletePress?: any; // Modele, Support
}



const CardComment: React.FC<IProps> = (props) => {
  const { t: translate } = useTranslation();



  const colorIcon = Colors.primary;
  const sizeIcon = 18;



  function renderEditComments(enabled: boolean, title: string, icon: any, onPress: any) {
    return (

      <ReviewActionsButton
        disabled={!enabled}
        onClick={() => {
          if (enabled && onPress) {
            onPress();
          }
        }}>

        <ReviewActionsIcon>
          {icon}
        </ReviewActionsIcon>


        <ReviewActionsText>
          {title}
        </ReviewActionsText>

      </ReviewActionsButton>

    );
  }



  return (

    <CardReviewContainer>

      <UserReviewContainer>

        <UserReviewsBoxHeader>

          <UserReviewUserPhoto
            onClick={() => {
              if (props.onImagePress) {
                props.onImagePress();
              }
            }}>
            <UserAvatar
              plan={props.plan}
              width={65}
              image={props.image
                ? props.image
                : Images.noImage
              }
            />
          </UserReviewUserPhoto>



          <UserReviewsInfoBoxHeader>

            <UserReviewsInfoHeader>

              <UserReviewInfoContent>

                <UserReviewInfoTitle
                  onClick={() => {
                    if (props.onImagePress) {
                      props.onImagePress();
                    }
                  }}>
                  {props.alias || '-'}
                </UserReviewInfoTitle>


                <UserReviewInfoPlaceText>
                  {props.dateMeet
                    ? `${translate(TranslateConfig.IN)}: ${FormatIsoDateWithPattern(props.dateMeet, 'dd/MM/yyyy')}`
                    : '-'
                  }
                </UserReviewInfoPlaceText>

              </UserReviewInfoContent>



              <UserReviewsBoxHeaderRight>
                <IconStar
                  color={Colors.primary}
                  size={22}
                />

                <UserStarRatingBox>
                  {props.rating || '0'}
                </UserStarRatingBox>
              </UserReviewsBoxHeaderRight>

            </UserReviewsInfoHeader>



            <UserReviewTimeText>
              {props.createdAt
                ? `${translate(TranslateConfig.COMMENTED_AT)}: ${FormatIsoDateWithPattern(props.createdAt, 'dd/MM/yyyy')}`
                : '-'
              }
            </UserReviewTimeText>

          </UserReviewsInfoBoxHeader>

        </UserReviewsBoxHeader>



        {props.makeAlias && (
          <UserReviewMakeContainer>

            <UserReviewMakeLabel>
              {translate(TranslateConfig.COMMENTED_BY)}
            </UserReviewMakeLabel>


            <UserReviewMakeInfoContainer>

              <UserAvatar
                plan={props.makePlan}
                width={45}
                image={props.makeImage
                  ? props.makeImage
                  : Images.noImage
                }
              />


              <UserReviewMakeInfoView>

                <UserReviewMakeAliasText>
                  {props.makeAlias || '-'}
                </UserReviewMakeAliasText>

              </UserReviewMakeInfoView>

            </UserReviewMakeInfoContainer>

          </UserReviewMakeContainer>
        )}



        <UserReviewDescriptionText>
          {props.comment || '-'}
        </UserReviewDescriptionText>



        <UserReviewTypeContent>

          <UserReviewTypeItemContent>
            <UserReviewTypeItemTitle>
              {translate(TranslateConfig.MEETING)}
            </UserReviewTypeItemTitle>

            <UserReviewTypeItemText>
              {reviewMeetArray?.find((itemArray) => itemArray.value === props.meet)?.label || '-'}
            </UserReviewTypeItemText>
          </UserReviewTypeItemContent>


          <UserReviewTypeItemContent>
            <UserReviewTypeItemTitle>
              {translate(TranslateConfig.ENVIRONMENT)}
            </UserReviewTypeItemTitle>

            <UserReviewTypeItemText>
              {reviewAmbienceArray?.find((itemArray) => itemArray.value === props.ambience)?.label || '-'}
            </UserReviewTypeItemText>
          </UserReviewTypeItemContent>


          <UserReviewTypeItemContent>
            <UserReviewTypeItemTitle>
              {translate(TranslateConfig.COMMENT_PICTURES)}
            </UserReviewTypeItemTitle>

            <UserReviewTypeItemText>
              {reviewMediasArray?.find((itemArray) => itemArray.value === props.medias)?.label || '-'}
            </UserReviewTypeItemText>
          </UserReviewTypeItemContent>


          <UserReviewTypeItemContent>
            <UserReviewTypeItemTitle>
              {translate(TranslateConfig.COMMENT_RECOMMEND)}
            </UserReviewTypeItemTitle>

            <UserReviewTypeItemText>
              {reviewRecommendArray?.find((itemArray) => itemArray.value === props.recommend)?.label || '-'}
            </UserReviewTypeItemText>
          </UserReviewTypeItemContent>

        </UserReviewTypeContent>

      </UserReviewContainer>



      {props.type === 'USER' && props.edit && (
        <ReviewActionsContainer>

          {renderEditComments(
            true,
            translate(TranslateConfig.ACTION_EDIT),
            <IconEdit
              color={colorIcon}
              size={sizeIcon}
            />,
            props.onEditPress,
          )}


          {renderEditComments(
            true,
            translate(TranslateConfig.ACTION_DELETE),
            <IconDelete
              color={colorIcon}
              size={sizeIcon}
            />,
            props.onDeletePress,
          )}

        </ReviewActionsContainer>
      )}


      {props.type === 'MODELE' && props.edit && (
        <ReviewActionsContainer>

          {/*
          {renderEditComments(
            false,
            'Responder',
            <IconComment
              color={colorIcon}
              size={sizeIcon}
            />,
            props.onRespondPress,
          )}
          */}


          {renderEditComments(
            true,
            props.visible === EStatusVisible.VISIBLE
              ? translate(TranslateConfig.ACTION_HIDE_PROFILE)
              : translate(TranslateConfig.ACTION_SHOW_PROFILE),
            props.visible === EStatusVisible.VISIBLE
              ? (
                <IconEyeOff
                  color={colorIcon}
                  size={sizeIcon}
                />
              )
              : (
                <IconEyeOn
                  color={colorIcon}
                  size={sizeIcon}
                />
              ),
            props.onVisibleOrRevisdPress,
          )}

        </ReviewActionsContainer>
      )}

    </CardReviewContainer>

  );
};



export default CardComment;
