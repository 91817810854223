export const feetArray = [
  { id: 1, label: '30 cm - US 6', value: 'FOOT_SIZE_30CM' },
  { id: 2, label: '31 cm - US 7', value: 'FOOT_SIZE_31CM' },
  { id: 3, label: '32 cm - US 8', value: 'FOOT_SIZE_32CM' },
  { id: 4, label: '33 cm - US 9', value: 'FOOT_SIZE_33CM' },
  { id: 5, label: '34 cm - US 10', value: 'FOOT_SIZE_34CM' },
  { id: 6, label: '35 cm - US 11', value: 'FOOT_SIZE_35CM' },
  { id: 7, label: '36 cm - US 12', value: 'FOOT_SIZE_36CM' },
  { id: 8, label: '37 cm - US 13', value: 'FOOT_SIZE_37CM' },
  { id: 9, label: '38 cm - US 14', value: 'FOOT_SIZE_38CM' },
  { id: 10, label: '39 cm - US 15', value: 'FOOT_SIZE_39CM' },
  { id: 11, label: '40 cm - US 16', value: 'FOOT_SIZE_40CM' },
  { id: 12, label: '41 cm - US 17', value: 'FOOT_SIZE_41CM' },
  { id: 13, label: '42 cm - US 18', value: 'FOOT_SIZE_42CM' },
  { id: 14, label: '43 cm - US 19', value: 'FOOT_SIZE_43CM' },
  { id: 15, label: '44 cm - US 20', value: 'FOOT_SIZE_44CM' },
  { id: 16, label: '45 cm - US 21', value: 'FOOT_SIZE_45CM' },
  { id: 17, label: '46 cm - US 22', value: 'FOOT_SIZE_46CM' },
  { id: 18, label: '47 cm - US 23', value: 'FOOT_SIZE_47CM' },
  { id: 19, label: '48 cm - US 24', value: 'FOOT_SIZE_48CM' },
  { id: 20, label: '49 cm - US 25', value: 'FOOT_SIZE_49CM' },
  { id: 21, label: '50 cm - US 26', value: 'FOOT_SIZE_50CM' },
];
