import styled,
{
  keyframes,
} from 'styled-components';

import InputText from '../../../../components/Inputs/InputText';

import {
  Colors,
  Sizes,
} from '../../../../shared/constants';

import {
  PlatformDevices,
} from '../../../../shared/utils/platforms.utils';



export const PayAttentionContainer = styled.div`
  display: flex;
  flex-direction: row;
`;


export const PayAttentionInfoView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 20px;
  padding-top: 3px;
`;

export const PayAttentionInfoTitle = styled.p`
  margin-bottom: 8px;
  color: ${Colors.white};
  font-size: 20px;
  font-weight: bold;
`;

export const PayAttentionInfoText = styled.p`
  margin-top: 2px;
  margin-bottom: 2px;
  color: ${Colors.grayLight};
  font-size: 14px;
`;

export const PayAttentionHighlightText = styled(PayAttentionInfoText)`
  margin-bottom: 7px;
  line-height: 26px;
`;


const LongPulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 ${Colors.pulseAnimation};
  }

  70% {
    box-shadow: 0 0 0 8px rgba(0, 255, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 255, 0, 0);
  }
`;

export const PayAttentionHighlightSpan = styled.span`
  margin-left: 4px;
  margin-right: 4px;
  padding-top: 4px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 4px;
  color: ${Colors.pulse};
  font-weight: bold;
  border-color: ${Colors.pulse};
  border-style: solid;
  border-width: 1px;
  border-radius: ${Sizes.cardRadius}px;
  animation: ${LongPulseAnimation} 2s infinite;
`;

export const PayAttentionSpanBold = styled.span`
  font-weight: bold;
`;



export const RentAttentionExampleView = styled.div`
  display: flex;
  flex-direction: column;
  height: 330px;
  min-width: 160px;
`;

export const RentAttentionExampleText = styled.p`
  margin-bottom: 15px;
  color: ${Colors.grayLight};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
`;



export const PayAttentionDetPcView = styled.div`
  display: flex;
  flex-direction: column;

  @media ${PlatformDevices.maxTablet} {
    display: none;
  }
`;

export const PayAttentionDetMobileView = styled.div`
  display: none;
  flex-direction: column;

  @media ${PlatformDevices.maxTablet} {
    display: flex;
  }
`;


export const PayAttentionDetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;


export const PayAttentionPriceContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin-top: 20px;

  @media ${PlatformDevices.maxTablet} {
    align-items: center;
    max-width: 100%;
  }
`;

export const PayAttentionCurrentText = styled.p`
  margin-top: 5px;
  margin-bottom: 10px;
  color: ${Colors.pulse};
  font-size: 24px;
  font-weight: bold;
`;

export const PayAttentionLineRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const InpuTextPayAttention = styled(InputText)`
  width: 160px;
`;

export const PayValueAttentionPrice = styled.p`
  margin-left: 15px;
  color: ${Colors.white};
  font-size: 16px;
  text-align: center;
`;

export const PayChooseProContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 25px;
`;
