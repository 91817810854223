import styled from 'styled-components';



export interface IProps {
  backgroundColor?: string;
}



export const DotContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 15px;
`;

export const DotItem = styled.a`
  align-self: center;
  width: 7px;
  height: 7px;
  margin-left: 4px;
  margin-right: 4px;
  background-color: ${(props: IProps) => props.backgroundColor};
  border-radius: 10px;
`;
