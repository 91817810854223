import {
  EGenderUser,
} from '../../enums';



export const attendanceModele = [
  {
    id: 1,
    label: 'Homem',
    value: EGenderUser.MAN,
  },
  {
    id: 2,
    label: 'Mulher',
    value: EGenderUser.WOMAN,
  },
  {
    id: 3,
    label: 'Trans Homem',
    value: EGenderUser.TRANS_MAN,
  },
  {
    id: 4,
    label: 'Trans Mulher',
    value: EGenderUser.TRANS_WOMAN,
  },
  {
    id: 5,
    label: 'Casal',
    value: EGenderUser.COUPLE,
  },
  {
    id: 6,
    label: 'Grupo',
    value: EGenderUser.GROUP,
  },
];
