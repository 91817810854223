import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';



export interface IProps {
  color?: string;
}



export const FooterAppContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 55px;
  margin-left: 15px;
  margin-right: 15px;
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
  border-top-color: ${Colors.toolbarLine};
  border-top-style: solid;
  border-top-width: 1px;
  z-index: 5;

  @media ${PlatformDevices.maxTabletM} {
    flex-direction: column;
    min-height: auto;
    padding-top: 15px;
    padding-bottom: 15px;
  }
`;


export const FooterListInline = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;

  @media ${PlatformDevices.maxTabletM} {
    width: 100%;
    justify-content: center;
    margin-bottom: 15px;
  }
`;

export const FooterListButton = styled.a`
  display: flex;
  flex-direction: row;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 12px;
  text-decoration: none;
`;

export const FooterListTextButton = styled.p`
  color: ${Colors.white};
  font-size: 13px;
  transition: all 0.35s;

  ${FooterListButton}:hover && {
    color: ${(props: IProps) => props.color ? props.color : Colors.primary};
  }
`;



export const FooterLogoInserted = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  @media ${PlatformDevices.maxTabletM} {
    width: 100%;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const FooterTextYear = styled.p`
  color: ${Colors.white};
  font-size: 15px;
  text-align: center;
`;

export const FooterLogoImageButton = styled.a`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 3px;
`;
