import React from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useLocation,
} from 'react-router-dom';

import {
  Body,
  Content,
  Screen,
  SubTitle,
  Title,
} from '../../../../components/Composh/web';

import {
  ContentPadder,
} from '../../../../components/Contents/ContentPadder';

import {
  ButtonBack,
} from '../../../../components/Controls';

import FooterApp from '../../../../components/Footers/FooterApp';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';

import {
  IconThumbDown,
  IconThumbMiddle,
  IconThumbUp,
} from '../../../../components/Icons';

import {
  openHelpWhatsApp,
} from '../../../../config/linking.config';

import {
  Colors,
} from '../../../../shared/constants';

import {
  TranslateConfig,
} from '../../../../shared/translations';

import {
  FaqSectionTitle,
  FaqCategoryDescription,
} from '../styled';

import {
  HelpActionsContainer,
  HelpVoteContainer,
  HelpVoteText,
  VoteButtonsContent,
  HelpVoteButton,
  HelpContactButton,
  FaqCategoryBullet,
} from './styled';



export interface IRouteLocation {
  category: string;
  bullet: any;
}



const FaqBulletScreen: React.FC = () => {
  const { t: translate } = useTranslation();

  const routeLocation = useLocation();
  const params = routeLocation?.state as IRouteLocation;


  const itemCategory = params?.category;
  const itemFaq = params?.bullet;

  const utillityNo = false;
  const utillityMiddle = false;
  const utillityYes = false;



  function renderButtonVote(vote: boolean, icon: any, onPress: any) {
    return (

      <HelpVoteButton
        // activeOpacity={Sizes.ButtonActiveOpacity}
        onClick={onPress}
        style={vote
          ? {
            backgroundColor: vote ? Colors.primary : Colors.transparent,
            borderColor: Colors.primary,
          }
          : undefined
        }>

        {icon}

      </HelpVoteButton>

    );
  }



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet prioritizeSeoTags>
        <title>
          {`${translate(TranslateConfig.HELP)} | ${translate(TranslateConfig.NAME_APP)}`}
        </title>

        <meta name="robots" content="noindex, nofollow" />
      </Helmet>



      <HeaderScreen
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            color={Colors.white}
          />
        }
        centerContent={
          <Body>
            <Title
              color={Colors.white}>
              {translate(TranslateConfig.HELP)}
            </Title>

            <SubTitle
              color={Colors.white}>
              {itemCategory || '-'}
            </SubTitle>
          </Body>
        }
      />


      <Content>

        <ContentPadder>

          <FaqSectionTitle
            color={Colors.buttonOkLight}>
            {itemFaq?.title || '-'}
          </FaqSectionTitle>


          <FaqCategoryDescription
            color={Colors.white}>
            {itemFaq?.description || '-'}
          </FaqCategoryDescription>



          <HelpActionsContainer>

            <HelpVoteContainer>

              <HelpVoteText>
                {translate(TranslateConfig.HELP_INFO_HELPED)}
              </HelpVoteText>


              <VoteButtonsContent>

                {renderButtonVote(
                  utillityNo,
                  <IconThumbDown
                    color={utillityNo ? Colors.white : Colors.gray}
                    size={28}
                  />,
                  () => {
                    // TODO
                  },
                )}


                {renderButtonVote(
                  utillityMiddle,
                  <IconThumbMiddle
                    color={utillityMiddle ? Colors.white : Colors.gray}
                    size={28}
                  />,
                  () => {
                    // TODO
                  },
                )}


                {renderButtonVote(
                  utillityYes,
                  <IconThumbUp
                    color={utillityYes ? Colors.white : Colors.gray}
                    size={28}
                  />,
                  () => {
                    // TODO
                  },
                )}

              </VoteButtonsContent>

            </HelpVoteContainer>



            <HelpVoteContainer>

              <HelpVoteText>
                {translate(TranslateConfig.HELP_HAVE_DOUBTS)}
              </HelpVoteText>



              <HelpContactButton
                // activeOpacity={Sizes.ButtonActiveOpacity}
                onClick={() => {
                  // FIXME: Automatizar
                  openHelpWhatsApp();
                }}>

                <FaqCategoryBullet
                  color={Colors.buttonOkAccent}>
                  {translate(TranslateConfig.CONTACT_US)}
                </FaqCategoryBullet>

              </HelpContactButton>

            </HelpVoteContainer>

          </HelpActionsContainer>

        </ContentPadder>



        <FooterApp />

      </Content>

    </Screen>

  );
};



export default FaqBulletScreen;
