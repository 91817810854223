import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../shared/constants';



export const WarningOverlayStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9998;
`;

export const WarningOverlay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${Colors.menuOverlay};
  backdrop-filter: blur(15px);
  
  z-index: 9998;
`;



export const WarningInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  width: 82%;
  max-width: 440px;
  max-height: 500px;
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 15px;
  background-color: ${Colors.appBackground};
  border-radius: ${Sizes.cardRadius}px;
  box-shadow: 1px 1px 25px -1px rgba(0, 0, 0, 0.5);
  z-index: 9999 !important;
`;


export const ContainerAdultHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom-color: #ffffff26;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding-bottom: 10px;
`;

export const WarningModelImageContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 45px;
`;

export const WarningInfoLogo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const WarningInfoSlogan = styled.p`
  margin-top: 10px;
  margin-bottom: 2px;
  color: ${Colors.white};
  font-weight: 500;
  font-size: 17px;
  font-weight: bold;
  text-align: center;
`;



export const ContainerAdultContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
`;

export const ContainerAdultIcon = styled.div`
  width: 56;
  height: 32;
  margin-right: 14px;
`;

export const ContainerAdultInfoTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerAdultTitle = styled.h3`
  color: ${Colors.white};
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
`;

export const ContainerAdultTitleBold = styled(ContainerAdultTitle)`
  margin-top: 2px;
  font-weight: bold;
`;



export const WarningInfoAccessContent = styled.p`
  margin-top: 15px;
  margin-bottom: 15px;
  color: ${Colors.white};
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
`;

export const WarningInfoTermsButton = styled.a`
  color: ${Colors.primary};
  font-weight: 500;
`;



export const WarningLangButtonView = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;



export const ContainerAdultFooter = styled.div`
  display: flex;
  flex-direction: column;
  border-top-color: #ffffff26;
  border-top-style: solid;
  border-top-width: 1px;
`;

export const ContainerAdultFooterText = styled.p`
  margin-top: 14px;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 20px;
  color: ${Colors.grayLight};
  font-size: 11px;
  line-height: 17px;
  text-align: center;
`;

export const ContainerAdultFooterKnowMore = styled.a`
  color: ${Colors.primary};
  text-decoration-line: underline;
`;



export const WarningInfoAggreeButton = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${Sizes.buttonHeight}px;
  margin-bottom: 10px;
`;
