import React from 'react';

import {
  Body,
  Screen,
  SubTitle,
  Title,
} from '../../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../../components/Controls';

import HeaderScreen from '../../../../components/Headers/HeaderScreen';

import {
  Colors,
} from '../../../../shared/constants';



const ModelePlanManageScreen: React.FC = () => {
  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        disableGender
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            color={Colors.white}
            size={27}
          />
        }
        centerContent={
          <Body>
            <Title>
              Gerenciar plano
            </Title>

            <SubTitle>
              Modelo
            </SubTitle>
          </Body>
        }
      />



    </Screen>

  );
};



export default ModelePlanManageScreen;
