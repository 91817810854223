import styled from 'styled-components';

import {
  Sizes,
} from '../../shared/constants';

import {
  PlatformDevices,
} from '../../shared/utils/platforms.utils';



export const HomeMyPriveLogo = styled.img`
  width: 110px;
  object-fit: contain;
`;



export const HomeViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
`;

export const HomeRowWrapperAuto = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media ${PlatformDevices.maxLaptopL} {
    overflow: auto;
  }

  @media ${PlatformDevices.minTabletM} {
    align-items: center;
  }

  @media ${PlatformDevices.minLaptopL} {
    align-items: normal;
  }
`;

export const HomeRowPaddingAuto = styled.div`
  display: flex;
  flex-direction: row;

  @media ${PlatformDevices.maxLaptopL} {
    flex-direction: column;
    padding-top: ${Sizes.paddingFlatList + 7}px;
    padding-left: ${Sizes.paddingFlatList}px;
    padding-right: ${Sizes.paddingFlatList}px;
  }

  @media ${PlatformDevices.minLaptopL} {
    height: 100%;
    padding-bottom: 55px;
  }
`;



// Footer

export const HomeFooterInsideContainer = styled.div`
  display: none;
  flex-direction: column;
  width: 100%;
  
  @media ${PlatformDevices.maxLaptopL} {
    display: flex;
  }
`;

export const HomeFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  bottom: 0;

  @media ${PlatformDevices.maxLaptopL} {
    display: none;
  }
`;
