import styled from 'styled-components';


export const FinancialBillingContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  gap: 12px;
`;

export const CreditTableContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 18px 1px rgba(49,53,72,.1);
  border-radius: .45rem;
`;

export const CreditTableHeader = styled.div`
  padding: 1.5rem;
`;

export const CreditTableTitle = styled.h4`
  color: #ffffff;
  font-weight: 500;
  letter-spacing: .05rem;
  font-size: 1.12rem;
`;
