import React from 'react';

import {
  ChipSuggestionChatView,
  ChipSuggestionChatText,
} from './styled';



const MessageSuggestion: React.FC = () => {
  return (

    <ChipSuggestionChatView>
      <ChipSuggestionChatText>
        Hi, nice to meet you :)
      </ChipSuggestionChatText>
    </ChipSuggestionChatView>

  );
};



export default MessageSuggestion;
