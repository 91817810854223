import React from 'react';

import {
  Colors,
} from '../../../shared/constants';

import {
  Switch,
} from '../../Composh/web';

import {
  InputSwitchStyled,
  InputSwitchTextStyled,
  InputSwitchViewStyled,
} from './styled';



export interface IProps {
  disabled?: boolean;
  selected?: boolean;
  title: string;
  description: string;
  onPress?: any;
}



const InputSwitch: React.FC<IProps> = (props: IProps) => {
  const className = { ...props } as any;

  const selectedState = props.selected;



  return (

    <InputSwitchViewStyled
      className={className?.className}

      labelText={props.title || '-'}
      labelColor={Colors.inputLabel}
      backgroundColor={Colors.inputBackground}
      countLimit={selectedState ? 'Clique para desativar' : 'Clique para ativar'}
      countColor={Colors.textDescription}>

      <InputSwitchStyled
        onClick={() => {
          if (props.onPress) {
            props.onPress();
          }
        }}>

        <InputSwitchTextStyled
          color={Colors.inputLabel}>
          {props.description || '-'}
        </InputSwitchTextStyled>


        <Switch
          hideText
          disabled={props.disabled}
          selected={selectedState}
          color={Colors.white}
          selectedColor={Colors.primary}
        />

      </InputSwitchStyled>

    </InputSwitchViewStyled>

  );
};



export default InputSwitch;
