import {
  createAction,
  createReducer,
} from '@reduxjs/toolkit';



const INITIAL_STATE = {
  messages: [],
};



// Action types & creators

export const addMessage = createAction<object>('ADD_MESSAGE');
export const removeMessage = createAction<object>('REMOVE_MESSAGE');


// Handlers

const setAddMessage = (state: any, action: any) => ({
  ...state,
  messages: [
    ...state.messages,
    action.payload,
  ],
});

const setRemoveMessage = (state: any, action: any) => ({
  ...state,
  messages: state.messages.filter((mg: any) => mg !== action.payload),
});


// Reducers

export default createReducer(INITIAL_STATE, {
  [addMessage.type]: setAddMessage,
  [removeMessage.type]: setRemoveMessage,
});
