import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
} from 'react-redux';

import {
  SetLightGenreText,
} from '../../../config/genre.config';

import {
  RootState,
} from '../../../redux';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import {
  IconArrowDown,
} from '../../Icons';

import {
  ButtonGenderContainer,
  LogoImage,
  GenderView,
  GenderText,
  IconArrowComponent,
} from './styled';



export interface IProps {
  disabled?: boolean;
  onPress?: any;
}



const ButtonGender: React.FC<IProps> = (props) => {
  const { t: translate } = useTranslation();


  const genderChoose = useSelector((state: RootState) => state.choose.genderChoose);



  return (

    <ButtonGenderContainer
      disabled={props.disabled}
      onClick={!props.disabled ? props.onPress : undefined}>

      <LogoImage
        alt={''}
        src={Images.logoAppFullWhite}
      />


      {!props.disabled && (
        <GenderView>

          <GenderText>
            {translate(SetLightGenreText(genderChoose))}
          </GenderText>


          <IconArrowComponent>
            <IconArrowDown
              color={Colors.white}
              size={22}
            />
          </IconArrowComponent>

        </GenderView>
      )}

    </ButtonGenderContainer>

  );
};



export default ButtonGender;
