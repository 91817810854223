import {
  IInputPlaceSelect,
} from '../../../apis/interfaces/App/input-select-place.interface';



const CitiesFullBraPi: Array<IInputPlaceSelect> = [
  {
    id: 1,
    label: 'Barras',
    value: 'BARRAS',
  },
  {
    id: 2,
    label: 'Campo Maior',
    value: 'CAMPO_MAIOR',
  },
  {
    id: 3,
    label: 'Floriano',
    value: 'FLORIANO',
  },
  {
    id: 4,
    label: 'José de Freitas',
    value: 'JOSE_DE_FREITAS',
  },
  {
    id: 5,
    label: 'Oeiras',
    value: 'OEIRAS',
  },
  {
    id: 6,
    label: 'Parnaíba',
    value: 'PARNAIBA',
  },
  {
    id: 7,
    label: 'Picos',
    value: 'PICOS',
  },
  {
    id: 8,
    label: 'Piripiri',
    value: 'PIRIPIRI',
  },
  {
    id: 9,
    label: 'Teresina',
    value: 'TERESINA',
  },
  {
    id: 10,
    label: 'Valença do Piauí',
    value: 'VALENCA_DO_PIAUI',
  },
];



export default CitiesFullBraPi;
