import React from 'react';
import { useSelector } from 'react-redux';
import ButtonAction from '../../../../components/Buttons/ButtonAction';
import UserAvatar from '../../../../components/Contents/UserAvatar';
import { IconSocialFacebook, IconSocialInstagram, IconSocialTwitter } from '../../../../components/Icons';
import { RootState } from '../../../../redux';
import linkbioThemesArray from '../../../../shared/arrays/LinkBio/linkbio-themes.array';
import { PreviewRoot, ProfileBox, ItemsBox, SocialNetworksBox } from './styled';



const LinkBioFrame: React.FC = () => {
  const user = useSelector((state: RootState) => state.user.actualUser);


  const profileAA = useSelector((state: RootState) => state.linkbio.appearance);
  const profile = profileAA as any;

  const socialAA = useSelector((state: RootState) => state.linkbio.config);
  const social = socialAA as any;

  const items = useSelector((state: RootState) => state.linkbio.items);

  const themesAA = profileAA?.themes as any;
  const themes = themesAA?.list as any;
  const theme = themesAA?.selected as any;

  const fallbackTheme = linkbioThemesArray[0]?.image;


  return (
    <PreviewRoot srcBg={theme ? theme : fallbackTheme}>
      <div>
        <ProfileBox>

          <UserAvatar
            plan={null}
            image={user?.picture}
            width={70}
          />

          {/* <Spacing size={4} /> */}
          <p style={{ color: '#fff' }}>
            {user?.useralias}
          </p>
          <p style={{ color: '#fff' }}>
            {profile?.description}
          </p>
        </ProfileBox>
        {/* <Spacing size={24} /> */}
        <ItemsBox>
          {items.map(
            ({ props }) =>
              props.enabled && (
                <a href={props.url} target='blank' key={'shortid.generate()'}>
                  <li key={props.title}>
                    <ButtonAction
                      title={props.title}
                    />
                  </li>
                </a>
              ),
          )}
        </ItemsBox>
        {/* <Spacing size={24} /> */}
        <SocialNetworksBox>
          {social?.facebook && (
            <a href={social?.facebook} target='blank'>
              <li>
                <IconSocialFacebook size={32} color='white' />
              </li>
            </a>
          )}
          {social?.instagram && (
            <a href={social?.instagram} target='blank'>
              <li>
                <IconSocialInstagram size={28} color='white' />
              </li>
            </a>
          )}
          {social?.twitter && (
            <a href={social?.twitter} target='blank'>
              <li>
                <IconSocialTwitter size={28} color='white' />
              </li>
            </a>
          )}
        </SocialNetworksBox>
      </div>
    </PreviewRoot>
  );
};

export default LinkBioFrame;
