import SecretioWS from '../index';



export const GetGlobalAppParams = async () => {
  const url = '/app/global-params';

  const response = await SecretioWS.get(url);
  return response.data;
};


// Get Dynamic localities
// export const GetLocalitiesApp = async () => {
//   const url = '/app/global-params/localities';
//   const response = await SecretioWS.get(url);

//   return response.data;
// };
