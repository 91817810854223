import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useNavigate,
} from 'react-router-dom';

import {
  GetHelpFaq,
} from '../../../apis/endpoints/help.endpoint';

import {
  Content,
  Container,
  // FAB,
  Screen,
  Title,
  ToolbarGroup,
  // Title,
  // ToolbarGroup,
} from '../../../components/Composh/web';

import {
  ContentPadder,
} from '../../../components/Contents/ContentPadder';

import {
  ButtonDrawer,
  ButtonHelpContact,
  // ButtonMore,
  // ButtonRequests,
} from '../../../components/Controls';

import EmptyContent from '../../../components/Empty';
import FooterApp from '../../../components/Footers/FooterApp';
import HeaderScreen from '../../../components/Headers/HeaderScreen';

import LoadingScreen from '../../../components/Loadings/LoadingScreen';

import {
  openHelpWhatsApp,
} from '../../../config/linking.config';

import NameRoutes from '../../../navigation/names';

import {
  RootState,
} from '../../../redux';

import {
  APP_MENU_SELECTED_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../redux/reducers/app-modals.store';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import EAppHelpNames from '../../../shared/enums/App/app-help-names.enum';
import EDrawerMenu from '../../../shared/enums/App/drawer-menu.enum';

import {
  TranslateConfig,
} from '../../../shared/translations';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../shared/utils/errors.utils';

import {
  FaqSectionContainer,
  FaqSectionTitle,
  FaqCategorySectionTitle,
  FaqCategoryButton,
  FaqCategoryView,
  FaqCategoryTitle,
  FaqCategoryDescription,
  FaqCategoryBullet,
  FaqCategoriesContainer,
} from './styled';



const HelpFaqScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);
  // const faq = useSelector((state: RootState) => state.company.allCompanies);

  const [faq, setFaq] = useState([]);
  const [frequency, setFrequency] = useState<Array<any>>([]);

  const [loading, setLoading] = useState(false);
  // const [refreshing, setRefreshing] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }



  async function getFaq() {
    setLoading(true);

    try {
      const response = await GetHelpFaq(EAppHelpNames.SECRETIO_USER);
      const responseModele = response?.data;

      if (!responseModele) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, translate(TranslateConfig.EMPTY_HOME));
        return;
      }

      const frequencyResponse: Array<any> = [];
      const categoriesResponse: Array<any> = [];

      responseModele?.forEach((item: any) => {
        if (item?.bullets?.length > 0) {
          categoriesResponse.push(item);

          item?.bullets?.forEach((bulletItem: any) => {
            if (bulletItem?.frequent) {
              frequencyResponse.push(bulletItem);
            }
          });
        }
      });

      setFrequency(frequencyResponse);
      setFaq(categoriesResponse);
    }
    catch (error: any) {
      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
    }
  }


  function renderSection(title: string) {
    return (

      <FaqCategorySectionTitle>
        {title}
      </FaqCategorySectionTitle>

    );
  }


  function renderBullet(item: any, index: number) {
    return (

      <FaqCategoryView
        key={index}
        onClick={() => {
          history(
            NameRoutes.FaqBulletScreen,
            {
              state: {
                bullet: item,
                category: item?.category?.title,
              },
            },
          );
        }}>

        <FaqCategoryBullet
          color={Colors.buttonOkLight}>
          {item?.title}
        </FaqCategoryBullet>

      </FaqCategoryView>

    );
  }


  function renderItem(item: any, index: number) {
    return (

      <FaqCategoryButton
        key={index}
        // activeOpacity={Sizes.ButtonActiveOpacity}
        onClick={() => {
          history(
            NameRoutes.FaqCategoryScreen,
            {
              state: {
                categories: item,
              },
            },
          );
        }}>

        <FaqCategoryTitle
          color={Colors.buttonOkLight}>
          {item.title}
        </FaqCategoryTitle>


        <FaqCategoryDescription
          color={Colors.white}>
          {item.subtitle}
        </FaqCategoryDescription>

      </FaqCategoryButton>

    );
  }


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.white}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noHelp}
        title={translate(TranslateConfig.EMPTY_HELP)}
        description={translate(TranslateConfig.EMPTY_HELP_CONTACT)}
      />

    );
  }



  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.HELP });
    getFaq();
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet prioritizeSeoTags>
        <title>
          {`${translate(TranslateConfig.HELP)} | ${translate(TranslateConfig.NAME_APP)}`}
        </title>

        <meta name="robots" content="noindex, nofollow" />
      </Helmet>



      <Container>

        <HeaderScreen
          disableGender
          showBalance
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={
            <ButtonDrawer
              color={Colors.white}
            />
          }
          centerContent={
            <Title>
              {translate(TranslateConfig.HELP)}
            </Title>
          }
          rightIcon={
            <ToolbarGroup>

              <ButtonHelpContact
                color={Colors.white}
                onPress={() => {
                  // FIXME: Automatizar
                  openHelpWhatsApp();
                }}
              />


              {/*
              <ButtonRequests
                color={Colors.white}
              />
              */}

            </ToolbarGroup>
          }
        />



        <Content>

          {loading && (
            renderLoading()
          )}



          {!loading && faq?.length === 0 && (
            listEmptyComponent()
          )}



          {!loading && (frequency?.length > 0 || faq?.length > 0) && (
            <ContentPadder>

              <FaqSectionContainer>

                <FaqSectionTitle>
                  {`${translate(TranslateConfig.HI)} ${user?.alias.split(' ')[0]},`}
                </FaqSectionTitle>


                <FaqCategoryDescription
                  color={Colors.white}>
                  {translate(TranslateConfig.HELP_FAQ_TEXT)}
                </FaqCategoryDescription>

              </FaqSectionContainer>



              {frequency?.length > 0 && (
                <FaqCategoriesContainer>
                  {renderSection(translate(TranslateConfig.HELP_COMMON_QUESTIONS))}

                  {frequency.map((item, index: number) => (
                    renderBullet(item, index)
                  ))}
                </FaqCategoriesContainer>
              )}



              {faq?.length > 0 && (
                <FaqCategoriesContainer>
                  {renderSection(translate(TranslateConfig.HELP_CATEGORIES))}

                  {faq?.map((item, index: number) => (
                    renderItem(item, index)
                  ))}
                </FaqCategoriesContainer>
              )}

            </ContentPadder>
          )}



          <FooterApp />

        </Content>

      </Container>

    </Screen>

  );
};



export default HelpFaqScreen;
