import React from 'react';

import {
  useSelector,
} from 'react-redux';

import ButtonAction from '../../components/Buttons/ButtonAction';

import {
  Overlay,
} from '../../components/Composh/web';

import {
  IconVerifiedPromoter,
} from '../../components/Icons';

import {
  RootState,
} from '../../redux';

import {
  Colors,
} from '../../shared/constants';

import {
  CategoryChooseContainer,
  VerifiedModalItemView,
  CategoryChooseIcon,
  VerifiedModalTitle,
  VerifiedModalTextsView,
  VerifiedModalDescription,
  VerifiedModalMoreText,
} from './styled';



export interface IProps {
  visible?: boolean;
  onClose?: any;
}



const ModeleBrandPromoterModal: React.FC<IProps> = (props: IProps) => {
  const visibleModal = props.visible;
  if (!visibleModal) {
    return null;
  }


  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);



  return (

    <Overlay
      visible={visibleModal}
      showBackground={true}
      overlayColor={Colors.menuOverlay}
      onOverlayPress={props.onClose}>

      <CategoryChooseContainer>

        <VerifiedModalItemView>

          <CategoryChooseIcon>
            <IconVerifiedPromoter
              size={36}
            />
          </CategoryChooseIcon>


          <VerifiedModalTitle>
            Selo de Influência Secretio
          </VerifiedModalTitle>

        </VerifiedModalItemView>



        <VerifiedModalTextsView>

          <VerifiedModalDescription>
            Este anúncio pertence a uma pessoa Embaixadora ou Influenciadora produtora de conteúdo do Secretio.
          </VerifiedModalDescription>


          {projectParams?.showBlog && (
            <VerifiedModalMoreText
              onClick={() => {
                // TODO
              }}>
              Para conhecer os conteúdos oficias do Secretio, clique aqui.
            </VerifiedModalMoreText>
          )}

        </VerifiedModalTextsView>



        <ButtonAction
          title={'Fechar'}
          onPress={() => {
            if (props.onClose) {
              props.onClose();
            }
          }}
        />

      </CategoryChooseContainer>

    </Overlay>

  );
};



export default ModeleBrandPromoterModal;
