import {
  initReactI18next,
} from 'react-i18next';

import i18n from 'i18next';

import {
  ELanguage,
} from '../enums';

// import en_us from './en-us.translations';
// import es_ar from './es-ar.translations';
// import fr_fr from './fr-fr.translations';
// import it_it from './it-it.translations';
import pt_br from './pt-br.translations';

import TranslateInteface from './translates.keys';


export const defaultLanguage = ELanguage.PT_BR;


export const resourcesLanguage: Record<ELanguage, { translation: Record<string, string> }> = {
  pt_br: { translation: pt_br },

  // es_ar: { translation: es_ar },
  // en_us: { translation: en_us },
  // it_it: { translation: it_it },
  // fr_fr: { translation: fr_fr },
};



i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  resources: resourcesLanguage,
  fallbackLng: defaultLanguage,
  debug: false, // Enable for debug messages in the console
  interpolation: {
    escapeValue: false, // Not needed for React as it escapes by default
  },
});



export const TranslateConfig = TranslateInteface;



export default i18n;
