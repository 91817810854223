import {
  IInputSelect,
} from '../../../apis/interfaces/App/input-select.interface';



export const heightsArray: Array<IInputSelect> = [
  { id: 1, label: '160 cm - 5\' 3"', value: 'HEIGHT_160CM' },
  { id: 2, label: '161 cm - 5\' 3.4"', value: 'HEIGHT_161CM' },
  { id: 3, label: '162 cm - 5\' 3.8"', value: 'HEIGHT_162CM' },
  { id: 4, label: '163 cm - 5\' 4"', value: 'HEIGHT_163CM' },
  { id: 5, label: '164 cm - 5\' 4.6"', value: 'HEIGHT_164CM' },
  { id: 6, label: '165 cm - 5\' 5"', value: 'HEIGHT_165CM' },
  { id: 7, label: '166 cm - 5\' 5.4"', value: 'HEIGHT_166CM' },
  { id: 8, label: '167 cm - 5\' 5.7"', value: 'HEIGHT_167CM' },
  { id: 9, label: '168 cm - 5\' 6"', value: 'HEIGHT_168CM' },
  { id: 10, label: '169 cm - 5\' 6.5"', value: 'HEIGHT_169CM' },
  { id: 11, label: '170 cm - 5\' 7"', value: 'HEIGHT_170CM' },
  { id: 12, label: '171 cm - 5\' 7.3"', value: 'HEIGHT_171CM' },
  { id: 13, label: '172 cm - 5\' 7.7"', value: 'HEIGHT_172CM' },
  { id: 14, label: '173 cm - 5\' 8"', value: 'HEIGHT_173CM' },
  { id: 15, label: '174 cm - 5\' 8.5"', value: 'HEIGHT_174CM' },
  { id: 16, label: '175 cm - 5\' 9"', value: 'HEIGHT_175CM' },
  { id: 17, label: '176 cm - 5\' 9.3"', value: 'HEIGHT_176CM' },
  { id: 18, label: '177 cm - 5\' 9.7"', value: 'HEIGHT_177CM' },
  { id: 19, label: '178 cm - 5\' 10"', value: 'HEIGHT_178CM' },
  { id: 20, label: '179 cm - 5\' 10.5"', value: 'HEIGHT_179CM' },
  { id: 21, label: '180 cm - 5\' 11"', value: 'HEIGHT_180CM' },
  { id: 22, label: '181 cm - 5\' 11.3"', value: 'HEIGHT_181CM' },
  { id: 23, label: '182 cm - 5\' 11.7"', value: 'HEIGHT_182CM' },
  { id: 24, label: '183 cm - 6\' 0"', value: 'HEIGHT_183CM' },
  { id: 25, label: '184 cm - 6\' 0.4"', value: 'HEIGHT_184CM' },
  { id: 26, label: '185 cm - 6\' 1"', value: 'HEIGHT_185CM' },
  { id: 27, label: '186 cm - 6\' 1.2"', value: 'HEIGHT_186CM' },
  { id: 28, label: '187 cm - 6\' 1.6"', value: 'HEIGHT_187CM' },
  { id: 29, label: '188 cm - 6\' 2"', value: 'HEIGHT_188CM' },
  { id: 30, label: '189 cm - 6\' 2.4"', value: 'HEIGHT_189CM' },
  { id: 31, label: '190 cm - 6\' 2.8"', value: 'HEIGHT_190CM' },
  { id: 32, label: '191 cm - 6\' 3"', value: 'HEIGHT_191CM' },
  { id: 33, label: '192 cm - 6\' 3.6"', value: 'HEIGHT_192CM' },
  { id: 34, label: '193 cm - 6\' 4"', value: 'HEIGHT_193CM' },
  { id: 35, label: '194 cm - 6\' 4.4"', value: 'HEIGHT_194CM' },
  { id: 36, label: '195 cm - 6\' 4.8"', value: 'HEIGHT_195CM' },
  { id: 37, label: '196 cm - 6\' 5"', value: 'HEIGHT_196CM' },
  { id: 38, label: '197 cm - 6\' 5.6"', value: 'HEIGHT_197CM' },
  { id: 39, label: '198 cm - 6\' 6"', value: 'HEIGHT_198CM' },
  { id: 40, label: '199 cm - 6\' 6.3"', value: 'HEIGHT_199CM' },
  { id: 41, label: '200 cm - 6\' 6.7"', value: 'HEIGHT_200CM' },
  { id: 42, label: '201 cm - 6\' 7"', value: 'HEIGHT_201CM' },
  { id: 43, label: '202 cm - 6\' 7.5"', value: 'HEIGHT_202CM' },
  { id: 44, label: '203 cm - 6\' 8"', value: 'HEIGHT_203CM' },
  { id: 45, label: '204 cm - 6\' 8.3"', value: 'HEIGHT_204CM' },
  { id: 46, label: '205 cm - 6\' 8.7"', value: 'HEIGHT_205CM' },
  { id: 47, label: '206 cm - 6\' 9"', value: 'HEIGHT_206CM' },
  { id: 48, label: '207 cm - 6\' 9.5"', value: 'HEIGHT_207CM' },
  { id: 49, label: '208 cm - 6\' 10"', value: 'HEIGHT_208CM' },
  { id: 50, label: '209 cm - 6\' 10.2"', value: 'HEIGHT_209CM' },
  { id: 51, label: '210 cm - 6\' 10.6"', value: 'HEIGHT_210CM' },
  { id: 52, label: '211 cm - 6\' 11"', value: 'HEIGHT_211CM' },
  { id: 53, label: '212 cm - 6\' 11.4"', value: 'HEIGHT_212CM' },
  { id: 54, label: '213 cm - 7\' 0"', value: 'HEIGHT_213CM' },
  { id: 55, label: '214 cm - 7\' 0.2"', value: 'HEIGHT_214CM' },
  { id: 56, label: '215 cm - 7\' 0.6"', value: 'HEIGHT_215CM' },
  { id: 57, label: '216 cm - 7\' 1"', value: 'HEIGHT_216CM' },
  { id: 58, label: '217 cm - 7\' 1.4"', value: 'HEIGHT_217CM' },
  { id: 59, label: '218 cm - 7\' 2"', value: 'HEIGHT_218CM' },
  { id: 60, label: '219 cm - 7\' 2.2"', value: 'HEIGHT_219CM' },
  { id: 61, label: '220 cm - 7\' 3"', value: 'HEIGHT_220CM' },
];
