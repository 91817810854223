export const tribeArray = [
  { id: 1, label: 'Twink', value: 'TWINK' },
  { id: 2, label: 'Jock', value: 'JOCK' },
  { id: 3, label: 'Músculos', value: 'MUSCLES' },
  { id: 4, label: 'Porta ao lado', value: 'NEXT_DOOR' },
  { id: 5, label: 'Punk', value: 'PUNK' },
  { id: 6, label: 'Daddy', value: 'DADDY' },
  { id: 7, label: 'Couro', value: 'LEATHER' },
  { id: 8, label: 'Urso', value: 'BEAR' },
  { id: 9, label: 'Lontra', value: 'OTTER' },
];
