import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';



export const PanelValuesContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  margin-bottom: 25px;

  @media ${PlatformDevices.maxTabletM} {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 35px;
  }
`;



export const PanelValuesInfoContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PanelValuesLabel = styled.p`
  margin-bottom: 3px;
  font-size: 13px;
  font-weight: 400;
  color: ${Colors.subtextApp};
`;

export const PanelValuesText = styled.span`
  color: ${Colors.white};
`;



export const PanelValuesEditButton = styled.a`
  display: flex;
  flex-direction: column;
  align-self: flex-start;

  @media ${PlatformDevices.maxTabletM} {
    margin-top: 15px;
  }
`;

export const PanelValuesEditText = styled.div`
  color: ${Colors.grayLight};
  font-size: 16px;
  text-align: right;
`;
