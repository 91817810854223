import React from 'react';

import {
  Container,
} from './styled';



const RentModeleScreen: React.FC = () => {
  return (

    <Container>
      <h2>
        RentModeleScreen
      </h2>
    </Container>

  );
};



export default RentModeleScreen;
