import React,
{
  useState,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
} from 'react-redux';

import {
  Title,
  Screen,
  Content,
} from '../../components/Composh/web';

import {
  ButtonBack,
} from '../../components/Controls';

import HeaderScreen from '../../components/Headers/HeaderScreen';

import {
  IconDashboard,
} from '../../components/Icons';

import TabBarStyled from '../../components/TabBar';

import {
  RootState,
} from '../../redux';

import {
  Colors,
} from '../../shared/constants';

import {
  TranslateConfig,
} from '../../shared/translations';

import TabGeneralRanking from './Tabs/General';
import TabHallOfFame from './Tabs/HallOfFame';

import TabMonthlyCrush from './Tabs/MonthlyCrush';
import TabYearlyCrush from './Tabs/YearlyCrush';

import ScreenYearlyCrush1 from './Tabs/YearlyCrush1';



const RankingsScreen: React.FC = () => {
  const { t: translate } = useTranslation();


  const genderColorChoose = useSelector((state: RootState) => state.choose.genderColorApp);
  const accentGender = genderColorChoose;

  const iconTabBar = 23;


  const [stateIndex, setStateIndex] = useState(0);


  const screenTabs = [
    {
      visible: true,
      name: 'RANKING',
      title: 'Acompanhante',
      icon: (
        <IconDashboard
          color={stateIndex === 0 ? accentGender : Colors.inputCount}
          size={iconTabBar}
        />
      ),
      component: (
        <TabGeneralRanking />
      ),
    },
    {
      visible: true,
      name: 'MONTHLY_CRUSH',
      title: 'Crushs do mês',
      icon: (
        <IconDashboard
          color={stateIndex === 1 ? accentGender : Colors.inputCount}
          size={iconTabBar}
        />
      ),
      component: (
        <TabMonthlyCrush />
      ),
    },
    {
      visible: true,
      name: 'YEARLY_CRUSH',
      title: 'Crushs do ano',
      icon: (
        <IconDashboard
          color={stateIndex === 2 ? accentGender : Colors.inputCount}
          size={iconTabBar}
        />
      ),
      component: (
        <TabYearlyCrush />
      ),
    },
    {
      visible: true,
      name: 'HALL_FAME',
      title: 'Hall da fama',
      icon: (
        <IconDashboard
          color={stateIndex === 3 ? accentGender : Colors.inputCount}
          size={iconTabBar}
        />
      ),
      component: (
        <TabHallOfFame />
      ),
    },
    {
      visible: true,
      name: 'FAME1',
      title: 'Y1',
      icon: (
        <IconDashboard
          color={stateIndex === 4 ? accentGender : Colors.inputCount}
          size={iconTabBar}
        />
      ),
      component: (
        <ScreenYearlyCrush1 />
      ),
    },
  ];



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet prioritizeSeoTags>
        <title>
          {`${translate('Rankings')} | ${translate(TranslateConfig.NAME_APP)}`}
        </title>

        <meta name="robots" content="noindex, nofollow" />
      </Helmet>



      <HeaderScreen
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            color={Colors.black}
            size={27}
          />
        }
        centerContent={
          <Title
            color={Colors.black}>
            Rankings
          </Title>
        }
        noSubToolbarPadding>


        <TabBarStyled
          tabs={screenTabs}
          selected={stateIndex}
          onPress={(tab) => {
            setStateIndex(tab?.index);
          }}
        />

      </HeaderScreen>



      <Content>

        {stateIndex === 0 && screenTabs[0].component}

        {stateIndex === 1 && screenTabs[1].component}

        {stateIndex === 2 && screenTabs[2].component}

        {stateIndex === 3 && screenTabs[3].component}

        {stateIndex === 4 && screenTabs[4].component}

      </Content>

    </Screen>

  );
};



export default RankingsScreen;
