import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useNavigate,
} from 'react-router-dom';

import {
  GetUserCreditCard,
} from '../../../../apis/endpoints/app-global-payment.endpoints';

import {
  PutRentCallAttention,
} from '../../../../apis/endpoints/rent.endpoint';

import ButtonAction from '../../../../components/Buttons/ButtonAction';
import CardModeles from '../../../../components/Cards/CardModeles';

import {
  Body,
  Container,
  Content,
  Screen,
  SubTitle,
  Title,
} from '../../../../components/Composh/web';

import {
  ContentPadder,
} from '../../../../components/Contents/ContentPadder';

import {
  ButtonBack,
} from '../../../../components/Controls';

import FooterApp from '../../../../components/Footers/FooterApp';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../../components/Loadings/LoadingScreen';
import PaymentChoose from '../../../../components/Payments/PaymentChoose';
import PaymentIsention from '../../../../components/Payments/PaymentIsention';

import {
  openSite,
} from '../../../../config/linking.config';

import {
  FormatMoney,
} from '../../../../config/mask.config';

import PaymentsModal from '../../../../modals/Payments';

import {
  RootState,
} from '../../../../redux';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../../redux/reducers/app-modals.store';

import {
  Colors,
  Sizes,
  Values,
} from '../../../../shared/constants';

import {
  ETokenAsync,
} from '../../../../shared/enums';

import Vars from '../../../../shared/environments/variables';

import {
  FormatIsoDateWithPattern,
} from '../../../../shared/utils/date.utils';

import {
  getLocalStorageItem,
} from '../../../../shared/utils/local-storage.utils';

import {
  validateString,
} from '../../../../shared/utils/string.utils';

import {
  PayAttentionContainer,
  PayAttentionInfoView,
  PayAttentionInfoTitle,
  PayAttentionInfoText,
  PayAttentionHighlightText,
  PayAttentionHighlightSpan,
  PayAttentionSpanBold,
  RentAttentionExampleView,
  RentAttentionExampleText,
  PayAttentionDetPcView,
  PayAttentionDetMobileView,
  PayAttentionDetailsContent,
  PayAttentionPriceContent,
  PayAttentionCurrentText,
  PayAttentionLineRow,
  InpuTextPayAttention,
  PayValueAttentionPrice,
  PayChooseProContent,
} from './styled';



const RentPayAttentionScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();


  const user = useSelector((state: RootState) => state.user.actualUser);
  const userIdSearch = user?.useralias;


  const priceNowCome = 200;

  const [inputCaption, setInputCaption] = useState('');
  const [totalPrice, setTotalPrice] = useState(0);

  const [paymentModal, setPaymentModal] = useState(false);
  const [mainCreditCard, setMainCreditCard] = useState<any>(null);
  const [cardItem, setCardItem] = useState<any>(null);

  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function getMainCreditCard() {
    setLoading(true);

    try {
      const response = await GetUserCreditCard();
      const responseData = response?.data;

      if (!response || responseData?.length === 0) {
        // FIXME - Transformar em modal
        alert(
          'Você ainda não adicionou um cartão a sua conta',
        );
        // history(NameRoutes);
        return;
      }

      setCardItem(responseData);

      const mainCard = responseData?.filter((e: any) => e?.main === true)[0];
      setMainCreditCard(mainCard);
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : 'Houve um erro';
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
    }
  }


  async function payCallAttentionFunction() {
    const payload = {
      isToPay: user?.plan?.isToPay,
      card: user?.plan?.isToPay ? mainCreditCard?.id : null,
      hours: inputCaption ? Number(inputCaption) : 0,
    };

    try {
      setLoading(true);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await PutRentCallAttention(userIdSearch, payload);

      if (!response?.data) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, 'Erro ao ativar o anúncio');
        return;
      }

      showSnackBarProps(Colors.accept, 'Chamar atenção ativado');
      history(-1);
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : 'Houve um erro';
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function renderItem() {
    const item = user;


    return (

      <CardModeles
        relative
        comeNow
        isOnTravel={false}
        showFlag={false}
        showCock={false}
        showPosition={false}
        image={item?.picture}
        plan={item?.plan?.planCurrent}
        name={item?.alias}
        username={item?.useralias}
        online={item?.online}
        verified={item?.verified}
        brandPromoter={item?.brandPromoter}
        all24h={item?.contacts?.phoneNumber24h}
      />

    );
  }


  function openCreditCardScreen() {
    const token = getLocalStorageItem(ETokenAsync.USER_TOKEN);
    const link = Vars().globalAccountCreditCard;

    const commonParams = `?token=${token}`;
    openSite(`${link}${commonParams}`);
  }


  function renderSectionDetails() {
    return (

      <PayAttentionDetailsContent>

        <PayAttentionHighlightText>
          Você recebe <PayAttentionHighlightSpan>bordas iluminadas chamativas</PayAttentionHighlightSpan> e um aviso de que você está disponível.
        </PayAttentionHighlightText>


        <PayAttentionInfoText>
          Um diferencial é que, caso seu número esteja visível e seja WhatsApp, a mensagem automática do WhatsApp muda e faz referência a você estar livre.
        </PayAttentionInfoText>


        <PayAttentionInfoText>
          Você pode chamar a atenção escolhendo quantas horas gostaria do destaque.
        </PayAttentionInfoText>


        <PayAttentionInfoText>
          O valor de cada hora é
          {' '}
          <PayAttentionSpanBold>
            {FormatMoney(priceNowCome)}
          </PayAttentionSpanBold>
          {' '}
          e conta a partir do momento que o pagamento é validado.
        </PayAttentionInfoText>



        {user?.appRentHighlight?.length > 0 && (
          <PayAttentionPriceContent>
            <PayAttentionCurrentText>
              {user?.appRentHighlight && user?.appRentHighlight[0] && user?.appRentHighlight[0]?.finishedAt
                ? `Já há um destaque em andamento até ${FormatIsoDateWithPattern(user?.appRentHighlight[0]?.finishedAt, 'dd/MM/yyyy - HH:mm')}. Aguarde o destaque terminar para iniciar outro.`
                : 'Já há um destaque em andamento'
              }
            </PayAttentionCurrentText>
          </PayAttentionPriceContent>
        )}


        {user?.appRentHighlight?.length <= 0 && (
          <PayAttentionPriceContent>

            <PayAttentionLineRow>

              <InpuTextPayAttention
                disabled={loading}
                autoCorrect
                autoCapitalize={'words'}
                type={'TEXT'}
                labelText={'Horas *'}
                placeholderText={'Horas'}
                countLimit={Values.hourMaxCount}
                value={inputCaption}
                onChange={(rawText) => {
                  setInputCaption(rawText);
                }}
              />


              <PayValueAttentionPrice>
                Total:
                {' '}
                <PayAttentionSpanBold>
                  {FormatMoney(totalPrice)}
                </PayAttentionSpanBold>
              </PayValueAttentionPrice>

            </PayAttentionLineRow>



            <PayChooseProContent>

              {!user?.plan?.isToPay && (
                <PaymentIsention />
              )}


              {user?.plan?.isToPay && (
                <PaymentChoose
                  cardBrand={mainCreditCard?.card_brand}
                  last4={mainCreditCard?.last4 ? validateString(mainCreditCard?.last4) : null}
                  onPress={() => {
                    setPaymentModal(true);
                  }}
                />
              )}

            </PayChooseProContent>


            {user?.plan?.isToPay && !mainCreditCard && (
              <ButtonAction
                backgroundColor={Colors.buttonAction}
                width={Sizes.buttonMinWidth}
                title={'Adicionar cartão'}
                onPress={() => {
                  openCreditCardScreen();
                }}
              />
            )}


            {(!user?.plan?.isToPay || mainCreditCard) && (
              <ButtonAction
                backgroundColor={Colors.primaryDark}
                width={Sizes.buttonMinWidth}
                title={'Mudar destaque'}
                onPress={() => {
                  payCallAttentionFunction();
                }}
              />
            )}

          </PayAttentionPriceContent>
        )}

      </PayAttentionDetailsContent>

    );
  }


  async function updateForm() {
    await getMainCreditCard();

    setTimeout(() => {
      setMounted(true);
    }, Values.mountTime);
  }



  useEffect(() => {
    updateForm();
  }, []);


  useEffect(() => {
    if (inputCaption) {
      // Converter a quantidade de horas para número
      const hours = parseFloat(inputCaption);

      // Calcular o valor total
      const total = hours * priceNowCome;

      // Atualizar o estado do valor total
      setTotalPrice(total);
    }
    else {
      // Se não houver quantidade de horas, definir o valor total como 0
      setTotalPrice(0);
    }
  }, [inputCaption]);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <Container>

        <HeaderScreen
          disableGender
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={
            <ButtonBack
              color={Colors.white}
              size={27}
            />
          }
          centerContent={
            <Body>
              <Title
                color={Colors.white}>
                Chamar atenção
              </Title>

              <SubTitle
                color={Colors.gray}>
                Acompanhante
              </SubTitle>
            </Body>
          }
        />



        <Content>

          {!mounted && (
            <LoadingScreen
              type={'SCREEN'}
              color={Colors.primary}
            />
          )}



          {mounted && (
            <ContentPadder>

              <PayAttentionContainer>

                <PayAttentionInfoView>

                  <PayAttentionInfoTitle>
                    Está livre? Chame mais atenção!
                  </PayAttentionInfoTitle>


                  <PayAttentionInfoText>
                    Ao ativar essa opção de chamar atenção, seu perfil automáticamente fica nas primeiras colocações do seu nível nos anúncios de acompanhantes.
                  </PayAttentionInfoText>



                  <PayAttentionDetPcView>
                    {renderSectionDetails()}
                  </PayAttentionDetPcView>

                </PayAttentionInfoView>



                <RentAttentionExampleView>

                  <RentAttentionExampleText>
                    Exemplo
                  </RentAttentionExampleText>


                  {renderItem()}

                </RentAttentionExampleView>

              </PayAttentionContainer>



              <PayAttentionDetMobileView>
                {renderSectionDetails()}
              </PayAttentionDetMobileView>

            </ContentPadder>
          )}



          <FooterApp />

        </Content>

      </Container>



      <PaymentsModal
        visible={paymentModal}
        data={cardItem}
        onCancelPress={() => {
          setPaymentModal(false);
        }}
        onPress={(card: any) => {
          setMainCreditCard(card);
          setPaymentModal(false);
        }}
      />

    </Screen>

  );
};



export default RentPayAttentionScreen;
