import styled,
{
  css,
} from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';



export interface IProps {
  clickable?: boolean;
  border?: boolean;
  color?: string;
  backgroundColor?: string;
}



export const RentAttendanceTipsView = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60px;
  background-color: ${(props: IProps) => props.backgroundColor};
  border-radius: ${Sizes.cardRadius}px;
  ${(props: IProps) => props.border && RentAttendanceBorder};

  cursor: ${(props: IProps) => !props.clickable ? 'default' : 'pointer'};
`;


export const RentAttendanceBorder = css`
  border-color: ${Colors.white};
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
`;

export const RentAttendanceTipsIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
`;

export const RentAttendanceTipsText = styled.p`
  margin-top: 10px;
  color: ${(props: IProps) => props.color};
  font-size: 15px;
  text-align: center;
`;
