import styled from 'styled-components';

import InputText from '../../../../components/Inputs/InputText';

import {
  Colors,
} from '../../../../shared/constants';

import {
  PlatformDevices,
} from '../../../../shared/utils/platforms.utils';



export const PayAttentionInfoTitle = styled.p`
  margin-bottom: 8px;
  color: ${Colors.white};
  font-size: 20px;
  font-weight: bold;
`;

export const PayAttentionInfoText = styled.p`
  margin-top: 5px;
  margin-bottom: 5px;
  color: ${Colors.grayLight};
  font-size: 14px;
`;

export const RentBoldText = styled.span`
  font-weight: bold;
`;


export const RentAdsArticleContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 10px;
`;

export const RentAdsArticleTitle = styled.p`
  margin-bottom: 5px;
  color: ${Colors.white};
  font-size: 20px;
  font-weight: bold;
`;

export const RentAdsArticleSpan = styled.span`
  color: ${Colors.primary};
`;

export const RentAdsArticleSuit = styled.p`
  padding-right: 6px;
  color: ${Colors.white};
  font-size: 17px;
`;

export const SuitItemImage = styled.img`
  width: 25px;
  height: 25px;
  margin-left: 3px;
  margin-right: 3px;
  margin-bottom: -5px;
`;


export const PayAttentionPriceContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin-top: 20px;

  @media ${PlatformDevices.maxTablet} {
    align-items: center;
    max-width: 100%;
  }
`;

export const PayAttentionLineRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const InpuTextPayAttention = styled(InputText)`
  width: 170px;
`;

export const PayValueAttentionPrice = styled.p`
  margin-left: 15px;
  color: ${Colors.white};
  font-size: 16px;
  text-align: center;
`;

export const PayChooseProContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 25px;
`;


export const PayChooseRowActionView = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px 10px;

  @media ${PlatformDevices.maxTablet} {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;
