import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';



interface IProps {
  backgroundColor?: string;
}



export const InputChatView = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  /* max-height: 80px; */
  padding-top: 3px;
  background-color: ${(props: IProps) => props.backgroundColor || 'transparent'};
`;

export const SuggestionTextChatView = styled.div`
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 35px;
  margin-bottom: -5px;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const InputTextChatView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  gap: 10px;
`;

export const EmojiChatButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  background-color: ${Colors.inputBackground};
  border-radius: 3px;
`;

export const EmojiChatView = styled.div`
  position: absolute;
  bottom: 48px;
  left: 5px;
`;

export const SendChatButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 35px;
  background-color: ${(props: IProps) => props.backgroundColor || Colors.primary};
  border-radius: 3px;
`;

export const SendChatTextButton = styled.p`
  margin-top: 1px;
  margin-left: 12px;
  color: ${Colors.white};
  font-size: 14px;
`;
