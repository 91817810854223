import {
  differenceInYears,
} from 'date-fns';

import * as Yup from 'yup';

import Mask from '../../components/Composh/plugins/Mask';



const returnParsetDate = (value: any) => {
  const newPureDate = value.replace(/\D/g, '');

  const day = newPureDate.substring(0, 2);
  const month = newPureDate.substring(2, 4);
  const year = newPureDate.substring(4);

  const parsedDay = parseInt(day, 10);
  const parsedMonth = parseInt(month, 10) - 1;
  const parsedYear = parseInt(year, 10);

  return {
    parsedDay,
    parsedMonth,
    parsedYear,
  };
};


const testDate = (value: any) => {
  if (!value) {
    return false;
  }

  const { parsedDay, parsedMonth, parsedYear } = returnParsetDate(value);

  if (parsedDay <= 0 || parsedDay > 31) {
    return false;
  }

  if (parsedMonth < 0 || parsedMonth > 12) {
    return false;
  }

  const lastDayOfMonth = new Date(parsedYear, parsedMonth, 0).getDate();

  if (parsedDay > lastDayOfMonth) {
    return false;
  }

  return true;
};



const testDifferentToday = (value: any) => {
  if (!value) {
    return false;
  }

  const { parsedDay, parsedMonth, parsedYear } = returnParsetDate(value);

  const wroteDate = new Date(parsedYear, parsedMonth, parsedDay);
  const todayDate = new Date();

  if (wroteDate > todayDate) {
    return false;
  }

  return true;
};



const testAgeMax = (value: any) => {
  if (!value) {
    return false;
  }

  const { parsedDay, parsedMonth, parsedYear } = returnParsetDate(value);

  const yearDate = new Date(parsedYear, parsedMonth, parsedDay);

  const years = differenceInYears(new Date(), yearDate);
  if (years < 16) {
    return false;
  }

  return true;
};



Yup.addMethod<Yup.StringSchema>(Yup.string, 'cpfOrUsernameValidation', function (msg) {
  return this.test('cpfOrUsernameValidation', msg, function (value) {
    if (!value) {
      return false;
    }

    if (/^[0-9]/.test(value)) {
      return Mask.isValid('cpf', value);
    }

    return /^[^\d].*/.test(value) && /^[\w.]+$/.test(value);
  });
});



Yup.addMethod<Yup.StringSchema>(Yup.string, 'fullnameValidation', function (msg) {
  return this.test('fullnameValidation', msg, (value) =>
    value ? /^[a-zA-ZçÇáÁéÉíÍóÓúÚâÂêÊîÎôÔûÛãÃõÕüÜàÀèÈìÌòÒùÙýÝñÑ]+\s([a-zA-ZçÇáÁéÉíÍóÓúÚâÂêÊîÎôÔûÛãÃõÕüÜàÀèÈìÌòÒùÙýÝñÑ]+\s)*[a-zA-ZçÇáÁéÉíÍóÓúÚâÂêÊîÎôÔûÛãÃõÕüÜàÀèÈìÌòÒùÙýÝñÑ]+$/.test(value) : false,
  );
});



Yup.addMethod<Yup.StringSchema>(Yup.string, 'cpfValidation', function (msg) {
  return this.test('cpfValidation', msg, (value) =>
    value ? Mask.isValid('cpf', value) : false,
  );
});



Yup.addMethod<Yup.StringSchema>(Yup.string, 'usernameNumberValidation', function (msg) {
  return this.matches(/^[^\d].*/, msg);
});



Yup.addMethod<Yup.StringSchema>(Yup.string, 'usernameLetterValidation', function (msg) {
  return this.matches(/^[\w.]+$/, msg);
});



Yup.addMethod<Yup.StringSchema>(Yup.string, 'dateValidation', function (msg, msg1) {
  return this.matches(
    /^(0?[1-9]|[12][0-9]|3[01])[-./_ ](0?[1-9]|1[012])[-./_ ]((19|20)\d{2})$/,
    msg,
  ).test('dateValidation', msg1, (value) => testDate(value));
});



Yup.addMethod<Yup.StringSchema>(Yup.string, 'dateDifferentToday', function (msg) {
  return this.test('dateDifferentToday', msg, (value) =>
    testDifferentToday(value),
  );
});



Yup.addMethod<Yup.StringSchema>(Yup.string, 'ageMaxValidation', function (msg) {
  return this.test('ageMaxValidation', msg, (value) =>
    testAgeMax(value),
  );
});



Yup.addMethod<Yup.StringSchema>(Yup.string, 'phoneValidation', function (msg) {
  return this.test('phoneValidation', msg, (value) => {
    if (!value) {
      return true;
    }

    return value ? Mask.isValid('phone', value) : false;
  });
});



Yup.addMethod<Yup.StringSchema>(Yup.string, 'dddValidation', function (msg) {
  return this.test('dddValidation', msg, (value) => {
    if (!value) {
      return true;
    }

    // Remove phone string mask
    const ddd = value.replace(/[^\d]/g, '').substring(0, 2);

    // Validates the DDD using a regular expression
    return /^[1-9]{1}\d{1}$/.test(ddd) && !/0$/.test(ddd);
  });
});



Yup.addMethod<Yup.StringSchema>(Yup.string, 'cnpjValidation', function (msg) {
  return this.test('cnpjValidation', msg, (value) =>
    value ? Mask.isValid('cnpj', value) : false,
  );
});



Yup.addMethod<Yup.StringSchema>(Yup.string, 'zipCodeValidation', function (msg) {
  return this.test('zipCodeValidation', msg, (value) =>
    value ? Mask.isValid('zip-code', value) : false,
  );
});



declare module 'yup' {
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
  interface StringSchema {
    cpfOrUsernameValidation(message: string): this;

    fullnameValidation(message: string): this;
    cpfValidation(message: string): this;
    usernameNumberValidation(message: string): this;
    usernameLetterValidation(message: string): this;
    dateValidation(message: string, message1: string): this;
    dateDifferentToday(message: string): this;
    ageMaxValidation(message: string): this;
    phoneValidation(message: string): this;
    dddValidation(message: string): this;
    cnpjValidation(message: string): this;
    zipCodeValidation(message: string): this;
  }
}



export default Yup;
