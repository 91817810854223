import React from 'react';

import {
  Images,
} from '../../../shared/constants';

import {
  CardYearModeleContainer,
  ModeleYearImageBackground,
  ModeleYearRankingText,
  ModeleYearRankingView,
  ModeleYearUserPrize,
  ModeleYearUserText,
  ModeleYearUserView,
} from './styled';



interface IProps {
  rank?: string;
  name?: string;
  username?: string;
  image?: string;
}



const CardModeleRankingYear: React.FC<IProps> = (props) => {
  return (

    <CardYearModeleContainer
      onClick={() => {
        // href="/models/bios/ian-hunk/about.php"
      }}>

      <ModeleYearImageBackground
        src={props.image && props.image !== '' && props.image !== undefined
          ? props.image
          : Images.avatarModeleImage
        }
      />


      <ModeleYearRankingView>
        <ModeleYearRankingText>
          {props.rank}
        </ModeleYearRankingText>
      </ModeleYearRankingView>


      <ModeleYearUserView>
        <ModeleYearUserText>
          {props.name?.toUpperCase()}
        </ModeleYearUserText>

        <ModeleYearUserPrize>
          @{props.username}
        </ModeleYearUserPrize>
      </ModeleYearUserView>

    </CardYearModeleContainer>

  );
};



export default CardModeleRankingYear;
