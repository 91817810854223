import React,
{
  useEffect,
  useState,
} from 'react';

import {
  IInputSelect,
} from '../../apis/interfaces/App/input-select.interface';

// import {
//   ArrayColors,
// } from '../../shared/arrays';

// import ButtonAddLine from '../../components/Buttons/ButtonAddLine';

import {
  Overlay,
  Title,
} from '../../components/Composh/web';

import {
  ButtonBack,
} from '../../components/Controls';

import EmptyContent from '../../components/Empty';
// import FooterChoose from '../../components/Footers/FooterChoose';

import Header from '../../components/Headers/Header';

import {
  IconCircleCheck,
  IconClose,
} from '../../components/Icons';

import InputText from '../../components/Inputs/InputText';
import LoadingScreen from '../../components/Loadings/LoadingScreen';

import {
  Colors,
  Images,
  Values,
} from '../../shared/constants';

import {
  normalizeRawText,
} from '../../shared/utils/string.utils';

import {
  CategoryChooseContainer,
  FilteredInput,
  BankClearButton,
  CategoryFlat,
  CategoryButton,
  CategoryText,
} from './styled';



export interface IProps {
  hideSearch?: boolean;
  title?: string;
  visible?: boolean;
  data: Array<IInputSelect>;
  selectedValue: string;
  setData?: any;
  onClose?: any;
  onOkText?: string;
  onOkPress?: any;
}



const DataSelectModal: React.FC<IProps> = (props: IProps) => {
  const visibleModal = props.visible;
  if (!visibleModal) {
    return null;
  }


  const categories = props.data;

  const [loading, setLoading] = useState(false);

  const [filterCategory, setFilterCategory] = useState('');

  const [allCategories, setAllCategories] = useState<Array<any>>([]);
  const [filterArrayCategories, setFilterArrayCategories] = useState<Array<any>>([]);



  function renderItem(item: any, index: number) {
    return (

      <CategoryButton
        key={index}
        // activeOpacity={Sizes.ButtonActiveOpacity}
        onClick={() => {
          props.setData({
            ...item,
            id: item?.id,
            label: item?.label,
            value: item?.value,
          } as IInputSelect);

          props.onClose();
        }}>


        <CategoryText>
          {item?.label}
        </CategoryText>


        {props.selectedValue === item?.value && (
          <IconCircleCheck
            color={Colors.primary}
          />
        )}

      </CategoryButton>

    );
  }


  function returnListFiltered() {
    const getAllTofilterBankArray = allCategories?.filter((bankFilter: any) => normalizeRawText(bankFilter.label)?.toLowerCase().includes(normalizeRawText(filterCategory)?.toLowerCase() || '') || filterCategory === '');
    setFilterArrayCategories(getAllTofilterBankArray);
  }


  function renderFooter() {
    if (!loading) {
      return null;
    }

    // if (categories.length !== 0) {
    //   return null;
    // }

    return (

      <LoadingScreen
        type={'FOOTER'}
        color={Colors.white}
      />

    );
  }


  function listEmptyComponent() {
    if (loading) {
      return null;
    }

    return (

      <EmptyContent
        image={Images.logoIconApp}
        title={'Sem categorias cadastradas!'}
      />

    );
  }


  // async function refreshList() {
  //   setRefreshing(true);
  //   await getRules();
  //   setRefreshing(false);
  // }



  useEffect(() => {
    setLoading(true);

    setAllCategories(categories);
    setFilterArrayCategories(categories);

    setTimeout(() => {
      setLoading(false);
    }, Values.mountTime);
  }, []);



  return (

    <Overlay
      visible={visibleModal}
      showBackground={true}
      overlayColor={Colors.menuOverlay}
      onOverlayPress={props.onClose}>

      <CategoryChooseContainer>

        <Header
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={
            <ButtonBack
              close
              color={Colors.white}
              onPress={() => {
                props.onClose();
              }}
            />
          }
          centerContent={
            <Title
              color={Colors.white}>
              {props.title}
            </Title>
          }>

          {!props.hideSearch && (
            <FilteredInput>
              <InputText
                disabled={loading}
                type={'TEXT'}
                iconRight={filterCategory === ''
                  ? null
                  : (
                    <BankClearButton
                      onClick={() => {
                        setFilterCategory('');
                      }}>
                      <IconClose
                        color={Colors.black}
                        size={28}
                      />
                    </BankClearButton>
                  )
                }
                placeholderText={'Nome'}
                placeholderColor={Colors.textSubtitle}
                value={filterCategory}
                // helpText={errorMessage}
                // countLimit={Values.nameItem}
                onChange={(rawText: string) => {
                  setFilterCategory(rawText);
                  returnListFiltered();
                }}
              // onBlur={handleBlur('cpf')}
              />
            </FilteredInput>
          )}

        </Header>



        <CategoryFlat style={{
          justifyContent: loading && filterArrayCategories?.length === 0 ? 'center' : 'flex-start',
        }}>

          {/* {filterArrayBank?.length === 0 && loading && ( */}
          {renderFooter()}
          {/* )} */}


          {!loading && (!filterArrayCategories || filterArrayCategories?.length === 0) && (
            listEmptyComponent()
          )}


          {!loading && filterArrayCategories?.length !== 0 && filterArrayCategories?.map((item: any, index: any) => (
            renderItem(item, index)
          ))}

        </CategoryFlat>



        {/* {props.onOkPress && (
          <FooterChoose
            hideCancelButton

            okDisabled={loading}
            okColor={loading
              ? ArrayColors.arrayDisabled
              : ArrayColors.arrayOk
            }
            okPress={props.onOkPress}
            okLabel={
              <ButtonAddLine
                noPadding
                // activeOpacity={Sizes.ButtonActiveOpacity}
                title={props.onOkText}
                color={Colors.white}
              />
            }
          />
        )} */}

      </CategoryChooseContainer>

    </Overlay>

  );
};



export default DataSelectModal;
