import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';


export const MenuAdsButton = styled.a`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const MenuAdsItemView = styled.img`
  width: 100%;
  min-height: 83px;
  background-color: ${Colors.cardBackground};
  object-fit: contain;
`;
