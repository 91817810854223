import styled from 'styled-components';

import ButtonPublish from '../../../components/Buttons/ButtonPublish';

import {
  ListFlat,
} from '../../../components/Composh/web';

import {
  ContentPadder,
} from '../../../components/Contents/ContentPadder';

import {
  Colors,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';



export const TitleUserReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
`;

export const TitleUserReviewLengthText = styled.p`
  color: ${Colors.white};
  font-size: 20px;
`;



export const ButtonAutomaticPublishStyled = styled(ButtonPublish)`
  margin-top: 3px;
  margin-bottom: 10px;
`;


export const ContentWrapperRowFull = styled(ContentPadder)`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;

  @media ${PlatformDevices.maxLaptop} {
    padding-left: 0;
    padding-right: 0;
  }
`;


export const HighlightEventScrollFlatList = styled(ListFlat).attrs({
  style: {
    display: 'flex',
    flexDirection: 'column',
    // paddingLeft: paddingHighlight,
    // paddingRight: paddingHighlight,
  },
  contentContainerStyle: {
    display: 'flex',
    flexDirection: 'column',
  },
})`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
