import React from 'react';

import {
  countriesOption,
} from '../../../shared/arrays/App/countries.array';

import {
  Images,
} from '../../../shared/constants';

import {
  stringRawDataText,
  validateString,
} from '../../../shared/utils/string.utils';

import {
  DashboardProfileHeaderBox,
  DashboardProfileImageContent,
  DashboardProfileImage,
  DashboardProfileInfoContent,
  DashboardProfileNameText,
  DashboardProfileUsernameText,
  DashboardProfilePlaceContent,
  ModelesPlaceAtText,
  DashboardProfilePlaceIcon,
  ModelesPlaceCountry,
  DashboardProfilePlaceText,
} from './styled';



export interface IProps {
  name?: string;
  username?: string;
  image?: string;
  country?: string;
  place?: string;
  onPress?: any;
}



const ItemUser: React.FC<IProps> = (props) => {
  const countryValue = props.country;
  const filterCountry = countriesOption.find((country) => stringRawDataText(country?.code) === stringRawDataText(countryValue));


  const imageUser = validateString(props.image) || Images.avatarUserImage;



  return (

    <DashboardProfileHeaderBox>

      <DashboardProfileImageContent
        onClick={() => {
          // Abrir perfil publico
          // href = "/male_grr"
        }}>

        <DashboardProfileImage
          alt={''}
          src={imageUser}
        />

      </DashboardProfileImageContent>



      <DashboardProfileInfoContent>

        <DashboardProfileNameText>
          {props.name || '-'}
        </DashboardProfileNameText>



        <DashboardProfileUsernameText>
          @{props.username || '-'}
        </DashboardProfileUsernameText>



        {!countryValue && (
          <DashboardProfilePlaceContent>
            <DashboardProfilePlaceText>
              Localização indisponível
            </DashboardProfilePlaceText>
          </DashboardProfilePlaceContent>
        )}



        {countryValue && (
          <DashboardProfilePlaceContent>

            <ModelesPlaceAtText>
              De:
            </ModelesPlaceAtText>


            <DashboardProfilePlaceIcon>
              <ModelesPlaceCountry
                src={filterCountry?.image}
              />
            </DashboardProfilePlaceIcon>


            <DashboardProfilePlaceText>
              {props.place || '-'}
            </DashboardProfilePlaceText>

          </DashboardProfilePlaceContent>
        )}

      </DashboardProfileInfoContent>

    </DashboardProfileHeaderBox>

  );
};



export default ItemUser;
