import styled from 'styled-components';

import {
  ContentPadder,
} from '../../../../../components/Contents/ContentPadder';

import ToggleGrid from '../../../../../components/Toggles/ToggleGrid';

import {
  Colors,
  Sizes,
} from '../../../../../shared/constants';

import {
  PlatformDevices,
} from '../../../../../shared/utils/platforms.utils';



export interface IProps {
  color?: string;
}



export const CharContentCenterRowFlex = styled(ContentPadder)`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  max-width: ${Sizes.contentMaxWidth}px;

  @media ${PlatformDevices.maxTabletM} {
    flex-direction: column;
  }
`;


export const RentContentCenterFlex = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

export const RentWrapperFirstContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 40px;
`;

export const RentWrapperSecondContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;



export const RentHoursContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`;

export const RentHoursImage = styled.img`
  width: 45px;
  height: 45px;
  margin-right: 10px;
  object-fit: contain;
`;

export const RentHoursText = styled.p`
  color: ${Colors.white};
  font-size: 17px;
`;


export const RentTipsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 5px;
  margin-bottom: 10px;
`;


export const RentTipsHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const RentInfoServiceButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30px;
  margin-left: 6px;
  padding-bottom: 10px;
`;


export const RentServicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 5px;
  gap: 40px 0;
`;



export const ToggleModeleMeet = styled(ToggleGrid)`
  display: flex;
  flex-direction: column;
  height: 95px;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  padding-top: 10px;
  padding-left: 7px;
  padding-right: 7px;
  padding-bottom: 10px;

  @media ${PlatformDevices.maxMobileS} {
    width: calc(50% - 10px);
  }

  @media ${PlatformDevices.minMobileS} {
    width: calc(calc(100% / 3) - 10px);
  }

  @media ${PlatformDevices.minTablet} {
    width: calc(25% - 10px);
  }

  @media ${PlatformDevices.minTabletM} {
    width: calc(calc(100% / 6) - 10px);
  }
`;



export const RentPriceContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media ${PlatformDevices.maxTabletM} {
    flex-direction: column-reverse;
  }
`;

export const RentNoPricesText = styled.p`
  margin-top: 10px;
  color: ${Colors.white};
  font-size: 14px;
  line-height: 16px;
`;

export const RentPriceValueContent = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 330px;
  margin-right: 40px;

  @media ${PlatformDevices.maxTabletM} {
    flex: 1;
    min-width: 200px;
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

export const RentPriceValueItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 40px;
  padding-top: 8px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 8px;
  border-bottom-color: rgba(255, 255, 255, .15);
  border-bottom-style: solid;
  border-bottom-width: 1px;
`;

export const RentPricePeriodText = styled.p`
  height: fit-content;
  color: ${Colors.white};
  font-size: 14px;
  line-height: 16px;
`;

export const RentPriceValueText = styled.p`
  margin: 0;
  color: ${(props: IProps) => props.color};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: right;
`;



export const RentPaymentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;

export const RentPaymentsText = styled.h2`
  margin-top: 7px;
  margin-bottom: 3px;
  color: ${Colors.textApp};
  font-size: ${14.5}px;
  line-height: 20.5px;
  white-space: pre-wrap;
`;

export const RentPaymentsContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const RentPaymentsImage = styled.img`
  width: 55px;
  height: 25px;
  object-fit: contain;
`;
