import React from 'react';

import {
  IconItemCouple,
  IconItemGroup,
  IconItemMan,
  IconItemTransMan,
  IconItemTransWoman,
  IconItemWoman,
} from '../components/Icons';

import {
  Colors,
} from '../shared/constants';

import {
  EGenderUser,
} from '../shared/enums';



export function returnIconGenre(value: string | undefined, color?: string, size?: number) {
  const colorProp = color || Colors.white;
  const sizeProp = size;


  if (!value) {
    return;
  }

  switch (value) {
    case EGenderUser.MAN:
      return (
        <IconItemMan
          color={colorProp}
          size={sizeProp}
        />
      );

    case EGenderUser.WOMAN:
      return (
        <IconItemWoman
          color={colorProp}
          size={sizeProp}
        />
      );

    case EGenderUser.TRANS_MAN:
      return (
        <IconItemTransMan
          color={colorProp}
          size={sizeProp}
        />
      );

    case EGenderUser.TRANS_WOMAN:
      return (
        <IconItemTransWoman
          color={colorProp}
          size={sizeProp}
        />
      );

    case EGenderUser.COUPLE:
      return (
        <IconItemCouple
          color={colorProp}
          size={sizeProp}
        />
      );

    case EGenderUser.GROUP:
      return (
        <IconItemGroup
          color={colorProp}
          size={sizeProp}
        />
      );

    default:
      break;
  }
};
