import SecretioWS from '../index';



export const GetAllLiveHome = async (genderSearch: string) => {
  const url = '/app/lives/home';

  const payload = {
    gender: genderSearch,
  };

  const response = await SecretioWS.get(url, {
    params: payload,
  });
  return response.data;
};



export const GetAllLivePromo = async (genderSearch: string) => {
  const url = '/app/lives/promos';

  const payload = {
    gender: genderSearch,
  };

  const response = await SecretioWS.get(url, {
    params: payload,
  });
  return response.data;
};



export const GetLiveDetails = async (idModele: string) => {
  const url = `/app/modele/${idModele}`;

  const response = await SecretioWS.get(url);
  return response.data;
};
