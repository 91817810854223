import React from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  IInputSelect,
} from '../../../../apis/interfaces/App/input-select.interface';

import {
  RootState,
} from '../../../../redux';

import { picureVisibilityGenders } from '../../../../shared/arrays/Picture/visibility.array';

import {
  Colors,
  Images,
} from '../../../../shared/constants';

import EStatusVisible from '../../../../shared/enums/Status/status-visibility.enum';

import {
  validateString,
} from '../../../../shared/utils/string.utils';

import {
  IconEdit,
  IconEyeOff,
  IconEyeOn,
  IconMoney,
} from '../../../Icons';

import {
  ImageBoxContainer,
  ImageBoxContent,
  ImageContainer,
  LazyImage,
  RibbonPhotoLevel,
  RibbonPending,
  CanBuyRibbon,
  ImageActionContent,
  ImageActionButton,
  HideImageContent,
} from './styled';



export interface IProps {
  image?: string;
  visible?: EStatusVisible;
  visibility?: string;
  revised?: boolean;
  canBuy?: boolean;
  onEditPress?: any;
  onShowHidePress?: any;
}



const CardAddImage: React.FC<IProps> = (props: IProps) => {
  const userImage = validateString(props.image) || Images.noImage;


  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);



  return (

    <ImageBoxContainer>
      <ImageBoxContent>

        <ImageContainer>

          <LazyImage
            src={userImage}
          />



          <RibbonPhotoLevel>
            {picureVisibilityGenders?.find((item: IInputSelect) => item.value === props.visibility)?.label || '-'}
          </RibbonPhotoLevel>



          {!props.revised && (
            <RibbonPending>
              Pendente de aprovação
            </RibbonPending>
          )}



          {props.canBuy && (
            <CanBuyRibbon>
              <IconMoney
                color={Colors.white}
                size={18}
              />
            </CanBuyRibbon>
          )}



          <ImageActionContent>

            {projectParams?.showFanClub && (
              <ImageActionButton
                onClick={() => {
                  if (props.onEditPress) {
                    props.onEditPress();
                  }
                }}>
                <IconEdit
                  color={Colors.white}
                  size={18}
                />
              </ImageActionButton>
            )}


            <ImageActionButton
              onClick={() => {
                if (props.onShowHidePress) {
                  props.onShowHidePress();
                }
              }}>
              {props.visible === EStatusVisible.VISIBLE
                ? (
                  <IconEyeOff
                    color={Colors.white}
                    size={18}
                  />
                )
                : (
                  <IconEyeOn
                    color={Colors.white}
                    size={18}
                  />
                )}
            </ImageActionButton>

          </ImageActionContent>

        </ImageContainer>



        {(props.visible === EStatusVisible.INVISIBLE || props.visible === EStatusVisible.HIDE_BY_USER) && (
          <HideImageContent>
            Imagem oculta
          </HideImageContent>
        )}
      </ImageBoxContent>
    </ImageBoxContainer>

  );
};



export default CardAddImage;
