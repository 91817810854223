import React from 'react';

import {
  Images,
} from '../../../shared/constants';

import {
  CardRankingModeleContainer,
  ModeleRankBackground,
  ModeleRankBox,
  ModeleRankingImageBackground,
  ModeleRankUp,
  ModeleRankText,
  ModeleRankDown,
  ModeleRankUserText,
} from './styled';



interface IProps {
  rank?: string | number;
  top10?: boolean;
  status?: string;
  image?: string;
}



const CardModeleRanking: React.FC<IProps> = (props) => {
  function returnRankModele() {
    return props.rank !== null && props.rank !== undefined
      ? String(props.rank)
      : 'X';
  }

  return (

    <CardRankingModeleContainer
      onClick={() => {
        // href="/models/bios/ian-hunk/about.php"
      }}>

      <ModeleRankingImageBackground
        src={props.image && props.image !== '' && props.image !== undefined
          ? props.image
          : Images.avatarModeleImage
        }
      />


      <ModeleRankBox
        rank={returnRankModele()}
        top10={props.top10}>

        <ModeleRankBackground
          src={Images.iconRankingStatus}
        />

        {props.status === 'UP' && (
          <ModeleRankUp />
        )}

        <ModeleRankText>
          {returnRankModele()}
        </ModeleRankText>

        {props.status === 'DOWN' && (
          <ModeleRankDown />
        )}

      </ModeleRankBox>


      <ModeleRankUserText>
        Ian Hunk
      </ModeleRankUserText>

    </CardRankingModeleContainer>

  );
};



export default CardModeleRanking;
