import React from 'react';

import {
  SuggestionH4,
  SuggestionTitleSpan,
  SuggestionSubTitleSpan,
} from './styled';



interface IProps {
  title: string;
  spanTitle?: string;
  text?: string;
}



const RankingTitle: React.FC<IProps> = (props) => {
  return (

    <SuggestionH4>
      <SuggestionTitleSpan>
        {props.title}&nbsp;
      </SuggestionTitleSpan>

      {props.text && (
        <SuggestionSubTitleSpan>
          {props.text}
        </SuggestionSubTitleSpan>
      )}

      {props.spanTitle && (
        <SuggestionTitleSpan>
          &nbsp;• {props.spanTitle}
        </SuggestionTitleSpan>
      )}
    </SuggestionH4>

  );
};



export default RankingTitle;
