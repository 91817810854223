import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';



export interface IProps {
  disabled?: boolean;
}



export const PaymentChooseContainer = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 12px;
  background-color: ${Colors.inputBackground};
  border-radius: ${Sizes.cardRadius}px;
  cursor: ${(props: IProps) => props.disabled ? 'default' : 'pointer'};
`;



export const PaymentInfoChooseView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const PaymentChooseTitle = styled.p`
  margin-bottom: 4px;
  color: ${Colors.white};
  font-size: 14px;
`;

export const PaymentChooseCardNumber = styled.p`
  margin-bottom: 2px;
  color: ${Colors.white};
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
`;

export const PaymentChooseSubtitle = styled.p`
  color: gray;
  font-size: 12px;
  font-style: italic;
  line-height: 16px;
`;


export const PaymentCardView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const PaymentParcelsCardView = styled.p`
  margin-top: 10px;
  color: gray;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 2px;
`;
