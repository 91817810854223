import styled from 'styled-components';

import {
  SubTitle,
  Title,
} from '../../components/Composh/web';

import {
  Colors,
} from '../../shared/constants';



export const WebViewTitle = styled(Title)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const WebViewSubTitle = styled(SubTitle)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const WebViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${Colors.appBackground};
`;

export const WebViewComponent = styled.iframe`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
