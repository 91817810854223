export const ethnicityArray = [
  { id: 1, label: 'Branca/Caucasiana', value: 'CAUCASIAN' },
  { id: 2, label: 'Latina', value: 'LATIN' },
  { id: 3, label: 'Negra', value: 'BLACK' },
  { id: 4, label: 'Asiática', value: 'ASIAN' },
  { id: 5, label: 'Mediterrânea', value: 'MEDITERRANEAN' },
  { id: 6, label: 'Nativo Americana', value: 'NATIVE_AMERICAN' },
  { id: 7, label: 'Árabe', value: 'ARAB' },
  { id: 8, label: 'Mista', value: 'MIXED' },
  { id: 9, label: 'Outro', value: 'OTHER' },
];
