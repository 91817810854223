import styled from 'styled-components';



export const AFotyAcontestALLVIEW = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
position: relative;
width: 100%;

height: 445px;

`;

export const FotyconAtestmodelmobile = styled.img`
position: absolute;
width: 100%;
height: 100%;
object-fit: contain;
`;



export const FotyAcontesttext = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 80px;
  padding-bottom: 100px;
  z-index: 1;
`;

export const FotyAcontesttopVIEW = styled.div`
display: flex;
justify-content: space-between;
width: 100%;
align-items: center;
flex-direction: column;
align-items: flex-start;
`;



export const FotycoAntestcontentBNER = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin-top: 40px;
`;

export const FotyAsectionText = styled.p`
line-height: 1.4em;
-webkit-text-size-adjust: 100%;
-webkit-font-smoothing: antialiased;
box-sizing: border-box;
font-family: 'Open Sans', sans-serif !important;
font-size: 13px;
color: #CCC !important;
  margin-bottom: 50px;
`;


export const FotycoAnteTitleText = styled.h2`
line-height: 1.4em;
-webkit-text-size-adjust: 100%;
-webkit-font-smoothing: antialiased;
color: #CCC !important;
text-align: right;
margin: 0;
padding: 0;
border: 0;
outline: 0;
vertical-align: baseline;
background: transparent;
box-sizing: border-box;
font-family: 'Open Sans', sans-serif !important;
font-size: 13px;
`;

export const FotyconAteTitleAmoutPrize = styled.p`
-webkit-text-size-adjust: 100%;
-webkit-font-smoothing: antialiased;
text-align: right;
margin: 0;
padding: 0;
border: 0;
outline: 0;
vertical-align: baseline;
background: transparent;
box-sizing: border-box;
font-family: 'Open Sans', sans-serif !important;
color: #2C91AF;
font-weight: 600;
font-size: 42px;
line-height: 0.9;
`;

export const FotycoAntestbadge = styled.img`
margin-top: 10px;
width: 250px;
height: auto;
max-width: 100%;
`;


export const FotySeparator = styled.img`
position: absolute;
bottom: 0;
width: 100%;
height: 2px;
object-fit: fill;
`;

