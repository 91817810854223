import styled,
{
  keyframes,
} from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';



export interface IProps {
  backgroundColor?: string;
  color?: string;
}



const DotKeyFrame = keyframes`
  50%  {
    transform: translateX(96px)
  }
`;

const DotsKeyFrame = keyframes`
  50% {
    transform: translateX(-31px)
  }
`;


export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const LoaderDotsContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 142px;
  height: 40px;
  margin: -20px 0 0 -71px;
  background: ${(props: IProps) => props.backgroundColor || Colors.cardBackground};
  filter: contrast(20);
  border-radius: 5px;
`;

export const DotLoader = styled.span`
  position: absolute;
  width: 16px;
  height: 16px;
  top: 12px;
  left: 15px;
  filter: blur(4px);
  background: ${(props: IProps) => props.color || Colors.white};
  border-radius: 50%;
  transform: translateX(0);
  animation: ${DotKeyFrame} 2.8s infinite;
`;

export const DotsLoader = styled.div`
  transform: translateX(0);
  margin-top: 12px;
  margin-left: 31px;
  animation: ${DotsKeyFrame} 2.8s infinite;
`;

export const DotItem = styled.div`
  display: block;
  float: left;
  width: 16px;
  height: 16px;
  margin-left: 16px;
  filter: blur(4px);
  background: ${(props: IProps) => props.color || Colors.white};
  border-radius: 50%;
`;
