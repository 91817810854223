import React from 'react';

import {
  Colors,
} from '../../../shared/constants';

import {
  Input,
} from '../../Composh/web';

import {
  SearchTogglesContent,
} from './styled';



export interface IProps {
  countLimit?: string;
  children?: any;
}



const InputChip: React.FC<IProps> = (props: IProps) => {
  return (

    <Input.View
      noShadow
      backgroundColor={Colors.transparent}
      // helpText={translate(errors.type || '')}
      helpColor={Colors.danger}
      countColor={Colors.inputCount}
      countLimit={props.countLimit}>

      <SearchTogglesContent>
        {props.children}
      </SearchTogglesContent>

    </Input.View>

  );
};



export default InputChip;
