import styled,
{
  css,
} from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';



export interface IProps {
  width?: number;
  maxWidth?: number;
  height?: number;
}



export const BankModalView = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background-color: ${Colors.appBackground};
  border-radius: ${Sizes.cardRadius}px;

  ${(props: IProps) => props.width && `width: ${props.width}px`};
  ${(props: IProps) => props.width && `max-width: ${props.maxWidth}px`};
  ${(props: IProps) => props.height && `height: ${props.height}px`};
`;



export const ListSectionCss = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 5px;
  padding-left: 14px;
  padding-right: 14px;
  padding-bottom: 5px;
`;


export const FilteredInput = styled.div`
  ${ListSectionCss};
  border-bottom-color: ${Colors.toolbarLine};
  border-bottom-style: solid;
  border-bottom-width: 1px;
`;



export const FilteredBanksContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  width: 100%;
  height: 100%;
`;


export const FilteredListSection = styled.div`
  ${ListSectionCss};
  flex-grow: 1;
  overflow: auto;
`;
