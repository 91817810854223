import styled,
{
  css,
} from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';
import { PlatformDevices } from '../../../shared/utils/platforms.utils';



export interface IProps {
  flexDirection?: string;
  backgroundColor?: string;
}



export const PhoneContainerColumnStyle = css`
  flex-direction: column;
  width: 100%;
  border-radius: ${Sizes.cardRadius}px;
`;

export const PhoneContainerRowStyle = css`
  flex-direction: row;

  @media ${PlatformDevices.maxTablet} {
    width: 100%;
  }
`;

export const PhoneContainerButton = styled.div`
  display: flex;
  overflow: hidden;

  ${(props: IProps) => props.flexDirection === 'COLUMN' && PhoneContainerColumnStyle};
  ${(props: IProps) => props.flexDirection === 'ROW' && PhoneContainerRowStyle};
`;



export const PhoneCallRowStyle = css`
  border-top-left-radius: ${Sizes.cardRadius}px;
  border-bottom-left-radius: ${Sizes.cardRadius}px;

  @media ${PlatformDevices.maxTablet} {
    flex: 1;
    justify-content: center;
  }
`;

export const PhoneCallColumnStyle = css`
  flex-direction: row-reverse;
  justify-content: center;
  width: 100%;
`;

export const PhoneCallButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${Sizes.buttonHeight}px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 15px;
  padding-bottom: 10px;
  background-color: ${(props: IProps) => props.backgroundColor};

  ${(props: IProps) => props.flexDirection === 'ROW' && PhoneCallRowStyle};
  ${(props: IProps) => props.flexDirection === 'COLUMN' && PhoneCallColumnStyle};
`;

export const PhoneButtonText = styled.p`
  margin-left: 5px;
  margin-right: 5px;
  color: ${Colors.white};
  font-size: 13.5px;
  white-space: nowrap;
`;

export const PhoneButtonIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  margin-right: 5px;
`;



export const CopyButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: ${Sizes.buttonHeight}px;
  background-color: ${(props: IProps) => props.backgroundColor || Colors.primary};

  ${(props: IProps) => props.flexDirection === 'COLUMN' && PhoneCallColumnStyle};
`;



export const WhatsappRowStyle = css`
  border-top-right-radius: ${Sizes.cardRadius}px;
  border-bottom-right-radius: ${Sizes.cardRadius}px;
`;

export const WhatsappButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: ${Sizes.buttonHeight}px;
  padding-right: 3px;
  background-color: ${(props: IProps) => props.backgroundColor};

  ${(props: IProps) => props.flexDirection === 'ROW' && WhatsappRowStyle};
  ${(props: IProps) => props.flexDirection === 'COLUMN' && PhoneCallColumnStyle};
`;
