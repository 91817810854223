import styled from 'styled-components';

import Color from 'color';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';



export interface IProps {
  clickable?: boolean;
  disabled?: boolean;
  backgroundColor?: string;
}



export const UserShortcutButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 7px;
  margin-bottom: 13px;
  opacity: ${(props: IProps) => props.disabled ? Sizes.opacityOff : Sizes.opacityOn};
  cursor: ${(props: IProps) => !props.clickable ? 'default' : 'pointer'};
`;

export const UserShortcutIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50px;
  margin-right: 10px;
  background-color: ${(props: IProps) => props.backgroundColor
    ? Color(props.backgroundColor).darken(0.3).toString()
    : Colors.primary};
`;

export const UserShortcutText = styled.p`
  color: ${Colors.white};
  font-size: 14px;
  font-weight: 400;
`;
