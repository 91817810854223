import styled from 'styled-components';

import Color from 'color';

import {
  Colors,
  Sizes,
  Svgs,
} from '../../shared/constants';



export const FaClubDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 902px;
  max-width: calc(100% - 20px);
  height: 500px;
  background-color: ${Colors.alertBackground};
  border-radius: ${Sizes.cardRadius}px;
  box-shadow: 0 2px 45px 4px rgba(5,0,6,.2);
  z-index: 55;
`;

export const AddCardContent = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;


export const AddACardAsideContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 250px;
  height: 100%;
  background-color: ${Colors.fanClubBackgroundLevel};
`;

export const FanclubModalBackground = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
`;

export const FanclubInformationsModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-top: 25px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 25px;
  background-color: ${Color(Colors.fanClubBackgroundLevel).alpha(0.91).toString()};
  z-index: 5;
`;

export const FaClubAvaterView = styled.div`
  display: flex;
  flex-direction: column;
  width: 150px;
  height: 150px;
`;

export const FaClubAvatarContent = styled.div`
  width: 100%;
  position: relative;
  border-radius: 100%;

  :before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  :after {
    content: '';
    position: absolute;
    background-image: url(${Svgs.fanclubAvatar});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 2;
  }
`;

export const FaClubAvatarImage = styled.img`
  display: block;
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  bottom: 0;
  width: calc(100% - 24px);
  height: calc(100% - 24px);
  margin: auto;
  border-radius: 100%;
  object-fit: cover;
`;


export const FaClubAcessDescription = styled.p`
  max-width: 90%;
  margin-top: 30px;
  margin-bottom: 5px;
  color: ${Colors.grayLight};
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  word-break: break-word;
`;


export const FaClubAcessName = styled.h3`
  margin-bottom: 10px;
  color: ${Colors.white};
  font-size: 24px;
  font-weight: 500;
  line-height: 1.17;
  text-align: center;
`;



export const FaClubBoxMain = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
`;

export const FaClubFeatDetaisButtonView = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;


export const FaClubTuoleBoxMain = styled.p`
  margin-bottom: 5px;
  color: ${Colors.white};
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  overflow-wrap: break-word;
  word-break: break-word;
`;

export const FaClubOfferJoinBubble = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 18px;
  width: 100%;
`;

export const FaClubOfferBubbleAvatar = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 36px;
  height: 36px;
  background-color: ${Colors.cardBackground};
  box-shadow: 0 0 0 2px ${Colors.black};
  border-radius: 1000px;
`;

export const FaClubOfferAvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const FaClubOfferTextView = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgba(138, 150, 163, 0.25);
  padding-top: 6px;
  padding-left: 26px;
  padding-right: 12px;
  padding-bottom: 6px;
  border-top-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`;

export const FaClubOfferText = styled.div`
  color: ${Colors.white};
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  overflow-wrap: break-word;
  word-break: break-word;
`;



export const FaClubFeatDetaisView = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  gap: 8px;
`;

export const FaClubFeatDetaisItemView = styled.p`
  position: relative;
  width: 100%;
  padding-left: 20px;
  color: ${Colors.gray};
  font-size: 14px;
  font-weight: 400;

  :before {
    content: '';
    position: absolute;
    border-radius: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    width: 7px;
    height: 7px;
    margin: auto;
    background-color: ${Colors.primaryDark};
  }
`;



export const FaClubOfferJoinContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 9px;
  padding-top: 15px;
`;

export const FaClubOfferJoinNormalPrice = styled.p`
  margin-top: 13px;
  color: ${Colors.primaryDark};
  font-size: 13px;
`;



export const FaClubPackageContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FaClubPackageButtonView = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 5px;
`;
