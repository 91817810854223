import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../../shared/constants';



export const Container1 = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 10px;
`;

export const Container2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #bdbdbd;
`;

export const Container3 = styled.p`
  color: ${Colors.gray};
  font-size: 12px;
`;

export const Container4 = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

export const Container5 = styled.div`
  display: flex;
  flex-direction: row;
  
`;

export const Container8 = styled.div`
      -webkit-tap-highlight-color: transparent;
    user-select: none;
    font-family: SWISSC;
    color: grey;
    padding: 0;
    list-style: none;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    width: 70%;
`;

export const Container9 = styled.div`
  
`;

export const Container10 = styled.div`
  
`;

export const Container11 = styled.div`
  
`;

export const Container12 = styled.div`
  
`;
