import styled from 'styled-components';

import ButtonAction from '../../Buttons/ButtonAction';

import {
  ContentRowToColumnPadder,
} from '../../Contents/ContentPadder';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';



export const ErrorViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  position: relative;
  overflow: hidden;
`;


export const ErrorInfoViewPadder = styled(ContentRowToColumnPadder)`
  flex: 1;
  z-index: 5;

  @media ${PlatformDevices.maxTabletL} {
    width: 100%;
    background-color: ${Colors.cardOverlay};
  }
`;

export const ErrorInfoViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 360px;
  height: 100%;
`;

export const ErroLogoAppImage = styled.img`
  margin-bottom: 20px;
  width: 170px;
  object-fit: contain;
`;

export const ErrorInfoViewTitle = styled.p`
  color: ${Colors.white};
  font-weight: bold;
  font-size: 42px;
`;

export const ErrorInfoViewDescription = styled.p`
  color: ${Colors.white};
  font-size: 17px;
  line-height: 24px;
`;

export const ErrorInfoViewButtonAction = styled(ButtonAction)`
  margin-top: 25px;
  max-width: ${Sizes.buttonMinWidth}px;
`;

export const ErrorInfoViewFooter = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${Colors.menuOverlay};
  z-index: 5;
`;



export const ErrorInfoViewBackground = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
`;
