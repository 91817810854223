import React from 'react';

import {
  Content,
  Screen,
  Title,
} from '../../../../components/Composh/web';

import {
  ContentPadder,
} from '../../../../components/Contents/ContentPadder';
import {
  ButtonBack,
} from '../../../../components/Controls';

import FooterApp from '../../../../components/Footers/FooterApp';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';

import {
  Colors,
} from '../../../../shared/constants';

import {
  FinancialBillingContainer,
  CreditTableContainer,
  CreditTableHeader,
  CreditTableTitle,
} from './styled';



const FinancialMovimentsScreen: React.FC = () => {
  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        disableGender
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            color={Colors.white}
            size={27}
          />
        }
        centerContent={
          <Title>
            Movimentações financeiras
          </Title>
        }
      />



      <Content>

        <ContentPadder>

          <CreditTableContainer>
            <CreditTableHeader>
              <CreditTableTitle>
                Active Order
              </CreditTableTitle>
            </CreditTableHeader>


            <div className="table-responsive" style={{ color: '#ffffff' }}>
              <table className="table table-de mb-0">

                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Type</th>
                    <th>Amount</th>
                    <th>Price</th>
                    <th>USD</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>2018-01-31 06:51:51</td>
                    <td className="success">Buy</td>
                    <td><i className="cc BTC-alt"></i> 0.58647</td>
                    <td>11900.12</td>
                    <td>$ 6979.78</td>
                  </tr>

                  <tr>
                    <td>2018-01-31 06:50:50</td>
                    <td className="danger">Sell</td>
                    <td><i className="cc BTC-alt"></i> 1.38647</td>
                    <td>11905.09</td>
                    <td>$ 4600.97</td>
                  </tr>

                  <tr>
                    <td>2018-01-31 06:49:51</td>
                    <td className="success">Buy</td>
                    <td><i className="cc BTC-alt"></i> 0.45879</td>
                    <td>11901.85</td>
                    <td>$ 5460.44</td>
                  </tr>

                  <tr>
                    <td>2018-01-31 06:51:51</td>
                    <td className="success">Buy</td>
                    <td><i className="cc BTC-alt"></i> 0.89877</td>
                    <td>11899.25</td>
                    <td>$ 10694.6</td>
                  </tr>

                  <tr>
                    <td>2018-01-31 06:51:51</td>
                    <td className="danger">Sell</td>
                    <td><i className="cc BTC-alt"></i> 0.45712</td>
                    <td>11908.58</td>
                    <td>$ 5443.65</td>
                  </tr>

                  <tr>
                    <td>2018-01-31 06:51:51</td>
                    <td className="success">Buy</td>
                    <td><i className="cc BTC-alt"></i> 0.58647</td>
                    <td>11900.12</td>
                    <td>$ 6979.78</td>
                  </tr>
                </tbody>

              </table>
            </div>
          </CreditTableContainer>

        </ContentPadder>



        <FooterApp />

      </Content>

    </Screen>

  );
};



export default FinancialMovimentsScreen;
