/* eslint-disable import/no-duplicates */
import {
  format,
  parseISO,
  differenceInMilliseconds,
} from 'date-fns';

import {
  pt,
} from 'date-fns/locale';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import {
  validateString,
} from './string.utils';

dayjs.extend(customParseFormat);
dayjs.locale('pt-br');



// FIXME: Remover e deixar o DayJS
export function FormatIsoDateWithPattern(isoDate: string, pattern: string) {
  if (validateString(isoDate)) {
    const newDate = new Date(isoDate);
    const dateParse = format(newDate, pattern, { locale: pt });
    return dateParse;
  }
  else {
    return null;
  }
}

export function FormatIsoDateWithPatternDayjs(isoDate, pattern: string) {
  if (!isoDate) {
    return '';
  }

  const date = dayjs(isoDate);
  const formattedDate = date.format(pattern);

  return formattedDate;
}



export function CalculateAgeByBirthdayString(bornDate: string): string {
  // Verifica se a data de nascimento é nula ou indefinida
  if (!bornDate) {
    return '';
  }

  // Converte a string da data de nascimento para um objeto de data
  const dataNascimentoObj = parseISO(bornDate);

  // Verifica se a conversão da data foi bem-sucedida
  if (isNaN(dataNascimentoObj.getTime())) {
    return '';
  }

  // Obtém a data atual
  const dataAtual = new Date();

  // Calcula a diferença em milissegundos
  const diferencaEmMilissegundos = differenceInMilliseconds(dataAtual, dataNascimentoObj);

  // Converte a diferença para anos
  const idadeEmAnos = Math.floor(diferencaEmMilissegundos / (365.25 * 24 * 60 * 60 * 1000));

  // Retorna a idade como uma string
  return idadeEmAnos.toString();
}



export function FormatExtenseDate(dataString: string) {
  if (!dataString) {
    return '';
  }

  // Converte a string da data para um objeto de data
  const data = parseISO(dataString);

  const dataFormatada = format(data, 'dd, MMMM yyyy', { locale: pt });
  return dataFormatada;
}



export function DayJStoIso(dataString: string, format: string) {
  if (!dataString) {
    return '';
  }

  return dayjs(dataString, format, true).hour(12).toISOString();
}



export function daysUntilDate(dataString: string) {
  if (!dataString) {
    return '';
  }

  // Parse da data alvo usando o Day.js
  const targetDateTime = dayjs(dataString);

  // Parse da data de hoje usando o Day.js
  const todayDateTime = dayjs().startOf('day');

  // Calcula a diferença entre as datas em dias
  const daysDifference = targetDateTime.diff(todayDateTime, 'day');

  return daysDifference;
};
