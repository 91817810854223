import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';



export interface IProps {
  width?: number;
  height?: number;
}



export const UserImageContainer = styled.div`
  overflow: hidden;
  position: relative;
  width: ${(props: IProps) => props.width}px;
  min-width: ${(props: IProps) => props.width}px;
  height: ${(props: IProps) => props.height}px;
  min-height: ${(props: IProps) => props.height}px;
  border-radius: ${Sizes.cardRadius}px;
`;


export const UserImage = styled.img`
  width: ${(props: IProps) => props.width}px;
  height: ${(props: IProps) => props.height}px;
  background-color: ${Colors.black};
  border-radius: 5px;
  object-fit: cover;
  object-position: top center;
`;

export const RibbonPlanUserImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: ${(props: IProps) => props.width / 2.5}px;
  height: ${(props: IProps) => props.width / 2.5}px;
  object-fit: center;
`;
