import React from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  RootState,
} from '../../redux';

import {
  Colors,
} from '../../shared/constants';

import {
  DotContainer,
  DotItem,
} from './styled';



export interface IProps {
  color?: string;
  currentPage?: number;
  total: number;
  onPageChange?: (index: number) => void;
}



const Dots: React.FC<IProps> = (props: IProps) => {
  const genderColorChoose = useSelector((state: RootState) => state.choose.genderColorApp);
  const colorAccent = props.color || genderColorChoose;

  const dots = Array.from({ length: props.total });



  return (

    <DotContainer>

      {dots.map((_, index) => (
        <DotItem
          key={index}
          backgroundColor={props.currentPage === index ? colorAccent : Colors.gray}
          onClick={() => {
            if (props.onPageChange) {
              props.onPageChange(index);
            }
          }}
        />
      ))}

    </DotContainer>

  );
};



export default Dots;
