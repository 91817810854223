import styled,
{
  css,
} from 'styled-components';

import {
  GetPlatform,
} from '../../config/plataform.config';

import {
  Colors,
} from '../../shared/constants';

import {
  Scrolls,
  Tab,
} from '../Composh/web';



export const ScrollbarHideStyle = css`
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ScrollbarHorizontalStyle = css`
  ::-webkit-scrollbar {
    height: 17px;
  }

  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar {
    background: ${Colors.tabBackground};
  }

  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px ${Colors.appScrollbar};

    border-color: ${Colors.tabBackground};
    border-style: solid;
    border-top-width: 9px;
    border-left-width: 15px;
    border-right-width: 15px;
    border-bottom-width: 1px;
  }
`;



export const ScrollHorizontalStyled = styled(Scrolls.Horizontal)`
  justify-content: center;
  ${GetPlatform() === 'WEB' ? ScrollbarHorizontalStyle : ScrollbarHideStyle}
  z-index: 10;
`;

export const TabComposhStyled = styled(Tab)`
  height: 100%;
  margin-top: 2px;
  font-size: 15px;
`;
