import React from 'react';

import {
  IconBreastsOff,
  IconBreastsOn,
  IconDrinkOff,
  IconDrinkOn,
  IconPiercingOff,
  IconPiercingOn,
  IconSmokeOff,
  IconSmokeOn,
  IconTattooOff,
  IconTattooOn,
  IconVisitHotelOff,
  IconVisitHotelOn,
  IconVisitMotelOff,
  IconVisitMotelOn,
  IconVisitMyLocationOff,
  IconVisitMyLocationOn,
  IconVisitTravelOff,
  IconVisitTravelOn,
  IconVisitYourLocationOff,
  IconVisitYourLocationOn,
} from '../components/Icons';

import {
  Colors,
} from '../shared/constants';



export function returnIconSmoke(value: string, color?: string, size?: number) {
  const colorProp = color || Colors.white;
  const sizeProp = size;

  if (value) {
    return (
      <IconSmokeOn
        color={colorProp}
        size={sizeProp}
      />
    );
  }
  else {
    return (
      <IconSmokeOff
        color={colorProp}
        size={sizeProp}
      />
    );
  }
};



export function returnIconDrink(value: string, color?: string, size?: number) {
  const colorProp = color || Colors.white;
  const sizeProp = size;

  if (value) {
    return (
      <IconDrinkOn
        color={colorProp}
        size={sizeProp}
      />
    );
  }
  else {
    return (
      <IconDrinkOff
        color={colorProp}
        size={sizeProp}
      />
    );
  }
};



export function returnIconTattoo(value: string, color?: string, size?: number) {
  const colorProp = color || Colors.white;
  const sizeProp = size;

  if (value) {
    return (
      <IconTattooOn
        color={colorProp}
        size={sizeProp}
      />
    );
  }
  else {
    return (
      <IconTattooOff
        color={colorProp}
        size={sizeProp}
      />
    );
  }
};



export function returnIconPiercing(value: string, color?: string, size?: number) {
  const colorProp = color || Colors.white;
  const sizeProp = size;

  if (value) {
    return (
      <IconPiercingOn
        color={colorProp}
        size={sizeProp}
      />
    );
  }
  else {
    return (
      <IconPiercingOff
        color={colorProp}
        size={sizeProp}
      />
    );
  }
};



export function returnIconBreasts(value: string, color?: string, size?: number) {
  const colorProp = color || Colors.white;
  const sizeProp = size;

  if (value) {
    return (
      <IconBreastsOn
        color={colorProp}
        size={sizeProp}
      />
    );
  }
  else {
    return (
      <IconBreastsOff
        color={colorProp}
        size={sizeProp}
      />
    );
  }
};



export function returnIconMyLocation(value: string, color?: string, size?: number) {
  const colorProp = color || Colors.white;
  const sizeProp = size;

  if (value) {
    return (
      <IconVisitMyLocationOn
        color={colorProp}
        size={sizeProp}
      />
    );
  }
  else {
    return (
      <IconVisitMyLocationOff
        color={colorProp}
        size={sizeProp}
      />
    );
  }
};



export function returnIconYourLocation(value: string, color?: string, size?: number) {
  const colorProp = color || Colors.white;
  const sizeProp = size;

  if (value) {
    return (
      <IconVisitYourLocationOn
        color={colorProp}
        size={sizeProp}
      />
    );
  }
  else {
    return (
      <IconVisitYourLocationOff
        color={colorProp}
        size={sizeProp}
      />
    );
  }
};



export function returnIconHotel(value: string, color?: string, size?: number) {
  const colorProp = color || Colors.white;
  const sizeProp = size;

  if (value) {
    return (
      <IconVisitHotelOn
        color={colorProp}
        size={sizeProp}
      />
    );
  }
  else {
    return (
      <IconVisitHotelOff
        color={colorProp}
        size={sizeProp}
      />
    );
  }
};



export function returnIconMotel(value: string, color?: string, size?: number) {
  const colorProp = color || Colors.white;
  const sizeProp = size;

  if (value) {
    return (
      <IconVisitMotelOn
        color={colorProp}
        size={sizeProp}
      />
    );
  }
  else {
    return (
      <IconVisitMotelOff
        color={colorProp}
        size={sizeProp}
      />
    );
  }
};



export function returnIconTravel(value: string, color?: string, size?: number) {
  const colorProp = color || Colors.white;
  const sizeProp = size;

  if (value) {
    return (
      <IconVisitTravelOn
        color={colorProp}
        size={sizeProp}
      />
    );
  }
  else {
    return (
      <IconVisitTravelOff
        color={colorProp}
        size={sizeProp}
      />
    );
  }
};
