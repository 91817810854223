import styled from 'styled-components';

import color from 'color';

import {
  Colors,
} from '../../../shared/constants';



interface IProps {
  backgroundColor?: string;
}



export const TipCardContainer = styled.a`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 60px;

  :hover {
    background-color: ${(props: IProps) => props.backgroundColor
    ? color(props.backgroundColor).darken(0.2).toString()
    : Colors.primary};
  }
`;

export const TipCardImage = styled.img`
  width: 35px;
  height: 35px;
  margin-bottom: 4px;
  object-fit: cover;
`;

export const TipCardText = styled.p`
  color: ${Colors.white};
  font-size: 13px;
`;

