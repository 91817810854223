export const APP = {
  imageDrawer:                      90,
  paddingContainer:                 16, // 20
  paddingFlatList:                  12,
  opacityOn:                        1,
  opacityOff:                       0.4,
};


export const ANIMATIONS = {
  loginTime:                        300,
};


export const BUTTONS = {
  buttonMinWidth:                   200,
  buttonHeight:                     40,
  buttonRadius:                     4,
};


export const CARD = {
  cardRadius:                       4,
  cardHomeMaxWidth:                 800,
};


export const FONTS = {
  fontInput:                        16,
  fontLarge:                        16,
  fontRegular:                      14,
  fontRegularMiddle:                14.5,
  fontMedium:                       12,
  fontSmall:                        11,
  fontTiny:                         10,
};


export const ICONS = {
  iconSize:                         35,
  iconTabBar:                       20,
  iconInputSize:                    27,
  iconAndroidSize:                  24,
  iconIosSize:                      22,
};


export const MARGINS = {
  contentMaxWidth:                  1450,
  messageMaxWidth:                  1600,

  contentLeftWidth:                 250,
  contentRightHomeWidth:            350,
  contentMargin:                    20,

  contentRentHeight:                420,


  menuContent:                      35,
};


export const MODALS = {
  borderModalsRadius:               15,
};


export const TOGGLE = {
  TOOGLE_HEIGHT:                    40, // 42,
};



const Sizes = {
  ...APP,
  ...ANIMATIONS,
  ...BUTTONS,
  ...CARD,
  ...FONTS,
  ...ICONS,
  ...MARGINS,
  ...MODALS,
  ...TOGGLE,
};



export default Sizes;
