import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../../shared/constants';



export const ImageContentRightHome = styled.div`
  display: flex;
  flex-direction: column;
`;



export const WarningApproveText = styled.p`
  margin-bottom: 15px;
  color: ${Colors.white};
  font-size: 16px;
  text-align: center;
`;



export const WrapperItemContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;



export const AntiFakeItemSplitText = styled.p`
  position: relative;
  margin-bottom: 15px;
  color: ${Colors.grayLight};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
`;

export const AntiFakeRightVideoView = styled.video`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  max-width: 250px;
  aspect-ratio: 9 / 16;
  background-color: ${Colors.black};
  border-radius: ${Sizes.cardRadius}px;
`;
