import styled from 'styled-components';



export const ViewSeparator = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 8px;
  margin-right: 8px;
`;
