import styled from 'styled-components';



export const HallFameHeaderView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  height: 250px;
  margin-top: 10px;
  background-color: #121317;
`;

export const HallFameHeaderBackground = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
  z-index: 0;
`;


export const HallFameHeaderInfoView = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  z-index: 2;
`;

export const HallFameHeaderLogo = styled.img`
  width: 100%;
  max-width: 765px;
`;

export const HallFameHeaderInfoTitle = styled.h2`
  margin: 0 0 10px 0;
  font-style: italic;
  color: #ffffff;
  font-weight: 300;
  font-size: 26px;
  line-height: 32px;
  text-align: center;
`;

export const HallFameHeaderInfoText = styled.p`
  width: 100%;
  max-width: 825px;
  color: #ffffff;
  text-align: center;
  line-height: 1.4em;
`;



// Body

export const HallFameBodyContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 1% 0 1%;
`;

export const HallFameBodyBackgroundView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HallFameBodyWrapperView = styled.div`
  width: 100%;
  max-width: 1430px;
`;



export const HallFameRecordsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 5px;
`;

export const HallFameStarsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 5px;
`;
