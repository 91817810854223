import React from 'react';
import { Images } from '../../../../../shared/constants';

import ButtonYearly from '../ButtonYearly';
import TitleYearly from '../TitleYearly';

import {
  AFotyAcontestALLVIEW,
  FotyconAtestmodelmobile,
  FotyAcontesttext,
  FotyAcontesttopVIEW,
  FotycoAntestcontentBNER,
  FotycoAnteTitleText,
  FotyconAteTitleAmoutPrize,
  FotycoAntestbadge,
  FotyAsectionText,
  FotySeparator,
} from './styled';



export interface IProps {
  id?: any;
  position?: any;
  image?: any;
  badge?: any;
  title?: any;
  text?: any;
  prize?: any;
  buttonText?: any;
}



const SectionYearly1: React.FC<IProps> = (props) => {
  return (

    <AFotyAcontestALLVIEW>
      <FotyconAtestmodelmobile
        src={props.image}
        alt=""
      />


      <FotyAcontesttext
        style={{
          flexDirection: props.position === 'right' ? 'row-reverse' : 'row',
        }}>
        <FotyAcontesttopVIEW
          style={{
            alignItems: props.position === 'right' ? 'flex-end' : 'flex-start',
          }}>
          <TitleYearly
            position={props.position}
            title={props.title}
          />

          <FotycoAntestcontentBNER>
            <FotyAsectionText>
              {props.text}
            </FotyAsectionText>

            <ButtonYearly
              title={props.buttonText}
              href="#"
            />
          </FotycoAntestcontentBNER>
        </FotyAcontesttopVIEW>


        <FotycoAntestcontentBNER>
          <FotycoAnteTitleText>Total Prize Money</FotycoAnteTitleText>
          <FotyconAteTitleAmoutPrize>
            {props.prize}
          </FotyconAteTitleAmoutPrize>

          <FotycoAntestbadge
            src={props.badge}
          />
        </FotycoAntestcontentBNER>
      </FotyAcontesttext>


      <FotySeparator
        src={Images.fotySeparatorV2}
      />
    </AFotyAcontestALLVIEW>

  );
};



export default SectionYearly1;
