import React from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  RootState,
} from '../../../redux';

import {
  countriesOption,
} from '../../../shared/arrays/App/countries.array';

import {
  Images,
} from '../../../shared/constants';

import {
  returnCityArray,
  returnStateArray,
} from '../../../shared/utils/address.utils';

import {
  stringRawDataText,
  stringWordCapitalize,
} from '../../../shared/utils/string.utils';

import {
  ButtonCityContainer,
  DivisorCountry,
  CountryView,
  CountryImage,
  DivisorView,
  DivisorShadow,
  CityView,
  CityText,
  StateView,
} from './styled';



export interface IProps {
  onPress?: any;
}



const ButtonLocationApp: React.FC<IProps> = (props) => {
  const genderColorChoose = useSelector((state: RootState) => state.choose.genderColorApp);
  const localities = useSelector((state: RootState) => state.localities.selected);
  const appActualCountryName = localities.country;

  const filterCountry = countriesOption.find((country) => stringRawDataText(country?.code) === stringRawDataText(appActualCountryName));

  const getStateArray = returnStateArray(filterCountry?.code);
  const findState = getStateArray?.find((item) => item.value === localities.state);
  const stateUser = findState?.label;

  const getCityArray = returnCityArray(appActualCountryName, findState?.value);
  const findCity = getCityArray?.find((item) => item.value === localities.city);
  const cityUser = findCity?.label;



  return (

    <ButtonCityContainer
      onClick={() => {
        if (props.onPress) {
          props.onPress();
        }
      }}>

      <DivisorCountry
        backgroundColor={genderColorChoose}
      />

      <DivisorView
        backgroundColor={genderColorChoose}
      />

      <DivisorShadow />


      <CountryView>
        <CountryImage
          alt={appActualCountryName}
          src={filterCountry?.image || Images.world}
        />
      </CountryView>


      <CityView>

        <CityText>
          {stringWordCapitalize(cityUser) || 'Escolha uma cidade'}
        </CityText>

        <StateView>
          {stringWordCapitalize(stateUser) || ''}
        </StateView>

      </CityView>

    </ButtonCityContainer>

  );
};



export default ButtonLocationApp;
