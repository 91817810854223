export enum EPlansUser {
  NONE = 'NONE',
  PREMIUM = 'PREMIUM',
  BRONZE = 'BRONZE',
  SILVER = 'SILVER',
  GOLD = 'GOLD',
  GOLD_FREEMIUM = 'GOLD_FREEMIUM',
  PLATINUM = 'PLATINUM',
  DIAMOND = 'DIAMOND',
}



export default EPlansUser;
