import styled,
{
  css,
} from 'styled-components';

import Color from 'color';

import {
  GetPlatform,
} from '../../config/plataform.config';

import {
  Colors,
  Sizes,
} from '../../shared/constants';

import {
  PlatformDevices,
} from '../../shared/utils/platforms.utils';



export interface IProps {
  color?: string;
}



export const ChooseGenderContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 80%;
  min-width: 200px;
  min-height: 550px;
  background-color: ${Colors.appBackground};
  border-radius: ${Sizes.cardRadius}px;
  transition: all 0.3s ease-in-out;

  @media ${PlatformDevices.minMobileS} {
    flex-direction: column;
    width: 320px;
  }

  @media ${PlatformDevices.minTablet} {
    flex-direction: column;
    width: 500px;
  }

  @media ${PlatformDevices.minTabletM} {
    flex-direction: row;
    width: 700px;
  }

  @media ${PlatformDevices.minLaptop} {
    flex-direction: row;
    width: 900px;
  }
  
  @media ${PlatformDevices.minDesktop} {
    flex-direction: row;
    width: 1050px;
  }
`;



export const ChooseGenderItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
`;

export const ChooseGenderAbsoluteStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;



export const ChooseGenderImageVisibleHover = css`
  ${ChooseGenderItem}:hover & {
    transform: scale(1.1);
  }
`;

export const ChooseGenderLongImage = styled.img`
  ${ChooseGenderAbsoluteStyle};
  object-fit: cover;
  z-index: 0;
  transition: 0.4s all ease-in-out;

  ${ChooseGenderImageVisibleHover}
`;



export const ChooseInfoVisibility = css`
  visibility: visible;
  opacity: 1;
`;

export const ChooseInfoVisibilityHover = css`
  ${ChooseGenderItem}:hover & {
    ${ChooseInfoVisibility}
  }
`;

export const ChooseGenderInfoContent = styled.div`
  ${ChooseGenderAbsoluteStyle};
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s linear;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: ${Colors.cardOverlay};
  z-index: 5;

  @media ${PlatformDevices.maxTabletM} {
    ${ChooseInfoVisibility};
    flex-direction: row-reverse;
    background: linear-gradient(90deg, ${Colors.cardOverlay} 0%, ${Colors.transparent} 100%);
  }
  @media ${PlatformDevices.minTabletM} {
    ${GetPlatform() === 'WEB' && ChooseInfoVisibilityHover};
    ${GetPlatform() !== 'WEB' && ChooseInfoVisibility};
  }
`;


export const ChooseGenderFlexButton = styled.a`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;


export const ChooseGenderInfoButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 25px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 25px;

  @media ${PlatformDevices.maxTabletM} {
    width: 140px;
    align-items: flex-start;
    justify-content: center;
    padding-top: 0;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 0;
  }
`;


export const ChooseGenderTitle = styled.h3`
  margin-bottom: 12px;
  color: ${Colors.white};
  font-size: 28px;
  line-height: 30px;
  font-weight: bold;
  text-align: center;

  @media ${PlatformDevices.maxTabletM} {
    color: ${(props: IProps) => props.color
    ? Color(props.color).lighten(0.4).toString()
    : Colors.white};
    font-size: 26px;
    line-height: 28px;
    text-align: left;
  }
`;

export const ChooseGenderName = styled.p`
  margin-bottom: 2px;
  color: ${Colors.white};
  font-size: 17px;
  text-align: center;
`;

export const ChooseGenderViewUsername = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ChooseGenderUsername = styled.p`
  color: ${Colors.white};
  font-size: 14px;
  text-align: center;
`;

export const ChooseGenderUsernameIcon = styled.div`
  margin-left: 10px;
`;
