// Assets - Patterns

const linkbioThemesArray = [
  {
    id: 1,
    label: 'Summer',
    image: 'https://res.cloudinary.com/dxarbtyux/image/upload/v1625095575/Summer_r4bq2g.svg',
  },
  {
    id: 2,
    label: 'Pink',
    image: 'https://res.cloudinary.com/dxarbtyux/image/upload/v1625095575/Pink_xnhz7r.svg',
  },
  {
    id: 3,
    label: 'Ocean',
    image: 'https://res.cloudinary.com/dxarbtyux/image/upload/v1625095575/Ocean_di3ett.svg',
  },
  {
    id: 4,
    label: 'Purple',
    image: 'https://res.cloudinary.com/dxarbtyux/image/upload/v1625095575/Purple_ug8jf7.svg',
  },
];



export default linkbioThemesArray;
