import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  GetUserCreditCard,
} from '../../../../apis/endpoints/app-global-payment.endpoints';

import {
  CancelRentChangeLevel,
  PutRentChangeLevel,
} from '../../../../apis/endpoints/rent.endpoint';

import {
  IInputSelect,
} from '../../../../apis/interfaces/App/input-select.interface';

import ButtonAction from '../../../../components/Buttons/ButtonAction';

import {
  Body,
  Container,
  Content,
  Screen,
  SubTitle,
  Title,
} from '../../../../components/Composh/web';

import {
  ContentPadder,
} from '../../../../components/Contents/ContentPadder';

import {
  ButtonBack,
} from '../../../../components/Controls';

import FooterApp from '../../../../components/Footers/FooterApp';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../../components/Loadings/LoadingScreen';
import PaymentChoose from '../../../../components/Payments/PaymentChoose';
import PaymentIsention from '../../../../components/Payments/PaymentIsention';

import {
  SetSuitsGenderImage,
} from '../../../../config/genre.config';

import {
  openSite,
} from '../../../../config/linking.config';

import {
  FormatMoney,
} from '../../../../config/mask.config';

import PaymentsModal from '../../../../modals/Payments';

import {
  RootState,
} from '../../../../redux';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../../redux/reducers/app-modals.store';

import {
  typeGenders,
} from '../../../../shared/arrays/Gender/gender.array';

import {
  Colors,
  Sizes,
  Values,
} from '../../../../shared/constants';

import Vars from '../../../../shared/environments/variables';

import {
  validateString,
} from '../../../../shared/utils/string.utils';

import {
  PayAttentionInfoTitle,
  PayAttentionInfoText,
  RentBoldText,
  RentAdsArticleContent,
  RentAdsArticleTitle,
  RentAdsArticleSpan,
  RentAdsArticleSuit,
  SuitItemImage,
  PayAttentionPriceContent,
  PayAttentionLineRow,
  InpuTextPayAttention,
  PayValueAttentionPrice,
  PayChooseProContent,
  PayChooseRowActionView,
} from './styled';



const RentChangeLevelScreen: React.FC = () => {
  const dispatch = useDispatch();


  const user = useSelector((state: RootState) => state.user.actualUser);
  const userIdSearch = user?.useralias;


  const priceNowCome = 2000;

  const suitImageChoose = SetSuitsGenderImage(user?.gender);

  const [inputCaption, setInputCaption] = useState('');
  const [totalPrice, setTotalPrice] = useState(0);

  const [paymentModal, setPaymentModal] = useState(false);
  const [mainCreditCard, setMainCreditCard] = useState<any>(null);
  const [cardItem, setCardItem] = useState<any>(null);

  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function getMainCreditCard() {
    setLoading(true);

    try {
      const response = await GetUserCreditCard();
      const responseData = response?.data;

      if (!response || responseData?.length === 0) {
        // FIXME - Transformar em modal
        alert(
          'Você ainda não adicionou um cartão a sua conta',
        );
        // history(NameRoutes);
        return;
      }

      setCardItem(responseData);

      const mainCard = responseData?.filter((e: any) => e?.main === true)[0];
      setMainCreditCard(mainCard);
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : 'Houve um erro';
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
    }
  }


  async function payCallAttentionFunction() {
    const payload = {
      isToPay: user?.plan?.isToPay,
      card: user?.plan?.isToPay ? mainCreditCard?.id : null,
      level: inputCaption ? Number(inputCaption) : 0,
    };

    try {
      setLoading(true);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await PutRentChangeLevel(userIdSearch, payload);

      if (!response?.data) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, 'Erro ao ativar o anúncio');
        return;
      }

      showSnackBarProps(Colors.accept, 'Anúncio ativado com sucesso');
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : 'Houve um erro';
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  async function cancelPressAction() {
    const payload = {
      level: 0,
    };

    try {
      setLoading(true);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await CancelRentChangeLevel(userIdSearch, payload);

      if (!response?.data) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, 'Erro ao cancelar o anúncio');
        return;
      }

      showSnackBarProps(Colors.accept, 'Cancelamento feito com sucesso');
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : 'Houve um erro';
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function openCreditCardScreen() {
    openSite(Vars().globalAccountCreditCard);
  }


  function renderHeaderRent() {
    return (

      <SuitItemImage
        src={suitImageChoose}
      />

    );
  }


  async function updateForm() {
    if (user?.appRentLevel?.length > 0 && user?.appRentLevel[0]) {
      const preLevelString = String(user?.appRentLevel[0]?.level);
      setInputCaption(preLevelString);
    }

    await getMainCreditCard();

    setTimeout(() => {
      setMounted(true);
    }, Values.mountTime);
  }


  function onInputChange(rawText: string) {
    // Se o texto estiver vazio ou contiver apenas espaços em branco, limpa o input
    if (rawText.trim() === '') {
      setInputCaption('');
      return;
    }

    // Validação para permitir apenas números de 1 a 5
    const validatedText = rawText.replace(/\D/g, ''); // Remove qualquer caractere que não seja um número
    const validatedNumber = parseInt(validatedText); // Converte a string validada para um número inteiro

    // Verifica se o número está dentro do intervalo de 0 a 5
    if (!isNaN(validatedNumber) && validatedNumber >= 1 && validatedNumber <= 5) {
      setInputCaption(validatedNumber.toString()); // Define o valor do input como o número válido
    }
  }



  useEffect(() => {
    updateForm();
  }, []);


  useEffect(() => {
    if (inputCaption) {
      // Converter a quantidade de horas para número
      const hours = parseFloat(inputCaption);

      // Calcular o valor total
      const total = hours * priceNowCome;

      // Atualizar o estado do valor total
      setTotalPrice(total);
    }
    else {
      // Se não houver quantidade de horas, definir o valor total como 0
      setTotalPrice(0);
    }
  }, [inputCaption]);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <Container>

        <HeaderScreen
          disableGender
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={
            <ButtonBack
              color={Colors.white}
              size={27}
            />
          }
          centerContent={
            <Body>
              <Title
                color={Colors.white}>
                Mudar destaque
              </Title>

              <SubTitle
                color={Colors.gray}>
                Acompanhante
              </SubTitle>
            </Body>
          }
        />



        <Content>

          {!mounted && (
            <LoadingScreen
              type={'SCREEN'}
              color={Colors.primary}
            />
          )}



          {mounted && (
            <ContentPadder>

              <PayAttentionInfoTitle>
                Apareça primeiro na página de acompanhantes.
              </PayAttentionInfoTitle>


              <PayAttentionInfoText>
                Ao ecolher uma opção de destaque, você estará sempre no topo.
              </PayAttentionInfoText>


              <PayAttentionInfoText>
                Em qualquer um dos níveis de Destaque, o período válido é por <RentBoldText>30 dias</RentBoldText>, após esse prazo, se o pagamento não for efetivado, o anúncio volta para o nível de GRATUITO e para sua respectiva separação (que mostra o rosto ou não).
              </PayAttentionInfoText>


              <PayAttentionInfoText>
                Caso você faça uma viagem e registre na sua área de viagens, seu destaque acompanha você a essa cidade. Se você for destaque 5 na sua localização atual, na sua viagem você também será destaque 5.
              </PayAttentionInfoText>



              <RentAdsArticleContent>

                <RentAdsArticleTitle>
                  Gênero de destaque
                  {user?.appRentLevel[0]?.level > 0 && ': '}
                  {user?.appRentLevel[0]?.level > 0 && (
                    <RentAdsArticleSpan>
                      {`${user?.appRentLevel[0]?.level}`}
                    </RentAdsArticleSpan>
                  )}
                </RentAdsArticleTitle>


                <RentAdsArticleSuit>
                  {typeGenders?.find((item: IInputSelect) => item.value === user?.gender)?.label || '-'}:
                  {' '}
                  {renderHeaderRent()}
                </RentAdsArticleSuit>

              </RentAdsArticleContent>


              <PayAttentionInfoText>
                O valor de cada nível é
                {' '}
                <RentBoldText>
                  {FormatMoney(priceNowCome)}
                </RentBoldText>
                {' '}
                e é renovado mensalmente até você fazer o cancelamento.
              </PayAttentionInfoText>


              <PayAttentionPriceContent>

                <PayAttentionLineRow>

                  <InpuTextPayAttention
                    disabled={loading}
                    autoCorrect
                    autoCapitalize={'words'}
                    type={'TEXT'}
                    labelText={'Nível de destaque *'}
                    placeholderText={'Nível'}
                    countLimit={Values.hourMinCount}
                    value={inputCaption}
                    onChange={(rawText) => {
                      onInputChange(rawText);
                    }}
                  />


                  <PayValueAttentionPrice>
                    Total:
                    {' '}
                    <RentBoldText>
                      {FormatMoney(totalPrice)}
                    </RentBoldText>
                  </PayValueAttentionPrice>

                </PayAttentionLineRow>


                <PayAttentionInfoText>
                  Você pode escolher entre o destaque 1, até o destaque 5
                </PayAttentionInfoText>



                <PayChooseProContent>

                  {!user?.plan?.isToPay && (
                    <PaymentIsention />
                  )}


                  {user?.plan?.isToPay && (
                    <PaymentChoose
                      cardBrand={mainCreditCard?.card_brand}
                      last4={mainCreditCard?.last4 ? validateString(mainCreditCard?.last4) : null}
                      onPress={() => {
                        setPaymentModal(true);
                      }}
                    />
                  )}

                </PayChooseProContent>


                <PayChooseRowActionView>

                  {user?.plan?.isToPay && !mainCreditCard && (
                    <ButtonAction
                      backgroundColor={Colors.buttonAction}
                      width={Sizes.buttonMinWidth}
                      title={'Adicionar cartão'}
                      onPress={() => {
                        openCreditCardScreen();
                      }}
                    />
                  )}


                  {(!user?.plan?.isToPay || mainCreditCard) && (
                    <ButtonAction
                      backgroundColor={Colors.primaryDark}
                      width={Sizes.buttonMinWidth}
                      title={'Mudar destaque'}
                      onPress={() => {
                        payCallAttentionFunction();
                      }}
                    />
                  )}


                  {user?.appRentLevel?.length > 0 && user?.appRentLevel[0]?.level > 0 && (
                    <ButtonAction
                      backgroundColor={Colors.buttonCancelDark}
                      width={Sizes.buttonMinWidth}
                      title={'Cancelar destaque'}
                      onPress={() => {
                        cancelPressAction();
                      }}
                    />
                  )}

                </PayChooseRowActionView>

              </PayAttentionPriceContent>

            </ContentPadder>
          )}



          <FooterApp />

        </Content>

      </Container>



      <PaymentsModal
        visible={paymentModal}
        data={cardItem}
        onCancelPress={() => {
          setPaymentModal(false);
        }}
        onPress={(card: any) => {
          setMainCreditCard(card);
          setPaymentModal(false);
        }}
      />

    </Screen>

  );
};



export default RentChangeLevelScreen;
