import {
  createAction,
  createReducer,
} from '@reduxjs/toolkit';



const INITIAL_STATE = {
  modules: null as any,
};



// Action Types

export const PROJECT_CONFIGS_MODULES_SET = '@project_configs/PROJECT_CONFIGS_MODULES_SET';



// Action Creators

export const projectConfigsModulesAction = createAction<object>(PROJECT_CONFIGS_MODULES_SET);



// Handlers

const projectConfigsModulesReducer = (state = INITIAL_STATE, action: any) => ({
  ...state,
  modules: action.payload,
});



// Reducers

export default createReducer(INITIAL_STATE, {
  [projectConfigsModulesAction.type]: projectConfigsModulesReducer,
});
