import React from 'react';

import {
  Images,
} from '../../../shared/constants';

import {
  validateString,
} from '../../../shared/utils/string.utils';

import {
  ModeleSponsorsCity,
  ModeleSponsorsContainer,
  ModeleSponsorsContent,
  ModeleSponsorsImageBackground,
  ModeleSponsorsInfoView,
  ModeleSponsorsName,
  ModeleSponsorsOverlay,
  ModeleSponsorsTitle,
} from './styled';



export interface IProps {
  name: string;
  username: string;
  image?: string;
  onPress?: any;
}



const SponsorsModele: React.FC<IProps> = (props: IProps) => {
  const userImage = validateString(props.image) || Images.avatarModeleImage;



  return (

    <ModeleSponsorsContainer>

      <ModeleSponsorsContent
        onClick={() => {
          if (props.onPress) {
            props.onPress();
          }
        }}>

        <ModeleSponsorsImageBackground
          alt={''}
          src={userImage}
        />


        <ModeleSponsorsOverlay />



        <ModeleSponsorsInfoView>

          {/* FIXME */}
          {false && (
            <ModeleSponsorsTitle>
              Sponsor
            </ModeleSponsorsTitle>
          )}


          <ModeleSponsorsName>
            {props.name || '-'}
          </ModeleSponsorsName>


          <ModeleSponsorsCity>
            @{props.username || '-'}
          </ModeleSponsorsCity>

        </ModeleSponsorsInfoView>

      </ModeleSponsorsContent>

    </ModeleSponsorsContainer>

  );
};



export default SponsorsModele;
