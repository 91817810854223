import {
  Colors,
  Images,
} from '../shared/constants';

import {
  EGenderUser,
} from '../shared/enums';

import {
  TranslateConfig,
} from '../shared/translations';



export function SetGenrePluralText(toLightTextLevel: string) {
  switch (toLightTextLevel) {
    case EGenderUser.MAN:
      return TranslateConfig.GENRE_MEN;

    case EGenderUser.WOMAN:
      return TranslateConfig.GENRE_WOMEN;

    case EGenderUser.TRANS_MAN:
      return TranslateConfig.GENRE_TRANS_MEN;

    case EGenderUser.TRANS_WOMAN:
      return TranslateConfig.GENRE_TRANS_WOMEN;

    default:
      return '';
  }
}



export function SetGenreSingularText(toLightTextLevel: string) {
  switch (toLightTextLevel) {
    case EGenderUser.MAN:
      return TranslateConfig.GENRE_SINGLE_MAN;

    case EGenderUser.WOMAN:
      return TranslateConfig.GENRE_SINGLE_WOMAN;

    case EGenderUser.TRANS_MAN:
      return TranslateConfig.GENRE_SINGLE_TRANS_MAN;

    case EGenderUser.TRANS_WOMAN:
      return TranslateConfig.GENRE_SINGLE_TRANS_WOMAN;

    default:
      return '';
  }
}



export function SetLightGenreText(toLightTextLevel: string) {
  switch (toLightTextLevel) {
    case EGenderUser.MAN:
      return TranslateConfig.GENRE_LIGHT_MEN;

    case EGenderUser.WOMAN:
      return TranslateConfig.GENRE_LIGHT_WOMEN;

    case EGenderUser.TRANS_MAN:
      return TranslateConfig.GENRE_LIGHT_TRANS_MEN;

    case EGenderUser.TRANS_WOMAN:
      return TranslateConfig.GENRE_LIGHT_TRANS_WOMEN;

    default:
      return '';
  }
}



export function SetGenreColor(toColorLevel: string) {
  switch (toColorLevel) {
    case EGenderUser.MAN:
      return Colors.male;

    case EGenderUser.WOMAN:
      return Colors.female;

    case EGenderUser.TRANS_MAN:
      return Colors.transMale;

    case EGenderUser.TRANS_WOMAN:
      return Colors.transFemale;

    default:
      return Colors.primary;
  }
}



export function SetSuitsGenderImage(toGenderTextImage: string) {
  switch (toGenderTextImage) {
    case EGenderUser.MAN:
      return Images.suitSpades;

    case EGenderUser.WOMAN:
      return Images.suitHearts;

    case EGenderUser.TRANS_MAN:
      return Images.suitClubs;

    case EGenderUser.TRANS_WOMAN:
      return Images.suitDiamonds;

    default:
      return Images.logoIconApp;
  }
}



export function SetEmptyGenderImage(toGenderTextImage: string) {
  switch (toGenderTextImage) {
    case EGenderUser.MAN:
      return Images.noModeleUserMan;

    case EGenderUser.WOMAN:
      return Images.noModeleUserWoman;

    case EGenderUser.TRANS_MAN:
      return Images.noModeleUserTransMan;

    case EGenderUser.TRANS_WOMAN:
      return Images.noModeleUserTransWoman;

    default:
      return Images.logoIconApp;
  }
}
