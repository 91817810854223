import styled from 'styled-components';

import ButtonPanelHover from '../../../components/Details/ButtonPanelHover';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';



export const ContentLeftMessage = styled.div`
  display: flex;
  flex-direction: column;

  @media ${PlatformDevices.maxLaptop} {
    width: 100%;
    min-width: 100%;
  }

  @media ${PlatformDevices.minLaptop} {
    width: 290px;
    min-width: 290px;
    margin-right: ${Sizes.contentMargin}px;
  }

  @media ${PlatformDevices.minLaptopL} {
    width: 330px;
    min-width: 330px;
    margin-right: ${Sizes.contentMargin}px;
  }
`;

export const ContentUserItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const DashboardItemButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 70px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-color: ${Colors.grayLight};
  border-style: solid;
  border-width: 1px;
  border-radius: ${Sizes.cardRadius}px;
`;

export const DashboardClientIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 30px;
  height: 30px;
  margin-right: 15px;
`;

export const DashboardClientInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const DashboardItemText = styled.p`
  color: ${Colors.white};
  font-size: 16px;
`;

export const DashboardItemValueText = styled.p`
  margin-top: 5px;
  color: ${Colors.gray};
  font-size: 13px;
`;



export const ContentCenterFlex = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ContentUserEditItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
  gap: 10px;

  @media ${PlatformDevices.maxMobileL} {
    flex-direction: column;
  }

  @media ${PlatformDevices.maxLaptop} {
    margin-top: 10px;
  }
`;

export const ContentUserMediaItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
  gap: 10px;

  @media ${PlatformDevices.maxTabletM} {
    flex-direction: column;
  }
`;


export const PanelItemsButton = styled(ButtonPanelHover)`
  min-width: 270px;
  max-width: 500px;

  @media ${PlatformDevices.maxLaptop} {
    min-width: 150px;
  }
`;

export const PanelItemsIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3px;
  margin-bottom: 12px;
`;

export const PanelItemsText = styled.p`
  color: ${Colors.white};
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
`;


export const WrapperItemContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 25px;
`;

export const DashboardBillingContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  gap: 12px;

  @media ${PlatformDevices.maxLaptopL} {
    flex-direction: column;
    gap: 15px;
  }
`;



export const WrapperLastContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const DashboardItemTitle = styled.h5`
  margin-top: 10px;
  margin-bottom: 10px;
  color: ${Colors.primary};
  font-size: 15px;
`;

export const DashboardItemGrid = styled.div`
  display: grid;
  grid-gap: 12px;
  margin-top: 5px;

  @media ${PlatformDevices.maxMobileL} {
    grid-template-columns: 1fr;
  }

  @media ${PlatformDevices.minMobileL} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${PlatformDevices.minLaptopL} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
