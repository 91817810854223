import styled,
{
  css,
} from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../../shared/constants';

import {
  PlatformDevices,
} from '../../../../shared/utils/platforms.utils';



export interface IProps {
  clickable?: boolean;
  disabled?: boolean;
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
}



export const CardPostContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;


export const CardPostHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
`;

export const CardPostUserButton = styled.a`
  display: flex;
  flex-direction: column;
  cursor: ${(props: IProps) => !props.clickable ? 'default' : 'pointer'};
`;



export const CardPostInfoView = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 14px;
`;

export const CardPostNameView = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;
  cursor: ${(props: IProps) => !props.clickable ? 'default' : 'pointer'};
`;

export const CardPostNameBadge = styled.div`
  width: 10px;
  height: 10px;
  margin-right: 8px;
  background-color: ${Colors.accept};
  border-radius: 50%;
`;

export const CardPostNameText = styled.h4`
  color: ${Colors.white};
  font-size: 21px;
  line-height: 24px;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  word-break: break-word;
`;

export const CardPostUsernameView = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CardPostUsernameText = styled.p`
  color: ${Colors.gray};
  font-size: 16px;
  cursor: ${(props: IProps) => !props.clickable ? 'default' : 'pointer'};

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  word-break: break-word;
`;

export const CardPostVerifiedIcon = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
`;



export const CardPostCommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const CardPostDatePostedText = styled.p`
  color: ${Colors.grayLight};
  font-size: 14px;
  line-height: 18px;
`;

export const CardPostHourPostedText = styled.p`
  margin-top: 3px;
  color: ${Colors.textDescription};
  font-size: 11px;
`;



export const CardPostImageTypeCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background-color: ${Colors.black};
  width: 100%;
  min-height: 200px;
  max-height: 400px;

  @media ${PlatformDevices.maxTablet} {
    max-height: 480px;
  }
`;


export const CardPostImageContainer = styled.a`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
`;

export const CardPostImageTypeImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 5;
`;

export const CardPostBackgroundTypeImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  filter: blur(15px);
`;



export const CardPostVideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;
`;

export const CardPostImageTypeVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const CardPostVideoPlayButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  background-color: rgba(47, 41, 41, 0.5);
  transform: translate(-50%,-50%);
  border-radius: 50%;
`;

export const CardPostVideoControlsView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: absolute;
  right: 10px;
  bottom: 13px;
`;

export const CardPostVideoMuteButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-left: 10px;
  background-color: rgba(47, 41, 41, 0.5);
  border-radius: 50%;
`;


export const CardPostFooterActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 3px;
`;


export const CardPostImageTypeView = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 10px;
  border-bottom: 1px solid #333333;
`;

export const CardPostCaptionView = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const CardPostImageTypeCaptionText = styled.p`
  color: ${Colors.white};
  font-size: 14px;
`;

export const CardPostLikeView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 6px;
`;

export const CardPostLikeIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 7px;
`;

export const CardPostLikeText = styled.p`
  color: ${Colors.subtextApp};
  font-size: 12px;
`;



export const CardPostFooterActionsContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  height: 45px;
  padding-top: 3px;
`;



export const CardPostButtonStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  opacity: ${(props: IProps) => props.disabled ? Sizes.opacityOff : Sizes.opacityOn};
`;


export const CardPostFooterLikeButton = styled.a`
  ${CardPostButtonStyle};
  flex: 1;

  /*
  @media ${PlatformDevices.maxTablet} {
    max-width: 100px;
    padding-right: 10px;
  }
  */
`;

export const CardPostFooterButton = styled.a`
  ${CardPostButtonStyle};
  flex: 1;
`;

export const CardPostFooterIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  margin-right: 10px;
  margin-bottom: 4px;
`;

export const CardPostFooterText = styled.p`
  color: ${(props: IProps) => props.color};
  font-size: 13px;
  line-height: 16px;
`;

export const CardPostActionHeaderButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 100%;
  margin-left: 10px;
  opacity: ${(props: IProps) => props.disabled ? Sizes.opacityOff : Sizes.opacityOn};
`;
