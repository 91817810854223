import styled from 'styled-components';



interface IProps {
  rank?: string;
  top10?: boolean;
}



export const CardRankingModeleContainer = styled.a`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  aspect-ratio: 1.6;
`;


export const ModeleRankingImageBackground = styled.img`
  width: 100%; 
  height: 100%;
  object-fit: cover;
  z-index: 0;
`;


export const ModeleRankBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 50px;
  height: 50px;
  background: ${(props: IProps) => props.rank === '1'
    ? '#189052'
    : props.top10
      ? 'linear-gradient(to bottom, #fadf38 6%, #f4b52a 49%, #f3b127 50%, #ef9632 100%)'
      : 'rgba(45,125,202,0.7)'};
  z-index: 1;
`;

export const ModeleRankBackground = styled.img`
  position: absolute;
  top: 0;
  width: 50px;
  height: 50px;
  object-fit: center;
  z-index: 0;
`;

export const ModeleRankUp = styled.div`
  display: flex;
  align-self: center;
  position: absolute;
  top: 4px;
  width: 0;
  height: 0;
  border-bottom: 8px solid #00ff00;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  z-index: 1;
`;

export const ModeleRankText = styled.span`
  color: #ffffff;
  font-size: 17px;
  z-index: 1;
`;

export const ModeleRankDown = styled.div`
  display: flex;
  align-self: center;
  position: absolute;
  bottom: 4px;
  width: 0;
  height: 0;
  border-top: 8px solid #ed1c24;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  z-index: 1;
`;

export const ModeleRankUserText = styled.p`
  position: absolute;
  color: #fff;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-top: 5px;
  padding-left: 7px;
  padding-right: 7px;
  padding-bottom: 5px;
  font-size: 17px;
  background: linear-gradient(to right, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.32) 65%, transparent 100%);
`;
