import styled from 'styled-components';

import InputText from '../../components/Inputs/InputText';

import {
  Colors,
  Sizes,
} from '../../shared/constants';



export const ModeleBadReportContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  max-width: 500px;
  min-width: 320px;
  background-color: ${Colors.appBackground};
  border-radius: ${Sizes.cardRadius}px;
`;


export const ModeleBadReportContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
`;

export const ModeleBadReportText = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: white;
  margin-bottom: 25px;
`;

export const ModeleBadReportInput = styled(InputText)`
  flex: 1;
`;


export const ModeleBadReportCaptchaView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 15px;
`;

export const ModeleBadReportCaptchaImageView = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 90px;
  aspect-ratio: auto 104 / 54;
  margin-right: 16px;
  background-color: ${Colors.black};
  border-radius: ${Sizes.cardRadius}px;
`;
