import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';



export interface IProps {
  disabled?: boolean;
}



export const ButtonGenderContainer = styled.a`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  min-width: 100px;
  margin-top: 2px;
  margin-left: 10px;
  margin-right: 8px;
  cursor: ${(props: IProps) => props.disabled ? 'default' : 'pointer'};

  @media ${PlatformDevices.maxMobileL} {
    margin-left: 1.5px;
    margin-right: 1.5px;
  }
`;

export const LogoImage = styled.img`
  height: 22px;
  object-fit: contain;

  @media ${PlatformDevices.maxMobileL} {
    height: 18px;
  }
`;


export const GenderView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 20px;
  margin-top: 2px;
`;

export const GenderText = styled.p`
  color: ${Colors.white};
  font-size: 14px;
  font-style: normal;
  line-height: 16px;

  @media ${PlatformDevices.maxMobileL} {
    font-size: 14px;
    line-height: 16px;
  }
`;

export const IconArrowComponent = styled.div`
  margin-top: 3px;
  margin-left: -2px;
`;
