import React from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useNavigate,
} from 'react-router-dom';

import ButtonAction from '../../../components/Buttons/ButtonAction';

import {
  AppBar,
  Content,
  Epigraph,
  Screen,
} from '../../../components/Composh/web';

import FooterApp from '../../../components/Footers/FooterApp';
import HeaderFixed from '../../../components/Headers/HeaderFixed';

import NameRoutes from '../../../navigation/names';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import {
  EEnvsName,
} from '../../../shared/enums';

import Vars from '../../../shared/environments/variables';

import Seo from '../../../shared/seo';

import {
  TranslateConfig,
} from '../../../shared/translations';

import {
  AdsRegisterContainer,
  AdsRegisterArticleContainer,
  AdsRegisterArticleView,
  AdsRegisterArticleText,
  AdsRegisterButtonOrText,
} from './styled';



const AdsRegisterBothScreen: React.FC = () => {
  const history = useNavigate();
  const { t: translate } = useTranslation();



  function openBeUser() {
    history(
      NameRoutes.WebViewScreen,
      {
        state: {
          title: translate(TranslateConfig.REGISTER),
          link: Vars().globalAccountRegister,
        },
      },
    );
  }

  function openBeModele() {
    history(NameRoutes.BeModeleScreen);
  }


  const urlJsonLd = `${Vars(EEnvsName.PROD).secretioApp}${NameRoutes.AdsRegisterBothScreen}`;

  const jsonLd = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    '@id': urlJsonLd,
    'url': urlJsonLd,
    'name': `${translate('Registre-se agora')} | ${translate(TranslateConfig.NAME_APP)}`,
    'image': Images.bannerAppLink,
    'description': Seo.descriptionRegister,
    'isPartOf': {
      '@type': 'WebSite',
      'url': Vars(EEnvsName.PROD).secretioApp,
      'name': translate(TranslateConfig.NAME_APP),
    },
  };



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet prioritizeSeoTags>
        <title>
          {`${translate('Registre-se agora')} | ${translate(TranslateConfig.NAME_APP)}`}
        </title>

        <meta name="robots" content="index, follow" />
        <meta name="description" content={Seo.descriptionRegister} />

        <meta name="keywords" content={Seo.keywords} />

        <meta property="og:site_name" content={translate(TranslateConfig.NAME_APP)} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={Images.logo512} />
        <meta property="og:url" content={urlJsonLd} />
        <meta property="og:title" content={`${translate('Registre-se agora')} | ${translate(TranslateConfig.NAME_APP)}`} />
        <meta property="og:description" content={Seo.descriptionRegister} />

        <link rel="canonical" href={urlJsonLd} />

        <script type="application/ld+json">
          {JSON.stringify(jsonLd)}
        </script>
      </Helmet>


      <AppBar>
        <HeaderFixed />
      </AppBar>



      <Content>

        <AdsRegisterContainer>

          <AdsRegisterArticleContainer>

            <AdsRegisterArticleView>
              <Epigraph.Section
                bold
                text={'Registre-se'}
                textColor={Colors.primary}
                borderBottomWidth={2}
                borderBottomColor={Colors.primary}
                textSize={22}
              />


              <AdsRegisterArticleText>
                Seja um cliente ou um modelo. Você escolhe
              </AdsRegisterArticleText>
            </AdsRegisterArticleView>



            <ButtonAction
              title={translate('Seja cliente')}
              backgroundColor={Colors.primaryDark}
              onPress={() => {
                openBeUser();
              }}
            />


            <AdsRegisterButtonOrText>
              {translate(TranslateConfig.OR).toUpperCase()}
            </AdsRegisterButtonOrText>


            <ButtonAction
              title={translate('Seja modelo')}
              backgroundColor={Colors.primaryDark}
              onPress={() => {
                openBeModele();
              }}
            />


          </AdsRegisterArticleContainer>

        </AdsRegisterContainer>



        <FooterApp />

      </Content>



    </Screen>

  );
};



export default AdsRegisterBothScreen;
