import React from 'react';


import {
  Content,
  Screen,
  Title,
} from '../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../components/Controls';

import HeaderScreen from '../../../components/Headers/HeaderScreen';
import {
  Colors,
} from '../../../shared/constants';



const PeopleClubListScreen: React.FC = () => {
  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        disableGender
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            color={Colors.white}
            size={27}
          />
        }
        centerContent={
          <Title>
            Meu Fã Clube
          </Title>
        }
      />



      <Content>

        <h2>
          Lista de Modelos feito fans
        </h2>

      </Content>

    </Screen>

  );
};



export default PeopleClubListScreen;
