import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  IconCheck,
  IconClose,
} from '../../../../components/Icons';

import {
  Colors,
} from '../../../../shared/constants';

import {
  TranslateConfig,
} from '../../../../shared/translations';

import {
  VideoContentRightHome,
  WarningApproveText,
  WrapperItemContent,
  ApproveSplitView,
  ApproveSplitIconView,
  ApproveSplitText,
  ApproveItemInfoView,
  ApproveItemSplitText,
} from './styled';



const InfoUserImage: React.FC = () => {
  const { t: translate } = useTranslation();



  return (

    <VideoContentRightHome>

      <WarningApproveText>
        {translate(TranslateConfig.MEDIA_APPROVE_TITLE)}
      </WarningApproveText>



      <WrapperItemContent>

        <ApproveSplitView>
          <ApproveSplitIconView>
            <IconCheck
              color={Colors.black}
              size={20}
            />
          </ApproveSplitIconView>


          <ApproveSplitText>
            {translate(TranslateConfig.MEDIIA_ACCEPT_TITLE)}
          </ApproveSplitText>
        </ApproveSplitView>



        <ApproveItemInfoView>
          <ApproveItemSplitText>
            {translate(TranslateConfig.MEDIIA_ACCEPT_1)}
          </ApproveItemSplitText>

          <ApproveItemSplitText>
            {translate(TranslateConfig.MEDIIA_ACCEPT_2)}
          </ApproveItemSplitText>
        </ApproveItemInfoView>

      </WrapperItemContent>



      <WrapperItemContent>

        <ApproveSplitView>
          <ApproveSplitIconView>
            <IconClose
              color={Colors.black}
              size={20}
            />
          </ApproveSplitIconView>


          <ApproveSplitText>
            {translate(TranslateConfig.MEDIIA_NOT_ACCEPT_TITLE)}
          </ApproveSplitText>
        </ApproveSplitView>



        <ApproveItemInfoView>
          <ApproveItemSplitText>
            {translate(TranslateConfig.MEDIIA_NOT_ACCEPT_1)}
          </ApproveItemSplitText>

          <ApproveItemSplitText>
            {translate(TranslateConfig.MEDIIA_NOT_ACCEPT_2)}
          </ApproveItemSplitText>
        </ApproveItemInfoView>

      </WrapperItemContent>

    </VideoContentRightHome>

  );
};



export default InfoUserImage;
