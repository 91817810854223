import React,
{
  useState,
} from 'react';

import {
  Colors,
} from '../../shared/constants';

import {
  IconStar,
} from '../Icons';

import {
  RatingContainer,
  RatingButton,
} from './styled';



export interface IProps {
  rating?: number;
  quantity?: number;
  color?: string;
  colorAccent?: string;
  size?: number;
  onPress?: any;
}



const Rating: React.FC<IProps> = (props: IProps) => {
  const [rating, setRating] = useState(props.rating || 0);

  const quantityArray = props.quantity || 5;
  const normalColor = props.color || Colors.inputPlaceholder;
  const accentColor = props.colorAccent || Colors.primaryDark;
  const sizeIcon = props.size || 26;



  function handleStarClick(value: number) {
    setRating(value);

    if (props.onPress) {
      props.onPress(value);
    }
  };



  return (

    <RatingContainer>

      {[...Array(quantityArray)].map((_, index) => (

        <RatingButton
          key={index + 1}
          onClick={() => {
            handleStarClick(index + 1);
          }}>

          <IconStar
            color={index < rating ? accentColor : normalColor}
            size={sizeIcon}
          />

        </RatingButton>

      ))}

    </RatingContainer>

  );
};



export default Rating;
