import React from 'react';

import {
  Overlay,
} from '../../../../../components/Composh/web';

import HeaderModal from '../../../../../components/Headers/HeaderModal';

import {
  Colors,
} from '../../../../../shared/constants';

import {
  CategoryChooseContainer,
  CategoryContent,
} from './styled';



export interface IProps {
  visible?: boolean;
  onClose?: any;
  children?: any;
}



const AntiFakeInfoModal: React.FC<IProps> = (props: IProps) => {
  const visibleModal = props.visible;
  if (!visibleModal) {
    return null;
  }


  return (

    <Overlay
      visible={visibleModal}
      showBackground={true}
      overlayColor={Colors.menuOverlay}
      onOverlayPress={props.onClose}>

      <CategoryChooseContainer>

        <HeaderModal
          title={'Mídia anti-fake'}
          onPress={props.onClose}
        />


        <CategoryContent>
          {props.children}
        </CategoryContent>

      </CategoryChooseContainer>

    </Overlay>

  );
};



export default AntiFakeInfoModal;
