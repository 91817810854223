export const APP_ACCOUNT = {
  globalAccountRegister:            'https://conta-dev.plugante.com/register-user',
  globalAccountForgotPassword:      'https://conta-dev.plugante.com/conta/password/forgot',
  globalAccountUser:                'https://conta-dev.plugante.com/conta',
  globalAccountAddress:             'https://conta-dev.plugante.com/conta/address',
  globalAccountProfile:             'https://conta-dev.plugante.com/conta/profiles/public/details',
  globalAccountCreditCard:          'https://conta-dev.plugante.com/conta/payments/cards',
};


export const LINKS = {
  secretioRankingApp:               'https://dev.secretio.app/ranking',
};


export const SOCIAL = {
  secretioApp:                      'https://dev.secretio.app',
  secretioStreamApp:                'https://stream-dev.secretio.app',
  secretioSite:                     'https://site-dev.secretio.app',

  secretioAbout:                    'https://site-dev.secretio.app/about',
  secretioContact:                  'https://site-dev.secretio.app/contact',
};


export const TERMS = {
  licencesLink:                     'https://site-dev.secretio.app/terms-policy/secretio-license',
  privacityPolicyLink:              'https://site-dev.secretio.app/terms-policy/secretio-privacity',
  termCookiesLink:                  'https://site-dev.secretio.app/terms-policy/secretio-cookies',
  termsUseLink:                     'https://site-dev.secretio.app/terms-policy/secretio-use',
};



const Vars = {
  ...APP_ACCOUNT,
  ...LINKS,
  ...SOCIAL,
  ...TERMS,
};



export default Vars;
