import React from 'react';

import Color from 'color';

import {
  Colors,
} from '../../../shared/constants';

import EProjectMessagesStatus from '../../../shared/enums/Project/project-messages-status.enum';

import {
  IconCircleCheck,
  IconCircleRemove,
  IconInformation,
} from '../../Icons';

import {
  EditWarningView,
  InfoIcon,
  WarningInfoView,
  EditWarningTitle,
  EditWarningDescription,
} from './styled';



export interface IProps {
  status?: EProjectMessagesStatus;
  title?: string;
  description: string;
}



const WarningItemContent: React.FC<IProps> = (props: IProps) => {
  const className: any = { ...props };

  const sizeNumber = 13;



  function renderBackground() {
    if (props.status === EProjectMessagesStatus.SUCCESS) {
      return Color(Colors.accept).lighten(0.95).toString();
    }

    if (props.status === EProjectMessagesStatus.WARNING) {
      return Color(Colors.attention).lighten(0.3).toString();
    }

    if (props.status === EProjectMessagesStatus.ERROR) {
      return Color(Colors.danger).lighten(0.3).toString();
    }

    return Colors.gray;
  };


  function renderColor() {
    if (props.status === EProjectMessagesStatus.SUCCESS) {
      return Color(Colors.accept).darken(0.6).toString();
    }

    if (props.status === EProjectMessagesStatus.WARNING) {
      return Color(Colors.attention).darken(0.35).toString();
    }

    if (props.status === EProjectMessagesStatus.ERROR) {
      return Color(Colors.danger).darken(0.35).toString();
    }

    return Colors.gray;
  };


  function renderIcon() {
    const sizeIcon = sizeNumber * 2.4;
    const color = renderColor();


    if (props.status === EProjectMessagesStatus.SUCCESS) {
      return (
        <IconCircleCheck
          size={sizeIcon}
          color={color}
        />
      );
    }

    if (props.status === EProjectMessagesStatus.WARNING) {
      return (
        <IconInformation
          size={sizeIcon}
          color={color}
        />
      );
    }

    if (props.status === EProjectMessagesStatus.ERROR) {
      return (
        <IconCircleRemove
          size={sizeIcon}
          color={color}
        />
      );
    }

    return (
      <IconInformation
        size={sizeIcon}
        color={color}
      />
    );
  };



  return (

    <EditWarningView
      className={className?.className}
      color={renderColor()}
      backgroundColor={renderBackground()}>

      <InfoIcon>
        {renderIcon()}
      </InfoIcon>


      <WarningInfoView>

        <EditWarningTitle
          color={renderColor()}
          size={sizeNumber}>
          {props.title}
        </EditWarningTitle>


        <EditWarningDescription
          size={sizeNumber}>
          {props.description}
        </EditWarningDescription>

      </WarningInfoView>

    </EditWarningView>

  );
};



WarningItemContent.defaultProps = {
  status: EProjectMessagesStatus.WARNING,
};



export default WarningItemContent;
