import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useNavigate,
} from 'react-router-dom';

import {
  GetAllSuggestions,
} from '../../apis/endpoints/suggestions.endpoints';

import CardAdsSuggestion from '../../components/Ads';
import CardModeleSuggestion from '../../components/Cards/CardModeleSuggestion';

import {
  Title,
  Content,
  Screen,
  Body,
  SubTitle,
} from '../../components/Composh/web';

import {
  ContentRowToColumnPadder,
} from '../../components/Contents/ContentPadder';

import {
  ButtonDrawer,
} from '../../components/Controls';

import EmptyContent from '../../components/Empty';
import FooterApp from '../../components/Footers/FooterApp';
import HeaderScreen from '../../components/Headers/HeaderScreen';
import LoadingScreen from '../../components/Loadings/LoadingScreen';
import SectionTitle from '../../components/Titles/SectionTitle';

import {
  SetLightGenreText,
} from '../../config/genre.config';

import NameRoutes from '../../navigation/names';

import {
  RootState,
} from '../../redux';

import {
  APP_MENU_SELECTED_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../redux/reducers/app-modals.store';

import {
  Colors,
  Images,
} from '../../shared/constants';

import EDrawerMenu from '../../shared/enums/App/drawer-menu.enum';

import {
  TranslateConfig,
} from '../../shared/translations';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../shared/utils/errors.utils';

import {
  ContentCenterFlex,
  WrapperItemContent,
} from '../../styles/styled.layout';

import {
  SuggestionModeleItemGrid,
  // SuggestionRoomOnlineList,
} from './styled';



const SuggestionScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { t: translate } = useTranslation();


  const genderChoose = useSelector((state: RootState) => state.choose.genderChoose);

  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);


  const [modeles, setModeles] = useState<Array<any>>([]);

  const [loading, setLoading] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function getAllSuggestions() {
    try {
      setLoading(true);

      const response = await GetAllSuggestions(genderChoose);

      if (!response?.data || !Array.isArray(response?.data)) {
        setModeles([]);
        return;
      }

      setModeles(response?.data);
    }
    catch (error: any) {
      console.error(error);
      setModeles([]);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
    }
  };


  function openDetailsModele(useralias: string) {
    history(NameRoutes.ModeleDetailsScreen.replace(
      ':id',
      useralias,
    ));
  }


  function renderBigLongItem(item: any, index: number) {
    return (

      <CardModeleSuggestion
        key={index}
        online={item?.online}
        exclusive={item?.exclusive}
        plan={item?.plan?.planCurrent}
        image={item?.picture}
        backgroundImage={item?.pictureCover}
        username={item?.useralias}
        name={item?.alias}
        verified={item?.verified}
        promoter={item?.promoter}
        onPress={() => {
          openDetailsModele(item?.useralias);
        }}
      />

    );
  }


  function renderLoading() {
    if (!loading) {
      return null;
    }

    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.white}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noSuggestions}
        title={translate(TranslateConfig.EMPTY_ITEMS)}
      />

    );
  }



  useEffect(() => {
    getAllSuggestions();
  }, [genderChoose]);


  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.MY_PRIVE });
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet prioritizeSeoTags>
        <title>
          {`${translate(TranslateConfig.SUGGESTIONS)} | ${translate(TranslateConfig.NAME_APP)}`}
        </title>

        <meta name="robots" content="noindex, nofollow" />
      </Helmet>



      <HeaderScreen
        screenKey={EDrawerMenu.SUGGESTIONS}
        showBalance
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={projectParams?.canAccess
          ? (
            <ButtonDrawer
              color={Colors.white}
              size={27}
            />
          )
          : null
        }
        centerContent={
          <Body>
            <Title
              color={Colors.white}>
              {translate(TranslateConfig.SUGGESTIONS)}
            </Title>

            <SubTitle>
              {translate(SetLightGenreText(genderChoose))}
            </SubTitle>
          </Body>
        }
      />



      <Content>

        {loading && (
          renderLoading()
        )}



        {!loading && (
          <ContentRowToColumnPadder>

            <ContentCenterFlex>

              {modeles?.length <= 0 && (
                listEmptyComponent()
              )}



              {modeles?.length > 0 && (
                <WrapperItemContent>
                  <SectionTitle
                    title={translate(TranslateConfig.SUGGESTIONS)}
                  />


                  <SuggestionModeleItemGrid>
                    {modeles.map((item: any, index: number) => (
                      renderBigLongItem(item, index)
                    ))}
                  </SuggestionModeleItemGrid>
                </WrapperItemContent>
              )}
            </ContentCenterFlex>



            <CardAdsSuggestion />

          </ContentRowToColumnPadder>
        )}



        <FooterApp />

      </Content>

    </Screen>

  );
};



export default SuggestionScreen;
