import styled from 'styled-components';

import {
  Images,
} from '../../../shared/constants';



export const ModelRecordContainer = styled.a`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
`;

export const ModelContainerButton = styled.a`
  width: 100%;
  max-width: 300px;
  aspect-ratio: 1.33;
  margin-bottom: -2px;
  padding: 2px;
  background: linear-gradient(45deg, #f1e294, #ae7028);
`;

export const ModelContainerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;


export const ModelContentInfoView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 346px;
  min-height: 112px;
  background-image: url(${Images.frameHallHolder});
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100%;
`;

export const ModelContentCategoryText = styled.p`
  padding-top: 13px;
  padding-bottom: 12px;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
`;

export const ModelContentNameView = styled.div`
  display: flex;
  height: 100%;
  max-height: 50px;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
`;

export const ModelContentNameText = styled.p`
  color: #000000;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
`;

export const ModelContentUserInfoView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
`;

export const ModelContentQuantityText = styled.p`
  font-size: 18px;
  line-height: 26px;
  color: #fff2cd;
  text-align: center;
`;

export const ModelContentDateText = styled.p`
  font-size: 13px;
  line-height: 19px;
  color: #aa8c51;
  font-weight: 600;
  text-align: center;
`;
