import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';



export interface IProps {
  disabled?: boolean;
}



export const CardReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-top: 7px;
  margin-bottom: 7px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;


export const UserReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
`;


export const UserReviewsBoxHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
`;

export const UserReviewsInfoBoxHeader = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const UserReviewsInfoHeader = styled.div`
  display: flex;
  flex-direction: row;
`;

export const UserReviewUserPhoto = styled.a`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`;


export const UserReviewInfoContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 10px;
`;

export const UserReviewInfoTitle = styled.h3`
  color: ${Colors.white};
  font-size: 17px;
  cursor: pointer;
`;

export const UserReviewInfoPlaceText = styled.p`
  margin-top: 3px;
  color: ${Colors.grayDark};
  font-size: 15px;
`;

export const UserReviewTimeText = styled.p`
  margin-top: 7px;
  color: ${Colors.gray};
  font-size: 13px;
  font-weight: 400;
`;


export const UserReviewsBoxHeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  padding-right: 3px;
`;

export const UserStarRatingBox = styled.p`
  margin-top: 4px;
  margin-left: 8px;
  font-size: 20px;
  color: ${Colors.white};
  text-align: right;
`;



export const UserReviewMakeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-bottom: 15px;
`;

export const UserReviewMakeLabel = styled.p`
  margin-bottom: 7px;
  color: ${Colors.primaryDark};
  font-size: 16px;
`;

export const UserReviewMakeInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const UserReviewMakeInfoView = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

export const UserReviewMakeAliasText = styled.p`
  color: ${Colors.white};
  font-size: 16px;
  font-weight: bold;
`;



export const UserReviewDescriptionText = styled.p`
  margin-bottom: 12px;
  color: ${Colors.white};
  font-size: 15px;
  line-height: 21px;
  word-break: break-word;
`;



export const UserReviewTypeContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-between;
`;


export const UserReviewTypeItemContent = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 25%;
  margin-top: 7px;
  margin-bottom: 7px;

  @media ${PlatformDevices.maxTabletM} {
    min-width: 50%;
  }
`;

export const UserReviewTypeItemTitle = styled.p`
  margin-bottom: 4px;
  color: ${Colors.gray};
  font-size: 14px;
`;

export const UserReviewTypeItemText = styled.p`
  color: ${Colors.white};
  font-size: 16px;
`;



export const ReviewActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: ${Colors.cardFooterOverlay};
`;

export const ReviewActionsButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  opacity: ${(props: IProps) => props.disabled ? Sizes.opacityOff : Sizes.opacityOn};
`;

export const ReviewActionsIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 8px;
`;

export const ReviewActionsText = styled.p`
  color: ${Colors.primary};
  font-size: 15px;
`;
