import styled,
{
  css,
} from 'styled-components';

import {
  Content,
  ListSection,
} from '../../../../components/Composh/web';

import {
  Colors,
  Sizes,
} from '../../../../shared/constants';



export const DdiModalView = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 320px;
  height: 610px;
  background-color: ${Colors.appBackground};
  border-radius: ${Sizes.cardRadius}px;
`;



export const ListSectionCss = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 5px;
  padding-left: 14px;
  padding-right: 14px;
  padding-bottom: 5px;
`;


export const FilteredInput = styled.div`
  ${ListSectionCss};
  border-bottom-color: ${Colors.toolbarLine};
  border-bottom-style: solid;
  border-bottom-width: 1px;
`;

export const DdiClearButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;



export const FilteredDdiContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  width: 100%;
  height: 100%;
`;


export const FilteredListSection = styled(Content)`
  ${ListSectionCss};
`;


export const StyledListSection = styled(ListSection)`
  ${ListSectionCss};
  flex-grow: 1;
`;
