import React from 'react';

import {
  countriesOption,
} from '../../../shared/arrays/App/countries.array';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import {
  EPlansUser,
} from '../../../shared/enums';

import {
  stringRawDataText,
  validateString,
} from '../../../shared/utils/string.utils';

import UserAvatar from '../../Contents/UserAvatar';

import {
  IconFaClub,
  IconRentOn,
  IconRoomOpen,
  IconVerifiedPromoter,
} from '../../Icons';

import {
  CardFavoriteContainer,
  FavoriteLeftView,
  FavoriteLeftHeader,
  UserFavoriteInfoView,
  UserFavoriteName,
  FavoriteLocationView,
  FavoriteMapPinIcon,
  ModelesPlaceCountry,
  FavoriteUserAliasNameText,
  FavoriteUserAliasText,
  UserFavoriteVerifiedView,
  UserFavoriteIcon,
  FavoriteAvailableView,
  FavoriteItemAvailableView,
  FavoriteItemAvailableIcon,
  FavoriteItemAvailableInfo,
  FavoriteTypeAvailableText,
  FavoriteUserLocationText,
  FavoritesButtonText,
  RightItemView,
  RightItemImageView,
  RightItemImage,
} from './styled';



interface IProps {
  alias?: string;
  useralias?: string;
  image?: string;

  rent?: boolean;
  fanClub?: boolean;
  live?: boolean;
  massage?: boolean;

  available?: boolean;
  plan?: EPlansUser;

  country?: string;
  place?: string;

  arrayImage?: Array<any>;

  onPress?: any;
}



const CardFavorites: React.FC<IProps> = (props: IProps) => {
  const imageFavorite = validateString(props?.image) || Images.avatarUserImage;

  const countryValue = props.country;
  const filterCountry = countriesOption.find((country) => stringRawDataText(country?.code) === stringRawDataText(countryValue));



  function renderItemAvailable(icon: any, title: string) {
    return (

      <FavoriteItemAvailableView>

        <FavoriteItemAvailableIcon>
          {icon}
        </FavoriteItemAvailableIcon>


        <FavoriteItemAvailableInfo>

          <FavoriteTypeAvailableText>
            {title}
          </FavoriteTypeAvailableText>


          <FavoriteUserLocationText>
            {'Disponível'}
          </FavoriteUserLocationText>

        </FavoriteItemAvailableInfo>

      </FavoriteItemAvailableView>

    );
  }



  return (

    <CardFavoriteContainer
      // disabled={props.disabled}
      onClick={() => {
        if (props.onPress) {
          props.onPress();
        }
      }}>

      <FavoriteLeftView>

        <FavoriteLeftHeader>

          <UserAvatar
            width={70}
            image={imageFavorite}
            plan={props.plan}
          />



          <UserFavoriteInfoView>

            <UserFavoriteName>
              {props.alias}
            </UserFavoriteName>



            <UserFavoriteVerifiedView>

              <FavoriteUserAliasText>
                @{props.useralias}
              </FavoriteUserAliasText>

              <UserFavoriteIcon>
                <IconVerifiedPromoter
                  color={Colors.accent}
                  size={16}
                />
              </UserFavoriteIcon>

            </UserFavoriteVerifiedView>



            {!countryValue && (
              <FavoriteLocationView>

                <FavoriteUserAliasNameText>
                  Localização indisponível
                </FavoriteUserAliasNameText>

              </FavoriteLocationView>
            )}



            {countryValue && (
              <FavoriteLocationView>

                <FavoriteMapPinIcon>
                  <ModelesPlaceCountry
                    src={filterCountry?.image}
                  />
                </FavoriteMapPinIcon>


                <FavoriteUserAliasNameText>
                  Em: {props.place}
                </FavoriteUserAliasNameText>

              </FavoriteLocationView>
            )}

          </UserFavoriteInfoView>

        </FavoriteLeftHeader>



        {(props.rent || props.fanClub || props.live && props.massage) && (
          <FavoriteAvailableView>
            {props.rent && renderItemAvailable(
              <IconRentOn
                size={24}
                color={Colors.white}
              />,
              'Acompanhante',
            )}


            {props.massage && renderItemAvailable(
              <IconRoomOpen
                size={24}
                color={Colors.white}
              />,
              'Massagem',
            )}


            {props.fanClub && renderItemAvailable(
              <IconFaClub
                size={24}
                color={Colors.white}
              />,
              'Fã-clube',
            )}


            {props.live && renderItemAvailable(
              <IconRoomOpen
                size={24}
                color={Colors.white}
              />,
              'Ao Vivo',
            )}
          </FavoriteAvailableView>
        )}


        <FavoritesButtonText>
          Clique para ver o perfil de {props.alias}
        </FavoritesButtonText>

      </FavoriteLeftView>



      {props.arrayImage?.length > 0 && (
        <RightItemView>

          {props.arrayImage[0] && (
            <RightItemImageView>
              <RightItemImage
                src={props.arrayImage[0]?.picture}
              />
            </RightItemImageView>
          )}


          {props.arrayImage[1] && (
            <RightItemImageView>
              <RightItemImage
                src={props.arrayImage[1]?.picture}
              />
            </RightItemImageView>
          )}


          {props.arrayImage[2] && (
            <RightItemImageView>
              <RightItemImage
                src={props.arrayImage[2]?.picture}
              />
            </RightItemImageView>
          )}


          {props.arrayImage[3] && (
            <RightItemImageView>
              <RightItemImage
                src={props.arrayImage[3]?.picture}
              />
            </RightItemImageView>
          )}

        </RightItemView>
      )}

    </CardFavoriteContainer>

  );
};



export default CardFavorites;
