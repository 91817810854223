import styled from 'styled-components';

import {
  Sizes,
} from '../../../shared/constants';



export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;



export const CharRowWrapperCenterView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
`;

export const CharRowWrapperAuto = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: ${Sizes.contentMaxWidth}px;
`;
