import React from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  IGenderHighlight,
} from '../../../../apis/interfaces/Genders/gender-highlight.interface';

import {
  RootState,
} from '../../../../redux';

import {
  EGenderUser,
} from '../../../../shared/enums';

import {
  ImageContentRightHome,
  WarningApproveText,
  WrapperItemContent,
  AntiFakeItemSplitText,
  AntiFakeRightVideoView,
} from './styled';



const RightAntiFakeMediaInfo: React.FC = () => {
  const user = useSelector((state: RootState) => state.user.actualUser);
  const genderUser = user?.gender as EGenderUser;

  const modelesStore = useSelector((state: RootState) => state.modeles.modelesHighlights);
  const findModeleStore = modelesStore?.find((itemState: IGenderHighlight) => itemState?.gender === genderUser);



  return (

    <ImageContentRightHome>

      <WarningApproveText>
        Veja o vídeo abaixo e use de exemplo para acelerar o processo de verificação.
      </WarningApproveText>



      <WrapperItemContent>

        <AntiFakeItemSplitText>
          Vídeo exemplo
        </AntiFakeItemSplitText>


        <AntiFakeRightVideoView
          controls
          autoPlay={true}
          playsInline
          muted>
          <source src={findModeleStore?.videoAntiFake} />
          Seu navegador não suporta a reprodução de vídeos HTML5.
        </AntiFakeRightVideoView>

      </WrapperItemContent>

    </ImageContentRightHome>

  );
};



export default RightAntiFakeMediaInfo;
