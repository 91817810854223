import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useNavigate,
} from 'react-router-dom';

import {
  SwipeableDrawer,
  makeStyles,
} from '@material-ui/core';

import ButtonLanguages from '../../../components/Buttons/ButtonLanguages';

import {
  RootState,
} from '../../../redux';

import {
  APP_DRAWER_OFF_ACTION,
} from '../../../redux/reducers/app-modals.store';

import {
  USER_LOGOUT_STATE,
} from '../../../redux/reducers/user.store';

import {
  Colors,
} from '../../../shared/constants';

import {
  EUserType,
} from '../../../shared/enums';

import {
  TranslateConfig,
} from '../../../shared/translations';

import NameRoutes from '../../names';

import {
  MenuContainer,
  CpLinksLeftContent,
  HeaderActionsButton,
  HeaderActionsText,
  BeModelActionsText,
  HeaderActionsContent,
} from './styled';



export interface IProps {
  open?: boolean;

  onOpen?: any;
  onClose?: any;
}



const useStyles = makeStyles(() => ({
  backdrop: {
    backgroundColor: Colors.menuOverlay,
  },
}));



const DrawerUserOff: React.FC<IProps> = (props: any) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const classes = useStyles();
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;

  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);



  function onHomePress() {
    history(NameRoutes.HomeScreen);
  };


  function onClearAndReplaceHome() {
    history(NameRoutes.MyPriveScreen);
  };



  return (

    <SwipeableDrawer
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      anchor={'right'}
      disableSwipeToOpen={true}
      open={props.open}
      onOpen={props.onOpen}
      onClose={props.onClose}>

      <MenuContainer>

        <CpLinksLeftContent>

          <HeaderActionsButton
            onClick={() => {
              onHomePress();
              dispatch({ type: APP_DRAWER_OFF_ACTION, payload: false });
            }}>
            <HeaderActionsText>
              {translate(TranslateConfig.HOME)}
            </HeaderActionsText>
          </HeaderActionsButton>



          {availableUser && (
            <HeaderActionsButton
              onClick={() => {
                onClearAndReplaceHome();
                dispatch({ type: APP_DRAWER_OFF_ACTION, payload: false });
              }}>
              <HeaderActionsText>
                {translate(TranslateConfig.MY_PRIVE)}
              </HeaderActionsText>
            </HeaderActionsButton>
          )}



          {projectParams?.showRent && (
            <HeaderActionsButton
              onClick={() => {
                history(NameRoutes.RentHomeScreen);
                dispatch({ type: APP_DRAWER_OFF_ACTION, payload: false });
              }}>
              <HeaderActionsText>
                {translate(TranslateConfig.ESCORTS)}
              </HeaderActionsText>
            </HeaderActionsButton>
          )}



          {projectParams?.showLive && (
            <HeaderActionsButton
              onClick={() => {
                history(NameRoutes.LiveHomeScreen);
                dispatch({ type: APP_DRAWER_OFF_ACTION, payload: false });
              }}>
              <HeaderActionsText>
                {translate(TranslateConfig.LIVE_ROOMS_ONDEMAND)}
              </HeaderActionsText>
            </HeaderActionsButton>
          )}



          {projectParams?.canAccess && availableUser && (
            <HeaderActionsButton
              onClick={() => {
                history(NameRoutes.SuggestionScreen);
                dispatch({ type: APP_DRAWER_OFF_ACTION, payload: false });
              }}>
              <HeaderActionsText>
                {translate(TranslateConfig.SUGGESTIONS)}
              </HeaderActionsText>
            </HeaderActionsButton>
          )}



          {projectParams?.showShop && (
            <HeaderActionsButton
              onClick={() => {
                // history(NameRoutes.ShopScreen);
                dispatch({ type: APP_DRAWER_OFF_ACTION, payload: false });
              }}>
              <HeaderActionsText>
                {translate(TranslateConfig.SHOP_APP)}
              </HeaderActionsText>
            </HeaderActionsButton>
          )}

        </CpLinksLeftContent>



        <HeaderActionsContent>

          {projectParams?.canAccess && !availableUser && (
            <HeaderActionsButton
              onClick={() => {
                history(NameRoutes.AccessScreen);
                dispatch({ type: APP_DRAWER_OFF_ACTION, payload: false });
              }}>
              <HeaderActionsText>
                {translate(TranslateConfig.ACCESS)}
              </HeaderActionsText>
            </HeaderActionsButton>
          )}



          {projectParams?.canAccess && !availableUser && (
            <HeaderActionsButton
              onClick={() => {
                history(NameRoutes.AccessScreen);
                dispatch({ type: APP_DRAWER_OFF_ACTION, payload: false });
              }}>
              <HeaderActionsText>
                {translate(TranslateConfig.REGISTER_FREE)}
              </HeaderActionsText>
            </HeaderActionsButton>
          )}



          {user?.type !== EUserType.MODELE && (
            <HeaderActionsButton
              onClick={() => {
                history(NameRoutes.BeModeleScreen);
                dispatch({ type: APP_DRAWER_OFF_ACTION, payload: false });
              }}>
              <BeModelActionsText>
                {translate(TranslateConfig.BE_MODELE)}
              </BeModelActionsText>
            </HeaderActionsButton>
          )}



          {(projectParams?.showRent || projectParams?.showLive) && (
            <HeaderActionsButton
              onClick={() => {
                history(NameRoutes.SettingsAppScreen);
                dispatch({ type: APP_DRAWER_OFF_ACTION, payload: false });
              }}>
              <HeaderActionsText>
                {availableUser
                  ? translate(TranslateConfig.SETTINGS)
                  : translate(TranslateConfig.MORE_OPTIONS)
                }
              </HeaderActionsText>
            </HeaderActionsButton>
          )}



          {projectParams?.canAccess && availableUser && (
            <HeaderActionsButton
              onClick={() => {
                dispatch({ type: USER_LOGOUT_STATE, payload: true });
                dispatch({ type: APP_DRAWER_OFF_ACTION, payload: false });
              }}>
              <HeaderActionsText>
                {translate(TranslateConfig.EXIT)}
              </HeaderActionsText>
            </HeaderActionsButton>
          )}



          <ButtonLanguages />

        </HeaderActionsContent>

      </MenuContainer>

    </SwipeableDrawer>

  );
};



export default DrawerUserOff;
