import React from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  USER_FACLUB_MODAL,
} from '../../../redux/reducers/app-modals.store';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import {
  FanclubContainer,
  FanclubModeleBackground,
  FanclubInformationsContainer,
  FanclubHeaderSpotligh,
  FanclubAvatarView,
  FanclubHeaderAvatar,
  FanclubHeaderTitle,
  FanclubHeaderDescription,
  FanclubTotalMediaView,
  FanclubTotalText,
  MediaTotalCount,
  MediaTotalText,
  FanclubDivisor,
  FanclubActionView,
  FanclubPrice,
  FanclubSubscribeButton,
  FanclubSubscribeText,
  FanclubSubscribeImage,
  FanclubDisabledContainer,
} from './styled';



export interface IProps {
  disabled?: boolean;
  alias?: string;
}



const CardFanClubAdd: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();


  const imageModele = Images.avatarUserImage;


  function renderFanClubImage() {
    return (

      <FanclubHeaderSpotligh>
        <FanclubAvatarView>
          <FanclubHeaderAvatar
            alt={''}
            src={imageModele}
          />
        </FanclubAvatarView>
      </FanclubHeaderSpotligh>

    );
  }



  return (

    <FanclubContainer
      onClick={() => {
        if (!props.disabled) {
          dispatch({ type: USER_FACLUB_MODAL, payload: true });
        }
        else {
          console.log('FAVORITAR');
        }
      }}>


      <FanclubModeleBackground
        src={imageModele}
      />


      {!props.disabled && (
        <FanclubInformationsContainer>

          {renderFanClubImage()}



          <FanclubHeaderTitle>
            Assine meu FanClub
          </FanclubHeaderTitle>



          <FanclubHeaderDescription>
            Acesse todo o Conteúdo de Fotos e Videos para Fãs. Em constante atualização.
          </FanclubHeaderDescription>



          <FanclubTotalMediaView>
            <FanclubTotalText>
              <MediaTotalCount> 15 <i></i> </MediaTotalCount>
              <MediaTotalText>Fotos</MediaTotalText>
            </FanclubTotalText>

            <FanclubDivisor />

            <FanclubTotalText>
              <MediaTotalCount> 5 <i></i> </MediaTotalCount>
              <MediaTotalText>Vídeos</MediaTotalText>
            </FanclubTotalText>
          </FanclubTotalMediaView>



          <FanclubActionView>

            <FanclubPrice>
              Clique e saiba mais
            </FanclubPrice>


            <FanclubSubscribeButton
              backgroundColor={Colors.fanClubLevel}>
              <FanclubSubscribeText>
                Assinar
              </FanclubSubscribeText>

              <FanclubSubscribeImage
                alt={''}
                src={Images.fanclubLogo}
              />
            </FanclubSubscribeButton>

          </FanclubActionView>

        </FanclubInformationsContainer>
      )}



      {props.disabled && (
        <FanclubDisabledContainer>

          {renderFanClubImage()}



          <FanclubHeaderTitle>
            FanClub desabilitado
          </FanclubHeaderTitle>



          <FanclubHeaderDescription>
            {props.alias} não tem fan club, mas você pode adicionar seu perfil a sua lista de favoritos e acompanhar suas atualizações.
          </FanclubHeaderDescription>

        </FanclubDisabledContainer>
      )}

    </FanclubContainer>

  );
};



export default CardFanClubAdd;
