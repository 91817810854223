import styled from 'styled-components';

import Colors from '../../shared/constants/Colors';



export const AccessLoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  background-color: ${Colors.appBackground};
`;


export const LogoContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 15px;
  margin-bottom: 10px;
`;

export const LogoInfoAppContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const LogoName = styled.h1`
  margin-bottom: 5px;
  color: ${Colors.white};
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0.5px;
`;

export const LogoApplication = styled.h2`
  color: ${Colors.gray};
  font-size: 14px;
  font-weight: 100;
  line-height: 17px;
  letter-spacing: 0.5px;
`;

export const LogoImageView = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 65px;
  max-height: 65px;
  width: 65px;
  max-width: 65px;
  margin-left: 20px;
  padding-top: 9px;
  padding-left: 11px;
  padding-right: 9px;
  padding-bottom: 9px;
  background-color: ${Colors.black};
  border-radius: 5px;
  
  shadow-color: #808080;
  shadow-offset: 0 4px;
  shadow-opacity: 0.8;
  shadow-radius: 10px;

  elevation: 8;
`;

export const LogoImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;



export const InputsView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 15px;
  margin-bottom: 30px;
`;

export const InputMarginView = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 7px;
`;


export const ButtonsMarginView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  gap: 15px;
`;


export const FooterView = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LoginWarningText = styled.p`
  margin-top: 3px;
  margin-bottom: 3px;
  color: gray;
  font-size: 12px;
  font-weight: 100;
`;

export const FooterTermsText = styled.span`
  color: white;
  font-weight: bold;
  cursor: pointer;
`;
