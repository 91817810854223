const PricesArray = [
  {
    package: 1,
    promotion: false,
    credit: 10,
    price: '9,99',
    priceEU: '',
    priceDOL: '',
  },
  {
    package: 2,
    promotion: false,
    credit: 30,
    price: '29,99',
    priceEU: '9,99',
    priceDOL: '9,99',
  },
  {
    package: 3,
    promotion: true,
    credit: 50,
    price: '49,99',
    priceEU: '29,99',
    priceDOL: '29,99',
  },
  {
    package: 4,
    promotion: false,
    credit: 100,
    price: '99,99',
    priceEU: '',
    priceDOL: '',
  },
  {
    package: 5,
    promotion: false,
    credit: 150,
    price: '149,99',
    priceEU: '49,99',
    priceDOL: '49,99',
  },
  {
    package: 6,
    promotion: false,
    credit: 300,
    price: '299,99',
    priceEU: '99,99',
    priceDOL: '99,99',
  },
];



export default PricesArray;
