import styled from 'styled-components';



export const SearchTogglesContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
`;
