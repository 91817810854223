import styled from 'styled-components';



export const CardPackagesOptionsItem = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 180px;
  margin: 5px;
  padding: 8px;
  background-color: #4e504f;
  border-radius: 3px;
`;

export const CardPackagesOptionsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2px;
  padding: 10px;
  width: 100%;
  background-color: #fff;
  position: relative;
  transition: all .15s ease-out;
  border-radius: 3px;
`;


export const CardPackagesAddText = styled.span`
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 18px;
`;

export const CardPackagesPriceText = styled.span`
  font-family: Nunito,sans-serif;
  font-size: 70px;
  font-weight: 700;
  letter-spacing: -2px;
  line-height: 60px;
`;

export const CardPackagesSaldoText = styled.span`
  margin-top: 5px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 18px;
`;


export const CardInfoPriceView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2px;
`;

export const CardPackagesPriceView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const CardPackagesToText = styled.span`
  margin-bottom: 5px;
  color: #fff;
  font-size: 12px;
`;

export const CardPackagesValueText = styled.span`
  color: #fff;
  font-size: 24px;
`;



export const CardPackagesTryView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 142px;
  left: -6px;
  width: calc(100% + 12px);
  height: 22px;
  padding: 2px;
  background-color: #e9bd51;
  color: #000;
  font-size: 12px;
  line-height: 1.25;
  clip-path: polygon(
    100% 0,97% 50%,
    100% 100%,
    0 100%,
    3% 50%,
    0 0
  );
  z-index: 1;
`;


export const CardPackagesTryText = styled.span`
  color: #000;
  font-weight: 700;
`;
