import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  RootState,
} from '../../../redux';

import {
  USER_LANGUAGE_MODAL,
} from '../../../redux/reducers/localities.store';

import {
  languagesOption,
} from '../../../shared/arrays/App/lang-country.array';

import {
  Images,
} from '../../../shared/constants';

import {
  HeaderLangsContent,
  HeaderLangsImage,
  HeaderLangsText,
} from './styled';



export interface IProps {
  onPress?: any;
}



const ButtonLanguages: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const className: any = { ...props };


  const languageSelected = useSelector((state: RootState) => state.localities.language);
  const languageFiltered = languagesOption?.find((item) => item?.languageIso === languageSelected);



  return (

    <HeaderLangsContent
      className={className?.className}
      onClick={() => {
        dispatch({ type: USER_LANGUAGE_MODAL, payload: true });

        if (props.onPress) {
          props.onPress();
        }
      }}>

      <HeaderLangsImage
        alt={translate(languageFiltered?.label || '') || '-'}
        src={languageFiltered?.flagImage || Images.avatarUserImage}
      />

      <HeaderLangsText>
        {translate(languageFiltered?.label || '') || '-'}
      </HeaderLangsText>

    </HeaderLangsContent>

  );
};



export default ButtonLanguages;
