import styled from 'styled-components';



export const HallFameSctionTitleView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;

export const HallFameSctionArrowImage = styled.img`
  width: 100%;
  max-width: 73px;
`;

export const HallFameSctionArrowTitle = styled.h4`
  font-family: parkside;
  font-weight: 700;
  line-height: 1.5;
  font-style: normal;
  font-size: calc(55px - .1vw);
  color: #ffda70;
  margin-top: 30px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 30px;
`;
