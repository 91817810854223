import {
  IInputPlaceSelect,
} from '../../../apis/interfaces/App/input-select-place.interface';



const CitiesFullBraAl: Array<IInputPlaceSelect> = [
  {
    id: 1,
    label: 'Arapiraca',
    value: 'ARAPIRACA',
    capital: false,
  },
  {
    id: 2,
    label: 'Coruripe',
    value: 'CORURIPE',
    capital: false,
  },
  {
    id: 3,
    label: 'Delmiro Gouveia',
    value: 'DELMIRO_GOUVEIA',
    capital: false,
  },
  {
    id: 4,
    label: 'Maceió',
    value: 'MACEIO',
    capital: true,
  },
  {
    id: 5,
    label: 'Marechal Deodoro',
    value: 'MARECHAL_DEODORO',
    capital: false,
  },
  {
    id: 6,
    label: 'Palmeira dos Índios',
    value: 'PALMEIRA_DOS_INDIOS',
    capital: false,
  },
  {
    id: 7,
    label: 'Penedo',
    value: 'PENEDO',
    capital: false,
  },
  {
    id: 8,
    label: 'Piranhas',
    value: 'PIRANHAS',
    capital: false,
  },
  {
    id: 9,
    label: 'Rio Largo',
    value: 'RIO_LARGO',
    capital: false,
  },
  {
    id: 10,
    label: 'Santana do Ipanema',
    value: 'SANTANA_DO_IPANEMA',
    capital: false,
  },
  {
    id: 11,
    label: 'São Miguel dos Campos',
    value: 'SAO_MIGUEL_DOS_CAMPOS',
    capital: false,
  },
  {
    id: 12,
    label: 'União dos Palmares',
    value: 'UNIAO_DOS_PALMARES',
    capital: false,
  },
];



export default CitiesFullBraAl;
