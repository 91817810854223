import {
  IInputSelect,
} from '../../../apis/interfaces/App/input-select.interface';

import {
  ERoomType,
} from '../../enums';



export const appLiveRoomStatusArray: Array<IInputSelect> = [
  {
    id: 1,
    label: 'Sala aberta',
    text: 'Entrar na sala',
    value: ERoomType.BASIC,
  },
  {
    id: 1,
    label: 'Ausente',
    text: 'Ver perfil',
    value: ERoomType.BREAK,
  },
  {
    id: 1,
    label: 'Em leilão',
    text: 'Ver',
    value: ERoomType.PARTY_AUCTION,
  },
  {
    id: 1,
    label: 'Perguntando',
    text: 'Ver',
    value: ERoomType.PARTY_QUESTION,
  },
  {
    id: 1,
    label: 'Preparando grupo',
    text: 'Ver',
    value: ERoomType.PREGROUP,
  },
  {
    id: 1,
    label: 'Festa',
    text: 'Entrar na sala',
    value: ERoomType.PARTY,
  },
  {
    id: 1,
    label: 'Me apresentando',
    text: 'Entrar na sala',
    value: ERoomType.GROUP,
  },
  {
    id: 1,
    label: 'Chat Privado',
    text: 'Espiar chat',
    value: ERoomType.PRIVATE,
  },
  {
    id: 1,
    label: 'Chat Exclusivo',
    text: 'Ver perfil',
    value: ERoomType.EXCLUSIVE,
  },
  {
    id: 1,
    label: 'Desconectado',
    text: 'Ver perfil',
    value: ERoomType.OFFLINE,
  },
  {
    id: 1,
    label: '',
    text: '',
    value: ERoomType.PROMO,
  },
];
