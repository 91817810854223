import SecretioWS from '../index';



export const GetModeleToReview = async (idModele: string) => {
  const url = `/app/user-do/review/${idModele}`;

  const response = await SecretioWS.get(url);
  return response.data;
};



export const DoReviewModele = async (modeleUseralias: string, payload: any) => {
  const url = `/app/user-do/review/${modeleUseralias}`;

  const response = await SecretioWS.post(url, payload);
  return response.data;
};
