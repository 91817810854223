import React from 'react';

// import { Container } from './styles';



const TabCheckData: React.FC = () => {
  return (

    <div>


      <p>
        [] Fazer essa recarga a cada mês
      </p>


      <button>
        Voltar
      </button>

      <button>
        Finalizar
      </button>

    </div>

  );
};



export default TabCheckData;
