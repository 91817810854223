import React from 'react';

import {
  Colors,
  Images,
} from '../../../../shared/constants';

import {
  validateString,
} from '../../../../shared/utils/string.utils';

import {
  IconVerified,
  IconVerifiedPromoter,
} from '../../../Icons';

import {
  CardProfileOnlineContainer,
  ProfileOnlineButton,
  ProfileOnlineUserOnlineImage,
  ProfileOnlineUserImage,
  ProfileOnlineContent,
  ProfileOnlineInfoViewContent,
  ProfileOnlineInfoContent,
  ProfileOnlineUsernameText,
  ProfileOnlineUserIcon,
  ProfileOnlineNameText,
  ProfileOnlineItemControls,
} from './styled';



export interface IProps {
  username?: any;
  name?: any;
  image?: any;
  verified?: boolean;
  promoter?: boolean;
  onPress?: any;
}



const CardSuggestionMedium: React.FC<IProps> = (props) => {
  return (

    <CardProfileOnlineContainer>

      <ProfileOnlineButton
        onClick={props.onPress}>

        <ProfileOnlineUserOnlineImage>
          <ProfileOnlineUserImage
            alt={'story'}
            src={validateString(props.image) || Images.avatarUserImage}
          />
        </ProfileOnlineUserOnlineImage>



        <ProfileOnlineContent>

          <ProfileOnlineInfoViewContent>

            <ProfileOnlineInfoContent>

              <ProfileOnlineUsernameText>
                @{props.username}
              </ProfileOnlineUsernameText>


              {props.verified && (
                <ProfileOnlineUserIcon>
                  <IconVerified
                    size={14}
                    color={Colors.accent}
                  />
                </ProfileOnlineUserIcon>
              )}


              {props.promoter && (
                <ProfileOnlineUserIcon>
                  <IconVerifiedPromoter
                    size={14}
                    color={Colors.white}
                  />
                </ProfileOnlineUserIcon>
              )}

            </ProfileOnlineInfoContent>



            <ProfileOnlineNameText>
              {props.name}
            </ProfileOnlineNameText>

          </ProfileOnlineInfoViewContent>



          <ProfileOnlineItemControls>



          </ProfileOnlineItemControls>

        </ProfileOnlineContent>

      </ProfileOnlineButton>

    </CardProfileOnlineContainer>

  );
};



export default CardSuggestionMedium;
