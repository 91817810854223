import React, { useCallback, useEffect, useRef, useState } from 'react';
import ButtonAction from '../../../../../components/Buttons/ButtonAction';
import { Switch } from '../../../../../components/Composh/web';
import { IconDelete, IconEdit } from '../../../../../components/Icons';
import { Colors } from '../../../../../shared/constants';
import {
  ItemCardRoot,
  BodyBox,
  ContentBox,
  ActionsBox,
  InputEditableBox,
  DeleteBox,
} from './styled';

type EditableProps = {
  placeholder?: string;
  onChange?: (e: React.ChangeEvent) => void;
  value?: string;
  name?: string;
};

export const Editable = ({
  placeholder,
  value: valueProp,
  name,
  onChange,
}: EditableProps) => {
  const [modeEditable, setModeEditable] = useState(false);
  const [value, setValue] = useState(valueProp || '');
  const handleChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      setValue(ev.target.value);
      onChange && onChange(ev);
    },
    [value],
  );

  return (
    <InputEditableBox>
      {modeEditable ? (
        <input
          placeholder={placeholder}
          autoFocus
          type='text'
          value={value}
          name={name}
          onChange={handleChange}
          onBlur={() => setModeEditable(false)}
        />
      ) : (
        <>
          <button onClick={() => setModeEditable(true)}>
            {value || placeholder}
          </button>
          <IconEdit
            color={Colors.primaryDark}
          />
        </>
      )}
    </InputEditableBox>
  );
};

export type ItemCardProps = {
  children?: React.ReactNode;
  centerAxisX?: boolean;
  centerAxisY?: boolean;
  onRequestRemove?: (ev?: React.MouseEvent) => void;
  onEnable?: (isEnabled: boolean) => void;
  isEnabled?: boolean;
};

const ItemCard = ({
  children,
  centerAxisX,
  centerAxisY,
  isEnabled,
  onRequestRemove,
  onEnable,
}: ItemCardProps) => {
  const [showDelete, setShowDelete] = useState(false);
  const [enabled, setEnabled] = useState(isEnabled);
  const handleSwitch = useCallback(() => setEnabled(!enabled), [enabled]);
  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) onEnable && onEnable(enabled);
    mounted.current = true;
  }, [enabled, mounted.current]);

  return (
    <ItemCardRoot>
      <BodyBox>
        <div />
        <div>
          <ContentBox centerAxisX={centerAxisX} centerAxisY={centerAxisY}>
            {children}
          </ContentBox>
          <ActionsBox>
            <Switch
              selected={isEnabled}
              onPress={handleSwitch}
            />

            {/* <Switch
              colorScheme='success'
              onSwitch={onEnable}
              isEnabled={isEnabled}
            /> */}

            <a
              onClick={() => setShowDelete(!showDelete)}
            >
              <IconDelete
                color={Colors.danger}
              />
            </a>
          </ActionsBox>
        </div>
      </BodyBox>
      {showDelete && (
        <DeleteBox>
          <p style={{ color: '#000' }}>
            ¿Eliminar esto para siempre?
          </p>
          <div>
            <ButtonAction
              title={'Cancelar'}
              onPress={() => setShowDelete(false)}
            />

            <ButtonAction
              title={'Eliminar'}
              onPress={onRequestRemove}
            />
          </div>
        </DeleteBox>
      )}
    </ItemCardRoot>
  );
};

export default ItemCard;
