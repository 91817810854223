import React from 'react';

import {
  Colors,
} from '../../../shared/constants';

import {
  Input,
} from '../../Composh/web';

import {
  InputSelectButton,
  InputSelectText,
} from './styled';



export interface IProps {
  disabled?: any;
  noShadow?: any;
  backgroundColor?: any;
  // iconLeft?: any;
  // iconRight?: any;
  labelText?: any;
  // placeholderColor?: any;
  // inputTextColor?: any;
  placeholderText?: any;
  helpText?: any;
  // countLimit?: any;
  borderColor?: any;
  value?: any;
  onChange?: any;
  // onBlur?: any;

  name?: string;
  array?: Array<any>;
  onPress?: any;
}



const InputSelect: React.FC<IProps> = (props: IProps) => {
  function renderTextSelect() {
    if (!props.disabled && !props.placeholderText) {
      return 'Toque para selecionar';
    }

    if (!props.placeholderText) {
      return 'Sem dados';
    }

    if (props.placeholderText) {
      return props.placeholderText;
    }

    return '-';
  }



  return (

    <Input.View
      {...props}

      disabled={props.disabled}
      noShadow={props.noShadow}

      backgroundColor={props.backgroundColor || Colors.transparent}
      borderColor={props.borderColor}

      labelText={props.labelText}
      labelColor={Colors.inputLabel}

      helpText={props.helpText}
      helpColor={Colors.danger}

      countLimit={'Clique para abrir a lista'}
      countColor={Colors.inputCount}>

      <InputSelectButton
        onClick={() => {
          if (!props.disabled && props.onPress) {
            props.onPress();
          }
        }}>

        <InputSelectText
          color={!props.placeholderText ? Colors.attention : Colors.white}>
          {renderTextSelect()}
        </InputSelectText>

      </InputSelectButton>

    </Input.View>

  );
};



export default InputSelect;
