import styled from 'styled-components';

import {
  Content,
} from '../../components/Composh/web';

import {
  Colors,
  Sizes,
} from '../../shared/constants';



export interface IProps {
  width?: number;
  maxWidth?: number;
  height?: number;
}



export const CategoryChooseContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 310px;
  height: 450px;
  background-color: ${Colors.appBackground};
  border-radius: ${Sizes.cardRadius}px;
`;



export const FilteredInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 16px;
`;

export const BankClearButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;



export const CategoryFlat = styled(Content)`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
`;



export const CategoryButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  min-height: 50px;
`;

export const CategoryText = styled.p`
  color: ${Colors.white};
  font-size: 16px;
`;
