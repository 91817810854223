import {
  createAction,
  createReducer,
} from '@reduxjs/toolkit';

import {
  arrayModelesHighlights,
} from '../../shared/arrays/Gender/app-gender-choose..array';



const INITIAL_STATE = {
  modelesHighlights: arrayModelesHighlights,
  modelesRentServices: [],
};



// Action Types

export const MODELES_HIGHLIGHTS = '@user/MODELES_HIGHLIGHTS';
export const MODELES_RENT_SERVICES = '@user/MODELES_RENT_SERVICES';


// Action Creators

export const modelesHighlightsAction = createAction<object>(MODELES_HIGHLIGHTS);
export const modelesRentServicesAction = createAction<object>(MODELES_RENT_SERVICES);


// Handlers

const modelesHighlightsReducer = (state = INITIAL_STATE, action: any) => ({
  ...state,
  modelesHighlights: action.payload,
});


const modelesRentServicesReducer = (state = INITIAL_STATE, action: any) => ({
  ...state,
  modelesRentServices: action.payload,
});


// Reducers

export default createReducer(INITIAL_STATE, {
  [modelesHighlightsAction.type]: modelesHighlightsReducer,
  [modelesRentServicesAction.type]: modelesRentServicesReducer,
});
