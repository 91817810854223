import React from 'react';

import {
  FotyContainerButton,
} from './styled';


export interface IProps {
  href?: any;
  title?: any;
}

const ButtonYearly: React.FC<IProps> = (props) => {
  return (

    <FotyContainerButton href={props.href}>
      {props.title}
    </FotyContainerButton>

  );
};



export default ButtonYearly;
