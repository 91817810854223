import styled from 'styled-components';

import {
  ListFlat,
  Scrolls,
} from '../../components/Composh/web';

import {
  ContentPadder,
} from '../../components/Contents/ContentPadder';

import {
  GetPlatform,
} from '../../config/plataform.config';

import {
  Colors,
  Sizes,
} from '../../shared/constants';

import {
  PlatformDevices,
} from '../../shared/utils/platforms.utils';

import {
  ScrollbarHideStyle,
  ScrollbarHorizontalStyle,
} from '../../styles/scrolls.layout';



export const NotificationBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const NotificationBadgeContainer = styled.p`
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  background-color: ${Colors.danger};
  color: ${Colors.white};
  font-size: 15px;
  text-align: center;
  border-radius: 20px;
`;



export const NotificationsControlsContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  @media ${PlatformDevices.minLaptop} {
    flex-direction: row;
    align-items: center;
  }
`;

export const ContentWrapperRowFull = styled(ContentPadder)`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;

  @media ${PlatformDevices.maxLaptop} {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const ContentCenterFlex = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;



export const MediaChooseTypeHeader = styled(Scrolls.Horizontal)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;

  ${GetPlatform() === 'WEB' ? ScrollbarHorizontalStyle : ScrollbarHideStyle}
`;



export const NotificationReadButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  min-width: 210px;
  height: ${Sizes.buttonHeight}px;
  padding-right: 10px;

  @media ${PlatformDevices.maxLaptop} {
    width: 100%;
    margin-top: 10px;
    padding-right: 0;
    justify-content: center;
  }
`;

export const NotificationReadIcon = styled.div`
  margin-right: 10px;
`;

export const NotificationReadText = styled.p`
  color: ${Colors.white};
  font-size: 16px;
`;



export const NotificationFlatListStyled = styled(ListFlat).attrs({
  style: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: Sizes.paddingFlatList,
    paddingRight: Sizes.paddingFlatList,
  },
  contentContainerStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
})`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
