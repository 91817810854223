import SecretioWS from '../index';



export const GetModeleReportsData = async (useralias: any) => {
  const url = `/app/modele/reports/${useralias}`;

  const response = await SecretioWS.get(url);
  return response.data;
};



export const SaveContactModeleClick = async (payload: any) => {
  const url = '/app/modele/reports-contact';

  const response = await SecretioWS.post(url, payload);
  return response.data;
};
