import React from 'react';

import {
  Fotysectiontitle,
} from './styled';



export interface IProps {
  title: string;
  position?: any;
}

const TitleYearly: React.FC<IProps> = (props) => {
  return (

    <Fotysectiontitle
      position={props.position}>
      {props.title}
    </Fotysectiontitle>

  );
};

TitleYearly.defaultProps = {
  position: 'left',
};

export default TitleYearly;
