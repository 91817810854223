import styled,
{
  keyframes,
} from 'styled-components';

import Color from 'color';

import {
  Colors,
} from '../../shared/constants';



export interface IProps {
  size?: number;
  color?: string;
}



const Blinker = keyframes`
  from {
    opacity: 1;
  }
  
  to {
    opacity: 0;
  }
`;



export const BadgeLiveOnline = styled.p`
  display: flex;
  align-self: flex-start;
  padding: 4px 12px;
  background-color: ${(props: IProps) => Color(props.color).darken(0.3).toString()};
  color: ${Colors.white};
  font-size: 11px;
  border-radius: 50px;
  animation: ${Blinker} 1.3s cubic-bezier(.5, 0, 1, 1) infinite alternate;  
  cursor: default;
`;



export const NotificationBadgeContainer = styled.p`
  padding-top: 4px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 4px;
  background-color: ${(props: IProps) => Color(props.color).darken(0.3).toString()};
  color: ${Colors.white};
  font-size: 13px;
  text-align: center;
  border-radius: 20px;
  animation: ${Blinker} 1.3s cubic-bezier(.5, 0, 1, 1) infinite alternate;  
`;



export const FooterLogoImage = styled.img`
  width: ${(props: IProps) => props.size || 30}px;
  height: ${(props: IProps) => props.size || 30}px;
  object-fit: cover;
`;
