import styled from 'styled-components';

import {
  ContentPadder,
} from '../../../../../components/Contents/ContentPadder';

import {
  Colors,
  Sizes,
} from '../../../../../shared/constants';

import {
  PlatformDevices,
} from '../../../../../shared/utils/platforms.utils';

import {
  WrapperItemContent,
} from '../../../../../styles/styled.layout';



export const ContentRowWrapperAuto = styled(ContentPadder)`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  max-width: ${Sizes.contentMaxWidth}px;

  @media ${PlatformDevices.maxTabletM} {
    flex-direction: column;
    align-items: center;
  }
`;

export const ContentCenterFlex = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

export const ContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: ${Sizes.contentLeftWidth}px;
  margin-right: ${Sizes.contentMargin}px;

  @media ${PlatformDevices.maxTabletM} {
    width: 100%;
    max-width: 300px;
    margin-right: 0;
    margin-bottom: 5px;
  }
`;

export const WrapperItemCommentContent = styled(WrapperItemContent)`
  align-items: center;
  width: 100%;
`;

export const CommentsCountContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CommentsCountIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`;

export const CommentsCountText = styled.p`
  color: ${Colors.white};
  font-size: 48px;
  font-weight: 500;
`;

export const CommentsCountReviewText = styled.p`
  margin-top: 3px;
  color: ${Colors.white};
  font-size: 17px;
  font-weight: 400;
`;

export const CommentsButtonContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  padding-left: 5px;
  padding-right: 5px;
`;


export const CommentListFlat = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;
