import {
  IInputPlaceSelect,
} from '../../../apis/interfaces/App/input-select-place.interface';



const CitiesFullItaVen: Array<IInputPlaceSelect> = [
  {
    id: 1,
    label: 'Milão',
    value: 'MILAN',
  },
];



export default CitiesFullItaVen;
