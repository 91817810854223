export const hairColorArray = [
  { id: 1, label: 'Castanhos', value: 'BROWN' },
  // { id: 2, label: 'Castanhos claro', value: 'LIGHT_BROWN' },
  // { id: 3, label: 'Castanhos escuro', value: 'DARK_BROWN' },
  { id: 4, label: 'Loiro', value: 'BLONDE' },
  // { id: 5, label: 'Loiro claro', value: 'LIGHT_BLONDE' },
  // { id: 6, label: 'Loiro escuro', value: 'DARK_BLONDE' },
  { id: 7, label: 'Preto', value: 'BLACK' },
  { id: 8, label: 'Ruivo', value: 'GINGER' },
  { id: 9, label: 'Vermelho', value: 'RED' },
  // { id: 10, label: 'Cinza', value: 'GRAY' },
  { id: 11, label: 'Branco', value: 'WHITE' },
  { id: 12, label: 'Colorido', value: 'COLORFUL' },
];
