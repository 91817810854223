import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
} from 'react-redux';

import {
  IViewMediaModal,
} from '../../../apis/interfaces/Medias/view-media-modal.interface';

import CardAdsSuggestion from '../../../components/Ads';
import BadgeApp from '../../../components/Badges/BadgeApp';
import CardPhoto from '../../../components/Cards/CardMedia/CardPhoto';
import ChipApp from '../../../components/Chip';

import {
  Content,
} from '../../../components/Composh/web';

import EmptyContent from '../../../components/Empty';
import FooterApp from '../../../components/Footers/FooterApp';

import {
  IconFavoriteOn,
  IconMediaPhoto,
  IconMoney,
} from '../../../components/Icons';

import LoadingScreen from '../../../components/Loadings/LoadingScreen';

import {
  RootState,
} from '../../../redux';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import {
  TranslateConfig,
} from '../../../shared/translations';

import {
  MaskNotificationsBadge,
} from '../../../shared/utils/masks.utils';

import {
  ContentWrapperRowFull,
  ContentCenterFlex,
  MediaHeaderPadderTop,
  MediaPhotoGridView,
} from './styled';



export interface IProps {
  favoriteArray?: Array<any>;
  loading?: boolean;
  onShowMedia?: any;
}



const TabPhotosBookmark: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);


  const photosArray = props.favoriteArray || [];
  const loading = props.loading;

  const [filteredPhotos, setFilteredPhotos] = useState<any[]>([]);
  const [typeImage, setTypeImage] = useState<any | ''>('');


  const photosOptions = [
    {
      visible: true,
      title: translate(TranslateConfig.ALL),
      typeImage: '',
      icon: (
        <IconMediaPhoto
          color={Colors.white}
          size={18}
        />
      ),
      badgeCount: photosArray?.length,
    },
    {
      visible: true,
      title: translate(TranslateConfig.FAVORITEDS),
      typeImage: 'FAVORITED',
      icon: (
        <IconFavoriteOn
          color={Colors.white}
          size={18}
        />
      ),
      badgeCount: photosArray?.filter((media: any) => media?.collection === 'FAVORITED')?.length,
    },
    {
      visible: projectParams?.showFanClub,
      title: translate(TranslateConfig.BUYED),
      typeImage: 'BUYED',
      icon: (
        <IconMoney
          color={Colors.white}
          size={18}
        />
      ),
      badgeCount: photosArray?.filter((media: any) => media?.collection === 'BUYED')?.length,
    },
  ];



  function renderChipChooses(item: any, index: number) {
    if (!item?.visible) {
      return;
    }


    return (

      <ChipApp
        key={index}
        title={item?.title}
        selected={typeImage === item?.typeImage}
        selectedBackgroundColor={Colors.primaryDark}
        thumb={item?.icon}
        icon={
          <BadgeApp
            text={MaskNotificationsBadge(item?.badgeCount)}
            backgroundColor={Colors.black}
            color={Colors.white}
          />
        }
        onPress={() => {
          setTypeImage(item?.typeImage);
        }}
      />

    );
  }


  function renderItem(item: any, index: number) {
    return (

      <CardPhoto
        key={index}
        image={item?.picture}
        visibility={item?.visibility}
        canBuy={item?.canBuy}
        onPress={() => {
          const itemToSender: IViewMediaModal = {
            indexSelected: index,
            type: 'PHOTOS',
          };

          if (props.onShowMedia) {
            props.onShowMedia(itemToSender);
          }
        }}
      />

    );
  }


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.white}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noFavoritesPhoto}
        title={translate(TranslateConfig.EMPTY_FAVORITES_PHOTOS)}
      />

    );
  }



  useEffect(() => {
    if (typeImage === '') {
      setFilteredPhotos(photosArray);
    }
    else {
      const filtered = photosArray.filter((media) => media?.collection === typeImage);
      setFilteredPhotos(filtered);
    }
  }, [typeImage, photosArray]);



  return (

    <Content>

      <ContentWrapperRowFull>

        <ContentCenterFlex>

          <MediaHeaderPadderTop>
            {photosOptions.map((chip, index) => (
              renderChipChooses(chip, index)
            ))}
          </MediaHeaderPadderTop>



          <MediaPhotoGridView
            noScroll
            loading={loading}
            data={filteredPhotos}
            renderItem={renderItem}
            listLoadingComponent={renderLoading()}
            listEmptyComponent={listEmptyComponent()}
          />

        </ContentCenterFlex>



        <CardAdsSuggestion />

      </ContentWrapperRowFull>



      <FooterApp />

    </Content>

  );
};



export default TabPhotosBookmark;
