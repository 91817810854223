import React from 'react';

import {
  useSelector,
} from 'react-redux';

import Color from 'color';

import {
  RootState,
} from '../../../../redux';

import {
  IconMapPin,
} from '../../../Icons';

import {
  LocationItemContainer,
  LocationItemText,
  LocationItemIcon,
} from './styled';



export interface IProps {
  title: any;
  actualCity?: boolean;
  borderWidth?: any;
  onPress?: any;
}



const CardLocationCity: React.FC<IProps> = (props: any) => {
  const genderColorChoose = useSelector((state: RootState) => state.choose.genderColorApp);
  const accentGender = props.color || Color(genderColorChoose).lighten(0.1).toString();



  return (

    <LocationItemContainer
      borderBottomWidth={props.borderWidth}
      onClick={props.onPress}>

      <LocationItemText>
        {props.title}
      </LocationItemText>


      {props.actualCity && (
        <LocationItemIcon>
          <IconMapPin
            color={accentGender}
            size={25}
          />
        </LocationItemIcon>
      )}

    </LocationItemContainer>

  );
};



export default CardLocationCity;
