import {
  Values,
} from '../../constants';

import Yup from '../base-validation.yup';



const CharactersProfileValidationSchema = Yup.object().shape({
  // {/* De 18 a 80 */}
  age: Yup.string(),
});



export default CharactersProfileValidationSchema;
