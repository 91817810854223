import {
  Colors,
} from '../constants';

import EResponseStatus from '../enums/Response/response-status.enum';

import {
  TranslateConfig,
} from '../translations';



export function treatErrorAndReturnMessage(error: any): string {
  if (!error) {
    return '';
  }

  const message = (error?.status === EResponseStatus.ERROR || error?.status === EResponseStatus.WARNING)
    && error?.messages?.length > 0 && error?.messages[0]
    && typeof error?.messages[0] === 'string'
    ? error?.messages[0]
    : TranslateConfig.ERROR_HAD;

  return message;
}



export function treatErrorAndReturnColor(error: any): string {
  if (!error) {
    return Colors.danger;
  }

  if (error?.status === EResponseStatus.SUCCESS) {
    return Colors.accept;
  }

  if (error?.status === EResponseStatus.WARNING) {
    return Colors.attention;
  }

  if (error?.status === EResponseStatus.ERROR) {
    return Colors.danger;
  }

  return Colors.danger;
}
