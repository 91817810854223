import styled from 'styled-components';

import {
  ContentPadder,
} from '../../../../../components/Contents/ContentPadder';

import {
  Colors,
  Sizes,
} from '../../../../../shared/constants';

import {
  PlatformDevices,
} from '../../../../../shared/utils/platforms.utils';



export interface IProps {
  disabled?: boolean;
}



export const CharContentCenterRowFlex = styled(ContentPadder)`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  max-width: ${Sizes.contentMaxWidth}px;

  @media ${PlatformDevices.maxTabletM} {
    flex-direction: column;
  }
`;

export const CharContentLeft = styled.div`
  display: flex;
  flex-direction: column;

  @media ${PlatformDevices.maxTabletM} {
    margin-bottom: ${Sizes.contentMargin}px;
    width: 100%;
  }

  @media ${PlatformDevices.minTabletM} {
    margin-right: ${Sizes.contentMargin}px;
    width: ${Sizes.contentLeftWidth}px;
  }
`;



export const CharWrapperFirstContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 40px;
`;

export const CharWrapperSecondContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;



export const CharPornExclusiveView = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const CharPornExclusiveButton = styled.a`
  display: flex;
  flex-direction: column;
  height: 80px;
  width: 80px;
  padding: 5px;
`;

export const CharImagePornExclusive = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;



export const CharCenterFlex = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

export const CharServiceContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const FeedTipsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const FeedTipsIconHeader = styled.a`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  opacity: ${(props: IProps) => props.disabled ? Sizes.opacityOff : Sizes.opacityOn};
`;

export const CharUserDescription = styled.p`
  margin-top: 5px;
  color: ${Colors.white};
  font-size: 15px;
  font-style: italic;
  font-weight: 400;
  line-height: 1.5;
`;


export const CharItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
