import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';

import ButtonPanelHover from '../ButtonPanelHover';



export const PanelLocationsContainer = styled(ButtonPanelHover)`
  min-width: 230px;

  @media ${PlatformDevices.maxLaptop} {
    min-width: 160px;
  }
`;



export const PanelLocationsView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const PanelLocationsIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;

export const PanelLocationsTitle = styled.h3`
  color: ${Colors.inputLabel};
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
`;


export const PanelLocationsText = styled.p`
  margin-top: 3px;
  color: ${Colors.inputLabel};
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
`;
