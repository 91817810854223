import styled from 'styled-components';

import {
  ListFlat,
  Scrolls,
} from '../../../../../components/Composh/web';

import {
  GetPlatform,
} from '../../../../../config/plataform.config';

import {
  Sizes,
} from '../../../../../shared/constants';

import {
  PlatformDevices,
} from '../../../../../shared/utils/platforms.utils';

import {
  ScrollbarHideStyle,
  ScrollbarHorizontalStyle,
} from '../../../../../styles/scrolls.layout';



export const ContentWrapperRowFull = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  margin-bottom: 20px;
`;


export const ContentCenterFlex = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;


export const MediaHeaderPadderTop = styled(Scrolls.Horizontal)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;

  @media ${PlatformDevices.maxTablet} {
    padding-left: 15px;
    padding-right: 15px;
  }

  ${GetPlatform() === 'WEB' ? ScrollbarHorizontalStyle : ScrollbarHideStyle}
`;


export const MediaPhotoGridView = styled(ListFlat).attrs({
  style: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: Sizes.paddingContainer,
    paddingLeft: Sizes.paddingContainer,
    paddingRight: Sizes.paddingContainer,
  },
  contentContainerStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
})`
  display: flex;
  flex-direction: column;
  flex: 1;
`;



export const MediaTypeContent = styled.div`
  display: flex;
  flex-direction: column;
  width: ${Sizes.contentRightHomeWidth}px;
  margin-left: ${Sizes.contentMargin}px;
  padding-top: 20px;
  padding-left: 5px;
  padding-right: ${Sizes.paddingContainer}px;
  padding-bottom: ${Sizes.paddingContainer}px;

  @media ${PlatformDevices.maxLaptopL} {
    display: none;
  }
`;
