import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';



export const TouchableEditUser = styled.a`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  margin-bottom: 5px;
`;

export const SettingsUserView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SettingImagesView = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const UserSettingsImage = styled.img`
  height: 100px;
  width: 100px;
  margin-bottom: 10px;
  border-radius: ${Sizes.cardRadius}px;
  object-fit: cover;
  object-position: top center;
`;

export const UserVerifiedContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 3px;
  padding-right: 7px;
`;

export const TextUser = styled.p`
  color: white;
  font-size: 20px;
`;

export const IconVerifiedStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2px;
  margin-left: 8px;
`;


export const TextName = styled.p`
  color: white;
  font-size: 13px;
  font-weight: 300;
`;

export const TextEditProfile = styled.p`
  margin-top: 8px;
  color: ${Colors.primary};
  font-size: 12px;
`;



export const DetailsSectionView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
`;



export const FooterText = styled.p`
  margin-top: 10px;
  font-size: 12px;
  text-align: center;
  color: ${Colors.textApp};
`;
