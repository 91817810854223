import React from 'react';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  ToggleStyled,
} from './styled';



export interface IProps {
  noToggle?: boolean;
  selected?: boolean;
  iconContent?: any;
  value?: any;
  displayValue?: string;
  borderTintColor?: string;
  backgroundTintColor?: string;
  textTintColor?: string;
}



const ToggleApp: React.FC<IProps> = (props: IProps) => {
  return (

    <ToggleStyled
      noToggle={props.noToggle}
      selected={props.selected}
      iconContent={props.iconContent}
      value={props.value}
      height={Sizes.TOOGLE_HEIGHT}
      displayValue={props.displayValue}
      borderWidth={1.5}
      borderRadius={Sizes.cardRadius}
      borderTintColor={props.borderTintColor || Colors.toolbarLine}
      backgroundTintColor={props.backgroundTintColor || Colors.cardBackground}
      textTintColor={props.textTintColor || Colors.white}
    />

  );
};


export default ToggleApp;
