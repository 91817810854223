import React from 'react';

import {
  ResizingContainer,
  ResizingHandleButton,
  ResizingTriangleWrap,
  ResizingTriangleLeft,
  ResizingTriangleRight,
} from './styled';



const RoomResize: React.FC = () => {
  return (

    <ResizingContainer>
      <ResizingHandleButton>

        <ResizingTriangleWrap>

          <ResizingTriangleLeft />

          <ResizingTriangleRight />

        </ResizingTriangleWrap>

      </ResizingHandleButton>
    </ResizingContainer>

  );
};



export default RoomResize;
