import React from 'react';

import {
  renderCardFlag,
} from '../../../config/payments.config';

import {
  CardFlagImage,
} from './styled';



export interface IProps {
  type?: string;
  size?: number;
  borderRadius?: number;
}



const CreditFlag: React.FC<IProps> = (props) => {
  return (

    <CardFlagImage
      alt={'flag'}
      src={renderCardFlag(props.type || 'unknown')}
      size={props.size}
      borderRadius={props.borderRadius}
    />

  );
};



export default CreditFlag;
