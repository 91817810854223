import React from 'react';

import {
  Images,
} from '../../../shared/constants';

import {
  ERoomType,
} from '../../../shared/enums';

import PartyTermometer from '../PartyTermometer';
import PartyTips from '../PartyTips';
import TermometerGroup from '../TermometerGroup';

import {
  CurtainButtonContainer,
  CurtainButtonText,
  CurtainMessages,
  CurtainMessageSecond,
  CurtainMessageThird,
  CurtainPrimaryButton,
  CurtainPrimaryTextButton,
  ModeleCurtainImage,
  ModeleScreenName,
  ModeleVideoPlayer,
} from './styled';



export interface IProps {
  status?: string;

  onRedirect?: any;
  onPress?: any;
}



const ModelePlayer: React.FC<IProps> = (props) => {
  return (

    <ModeleVideoPlayer>

      {props.status === ERoomType.PARTY_QUESTION && (
        <PartyTips
          type={ERoomType.PARTY_QUESTION}
        />
      )}



      {props.status === ERoomType.PARTY_AUCTION && (
        <PartyTips
          type={ERoomType.PARTY_AUCTION}
        />
      )}



      {props.status === ERoomType.PARTY && (
        <PartyTermometer />
      )}



      {props.status === ERoomType.PREGROUP && (
        <TermometerGroup />
      )}



      {/* Video to Show */}
      {(props.status === ERoomType.BASIC || props.status === ERoomType.PREGROUP || props.status === ERoomType.PARTY) && (
        <video
          className="hide curtains-video-clip"
          id="modelCurtainMedia"
          width="100%"
          height="auto"
          poster=""
        // webkit-playsinline="webkit-playsinline"
        />
      )}



      {/* Break */}
      {props.status === ERoomType.BREAK && (
        <CurtainMessages>

          <ModeleCurtainImage
            alt={''}
            src={Images.avatarUserImage}
          />


          <ModeleScreenName>
            {'Kyle Maddison'.toUpperCase()}
          </ModeleScreenName>

          <CurtainMessageSecond>
            {'Está atualmente'.toUpperCase()}
          </CurtainMessageSecond>

          <CurtainMessageThird>
            {'Dando uma pausa'.toUpperCase()}
          </CurtainMessageThird>


          <CurtainButtonContainer>

            <CurtainButtonText>
              Se quiser, você pode conferir outros modeles incríveis
            </CurtainButtonText>

            <CurtainPrimaryButton
              onClick={props.onRedirect}>
              <CurtainPrimaryTextButton>
                Ver mais modeles
              </CurtainPrimaryTextButton>
            </CurtainPrimaryButton>

          </CurtainButtonContainer>

        </CurtainMessages>
      )}



      {/* Group - Fazer algo por quantidade de creditos */}
      {props.status === ERoomType.GROUP && (
        <CurtainMessages>

          <ModeleCurtainImage
            alt={''}
            src={Images.avatarUserImage}
          />


          <ModeleScreenName>
            {'Kyle Maddison'.toUpperCase()}
          </ModeleScreenName>

          <CurtainMessageSecond>
            {'Está atualmente em um'.toUpperCase()}
          </CurtainMessageSecond>

          <CurtainMessageThird>
            {'Show em Grupo'.toUpperCase()}
          </CurtainMessageThird>


          <CurtainButtonContainer>

            <CurtainButtonText>
              Faça uma promessa no botão abaixo para participar deste show!
            </CurtainButtonText>

          </CurtainButtonContainer>

        </CurtainMessages>
      )}



      {/* Private */}
      {props.status === ERoomType.PRIVATE && (
        <CurtainMessages>

          <ModeleCurtainImage
            alt={''}
            src={Images.avatarUserImage}
          />


          <ModeleScreenName>
            {'Kyle Maddison'.toUpperCase()}
          </ModeleScreenName>

          <CurtainMessageSecond>
            {'Está atualmente em um'.toUpperCase()}
          </CurtainMessageSecond>

          <CurtainMessageThird>
            {'Show Privado'.toUpperCase()}
          </CurtainMessageThird>


          <CurtainButtonContainer>

            <CurtainButtonText>
              Seja Premium e assista a este show gratuitamente
            </CurtainButtonText>

            <CurtainPrimaryButton
              onClick={props.onRedirect}>
              <CurtainPrimaryTextButton>
                Seja Premium
              </CurtainPrimaryTextButton>
            </CurtainPrimaryButton>

          </CurtainButtonContainer>

        </CurtainMessages>
      )}



      {/* Exclusive - Membros premium */}
      {props.status === ERoomType.EXCLUSIVE && (
        <CurtainMessages>

          <ModeleCurtainImage
            alt={''}
            src={Images.avatarUserImage}
          />


          <ModeleScreenName>
            {'Kyle Maddison'.toUpperCase()}
          </ModeleScreenName>

          <CurtainMessageSecond>
            {'Está atualmente em um'.toUpperCase()}
          </CurtainMessageSecond>

          <CurtainMessageThird>
            {'Show Exclusivo'.toUpperCase()}
          </CurtainMessageThird>


          <CurtainButtonContainer>

            <CurtainButtonText>
              Este é um show privado exclusivo. Por favor selecione outro modelo, ou espere terminar.
            </CurtainButtonText>

            <CurtainPrimaryButton
              onClick={props.onRedirect}>
              <CurtainPrimaryTextButton>
                Ver mais modelos
              </CurtainPrimaryTextButton>
            </CurtainPrimaryButton>

          </CurtainButtonContainer>

        </CurtainMessages>
      )}



      {/* Offline */}
      {props.status === ERoomType.OFFLINE && (
        <CurtainMessages>

          <ModeleCurtainImage
            alt={''}
            src={Images.avatarUserImage}
          />


          <ModeleScreenName>
            {'Kyle Maddison'.toUpperCase()}
          </ModeleScreenName>

          <CurtainMessageSecond>
            {'Está atualmente'.toUpperCase()}
          </CurtainMessageSecond>

          <CurtainMessageThird>
            {'Offline'.toUpperCase()}
          </CurtainMessageThird>


          <CurtainButtonContainer>

            <CurtainButtonText>
              Você ainda pode conversar ao vivo com centenas de outros modeles.
            </CurtainButtonText>

            <CurtainPrimaryButton
              onClick={props.onRedirect}>
              <CurtainPrimaryTextButton>
                Ver mais modeles
              </CurtainPrimaryTextButton>
            </CurtainPrimaryButton>

          </CurtainButtonContainer>

        </CurtainMessages>
      )}

    </ModeleVideoPlayer>

  );
};



export default ModelePlayer;
