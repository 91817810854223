import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useNavigate,
} from 'react-router-dom';

import {
  IconModele,
} from '../../../components/Icons';

import NameRoutes from '../../../navigation/names';

import {
  Colors,
} from '../../../shared/constants';

import {
  TranslateConfig,
} from '../../../shared/translations';

import {
  HeroDetailsContainer,
  HeroDetailsButtonsView,
  HeroDetailsButton,
  HeroDetailsTextButton,
  HeroDetailsHeader,
  HeroDetailsTitle,
  HeroDetailsDescription,
  HeroDetailsFooterDescription,
  HeroDetailsFooterKnowMore,
  HeroDetailsInfoContainer,
} from './styled';



const HomeDetailsSection: React.FC = () => {
  const history = useNavigate();
  const { t: translate } = useTranslation();



  function renderButtonsOptions(icon: any, title: string, onPress: any) {
    return (

      <HeroDetailsButton
        onClick={onPress}>

        {icon}


        <HeroDetailsTextButton>
          {title}
        </HeroDetailsTextButton>

      </HeroDetailsButton>

    );
  }



  return (

    <HeroDetailsContainer>

      <HeroDetailsButtonsView>

        {renderButtonsOptions(
          <IconModele
            color={Colors.white}
            size={24}
          />,
          translate(TranslateConfig.ACTION_SEE_ALL_MODELES),
          () => {
            history(NameRoutes.AllModeleScreen);
          },
        )}


        {renderButtonsOptions(
          <IconModele
            color={Colors.white}
            size={24}
          />,
          translate(TranslateConfig.ACTION_SEE_ALL_SPONSORS),
          () => {
            history(NameRoutes.AllSponsorsScreen);
          },
        )}

      </HeroDetailsButtonsView>



      <HeroDetailsInfoContainer>

        <HeroDetailsHeader>

          <HeroDetailsTitle>
            {translate(TranslateConfig.LANDING_SECTION_TITLE_1)}
          </HeroDetailsTitle>

          <HeroDetailsDescription>
            {translate(TranslateConfig.LANDING_SECTION_DESCRIPTION_1)}
          </HeroDetailsDescription>

        </HeroDetailsHeader>



        {/* TODO: Fazer itens de destaque */}



        <HeroDetailsFooterDescription>
          {translate(TranslateConfig.LANDING_ADULT_JOB)}
          {' '}
          {false && (
            // TODO
            <HeroDetailsFooterKnowMore
              onClick={() => {
                //
              }}>
              {translate(TranslateConfig.ACTION_LEARN_MORE)}
            </HeroDetailsFooterKnowMore>
          )}
          {/* . */}
        </HeroDetailsFooterDescription>

      </HeroDetailsInfoContainer>

    </HeroDetailsContainer>

  );
};



export default HomeDetailsSection;
