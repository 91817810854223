import {
  IInputSelect,
} from '../../../apis/interfaces/App/input-select.interface';



export const weightsArray: Array<IInputSelect> = [
  { id: 1, label: '45 kg - 100 lbs', value: 'WEIGHT_45KG' },
  { id: 2, label: '46 kg - 101.4 lbs', value: 'WEIGHT_46KG' },
  { id: 3, label: '47 kg - 105 lbs', value: 'WEIGHT_47KG' },
  { id: 4, label: '48 kg - 105.8 lbs', value: 'WEIGHT_48KG' },
  { id: 5, label: '49 kg - 110 lbs', value: 'WEIGHT_49KG' },
  { id: 6, label: '50 kg - 110.2 lbs', value: 'WEIGHT_50KG' },
  { id: 7, label: '51 kg - 112.4 lbs', value: 'WEIGHT_51KG' },
  { id: 8, label: '52 kg - 115 lbs', value: 'WEIGHT_52KG' },
  { id: 9, label: '53 kg - 116.8 lbs', value: 'WEIGHT_53KG' },
  { id: 10, label: '54 kg - 120 lbs', value: 'WEIGHT_54KG' },
  { id: 11, label: '55 kg - 121.3 lbs', value: 'WEIGHT_55KG' },
  { id: 12, label: '56 kg - 125 lbs', value: 'WEIGHT_56KG' },
  { id: 13, label: '57 kg - 125.7 lbs', value: 'WEIGHT_57KG' },
  { id: 14, label: '58 kg - 130 lbs', value: 'WEIGHT_58KG' },
  { id: 15, label: '59 kg - 130.1 lbs', value: 'WEIGHT_59KG' },
  { id: 16, label: '60 kg - 132.3 lbs', value: 'WEIGHT_60KG' },
  { id: 17, label: '61 kg - 135 lbs', value: 'WEIGHT_61KG' },
  { id: 18, label: '62 kg - 136.7 lbs', value: 'WEIGHT_62KG' },
  { id: 19, label: '63 kg - 140 lbs', value: 'WEIGHT_63KG' },
  { id: 20, label: '64 kg - 141.1 lbs', value: 'WEIGHT_64KG' },
  { id: 21, label: '65 kg - 145 lbs', value: 'WEIGHT_65KG' },
  { id: 22, label: '66 kg - 145.5 lbs', value: 'WEIGHT_66KG' },
  { id: 23, label: '67 kg - 147.7 lbs', value: 'WEIGHT_67KG' },
  { id: 24, label: '68 kg - 150 lbs', value: 'WEIGHT_68KG' },
  { id: 25, label: '69 kg - 151.3 lbs', value: 'WEIGHT_69KG' },
  { id: 26, label: '70 kg - 155 lbs', value: 'WEIGHT_70KG' },
  { id: 27, label: '71 kg - 156.5 lbs', value: 'WEIGHT_71KG' },
  { id: 28, label: '72 kg - 160 lbs', value: 'WEIGHT_72KG' },
  { id: 29, label: '73 kg - 161.6 lbs', value: 'WEIGHT_73KG' },
  { id: 30, label: '74 kg - 165 lbs', value: 'WEIGHT_74KG' },
  { id: 31, label: '75 kg - 165.3 lbs', value: 'WEIGHT_75KG' },
  { id: 32, label: '76 kg - 167.6 lbs', value: 'WEIGHT_76KG' },
  { id: 33, label: '77 kg - 170 lbs', value: 'WEIGHT_77KG' },
  { id: 34, label: '78 kg - 172 lbs', value: 'WEIGHT_78KG' },
  { id: 35, label: '79 kg - 175 lbs', value: 'WEIGHT_79KG' },
  { id: 36, label: '80 kg - 176.4 lbs', value: 'WEIGHT_80KG' },
  { id: 37, label: '81 kg - 180 lbs', value: 'WEIGHT_81KG' },
  { id: 38, label: '82 kg - 180.8 lbs', value: 'WEIGHT_82KG' },
  { id: 39, label: '83 kg - 185 lbs', value: 'WEIGHT_83KG' },
  { id: 40, label: '84 kg - 185.2 lbs', value: 'WEIGHT_84KG' },
  { id: 41, label: '85 kg - 187.3 lbs', value: 'WEIGHT_85KG' },
  { id: 42, label: '86 kg - 190 lbs', value: 'WEIGHT_86KG' },
  { id: 43, label: '87 kg - 191.8 lbs', value: 'WEIGHT_87KG' },
  { id: 44, label: '88 kg - 195 lbs', value: 'WEIGHT_88KG' },
  { id: 45, label: '89 kg - 196.2 lbs', value: 'WEIGHT_89KG' },
  { id: 46, label: '90 kg - 200 lbs', value: 'WEIGHT_90KG' },
  { id: 47, label: '91 kg - 200.6 lbs', value: 'WEIGHT_91KG' },
  { id: 48, label: '92 kg - 202.8 lbs', value: 'WEIGHT_92KG' },
  { id: 49, label: '93 kg - 205 lbs', value: 'WEIGHT_93KG' },
  { id: 50, label: '94 kg - 206 lbs', value: 'WEIGHT_94KG' },
  { id: 51, label: '95 kg - 210 lbs', value: 'WEIGHT_95KG' },
  { id: 52, label: '96 kg - 211.6 lbs', value: 'WEIGHT_96KG' },
  { id: 53, label: '97 kg - 214 lbs', value: 'WEIGHT_97KG' },
  { id: 54, label: '98 kg - 215 lbs', value: 'WEIGHT_98KG' },
  { id: 55, label: '99 kg - 218 lbs', value: 'WEIGHT_99KG' },
  { id: 56, label: '100 kg - 220 lbs', value: 'WEIGHT_100KG' },
  { id: 57, label: '101 kg - 223.2 lbs', value: 'WEIGHT_101KG' },
  { id: 58, label: '102 kg - 224.9 lbs', value: 'WEIGHT_102KG' },
  { id: 59, label: '103 kg - 225 lbs', value: 'WEIGHT_103KG' },
  { id: 60, label: '104 kg - 229 lbs', value: 'WEIGHT_104KG' },
  { id: 61, label: '105 kg - 230 lbs', value: 'WEIGHT_105KG' },
  { id: 62, label: '106 kg - 233 lbs', value: 'WEIGHT_106KG' },
  { id: 63, label: '107 kg - 235 lbs', value: 'WEIGHT_107KG' },
  { id: 64, label: '108 kg - 238 lbs', value: 'WEIGHT_108KG' },
  { id: 65, label: '109 kg - 240 lbs', value: 'WEIGHT_109KG' },
  { id: 66, label: '110 kg - 242.5 lbs', value: 'WEIGHT_110KG' },
  { id: 67, label: '111 kg - 244.7 lbs', value: 'WEIGHT_111KG' },
  { id: 68, label: '112 kg - 245 lbs', value: 'WEIGHT_112KG' },
  { id: 69, label: '113 kg - 248 lbs', value: 'WEIGHT_113KG' },
  { id: 70, label: '114 kg - 250 lbs', value: 'WEIGHT_114KG' },
  { id: 71, label: '115 kg - 253.5 lbs', value: 'WEIGHT_115KG' },
  { id: 72, label: '116 kg - 256.4 lbs', value: 'WEIGHT_116KG' },
  { id: 73, label: '117 kg - 258 lbs', value: 'WEIGHT_117KG' },
  { id: 74, label: '118 kg - 260 lbs', value: 'WEIGHT_118KG' },
  { id: 75, label: '119 kg - 262 lbs', value: 'WEIGHT_119KG' },
  { id: 76, label: '120 kg - 265 lbs', value: 'WEIGHT_120KG' },
  { id: 77, label: '121 kg - 267 lbs', value: 'WEIGHT_121KG' },
  { id: 78, label: '122 kg - 270 lbs', value: 'WEIGHT_122KG' },
  { id: 79, label: '123 kg - 272.2 lbs', value: 'WEIGHT_123KG' },
  { id: 80, label: '124 kg - 273.2 lbs', value: 'WEIGHT_124KG' },
  { id: 81, label: '125 kg - 275 lbs', value: 'WEIGHT_125KG' },
];
