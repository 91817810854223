import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';

import {
  HomeScrollbarVertival,
} from '../../../styles/scrolls.layout';



export const HomeRightContent = styled.div`
  display: flex;
  flex-direction: column;

  @media ${PlatformDevices.maxLaptopL} {
    display: none;
  }

  @media ${PlatformDevices.minLaptopL} {
    ${HomeScrollbarVertival};

    width: ${Sizes.contentRightHomeWidth}px;
    min-width: ${Sizes.contentRightHomeWidth}px;
    margin-left: ${Sizes.contentMargin}px;
    padding-top: ${Sizes.paddingContainer + 7}px;
    padding-right: ${Sizes.paddingContainer}px;
    padding-bottom: ${Sizes.paddingContainer + 7}px;
  }
`;

export const SuggestionContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const SuggestionHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 5px;
`;


export const RightBoostLogoImage = styled.img`
  margin-top: 2px;
  margin-bottom: 5px;
  height: 20px;
  object-fit: contain;
`;


export const SuggestionViewButton = styled.a`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const SuggestionTitle = styled.h3`
  margin: 0;
  color: ${Colors.white};
  font-size: 18px;
  font-weight: 500;
`;

export const SuggestionText = styled.p`
  margin-top: 5px;
  color: ${Colors.textDescription};
  font-size: 12.5px;
`;

export const SuggestionButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  margin: 0 5px;
  transition: color .15s;
`;



export const SuggestionList = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 15px;
`;



export const OnlineContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;

export const OnlineList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  align-items: center;
  position: relative;
  margin: 15px 0 20px;
  gap: 12px 10px;
`;



export const AdsEmptyView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 5px;
`;
