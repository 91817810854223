import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';



export const DdiItemButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 60px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
`;

export const DdiModalImage = styled.img`
  width: 55px;
  height: 55px;
  object-fit: contain;
`;

export const DdiInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 15px;
  padding-bottom: 2px;
`;

export const DdiInfoText = styled.p`
  margin-bottom: 2px;
  color: ${Colors.white};
  font-size: 15px;
`;

export const DdiNameText = styled.p`
  color: ${Colors.textApp};
  font-size: 12px;
`;
