import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useNavigate,
} from 'react-router-dom';

import {
  GetUserCreditCard,
} from '../../../../apis/endpoints/app-global-payment.endpoints';

import {
  SubscribePro,
} from '../../../../apis/endpoints/users-plan.endpoint';

import AlertMessage from '../../../../components/Alerts';
import CardPro from '../../../../components/Cards/CardPro';

import {
  Container,
  Content,
  Screen,
  Title,
} from '../../../../components/Composh/web';

import {
  ContentPadder,
} from '../../../../components/Contents/ContentPadder';

import {
  ButtonBack,
} from '../../../../components/Controls';

import EmptyContent from '../../../../components/Empty';
import FooterChoose from '../../../../components/Footers/FooterChoose';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../../components/Loadings/LoadingScreen';
import PaymentChoose from '../../../../components/Payments/PaymentChoose';

import {
  openSite,
} from '../../../../config/linking.config';

import PaymentsModal from '../../../../modals/Payments';

import {
  RootState,
} from '../../../../redux';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../../redux/reducers/app-modals.store';

import {
  USER_SET,
} from '../../../../redux/reducers/user.store';

import {
  ArrayColors,
} from '../../../../shared/arrays';

import {
  Colors,
  Images,
  Values,
} from '../../../../shared/constants';

import {
  EPlansUser,
} from '../../../../shared/enums';

import Vars from '../../../../shared/environments/variables';

import {
  validateString,
} from '../../../../shared/utils/string.utils';

import {
  ButtonText,
} from '../../../../styles/styled.layout';

import {
  PayChooseProContent,
} from './styled';



const UpgradeModeleShowScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();



  const user = useSelector((state: RootState) => state.user.actualUser);
  const userIdSearch = user?.useralias;
  const planSelected = user?.plan?.planCurrent || null;


  const [modalQuestion, setModalQuestion] = useState(false);
  const [modalOk, setModalOk] = useState(false);

  const [selectionPro, setSelectionPro] = useState<EPlansUser | null>(null);

  const [paymentModal, setPaymentModal] = useState(false);
  const [mainCreditCard, setMainCreditCard] = useState<any>(null);
  const [cardItem, setCardItem] = useState<any>(null);

  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function getMainCreditCard() {
    setLoading(true);

    try {
      const response = await GetUserCreditCard();
      const responseData = response?.data;

      if (!response || responseData?.length === 0) {
        // FIXME - Transformar em modal
        alert(
          'Você ainda não adicionou um cartão a sua conta',
        );
        // history(NameRoutes);
        return;
      }

      setCardItem(responseData);

      const mainCard = responseData?.filter((e: any) => e?.main === true)[0];
      setMainCreditCard(mainCard);
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : 'Houve um erro';
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);

      setTimeout(() => {
        setMounted(true);
      }, Values.mountTime);
    }
  }


  async function payProFunction() {
    const payload = {
      card: mainCreditCard?.id,
      proType: selectionPro,
    };

    try {
      setLoading(true);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await SubscribePro(userIdSearch, payload);

      if (response) {
        dispatch({ type: USER_SET, payload: response?.data });
        setModalOk(true);
      }
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : 'Houve um erro';
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function openCreditCardScreen() {
    openSite(Vars().globalAccountCreditCard);
  }


  async function makeProCompany() {
    setModalQuestion(false);
    await payProFunction();
  }


  function listEmptyComponent() {
    if (loading) {
      return null;
    }

    return (

      <EmptyContent
        image={Images.noImage}
        title={'Não há um cartão de crédito principal cadastrado!'}
      />

    );
  }


  function renderTextAction() {
    if (loading) {
      return 'Carregando';
    }

    if (selectionPro === EPlansUser.NONE) {
      return 'Escolher';
    }

    if (!mainCreditCard) {
      return 'Adicionar método de pagamento';
    }

    return 'Assinar';
  }


  function renderButtonColor() {
    if (loading) {
      return ArrayColors.arrayDisabled;
    }

    if (!mainCreditCard) {
      return ArrayColors.arrayAction;
    }

    if (!selectionPro) {
      return ArrayColors.arrayDisabled;
    }

    return ArrayColors.arrayAction;
  }


  function renderPressAction() {
    if (!mainCreditCard) {
      openCreditCardScreen();
      return;
    }

    setModalQuestion(true);
  }


  useEffect(() => {
    getMainCreditCard();
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <Container>

        <HeaderScreen
          disableGender
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={
            <ButtonBack
              color={Colors.white}
              size={27}
            />
          }
          centerContent={
            <Title>
              Upgrade profissional
            </Title>
          }
        />



        {!mounted && (
          <LoadingScreen
            type={'SCREEN'}
            color={Colors.primary}
          />
        )}



        {mounted && !mainCreditCard && (
          listEmptyComponent()
        )}



        {mounted && mainCreditCard && (
          <Content>
            <ContentPadder>

              <PayChooseProContent>
                <PaymentChoose
                  cardBrand={mainCreditCard?.card_brand}
                  last4={mainCreditCard?.last4 ? validateString(mainCreditCard?.last4) : null}
                  onPress={() => {
                    setPaymentModal(true);
                  }}
                />
              </PayChooseProContent>


              <CardPro
                choosed={selectionPro === EPlansUser.NONE}
                active={planSelected === EPlansUser.NONE}
                title={'Normal'}
                price={'Grátis'}
                description={''} // FIXME
                onPress={() => {
                  setSelectionPro(EPlansUser.NONE);
                }}
              />


              <CardPro
                choosed={selectionPro === EPlansUser.BRONZE}
                active={planSelected === EPlansUser.BRONZE}
                title={'Bronze'}
                price={'R$ 9,90'}
                description={''} // FIXME
                onPress={() => {
                  setSelectionPro(EPlansUser.BRONZE);
                }}
              />


              <CardPro
                choosed={selectionPro === EPlansUser.SILVER}
                active={planSelected === EPlansUser.SILVER}
                title={'Prata'}
                price={'R$ 19,90'}
                description={''} // FIXME
                onPress={() => {
                  setSelectionPro(EPlansUser.SILVER);
                }}
              />


              <CardPro
                choosed={selectionPro === EPlansUser.GOLD}
                active={planSelected === EPlansUser.GOLD}
                title={'Ouro'}
                price={'R$ 29,90'}
                description={''} // FIXME
                onPress={() => {
                  setSelectionPro(EPlansUser.GOLD);
                }}
              />

            </ContentPadder>
          </Content>
        )}



        <FooterChoose
          hideCancelButton

          okDisabled={loading}
          okColor={renderButtonColor()}
          okPress={() => {
            renderPressAction();
          }}
          okLabel={
            <ButtonText
              color={Colors.white}>
              {renderTextAction()}
            </ButtonText>
          }
        />

      </Container>



      <AlertMessage
        visible={modalQuestion}
        title={'Gostaria de escolher esse plano?'}
        description={'Você pode mudar a qualquer momento'}
        cancelText={'Não'}
        cancelPress={() => {
          setModalQuestion(false);
        }}
        okText={'Sim'}
        okPress={() => {
          makeProCompany();
        }}
      />



      <PaymentsModal
        visible={paymentModal}
        data={cardItem}
        onCancelPress={() => {
          setPaymentModal(false);
        }}
        onPress={(card: any) => {
          setMainCreditCard(card);
          setPaymentModal(false);
        }}
      />



      {/* <ModalOk */}
      <AlertMessage
        visible={modalOk}
        // backgroundColor={Colors.cardBackground}
        // image={Images.proSignatureDone}
        title={'Bem-vindo ao PRO!'}
        description={'Agora você faz parte do time PRO! Acompanhe sua assinatura direto pela aba "Gerenciar plano"'}
        // buttonText={'Fechar'}
        // onPress={() => {
        //   history.replace(NameRoutes.ProInfoScreen);
        // }}
        okText={'Fechar'}
        okPress={() => {
          history(-1);
        }}
      />

    </Screen>

  );
};



export default UpgradeModeleShowScreen;
