const environment = {
  production: true,
  environment: 'PROD',
  title: 'Production Environment Heading',
  apiAccountGlobalUrl: 'https://api.noitada.app',
  apiUrl: 'https://api.cartoir.com',
  firebaseWebConfig: {
    apiKey: 'AIzaSyAoGJmt5EG0XmtEPxvZl-BMHamgQFoSs4g',
    authDomain: 'secretio-app-prod.firebaseapp.com',
    projectId: 'secretio-app-prod',
    storageBucket: 'secretio-app-prod.appspot.com',
    messagingSenderId: '146979033529',
    appId: '1:146979033529:web:9ef9c76ff8bd497046e668',
    measurementId: 'G-Q6XWRJP7NB',
  },
};



export default environment;
