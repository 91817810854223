import React from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  RootState,
} from '../../../redux';

import {
  MODAL_LOCATION_APP_ACTION,
  USER_COIN_MODAL,
  USER_GENDER_MODAL,
} from '../../../redux/reducers/app-modals.store';

import {
  Colors,
} from '../../../shared/constants';

import EDrawerMenu from '../../../shared/enums/App/drawer-menu.enum';
import ButtonGender from '../../Buttons/ButtonGender';

import {
  AppBar,
  Toolbar,
  SubToolbar,
} from '../../Composh/web';

import {
  ButtonMoney,
  ButtonPlace,
} from '../../Controls';

import HeaderFixed from '../HeaderFixed';

import {
  ToolbarViewContainer,
  HeaderEndContainer,
} from './styled';



export interface IProps {
  screenKey?: EDrawerMenu;

  transparent?: boolean;
  absolute?: boolean;

  hideHeaderFixed?: boolean;

  leftIcon?: any;
  rightIcon?: any;
  centerContent?: any;

  backgroundColor?: string;
  subBackgroundColor?: string;
  borderBottomColor?: string;

  noSubToolbarPadding?: boolean;

  middleToolbar?: any;
  children?: any;

  disableGender?: boolean;
  disableBalance?: boolean;
  showBalance?: boolean;
  showPlace?: boolean;
}



const HeaderScreen: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();


  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);

  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;



  function renderPlaceUser() {
    if (props.showPlace) {
      return (

        <ButtonPlace
          color={Colors.white}
          size={28}
          onPress={() => {
            dispatch({ type: MODAL_LOCATION_APP_ACTION, payload: true });
          }}
        />

      );
    }
  }


  function renderMoneyUser() {
    if (projectParams?.showFinancial && availableUser && props.showBalance) {
      return (

        <ButtonMoney
          disabled={props.disableBalance}
          onPress={() => {
            dispatch({
              type: USER_COIN_MODAL, payload: {
                visible: true,
                disabled: props.disableBalance,
              },
            });
          }}
        />

      );
    }
  }


  function renderGenderLogoUser() {
    return (

      <ButtonGender
        disabled={props.disableGender}
        onPress={() => {
          dispatch({ type: USER_GENDER_MODAL, payload: true });
        }}
      />

    );
  }



  return (

    <AppBar
      transparent={props.transparent}
      backgroundColor={props.transparent ? 'transparent' : props.backgroundColor}
      absolute={props.absolute}
      borderBottomColor={props.borderBottomColor}>

      {!props.hideHeaderFixed && (
        <HeaderFixed
          screenKey={props.screenKey}
        />
      )}


      <ToolbarViewContainer>

        {(props.leftIcon || props.centerContent || props.rightIcon) && (
          <Toolbar
            backgroundColor={props.transparent ? 'transparent' : props.backgroundColor}
            leftIcon={props.leftIcon}
            centerContent={props.centerContent}
            rightIcon={
              <HeaderEndContainer>
                {props.rightIcon}

                {renderMoneyUser()}

                {renderPlaceUser()}

                {renderGenderLogoUser()}
              </HeaderEndContainer>
            }
          />
        )}


        {props.middleToolbar && (
          <SubToolbar
            backgroundColor={props.transparent ? 'transparent' : props.subBackgroundColor}
            noSubToolbarPadding={props.noSubToolbarPadding}>
            {props.middleToolbar}
          </SubToolbar>
        )}


        {props.children && (
          <SubToolbar
            backgroundColor={props.transparent ? 'transparent' : props.subBackgroundColor}
            noSubToolbarPadding={props.noSubToolbarPadding}>
            {props.children}
          </SubToolbar>
        )}

      </ToolbarViewContainer>

    </AppBar>

  );
};



export default HeaderScreen;
