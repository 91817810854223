import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  IInputSelect,
} from '../../../../../apis/interfaces/App/input-select.interface';

import {
  IconMapPin,
  IconPlane,
} from '../../../../../components/Icons';

import {
  RootState,
} from '../../../../../redux';

import {
  USER_TRAVELS_MODAL,
} from '../../../../../redux/reducers/app-modals.store';

import { worldCountriesArray } from '../../../../../shared/arrays/Address/world-countries.array';
import { countriesOption } from '../../../../../shared/arrays/App/countries.array';

import {
  Colors,
} from '../../../../../shared/constants';

import {
  TranslateConfig,
} from '../../../../../shared/translations';

import {
  returnCityArray,
  returnStateArray,
} from '../../../../../shared/utils/address.utils';

import {
  daysUntilDate,
} from '../../../../../shared/utils/date.utils';

import {
  stringRawDataText,
} from '../../../../../shared/utils/string.utils';

import {
  TravelCardContainer,
  WebTravelsContainer,
  MobileTravelsContainer,
  TravelCurrentContent,
  TravelCurrentIcon,
  TravelCurrentInfoContent,
  TravelCurrentText,
  TravelLabelCurrentText,
  TravelViewSeparator,
  TravelSeparator,
  TravelNextPlaceText,
  TravelNextPlaceLabelText,
  TravelAllPlaceContent,
  TravelModelFromView,
  TravelModelFromImage,
  TravelModelFromText,
  TravelAllPlaceButton,
  TravelAllPlaceText,
} from './styled';



export interface IProps {
  name?: string;
  from?: string;
  fromCountry?: string;
  travels?: Array<any>;
}



const DetailsSectionTravel: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const genderColorChoose = useSelector((state: RootState) => state.choose.genderColorApp);


  // 1 - se nao tiver nenhuma viagem, não mostrar o TravelsContainer
  // 2 - se tiver viagem, filtrar a viagem que tiver o enable true e colocar na Localização atual
  // 3 - Se nao tiver localização atual, não mostrar ela
  // 4 - ao colocar as proximas viagens, nao deve colocar a localização atual (porque ela ja foi colocada)


  const totalTravelsToShow = 2;
  const travelIcon = 20;

  const filterLangCountry = countriesOption.find((country) => stringRawDataText(country?.code) === stringRawDataText(props.fromCountry));

  // Verifica e Encontra a primeira viagem habilitada
  const hasTravels = props.travels && props.travels.length > 0;
  const enabledTravel = props.travels?.find((travel) => travel.enabled);


  function renderCurrentLocationModele(showDivisor: boolean) {
    const getUserCountry = enabledTravel.country;
    const findCountry = worldCountriesArray?.find((item: IInputSelect) => item.value === getUserCountry);
    const countryUser = findCountry?.label;

    const getStateArray = returnStateArray(getUserCountry);
    const findState = getStateArray?.find((itemLocation: IInputSelect) => itemLocation.value === enabledTravel.state);
    const stateUser = findState?.label;

    const getCityArray = returnCityArray(getUserCountry, findState?.value);
    const findCity = getCityArray?.find((itemLocation: IInputSelect) => itemLocation.value === enabledTravel.city);
    const cityUser = findCity?.label;


    return (

      <React.Fragment>

        <TravelCurrentContent
          onClick={() => {
            // /escorts/rio
          }}>

          <TravelCurrentIcon>
            <IconMapPin
              color={Colors.white}
              size={travelIcon}
            />
          </TravelCurrentIcon>


          <TravelCurrentInfoContent>
            <TravelCurrentText>
              {`${cityUser}, ${stateUser}, ${countryUser}`}
            </TravelCurrentText>


            <TravelLabelCurrentText>
              {translate(TranslateConfig.LOCATION_MY)}
            </TravelLabelCurrentText>
          </TravelCurrentInfoContent>

        </TravelCurrentContent>



        {showDivisor && props.travels.filter((travel) => !travel.enabled).length > 0 && (
          <TravelViewSeparator>
            <TravelSeparator />
          </TravelViewSeparator>
        )}

      </React.Fragment>

    );
  }



  function renderTravelModele(item: any, index: number) {
    const itemStartAt = item?.startAt;

    const getUserCountry = item?.country;
    const findCountry = worldCountriesArray?.find((item: IInputSelect) => item.value === getUserCountry);
    const countryUser = findCountry?.label;

    const getStateArray = returnStateArray(getUserCountry);
    const findState = getStateArray?.find((itemLocation: IInputSelect) => itemLocation.value === item?.state);
    const stateUser = findState?.label;

    const getCityArray = returnCityArray(getUserCountry, findState?.value);
    const findCity = getCityArray?.find((itemLocation: IInputSelect) => itemLocation.value === item?.city);
    const cityUser = findCity?.label;


    return (

      <React.Fragment
        key={index}>

        <TravelCurrentContent
          onClick={() => {
            // /escorts/puertovallarta
          }}>

          <TravelCurrentIcon>
            <IconPlane
              color={Colors.white}
              size={travelIcon}
            />
          </TravelCurrentIcon>


          <TravelCurrentInfoContent>
            <TravelNextPlaceText>
              {`${cityUser}, ${stateUser}, ${countryUser}`}
            </TravelNextPlaceText>


            <TravelNextPlaceLabelText>
              {`${translate(TranslateConfig.IN)} ${daysUntilDate(itemStartAt)} ${translate(TranslateConfig.DAYS)}`}
            </TravelNextPlaceLabelText>
          </TravelCurrentInfoContent>

        </TravelCurrentContent>



        {index === 0 && props.travels?.length > totalTravelsToShow && (
          <TravelViewSeparator>
            <TravelSeparator />
          </TravelViewSeparator>
        )}

      </React.Fragment>

    );
  }



  return (

    <TravelCardContainer>

      {hasTravels && (
        <WebTravelsContainer>

          {enabledTravel && renderCurrentLocationModele(true)}


          {props.travels
            .filter((travel) => !travel.enabled)
            .slice(0, totalTravelsToShow) // Mostra apenas as duas primeiras viagens
            .map((travel, index) => renderTravelModele(travel, index))}

        </WebTravelsContainer>
      )}


      <MobileTravelsContainer>
        {enabledTravel && renderCurrentLocationModele(false)}
      </MobileTravelsContainer>



      <TravelAllPlaceContent>

        <TravelModelFromView>
          <TravelModelFromImage
            src={filterLangCountry?.image}
          />

          <TravelModelFromText>
            {translate(TranslateConfig.FROM)}: {props.from || '-'}
          </TravelModelFromText>
        </TravelModelFromView>



        {props.travels?.length > 0 && (
          <TravelAllPlaceButton
            onClick={() => {
              dispatch({
                type: USER_TRAVELS_MODAL,
                payload: {
                  alias: props.name,
                  travels: props.travels,
                },
              });
            }}>

            <TravelAllPlaceText
              color={genderColorChoose}>
              {translate(TranslateConfig.ACTION_SEE_TRAVELS)}
            </TravelAllPlaceText>

          </TravelAllPlaceButton>
        )}

      </TravelAllPlaceContent>

    </TravelCardContainer>

  );
};



export default DetailsSectionTravel;
