export const METAS = {
  descriptionGlobal:                'O melhor site de garotos de programa, garotas de programa, cis e trans de programa, GP e acompanhante com anúncios atualizados!',
  descriptionRent:                  'Acompanhantes mulheres, homens, garotos de programa, garotas de programa, cis e trans de programa, GP em todo o Brasil!',
  descriptionRentHowAds:            'Saiba como aumentar seu alcance na internet e se destacar com os anúncios de acompanhante Secretio.',
  descriptionBeModele:              'Torne-se um modelo no Secretio e comece a ganhar dinheiro!',
  descriptionRegister:              'Faça seu cadatro, favorite perfis e acompanhe as atualizações no Secretio.',
  descriptionPanelModele:           'Configure seu perfil Secretio de acordo com suas preferências profissionais.',
  keywords:                         'Garotos de programa, garotas de programa, travesti de programa, Gp, acompanhantes masculinos, acompanhantes femininos, acompanhantes trans, acompanhantes mulheres, homens, cis e trans, garoto com local, garota com local, travesti com local',
};



const Seo = {
  ...METAS,
};



export default Seo;
