import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../../shared/constants';

import {
  BadgeSkewX,
} from '../../../Badges/BadgeVt';



export interface IProps {
  online?: boolean;
}



export const CardHomeOnlineContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  overflow: hidden;
  flex: 1;
  width: 100%;
  height: 158px;
  border-radius: ${Sizes.cardRadius}px;
  background-color: ${Colors.cardBackground};
`;



export const HomeOnlineUserOnlineImage = styled.a`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${Colors.black};
  z-index: 0;
`;



export const SmallProfileLabelItem = styled(BadgeSkewX)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 3;
`;



export const HomeSmallViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 5;
`;

// FIXME: Ajustar pra usar o componente LinearGradient
export const HomeOnlineContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: ${Colors.black};
  background: linear-gradient(0deg, ${Colors.black} 20%, ${Colors.menuOverlay} 100%);
  padding-top: 6px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  border-bottom-left-radius: ${Sizes.cardRadius}px;
  border-bottom-right-radius: ${Sizes.cardRadius}px;
  z-index: 2;
`;



export const HomeOnlineInfoContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;


export const HomeOnlineUsernameText = styled.p`
  color: ${Colors.white};
  font-size: 11px;
  cursor: pointer;
`;

export const HomeOnlineUserIcon = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-left: 8px;
`;


export const HomeOnlineNameText = styled.p`
  margin-top: 6px;
  color: ${Colors.white};
  font-size: 13px;
  cursor: pointer;
`;


export const BorderStatusView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${Sizes.cardRadius + 3}px;
  margin-top: -${Sizes.cardRadius}px;
  background-color: ${(props: IProps) => props.online ? Colors.accept : Colors.disabled};
`;
