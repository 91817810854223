import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux';
import linkbioThemesArray from '../../../../shared/arrays/LinkBio/linkbio-themes.array';
import LinkBioFrame from '../Frame';
import {
  PreviewRoot,
} from './styled';



const ViewLinkBioScreen = () => {
  const user = useSelector((state: RootState) => state.user.actualUser);


  const profileAA = useSelector((state: RootState) => state.linkbio.appearance);
  const profile = profileAA as any;

  const socialAA = useSelector((state: RootState) => state.linkbio.config);
  const social = socialAA as any;

  const items = useSelector((state: RootState) => state.linkbio.items);

  const themesAA = profileAA?.themes as any;
  const themes = themesAA?.list as any;
  const theme = themesAA?.selected as any;

  const fallbackTheme = linkbioThemesArray[0]?.image;


  return (
    <PreviewRoot srcBg={theme ? theme : fallbackTheme}>
      <LinkBioFrame />
    </PreviewRoot>
  );
};

export default ViewLinkBioScreen;
