import SecretioWS from '../index';



export const GetUserReviews = async (idModele: string) => {
  const url = `/app/user/review/${idModele}`;

  const response = await SecretioWS.get(url);
  return response.data;
};



export const UserEditReview = async (idComment: number, idModele: string, payload: any) => {
  const url = `/app/user/review/${idModele}/${idComment}`;

  const response = await SecretioWS.put(url, payload);
  return response.data;
};



export const UserDeleteReview = async (idComment: number, idUserReceived: string) => {
  const url = `/app/user/review/${idComment}`;

  const payload = {
    idUser: idUserReceived,
  };

  const response = await SecretioWS.delete(url, {
    data: payload,
  });
  return response.data;
};
