import styled from 'styled-components';

import Color from 'color';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';



export interface IProps {
  color?: string;
  backgroundColor?: string;
}



export const CreditBuyView = styled.a`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  background-color: ${(props: IProps) => props.backgroundColor
    ? Color(props.backgroundColor).darken(0.35).toString()
    : Colors.primaryDark};
  border-radius: ${Sizes.cardRadius}px;
`;

export const PercentFullFree = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 8px 14px 8px 8px;
`;

export const CreditTipImage = styled.img`
  width: 80px;
  height: 80px;
  object-fit: contain;
`;

export const CreditTipInfoView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
`;

export const CreditTipPrice = styled.p`
  margin-bottom: 4px;
  color: ${Colors.white};
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  text-align: center;
`;

export const CreditTipFreePrice = styled.p`
  color: ${Colors.white};
  font-size: 14px;
  text-align: center;
`;

export const PercentFullPrice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: ${(props: IProps) => props.backgroundColor
    ? Color(props.backgroundColor).lighten(0.75).toString()
    : Colors.primary};
`;

export const CreditTextPrice = styled.p`
  margin-top: 2px;
  margin-bottom: 2px;
  color: ${(props: IProps) => props.color
    ? Color(props.color).darken(0.2).toString()
    : Colors.white};
  font-size: 16px;
  font-weight: 700;
  text-align: center;
`;
