import {
  IInputPlaceSelect,
} from '../../../apis/interfaces/App/input-select-place.interface';



const CitiesFullBraPb: Array<IInputPlaceSelect> = [
  {
    id: 1,
    label: 'Bayeux',
    value: 'BAYEUX',
  },
  {
    id: 2,
    label: 'Cabedelo',
    value: 'CABEDELO',
  },
  {
    id: 3,
    label: 'Cajazeiras',
    value: 'CAJAZEIRAS',
  },
  {
    id: 4,
    label: 'Campina Grande',
    value: 'CAMPINA_GRANDE',
  },
  {
    id: 5,
    label: 'Guarabira',
    value: 'GUARABIRA',
  },
  {
    id: 6,
    label: 'Itabaiana',
    value: 'ITABAIANA',
  },
  {
    id: 7,
    label: 'João Pessoa',
    value: 'JOAO_PESSOA',
  },
  {
    id: 8,
    label: 'Patos',
    value: 'PATOS',
  },
  {
    id: 9,
    label: 'Santa Rita',
    value: 'SANTA_RITA',
  },
  {
    id: 10,
    label: 'Sousa',
    value: 'SOUSA',
  },
];



export default CitiesFullBraPb;
