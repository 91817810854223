import React,
{
  useState,
} from 'react';

import {
  SetLiveRoomStatusColor,
} from '../../../config/app-live-room-status.config';

import {
  appLiveRoomStatusArray,
} from '../../../shared/arrays/Live/app-live-room-status.array';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import ERoomType from '../../../shared/enums/Live/room-type.enum';

import {
  IconFavoriteOff,
  IconScreenNew,
  IconVerified,
  IconVerifiedPromoter,
} from '../../Icons';

import LoadingProgress from '../../Loadings/LoadingProgress';

import {
  CardLiveModeleContent,
  CardLiveProgressView,
  CardModeleLiveCamView,
  CardModeleLiveButton,
  CardModeleLiveUserImage,
  CardModeleActionsView,
  CardModeleExclusiveView,
  CardModeleNewScreenButton,
  CardModeleLiveContent,
  CardModeleLiveInfoContent,
  CardModeleStatusDot,
  CardModeleLiveEntranceButton,
  CardModeleLiveTitleText,
  CardModeleLiveActionText,
  CardModeleNameContent,
  CardModeleLiveFlag,
  CardModeleLiveNameText,
  CardModeleLiveUserIcon,
} from './styled';



export interface IProps {
  status?: ERoomType;
  exclusive?: boolean;
  verified?: boolean;
  promoter?: boolean;
  showFlag?: boolean;
  username: string;
  name: string;
  image?: string;
  onFavoritePress?: any;
  onProfilePress?: any;
  onPress?: any;
}



const CardLiveModele: React.FC<IProps> = (props: IProps) => {
  const [showLoading, setShowLoading] = useState(false);
  const [showImage, setShowImage] = useState(true);
  const [showVideo, setShowVideo] = useState(false);


  function returnRoomAction() {
    const findRoomStatus = appLiveRoomStatusArray?.find((status) => status.value === props.status);
    return findRoomStatus;
  }


  const handleMouseEnter = () => {
    setShowLoading(true);

    setTimeout(() => {
      setShowLoading(false);
      setShowImage(false);
      setShowVideo(true);
    }, 2000);
  };


  const handleMouseLeave = () => {
    setShowLoading(false);
    setShowImage(true);
    setShowVideo(false);
  };



  return (

    <CardLiveModeleContent
      backgroundColor={Colors.cardBackground}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>

      {showLoading && (
        <CardLiveProgressView
          onClick={() => {
            if (props.onPress) {
              props.onPress();
            }
          }}>

          <LoadingProgress />

        </CardLiveProgressView>
      )}



      {showVideo && (
        <CardModeleLiveCamView
          onClick={() => {
            if (props.onPress) {
              props.onPress();
            }
          }}>

        </CardModeleLiveCamView>
      )}



      {showImage && (
        <CardModeleLiveButton
          onClick={() => {
            if (props.onPress) {
              props.onPress();
            }
          }}>
          <CardModeleLiveUserImage
            src={props.image || Images.avatarUserImage}
            alt={'story'}
          />
        </CardModeleLiveButton>
      )}


      <CardModeleActionsView>

        {props.exclusive && (
          <CardModeleExclusiveView
            backgroundColor={Colors.cardOverlay}
            color={Colors.primary}>
            Exclusivo
          </CardModeleExclusiveView>
        )}


        <CardModeleNewScreenButton>
          <IconScreenNew
            color={Colors.white}
            size={18}
          />
        </CardModeleNewScreenButton>


        <CardModeleNewScreenButton>
          <IconFavoriteOff
            color={Colors.white}
            size={18}
          />
        </CardModeleNewScreenButton>

      </CardModeleActionsView>



      <CardModeleLiveContent>

        <CardModeleLiveInfoContent
          onClick={() => {
            if (props.onPress) {
              props.onPress();
            }
          }}>

          <CardModeleStatusDot
            backgroundColor={SetLiveRoomStatusColor(props.status)}
          />


          <CardModeleLiveEntranceButton
            onClick={() => {
              if (props.onPress) {
                props.onPress();
              }
            }}>
            &nbsp;

            <CardModeleLiveTitleText
              color={SetLiveRoomStatusColor(props.status)}>
              {returnRoomAction()?.label || '-'}
            </CardModeleLiveTitleText>

            <CardModeleLiveActionText
              color={SetLiveRoomStatusColor(props.status)}>
              {returnRoomAction()?.text || '-'}
            </CardModeleLiveActionText>

          </CardModeleLiveEntranceButton>

        </CardModeleLiveInfoContent>



        <CardModeleNameContent
          onClick={() => {
            if (props.onPress) {
              props.onPress();
            }
          }}>

          {props.showFlag && (
            <CardModeleLiveFlag
              src={Images.countryFlagBrazil}
            />
          )}


          <CardModeleLiveNameText>
            {props.name}
          </CardModeleLiveNameText>


          {props.verified && (
            <CardModeleLiveUserIcon>
              <IconVerified
                size={14}
                color={Colors.accent}
              />
            </CardModeleLiveUserIcon>
          )}


          {!props.promoter && (
            <CardModeleLiveUserIcon>
              <IconVerifiedPromoter
                size={14}
                color={Colors.white}
              />
            </CardModeleLiveUserIcon>
          )}
        </CardModeleNameContent>

      </CardModeleLiveContent>

    </CardLiveModeleContent>

  );
};



export default CardLiveModele;
