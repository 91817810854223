export const availableModele = [
  {
    id: 1,
    label: 'Acompanhante',
    value: 'COMPANION',
  },
  {
    id: 2,
    label: 'Disponível para vídeos',
    value: 'AVAILABLE_FOR_VIDEOS',
  },
  {
    id: 3,
    label: 'Experiência de namoro',
    value: 'DATING_EXPERIENCE',
  },
  {
    id: 4,
    label: 'Festas e Eventos',
    value: 'PARTIES_AND_EVENTS',
  },
  {
    id: 5,
    label: 'Massagem Erótica',
    value: 'EROTIC_MASSAGE',
  },
  {
    id: 6,
    label: 'Massagem Corporal',
    value: 'BODY_MASSAGE',
  },
  {
    id: 7,
    label: 'Modelo',
    value: 'MODELING',
  },
  {
    id: 8,
    label: 'Striptease',
    value: 'STRIPTEASE',
  },
  {
    id: 9,
    label: 'Tudo pode acontecer',
    value: 'ANYTHING_CAN_HAPPEN',
  },
];
