import styled from 'styled-components';
import { Sizes } from '../../../../shared/constants';



export const ContentColumnWrapperAuto = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: ${Sizes.contentMaxWidth}px;
  margin: 30px auto;
`;


export const PackagesOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;


export const PackagesOptionsTitle = styled.h1`
  margin-top: 20px;
  margin-bottom: 20px;
  color: #ffffff;
  line-height: 30px;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
`;


export const PackagesSelectView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const PackagesOptionsGrid = styled.div`
  display: flex;
`;
