import styled from 'styled-components';

import Color from 'color';

import {
  Colors,
  Sizes,
  Svgs,
} from '../../../shared/constants';



export interface IProps {
  backgroundColor?: string;
}



export const FanclubContainer = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  background-color: ${Colors.fanClubBackgroundLevel};
  border-radius: 5px;
`;


export const FanclubModeleBackground = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
`;


export const FanclubInformationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 420px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 15px;
  background-color: ${Color(Colors.fanClubBackgroundLevel).darken(0.21).alpha(0.91).toString()};
  z-index: 5;
`;


export const FanclubHeaderSpotligh = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px 15px;
`;

export const FanclubAvatarView = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 100%;

  :after {
    content: '';
    position: absolute;
    background-image: url(${Svgs.fanclubAvatar});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 2;
  }
`;

export const FanclubHeaderAvatar = styled.img`
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  bottom: 0;
  width: calc(100% - 24px);
  height: calc(100% - 24px);
  margin: auto;
  border-radius: 100%;
  object-fit: cover;
`;


export const FanclubHeaderTitle = styled.h3`
  color: ${Colors.white};
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
`;

export const FanclubHeaderDescription = styled.p`
  margin-top: 10px;
  color: ${Colors.grayLight};
  font-size: 13px;
  line-height: 18px;
  text-align: center;
`;



export const FanclubTotalMediaView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 20px 0 0;
  padding: 5px;
  background-color: ${Colors.cardOverlay};
  border-radius: 10px;
`;

export const FanclubTotalText = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
`;

export const MediaTotalCount = styled.p`
  text-shadow: 1px 1px 1px rgba(0,0,0,.004);
  font-weight: 700;
  color: ${Colors.white};
  line-height: 1.5;
  font-size: 28px;
`;

export const MediaTotalText = styled.p`
  line-height: 1.5;
  text-shadow: 1px 1px 1px rgba(0,0,0,.004);
  color: ${Colors.fanClubLevel};
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
`;

export const FanclubDivisor = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.fanClubLevel};
  height: 35px;
  opacity: .3;
  width: 0.2px;
`;


export const FanclubActionView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 12px;
`;


export const FanclubPrice = styled.p`
  margin-bottom: 18px;
  color: ${Colors.white};
  font-size: 12px;
`;


export const FanclubSubscribeButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: ${Sizes.buttonHeight}px;
  padding: 10px 30px;
  background-color: ${(props: IProps) => props.backgroundColor};
  font-size: 11px;
  border-radius: ${Sizes.buttonRadius}px;
`;

export const FanclubSubscribeText = styled.p`
  color: white;
  font-size: 16px;
`;

export const FanclubSubscribeImage = styled.img`
  height: 25px;
  margin-left: 10px;
  object-fit: contain;
`;



export const FanclubDisabledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 15px;
  background-color: ${Color(Colors.black).alpha(0.91).toString()};
  z-index: 5;
`;
