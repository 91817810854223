import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../shared/constants';



export const CategoryChooseContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  max-width: 500px;
  min-width: 320px;
  padding-top: 16px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 16px;
  background-color: ${Colors.appBackground};
  border-radius: ${Sizes.cardRadius}px;
`;

export const VerifiedModalTitle = styled.p`
  margin-bottom: 15px;
  color: ${Colors.white};
  font-size: 20px;
`;



export const VerifiedModalTextsView = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const VerifiedModalDescription = styled.p`
  margin-top: 5px;
  margin-bottom: 5px;
  color: ${Colors.white};
  font-size: 14px;
  line-height: 20px;
`;

export const VerifiedModalMoreText = styled.p`
  margin-top: 15px;
  color: ${Colors.gray};
  font-size: 13px;
  cursor: pointer;
`;
