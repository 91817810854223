import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../../redux';
import { LINKBIO_UPDATE_SOCIAL_NETWORKS } from '../../../../../../../redux/reducers/linkbio.store';
import Input from '../../../../components/Input';
import { ContentBox } from './styled';



const SettingsTab: React.FC = () => {
  const dispatch = useDispatch();
  const profileAA = useSelector((state: RootState) => state.linkbio.config);

  const networks = profileAA?.social as any;



  const handleChangeInput = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = ev.target;
      dispatch({ type: LINKBIO_UPDATE_SOCIAL_NETWORKS, payload: { [name]: value } });
    },
    [networks],
  );

  return (
    <section>
      <p style={{ color: '#fff' }}>
        Redes Sociales
      </p>
      <ContentBox>
        <div>
          <div>
            <p style={{ color: '#fff' }}>Facebook</p>
            {/* <Spacing size={8} /> */}
            <Input
              placeholder='https://facebook.com/user'
              fullWidth
              value={networks.facebook}
              name='facebook'
              onChange={handleChangeInput}
            />
          </div>
          <div>
            <p style={{ color: '#fff' }}>Twitter</p>
            {/* <Spacing size={8} /> */}
            <Input
              placeholder='https://twitter.com/user'
              fullWidth
              value={networks.twitter}
              name='twitter'
              onChange={handleChangeInput}
            />
          </div>
          <div>
            <p style={{ color: '#fff' }}>Instagram</p>
            {/* <Spacing size={8} /> */}
            <Input
              placeholder='https://instagram.com/user'
              fullWidth
              value={networks.instagram}
              name='instagram'
              onChange={handleChangeInput}
            />
          </div>
        </div>
      </ContentBox>
    </section>
  );
};

export default SettingsTab;
