import {
  IInputPlaceSelect,
} from '../../../apis/interfaces/App/input-select-place.interface';



const CitiesFullBraMs: Array<IInputPlaceSelect> = [
  {
    id: 8,
    label: 'Aquidauana',
    value: 'AQUIDAUANA',
  },
  {
    id: 1,
    label: 'Campo Grande',
    value: 'CAMPO_GRANDE',
  },
  {
    id: 4,
    label: 'Corumbá',
    value: 'CORUMBA',
  },
  {
    id: 2,
    label: 'Dourados',
    value: 'DOURADOS',
  },
  {
    id: 10,
    label: 'Maracaju',
    value: 'MARACAJU',
  },
  {
    id: 6,
    label: 'Naviraí',
    value: 'NAVIRAI',
  },
  {
    id: 7,
    label: 'Nova Andradina',
    value: 'NOVA_ANDRADINA',
  },
  {
    id: 5,
    label: 'Ponta Porã',
    value: 'PONTA_PORA',
  },
  {
    id: 9,
    label: 'Sidrolândia',
    value: 'SIDROLANDIA',
  },
  {
    id: 3,
    label: 'Três Lagoas',
    value: 'TRES_LAGOAS',
  },
];



export default CitiesFullBraMs;
