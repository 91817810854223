import styled from 'styled-components';



export const ResizingContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 1px;
  background-color: #15171F;
  z-index: 20; 
`;

export const ResizingHandleButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 26px;
  height: 26px;
  background: transparent linear-gradient(45deg, #2C91AF 0%, #0D7391 100%) 0% 0% no-repeat padding-box;
  border-color: #15171F;
  border-style: solid;
  border-width: 4px;
  border-radius: 50%;
`;

export const ResizingTriangleWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 12px;
  background-color: transparent;
`;

export const ResizingTriangleLeft = styled.span`
  width: 0;
  height: 0;
  background-color: transparent;
  
  border-top-color: transparent;
  border-top-style: solid;
  border-top-width: 4px;
  
  border-right-color: #15171F;
  border-right-style: solid;
  border-right-width: 4px;
  
  border-bottom-color: transparent;
  border-bottom-style: solid;
  border-bottom-width: 4px;
`;

export const ResizingTriangleRight = styled.span`
  width: 0;
  height: 0;
  background-color: transparent;
  
  border-top-color: transparent;
  border-top-style: solid;
  border-top-width: 4px;
  
  border-left-color: #15171F;
  border-left-style: solid;
  border-left-width: 4px;
  
  border-bottom-color: transparent;
  border-bottom-style: solid;
  border-bottom-width: 4px;
`;
