import styled,
{
  css,
} from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';



export interface IProps {
  backgroundImage?: string;
  backgroundColor?: string;
}



export const LoginImageBackground = css`
  background-image: url(${(props: IProps) => props.backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const LoginColorBackground = css`
  background-color: ${(props: IProps) => props.backgroundColor};
`;

export const LoginScreen = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;

  ${(props: IProps) => props.backgroundImage && LoginImageBackground};
  ${(props: IProps) => props.backgroundColor && LoginColorBackground};

  @media (min-width: 521px) and (max-width: 700px) {
    align-items: center;
    justify-content: center;
  }
`;

export const LoginViewContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  position: absolute;

  background-color: ${Colors.appBackground};

  box-shadow: 0 2px 18px 1px rgb(49 53 72 / 10%);

  @media screen and (max-width: 520px) {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: 521px) {
    width: 360px;
    height: 640px;
    border-radius: ${Sizes.cardRadius}px;
  }

  @media screen and (min-width: 700px) {
    top: 50%;
    right: 100px;
    transform: translateY(-50%);
  }

`;

export const LoginScreenMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  width: 100%;
  height: 100%;
`;
