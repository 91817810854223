import React from 'react';

// import {
//   useTranslation,
// } from 'react-i18next';

import {
  renderCardColor,
  renderCardLogo,
} from '../../../config/payments.config';

import {
  Images,
} from '../../../shared/constants';

import {
  CardDashContainer,
  LoginImageBackground,
  CardDashView,
  InfoImageHeader,
  InfoTextHeader,
  CreditCardText,
  BadgeTextCard,
  CardName,
  ImageBank,
} from './styled';



export interface IProps {
  name?: string;
  number?: number;
  cardBrand?: any;

  current?: boolean;

  onPress?: any;
  disableOnPress?: boolean;

  last4?: string;
}



const CreditCardDash: React.FC<IProps> = (props: IProps) => {
  return (

    <CardDashContainer
      onClick={props.onPress}
      backgroundColor={renderCardColor(props.cardBrand)}>

      <CardDashView>

        <InfoImageHeader>

          <InfoTextHeader>

            <CreditCardText>
              •••• •••• •••• {props.last4}
            </CreditCardText>


            {props.current && (
              <BadgeTextCard>
                Atual
              </BadgeTextCard>
            )}
          </InfoTextHeader>



          {props.name && (
            <CardName>
              {props.name}
            </CardName>
          )}

        </InfoImageHeader>



        {props.cardBrand && (
          <ImageBank
            alt={'Card Logo'}
            src={renderCardLogo(props.cardBrand)}
          />
        )}

      </CardDashView>



      <LoginImageBackground
        alt={'Pattern'}
        src={Images.patternSquare}
      />

    </CardDashContainer>

  );
};



export default CreditCardDash;
