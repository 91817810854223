import {
  IInputPlaceSelect,
} from '../../../apis/interfaces/App/input-select-place.interface';



const CitiesFullBraPa: Array<IInputPlaceSelect> = [
  {
    id: 1,
    label: 'Abaetetuba',
    value: 'ABAETETUBA',
  },
  {
    id: 2,
    label: 'Ananindeua',
    value: 'ANANINDEUA',
  },
  {
    id: 3,
    label: 'Castanhal',
    value: 'CASTANHAL',
  },
  {
    id: 4,
    label: 'Cametá',
    value: 'CAMETA',
  },
  {
    id: 5,
    label: 'Belém',
    value: 'BELEM',
  },
  {
    id: 6,
    label: 'Itaituba',
    value: 'ITAITUBA',
  },
  {
    id: 7,
    label: 'Marituba',
    value: 'MARITUBA',
  },
  {
    id: 8,
    label: 'Marabá',
    value: 'MARABA',
  },
  {
    id: 9,
    label: 'Parauapebas',
    value: 'PARAUAPEBAS',
  },
  {
    id: 10,
    label: 'Santarém',
    value: 'SANTAREM',
  },
];



export default CitiesFullBraPa;
