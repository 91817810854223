import React from 'react';

import {
  useSelector,
} from 'react-redux';

import Color from 'color';

import {
  RootState,
} from '../../../redux';

import {
  Colors,
} from '../../../shared/constants';

import {
  PublishedContainer,
  SwitchStyled,
} from './styled';



export interface IProps {
  disabled?: boolean;
  selected?: boolean;
  textOn?: string;
  textOff?: string;
  color?: string;
  onPress?: any;
}



const ButtonPublish: React.FC<IProps> = (props: IProps) => {
  const genderColorChoose = useSelector((state: RootState) => state.choose.genderColorApp);
  const accentGender = props.color || Color(genderColorChoose).lighten(0.1).toString();



  return (

    <PublishedContainer
      {...props}>

      <SwitchStyled
        disabled={props.disabled}
        selected={props.selected}
        textOn={props.textOn}
        textOff={props.textOff}
        textColor={Colors.inputLabel}
        color={props.selected ? accentGender : Colors.inputCount}
        onPress={() => {
          if (!props.disabled && props.onPress) {
            props.onPress();
          }
        }}
      />

    </PublishedContainer>

  );
};



export default ButtonPublish;
