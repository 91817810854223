import React from 'react';

import {
  Colors,
} from '../../../shared/constants';

import {
  IconCreditCard,
} from '../../Icons';

import {
  DashboardAddCreditCard,
  DashboardAddCreditCardIcon,
  DashboardAddCreditCardText,
} from './styled';



const InfoCreditCard: React.FC = () => {
  return (

    <DashboardAddCreditCard>

      <DashboardAddCreditCardIcon>
        <IconCreditCard
          color={Colors.white}
          size={40}
        />
      </DashboardAddCreditCardIcon>


      <DashboardAddCreditCardText>
        Adicionar cartão de crédito
      </DashboardAddCreditCardText>

    </DashboardAddCreditCard>

  );
};



export default InfoCreditCard;
