import {
  Images,
} from '../shared/constants';

import {
  EPlansUser,
} from '../shared/enums';



export function RenderRibbonImage(plan: EPlansUser) {
  if (!plan) {
    return null;
  }
  switch (plan) {
    case EPlansUser.PREMIUM:
      return Images.ribbonPremium;

    case EPlansUser.BRONZE:
      return Images.ribbonBronze;

    case EPlansUser.SILVER:
      return Images.ribbonSilver;

    case EPlansUser.GOLD:
    case EPlansUser.GOLD_FREEMIUM:
      return Images.ribbonGold;

    case EPlansUser.DIAMOND:
      return Images.ribbonDiamond;

    case EPlansUser.NONE:
    default:
      return null;
  }
};



export function RenderMedalImage(plan: EPlansUser) {
  if (!plan) {
    return null;
  }
  switch (plan) {
    case EPlansUser.PREMIUM:
      return Images.medalPremium;

    case EPlansUser.BRONZE:
      return Images.medalBronze;

    case EPlansUser.SILVER:
      return Images.medalSilver;

    case EPlansUser.GOLD:
    case EPlansUser.GOLD_FREEMIUM:
      return Images.medalGold;

    case EPlansUser.DIAMOND:
      return Images.medalDiamond;

    case EPlansUser.NONE:
    default:
      return null;
  }
};



export function RenderPlanText(plan: EPlansUser) {
  if (!plan) {
    return null;
  }
  switch (plan) {
    case EPlansUser.PREMIUM:
      return 'Premium';

    case EPlansUser.BRONZE:
      return 'Bronze';

    case EPlansUser.SILVER:
      return 'Silver';

    case EPlansUser.GOLD:
    case EPlansUser.GOLD_FREEMIUM:
      return 'Gold';

    case EPlansUser.DIAMOND:
      return 'Diamante';

    case EPlansUser.NONE:
    default:
      return null;
  }
};
