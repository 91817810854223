import React from 'react';

import CardPackagePay from '../../../../components/Cards/CardPackagePay';
import PricesArray from '../../../../shared/arrays/Financial/prices.array';

import {
  ContentColumnWrapperAuto,
  PackagesOptionsContainer,
  PackagesOptionsTitle,
  PackagesSelectView,
  PackagesOptionsGrid,
} from './styled';



const TabPackages: React.FC = () => {
  return (

    <ContentColumnWrapperAuto>
      <PackagesOptionsContainer>

        <PackagesOptionsTitle>
          Selecione o valor desejado para carregar sua conta
        </PackagesOptionsTitle>



        <PackagesSelectView>
          <h3 style={{ color: '#fff' }}>
            Moeda: $ Dolar
          </h3>
        </PackagesSelectView>



        <PackagesOptionsGrid>

          {PricesArray.map((values, index) => (
            <CardPackagePay
              key={index}
              promotion={values.promotion}
              credit={values.credit}
              price={values.price}
            />
          ))}

        </PackagesOptionsGrid>

      </PackagesOptionsContainer>


      <button>
        Sair
      </button>

      <button>
        Avançar
      </button>

    </ContentColumnWrapperAuto>

  );
};



export default TabPackages;
