import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  IUserMedia,
} from '../../../../../apis/interfaces/Medias/user-media.interface';

import {
  IViewMediaModal,
} from '../../../../../apis/interfaces/Medias/view-media-modal.interface';

import ButtonActionType from '../../../../../components/Buttons/ButtonActionType';
import ButtonPhone from '../../../../../components/Buttons/ButtonPhone';
import CardPost from '../../../../../components/Cards/Posts/CardPostFeed';
import EmptyContent from '../../../../../components/Empty';

import {
  IconMediaPhoto,
  IconMediaVideo,
  IconPhone,
} from '../../../../../components/Icons';

import SectionTitle from '../../../../../components/Titles/SectionTitle';

import {
  Colors,
  Images,
} from '../../../../../shared/constants';

import {
  TranslateConfig,
} from '../../../../../shared/translations';

import RightContentUser from '../../Right';

import {
  FeedContentCenterRowFlex,
  ContentLeft,
  WrapperDetailItemContent,
  PhoneContentView,
  FeedHoursContainer,
  FeedHoursImage,
  FeedHoursText,
  TabFeedContentFlex,
  FeedGridMediaButtonsView,
  FeedGridMediaButton,
  FeedGridMediaIcon,
  FeedGridMediaText,
} from './styled';



export interface IProps {
  showActions?: boolean;
  modele?: any;
  dataMedia?: Array<IUserMedia>;
  suggestions?: Array<any>;
  onShowMedia?: any;
  showTabPhotos?: any;
  showTabVideos?: any;
}



const TabFeedScreen: React.FC<IProps> = (props: IProps) => {
  const modeleReceived = props.modele;
  const suggestionsArray = props.suggestions;
  const showActions = props.showActions;

  const phoneAvaillable = modeleReceived?.contacts?.showPhoneNumber
    && modeleReceived?.contacts?.phoneDdi
    && modeleReceived?.contacts?.phoneNumber;

  const feedModele = props.dataMedia || [];


  const { t: translate } = useTranslation();



  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noFeed}
        title={translate(TranslateConfig.EMPTY_ITEMS)}
      />

    );
  }



  return (

    <FeedContentCenterRowFlex>

      <ContentLeft>

        {/* TODO */}
        {false && (
          <a
            onClick={() => {
              // TODO
            }}>
            <p>
              {translate(TranslateConfig.ACTION_PROFILE_MANAGE)}
            </p>
          </a>
        )}



        <WrapperDetailItemContent>
          <SectionTitle
            title={translate(TranslateConfig.DETAILS)}
          />


          <PhoneContentView>

            {phoneAvaillable && (
              <ButtonPhone
                flexDirection={'COLUMN'}
                idModele={modeleReceived?.id}
                comeNow={modeleReceived?.appRentHighlight?.length > 0}
                username={modeleReceived?.useralias}
                isWhatsApp={modeleReceived?.contacts?.phoneNumberWhatsapp}
                ddi={modeleReceived?.contacts?.phoneDdi}
                phone={modeleReceived?.contacts?.phoneNumber}
              />
            )}


            {!phoneAvaillable && (
              <ButtonActionType
                disabled={true}
                title={translate(TranslateConfig.EMPTY_PHONES)}
                icon={
                  <IconPhone
                    color={Colors.white}
                    size={22}
                  />
                }
              />
            )}

          </PhoneContentView>



          {modeleReceived?.contacts?.phoneNumber24h && (
            <FeedHoursContainer>
              <FeedHoursImage
                src={Images.badge24h}
              />

              <FeedHoursText>
                {translate(TranslateConfig.SERVICE_24H)}
              </FeedHoursText>
            </FeedHoursContainer>
          )}

        </WrapperDetailItemContent>

      </ContentLeft>



      <TabFeedContentFlex>


        {!feedModele || feedModele?.length <= 0 && (
          listEmptyComponent()
        )}



        {feedModele && feedModele?.length > 0 && (
          <FeedGridMediaButtonsView>

            <FeedGridMediaButton
              onClick={() => {
                props.showTabPhotos();
              }}>

              <FeedGridMediaIcon>
                <IconMediaPhoto
                  color={Colors.white}
                  size={17}
                />
              </FeedGridMediaIcon>


              <FeedGridMediaText>
                {translate(TranslateConfig.ACTION_SEE_ALL_PHOTOS)}
              </FeedGridMediaText>

            </FeedGridMediaButton>



            <FeedGridMediaButton
              onClick={() => {
                props.showTabVideos();
              }}>

              <FeedGridMediaIcon>
                <IconMediaVideo
                  color={Colors.white}
                  size={17}
                />
              </FeedGridMediaIcon>


              <FeedGridMediaText>
                {translate(TranslateConfig.ACTION_SEE_ALL_VIDEOS)}
              </FeedGridMediaText>

            </FeedGridMediaButton>

          </FeedGridMediaButtonsView>
        )}



        {feedModele && feedModele?.length > 0 && feedModele.map((itemPost, index: number) => (
          <CardPost
            key={index}
            idMedia={itemPost?.id}
            feedType={'DETAILS'}
            type={itemPost?.type}
            plan={modeleReceived?.plan?.planCurrent}
            imageUser={modeleReceived?.picture}
            name={modeleReceived?.alias}
            username={modeleReceived?.useralias}
            verified={modeleReceived?.verified}
            promoter={modeleReceived?.brandPromoter}
            createdAt={itemPost?.createdAt}
            mediaLink={itemPost?.picture}
            like={itemPost?.like || 0}
            caption={itemPost?.caption}
            onPress={() => {
              const itemToSender: IViewMediaModal = {
                indexSelected: index,
                type: 'FEED',
              };

              if (props.onShowMedia) {
                props.onShowMedia(itemToSender);
              }
            }}
          />
        ))}

      </TabFeedContentFlex>



      <RightContentUser
        showActions={showActions}
        modele={modeleReceived}
        suggestions={suggestionsArray}
      />

    </FeedContentCenterRowFlex>

  );
};



export default TabFeedScreen;
