import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useNavigate,
} from 'react-router-dom';

import {
  GetAllLivePromo,
} from '../../../../../apis/endpoints/lives.endpoints';

import CardLivePromo from '../../../../../components/Cards/CardLivePromo';

import {
  Content,
} from '../../../../../components/Composh/web';

import EmptyContent from '../../../../../components/Empty';
import FooterApp from '../../../../../components/Footers/FooterApp';
import LoadingScreen from '../../../../../components/Loadings/LoadingScreen';

import NameRoutes from '../../../../../navigation/names';

import {
  RootState,
} from '../../../../../redux';

import {
  APP_SNACKBAR_ACTION,
} from '../../../../../redux/reducers/app-modals.store';

import {
  Colors,
  Images,
} from '../../../../../shared/constants';

import {
  TranslateConfig,
} from '../../../../../shared/translations';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../../../shared/utils/errors.utils';

import {
  PromoItemGrid,
  LiveWrapperItemContent,
  SectionTitleStyled,
} from './styled';



const TabPromoPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { t: translate } = useTranslation();


  const genderChoose = useSelector((state: RootState) => state.choose.genderChoose);


  const [modeles, setModeles] = useState<Array<any>>([]);

  const [loading, setLoading] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  const getCompanies = async () => {
    try {
      setLoading(true);

      const response = await GetAllLivePromo(genderChoose);

      if (response) {
        setModeles(response);
      }
      else {
        setModeles([]);
      }
    }
    catch (error: any) {
      console.error(error);
      setModeles([]);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
    }
  };


  function listHeaderComponent() {
    return (

      <SectionTitleStyled
        title={translate(TranslateConfig.LIVE_PROMOS)}
      />

    );
  }


  function renderItem(item: any, index: number) {
    return (

      <CardLivePromo
        key={index}
        liveNow={item?.liveNow}
        image={item?.image}
        date={item?.date}
        hour={item?.hour}
        onPress={() => {
          history(
            NameRoutes.LiveRoomCamScreen.replace(
              ':id',
              'username',
            ),
            {
              state: {
                status: item?.status,
                promo: true,
              },
            },
          );
        }}
      />

    );
  }


  function renderLoading() {
    if (!loading) {
      return null;
    }

    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.white}
      />

    );
  }


  function renderFooter() {
    if (!loading) {
      return null;
    }

    return (

      <LoadingScreen
        type={'FOOTER'}
        color={Colors.white}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noImage}
        title={translate(TranslateConfig.EMPTY_ITEMS)}
      />

    );
  }



  useEffect(() => {
    getCompanies();
  }, []);


  useEffect(() => {
    getCompanies();
  }, [genderChoose]);



  return (

    <Content>
      <LiveWrapperItemContent>

        <PromoItemGrid
          noScroll
          loading={loading}
          data={modeles}
          renderItem={renderItem}
          listHeaderComponent={listHeaderComponent()}
          listLoadingComponent={renderLoading()}
          listFooterComponent={renderFooter()}
          listEmptyComponent={listEmptyComponent()}
        />

      </LiveWrapperItemContent>



      <FooterApp />

    </Content>

  );
};



export default TabPromoPage;
