import React from 'react';

import {
  Colors,
  Images,
  Sizes,
} from '../../../shared/constants';

import {
  IconLiveNow,
} from '../../Icons';

import {
  CardPromoLiveContainer,
  PromoCardContainer,
  PromoCardViewImage,
  PromoCardImage,
  PromoCardInfoContent,
  PromoCardInfoView,
  PromoCardDate,
  PromoCardHor,
  PromoCardInfoIcon,
} from './styled';



export interface IProps {
  liveNow?: boolean;
  image?: string;
  date?: string;
  hour?: string;
  onPress?: any;
}



const CardLivePromo: React.FC<IProps> = (props: IProps) => {
  return (

    <CardPromoLiveContainer>
      <PromoCardContainer
        backgroundColor={Colors.cardBackground}
        borderRadius={Sizes.cardRadius}
        onPress={() => {
          if (props.onPress) {
            props.onPress();
          }
        }}>

        <PromoCardViewImage>
          <PromoCardImage
            alt={''}
            src={props.image || Images.avatarUserImage}
          />
        </PromoCardViewImage>


        <PromoCardInfoContent>

          <PromoCardInfoView>
            <PromoCardDate>
              {props.date}
            </PromoCardDate>


            <PromoCardHor>
              {props.hour}
            </PromoCardHor>
          </PromoCardInfoView>


          {props.liveNow && (
            <PromoCardInfoIcon>
              <IconLiveNow />
            </PromoCardInfoIcon>
          )}

        </PromoCardInfoContent>

      </PromoCardContainer>
    </CardPromoLiveContainer>

  );
};



export default CardLivePromo;
