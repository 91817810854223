import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';



export const AddCreditView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;

  @media ${PlatformDevices.minTabletM} {
    flex-direction: row;
  }
`;



export const AddCreditBalanceView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const AddCreditCoinImage = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
  object-fit: contain;
`;


export const AddCreditCountView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const AddCreditSymbolText = styled.p`
  margin-top: 6px;
  margin-right: 8px;
  color: ${Colors.primary};
  font-size: 16px;
`;

export const AddCreditPriceText = styled.p`
  color: ${Colors.primary};
  font-size: 34px;
  font-weight: 700;
`;

export const AddCreditPriceSup = styled.sup`
  top: -15px;
  margin-left: 4px;
  font-size: 42%;
  font-weight: 700;
  text-align: center;
`;



export const AddCreditButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: ${Sizes.buttonHeight}px;
  min-width: 210px;
  margin-left: 25px;
  padding: 8px 18px;
  background-color: #47b50a;
  border-radius: ${Sizes.buttonRadius}px;
  transition: all .15s ease-out;

  @media ${PlatformDevices.maxTabletM} {
    margin-top: 15px;
    min-width: 100%;
    margin-left: 0;
  }
`;

export const AddCreditTextButton = styled.p`
  color: ${Colors.white};
  font-size: 13px;
  font-weight: 700;
  text-align: center;
`;
