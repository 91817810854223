import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
} from 'react-redux';

import {
  useNavigate,
} from 'react-router-dom';

import {
  IInputSelect,
} from '../../../apis/interfaces/App/input-select.interface';

import CardAdsSuggestion from '../../../components/Ads';
import BadgeApp from '../../../components/Badges/BadgeApp';
import CardFavorites from '../../../components/Cards/CardFavorites';
import ChipApp from '../../../components/Chip';

import {
  Content,
} from '../../../components/Composh/web';

import EmptyContent from '../../../components/Empty';
import FooterApp from '../../../components/Footers/FooterApp';

import {
  IconFaClub,
  IconFavoriteOn,
  IconProfile,
} from '../../../components/Icons';

import LoadingScreen from '../../../components/Loadings/LoadingScreen';
import NameRoutes from '../../../navigation/names';

import {
  RootState,
} from '../../../redux';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import {
  TranslateConfig,
} from '../../../shared/translations';

import {
  returnCityArray,
  returnStateArray,
} from '../../../shared/utils/address.utils';

import {
  MaskNotificationsBadge,
} from '../../../shared/utils/masks.utils';

import {
  ContentWrapperRowFull,
  ContentCenterFlex,
  MediaHeaderPadderTop,
  ProfileFavoritesScrollCardsContent,
} from './styled';



export interface IProps {
  favoriteArray?: Array<any>;
  loading?: boolean;
}



const TabModelesBookmark: React.FC<IProps> = (props: IProps) => {
  const history = useNavigate();
  const { t: translate } = useTranslation();


  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);


  const modeleArray = props.favoriteArray || [];
  const loading = props.loading;

  const [filteredModele, setFilteredModele] = useState<any[]>([]);
  const [typeImage, setTypeImage] = useState<any | ''>('');


  const photosOptions = [
    {
      visible: true,
      title: translate(TranslateConfig.ALL),
      typeImage: '',
      icon: (
        <IconProfile
          color={Colors.white}
          size={18}
        />
      ),
      badgeCount: modeleArray.length,
    },
    {
      visible: true,
      title: translate(TranslateConfig.FAVORITEDS),
      typeImage: 'FAVORITED',
      icon: (
        <IconFavoriteOn
          color={Colors.white}
          size={18}
        />
      ),
      badgeCount: modeleArray.filter((media: any) => media?.collection === 'FAVORITED')?.length,
    },
    {
      visible: projectParams?.showFanClub,
      title: translate(TranslateConfig.FAN_CLUB),
      typeImage: 'FAN_CLUB',
      icon: (
        <IconFaClub
          color={Colors.white}
          size={18}
        />
      ),
      badgeCount: modeleArray.filter((media: any) => media?.visibility === 'FAN_CLUB')?.length,
    },
  ];



  function renderChipChooses(item: any, index: number) {
    if (!item?.visible) {
      return;
    }


    return (

      <ChipApp
        key={index}
        title={item?.title}
        selected={typeImage === item?.typeImage}
        selectedBackgroundColor={Colors.primaryDark}
        thumb={item?.icon}
        icon={
          <BadgeApp
            text={MaskNotificationsBadge(item?.badgeCount)}
            backgroundColor={Colors.black}
            color={Colors.white}
          />
        }
        onPress={() => {
          setTypeImage(item?.typeImage);
        }}
      />

    );
  }


  function renderItem(item: any, index: number) {
    let hasLocation = false;

    let cityUser = '';
    let stateUser = '';
    let countryUser = '';

    if (item?.appTravels?.length > 0) {
      const travel = item?.appTravels[0];

      hasLocation = travel
        && travel?.country
        && travel?.state
        && travel?.city;

      countryUser = travel?.country;

      const getStateArray = returnStateArray(countryUser);
      const findState = getStateArray?.find((itemTravel: IInputSelect) => itemTravel.value === travel?.state);
      stateUser = findState?.label;

      const getCityArray = returnCityArray(countryUser, findState?.value);
      const findCity = getCityArray?.find((itemTravel: IInputSelect) => itemTravel.value === travel?.city);
      cityUser = findCity?.label;
    }
    else {
      hasLocation = item?.location
        && item?.location?.country
        && item?.location?.state
        && item?.location?.city;

      countryUser = item?.location?.country;

      const getStateArray = returnStateArray(countryUser);
      const findState = getStateArray?.find((itemLocation: IInputSelect) => itemLocation.value === item?.location?.state);
      stateUser = findState?.label;

      const getCityArray = returnCityArray(countryUser, findState?.value);
      const findCity = getCityArray?.find((itemLocation: IInputSelect) => itemLocation.value === item?.location?.city);
      cityUser = findCity?.label;
    }


    return (

      <CardFavorites
        key={index}
        alias={item?.alias || '-'}
        useralias={item?.useralias}
        image={item?.picture}

        rent={item?.params?.rent}
        fanClub={item?.params?.fanClub}
        live={item?.params?.live}
        massage={item?.params?.massage}

        // available={item?.params?.available}
        plan={item?.params?.plan}

        country={hasLocation ? countryUser : ''}
        place={hasLocation
          ? `${cityUser}, ${stateUser}`
          : translate(TranslateConfig.EMPTY_LOCATION)
        }

        arrayImage={item?.pictureGalleryImages}
        onPress={() => {
          history(NameRoutes.ModeleDetailsScreen.replace(
            ':id',
            item?.useralias,
          ));
        }}
      />

    );
  }


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.white}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noFavoritesModele}
        title={translate(TranslateConfig.EMPTY_FAVORITES)}
      />

    );
  }



  useEffect(() => {
    if (typeImage === '') {
      setFilteredModele(modeleArray);
    }
    else {
      const filtered = modeleArray.filter((media) => media?.collection === typeImage);
      setFilteredModele(filtered);
    }
  }, [typeImage, modeleArray]);



  return (

    <Content>

      <ContentWrapperRowFull>

        <ContentCenterFlex>

          <MediaHeaderPadderTop>
            {photosOptions.map((chip, index) => (
              renderChipChooses(chip, index)
            ))}
          </MediaHeaderPadderTop>



          <ProfileFavoritesScrollCardsContent
            noScroll
            loading={loading}
            data={filteredModele}
            renderItem={renderItem}
            listLoadingComponent={renderLoading()}
            listEmptyComponent={listEmptyComponent()}
          />

        </ContentCenterFlex>



        <CardAdsSuggestion />

      </ContentWrapperRowFull>



      <FooterApp />

    </Content>

  );
};



export default TabModelesBookmark;
