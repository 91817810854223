import React from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  RootState,
} from '../../../redux';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import {
  IconVerifiedPromoter,
} from '../../Icons';

import {
  TabHighlightLeftView,
  TabHighlightModeleBanner,
  TabHighlightModeleImage,
  TabHighlightInfoContent,
  TabHomeHighlightSubtitle,
  TabHomeHighlightNameTitle,
  TabHomeHighlightUsernameButton,
  TabHomeHighlightVerifiedIcon,
  TabHomeHighlightUsernameText,
  TabHomeHighlightBookButton,
  TabHomeHighlightBookSeeText,
  TabHomeHighlightBookImagesView,
  TabHomeHighlightBookImage,
  TabHomeHighlightBookLastImage,
} from './styled';



export interface IProps {
  modele: any;
  onPress?: any;
};



const CardModeleDay: React.FC<IProps> = (props) => {
  const modeleDay = props.modele;


  const genderColorChoose = useSelector((state: RootState) => state.choose.genderColorApp);



  return (

    <TabHighlightLeftView
      onClick={props.onPress}>

      <TabHighlightModeleBanner
        title={modeleDay.alias}>
        <TabHighlightModeleImage
          alt={modeleDay.alias}
          title={modeleDay.alias}
          src={
            modeleDay.cover === '' || modeleDay.cover === null
              ? Images.avatarModeleImage
              : modeleDay.cover
          }
        />
      </TabHighlightModeleBanner>



      <TabHighlightInfoContent>

        <TabHomeHighlightSubtitle
          color={genderColorChoose}>
          {('Modelo destaque').toUpperCase()}
        </TabHomeHighlightSubtitle>



        <TabHomeHighlightNameTitle>
          {modeleDay.alias}
        </TabHomeHighlightNameTitle>



        <TabHomeHighlightUsernameButton
          title={`@${modeleDay.useralias}`}>
          <TabHomeHighlightUsernameText>
            @{modeleDay.useralias}
          </TabHomeHighlightUsernameText>


          {modeleDay.verified && (
            <TabHomeHighlightVerifiedIcon>
              <IconVerifiedPromoter
                color={Colors.white}
                size={12}
              />
            </TabHomeHighlightVerifiedIcon>
          )}
        </TabHomeHighlightUsernameButton>



        <TabHomeHighlightBookSeeText
          color={genderColorChoose}>
          Confira todas as fotos
        </TabHomeHighlightBookSeeText>



        <TabHomeHighlightBookButton
          title={modeleDay.alias}>

          <TabHomeHighlightBookImagesView>
            <TabHomeHighlightBookImage src={Images.avatarUserImage} title={modeleDay.alias} />
            <TabHomeHighlightBookImage src={Images.avatarUserImage} title={modeleDay.alias} />
            <TabHomeHighlightBookImage src={Images.avatarUserImage} title={modeleDay.alias} />
            <TabHomeHighlightBookLastImage src={Images.avatarUserImage} title={modeleDay.alias} />
          </TabHomeHighlightBookImagesView>

        </TabHomeHighlightBookButton>

      </TabHighlightInfoContent>

    </TabHighlightLeftView>

  );
};



export default CardModeleDay;
