export const CONTACT = {
  whatsAppNumber:                   '5571992742125',
  whatsAppMessage:                  'Olá! Eu gostaria de mais informações sobre o Secretio.app. Poderia me ajudar?',
  whatsAppProfileMessage:           'Olá, vi esse anúncio no Secretio',
  whatsAppProfileNormalMessage:     'Olá, vi seu anúncio no Secretio.app, tudo bem?',
  whatsAppProfileHighlightMessage:  'Olá, Vi no Secretio.app que você está livre agora. Eu também estou! Podemos marcar algo?',
  whatsAppBeModeleMessage:          'Olá! gostaria de ser modelo no Secretio. O que preciso enviar?',
  whatsAppWantMyProfileMessage:     'Olá! gostaria de ter acesso a um perfil publicado no Secretio. O que preciso fazer?',
};


export const SOCIAL = {
  facebookUsername:                 'secretio',
  facebookIDUsername:               '636260263204734',
  instagramUsername:                'secretio',
  twitterUsername:                  'secretioBR',
  twitterIDUsername:                '837020906467766272',
  youtubeChannel:                   'UCymPVkRbb1sj2UPQs_YVqGw',
};


// App Stores Values
export const STORES = {
  secretioAppPackage:               'com.secretio',
  secretioAppStoreID:               '1455081329',
};



const Keys = {
  ...CONTACT,
  ...SOCIAL,
  ...STORES,
};



export default Keys;
