import React from 'react';

// import { Container } from './styles';

const AdsRentScreen: React.FC = () => {
  return <div />;
};



export default AdsRentScreen;
