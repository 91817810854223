import styled from 'styled-components';



export const ModeleSponsorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 153px;
  min-width: 153px;
  max-width: 153px;
  height: 232px;
  margin-left: 5px;
  margin-right: 5px;
`;


export const ModeleSponsorsContent = styled.a`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  width: 100%;
  height: 100%;
`;


export const ModeleSponsorsImageBackground = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  z-index: 0;
`;

export const ModeleSponsorsOverlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 2;
`;


export const ModeleSponsorsInfoView = styled.div`
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;
    --swiper-theme-color: #007aff;
    --scroll-y: 329.6000061035156px;
    font-family: Roboto,sans-serif;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.5;
    scrollbar-color: #3d3d3d #1a1a1a;
    color: white;
    list-style: none;
    cursor: pointer;
    box-sizing: inherit;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: 0.75rem;
    z-index: 1;
    text-shadow: rgba(0, 0, 0, 0.7) 1px 1px 1px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0) 70%);

  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  width: 100%;
`;


export const ModeleSponsorsTitle = styled.p`
-webkit-font-smoothing: antialiased;
text-rendering: optimizeLegibility;
text-size-adjust: 100%;
--swiper-theme-color: #007aff;
--scroll-y: 329.6000061035156px;
font-family: Roboto,sans-serif;
scrollbar-color: #3d3d3d #1a1a1a;
color: white;
list-style: none;
cursor: pointer;
box-sizing: inherit;
text-transform: uppercase!important;
background-color: rgb(255, 0, 0);
box-shadow: rgba(0, 0, 0, 0.3) 1px 1px 1px;
padding: 0.125rem 0.25rem;
margin-bottom: 0.25rem;
border-radius: 5px;
width: fit-content;
text-shadow: none;
font-weight: 500;
pointer-events: none;
display: flex;
-webkit-box-align: center;
align-items: center;
font-size: 0.875rem;
line-height: 0.875rem;
`;

export const ModeleSponsorsName = styled.p`
-webkit-font-smoothing: antialiased;
text-rendering: optimizeLegibility;
text-size-adjust: 100%;
--swiper-theme-color: #007aff;
--scroll-y: 329.6000061035156px;
font-family: Roboto,sans-serif;
font-size: 1.25rem!important;
line-height: 1.5;
scrollbar-color: #3d3d3d #1a1a1a;
color: white;
list-style: none;
cursor: pointer;
text-shadow: rgba(0, 0, 0, 0.7) 1px 1px 1px;
box-sizing: inherit;
font-weight: 500!important;
display: flex!important;
align-items: center!important;
`;

export const ModeleSponsorsCity = styled.p`
-webkit-font-smoothing: antialiased;
text-rendering: optimizeLegibility;
text-size-adjust: 100%;
--swiper-theme-color: #007aff;
--scroll-y: 329.6000061035156px;
font-family: Roboto,sans-serif;
font-weight: 400;
line-height: 1.5;
scrollbar-color: #3d3d3d #1a1a1a;
color: white;
list-style: none;
cursor: pointer;
text-shadow: rgba(0, 0, 0, 0.7) 1px 1px 1px;
box-sizing: inherit;
font-size: 1em;
`;
