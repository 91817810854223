import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
} from 'react-redux';

import CardFanClubAdd from '../../../../components/Cards/CardFanClubAdd';
import CardSuggestionMedium from '../../../../components/Cards/CardSuggestions/CardSuggestionMedium';
import SectionTitle from '../../../../components/Titles/SectionTitle';

import {
  RootState,
} from '../../../../redux';

import {
  TranslateConfig,
} from '../../../../shared/translations';

import {
  ContentRightProfile,
  WrapperItemContent,
  DetailsOnlineList,
} from './styled';



export interface IProps {
  showActions?: boolean;
  modele?: any;
  suggestions?: Array<any>;
}



const RightContentUser: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  const modeleReceived = props.modele;
  const suggestionsArray = props.suggestions;
  const showActions = props.showActions;


  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);


  function renderItem(item: any, index: number) {
    return (

      <CardSuggestionMedium
        key={index}
        username={item?.useralias}
        name={item?.alias}
        image={item?.picture}
        verified={item?.verified}
        promoter={item?.promoter}
        onPress={() => {
          // TODO
        }}
      />

    );
  }



  return (

    <ContentRightProfile>

      {projectParams?.canAccess && projectParams?.showFanClub && !showActions && (
        <WrapperItemContent>
          <CardFanClubAdd
            disabled={!modeleReceived?.params?.fanClub}
            alias={modeleReceived?.alias}
          />
        </WrapperItemContent>
      )}



      {projectParams?.showFanClub && suggestionsArray?.length > 0 && (
        <WrapperItemContent>
          <SectionTitle
            title={translate(TranslateConfig.PROFILES_SUGGESTED)}
          />


          <DetailsOnlineList>
            {suggestionsArray?.map((item, index) => (
              renderItem(item, index)
            ))}
          </DetailsOnlineList>

        </WrapperItemContent>
      )}



      {/* TODO: ASSINE O PREMIUM / FAÇA UPGRADE */}

    </ContentRightProfile>

  );
};



export default RightContentUser;
