import React from 'react';


import {
  Content,
} from '../../../../components/Composh/web';

import FooterApp from '../../../../components/Footers/FooterApp';
import {
  Images,
  Videos,
} from '../../../../shared/constants';
import ButtonYearly from './ButtonYearly';
import SectionYearly1 from './SectionYearly1';

import {
  FotyHeaderContainer,
  FotyHeaderContent,
  FotyLogo,
  FotyVideo,
  FotyInfoTextHeaderView,
  FotyHeaderTitle,
  FotyHeaderText,
  FotyContent,
  FotyInfoViewContents,
  FotyOverall,
  Fotybgbluebig,
  Fotybgbluesmall,
  Fotycontestmaincontent,
  Fotycontesttopcontent,
  FotycontesectionTile,
  FotysectionText,
  FotycontestprizeVIEW,
  Fotycontesttitleprize,
  Fotycontestprizeamount,
  Fotymainbadge,
  Fotycontestmainwinners,
  Fotytopgrid,
  Fotytopmodel,
  FotytopmodelMIN,
  Fotytopinfo,
  FotytopplaceBadge,
  Fotytopplacenum,
  Fotytopplacesuffix,
  Fotytoplabel,
  Fotytopname,
  Fotytopprize,
  FotyMinRankModel,
  FotyMinRankPlace,
  FotyMinRankNumber,
  FotyMinRankSuffix,
  FotyMinRankInfo,
  FotyMinRankName,
  FotyMinRankPrize,
} from './styled';

import TitleYearly from './TitleYearly';



const ScreenYearlyCrush1: React.FC = () => {
  return (

    <Content
      style={{
        backgroundColor: '#070D15',
      }}>

      <FotyHeaderContainer>
        <FotyHeaderContent>

          <FotyLogo
            src={Images.fotyLogo}
          />


          <p style={{ color: 'white' }}>
            2022
          </p>


          <FotyVideo
            id="FOTYintroVideo"
          // controls=""
          // autoPlay="autoplay"
          // playsinline="playsinline"
          // webkit-playsinline="webkit-playsinline"
          // muted=""
          >
            <source src={Videos.yearlyGuysV2} />
          </FotyVideo>


          <FotyInfoTextHeaderView>
            <FotyHeaderTitle>
              Where the Stars of Camming Collide
            </FotyHeaderTitle>

            <FotyHeaderText>
              This is the greatest competition in camming. Dozens of categories feature a fortune in prizes. The race for <em>Flirt of the Year</em> glory begins at midnight, October 1st, 2022. Qualified models will be ranked based either on
              year-to-date credits earned, total credits earned from <em>Oct. 1st through Dec. 31st, 2022</em>, total monthly credits, or total Viewers Choice Votes and Super Votes, depending on the contest.
            </FotyHeaderText>
          </FotyInfoTextHeaderView>

        </FotyHeaderContent>
      </FotyHeaderContainer>



      <FotyContent>
        <FotyInfoViewContents>

          <FotyOverall>
            <Fotybgbluebig />
            <Fotybgbluesmall />


            <Fotycontestmaincontent>
              <Fotycontesttopcontent>

                <FotycontesectionTile>
                  <TitleYearly
                    title={'Geral'}
                  />

                  <FotysectionText>
                    A corrida para Mister Flirt of the Year 2022 testa a beleza, dedicação, inteligência e sensualidade de uma modelo durante todo o ano.
                  </FotysectionText>
                </FotycontesectionTile>


                <FotycontestprizeVIEW>
                  <Fotycontesttitleprize>
                    Prêmio em dinheiro total
                  </Fotycontesttitleprize>

                  <Fotycontestprizeamount>
                    $ 11.650
                  </Fotycontestprizeamount>

                  <Fotymainbadge
                    src={Images.badgeWinnerGuy}
                  />
                </FotycontestprizeVIEW>

              </Fotycontesttopcontent>
            </Fotycontestmaincontent>



            <Fotycontestmainwinners>
              <Fotytopgrid>

                <FotytopmodelMIN href="#">
                  <Fotytopinfo>

                    <FotytopplaceBadge
                      image={Images.badge2nd}>
                      <Fotytopplacenum>
                        2
                      </Fotytopplacenum>
                      <Fotytopplacesuffix>
                        nd
                      </Fotytopplacesuffix>
                    </FotytopplaceBadge>


                    <Fotytoplabel>
                      <Fotytopname>
                        Paulo Morissette
                      </Fotytopname>
                      <Fotytopprize>
                        $ 2.000
                      </Fotytopprize>
                    </Fotytoplabel>

                  </Fotytopinfo>
                </FotytopmodelMIN>



                <Fotytopmodel href="#">
                  <Fotytopinfo>

                    <FotytopplaceBadge
                      image={Images.badge1st}>
                      <Fotytopplacenum>
                        1
                      </Fotytopplacenum>
                      <Fotytopplacesuffix>
                        st
                      </Fotytopplacesuffix>
                    </FotytopplaceBadge>


                    <Fotytoplabel>
                      <Fotytopname>
                        Killian &amp; Equipe
                      </Fotytopname>
                      <Fotytopprize>
                        $ 1.500
                      </Fotytopprize>
                    </Fotytoplabel>

                  </Fotytopinfo>
                </Fotytopmodel>



                <FotytopmodelMIN href="#">
                  <Fotytopinfo>

                    <FotytopplaceBadge
                      image={Images.badge3rd}>
                      <Fotytopplacenum>
                        3
                      </Fotytopplacenum>
                      <Fotytopplacesuffix>
                        rd
                      </Fotytopplacesuffix>
                    </FotytopplaceBadge>


                    <Fotytoplabel>
                      <Fotytopname>
                        Izzi
                      </Fotytopname>
                      <Fotytopprize>
                        $ 1.000
                      </Fotytopprize>
                    </Fotytoplabel>
                  </Fotytopinfo>

                </FotytopmodelMIN>
              </Fotytopgrid>



              <FotyMinRankModel href="#">
                <FotyMinRankPlace>
                  <FotyMinRankNumber>
                    4
                  </FotyMinRankNumber>

                  <FotyMinRankSuffix>
                    th
                  </FotyMinRankSuffix>
                </FotyMinRankPlace>

                <FotyMinRankInfo>
                  <FotyMinRankName>Jaden Storm</FotyMinRankName>
                  <FotyMinRankPrize>
                    $850
                  </FotyMinRankPrize>
                </FotyMinRankInfo>
              </FotyMinRankModel>



              <ButtonYearly
                title={'View Top 100'}
                href="#"
              />

            </Fotycontestmainwinners>
          </FotyOverall>



          <SectionYearly1
            id={'credit'}
            position={'left'}
            badge={Images.badgecontestcredit}
            image={Images.fotyGuysCreditLevel}
            title={'CREDIT LEVEL'}
            text={'O nível de crédito de um modelo é baseado em seus ganhos vitalícios. Aqui, eles competirão para serem reconhecidos como os melhores entre seus pares, da Primeira Base ao Superastro, até a Lenda da Webcam.'}
            prize={'$4,300'}
            buttonText={'VIEW RANKINGS'}
          />



          <SectionYearly1
            id={'category'}
            position={'right'}
            badge={Images.badgecontestcategories}
            image={Images.fotyGuysCategories}
            title={'CATEGORIES'}
            text={'Video kings, kink specialists, top rookies, and explosive duos. Here our guys compete in the categories that suit their strengths.'}
            prize={'$13,000'}
            buttonText={'VIEW RANKINGS'}
          />



          <SectionYearly1
            id={'regional'}
            position={'left'}
            badge={Images.badgecontestcregional}
            image={Images.fotyGuysRegional}
            title={'REGIONAL'}
            text={'The Regional competition is a 3-month showdown to crown the most dominant performers from North America, South America, Europe, Russia, and Romania.'}
            prize={'$13,250'}
            buttonText={'VIEW RANKINGS'}
          />



          <SectionYearly1
            id={'monthly'}
            position={'right'}
            badge={Images.badgecontestmonthly}
            image={Images.fotyGuysMonthly}
            title={'MONTHLY'}
            text={'On his way to a massive Flirt of the Year victory, your top contender can win huge cash prizes during all 3 contest months.'}
            prize={'$5,250'}
            buttonText={'VIEW RANKINGS'}
          />

        </FotyInfoViewContents>

      </FotyContent>



      <FooterApp />

    </Content>


  );
};



export default ScreenYearlyCrush1;
