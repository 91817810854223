import {
  EEnvsName,
} from '../../enums';

import DEV_ENV from './environment.dev';
import LOCAL_ENV from './environment.local';
import PROD_ENV from './environment.prod';



const AppEnvironments = (envInsert?: EEnvsName) => {
  const env = envInsert || process.env.REACT_APP_NODE_ENV;

  if (!env) {
    return LOCAL_ENV;
  }
  switch (env) {
    case EEnvsName.DEV: return DEV_ENV;
    case EEnvsName.PROD: return PROD_ENV;
    default: return LOCAL_ENV;
  }
};



export default AppEnvironments;
