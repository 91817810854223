const UserModel = {
  id: '',
  alias: '',
  publicName: '',
  useralias: '',
  email: '',
  client_number: '',
  cpf: '',
  birthday: '',
  gender: null,
  ddi: null,
  phone: {
    ddi: '',
    number: '',
  },
  userInfo: {
    level: '',
    verified: false,
    aboutMe: '',
  },
  address: null,

  companys: [],
  info: null,
  login: '',
  oldPassword: null,
  password: '',
  picture: null,
  report: null,
  verified: false,
};



export default UserModel;
