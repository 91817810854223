import React from 'react';

import {
  ViewSeparator,
} from './styled';



export interface IProps {
  option?: string;
  style?: any;
}



const Separators: React.FC<IProps> = (props: any) => {
  return (

    <ViewSeparator />

  );
};



export default Separators;
