import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';



export interface IProps {
  color?: string;
}



export const TabHighlightLeftView = styled.a`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
  height: ${Sizes.contentRentHeight}px;
  margin-right: ${Sizes.contentMargin}px;
  padding: 10px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;

  @media ${PlatformDevices.maxTabletM} {
    flex-direction: column-reverse;
    width: 100%;
    align-items: flex-start;
  }
`;

export const TabHighlightModeleBanner = styled.div`
  height: 100%;
  aspect-ratio: 9/16;
  background-color: ${Colors.black};

  @media ${PlatformDevices.maxTabletM} {
    display: none;
  }
`;

export const TabHighlightModeleImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
  box-shadow: 2px 2px 30px 0px rgba(0, 0, 0, 0.35);
`;



export const TabHighlightInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;

  @media ${PlatformDevices.maxTabletM} {
    align-items: flex-start;
  }
`;

export const TabHomeHighlightSubtitle = styled.h3`
  margin-top: 10px;
  margin-bottom: 5px;
  color: ${(props: IProps) => props.color};
  font-weight: 900;
  font-size: 13px;
  text-align: right;

  @media ${PlatformDevices.maxTabletM} {
    text-align: left;
  }
`;

export const TabHomeHighlightNameTitle = styled.h2`
  margin: 0;
  color: ${Colors.white};
  font-weight: 900;
  font-size: 40px;
  line-height: 44px;
  text-align: right;

  @media ${PlatformDevices.maxTabletM} {
    text-align: left;
  }
`;

export const TabHomeHighlightUsernameButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
`;

export const TabHomeHighlightUsernameText = styled.p`
  color: ${Colors.white};
  font-size: 14px;
  font-weight: 500;
  text-align: right;

  @media ${PlatformDevices.maxTabletM} {
    text-align: left;
  }
`;

export const TabHomeHighlightVerifiedIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-left: 2px;
`;

export const TabHomeHighlightBookSeeText = styled.p`
  margin-top: 15px;
  color: ${Colors.white};
  font-size: 14px;
  font-weight: 600;
  text-align: right;
  transition: all .2s ease-out;

  @media ${PlatformDevices.maxTabletM} {
    text-align: left;
  }

  :hover {
    color: ${(props: IProps) => props.color};
  }
`;


export const TabHomeHighlightBookButton = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;

  @media ${PlatformDevices.maxTabletM} {
    margin-top: 20px;
  }
`;

export const TabHomeHighlightBookImagesView = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 0;
  bottom: 10px;

  @media ${PlatformDevices.maxTabletM} {
    position: relative;
    right: auto;
    bottom: auto;
  }
`;

export const TabHomeHighlightBookImage = styled.img`
  height: 175px;
  margin-left: 4px;
  aspect-ratio: 9/16;
  box-shadow: 2px 2px 30px 0px rgba(0, 0, 0, 0.35);
  object-fit: cover;
  object-position: top center;
`;


export const TabHomeHighlightBookLastImage = styled(TabHomeHighlightBookImage)`
  @media ${PlatformDevices.maxMobileL} {
    display: none;
  }
`;
