import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';



export const NotificationContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 200px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 16px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;

export const NotificationColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const NotificationHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const NotificationImageContainer = styled.a`
  display: flex;
  flex-direction: column;
`;



export const NotificationInfoView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  margin-left: 15px;
`;

export const NotificationInfoTitleView = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NotificationInfoTitle = styled.h1`
  color: ${Colors.white};
  font-size: 22px;
  
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  word-break: break-word;
  text-overflow: ellipsis;
`;

export const NotificationInfoUsernameView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3px;
`;

export const NotificationInfoUsername = styled.p`
  color: ${Colors.white};
  font-size: 17px;
`;

export const NotificationInfoIcon = styled.a`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;


export const NotificationText = styled.p`
  margin-top: 11px;
  margin-bottom: 10px;
  color: ${Colors.textDescription};
  font-size: 16px;
  line-height: 20px;
  
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  word-break: break-word;
  text-overflow: ellipsis;
`;



export const NotificationActionContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: flex-end;
  margin-top: 5px;
  margin-bottom: 10px;
`;

export const NotificationActionTextButton = styled.a`
  display: flex;
  flex-direction: row;
  min-width: 78px;
  padding: 6px 16px;
  background-color: rgba(138,150,163,.12);
  border-radius: 100px;
`;

export const NotificationActionText = styled.p`
  color: ${Colors.white};
  font-size: 14px;
  text-align: center;
`;



export const NotificationDateText = styled.p`
  color: ${Colors.gray};
  font-size: 14px;
  line-height: 20px;
`;



export const NotificationImageActionView = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  aspect-ratio: 1;

  @media ${PlatformDevices.maxTabletM} {
    display: none;
  }
`;

export const NotificationActionImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${Sizes.cardRadius}px;
`;
