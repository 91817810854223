import React,
{
  useState,
} from 'react';

import {
  Content,
  Screen,
  Title,
} from '../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../components/Controls';

import FooterApp from '../../../components/Footers/FooterApp';
import HeaderScreen from '../../../components/Headers/HeaderScreen';

import {
  IconAdsPersonalBanner,
  IconAdsModeleBoost,
  IconAdsRentOfDay,
  IconAdsRentSponsor,
} from '../../../components/Icons';

import SectionTitle from '../../../components/Titles/SectionTitle';

import {
  Colors,
} from '../../../shared/constants';

import {
  RentMavrttypeslist,
  RentMavrselection,
  RentMaavcatype,
  RentAdsIcon,
  RentMatypeass,
  AdsModeleContainer,
  AdsPreviewWrapper,
} from './styled';

import TabsModeleBoost from './Tabs/ModeleBoost';
import TabsPersonalBanner from './Tabs/PersonalBanner';
import TabsRentOfDay from './Tabs/RentOfDay';
import TabsRentSponsor from './Tabs/RentSponsor';



const ModeleAdsScreen: React.FC = () => {
  const [stateIndex, setStateIndex] = useState(0);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        disableGender
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            color={Colors.white}
            size={27}
          />
        }
        centerContent={
          <Title>
            Anúncios
          </Title>
        }
      />



      <Content>

        <AdsModeleContainer>

          <RentMavrttypeslist>

            <SectionTitle
              title={'Confira mais exemplos de anúncios'}
              color={Colors.primary}
            />


            <RentMavrselection>

              <RentMaavcatype
                onClick={() => {
                  setStateIndex(0);
                }}>

                <RentAdsIcon>
                  <IconAdsRentOfDay
                    color={Colors.white}
                    size={26}
                  />
                </RentAdsIcon>

                <RentMatypeass>
                  Acompanhante do dia
                </RentMatypeass>

              </RentMaavcatype>



              <RentMaavcatype
                onClick={() => {
                  setStateIndex(1);
                }}>

                <RentAdsIcon>
                  <IconAdsModeleBoost
                    color={Colors.white}
                    size={26}
                  />
                </RentAdsIcon>

                <RentMatypeass>
                  Secretio Boost
                </RentMatypeass>

              </RentMaavcatype>


              <RentMaavcatype
                onClick={() => {
                  setStateIndex(2);
                }}>

                <RentAdsIcon>
                  <IconAdsPersonalBanner
                    color={Colors.white}
                    size={26}
                  />
                </RentAdsIcon>

                <RentMatypeass>
                  Personal Banner
                </RentMatypeass>

              </RentMaavcatype>


              <RentMaavcatype
                onClick={() => {
                  setStateIndex(3);
                }}>

                <RentAdsIcon>
                  <IconAdsRentSponsor
                    color={Colors.white}
                    size={26}
                  />
                </RentAdsIcon>

                <RentMatypeass>
                  Secretio Sponsor
                </RentMatypeass>

              </RentMaavcatype>

            </RentMavrselection>
          </RentMavrttypeslist>



          <AdsPreviewWrapper>

            {stateIndex === 0 && (
              <TabsRentOfDay />
            )}

            {stateIndex === 1 && (
              <TabsModeleBoost />
            )}

            {stateIndex === 2 && (
              <TabsPersonalBanner />
            )}

            {stateIndex === 3 && (
              <TabsRentSponsor />
            )}

          </AdsPreviewWrapper>

        </AdsModeleContainer>



        <FooterApp />

      </Content>

    </Screen>

  );
};



export default ModeleAdsScreen;
