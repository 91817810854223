import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';


export const HeaderLangsContent = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-width: 145px;
  min-height: 25px;
  max-height: 25px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 8px;
  padding-right: 8px;
  background-color: ${Colors.inputCard};
  border-radius: 5px;
`;

export const HeaderLangsImage = styled.img`
  width: 19px;
  margin-left: 2px;
  margin-right: 7px;
`;

export const HeaderLangsText = styled.p`
  color: ${Colors.white};
  font-size: 11px;
`;
