export const DRAWER_ROUTES = {
  MyPriveScreen:                    '/my-prive',
  RentHomeScreen:                   '/rent',
  LiveHomeScreen:                   '/live',
  AllModeleScreen:                  '/all-modele',

  DashboardModeleScreen:            '/dashboard',
  FavoriteScreen:                   '/favorites',
  NotificationsScreen:              '/notifications',
  MessagesScreen:                   '/messages',

  PanelModeleScreen:                '/modele/panel',
  FinancialScreen:                  '/modele/financial',
  CalendarScreen:                   '/modele/calendar',

  SettingsAppScreen:                '/settings',
  HelpFaqScreen:                    '/help',
};


export const APP_ROUTES = {
  HomeScreen:                       '/',

  AppDownScreen:                    '/down',
  Error404Screen:                   '/404',

  AdsLiveScreen:                    '/ads-live',
  AdsRegisterBothScreen:            '/ads-register',
  AdsRentScreen:                    '/ads-rent',
  
  AccessScreen:                     '/login',

  AllSponsorsScreen:                '/all-sponsors',

  RentHowAdsScreen:                 '/rent/about',
  RentModeleScreen:                 '/rent-model/:id',
  
  LiveRoomCamScreen:                '/room/:id',
  
  CreateModeleFormScreen:           '/users/main-edit',
  CharactersProfileScreen:          '/users/edit',
  
  UserImageProfileScreen:           '/profile/images',
  BlockedScreen:                    '/profile/blocked',
  BuyMediasScreen:                  '/profile/buy-medias',
  MediaLikedScreen:                 '/profile/media-liked',
  CommentDoneScreen:                '/profile/reviews',
  FanClubPayedScreen:               '/profile/people-club',
  MyPaymentsScreen:                 '/profile/payments-history',
  BeModeleScreen:                   '/profile/be-model',

  UpgradeUserShowScreen:            '/user-upgrade',
  UpgradeUserDoScreen:              '/user-upgrade/details',

  ContactsModeleScreen:             '/modele/contacts',
  LinkBioDashboardScreen:           '/modele/link-bio',
  RentProfileModeleScreen:          '/modele/rent-edit',
  PeopleClubListScreen:             '/modele/fan-club',
  ModeleLiveOptionsScreen:          '/modele/lives-edit',
  ModeleLiveOpenScreen:             '/modele/lives-edit/open',
  UpgradeModeleShowScreen:          '/modele/upgrade',
  ModelePlanManageScreen:           '/modele/plan-manage',
  RentChangeLevelScreen:            '/modele/rent-level',
  RentPayAttentionScreen:           '/modele/rent-attention',
  ModeleDataProfileScreen:          '/modele/modele-data',
  ModeleLocationFormSection:        '/modele/location',
  ModeleTravelSeeScreen:            '/modele/travel',
  ModeleMediaAddScreen:             '/modele/medias',
  AntiFakeVerifiedScreen:           '/modele/verified-media',
  ModelePrivateGalleryScreen:       '/modele/private-gallery-access',
  ReviewModeleScreen:               '/modele/reviews',
  VisibilityModeleScreen:           '/modele/visibility-profile',
  ModeleAdsScreen:                  '/modele/ads',

  FinancialAffiliatesScreen:        '/modele/financial/affiliates',
  FinancialMovimentsScreen:         '/modele/financial/moviments',

  RankingsScreen:                   '/rankings',
  SuggestionScreen:                 '/suggestions',

  AddMoneyScreen:                   '/add-money',

  FaqCategoryScreen:                '/help/faq/category',
  FaqBulletScreen:                  '/help/faq/bullet',
  
  AboutScreen:                      '/about',
  TermsPolicyScreen:                '/settings/terms-policy',

  WebViewScreen:                    '/webview',

  ViewLinkBioScreen:                '/linkbio/:id',

  DoCommentModeleScreen:            '/:id/review',

  ModeleDetailsScreen:              '/:id',
};



const NameRoutes = {
  ...DRAWER_ROUTES,
  ...APP_ROUTES,
};



export default NameRoutes;
