import React from 'react';

import {
  useSelector,
} from 'react-redux';

import ButtonAction from '../../components/Buttons/ButtonAction';

import {
  Overlay,
} from '../../components/Composh/web';

import HeaderModal from '../../components/Headers/HeaderModal';

import {
  RootState,
} from '../../redux';

import {
  Colors,
  Images,
} from '../../shared/constants';

import {
  FaClubDetailContainer,
  AddCardContent,
  AddACardAsideContent,
  FanclubModalBackground,
  FanclubInformationsModal,
  FaClubAvaterView,
  FaClubAvatarContent,
  FaClubAvatarImage,
  FaClubAcessDescription,
  FaClubAcessName,
  FaClubBoxMain,
  FaClubFeatDetaisButtonView,
  FaClubTuoleBoxMain,
  FaClubOfferJoinBubble,
  FaClubOfferBubbleAvatar,
  FaClubOfferAvatarImage,
  FaClubOfferTextView,
  FaClubOfferText,
  FaClubFeatDetaisView,
  FaClubFeatDetaisItemView,
  FaClubOfferJoinContent,
  FaClubOfferJoinNormalPrice,
  FaClubPackageContent,
  FaClubPackageButtonView,
} from './styled';



export interface IProps {
  show?: boolean;
  onClose?: any;
}



const FaClubModal: React.FC<IProps> = (props) => {
  const genderColorChoose = useSelector((state: RootState) => state.choose.genderColorApp);
  const colorAccent = genderColorChoose;

  const imageModele = Images.avatarModeleImage;



  return (

    <Overlay
      visible={props.show}
      overlayColor={Colors.alertOverlay}
      onOverlayPress={props.onClose}>

      <FaClubDetailContainer>

        <HeaderModal
          title={'Assinar Fã Clube'}
          onPress={props.onClose}
        />



        <AddCardContent>

          <AddACardAsideContent>

            <FanclubModalBackground
              src={imageModele}
            />


            <FanclubInformationsModal>

              <FaClubAvaterView>
                <FaClubAvatarContent>
                  <FaClubAvatarImage
                    alt=""
                    src={imageModele}
                  />
                </FaClubAvatarContent>
              </FaClubAvaterView>


              <FaClubAcessDescription>
                Você esta adquirindo acesso ao FanClub de
              </FaClubAcessDescription>

              <FaClubAcessName>
                Colld
              </FaClubAcessName>
            </FanclubInformationsModal>

          </AddACardAsideContent>



          <FaClubBoxMain>

            <FaClubFeatDetaisButtonView>

              <FaClubTuoleBoxMain>
                Oferta limitada: 55% de desconto nos primeiros 31&nbsp;dias!
              </FaClubTuoleBoxMain>


              <FaClubOfferJoinBubble>

                <FaClubOfferBubbleAvatar>
                  <FaClubOfferAvatarImage
                    alt={''}
                    src={imageModele}
                  />
                </FaClubOfferBubbleAvatar>


                <FaClubOfferTextView>
                  <FaClubOfferText>
                    Im so so so so back with the biggest discount ever just 4$ for over 500 explicit videos
                  </FaClubOfferText>
                </FaClubOfferTextView>

              </FaClubOfferJoinBubble>



              <FaClubFeatDetaisView>
                {[
                  'Mensagem direta com este usuário e ver o status dele',
                  'Para sua comodidade, a assinatura é renovada automaticamente.',
                  'Você poderá cancelar a renovação automática a qualquer momento.',
                ].map((text: string, index: number) => (
                  <FaClubFeatDetaisItemView
                    key={index}>
                    {text}
                  </FaClubFeatDetaisItemView>
                ))}
              </FaClubFeatDetaisView>



              <FaClubOfferJoinContent>
                <ButtonAction
                  width={'100%'}
                  title={'Assinar 31 dias por $4.50'}
                  backgroundColor={colorAccent}
                />


                <FaClubOfferJoinNormalPrice>
                  Preço Normal $9.99 /mês
                </FaClubOfferJoinNormalPrice>
              </FaClubOfferJoinContent>
            </FaClubFeatDetaisButtonView>



            <FaClubPackageContent>

              <FaClubTuoleBoxMain>
                Pacotes de assinatura
              </FaClubTuoleBoxMain>


              <FaClubPackageButtonView>
                <ButtonAction
                  width={'100%'}
                  title={'Assinar 3 meses por $23.98 (20% off)'}
                  backgroundColor={colorAccent}
                />
              </FaClubPackageButtonView>


              <FaClubPackageButtonView>
                <ButtonAction
                  width={'100%'}
                  title={'Assinar 6 meses por $29.97 (50% off)'}
                  backgroundColor={colorAccent}
                />
              </FaClubPackageButtonView>

            </FaClubPackageContent>

          </FaClubBoxMain>

        </AddCardContent>

      </FaClubDetailContainer>

    </Overlay>

  );
};



export default FaClubModal;
