import React from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  RootState,
} from '../../../redux';

import {
  WrapperItemTitleView,
  BeforeTitleView,
  WrapperItemTitleTextView,
  WrapperItemTitle,
  WrapperItemSubtitle,
} from './styled';



export interface IProps {
  title: any;
  subtitle?: string;
  color?: any;
}



const SectionTitle: React.FC<IProps> = (props: IProps) => {
  const className = { ...props } as any;


  const genderColorChoose = useSelector((state: RootState) => state.choose.genderColorApp);



  return (

    <WrapperItemTitleView
      className={className?.className}>

      <BeforeTitleView
        color={props.color || genderColorChoose}
      />


      <WrapperItemTitleTextView>

        {typeof props.title === 'string'
          ? (
            <WrapperItemTitle>
              {props.title}
            </WrapperItemTitle>
          )
          : props.title
        }


        {props.subtitle && (
          <WrapperItemSubtitle>
            {props.subtitle}
          </WrapperItemSubtitle>
        )}

      </WrapperItemTitleTextView>

    </WrapperItemTitleView>

  );
};



export default SectionTitle;
