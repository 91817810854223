import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';



export const HomeIdentiyContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-top: 20px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 20px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;

export const HomeIdentiyBanner = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 85px;
  margin-top: -20px;
  margin-left: -20px;
  margin-right: -20px;
  background: ${Colors.primary};
`;

export const HomeIdentiyImageBanner = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const HomeIdentiyOVerlay = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${Colors.cardOverlay};
`;



export const HomeIdentiyuserButton = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const HomeIdentiyAvatarImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -38px;
`;

export const HomeIdentiyAvatarInfoView = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  padding-top: 4px;
  padding-bottom: 3px;
`;

export const HomeIdentiyViewUsername = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

export const HomeIdentiyUsername = styled.p`
  color: ${Colors.white};
  font-size: 16px;
  font-weight: 600;
  text-align: center;
`;

export const HomeIdentiyIcon = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 8px;
`;

export const HomeIdentiyFullName = styled.p`
  margin-top: 4px;
  color: ${Colors.textApp};
  font-size: 13px;
  font-weight: 600;
`;

export const HomeIdentiyDescription = styled.p`
  margin-top: 10px;
  color: ${Colors.white};
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
`;
