import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
} from 'react-redux';

import {
  useNavigate,
} from 'react-router-dom';

import {
  IconCheck,
  IconClose,
} from '../../../../components/Icons';

import NameRoutes from '../../../../navigation/names';

import {
  RootState,
} from '../../../../redux';

import {
  Colors,
} from '../../../../shared/constants';

import Vars from '../../../../shared/environments/variables';

import {
  TranslateConfig,
} from '../../../../shared/translations';

import {
  VideoContentRightHome,
  WarningApproveText,
  WrapperItemContent,
  ApproveSplitView,
  ApproveSplitIconView,
  ApproveSplitText,
  ApproveItemInfoView,
  ApproveItemSplitText,
  ApproveSubItemSplitText,
  ApproveSpanSplitText,
} from './styled';



const RightImageInfo: React.FC = () => {
  const history = useNavigate();
  const { t: translate } = useTranslation();


  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);



  return (

    <VideoContentRightHome>

      <WarningApproveText>
        Siga estas regras para acelerar o processo de aprovação.
      </WarningApproveText>



      <WrapperItemContent>

        <ApproveSplitView>
          <ApproveSplitIconView>
            <IconCheck
              color={Colors.black}
              size={20}
            />
          </ApproveSplitIconView>


          <ApproveSplitText>
            Aceito
          </ApproveSplitText>
        </ApproveSplitView>



        <ApproveItemInfoView>
          <ApproveItemSplitText>
            Fotos nítidas e de alta qualidade suas.
          </ApproveItemSplitText>

          <ApproveItemSplitText>
            Aproxime-se do espelho e esconda sua escova de dentes ao fazer selfies no banheiro.
          </ApproveItemSplitText>
        </ApproveItemInfoView>

      </WrapperItemContent>



      <WrapperItemContent>

        <ApproveSplitView>
          <ApproveSplitIconView>
            <IconClose
              color={Colors.black}
              size={20}
            />
          </ApproveSplitIconView>


          <ApproveSplitText>
            Não aceito
          </ApproveSplitText>
        </ApproveSplitView>



        <ApproveItemInfoView>
          <ApproveItemSplitText>
            Fotos falsas ou que não sejam suas
          </ApproveItemSplitText>

          <ApproveItemSplitText>
            Fotos hardcore como foto pública.
          </ApproveItemSplitText>

          {projectParams?.showFanClub && (
            <ApproveSubItemSplitText>
              Você pode adicionar essas imagens à sua galeria Privada ou do Fã-clube.
            </ApproveSubItemSplitText>
          )}

          <ApproveItemSplitText>
            Retoque excessivo do Photoshop, texto adicionado, números de telefone, marcas d`água, bandeiras de cartões, colagens ou bordas.
          </ApproveItemSplitText>

          <ApproveItemSplitText>
            Fotos protegidas por direitos autorais, a menos que um consentimento por escrito e assinado do proprietário dos direitos autorais nos seja enviado.
            {' '}
            <ApproveSpanSplitText
              onClick={() => {
                history(
                  NameRoutes.WebViewScreen,
                  {
                    state: {
                      title: translate(TranslateConfig.CONTACT),
                      link: Vars().secretioContact,
                    },
                  },
                );
              }}>
              Contate-nos aqui.
            </ApproveSpanSplitText>
          </ApproveItemSplitText>

          <ApproveItemSplitText>
            Fotos que retratam álcool, drogas, armas, fezes, sangue, tortura, notas ou outra violência.
          </ApproveItemSplitText>
        </ApproveItemInfoView>

      </WrapperItemContent>

    </VideoContentRightHome>

  );
};



export default RightImageInfo;
