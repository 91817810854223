import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  Formik,
  FormikProps,
} from 'formik';

import {
  EditCharUser,
} from '../../../apis/endpoints/users.endpoints';

import {
  IInputSelect,
} from '../../../apis/interfaces/App/input-select.interface';

import ButtonAction from '../../../components/Buttons/ButtonAction';
import ChipApp from '../../../components/Chip';

import {
  Container,
  Content,
  Screen,
  Title,
} from '../../../components/Composh/web';

import {
  ContentButtonForm,
  ContentPadder,
} from '../../../components/Contents/ContentPadder';

import {
  ButtonBack,
} from '../../../components/Controls';

import ButtonPanelItem from '../../../components/Details/ButtonPanelItem';
import FooterApp from '../../../components/Footers/FooterApp';
import HeaderScreen from '../../../components/Headers/HeaderScreen';
import InputChip from '../../../components/Inputs/InputChip';
import LoadingScreen from '../../../components/Loadings/LoadingScreen';
import SectionTitle from '../../../components/Titles/SectionTitle';
import DataSelectModal from '../../../modals/DataSelect';

import {
  RootState,
} from '../../../redux';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../redux/reducers/app-modals.store';

import {
  USER_SET,
} from '../../../redux/reducers/user.store';

import { bodyHairArray } from '../../../shared/arrays/User/user-body-hair.array';
import { bodyTypeArray } from '../../../shared/arrays/User/user-body-type.array';
import { bustArray } from '../../../shared/arrays/User/user-bust.array';
import { cockCutsArray } from '../../../shared/arrays/User/user-cock-cuts.array';
import { cockSizesArray } from '../../../shared/arrays/User/user-cock-size.array';
import { ethnicityArray } from '../../../shared/arrays/User/user-ethnicity.array';
import { eyeColorArray } from '../../../shared/arrays/User/user-eye-color.array';
import { feetArray } from '../../../shared/arrays/User/user-feet.array';
import { hairColorArray } from '../../../shared/arrays/User/user-hair-color.array';
import { hairSizeArray } from '../../../shared/arrays/User/user-hair-size.array';
import { hivArray } from '../../../shared/arrays/User/user-health.array';
import { heightsArray } from '../../../shared/arrays/User/user-height.array';
import { hipArray } from '../../../shared/arrays/User/user-hip.array';
import { interestArray } from '../../../shared/arrays/User/user-interest.array';
import { languagesUserArray } from '../../../shared/arrays/User/user-languages.array';
import { mannequinArray } from '../../../shared/arrays/User/user-mannequin.array';
import { orientationsArray } from '../../../shared/arrays/User/user-orientation.array';
import { positionsArray } from '../../../shared/arrays/User/user-positions.array';
import { tribeArray } from '../../../shared/arrays/User/user-tribe.array';
import { waistArray } from '../../../shared/arrays/User/user-waist.array';
import { weightsArray } from '../../../shared/arrays/User/user-weight.array';
import { zodiacArray } from '../../../shared/arrays/User/user-zodiac.array';

import {
  Colors,
  Sizes,
  Values,
} from '../../../shared/constants';

import {
  EGenderUser,
} from '../../../shared/enums';

import {
  TranslateConfig,
} from '../../../shared/translations';

import {
  CalculateAgeByBirthdayString,
} from '../../../shared/utils/date.utils';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../shared/utils/errors.utils';

import CharactersProfileValidationSchema from '../../../shared/validations/User/characters-profile.validations';

import {
  CardCharsForm,
  CharsInputsContainer,
  CharsInputsFirstContainer,
  CharsInputsLastContainer,
  CharsInputsContent,
  InputTextAreaStyled,
  InputTextStyled,
  InputSelectStyled,
  InputBooleanStyled,
} from './styled';



const CharactersProfileScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);


  const formik = useRef<FormikProps<any>>(null);

  const [selectedShowAge, setSelectedShowAge] = useState<boolean>(false);

  const [selectedHeight, setSelectedHeight] = useState<IInputSelect | null>(null);
  const [modalHeight, setModalHeight] = useState<boolean>(false);

  const [selectedWeight, setSelectedWeight] = useState<IInputSelect | null>(null);
  const [modalWeight, setModalWeight] = useState<boolean>(false);

  const [selectedEthnicity, setSelectedEthnicity] = useState<IInputSelect | null>(null);
  const [modalEthnicity, setModalEthnicity] = useState<boolean>(false);

  const [selectedHairSize, setSelectedHairSize] = useState<IInputSelect | null>(null);
  const [modalHairSize, setModalHairSize] = useState<boolean>(false);

  const [selectedHairColor, setSelectedHairColor] = useState<IInputSelect | null>(null);
  const [modalHairColor, setModalHairColor] = useState<boolean>(false);

  const [selectedEyes, setSelectedEyes] = useState<IInputSelect | null>(null);
  const [modalEyes, setModalEyes] = useState<boolean>(false);

  const [selectedMannequin, setSelectedMannequin] = useState<IInputSelect | null>(null);
  const [modalMannequin, setModalMannequin] = useState<boolean>(false);

  const [selectedBust, setSelectedBust] = useState<IInputSelect | null>(null);
  const [modalBust, setModalBust] = useState<boolean>(false);

  const [selectedWaist, setSelectedWaist] = useState<IInputSelect | null>(null);
  const [modalWaist, setModalWaist] = useState<boolean>(false);

  const [selectedHip, setSelectedHip] = useState<IInputSelect | null>(null);
  const [modalHip, setModalHip] = useState<boolean>(false);

  const [selectedFeet, setSelectedFeet] = useState<IInputSelect | null>(null);
  const [modalFeet, setModalFeet] = useState<boolean>(false);

  const [selectedCockSize, setSelectedCockSize] = useState<IInputSelect | null>(null);
  const [modalCockSize, setModalCockSize] = useState<boolean>(false);

  const [selectedPosition, setSelectedPosition] = useState<IInputSelect | null>(null);
  const [modalPosition, setModalPosition] = useState<boolean>(false);

  const [selectedBody, setSelectedBody] = useState<IInputSelect | null>(null);
  const [modalBody, setModalBody] = useState<boolean>(false);

  const [selectedBodyHair, setSelectedBodyHair] = useState<IInputSelect | null>(null);
  const [modalBodyHair, setModalBodyHair] = useState<boolean>(false);

  const [selectedForeskin, setSelectedForeskin] = useState<IInputSelect | null>(null);
  const [modalForeskin, setModalForeskin] = useState<boolean>(false);

  const [selectedOrientation, setSelectedOrientation] = useState<IInputSelect | null>(null);
  const [modalOrientation, setModalOrientation] = useState<boolean>(false);

  const [selectedZodiac, setSelectedZodiac] = useState<IInputSelect | null>(null);
  const [modalZodiac, setModalZodiac] = useState<boolean>(false);

  const [selectedSmoke, setSelectedSmoke] = useState<boolean>(false);
  const [selectedDrink, setSelectedDrink] = useState<boolean>(false);
  const [selectedTattoo, setSelectedTattoo] = useState<boolean>(false);
  const [selectedPiercing, setSelectedPiercing] = useState<boolean>(false);
  const [selectedBreasts, setSelectedBreasts] = useState<boolean>(false);

  const [selectedHiv, setSelectedHiv] = useState<IInputSelect | null>(null);
  const [modalHiv, setModalHiv] = useState<boolean>(false);

  const [selectedLanguages, setSelectedLanguages] = useState<Array<any>>([]);
  const [selectedTribes, setSelectedTribes] = useState<Array<any>>([]);
  const [selectedImOpen, setSelectedImOpen] = useState<Array<any>>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [mounted, setMounted] = useState<boolean>(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function updateForm() {
    if (formik.current) {
      const userAge = CalculateAgeByBirthdayString(user?.birthday);
      formik.current?.setFieldValue('age', userAge);

      formik.current?.setFieldValue('aboutMe', user?.appAbout?.aboutMe);

      setSelectedShowAge(!userAge ? false : user?.characters?.ageShow);
      formik.current?.setFieldValue('ageShow', !userAge ? false : user?.characters?.ageShow);

      const findHeight = heightsArray?.find((item) => item.value === user?.characters?.height);
      formik.current?.setFieldValue('height', findHeight?.value);
      setSelectedHeight(findHeight || null);

      const findweight = weightsArray?.find((item) => item.value === user?.characters?.weight);
      formik.current?.setFieldValue('weight', findweight?.value);
      setSelectedWeight(findweight || null);

      const findethnicity = ethnicityArray?.find((item) => item.value === user?.characters?.ethnicity);
      formik.current?.setFieldValue('ethnicity', findethnicity?.value);
      setSelectedEthnicity(findethnicity || null);

      const findhairSize = hairSizeArray?.find((item) => item.value === user?.characters?.hairSize);
      formik.current?.setFieldValue('hairSize', findhairSize?.value);
      setSelectedHairSize(findhairSize || null);

      const findhairColor = hairColorArray?.find((item) => item.value === user?.characters?.hairColor);
      formik.current?.setFieldValue('hairColor', findhairColor?.value);
      setSelectedHairColor(findhairColor || null);

      const findeyes = eyeColorArray?.find((item) => item.value === user?.characters?.eyes);
      formik.current?.setFieldValue('eyes', findeyes?.value);
      setSelectedEyes(findeyes || null);

      const findMannequin = mannequinArray?.find((item) => item.value === user?.characters?.mannequin);
      formik.current?.setFieldValue('mannequin', findMannequin?.value);
      setSelectedMannequin(findMannequin || null);

      const findBust = bustArray?.find((item) => item.value === user?.characters?.bust);
      formik.current?.setFieldValue('bust', findBust?.value);
      setSelectedBust(findBust || null);

      const findWaist = waistArray?.find((item) => item.value === user?.characters?.waist);
      formik.current?.setFieldValue('waist', findWaist?.value);
      setSelectedWaist(findWaist || null);

      const findHip = hipArray?.find((item) => item.value === user?.characters?.hip);
      formik.current?.setFieldValue('hip', findHip?.value);
      setSelectedHip(findHip || null);

      const findfeet = feetArray?.find((item) => item.value === user?.characters?.feet);
      formik.current?.setFieldValue('feet', findfeet?.value);
      setSelectedFeet(findfeet || null);

      const findCockSize = cockSizesArray?.find((item) => item.value === user?.characters?.cockSize);
      formik.current?.setFieldValue('cockSize', findCockSize?.value);
      setSelectedCockSize(findCockSize || null);

      const findposition = positionsArray?.find((item) => item.value === user?.characters?.position);
      formik.current?.setFieldValue('position', findposition?.value);
      setSelectedPosition(findposition || null);


      const findbody = bodyTypeArray?.find((item) => item.value === user?.style?.body);
      formik.current?.setFieldValue('body', findbody?.value);
      setSelectedBody(findbody || null);

      const findbodyHair = bodyHairArray?.find((item) => item.value === user?.style?.bodyHair);
      formik.current?.setFieldValue('bodyHair', findbodyHair?.value);
      setSelectedBodyHair(findbodyHair || null);

      const findforeskin = cockCutsArray?.find((item) => item.value === user?.style?.foreskin);
      formik.current?.setFieldValue('foreskin', findforeskin?.value);
      setSelectedForeskin(findforeskin || null);

      const findorientation = orientationsArray?.find((item) => item.value === user?.style?.orientation);
      formik.current?.setFieldValue('orientation', findorientation?.value);
      setSelectedOrientation(findorientation || null);

      const findzodiac = zodiacArray?.find((item) => item.value === user?.style?.zodiac);
      formik.current?.setFieldValue('zodiac', findzodiac?.value);
      setSelectedZodiac(findzodiac || null);

      setSelectedSmoke(user?.style?.smoke);
      formik.current?.setFieldValue('smoke', user?.style?.smoke);

      setSelectedDrink(user?.style?.drink);
      formik.current?.setFieldValue('drink', user?.style?.drink);

      setSelectedTattoo(user?.style?.tattoo);
      formik.current?.setFieldValue('tattoo', user?.style?.tattoo);

      setSelectedPiercing(user?.style?.piercing);
      formik.current?.setFieldValue('piercing', user?.style?.piercing);

      setSelectedBreasts(user?.style?.breasts);
      formik.current?.setFieldValue('breasts', user?.style?.breasts);


      const findhiv = hivArray?.find((item) => item.value === user?.appAbout?.hiv);
      formik.current?.setFieldValue('hiv', findhiv?.value);
      setSelectedHiv(findhiv || null);

      if (user?.appAbout?.languages) {
        const splitItem = user?.appAbout?.languages.split(', ');
        setSelectedLanguages(splitItem);
      }

      if (user?.appAbout?.tribes) {
        const splitItem = user?.appAbout?.tribes.split(', ');
        setSelectedTribes(splitItem);
      }

      if (user?.appAbout?.imOpen) {
        const splitItem = user?.appAbout?.imOpen.split(', ');
        setSelectedImOpen(splitItem);
      }

      formik.current?.validateForm();
    }

    setTimeout(() => {
      setMounted(true);
    }, Values.mountTime);
  }


  function mountPayload() {
    const values = formik?.current?.values;

    const payload = {
      aboutMe: values?.aboutMe,
      ageShow: values?.ageShow,

      height: values?.height,
      weight: values?.weight,
      ethnicity: values?.ethnicity,
      hairSize: values?.hairSize,
      hairColor: values?.hairColor,
      eyes: values?.eyes,
      mannequin: values?.mannequin,
      bust: values?.bust,
      waist: values?.waist,
      hip: values?.hip,
      feet: values?.feet,
      cockSize: values?.cockSize,
      position: values?.position,

      body: values?.body,
      bodyHair: values?.bodyHair,
      foreskin: values?.foreskin,
      orientation: values?.orientation,
      zodiac: values?.zodiac,
      smoke: values?.smoke,
      drink: values?.drink,
      tattoo: values?.tattoo,
      piercing: values?.piercing,
      breasts: values?.breasts,

      hiv: values?.hiv,

      languages: values?.languages?.join(', '),
      tribes: values?.tribes?.join(', '),
      imOpen: values?.imOpen?.join(', '),
    };

    return payload;
  }


  async function saveItemGroup() {
    setLoading(true);
    dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

    const payload = mountPayload();

    try {
      const response = await EditCharUser(user?.id, payload);

      if (!response?.data) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, translate(TranslateConfig.ERROR_SAVE));
        return;
      }

      showSnackBarProps(Colors.accept, translate(TranslateConfig.SUCCESS_SAVE));
      dispatch({ type: USER_SET, payload: response?.data });
    }
    catch (error: any) {
      console.error(error);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function setArrayMoreItems(name: string, item: string, valueTo: any, setValueTo: any) {
    const oldValues = [...valueTo];
    const index = oldValues.indexOf(item);
    if (index !== -1) {
      oldValues.splice(index, 1);
    }
    else {
      oldValues.push(item);
    }
    setValueTo(oldValues);
    formik.current?.setFieldValue(name, oldValues);
  }


  function renderChipLanguages(item: any, index: number) {
    const verifyItem = selectedLanguages.some((valueItem) => valueItem === item.value);


    return (

      <ChipApp
        key={index}
        disabled={loading}
        selected={verifyItem}
        title={item.label}
        selectedBackgroundColor={Colors.primaryDark}
        onPress={() => {
          setArrayMoreItems('languages', item.value, selectedLanguages, setSelectedLanguages);
        }}
      />

    );
  }


  function renderChipTribes(item: any, index: number) {
    const verifyItem = selectedTribes.some((valueItem) => valueItem === item.value);


    return (

      <ChipApp
        key={index}
        disabled={loading}
        selected={verifyItem}
        title={item.label}
        selectedBackgroundColor={Colors.primaryDark}
        onPress={() => {
          setArrayMoreItems('tribes', item.value, selectedTribes, setSelectedTribes);
        }}
      />

    );
  }


  function renderChipImOpen(item: any, index: number) {
    const verifyItem = selectedImOpen.some((valueItem) => valueItem === item.value);


    return (

      <ChipApp
        key={index}
        disabled={loading}
        selected={verifyItem}
        title={item.label}
        selectedBackgroundColor={Colors.primaryDark}
        onPress={() => {
          setArrayMoreItems('imOpen', item.value, selectedImOpen, setSelectedImOpen);
        }}
      />

    );
  }



  useEffect(() => {
    updateForm();
  }, [formik]);



  return (

    <Formik
      enableReinitialize
      validateOnMount={false}
      innerRef={formik}
      initialValues={{}}
      validationSchema={CharactersProfileValidationSchema}
      onSubmit={() => {
        // KeyboardModal.dismiss();
        saveItemGroup();
      }}>
      {({
        // dirty,
        // isValid,
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        setFieldValue,
      }) => (

        <Screen
          backgroundColor={Colors.appBackground}>


          <Helmet prioritizeSeoTags>
            <title>
              {`${translate(TranslateConfig.CHARACTERISTICS)} | ${translate(TranslateConfig.NAME_APP)}`}
            </title>

            <meta name="robots" content="noindex, nofollow" />
          </Helmet>



          <Container>

            <HeaderScreen
              disableGender
              backgroundColor={Colors.toolbarBackground}
              subBackgroundColor={Colors.toolbarBackground}
              leftIcon={
                <ButtonBack
                  color={Colors.white}
                  size={27}
                />
              }
              centerContent={
                <Title>
                  {translate(TranslateConfig.CHARACTERISTICS)}
                </Title>
              }
            />



            <Content>

              {!mounted && (
                <LoadingScreen
                  type={'SCREEN'}
                  color={Colors.white}
                />
              )}



              {mounted && (
                <ContentPadder>

                  <ButtonPanelItem
                  />



                  <CardCharsForm
                    backgroundColor={Colors.formBackground}
                    borderRadius={Sizes.cardRadius}>

                    <CharsInputsFirstContainer>
                      <SectionTitle
                        title={translate(TranslateConfig.AGE)}
                        color={Colors.primary}
                      />


                      <CharsInputsContent>
                        <InputTextStyled
                          disabled
                          type={'TEXT'}
                          labelText={translate(TranslateConfig.AGE)}
                          placeholderText={translate(TranslateConfig.AGE)}
                          errorText={errors.age}
                          countLimit={Values.ageCount}
                          value={values?.age}
                          onChange={handleChange('age')}
                          onBlur={handleBlur('age')}
                        />


                        <InputBooleanStyled
                          disabled={loading || !values?.age}
                          labelText={translate(TranslateConfig.AGE_SHOW)}
                          selected={selectedShowAge}
                          color={Colors.primary}
                          onPress={(state: boolean) => {
                            setFieldValue('ageShow', state);
                            setSelectedShowAge(state);
                          }}
                        />


                        <InputTextAreaStyled
                          disabled={loading}
                          type={'TEXT'}
                          labelText={translate(TranslateConfig.ABOUT_ME)}
                          placeholderText={translate(TranslateConfig.ABOUT_ME)}
                          // FIXME: Use este espaço para descrever como você é, seu corpo, suas habilidades e do que você gosta. Uma boa descrição pode prender muito a atenção dos clientes.
                          errorText={errors.aboutMe}
                          countLimit={Values.textCount}
                          value={values?.aboutMe}
                          onChange={handleChange('aboutMe')}
                          onBlur={handleBlur('aboutMe')}
                        />
                      </CharsInputsContent>
                    </CharsInputsFirstContainer>



                    <CharsInputsContainer>
                      <SectionTitle
                        title={translate(TranslateConfig.CHARACTERISTICS)}
                        subtitle={translate(TranslateConfig.SUBTITLES_CHARACTERISTICS)}
                        color={Colors.primary}
                      />


                      <CharsInputsContent>
                        <InputSelectStyled
                          disabled={loading}
                          labelText={translate(TranslateConfig.HEIGHT)}
                          placeholderText={selectedHeight?.label}
                          onPress={() => {
                            setModalHeight(true);
                          }}
                        />


                        <InputSelectStyled
                          disabled={loading}
                          labelText={translate(TranslateConfig.WEIGHT)}
                          placeholderText={selectedWeight?.label}
                          onPress={() => {
                            setModalWeight(true);
                          }}
                        />


                        <InputSelectStyled
                          disabled={loading}
                          labelText={translate(TranslateConfig.ETHNICITY)}
                          placeholderText={selectedEthnicity?.label}
                          onPress={() => {
                            setModalEthnicity(true);
                          }}
                        />


                        <InputSelectStyled
                          disabled={loading}
                          labelText={translate(TranslateConfig.HAIR_SIZE)}
                          placeholderText={selectedHairSize?.label}
                          onPress={() => {
                            setModalHairSize(true);
                          }}
                        />


                        <InputSelectStyled
                          disabled={loading}
                          labelText={translate(TranslateConfig.HAIR_COLOR)}
                          placeholderText={selectedHairColor?.label}
                          onPress={() => {
                            setModalHairColor(true);
                          }}
                        />


                        <InputSelectStyled
                          disabled={loading}
                          labelText={translate(TranslateConfig.EYE_COLOR)}
                          placeholderText={selectedEyes?.label}
                          onPress={() => {
                            setModalEyes(true);
                          }}
                        />


                        <InputSelectStyled
                          disabled={loading}
                          labelText={translate(TranslateConfig.MANNEQUIN)}
                          placeholderText={selectedMannequin?.label}
                          onPress={() => {
                            setModalMannequin(true);
                          }}
                        />


                        <InputSelectStyled
                          disabled={loading}
                          labelText={translate(TranslateConfig.BUST)}
                          placeholderText={selectedBust?.label}
                          onPress={() => {
                            setModalBust(true);
                          }}
                        />


                        <InputSelectStyled
                          disabled={loading}
                          labelText={translate(TranslateConfig.WAIST)}
                          placeholderText={selectedWaist?.label}
                          onPress={() => {
                            setModalWaist(true);
                          }}
                        />


                        <InputSelectStyled
                          disabled={loading}
                          labelText={translate(TranslateConfig.HIP)}
                          placeholderText={selectedHip?.label}
                          onPress={() => {
                            setModalHip(true);
                          }}
                        />


                        <InputSelectStyled
                          disabled={loading}
                          labelText={translate(TranslateConfig.FEET)}
                          placeholderText={selectedFeet?.label}
                          onPress={() => {
                            setModalFeet(true);
                          }}
                        />


                        {(user?.gender !== EGenderUser.WOMAN && user?.gender !== EGenderUser.TRANS_MAN) && (
                          <InputSelectStyled
                            disabled={loading}
                            labelText={translate(TranslateConfig.PENIS)}
                            placeholderText={selectedCockSize?.label}
                            onPress={() => {
                              setModalCockSize(true);
                            }}
                          />
                        )}


                        {(user?.gender !== EGenderUser.WOMAN && user?.gender !== EGenderUser.TRANS_MAN) && (
                          <InputSelectStyled
                            disabled={loading}
                            labelText={translate(TranslateConfig.POSITION)}
                            placeholderText={selectedPosition?.label}
                            onPress={() => {
                              setModalPosition(true);
                            }}
                          />
                        )}
                      </CharsInputsContent>
                    </CharsInputsContainer>



                    <CharsInputsContainer>
                      <SectionTitle
                        title={translate(TranslateConfig.STYLE)}
                        subtitle={translate(TranslateConfig.SUBTITLES_STYLE)}
                        color={Colors.primary}
                      />


                      <CharsInputsContent>
                        <InputSelectStyled
                          disabled={loading}
                          labelText={translate(TranslateConfig.BODY)}
                          placeholderText={selectedBody?.label}
                          onPress={() => {
                            setModalBody(true);
                          }}
                        />


                        <InputSelectStyled
                          disabled={loading}
                          labelText={translate(TranslateConfig.BODY_HAIR)}
                          placeholderText={selectedBodyHair?.label}
                          onPress={() => {
                            setModalBodyHair(true);
                          }}
                        />


                        {(user?.gender !== EGenderUser.WOMAN && user?.gender !== EGenderUser.TRANS_MAN) && (
                          <InputSelectStyled
                            disabled={loading}
                            labelText={translate(TranslateConfig.FORESKIN)}
                            placeholderText={selectedForeskin?.label}
                            onPress={() => {
                              setModalForeskin(true);
                            }}
                          />
                        )}


                        <InputSelectStyled
                          disabled={loading}
                          labelText={translate(TranslateConfig.ORIENTATION_SEXUAL)}
                          placeholderText={selectedOrientation?.label}
                          onPress={() => {
                            setModalOrientation(true);
                          }}
                        />


                        <InputSelectStyled
                          disabled={loading}
                          labelText={translate(TranslateConfig.ZODIAC)}
                          placeholderText={selectedZodiac?.label}
                          onPress={() => {
                            setModalZodiac(true);
                          }}
                        />


                        <InputBooleanStyled
                          disabled={loading}
                          labelText={translate(TranslateConfig.SMOKE)}
                          selected={selectedSmoke}
                          color={Colors.primary}
                          onPress={(state: boolean) => {
                            setFieldValue('smoke', state);
                            setSelectedSmoke(state);
                          }}
                        />


                        <InputBooleanStyled
                          disabled={loading}
                          labelText={translate(TranslateConfig.DRINK)}
                          selected={selectedDrink}
                          color={Colors.primary}
                          onPress={(state: boolean) => {
                            setFieldValue('drink', state);
                            setSelectedDrink(state);
                          }}
                        />


                        <InputBooleanStyled
                          disabled={loading}
                          labelText={translate(TranslateConfig.TATTOOS)}
                          selected={selectedTattoo}
                          color={Colors.primary}
                          onPress={(state: boolean) => {
                            setFieldValue('tattoo', state);
                            setSelectedTattoo(state);
                          }}
                        />


                        <InputBooleanStyled
                          disabled={loading}
                          labelText={translate(TranslateConfig.PIERCING)}
                          selected={selectedPiercing}
                          color={Colors.primary}
                          onPress={(state: boolean) => {
                            setFieldValue('piercing', state);
                            setSelectedPiercing(state);
                          }}
                        />


                        <InputBooleanStyled
                          disabled={loading}
                          labelText={translate(TranslateConfig.BREASTS)}
                          selected={selectedBreasts}
                          color={Colors.primary}
                          onPress={(state: boolean) => {
                            setFieldValue('breasts', state);
                            setSelectedBreasts(state);
                          }}
                        />
                      </CharsInputsContent>
                    </CharsInputsContainer>



                    <CharsInputsContainer>
                      <SectionTitle
                        title={translate(TranslateConfig.HEALTH)}
                        subtitle={translate(TranslateConfig.SUBTITLES_HEALTH)}
                        color={Colors.primary}
                      />


                      <CharsInputsContent>
                        <InputSelectStyled
                          disabled={loading}
                          labelText={translate(TranslateConfig.HIV)}
                          placeholderText={selectedHiv?.label}
                          onPress={() => {
                            setModalHiv(true);
                          }}
                        />
                      </CharsInputsContent>

                    </CharsInputsContainer>



                    <CharsInputsContainer>
                      <SectionTitle
                        title={translate(TranslateConfig.SPOKEN_LANGS)}
                        subtitle={translate(TranslateConfig.SUBTITLES_SPOKEN_LANGS)}
                        color={Colors.primary}
                      />


                      <InputChip
                        countLimit={translate(TranslateConfig.VALIDATE_ONE_MORE)}>
                        {languagesUserArray.map((item: any, index: number) => (
                          renderChipLanguages(item, index)
                        ))}
                      </InputChip>
                    </CharsInputsContainer>



                    <CharsInputsContainer>
                      <SectionTitle
                        title={translate(TranslateConfig.TRIBES)}
                        subtitle={translate(TranslateConfig.SUBTITLES_TRIBES)}
                        color={Colors.primary}
                      />


                      <InputChip
                        countLimit={translate(TranslateConfig.VALIDATE_ONE_MORE)}>
                        {tribeArray.map((item: any, index: number) => (
                          renderChipTribes(item, index)
                        ))}
                      </InputChip>
                    </CharsInputsContainer>



                    <CharsInputsLastContainer>
                      <SectionTitle
                        title={translate(TranslateConfig.IM_IN)}
                        subtitle={translate(TranslateConfig.SUBTITLES_IM_IN)}
                        color={Colors.primary}
                      />


                      <InputChip
                        countLimit={translate(TranslateConfig.VALIDATE_ONE_MORE)}>
                        {interestArray.map((item: any, index: number) => (
                          renderChipImOpen(item, index)
                        ))}
                      </InputChip>
                    </CharsInputsLastContainer>



                    <ContentButtonForm>
                      <ButtonAction
                        width={Sizes.buttonMinWidth}
                        title={translate(TranslateConfig.ACTION_SAVE)}
                        onPress={() => {
                          handleSubmit();
                        }}
                      />
                    </ContentButtonForm>

                  </CardCharsForm>

                </ContentPadder>
              )}


              <FooterApp />

            </Content>

          </Container>



          <DataSelectModal
            hideSearch
            title={translate(TranslateConfig.HEIGHT)}
            visible={modalHeight}
            data={heightsArray}
            selectedValue={selectedHeight?.value}
            setData={(typeReturn: IInputSelect) => {
              setSelectedHeight(typeReturn);
              setFieldValue('height', typeReturn?.value);
            }}
            onClose={() => {
              setModalHeight(false);
            }}
          />


          <DataSelectModal
            hideSearch
            title={translate(TranslateConfig.HEIGHT)}
            visible={modalWeight}
            data={weightsArray}
            selectedValue={selectedWeight?.value}
            setData={(typeReturn: IInputSelect) => {
              setSelectedWeight(typeReturn);
              setFieldValue('weight', typeReturn?.value);
            }}
            onClose={() => {
              setModalWeight(false);
            }}
          />


          <DataSelectModal
            hideSearch
            title={translate(TranslateConfig.ETHNICITY)}
            visible={modalEthnicity}
            data={ethnicityArray}
            selectedValue={selectedEthnicity?.value}
            setData={(typeReturn: IInputSelect) => {
              setSelectedEthnicity(typeReturn);
              setFieldValue('ethnicity', typeReturn?.value);
            }}
            onClose={() => {
              setModalEthnicity(false);
            }}
          />


          <DataSelectModal
            hideSearch
            title={translate(TranslateConfig.HAIR_SIZE)}
            visible={modalHairSize}
            data={hairSizeArray}
            selectedValue={selectedHairSize?.value}
            setData={(typeReturn: IInputSelect) => {
              setSelectedHairSize(typeReturn);
              setFieldValue('hairSize', typeReturn?.value);
            }}
            onClose={() => {
              setModalHairSize(false);
            }}
          />


          <DataSelectModal
            hideSearch
            title={translate(TranslateConfig.HAIR_COLOR)}
            visible={modalHairColor}
            data={hairColorArray}
            selectedValue={selectedHairColor?.value}
            setData={(typeReturn: IInputSelect) => {
              setSelectedHairColor(typeReturn);
              setFieldValue('hairColor', typeReturn?.value);
            }}
            onClose={() => {
              setModalHairColor(false);
            }}
          />


          <DataSelectModal
            hideSearch
            title={translate(TranslateConfig.EYE_COLOR)}
            visible={modalEyes}
            data={eyeColorArray}
            selectedValue={selectedEyes?.value}
            setData={(typeReturn: IInputSelect) => {
              setSelectedEyes(typeReturn);
              setFieldValue('eyes', typeReturn?.value);
            }}
            onClose={() => {
              setModalEyes(false);
            }}
          />


          <DataSelectModal
            hideSearch
            title={translate(TranslateConfig.MANNEQUIN)}
            visible={modalMannequin}
            data={mannequinArray}
            selectedValue={selectedMannequin?.value}
            setData={(typeReturn: IInputSelect) => {
              setSelectedMannequin(typeReturn);
              setFieldValue('mannequin', typeReturn?.value);
            }}
            onClose={() => {
              setModalMannequin(false);
            }}
          />


          <DataSelectModal
            hideSearch
            title={translate(TranslateConfig.BUST)}
            visible={modalBust}
            data={bustArray}
            selectedValue={selectedBust?.value}
            setData={(typeReturn: IInputSelect) => {
              setSelectedBust(typeReturn);
              setFieldValue('bust', typeReturn?.value);
            }}
            onClose={() => {
              setModalBust(false);
            }}
          />


          <DataSelectModal
            hideSearch
            title={translate(TranslateConfig.WAIST)}
            visible={modalWaist}
            data={waistArray}
            selectedValue={selectedWaist?.value}
            setData={(typeReturn: IInputSelect) => {
              setSelectedWaist(typeReturn);
              setFieldValue('waist', typeReturn?.value);
            }}
            onClose={() => {
              setModalWaist(false);
            }}
          />


          <DataSelectModal
            hideSearch
            title={translate(TranslateConfig.HIP)}
            visible={modalHip}
            data={hipArray}
            selectedValue={selectedHip?.value}
            setData={(typeReturn: IInputSelect) => {
              setSelectedHip(typeReturn);
              setFieldValue('hip', typeReturn?.value);
            }}
            onClose={() => {
              setModalHip(false);
            }}
          />


          <DataSelectModal
            hideSearch
            title={translate(TranslateConfig.FEET)}
            visible={modalFeet}
            data={feetArray}
            selectedValue={selectedFeet?.value}
            setData={(typeReturn: IInputSelect) => {
              setSelectedFeet(typeReturn);
              setFieldValue('feet', typeReturn?.value);
            }}
            onClose={() => {
              setModalFeet(false);
            }}
          />


          <DataSelectModal
            hideSearch
            title={translate(TranslateConfig.PENIS)}
            visible={modalCockSize}
            data={cockSizesArray}
            selectedValue={selectedCockSize?.value}
            setData={(typeReturn: IInputSelect) => {
              setSelectedCockSize(typeReturn);
              setFieldValue('cockSize', typeReturn?.value);
            }}
            onClose={() => {
              setModalCockSize(false);
            }}
          />


          <DataSelectModal
            hideSearch
            title={translate(TranslateConfig.POSITION)}
            visible={modalPosition}
            data={positionsArray}
            selectedValue={selectedPosition?.value}
            setData={(typeReturn: IInputSelect) => {
              setSelectedPosition(typeReturn);
              setFieldValue('position', typeReturn?.value);
            }}
            onClose={() => {
              setModalPosition(false);
            }}
          />


          <DataSelectModal
            hideSearch
            title={translate(TranslateConfig.BODY)}
            visible={modalBody}
            data={bodyTypeArray}
            selectedValue={selectedBody?.value}
            setData={(typeReturn: IInputSelect) => {
              setSelectedBody(typeReturn);
              setFieldValue('body', typeReturn?.value);
            }}
            onClose={() => {
              setModalBody(false);
            }}
          />


          <DataSelectModal
            hideSearch
            title={translate(TranslateConfig.BODY_HAIR)}
            visible={modalBodyHair}
            data={bodyHairArray}
            selectedValue={selectedBodyHair?.value}
            setData={(typeReturn: IInputSelect) => {
              setSelectedBodyHair(typeReturn);
              setFieldValue('bodyHair', typeReturn?.value);
            }}
            onClose={() => {
              setModalBodyHair(false);
            }}
          />


          <DataSelectModal
            hideSearch
            title={translate(TranslateConfig.FORESKIN)}
            visible={modalForeskin}
            data={cockCutsArray}
            selectedValue={selectedForeskin?.value}
            setData={(typeReturn: IInputSelect) => {
              setSelectedForeskin(typeReturn);
              setFieldValue('foreskin', typeReturn?.value);
            }}
            onClose={() => {
              setModalForeskin(false);
            }}
          />


          <DataSelectModal
            hideSearch
            title={translate(TranslateConfig.ORIENTATION_SEXUAL)}
            visible={modalOrientation}
            data={orientationsArray}
            selectedValue={selectedOrientation?.value}
            setData={(typeReturn: IInputSelect) => {
              setSelectedOrientation(typeReturn);
              setFieldValue('orientation', typeReturn?.value);
            }}
            onClose={() => {
              setModalOrientation(false);
            }}
          />


          <DataSelectModal
            hideSearch
            title={translate(TranslateConfig.ZODIAC)}
            visible={modalZodiac}
            data={zodiacArray}
            selectedValue={selectedZodiac?.value}
            setData={(typeReturn: IInputSelect) => {
              setSelectedZodiac(typeReturn);
              setFieldValue('zodiac', typeReturn?.value);
            }}
            onClose={() => {
              setModalZodiac(false);
            }}
          />


          <DataSelectModal
            hideSearch
            title={translate(TranslateConfig.HIV)}
            visible={modalHiv}
            data={hivArray}
            selectedValue={selectedHiv?.value}
            setData={(typeReturn: IInputSelect) => {
              setSelectedHiv(typeReturn);
              setFieldValue('hiv', typeReturn?.value);
            }}
            onClose={() => {
              setModalHiv(false);
            }}
          />

        </Screen>

      )}
    </Formik>

  );
};



export default CharactersProfileScreen;
