import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';

import {
  HomeScrollbarVertival,
} from '../../../styles/scrolls.layout';



export const HomeLeftContent = styled.div`
  display: flex;
  max-width: ${Sizes.cardHomeMaxWidth}px;

  @media ${PlatformDevices.maxTabletM} {
    flex-direction: column;
    width: 100%;
    margin-bottom: ${Sizes.contentMargin}px;
  }

  @media ${PlatformDevices.minTabletM} {
    flex-direction: row;
    margin-bottom: ${Sizes.contentMargin}px;
    gap: 20px;
  }

  @media ${PlatformDevices.minLaptopL} {
    ${HomeScrollbarVertival};
  
    flex-direction: column;
    width: ${Sizes.contentLeftWidth + 120}px;
    min-width: ${Sizes.contentLeftWidth + 120}px;
    margin-bottom: 0;
    padding-top: ${Sizes.paddingContainer + 7}px;
    padding-left: ${Sizes.paddingContainer}px;
    padding-right: ${Sizes.contentMargin}px;
    padding-bottom: ${Sizes.paddingContainer + 7}px;
    gap: 0px;
  }
`;

export const HomeStartLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 300px;
`;

export const HomeLiveNowLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${PlatformDevices.maxTabletM} {
    margin-top: 25px;
  }

  @media ${PlatformDevices.minLaptopL} {
    margin-top: 25px;
  }
`;



export const HomeOptionsLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${PlatformDevices.maxTabletM} {
    margin-top: 25px;
  }

  @media ${PlatformDevices.minTabletM} {
    max-width: 200px;
  }
  
  @media ${PlatformDevices.minLaptopL} {
    display: none;
  }
`;

export const SuggestionLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const SuggestionViewLeftButton = styled.a`
  display: flex;
  flex-direction: column;
  flex: 1;
`;


export const LeftBoostLogoImage = styled.img`
  margin-top: 2px;
  margin-bottom: 10px;
  height: 20px;
  object-fit: contain;
`;


export const SuggestionLeftTitle = styled.h3`
  margin: 0;
  color: ${Colors.white};
  font-size: 18px;
  font-weight: 500;
`;

export const SuggestionLeftText = styled.p`
  margin-top: 5px;
  color: ${Colors.textDescription};
  font-size: 12.5px;
`;



export const HomeLiveNowView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const BadgeLiveNowButton = styled.a`
  display: flex;
  overflow: hidden;
  width: 100%;
  border-radius: ${Sizes.cardRadius}px;
`;

export const BadgeLiveNowImage = styled.img`
  width: 100%;
  object-fit: contain;
`;
