import SecretioWS from '../index';



export const GetMediasLikedUser = async (userId: string) => {
  const url = `/app/like/${userId}`;

  const response = await SecretioWS.get(url);
  return response.data;
};



export const DoMediaLikeUser = async (userId: string, mediaLikeId: string) => {
  const url = `/app/like/do-like/${userId}`;

  const payload = {
    user: userId,
    media: mediaLikeId,
  };

  const response = await SecretioWS.post(url, payload);
  return response.data;
};
