export enum EDrawerMenu {
  HOME = 'HOME',

  MY_PRIVE = 'MY_PRIVE',
  RENT = 'RENT',
  LIVE = 'LIVE',
  ALL_MODELES = 'ALL_MODELES',
  ALL_SPONSORS_MODELES = 'ALL_SPONSORS_MODELES',

  DASHBOARD = 'DASHBOARD',
  FAVORITES = 'FAVORITES',
  NOTIFICATIONS = 'NOTIFICATIONS',
  MESSAGES = 'MESSAGES',

  ADM_MODEL = 'ADM_MODEL',
  ADM_FINANCIAL = 'ADM_FINANCIAL',
  ADM_CALENDAR = 'ADM_CALENDAR',

  SETTINGS = 'SETTINGS',
  HELP = 'HELP',
  EXIT = 'EXIT',


  // Just to prevent click
  SUGGESTIONS = 'SUGGESTIONS',
  BE_MODELE = 'BE_MODELE',
}



export default EDrawerMenu;
