import {
  EEnvsName,
} from '../../enums';

import DEV_VAR from './var.dev';
import LOCAL_VAR from './var.local';
import PROD_VAR from './var.prod';



const Vars = (varReceived?: EEnvsName) => {
  const env = varReceived || process.env.REACT_APP_NODE_ENV;

  if (!env) {
    return LOCAL_VAR;
  }
  switch (env) {
    case EEnvsName.DEV: return DEV_VAR;
    case EEnvsName.PROD: return PROD_VAR;
    default: return LOCAL_VAR;
  }
};



export default Vars;
