import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';



export const IsentionPayContainer = styled.div`
  display: flex;
  flex-direction: column;
`;



export const IsentionPayTitle = styled.p`
  color: ${Colors.primary};
  font-size: 20px;
  font-weight: bold;
  line-height: 22px;
`;

export const IsentionPayDescription = styled.p`
  margin-top: 10px;
  color: ${Colors.attention};
  font-size: 14px;
  line-height: 18px;
`;
