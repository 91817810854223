import React from 'react';

import {
  useNavigate,
} from 'react-router-dom';

import NameRoutes from '../../../navigation/names';

import {
  Colors,
} from '../../../shared/constants';

import {
  IconProfile,
} from '../../Icons';

import {
  PanelProfileContainer,
  PanelProfileIcon,
  PanelProfileDescriptionView,
  PanelProfileDescriptionTitle,
  PanelProfileDescriptionText,
} from './styled';



const ButtonPaneUser: React.FC = () => {
  const history = useNavigate();



  return (

    <PanelProfileContainer
      onPress={() => {
        history(NameRoutes.CharactersProfileScreen);
      }}>

      <PanelProfileIcon>
        <IconProfile
          color={Colors.white}
          size={36}
        />
      </PanelProfileIcon>



      <PanelProfileDescriptionView>
        <PanelProfileDescriptionTitle>
          Editar características
        </PanelProfileDescriptionTitle>

        <PanelProfileDescriptionText>
          Adicione mais informações, preferências e vontades até completar seu perfil.
        </PanelProfileDescriptionText>
      </PanelProfileDescriptionView>

    </PanelProfileContainer>

  );
};



export default ButtonPaneUser;
