import React from 'react';

import {
  Colors,
} from '../../../../shared/constants';

import {
  IconUpload,
} from '../../../Icons';

import {
  AddUploadFormContainer,
  AddUploadIcon,
  AddFlexFlowInfoView,
  AddUploadText,
  AddUploadTextSmall,
} from './styled';



export interface IProps {
  title: string;
  onPress?: any;
}



const CardAddMedia: React.FC<IProps> = (props) => {
  return (

    <AddUploadFormContainer
      onClick={() => {
        if (props.onPress) {
          props.onPress();
        }
      }}>

      <AddUploadIcon>
        <IconUpload
          color={Colors.inputLabel}
          size={46}
        />
      </AddUploadIcon>


      <AddFlexFlowInfoView>
        <AddUploadText>
          {props.title}
        </AddUploadText>

        <AddUploadTextSmall>
          Clique para adicionar
        </AddUploadTextSmall>
      </AddFlexFlowInfoView>

    </AddUploadFormContainer>

  );
};



export default CardAddMedia;
