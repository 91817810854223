import styled from 'styled-components';



export const LinkBioContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
`;


export const DashboardContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;


export const PreviewContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 350px;
  height: 100%;
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 20px;
`;
