import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
} from 'react-redux';

import {
  useNavigate,
} from 'react-router-dom';

import {
  IViewMediaModal,
} from '../../../apis/interfaces/Medias/view-media-modal.interface';

import CardPostCreate from '../../../components/Cards/Posts/CardPostCreate';
import CardPost from '../../../components/Cards/Posts/CardPostFeed';
import EmptyContent from '../../../components/Empty';

import NameRoutes from '../../../navigation/names';

import {
  RootState,
} from '../../../redux';

import {
  Images,
} from '../../../shared/constants';

import EUserType from '../../../shared/enums/Users/user-type.enum';

import {
  TranslateConfig,
} from '../../../shared/translations';

import {
  HomeCenterFlex,
  HomePostCenterView,
} from './styled';



export interface IProps {
  dataFeedArray?: Array<any>;
  onShowMedia?: any;
}



const HomeCenterContent: React.FC<IProps> = (props: IProps) => {
  const history = useNavigate();
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);


  const postArray = props.dataFeedArray;



  function renderItemPost(itemPost: any, index: number) {
    return (

      <CardPost
        key={index}
        idMedia={itemPost?.id}
        feedType={'TIMELINE'}
        type={itemPost?.type}
        plan={itemPost?.user?.plan?.planCurrent}
        imageUser={itemPost?.user?.picture}
        name={itemPost?.user?.alias}
        username={itemPost?.user?.useralias}
        verified={itemPost?.user?.verified}
        promoter={itemPost?.user?.brandPromoter}
        createdAt={itemPost?.createdAt}
        mediaLink={itemPost?.picture}
        like={itemPost?.like || 0}
        caption={itemPost?.caption}
        onModelePress={() => {
          history(NameRoutes.ModeleDetailsScreen.replace(
            ':id',
            itemPost?.user?.useralias,
          ));
        }}
        onPress={() => {
          const itemToSender: IViewMediaModal = {
            indexSelected: index,
          };

          if (props.onShowMedia) {
            props.onShowMedia(itemToSender);
          }
        }}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noFeed}
        title={translate(TranslateConfig.EMPTY_ITEMS)}
      />

    );
  }



  return (

    <HomeCenterFlex>

      {user?.type === EUserType.MODELE && (
        <HomePostCenterView>
          <CardPostCreate />
        </HomePostCenterView>
      )}


      {postArray?.length <= 0 && (
        listEmptyComponent()
      )}


      {postArray?.length > 0 && postArray.map((itemPost, index) => (
        renderItemPost(itemPost, index)
      ))}

    </HomeCenterFlex>

  );
};



export default HomeCenterContent;
