import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';



export interface IProps {
  backgroundColor?: string;
}



export const CardDashContainer = styled.a`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  height: 80px;
  min-height: 80px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: ${(props: IProps) => props.backgroundColor || Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;

export const LoginImageBackground = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
`;

export const CardDashView = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding-top: 4px;
  padding-left: 10px;
  padding-right: 15px;
  padding-bottom: 10px;
  z-index: 5;
`;


export const InfoImageHeader = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  padding-top: 8px;
  padding-left: 5px;
  padding-right: 7px;
  padding-bottom: 3px;
`;

export const InfoTextHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CreditCardText = styled.h1`
  color: ${Colors.white};
  font-size: 18px;
  font-weight: bold;
`;

export const BadgeTextCard = styled.p`
  margin-top: 2px;
  margin-left: 12px;
  padding-top: 3px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 3px;
  color: ${Colors.white};
  background-color: ${Colors.primary};
  border-radius: 10px;
  font-size: 11px;
`;


export const CardName = styled.h2`
  color: ${Colors.textApp};
  font-size: 17px;
  font-weight: bold;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  word-break: break-word;
`;


export const ImageBank = styled.img`
  height: 65px;
  width: 65px;
  border-radius: ${Sizes.cardRadius}px;
  object-fit: contain;
`;
