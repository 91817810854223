import IAppRoute from '../../apis/interfaces/App/app-route.interface';

import AccessScreen from '../../screens/Access';
import AllSponsorsScreen from '../../screens/All/AllSponsors';
import DoCommentModeleScreen from '../../screens/Details/DoComment';
import ModeleDetailsScreen from '../../screens/Details/ProfileModele';
import Error404Screen from '../../screens/Errors/404';
import AppDownScreen from '../../screens/Errors/AppDown';
import FaqBulletScreen from '../../screens/Help/FAQ/Bullets';
import FaqCategoryScreen from '../../screens/Help/FAQ/Categories';
import HomeScreen from '../../screens/Home';
import LiveRoomCamScreen from '../../screens/Live/Room';
import ModeleAdsScreen from '../../screens/Modele/Ads';
import RentChangeLevelScreen from '../../screens/Modele/Ads/RentChangeLevel';
import RentPayAttentionScreen from '../../screens/Modele/Ads/RentPayAttention';
import AntiFakeVerifiedScreen from '../../screens/Modele/AntiFakeVerified';
import ContactsModeleScreen from '../../screens/Modele/Contacts';
import ModeleDataProfileScreen from '../../screens/Modele/DataProfile';
import FinancialAffiliatesScreen from '../../screens/Modele/Financial/Affiliates';
import FinancialMovimentsScreen from '../../screens/Modele/Financial/Moviments';
import LinkBioDashboardScreen from '../../screens/Modele/LinkBio/Manage';
import ViewLinkBioScreen from '../../screens/Modele/LinkBio/View';
import ModeleLiveOptionsScreen from '../../screens/Modele/LiveOptions';
import ModeleLiveOpenScreen from '../../screens/Modele/LiveOptions/LiveOpen';
import ModeleLocationFormSection from '../../screens/Modele/Location';
import ModeleMediaAddScreen from '../../screens/Modele/Medias';
import PeopleClubListScreen from '../../screens/Modele/PeopleClubList';
import ModelePrivateGalleryScreen from '../../screens/Modele/PrivateGallery';
import RentProfileModeleScreen from '../../screens/Modele/RentProfile';
import ReviewModeleScreen from '../../screens/Modele/Reviews';
import ModeleTravelSeeScreen from '../../screens/Modele/Travels';
import VisibilityModeleScreen from '../../screens/Modele/VisibilityModele';
import AddMoneyScreen from '../../screens/Money';
import AdsLiveScreen from '../../screens/Publics/AdsLive';
import AdsRegisterBothScreen from '../../screens/Publics/AdsRegisterBoth';
import AdsRentScreen from '../../screens/Publics/AdsRent';
import RankingsScreen from '../../screens/Rankings';
import RentHowAdsScreen from '../../screens/Rent/RentHowAds';
import RentModeleScreen from '../../screens/Rent/RentModele';
import AboutScreen from '../../screens/Settings/About';
import TermsPolicyScreen from '../../screens/Settings/TermsPolicy';
import SuggestionScreen from '../../screens/Suggestion';
import ModelePlanManageScreen from '../../screens/Upgrade/Modele/PlanManage';
import UpgradeModeleShowScreen from '../../screens/Upgrade/Modele/UpgradeModele';
import UpgradeUserDoScreen from '../../screens/Upgrade/User/DoUpgrade';
import UpgradeUserShowScreen from '../../screens/Upgrade/User/UpgradeUser';
import BeModeleScreen from '../../screens/User/BeModele';
import BlockedScreen from '../../screens/User/Blocked';
import BuyMediasScreen from '../../screens/User/BuyMedias';
import CharactersProfileScreen from '../../screens/User/CharactersProfile';
import CommentDoneScreen from '../../screens/User/CommentDone';
import FanClubPayedScreen from '../../screens/User/FanClubPayed';
import UserImageProfileScreen from '../../screens/User/ImageProfile';
import CreateModeleFormScreen from '../../screens/User/MainData';
import MediaLikedScreen from '../../screens/User/MediaLiked';
import MyPaymentsScreen from '../../screens/User/MyPayments';
import WebViewScreen from '../../screens/WebView';

import NameRoutes from '../names';



const ScreenRoutes: IAppRoute[] = [

  // Start screens

  {
    path: NameRoutes.HomeScreen,
    page: HomeScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.AppDownScreen,
    page: AppDownScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.Error404Screen,
    page: Error404Screen,
    isPublic: true,
    exact: true,
  },



  // Ads Publics

  {
    path: NameRoutes.AdsLiveScreen,
    page: AdsLiveScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.AdsRegisterBothScreen,
    page: AdsRegisterBothScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.AdsRentScreen,
    page: AdsRentScreen,
    isPublic: true,
    exact: true,
  },



  // Access

  {
    path: NameRoutes.AccessScreen,
    page: AccessScreen,
    isPublic: true,
    exact: true,
  },



  // Sponsors

  {
    path: NameRoutes.AllSponsorsScreen,
    page: AllSponsorsScreen,
    isPublic: true,
    exact: true,
  },



  // Rent

  {
    path: NameRoutes.RentHowAdsScreen,
    page: RentHowAdsScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.RentModeleScreen,
    page: RentModeleScreen,
    isPublic: true,
    exact: true,
  },



  // Cams Live

  {
    path: NameRoutes.LiveRoomCamScreen,
    page: LiveRoomCamScreen,
    isPublic: true,
    exact: true,
  },



  // User

  {
    path: NameRoutes.CreateModeleFormScreen,
    page: CreateModeleFormScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.CharactersProfileScreen,
    page: CharactersProfileScreen,
    isPublic: false,
    exact: true,
  },



  // Profile

  {
    path: NameRoutes.UserImageProfileScreen,
    page: UserImageProfileScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.BlockedScreen,
    page: BlockedScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.BuyMediasScreen,
    page: BuyMediasScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.MediaLikedScreen,
    page: MediaLikedScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.CommentDoneScreen,
    page: CommentDoneScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.FanClubPayedScreen,
    page: FanClubPayedScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.MyPaymentsScreen,
    page: MyPaymentsScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.BeModeleScreen,
    page: BeModeleScreen,
    isPublic: true,
    exact: true,
  },



  // Upgrade User

  {
    path: NameRoutes.UpgradeUserShowScreen,
    page: UpgradeUserShowScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.UpgradeUserDoScreen,
    page: UpgradeUserDoScreen,
    isPublic: true,
    exact: true,
  },



  // Modeles

  {
    path: NameRoutes.ContactsModeleScreen,
    page: ContactsModeleScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.LinkBioDashboardScreen,
    page: LinkBioDashboardScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.RentProfileModeleScreen,
    page: RentProfileModeleScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.PeopleClubListScreen,
    page: PeopleClubListScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.ModeleLiveOptionsScreen,
    page: ModeleLiveOptionsScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.ModeleLiveOpenScreen,
    page: ModeleLiveOpenScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.ModelePlanManageScreen,
    page: ModelePlanManageScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.UpgradeModeleShowScreen,
    page: UpgradeModeleShowScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.RentChangeLevelScreen,
    page: RentChangeLevelScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.RentPayAttentionScreen,
    page: RentPayAttentionScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.ModeleDataProfileScreen,
    page: ModeleDataProfileScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.ModeleLocationFormSection,
    page: ModeleLocationFormSection,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.ModeleTravelSeeScreen,
    page: ModeleTravelSeeScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.ModeleMediaAddScreen,
    page: ModeleMediaAddScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.AntiFakeVerifiedScreen,
    page: AntiFakeVerifiedScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.ModelePrivateGalleryScreen,
    page: ModelePrivateGalleryScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.ReviewModeleScreen,
    page: ReviewModeleScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.VisibilityModeleScreen,
    page: VisibilityModeleScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.ModeleAdsScreen,
    page: ModeleAdsScreen,
    isPublic: false,
    exact: true,
  },



  // Financial

  {
    path: NameRoutes.FinancialAffiliatesScreen,
    page: FinancialAffiliatesScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.FinancialMovimentsScreen,
    page: FinancialMovimentsScreen,
    isPublic: false,
    exact: true,
  },



  // Suggestions and Rankings

  {
    path: NameRoutes.RankingsScreen,
    page: RankingsScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.SuggestionScreen,
    page: SuggestionScreen,
    isPublic: false,
    exact: true,
  },



  // Add Money

  {
    path: NameRoutes.AddMoneyScreen,
    page: AddMoneyScreen,
    isPublic: false,
    exact: true,
  },



  // Help - FAQ

  {
    path: NameRoutes.FaqCategoryScreen,
    page: FaqCategoryScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.FaqBulletScreen,
    page: FaqBulletScreen,
    isPublic: false,
    exact: true,
  },



  // Settings

  {
    path: NameRoutes.AboutScreen,
    page: AboutScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.TermsPolicyScreen,
    page: TermsPolicyScreen,
    isPublic: true,
    exact: true,
  },


  {
    path: NameRoutes.WebViewScreen,
    page: WebViewScreen,
    isPublic: true,
    exact: true,
  },



  // LinkBio View (Need be the last)

  {
    path: NameRoutes.ViewLinkBioScreen,
    page: ViewLinkBioScreen,
    isPublic: true,
    exact: true,
  },



  // Do Avaliation (Need be the last)

  {
    path: NameRoutes.DoCommentModeleScreen,
    page: DoCommentModeleScreen,
    isPublic: true,
    exact: true,
  },



  // Details (Need be the last)

  {
    path: NameRoutes.ModeleDetailsScreen,
    page: ModeleDetailsScreen,
    isPublic: true,
    exact: true,
  },

];



export default ScreenRoutes;
