import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  Formik,
  FormikProps,
} from 'formik';

import {
  EditUserMainProfile,
} from '../../../apis/endpoints/users.endpoints';

import ButtonAction from '../../../components/Buttons/ButtonAction';

import {
  Content,
  Title,
  Screen,
} from '../../../components/Composh/web';

import {
  ContentButtonForm,
  ContentPadder,
} from '../../../components/Contents/ContentPadder';

import {
  ButtonBack,
} from '../../../components/Controls';

import FooterApp from '../../../components/Footers/FooterApp';
import HeaderScreen from '../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../components/Loadings/LoadingScreen';
import SectionTitle from '../../../components/Titles/SectionTitle';
import RequiredText from '../../../components/Titles/TextRequired';

import {
  RootState,
} from '../../../redux';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../redux/reducers/app-modals.store';

import {
  USER_SET,
} from '../../../redux/reducers/user.store';

import {
  typeGenders,
} from '../../../shared/arrays/Gender/gender.array';

import {
  Colors,
  Sizes,
  Values,
} from '../../../shared/constants';

import {
  TranslateConfig,
} from '../../../shared/translations';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../shared/utils/errors.utils';

import {
  DetailsTopViewItems,
  DetailsBottomViewItems,
  InputTextStyled,
  InputViewStyled,
  CardContactsForm,
  ContactsAttendanceOptionsContent,
  GenderUserRadio,
} from './styled';



const CreateModeleFormScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);


  const formik = useRef<FormikProps<any>>(null);

  const [userGender, setUserGender] = useState('');
  const [initialGender, setInitialGender] = useState<any>(null);

  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function updateForm() {
    if (formik.current) {
      formik.current?.setFieldValue('alias', user?.alias);
      formik.current?.setFieldValue('useralias', user?.useralias);

      formik.current?.setFieldValue('gender', user?.gender);
      if (user?.gender) {
        const indexGender = typeGenders.find((itemGender: any) => itemGender.value === user?.gender);

        if (indexGender) {
          setUserGender(indexGender.value);
          setInitialGender(indexGender.id);
        }
        else {
          setUserGender(user?.gender);
          setInitialGender(user?.gender);
        }
      }

      formik.current?.validateForm();
    }

    setTimeout(() => {
      setMounted(true);
    }, Values.mountTime);
  }


  function mountPayload() {
    const values = formik?.current?.values;

    const payload: any = {
      alias: values.alias,
      useralias: values.useralias,
      gender: userGender,
    };

    return payload;
  }


  async function saveEvent() {
    setLoading(true);
    dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

    const payload = mountPayload();

    try {
      const response = await EditUserMainProfile(user?.id, payload);

      if (!response?.data) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, translate(TranslateConfig.ERROR_UPDATE));
        return;
      }

      dispatch({ type: USER_SET, payload: response?.data });
      showSnackBarProps(Colors.accept, translate(TranslateConfig.SUCCESS_UPDATE));
    }
    catch (error: any) {
      console.error(error);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.white}
      />

    );
  }



  useEffect(() => {
    updateForm();
  }, [formik]);



  return (

    <Formik
      enableReinitialize
      validateOnMount={false}
      innerRef={formik}
      initialValues={{}}
      // validationSchema={CharactersProfileValidationSchema}
      onSubmit={() => {
        // KeyboardModal.dismiss();
        saveEvent();
      }}>
      {({
        // dirty,
        // isValid,
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        setFieldValue,
      }) => (

        <Screen
          backgroundColor={Colors.appBackground}>


          <Helmet prioritizeSeoTags>
            <title>
              {`${translate(TranslateConfig.DATA_PRINCIPAL_EDIT)} | ${translate(TranslateConfig.NAME_APP)}`}
            </title>

            <meta name="robots" content="noindex, nofollow" />
          </Helmet>



          <HeaderScreen
            disableGender
            backgroundColor={Colors.toolbarBackground}
            subBackgroundColor={Colors.toolbarBackground}
            leftIcon={
              <ButtonBack
                color={Colors.white}
                size={27}
              />
            }
            centerContent={
              <Title
                color={Colors.white}>
                {translate(TranslateConfig.DATA_PRINCIPAL_EDIT)}
              </Title>
            }
          />



          <Content>

            {!mounted && (
              renderLoading()
            )}



            {mounted && (
              <ContentPadder>

                <CardContactsForm
                  backgroundColor={Colors.formBackground}
                  borderRadius={Sizes.cardRadius}>

                  <RequiredText>
                    * {translate(TranslateConfig.HELP_INPUT_REQUIRED)}
                  </RequiredText>



                  <DetailsTopViewItems>

                    <SectionTitle
                      title={translate(TranslateConfig.DATA_PRINCIPAL)}
                      color={Colors.primary}
                    />


                    <ContactsAttendanceOptionsContent>

                      <InputTextStyled
                        disabled={loading}
                        autoCorrect
                        autoCapitalize={'words'}
                        type={'TEXT'}
                        labelText={`${translate(TranslateConfig.NAME)} *`}
                        placeholderText={`${translate(TranslateConfig.NAME)} *`}
                        errorText={errors.alias}
                        countLimit={Values.nameMaxCount}
                        value={values.alias}
                        onChange={handleChange('alias')}
                        onBlur={handleBlur('alias')}
                      />



                      <InputTextStyled
                        disabled={!user?.canChangeUseralias}
                        autoCorrect
                        autoCapitalize={'words'}
                        type={'TEXT'}
                        labelText={`${translate(TranslateConfig.USERNAME)} *`}
                        placeholderText={`${translate(TranslateConfig.USERNAME)} *`}
                        errorText={errors.useralias}
                        countLimit={Values.usernameMaxCount}
                        value={values.useralias}
                        onChange={handleChange('useralias')}
                        onBlur={handleBlur('useralias')}
                      />

                    </ContactsAttendanceOptionsContent>

                  </DetailsTopViewItems>



                  <DetailsBottomViewItems>

                    <SectionTitle
                      title={translate(TranslateConfig.PROFILE)}
                      color={Colors.primary}
                    />


                    <ContactsAttendanceOptionsContent>

                      <InputViewStyled
                        noShadow
                        disabled={Boolean(userGender)}
                        labelText={`${translate(TranslateConfig.GENRE)} *`}
                        labelColor={Colors.inputLabel}
                        backgroundColor={Colors.transparent}
                        helpText={errors.gender}
                        helpColor={Colors.danger}
                        countColor={Colors.inputCount}
                        countLimit={translate(TranslateConfig.VALIDATE_ONLY_ONE)}>

                        <GenderUserRadio
                          disabled={Boolean(userGender)}
                          data={typeGenders}
                          initial={initialGender}
                          circleSize={16}
                          boxDirection={'row'}
                          direction={'column'}
                          activeColor={Colors.primary}
                          deactiveColor={Colors.inputPlaceholder}
                          textStyle={{
                            marginTop: 5,
                          }}
                          onPress={(item: any) => {
                            setFieldValue('gender', item?.value);
                            setUserGender(item.value);
                          }}
                        />

                      </InputViewStyled>

                    </ContactsAttendanceOptionsContent>

                  </DetailsBottomViewItems>



                  <ContentButtonForm>
                    <ButtonAction
                      width={Sizes.buttonMinWidth}
                      title={translate(TranslateConfig.ACTION_SAVE)}
                      onPress={() => {
                        handleSubmit();
                      }}
                    />
                  </ContentButtonForm>

                </CardContactsForm>

              </ContentPadder>
            )}



            <FooterApp />

          </Content>

        </Screen>

      )}
    </Formik>

  );
};



export default CreateModeleFormScreen;
