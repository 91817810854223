import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  GetHomeFeed,
} from '../../apis/endpoints/home.endpoints';

import {
  GetHomeSuggestions,
} from '../../apis/endpoints/suggestions.endpoints';

import {
  IViewMediaModal,
} from '../../apis/interfaces/Medias/view-media-modal.interface';

import {
  Container,
  Screen,
} from '../../components/Composh/web';

import {
  ButtonDrawer,
} from '../../components/Controls';

import FooterApp from '../../components/Footers/FooterApp';
import HeaderScreen from '../../components/Headers/HeaderScreen';
import LoadingScreen from '../../components/Loadings/LoadingScreen';

import ViewMediaModal from '../../modals/ViewMedia';

import {
  RootState,
} from '../../redux';

import {
  APP_MENU_SELECTED_ACTION,
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../redux/reducers/app-modals.store';

import {
  Colors,
  Images,
} from '../../shared/constants';

import EDrawerMenu from '../../shared/enums/App/drawer-menu.enum';

import {
  TranslateConfig,
} from '../../shared/translations';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../shared/utils/errors.utils';

import HomeCenterContent from './Center';
import LeftSidebarHome from './Left';
import RightSidebarHome from './Right';

import {
  HomeMyPriveLogo,
  HomeViewContainer,
  HomeRowWrapperAuto,
  HomeRowPaddingAuto,
  HomeFooterInsideContainer,
  HomeFooterContainer,
} from './styled';



const MyPriveScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const genderChoose = useSelector((state: RootState) => state.choose.genderChoose);


  const [feedMedias, setFeedMedias] = useState(null);
  const [mediaFindedArray, setMediaFindedArray] = useState<Array<any>>([]);
  const [modeles, setModeles] = useState(null);

  const [showMediaView, setShowMediaView] = useState<any>(null);

  const [loading, setLoading] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function getHomeSuggestions(showLoading = false) {
    try {
      if (showLoading) {
        dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });
      }

      const response = await GetHomeSuggestions(genderChoose);

      if (!response?.data || !Array.isArray(response?.data)) {
        setModeles([]);
        return;
      }

      setModeles(response?.data);
    }
    catch (error: any) {
      console.error(error);
      setModeles([]);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  };


  async function getFeedHome() {
    try {
      setLoading(true);

      await getHomeSuggestions();

      const response = await GetHomeFeed();

      if (!response?.data) {
        setFeedMedias([]);
        return;
      }

      setFeedMedias(response?.data?.home?.feed);
    }
    catch (error: any) {
      console.error(error);
      setFeedMedias([]);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
    }
  };


  function returnArrayChange(indexReceived: number) {
    const indexFindMedia = indexReceived;
    const mediaObject = feedMedias?.find((_item: any, index: number) => index === indexFindMedia);

    const arrayMedia = mediaObject ? [mediaObject] : [];
    setMediaFindedArray(arrayMedia);
  }


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.white}
      />

    );
  }



  useEffect(() => {
    getFeedHome();
  }, [genderChoose]);


  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.MY_PRIVE });
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet prioritizeSeoTags>
        <title>
          {`${translate(TranslateConfig.MY_PRIVE)} | ${translate(TranslateConfig.NAME_APP)}`}
        </title>

        <meta name="robots" content="noindex, nofollow" />
      </Helmet>



      <Container>

        <HeaderScreen
          screenKey={EDrawerMenu.MY_PRIVE}
          showBalance
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={
            <ButtonDrawer
              color={Colors.white}
              size={27}
            />
          }
          centerContent={
            <HomeMyPriveLogo
              src={Images.myPriveLogo}
            />
          }
        />



        <HomeViewContainer>

          <HomeRowWrapperAuto>

            {loading && renderLoading()}



            {!loading && (
              <HomeRowPaddingAuto>

                <LeftSidebarHome />



                <HomeCenterContent
                  dataFeedArray={feedMedias}
                  onShowMedia={(mediaObject: IViewMediaModal) => {
                    returnArrayChange(mediaObject?.indexSelected);
                    setShowMediaView({ indexSelected: 0 });
                  }}
                />



                <RightSidebarHome
                  dataSuggestionArray={modeles}
                  onProfilesSuggestionPress={() => {
                    getHomeSuggestions(true);
                  }}
                />

              </HomeRowPaddingAuto>
            )}


            <HomeFooterInsideContainer>
              <FooterApp />
            </HomeFooterInsideContainer>

          </HomeRowWrapperAuto>



          <HomeFooterContainer>
            <FooterApp />
          </HomeFooterContainer>

        </HomeViewContainer>

      </Container>



      <ViewMediaModal
        visible={Boolean(showMediaView)}
        indexSelected={showMediaView?.indexSelected}
        data={mediaFindedArray}
        onClose={() => {
          setShowMediaView(null);
        }}
      />

    </Screen>

  );
};



export default MyPriveScreen;
