import {
  createAction,
  createReducer,
} from '@reduxjs/toolkit';

import {
  SetGenreColor,
  SetSuitsGenderImage,
} from '../../config/genre.config';

import {
  EGenderUser,
} from '../../shared/enums';



const INITIAL_STATE = {
  genderChoose: EGenderUser.MAN,
  genderColorApp: SetGenreColor(EGenderUser.MAN),
  suitImageApp: SetSuitsGenderImage(EGenderUser.MAN),
};



// Action Types

export const USER_GENDER_CHOOSE_ACTION = '@user/GENDER_CHOOSE_SWITCH';



// Action Creators

export const genderChooseAction = createAction<object>(USER_GENDER_CHOOSE_ACTION);



// Handlers

const genderChooseReducer = (state = INITIAL_STATE, action) => ({
  ...state,
  genderChoose: action.payload,
  genderColorApp: SetGenreColor(action.payload),
  suitImageApp: SetSuitsGenderImage(action.payload),
});



// Reducers

export default createReducer(INITIAL_STATE, {
  [genderChooseAction.type]: genderChooseReducer,
});
