import styled from 'styled-components';

import {
  BadgeSkewX,
} from '../../../../../components/Badges/BadgeVt';

import {
  ContentPadder,
} from '../../../../../components/Contents/ContentPadder';

import SectionTitle from '../../../../../components/Titles/SectionTitle';

import {
  Colors,
} from '../../../../../shared/constants';

import {
  PlatformDevices,
} from '../../../../../shared/utils/platforms.utils';



export interface IProps {
  color?: string;
}



export const LiveWrapperItemContent = styled(ContentPadder)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const RentViewContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;



export const LiveCamContainer = styled.div`
  display: grid;
  grid-gap: 1px;
  position: relative;
  flex-wrap: unset;
  grid-template-columns: repeat(6, 1fr);
`;



export const LiveHeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-column: span 6;

  @media ${PlatformDevices.maxTablet} {
    flex-direction: column-reverse;
  }
`;

export const LiveRoomUpdateText = styled.p`
  margin-top: 5px;
  margin-bottom: 5px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 13px;

  @media ${PlatformDevices.maxTablet} {
    margin-top: 15px;
    text-align: center;
  }
`;

export const MessageLiveHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 10px;

  @media ${PlatformDevices.maxTablet} {
    width: 100%;
    margin-left: 0;
    justify-content: space-between;
  }
`;

export const FilterButtonLiveHeader = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
`;



export const LiveCamHeaderOptionsView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  grid-column: span 6;
  height: 60px;
  max-height: 60px;
  min-height: 60px;
`;

export const LiveCamHeadingView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

export const LiveCamHeadingTitle = styled(SectionTitle)`
  margin-bottom: 0;
`;

export const LiveModeleSortable = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 0 0 10px;
`;

export const LiveModelewrapSortable = styled.div`
  color: #CFDAE4;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  align-items: center;
  border-right: 1px solid #323541;
  height: 30px;
  padding: 0 6px 0 0;
`;

export const LiveModeleskew = styled(BadgeSkewX)`
  cursor: pointer;
`;

export const LiveSwitchToSeeButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  min-width: 155px;
  margin-left: 7px;
  margin-right: 5px;
`;

export const LiveSwitchToSeeText = styled.p`
  margin-right: 8px;
  color: ${(props: IProps) => props.color};;
  font-size: 13px;
`;

export const LiveModelewrapSortableButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-left: 3px;
  margin-right: 3px;
`;

export const LiveModeleSizewrapSort = styled.div`
  pointer-events: all;
  text-align: left !important;
  color: #CFDAE4;
  font-size: calc(14px + .3vw);
  font-weight: normal;
  margin: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  align-items: center;
  padding: 0 0 0 6px;
`;



export const CardLiveHighlightHigh = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  grid-column: span 2;
  grid-row: span 2;
  padding-bottom: 75%;
  background-color: ${Colors.cardBackground};
`;



export const CardLiveItemNormal = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  padding-bottom: 75%;
  background-color: ${Colors.cardBackground};
`;
