import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../../../shared/constants';



export const EditLinkBioContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  border-right: 1px solid ${'#D7DCE1'};
`;



export const EditLinkBioHeader = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.tabBackground};
`;


export const DetailsApproveViewBackground = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 90px;
`;

export const HeaderUserBackgroundOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${Colors.cardFooterOverlay};
  z-index: 1;
`;

export const DetailsApproveBackground = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;



export const UserLinkBioInfoView = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: -40px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 5px;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  background-color: ${Colors.black};
  border-radius: ${Sizes.cardRadius}px;
  z-index: 5;
`;


export const UserLinkBioAvatarView = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -35px;
  border: 2px solid ${Colors.white};
  border-radius: ${Sizes.cardRadius}px;
`;


export const UserLinkBioDataView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 20px;
  margin-right: 20px;
  border-right: 0.5px solid ${Colors.gray};
`;

export const UserLinkBioCountryText = styled.p`
  color: ${Colors.white};
`;

export const UserLinkBioNameText = styled.p`
  color: ${Colors.white};
`;

export const UserLinkBioUsernameText = styled.p`
  color: ${Colors.white};
`;


export const UserLinkBioLinkView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const UserLinkBioRowLineView = styled.div`
  display: flex;
  flex-direction: row;
`;

export const UserLinkBioLabelView = styled.p`
  color: ${Colors.white};
`;

export const UserLinkSeeLinkButton = styled.p`
  color: ${Colors.white};
`;

export const UserLinkActionsButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
