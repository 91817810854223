import Axios from 'axios';

import {
  ETokenAsync,
} from '../../shared/enums';

import AppEnvironments from '../../shared/environments/application';

import {
  getLocalStorageItem,
} from '../../shared/utils/local-storage.utils';

const baseAccountGlobalUrl = AppEnvironments().apiAccountGlobalUrl;



export const GetUserCreditCard = async () => {
  const token = getLocalStorageItem(ETokenAsync.USER_TOKEN);
  (Axios.defaults.headers as any).authorization = token;


  const url = baseAccountGlobalUrl + '/users/credit-card';

  const response = await Axios.get(url);

  return {
    data: response.data,
  };
};
