import React from 'react';

// import { Container } from './styles';



const AboutScreen: React.FC = () => {
  return (

    <h2>
      AboutScreen
    </h2>

  );
};



export default AboutScreen;
