import styled,
{
  css,
} from 'styled-components';

import {
  Colors,
} from '../../../../shared/constants';



// Header Style

export const MonthlyTypeContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const MonthlyContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  width: 100%;
  margin-top: 10px;
`;



export const MonthlyImageContent = styled.div`
  display: flex;
  flex: 1;
  margin-right: 24px;
`;

export const MonthlyImage = styled.img`
  width: 100%;
`;



export const MonthlyTableContent = styled.div`
  display: flex;
  width: 40%;
`;


export const MonthlyTableComponent = styled.table`
  width: 100%;
  max-width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  font-size: calc(12px + .1vw);
`;

export const MonthlyTablethead = styled.thead`
  border-bottom: 2px solid rgba(150,150,150,0.1);
`;


export const MonthlyTableTh = styled.th`
  color: ${Colors.white};
  padding: 14px;
  text-align: left;
`;


export const MonthlyTableTbody = styled.tbody`
`;

export const MonthlyTableTr = styled.tr`
  :nth-child(odd) td {
    background-color: rgba(150,150,150,0.1);
  }
`;

export const MonthlyTableTd = styled.td`
  padding: 14px;
  text-align: left;
  border-bottom: 1px solid rgba(150,150,150,0.1);
`;

export const MonthlyTableBigStyle = css`
  font-size: 130% !important;
  font-weight: 700;
`;

export const MonthlyTableRankSpan = styled.span`
  color: ${Colors.attention};
  font-size: 14px;
`;

export const MonthlyTablePriceSpan = styled.span`
  color: ${Colors.white};
  font-size: 14px;
`;

export const MonthlyTableBigRankSpan = styled(MonthlyTableRankSpan)`
  ${MonthlyTableBigStyle};
  color: #cead14;
`;

export const MonthlyTableBigPriceSpan = styled(MonthlyTablePriceSpan)`
  ${MonthlyTableBigStyle};
`;

export const MonthlyTableBodyItem = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
`;

export const MonthlyTableBodyIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-right: 7px;
`;



// Body Style

export const MonthlyDescriptionTextView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3rem;
`;

export const MonthlyDescriptionText = styled.p`
  color: ${Colors.white};
  font-size: 18px;
  text-align: center;
  line-height: 1.7rem;
`;



export const MonthlyModeleItemGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 5px;
  margin-top: 10px;
`;
