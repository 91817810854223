import React,
{
  useState,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
} from 'react-redux';

import CardAddMedia from '../../../components/Cards/CardAddMedia/CardAddMedia';

import {
  Container,
  Content,
  Screen,
  Title,
} from '../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../components/Controls';

import FooterApp from '../../../components/Footers/FooterApp';
import HeaderHelp from '../../../components/Headers/HeaderHelp';
import HeaderScreen from '../../../components/Headers/HeaderScreen';

import {
  IconHelp,
  IconLocked,
  IconVerified,
  IconCheck,
} from '../../../components/Icons';

import {
  RootState,
} from '../../../redux';

import {
  Colors,
} from '../../../shared/constants';

import EAppMediaTypeRelation from '../../../shared/enums/Picture/app-media-type-relation.enum';
import EAppMediaVisibility from '../../../shared/enums/Status/app-media-visibility.enum';
import EStatusVisible from '../../../shared/enums/Status/status-visibility.enum';

import {
  TranslateConfig,
} from '../../../shared/translations';

import RightAntiFakeMediaInfo from './Infos';
import AntiFakeAddModal from './Modals/AntiFakeAdd';
import AntiFakeInfoModal from './Modals/AntiFakeInfo';

import {
  ContentWrapperColumnFull,
  ContentCenterFlex,
  FakeUploadItemContent,
  FakeUploadSection,
  FakeUploadHaveRevisedText,
  FakeUploadText,
  FakeUploadVerifiedView,
  FakeUploadVerifiedIcon,
  FakeUploadTitleView,
  FakeUploadTitleIcon,
  FakeUploadTitleText,
  FakeItemSplitText,
  DisableModelContainer,
} from './styled';



const AntiFakeVerifiedScreen: React.FC = () => {
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);


  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);

  const [showVideoModal, setShowVideoModal] = useState(false);


  const antiFakeItem = user?.antiFake?.find((item) => {
    return (
      item.revised === false &&
      item.visible === EStatusVisible.INVISIBLE &&
      item.visibility === EAppMediaVisibility.PUBLIC
    );
  });



  function renderHeaderSection(icon: any, title: string) {
    return (

      <FakeUploadTitleView>
        <FakeUploadTitleIcon>
          {icon}
        </FakeUploadTitleIcon>


        <FakeUploadTitleText>
          {title}
        </FakeUploadTitleText>
      </FakeUploadTitleView>

    );
  }



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet prioritizeSeoTags>
        <title>
          {`${translate('Verificaçao anti-fake')} | ${translate(TranslateConfig.NAME_APP)}`}
        </title>

        <meta name="robots" content="noindex, nofollow" />
      </Helmet>



      <Container>

        <HeaderScreen
          disableGender
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={
            <ButtonBack
              color={Colors.white}
              size={27}
            />
          }
          centerContent={
            <Title
              color={Colors.white}>
              Verificaçao anti-fake
            </Title>
          }
        />



        <Content>

          <ContentWrapperColumnFull>

            <ContentCenterFlex>

              <HeaderHelp
                title={'Mídia anti-fake'}
                onHelpPress={() => {
                  setShowInfoModal(true);
                }}>

                <CardAddMedia
                  title={'Carregar vídeo de verificação'}
                  onPress={() => {
                    setShowVideoModal(true);
                  }}
                />

              </HeaderHelp>



              <FakeUploadItemContent>

                <FakeUploadSection>

                  {antiFakeItem && (
                    <FakeUploadHaveRevisedText>
                      Já há uma mídia anti-fake em análise.
                    </FakeUploadHaveRevisedText>
                  )}


                  <FakeUploadText>
                    O vídeo anti-fake serve para combater anunciantes que eventualmente tentem fazer um anúncio com informações falsas, ela atesta que mesmo que as fotos de ensaio sejam falsas ou tenham muito Photoshop, o Secretio pode compará-las com o vídeo anti-fake e verificar seu perfil.
                  </FakeUploadText>


                  <FakeUploadText>

                    <FakeUploadVerifiedView>
                      Anúncios com documentos verificados e anti-fake validado recebem nosso selo de verificação
                    </FakeUploadVerifiedView>

                    <FakeUploadVerifiedIcon>
                      <IconVerified
                        size={20}
                      />
                    </FakeUploadVerifiedIcon>

                    <FakeUploadVerifiedView>
                      .
                    </FakeUploadVerifiedView>

                  </FakeUploadText>

                </FakeUploadSection>



                <FakeUploadSection>

                  {renderHeaderSection(
                    <IconHelp
                      color={Colors.black}
                      size={20}
                    />,
                    'Como fazer o vídeo anti-fake',
                  )}

                  <FakeItemSplitText>
                    Grave um vídeo mostrando todo o seu corpo.
                  </FakeItemSplitText>

                  <FakeItemSplitText>
                    Siga o exemplo de como fazer no video. Faça exatamente como no exemplo que está ou ao bem ao lado, ou clicando na interrogação quando visível.
                  </FakeItemSplitText>

                  <FakeItemSplitText>
                    O VÍDEO SERÁ POSTADO NO ANÚNCIO e APENAS anunciantes premium terá acesso ao vídeo.
                  </FakeItemSplitText>

                </FakeUploadSection>



                <FakeUploadSection>

                  {renderHeaderSection(
                    <IconCheck
                      color={Colors.black}
                      size={20}
                    />,
                    'Obrigatório',
                  )}

                  <FakeItemSplitText>
                    A gravação deve ser feita com a imagem em pé (virada na vertical, não na horizontal).
                  </FakeItemSplitText>

                  <FakeItemSplitText>
                    Seu rosto DEVE aparecer no vídeo. Caso não queira aparecer, nós cortaremos ou borraremos ele do vídeo.
                  </FakeItemSplitText>

                  <FakeItemSplitText>
                    Seu corpo DEVE aparecer. Faça uma gravação girando o seu corpo por completo.
                  </FakeItemSplitText>

                  <FakeItemSplitText>
                    Você DEVE estar de roupas íntimas ou nu, e seu corpo tem que aparecer INTEIRO;
                  </FakeItemSplitText>

                  <FakeItemSplitText>
                    Caso não esteja conforme as regras, o vídeo NÃO SERÁ ACEITO.
                  </FakeItemSplitText>

                </FakeUploadSection>



                <FakeUploadSection>

                  {renderHeaderSection(
                    <IconLocked
                      color={Colors.black}
                      size={18}
                    />,
                    'Verificação segura',
                  )}

                  <FakeItemSplitText>
                    Os arquivos carregados aqui serão criptografados e não serão públicos de imediato antes dos devidos tratamentos.
                  </FakeItemSplitText>

                  <FakeItemSplitText>
                    As informações que você enviar serão tratadas por funcionários autorizados e usado para fins de verificação.
                  </FakeItemSplitText>

                </FakeUploadSection>

              </FakeUploadItemContent>

            </ContentCenterFlex>



            <DisableModelContainer>
              <RightAntiFakeMediaInfo />
            </DisableModelContainer>

          </ContentWrapperColumnFull>



          <FooterApp />

        </Content>

      </Container>



      <AntiFakeAddModal
        type={EAppMediaTypeRelation.VIDEO_ANTI_FAKE}
        visible={showVideoModal}
        title={'Adicionar vídeo anti-fake'}
        description={'Vídeo anti-fake'}
        accept={'video/*'}
        onClose={() => {
          setShowVideoModal(false);
        }}
      />



      <AntiFakeInfoModal
        visible={showInfoModal}
        onClose={() => {
          setShowInfoModal(false);
        }}>
        <RightAntiFakeMediaInfo />
      </AntiFakeInfoModal>

    </Screen>

  );
};



export default AntiFakeVerifiedScreen;
