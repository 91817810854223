export const reviewMeetArray = [
  {
    id: 1,
    label: 'Nos encontramos presencialmente',
    value: 'IN_PERSON',
  },
  {
    id: 2,
    label: 'Por conversa online',
    value: 'ONLINE_CONVERSATION',
  },
];
