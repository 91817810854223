import {
  IInputPlaceSelect,
} from '../../../apis/interfaces/App/input-select-place.interface';



const CitiesFullBraDf: Array<IInputPlaceSelect> = [
  {
    id: 1,
    label: 'Brasília',
    value: 'BRASILIA',
  },
];



export default CitiesFullBraDf;
