import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../../shared/constants';



export const AddUploadFormContainer = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 15px;
  background-color: ${Colors.cardBackground};
  border: 1px dashed ${Colors.inputMediaAdded};
  border-radius: ${Sizes.cardRadius}px;
  transition: .2s linear;
  cursor: pointer;
  z-index: 1;

  :hover {
    background-color: ${Colors.cardBackgroundHover};
    border-color: ${Colors.white};
    color: ${Colors.white};
  }
`;

export const AddUploadIcon = styled.div`
  display: flex;
  margin-right: 12px;
`;

export const AddFlexFlowInfoView = styled.div`
  display: flex;
  flex-direction: column;
`;


export const AddUploadText = styled.span`
  color: ${Colors.inputLabel};
  font-size: 20px;
  font-weight: 700;
`;

export const AddUploadTextSmall = styled.span`
  margin-top: 5px;
  color: ${Colors.inputLabel};
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
`;
