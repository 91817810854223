import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';



const RequiredText = styled.p`
  margin-bottom: 5px;
  color: ${Colors.danger};
  font-size: 11px;
  text-align: right;
`;



export default RequiredText;
