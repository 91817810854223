import _antiFakeExampleMan from '../assets/videos/anti-fake-example-man.mp4';
import _antiFakeExampleTransman from '../assets/videos/anti-fake-example-trans_man.mp4';
import _antiFakeExampleTranswoman from '../assets/videos/anti-fake-example-trans_woman.mp4';
import _antiFakeExampleWoman from '../assets/videos/anti-fake-example-woman.mp4';


import _crushsMontlyMan from '../assets/videos/crushs_yearly_man.mp4';
import _yearlyGuysV2 from '../assets/videos/yearly-guys-v2.mp4';



export const ANTI_FAKE = {
  antiFakeExampleWoman:             _antiFakeExampleWoman,
  antiFakeExampleMan:               _antiFakeExampleMan,
  antiFakeExampleTranswoman:        _antiFakeExampleTranswoman,
  antiFakeExampleTransman:          _antiFakeExampleTransman,
};


export const RANKINGS = {
  crushsMontlyMan:                  _crushsMontlyMan,
  // crushsMontlyWoman:                _crushsMontlyWoman,

  yearlyGuysV2:                     _yearlyGuysV2,
};



const Videos = {
  ...ANTI_FAKE,
  ...RANKINGS,
};



export default Videos;
