import EStatusProfile from '../../enums/Users/status-profile.enum';



export const userStatusArray = [
  { id: 1, label: 'Em construção', value: EStatusProfile.IN_CONSTRUCTION },
  { id: 2, label: 'Pendente', value: EStatusProfile.PENDING },
  { id: 3, label: 'Ativo', value: EStatusProfile.ACTIVE },
  { id: 4, label: 'Oculto', value: EStatusProfile.HIDE_BY_USER },
  { id: 5, label: 'Desativado', value: EStatusProfile.DISABLED },
  { id: 6, label: 'Banido', value: EStatusProfile.BANNED },
];
