import React from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  useNavigate,
} from 'react-router-dom';

import NameRoutes from '../../../../navigation/names';

import {
  RootState,
} from '../../../../redux';

import {
  Colors,
  Images,
} from '../../../../shared/constants';

import {
  IconFire,
} from '../../../Icons';

import {
  PhotoGridOverlay,
  PhotoGridSpecialText,
  PhotoGridSpecialCoinContent,
  PhotoGridSpecialCoinImage,
  PhotoGridSpecialBadgeText,
} from './styled';



const MediaSpecial: React.FC = () => {
  const history = useNavigate();


  const genderColorChoose = useSelector((state: RootState) => state.choose.genderColorApp);

  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;

  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);



  return (

    <PhotoGridOverlay>

      <IconFire
        color={Colors.white}
        size={50}
      />


      <PhotoGridSpecialText>
        Especial
      </PhotoGridSpecialText>


      {projectParams?.canAccess && (
        <PhotoGridSpecialCoinContent
          disabled={!availableUser}
          backgroundColor={genderColorChoose}
          onClick={() => {
            if (!availableUser) {
              history(NameRoutes.AccessScreen);
              return;
            }

            console.log('I WANT ACCESS');
          }}>

          <PhotoGridSpecialCoinImage
            alt={'Coin'}
            src={Images.gencoin}
          />


          <PhotoGridSpecialBadgeText>
            $ 10,00
          </PhotoGridSpecialBadgeText>
        </PhotoGridSpecialCoinContent>
      )}

    </PhotoGridOverlay>

  );
};



export default MediaSpecial;
