import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../../shared/constants';

import {
  PlatformDevices,
} from '../../../../shared/utils/platforms.utils';

const marginItems = 8;



export const VideoBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 4px;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 4px;
  aspect-ratio: 1;

  @media ${PlatformDevices.maxTablet} {
    width: 50%;
    min-width: 50%;
    max-width: 50%;
  }

  @media ${PlatformDevices.minTablet} {
    width: calc(100% / 3);
    min-width: calc(100% / 3);
    max-width: calc(100% / 3);
  }
  
  @media ${PlatformDevices.minLaptop} {
    width: 25%;
    min-width: 25%;
    max-width: 25%;
  }
  
  @media ${PlatformDevices.minDesktop} {
    width: 20%;
    min-width: 20%;
    max-width: 20%;
  }
`;

export const VideoBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;

export const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
`;

export const LazyImage = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;


export const RibbonPhotoLevel = styled.span`
  position: absolute;
  top: ${marginItems}px;
  left: ${marginItems}px;
  padding: 4px 6px;
  background-color: #292828;
  color: ${Colors.white};
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  border-radius: 6px;
`;

export const RibbonPending = styled.p`
  position: absolute;
  bottom: ${40}px;
  left: ${marginItems}px;
  max-width: 85px;
  padding: 4px 6px;
  color: ${Colors.white};
  background-color: ${Colors.danger};
  font-size: 11px;
  line-height: 14px;
  font-weight: 500;
  border-radius: 6px;
`;

export const RibbonTopInfo = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: ${marginItems}px;
  left: ${marginItems}px;
  padding: 4px 6px;
  background-color: ${Colors.black};
  border-radius: 6px;
  gap: 6px;
`;

export const RibbonVideoDuration = styled.p`
  color: ${Colors.white};
  font-size: 13px;
  text-align: right;
  text-shadow: 1px 1px 1px rgba(0,0,0,.7);
`;

export const RibbonHdVideoInserted = styled.span`
  color: ${Colors.warning};
  font-size: 13px;
  font-weight: 500;
  text-align: right;
  text-shadow: 1px 1px 1px rgba(0,0,0,.7);
`;


export const VideoIcon = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 60px;
  width: 60px;
  background-color: rgba(47, 41, 41, 0.5);
  transform: translate(-50%,-50%);
  border-radius: 50%;
`;



export const CardControlsContent = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: ${marginItems}px;
  right: ${marginItems}px;
`;

export const CanBuyRibbon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: ${Colors.alertOverlay};
  border-radius: 50px;
`;



export const ImageActionContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: ${marginItems}px;
  right: ${marginItems}px;
`;

export const ImageActionButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-left: 6px;
  background-color: ${Colors.alertOverlay};
  border-radius: 50px;
`;



export const HideVideoContent = styled.p`
  width: 100%;
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: ${Colors.attention};
  color: ${Colors.white};
  font-size: 12px;
  text-align: center;
`;
