import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';



export const ContentWrapperColumnFull = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
`;

export const ContentCenterFlex = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;



export const FakeUploadItemContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
`;

export const FakeUploadSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`;

export const FakeUploadHaveRevisedText = styled.p`
  margin-top: 5px;
  margin-bottom: 10px;
  color: ${Colors.danger};
  font-size: 24px;
  font-weight: bold;
`;

export const FakeUploadText = styled.p`
  display: inline-flex;
  margin-top: 8px;
  margin-bottom: 8px;
  color: ${Colors.white};
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
`;


export const FakeUploadVerifiedView = styled.span`
`;

export const FakeUploadVerifiedIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 6px;
  margin-right: 4px;
`;



export const FakeUploadTitleView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

export const FakeUploadTitleIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 23px;
  height: 23px;
  margin-right: 10px;
  background-color: ${Colors.primary};
  border-radius: 50%;
`;

export const FakeUploadTitleText = styled.p`
  color: ${Colors.white};
  font-size: 16px;
  font-weight: 500;
`;



export const FakeItemSplitText = styled.p`
  position: relative;
  margin-bottom: 6px;
  margin-left: 5px;
  padding-left: 15px;
  color: ${Colors.grayLight};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  
  :before{
    content: '';
    position: absolute;
    top: 8px;
    left: -2px;
    width: 5px;
    height: 5px;
    background-color: ${Colors.primary};
    border-radius: 9999px;
  }
`;



export const DisableModelContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${Sizes.contentRightHomeWidth}px;
  margin-left: ${Sizes.contentMargin}px;
  padding-top: 20px;
  padding-left: 5px;
  padding-right: ${Sizes.paddingContainer}px;
  padding-bottom: ${Sizes.paddingContainer}px;

  @media ${PlatformDevices.maxLaptopL} {
    display: none;
  }
`;
