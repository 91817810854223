import React from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  USER_BRAND_PROMOTER_MODAL,
  USER_VERIFIED_MODAL,
} from '../../../redux/reducers/app-modals.store';

import {
  Images,
} from '../../../shared/constants';

import {
  EPlansUser,
} from '../../../shared/enums';

import UserAvatar from '../../Contents/UserAvatar';

import {
  IconVerified,
  IconVerifiedPromoter,
} from '../../Icons';

import {
  UserReviewMakeInfoContainer,
  UserHowPublishButton,
  UserReviewMakeInfoView,
  UserReviewMakeAliasText,
  MenuVerifiedContent,
  MenuUserNameText,
  IconVerifiedView,
} from './styled';



export interface IProps {
  modeleName?: string;
  modeleUsername?: string;
  modelePlan?: EPlansUser;
  modeleImage?: string;
  verified?: boolean;
  brandPromoter?: boolean;
  onUserPress?: any;
}



const UserHeaderInfo: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();
  return (

    <UserReviewMakeInfoContainer>

      <UserHowPublishButton
        onClick={() => {
          if (props.onUserPress) {
            props.onUserPress();
          }
        }}>
        <UserAvatar
          plan={props.modelePlan}
          image={props.modeleImage
            ? props.modeleImage
            : Images.noImage
          }
          width={45}
        />
      </UserHowPublishButton>


      <UserReviewMakeInfoView>

        <UserReviewMakeAliasText
          onClick={() => {
            if (props.onUserPress) {
              props.onUserPress();
            }
          }}>
          {props.modeleName || '-'}
        </UserReviewMakeAliasText>



        <MenuVerifiedContent>

          <MenuUserNameText
            onClick={() => {
              if (props.onUserPress) {
                props.onUserPress();
              }
            }}>
            @{props.modeleUsername || '-'}
          </MenuUserNameText>


          {props.verified && (
            <IconVerifiedView
              onClick={() => {
                dispatch({ type: USER_VERIFIED_MODAL, payload: true });
              }}>
              <IconVerified
                size={13}
              />
            </IconVerifiedView>
          )}


          {props.brandPromoter && (
            <IconVerifiedView
              onClick={() => {
                dispatch({ type: USER_BRAND_PROMOTER_MODAL, payload: true });
              }}>
              <IconVerifiedPromoter
                size={13}
              />
            </IconVerifiedView>
          )}

        </MenuVerifiedContent>

      </UserReviewMakeInfoView>

    </UserReviewMakeInfoContainer>

  );
};



export default UserHeaderInfo;
