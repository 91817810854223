import { createAction, createReducer } from '@reduxjs/toolkit';

// Estado Inicial
const INITIAL_STATE = {
  appearance: {
    profile: {},
    themes: {
      selected: '',
      list: [],
    },
  },
  config: {
    social: {},
  },
  items: [],
};

// Tipos de Ação
export const LINKBIO_UPDATE_PROFILE = '@linkbio/update-profile';
export const LINKBIO_UPDATE_THEME = '@linkbio/update-theme';
export const LINKBIO_DELETE_THEME = '@linkbio/delete-theme';
export const LINKBIO_UPDATE_SOCIAL_NETWORKS = '@linkbio/update-socialNetworks';
export const LINKBIO_ADD_ITEM = '@linkbio/add-item';
export const LINKBIO_UPDATE_ITEM = '@linkbio/update-item';
export const LINKBIO_DELETE_ITEM = '@linkbio/delete-item';

// Criadores de Ação
export const updateProfileAction = createAction(LINKBIO_UPDATE_PROFILE);
export const updateThemeAction = createAction(LINKBIO_UPDATE_THEME);
export const deleteThemeAction = createAction(LINKBIO_DELETE_THEME);
export const updateSocialNetworksAction = createAction(LINKBIO_UPDATE_SOCIAL_NETWORKS);
export const addItemAction = createAction(LINKBIO_ADD_ITEM);
export const updateItemAction = createAction(LINKBIO_UPDATE_ITEM);
export const deleteItemAction = createAction(LINKBIO_DELETE_ITEM);

// Manipuladores de Ação
const updateProfileReducer = (state, action) => {
  const values = action.payload;
  for (const prop of Object.keys(values)) {
    const value = values[prop];
    state.appearance.profile[prop] = value;
  }
};

const updateThemeReducer = (state, action) => {
  const theme = action.payload;
  state.appearance.themes.selected = theme;
};

const deleteThemeReducer = (state, action) => {
  const themeName = action.payload.name;
  state.appearance.themes.list = state.appearance.themes.list.filter(
    (theme) => theme.name !== themeName,
  );
};

const updateSocialNetworksReducer = (state, action) => {
  const values = action.payload;
  for (const prop of Object.keys(values)) {
    const value = values[prop];
    state.config.social[prop] = value;
  }
};

const addItemReducer = (state, action) => {
  const typeItem = action.payload.type;
  const item = {
    id: 'shortid.generate()', // Substitua isso pelo seu gerador de ID real
    type: typeItem,
    props: { enabled: true, title: 'Title', url: '' },
  };
  state.items.push(item);
};

const deleteItemReducer = (state, action) => {
  const indexItem = action.payload.index;
  state.items.splice(indexItem, 1);
};

const updateItemReducer = (state, action) => {
  const { index: indexItem, data: values } = action.payload;
  for (const prop of Object.keys(values)) {
    const value = values[prop];
    state.items[indexItem].props = {
      ...state.items[indexItem].props,
      [prop]: value,
    };
  }
};

// Reducer Principal
export default createReducer(INITIAL_STATE, {
  [updateProfileAction.type]: updateProfileReducer,
  [updateThemeAction.type]: updateThemeReducer,
  [deleteThemeAction.type]: deleteThemeReducer,
  [updateSocialNetworksAction.type]: updateSocialNetworksReducer,
  [addItemAction.type]: addItemReducer,
  [deleteItemAction.type]: deleteItemReducer,
  [updateItemAction.type]: updateItemReducer,
});
