import styled,
{
  css,
} from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../shared/constants';

import {
  PlatformDevices,
} from '../../shared/utils/platforms.utils';



export interface IProps {
  centerItems?: boolean;
  fontSize?: number;
}



export const CenterEmptyStyle = css`
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const StartEmptyStyle = css`
  align-items: center;
  padding-top: 40px;
  padding-bottom: 10px;

  @media ${PlatformDevices.maxTabletM} {
    padding-top: 10px;
  }
`;

export const EmptyContentView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 35px;
  padding-right: 35px;

  ${(props: IProps) => props.centerItems ? CenterEmptyStyle : StartEmptyStyle}
`;

export const EmptyImage = styled.img`
  height: 160px;
  width: 160px;
  object-fit: cover;
  border-radius: ${Sizes.cardRadius}px;
  pointer-events: none;
`;

export const EmptyTitle = styled.p`
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
  color: ${Colors.grayLight};
  font-size: ${(props: IProps) => props.fontSize || 17}px;
  font-style: italic;
  font-weight: bold;
  text-align: center;
  pointer-events: none;
`;

export const EmptyDescription = styled.p`
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  color: ${Colors.gray};
  font-size: ${(props: IProps) => props.fontSize || 14}px;
  font-style: italic;
  font-weight: 100;
  text-align: center;
  pointer-events: none;
`;


export const EmptyButtonView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
`;
