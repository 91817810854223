import {
  EPlansUser,
} from '../../enums';



export const plansFinancialArray = [
  {
    id: 1,
    value: EPlansUser.NONE,
    label: 'Nenhum',
  },
  {
    id: 2,
    value: EPlansUser.PREMIUM,
    label: 'Premium',
  },
  {
    id: 3,
    value: EPlansUser.BRONZE,
    label: 'Bronze',
  },
  {
    id: 4,
    value: EPlansUser.SILVER,
    label: 'Prata',
  },
  {
    id: 5,
    value: EPlansUser.GOLD,
    label: 'Ouro',
  },
  {
    id: 6,
    value: EPlansUser.GOLD_FREEMIUM,
    label: 'Ouro',
  },
  {
    id: 7,
    value: EPlansUser.PLATINUM,
    label: 'Platinum',
  },
  {
    id: 8,
    value: EPlansUser.DIAMOND,
    label: 'Diamante',
  },
];
