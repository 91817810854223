import styled from 'styled-components';

import {
  Sizes,
} from '../../../../shared/constants';

import {
  PlatformDevices,
} from '../../../../shared/utils/platforms.utils';



export const ContentRightProfile = styled.div`
  display: flex;
  flex-direction: column;
  width: ${265}px;
  margin-left: ${Sizes.contentMargin}px;

  @media ${PlatformDevices.maxLaptop} {
    display: none;
  }
`;

export const WrapperItemContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 25px;
`;

export const DetailsOnlineList = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
