import React from 'react';

import {
  Colors,
} from '../../../shared/constants';

import {
  Badge as BadgeComposh,
} from '../../Composh/web';

import {
  BadgeComposhContainer,
} from './styled';



export interface IProps {
  text: string;
  backgroundColor?: string;
  color?: string;
}



const BadgeApp: React.FC<IProps> = (props: IProps) => {
  return (

    <BadgeComposhContainer
      backgroundColor={props.backgroundColor || Colors.primaryDark}>

      <BadgeComposh.Text
        color={props.color || Colors.black}>
        {props.text}
      </BadgeComposh.Text>

    </BadgeComposhContainer>

  );
};



export default BadgeApp;
