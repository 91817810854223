import styled from 'styled-components';

export const ActionsBox = styled.div`
  display: flex;
  align-items: stretch;
  gap: 12px;
  margin-bottom: 24px;

  button:first-child {
    flex: 1;
  }
`;

export const ItemsGrid = styled.ul`
  display: grid;
  grid-gap: 24px;
`;
