import {
  createAction,
  createReducer,
} from '@reduxjs/toolkit';

import EDrawerMenu from '../../shared/enums/App/drawer-menu.enum';



const INITIAL_STATE = {
  menuSelected: EDrawerMenu.MY_PRIVE,

  modalLoading: false,

  modalLocationApp: false,
  genderModal: false,

  coinsModal: {
    visible: false,
    disabled: false,
  },

  travelsModal: null,
  sendGiftModal: false,
  faClubModal: false,
  brandPromoterModal: false,
  verifiedModal: false,
  badReportModal: false,

  drawer: false,
  drawerOff: false,
  snackBar: {
    visible: false,
    color: null,
    text: null,
  },
};



// Action Types

export const APP_MENU_SELECTED_ACTION = '@user/MENU_SELECTED_ACTION';

export const APP_MODAL_LOADING_ACTION = '@user/MODAL_ACTION';

export const MODAL_LOCATION_APP_ACTION = '@company/MODAL_LOCATION_APP_ACTION';
export const USER_GENDER_MODAL = '@user/GENDER_MODAL';

export const USER_COIN_MODAL = '@user/USER_COIN_MODAL';
export const USER_SEND_GIFT_MODAL = '@user/USER_SEND_GIFT_MODAL';
export const USER_TRAVELS_MODAL = '@user/USER_TRAVELS_MODAL';
export const USER_FACLUB_MODAL = '@user/FACLUB_MODAL';
export const USER_BRAND_PROMOTER_MODAL = '@user/USER_BRAND_PROMOTER_MODAL';
export const USER_VERIFIED_MODAL = '@user/USER_VERIFIED_MODAL';
export const USER_BAD_REPORT_MODAL = '@user/USER_BAD_REPORT_MODAL';

export const APP_DRAWER_ACTION = '@user/DRAWER_ACTION';
export const APP_DRAWER_OFF_ACTION = '@user/APP_DRAWER_OFF_ACTION';
export const APP_SNACKBAR_ACTION = '@user/SNACKBAR_ACTION';
export const APP_SNACKBAR_RESET = '@company/SNACKBAR_RESERT';
export const APP_RESET_APP = '@company/RESET_APP';



// Action Creators

export const menuSelectedAction = createAction<object>(APP_MENU_SELECTED_ACTION);

export const modalLoadingAction = createAction<object>(APP_MODAL_LOADING_ACTION);

export const modalLocationAppAction = createAction<object>(MODAL_LOCATION_APP_ACTION);
export const genderModalAction = createAction<object>(USER_GENDER_MODAL);

export const coinsModalAction = createAction<object>(USER_COIN_MODAL);
export const travelsModalAction = createAction<object>(USER_TRAVELS_MODAL);
export const sendGiftModalAction = createAction<object>(USER_SEND_GIFT_MODAL);
export const faClubModalAction = createAction<object>(USER_FACLUB_MODAL);
export const brandPromoterModalAction = createAction<object>(USER_BRAND_PROMOTER_MODAL);
export const verifiedModalAction = createAction<object>(USER_VERIFIED_MODAL);
export const badReportModalAction = createAction<object>(USER_BAD_REPORT_MODAL);

export const drawerAction = createAction<object>(APP_DRAWER_ACTION);
export const drawerOffAction = createAction<object>(APP_DRAWER_OFF_ACTION);
export const snackbarAction = createAction<object>(APP_SNACKBAR_ACTION);
export const snackbarResetAction = createAction<object>(APP_SNACKBAR_RESET);
export const resetAppAction = createAction<object>(APP_RESET_APP);



// Handlers

const menuSelectedReducer = (state = INITIAL_STATE, action: any) => ({
  ...state,
  menuSelected: action.payload,
});


const modalLoadingReducer = (state = INITIAL_STATE, action: any) => ({
  ...state,
  modalLoading: action.payload,
});



const modalLocationAppReducer = (state = INITIAL_STATE, action: any) => ({
  ...state,
  modalLocationApp: action.payload,
});


const genderReducer = (state = INITIAL_STATE, action) => ({
  ...state,
  genderModal: action.payload,
});



const openCoinsReducer = (state = INITIAL_STATE, action) => ({
  ...state,
  coinsModal: action.payload,
});


const travelsReducer = (state = INITIAL_STATE, action) => ({
  ...state,
  travelsModal: action.payload,
});


const openSendGiftReducer = (state = INITIAL_STATE, action) => ({
  ...state,
  sendGiftModal: action.payload,
});


const faClubReducer = (state = INITIAL_STATE, action) => ({
  ...state,
  faClubModal: action.payload,
});


const openBrandPromoterReducer = (state = INITIAL_STATE, action) => ({
  ...state,
  brandPromoterModal: action.payload,
});


const openVerifiedReducer = (state = INITIAL_STATE, action) => ({
  ...state,
  verifiedModal: action.payload,
});


const badReportReducer = (state = INITIAL_STATE, action) => ({
  ...state,
  badReportModal: action.payload,
});



const drawerReducer = (state = INITIAL_STATE, action) => ({
  ...state,
  drawer: action.payload,
});


const drawerOffReducer = (state = INITIAL_STATE, action) => ({
  ...state,
  drawerOff: action.payload,
});


const snackbarReducer = (state = INITIAL_STATE, action: any) => ({
  ...state,
  snackBar: action.payload,
});


const snackbarResetReducer = (state = INITIAL_STATE) => ({
  ...state,
  snackBar: INITIAL_STATE.snackBar,
});


const resetAppStates = () => ({
  ...INITIAL_STATE,
});



// Reducers

export default createReducer(INITIAL_STATE, {
  [menuSelectedAction.type]: menuSelectedReducer,

  [modalLoadingAction.type]: modalLoadingReducer,

  [modalLocationAppAction.type]: modalLocationAppReducer,
  [genderModalAction.type]: genderReducer,

  [coinsModalAction.type]: openCoinsReducer,
  [travelsModalAction.type]: travelsReducer,
  [sendGiftModalAction.type]: openSendGiftReducer,
  [faClubModalAction.type]: faClubReducer,
  [brandPromoterModalAction.type]: openBrandPromoterReducer,
  [verifiedModalAction.type]: openVerifiedReducer,
  [badReportModalAction.type]: badReportReducer,

  [drawerAction.type]: drawerReducer,
  [drawerOffAction.type]: drawerOffReducer,
  [snackbarAction.type]: snackbarReducer,
  [snackbarResetAction.type]: snackbarResetReducer,
  [resetAppAction.type]: resetAppStates,
});
