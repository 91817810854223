import {
  IInputPlaceSelect,
} from '../../../apis/interfaces/App/input-select-place.interface';



const CitiesFullBraMg: Array<IInputPlaceSelect> = [
  {
    id: 3,
    label: 'Betim',
    value: 'BETIM',
  },
  {
    id: 1,
    label: 'Belo Horizonte',
    value: 'BELO_HORIZONTE',
  },
  {
    id: 2,
    label: 'Contagem',
    value: 'CONTAGEM',
  },
  {
    id: 9,
    label: 'Governador Valadares',
    value: 'GOVERNADOR_VALADARES',
  },
  {
    id: 10,
    label: 'Ipatinga',
    value: 'IPATINGA',
  },
  {
    id: 5,
    label: 'Juiz de Fora',
    value: 'JUIZ_DE_FORA',
  },
  {
    id: 6,
    label: 'Montes Claros',
    value: 'MONTES_CLAROS',
  },
  {
    id: 7,
    label: 'Ribeirão das Neves',
    value: 'RIBEIRAO_DAS_NEVES',
  },
  {
    id: 8,
    label: 'Uberaba',
    value: 'UBERABA',
  },
  {
    id: 4,
    label: 'Uberlândia',
    value: 'UBERLANDIA',
  },
];



export default CitiesFullBraMg;
