import {
  IGenderHighlight,
} from '../../../apis/interfaces/Genders/gender-highlight.interface';

import {
  Images,
  Videos,
} from '../../constants';

import {
  EGenderUser,
} from '../../enums';



export const arrayModelesHighlights: Array<IGenderHighlight> = [
  {
    gender: EGenderUser.WOMAN,
    alias: '',
    useralias: '',
    imageHorizontal: Images.homeWomanHorizontal,
    imageVertical: Images.homeWomanVertical,
    imageUserUpgrade: Images.adsUserUpgradeWoman,
    imageModeleUpgrade: Images.adsModeleUpgradeWoman,
    imagePremium: Images.upgradeWoman,
    imageBeModele: Images.beModeleWoman,
    videoAntiFake: Videos.antiFakeExampleWoman,
  },
  {
    gender: EGenderUser.MAN,
    alias: '',
    useralias: '',
    imageHorizontal: Images.homeManHorizontal,
    imageVertical: Images.homeManVertical,
    imageUserUpgrade: Images.adsUserUpgradeMan,
    imageModeleUpgrade: Images.adsModeleUpgradeMan,
    imagePremium: Images.upgradeMan,
    imageBeModele: Images.beModeleMan,
    videoAntiFake: Videos.antiFakeExampleMan,
  },
  {
    gender: EGenderUser.TRANS_WOMAN,
    alias: '',
    useralias: '',
    imageHorizontal: Images.homeTransWomanHorizontal,
    imageVertical: Images.homeTransWomanVertical,
    imageUserUpgrade: Images.adsUserUpgradeTransWoman,
    imageModeleUpgrade: Images.adsModeleUpgradeTransWoman,
    imagePremium: Images.upgradeTransWoman,
    imageBeModele: Images.beModeleTransWoman,
    videoAntiFake: Videos.antiFakeExampleTranswoman,
  },
  {
    gender: EGenderUser.TRANS_MAN,
    alias: '',
    useralias: '',
    imageHorizontal: Images.homeTransManHorizontal,
    imageVertical: Images.homeTransManVertical,
    imageUserUpgrade: Images.adsUserUpgradeTransMan,
    imageModeleUpgrade: Images.adsModeleUpgradeTransMan,
    imagePremium: Images.upgradeTransMan,
    imageBeModele: Images.beModeleTransMan,
    videoAntiFake: Videos.antiFakeExampleTransman,
  },
];
