import React from 'react';

import {
  useSelector,
} from 'react-redux';

import Color from 'color';

import {
  RootState,
} from '../../redux';

import {
  Colors,
} from '../../shared/constants';

import {
  IconAdd,
  IconCheck,
} from '../Icons';

import {
  ChipStyled,
} from './styled';



export interface IProps {
  thumb?: any;
  hideIcon?: boolean;
  icon?: any;
  disabled?: boolean;
  selected?: boolean;
  title?: any;
  backgroundColor?: string;
  color?: string;
  selectedBackgroundColor?: string;
  selectedColor?: string;
  textStyle?: any;
  onPress?: any;
}



const ChipApp: React.FC<IProps> = (props: IProps) => {
  const genderColorChoose = useSelector((state: RootState) => state.choose.genderColorApp);
  const accentGender = props.selectedBackgroundColor || Color(genderColorChoose).darken(0.2).toString();



  return (

    <ChipStyled
      onCloseDisabled
      clickable={Boolean(props.onPress)}
      disabled={props.disabled}
      selected={props.selected}
      title={props.title}
      textStyle={{
        ...props.textStyle,
        whiteSpace: 'noWrap',
      }}
      textColor={props.color || Colors.inputPlaceholder}
      backgroundColor={props.backgroundColor || Colors.inputBackground}
      selectedTextColor={props.selectedColor || Colors.white}
      selectedBackgroundColor={accentGender}
      thumb={props.thumb}
      icon={props.hideIcon
        ? null
        : props.icon
          ? props.icon
          : props.selected
            ? (
              <IconCheck
                size={18}
                color={Colors.white}
              />
            )
            : (
              <IconAdd
                size={18}
                color={Colors.inputPlaceholder}
              />
            )
      }
      onPress={props.onPress}
    />

  );
};



export default ChipApp;
