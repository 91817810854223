import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../shared/constants';



export const CreditsModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  max-width: 500px;
  min-width: 320px;
  background-color: ${Colors.appBackground};
  border-radius: ${Sizes.cardRadius}px;
`;

export const CreditsModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  padding-bottom: 16px;
`;
