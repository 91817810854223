import styled from 'styled-components';

import {
  Images,
} from '../../../../shared/constants';



export interface IProps {
  image?: any;
}


export const FotyHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  padding: 50px 15px;
  background-image: url(${Images.backgroundFotyGuys});
  background-size: 150%;
  background-position: bottom center;
`;

export const FotyHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  max-width: 580px;
  margin-bottom: 25px;
`;


export const FotyLogo = styled.img`
  width: 100%;
  max-width: 580px;
  padding: 40px;
`;

export const FotyVideo = styled.video`
  width: 100%;
  max-width: 100%;
  height: auto;
  margin-top: 5px;
  margin-bottom: 30px;
`;


export const FotyInfoTextHeaderView = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const FotyHeaderTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 25px;
  color: #2C91AF;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 32px;
  font-weight: 300;
  line-height: normal;
  text-align: center;
`;

export const FotyHeaderText = styled.div`
  margin: 0;
  color: #FFF;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 13px;
  line-height: 1.5;
  text-align: center;
`;



export const FotyContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 30px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
`;

export const FotyInfoViewContents = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 75%;
  margin: -1px 0px 20px 0px;
  padding: 10px;
`;

export const FotyOverall = styled.div`


-webkit-font-smoothing: antialiased;
color: #CCC !important;
box-sizing: border-box;
font-family: 'Open Sans', sans-serif !important;
font-size: 13px;
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: flex-start;
padding-bottom: 100px;
width: 100%;
`;

export const Fotybgbluebig = styled.div`


-webkit-font-smoothing: antialiased;
color: #CCC !important;
margin: 0;
padding: 0;
border: 0;
outline: 0;
vertical-align: baseline;
background: transparent;
box-sizing: border-box;
font-family: 'Open Sans', sans-serif !important;
font-size: 13px;
position: absolute;
top: 90px;
left: 50%;
width: calc(100vw - 15px);
transform: translateX(-50%);
background-size: contain;
background-position: center;
background-repeat: no-repeat;
background-image: url(${Images.blue_big});
padding-top: calc(1011 / 1916 * 100%);
z-index: 0;
max-width: 100%;
`;

export const Fotybgbluesmall = styled.div`


-webkit-font-smoothing: antialiased;
color: #CCC !important;
margin: 0;
padding: 0;
border: 0;
outline: 0;
vertical-align: baseline;
background: transparent;
box-sizing: border-box;
font-family: 'Open Sans', sans-serif !important;
font-size: 13px;
position: absolute;
top: 90px;
left: 50%;
width: calc(100vw - 15px);
transform: translateX(-50%);
background-size: contain;
background-position: center;
background-repeat: no-repeat;
z-index: 1;
background-image: url(${Images.blue_small});
padding-top: calc(691 / 1310 * 100%);
max-width: 100%;
`;

export const Fotycontestmaincontent = styled.div`


-webkit-font-smoothing: antialiased;
color: #CCC !important;
margin: 0;
padding: 0;
border: 0;
outline: 0;
vertical-align: baseline;
background: transparent;
display: flex !important;
box-sizing: border-box;
font-family: 'Open Sans', sans-serif !important;
font-size: 13px;
flex-direction: column;
width: 100%;
position: relative;
z-index: 1;
`;

export const Fotycontesttopcontent = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: flex-start;
width: 100%;
z-index: 1;
`;

export const FotysectionText = styled.p`


-webkit-font-smoothing: antialiased;
box-sizing: border-box;
font-family: 'Open Sans', sans-serif !important;
font-size: 13px;
color: #CCC !important;
margin-bottom: 0;
`;

export const FotycontestprizeVIEW = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Fotycontesttitleprize = styled.h2`


-webkit-font-smoothing: antialiased;
color: #CCC !important;
text-align: right;
margin: 0;
padding: 0;
border: 0;
outline: 0;
vertical-align: baseline;
background: transparent;
box-sizing: border-box;
font-family: 'Open Sans', sans-serif !important;
font-size: 13px;
`;

export const Fotycontestprizeamount = styled.p`

-webkit-font-smoothing: antialiased;
text-align: right;
margin: 0;
padding: 0;
border: 0;
outline: 0;
vertical-align: baseline;
background: transparent;
box-sizing: border-box;
font-family: 'Open Sans', sans-serif !important;
color: #2C91AF;
font-weight: 600;
font-size: 42px;
line-height: 0.9;
`;

export const Fotymainbadge = styled.img`


-webkit-font-smoothing: antialiased;
color: #CCC !important;
text-align: right;
padding: 0;
border: 0;
outline: 0;
vertical-align: baseline;
background: transparent;
box-sizing: border-box;
font-family: 'Open Sans', sans-serif !important;
font-size: 13px;
width: 160px;
margin: 15px -15px;
height: auto;
max-width: 100%;
`;



export const Fotycontestmainwinners = styled.div`


-webkit-font-smoothing: antialiased;
color: #CCC !important;
margin: 0;
padding: 0;
border: 0;
outline: 0;
vertical-align: baseline;
background: transparent;
box-sizing: border-box;
font-family: 'Open Sans', sans-serif !important;
font-size: 13px;
display: flex;
flex-direction: column;
align-items: center;
text-align: center;
width: 100%;
z-index: 1;
`;

export const Fotytopgrid = styled.div`


-webkit-font-smoothing: antialiased;
color: #CCC !important;
text-align: center;
margin: 0;
padding: 0;
border: 0;
outline: 0;
vertical-align: baseline;
background: transparent;
box-sizing: border-box;
font-family: 'Open Sans', sans-serif !important;
font-size: 13px;
width: 100%;
display: flex;
align-items: flex-end;
justify-content: center;
gap: 12px;
z-index: 1;
`;

export const Fotytopmodel = styled.a`


-webkit-font-smoothing: antialiased;
text-align: center;
text-decoration: underline;
color: #00a9e7;
box-sizing: border-box;
font-family: 'Open Sans', sans-serif !important;
font-size: 13px;
position: relative;
background-size: cover;
background-position: center;
background-repeat: no-repeat;
transition: all 0.3s ease-in-out;
order: 2;
flex: 1 1 40%;
padding-bottom: 25%;
background-image: url(${Images.avatarUserImage});
`;

export const FotytopmodelMIN = styled(Fotytopmodel)`
flex: 1 1 30%;
padding-bottom: 20%;
`;

export const Fotytopinfo = styled.div`


-webkit-font-smoothing: antialiased;
text-align: center;
color: #00a9e7;
padding: 0;
border: 0;
outline: 0;
vertical-align: baseline;
background: transparent;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
max-width: 1760px;
margin: 0 auto;
box-sizing: border-box;
font-family: 'Open Sans', sans-serif !important;
font-size: 13px;
position: absolute;
bottom: 0;
left: 0;
right: 0;
`;

export const FotytopplaceBadge = styled.div`


-webkit-font-smoothing: antialiased;
text-align: center;
margin: 0;
padding: 0;
border: 0;
outline: 0;
vertical-align: baseline;
background: transparent;
box-sizing: border-box;
font-family: 'Open Sans', sans-serif !important;
font-size: 13px;
position: absolute;
top: -36px;
left: calc((100% - 210px) / 2 - 20px);
width: 84px;
height: 68px;
font-weight: 800;
text-transform: uppercase;
color: #000;
text-shadow: 0px 1px 0px #FFF;
display: flex;
justify-content: center;
align-items: center;
z-index: 1;
background-image: url(${(props: IProps) => props.image});
`;

export const Fotytopplacenum = styled.span`


-webkit-font-smoothing: antialiased;
text-align: center;
font-weight: 800;
text-transform: uppercase;
color: #000;
text-shadow: 0px 1px 0px #FFF;
margin: 0;
padding: 0;
border: 0;
outline: 0;
vertical-align: baseline;
background: transparent;
box-sizing: border-box;
font-family: 'Open Sans', sans-serif !important;
font-size: 16px;
margin-bottom: 13px;
`;

export const Fotytopplacesuffix = styled.span`


-webkit-font-smoothing: antialiased;
text-align: center;
font-weight: 800;
text-transform: uppercase;
color: #000;
text-shadow: 0px 1px 0px #FFF;
margin: 0;
padding: 0;
border: 0;
outline: 0;
vertical-align: baseline;
background: transparent;
box-sizing: border-box;
font-family: 'Open Sans', sans-serif !important;
font-size: 10px;
margin-bottom: 8px;
margin-right: 6px;
`;

export const Fotytoplabel = styled.div`


-webkit-font-smoothing: antialiased;
margin: 0;
border: 0;
outline: 0;
vertical-align: baseline;
box-sizing: border-box;
font-family: 'Open Sans', sans-serif !important;
font-size: 13px;
position: absolute;
left: 50%;
transform: translateX(-50%);
bottom: -30px;
width: 210px;
max-width: 100%;
clip-path: polygon(7% 0, 100% 0%, 93% 100%, 0% 100%);
background: transparent linear-gradient(258deg, #3DA2D8 0%, #2E4CB2 100%) 0% 0% no-repeat padding-box;
text-align: center;
color: #FFF !important;
padding: 5px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 48px;
`;

export const Fotytopname = styled.h2`


-webkit-font-smoothing: antialiased;
text-align: center;
color: #FFF !important;
margin: 0;
padding: 0;
border: 0;
outline: 0;
vertical-align: baseline;
background: transparent;
box-sizing: border-box;
font-family: 'Open Sans', sans-serif !important;
font-size: 16px;
font-weight: 700;
`;

export const Fotytopprize = styled.p`


-webkit-font-smoothing: antialiased;
text-align: center;
color: #FFF !important;
margin: 0;
padding: 0;
border: 0;
outline: 0;
vertical-align: baseline;
background: transparent;
box-sizing: border-box;
font-family: 'Open Sans', sans-serif !important;
font-size: 13px;
font-weight: 400;
`;



export const FotyMinRankModel = styled.a`
margin-top: 100px;



-webkit-font-smoothing: antialiased;
text-align: center;
color: #00a9e7;
box-sizing: border-box;
font-family: 'Open Sans', sans-serif !important;
font-size: 13px;
background-size: cover;
background-position: center;
background-repeat: no-repeat;
position: relative;
text-decoration: none;
overflow: hidden;
transition: all 0.3s ease-in-out;
background-image: url(${Images.avatarUserImage});
width: 245px;
height: 200px;
`;

export const FotyMinRankPlace = styled.div`
top: 0;
left: 0;
width: 44px;
height: 22px;
background-image: url(${Images.foty_place});
display: flex;
justify-content: center;
align-items: center;
`;

export const FotyMinRankNumber = styled.span`

text-align: center;
color: #002E41;
text-shadow: 0 1px 0 #FFF;
font-size: 13px;
font-weight: 700;
font-family: 'Open Sans', sans-serif !important;
`;

export const FotyMinRankSuffix = styled.span`


-webkit-font-smoothing: antialiased;
text-align: center;
font-weight: 700;
color: #002E41;
text-shadow: 0 1px 0 #FFF;
margin: 0;
padding: 0;
border: 0;
outline: 0;
vertical-align: baseline;
background: transparent;
box-sizing: border-box;
font-family: 'Open Sans', sans-serif !important;
font-size: 8px;
text-transform: uppercase;
margin-right: 5px;
`;

export const FotyMinRankInfo = styled.div`


-webkit-font-smoothing: antialiased;
color: #00a9e7;
margin: 0;
border: 0;
outline: 0;
vertical-align: baseline;
box-sizing: border-box;
font-family: 'Open Sans', sans-serif !important;
font-size: 13px;
position: absolute;
left: 0;
right: 0;
bottom: 0;
background: linear-gradient(180deg, rgba(32,34,44,0) 0%, rgba(7,7,10,0.764) 77%, #13141A 100%) 0% 0% no-repeat padding-box;
text-align: center;
display: flex;
justify-content: space-between;
align-items: center;
padding: 15px 0 15px 15px;
`;

export const FotyMinRankName = styled.span`

-webkit-font-smoothing: antialiased;
margin: 0;
padding: 0;
border: 0;
outline: 0;
vertical-align: baseline;
background: transparent;
box-sizing: border-box;
font-family: 'Open Sans', sans-serif !important;
font-weight: 700;
font-size: 16px;
line-height: 0.9;
text-align: left;
color: #FFF !important;
`;

export const FotyMinRankPrize = styled.span`

-webkit-font-smoothing: antialiased;
margin: 0;
padding: 0;
border: 0;
outline: 0;
vertical-align: baseline;
background: transparent;
box-sizing: border-box;
font-family: 'Open Sans', sans-serif !important;
display: flex;
align-items: center;
justify-content: center;
flex: unset;
background-image: url(${Images.contestamount});
background-size: contain;
background-repeat: no-repeat;
width: 89px;
height: 33px;
font-weight: 400;
font-size: 13px;
line-height: 1;
color: #CCC !important;
margin-top: 3px;
text-align: center;
margin-right: -1px;
`;



// export const Fotycontestbg = styled.div`
// 
// 
// -webkit-font-smoothing: antialiased;
// color: #CCC !important;
// margin: 0;
// padding: 0;
// border: 0;
// outline: 0;
// vertical-align: baseline;
// background: transparent;
// box-sizing: border-box;
// font-family: 'Open Sans', sans-serif !important;
// font-size: 13px;
// position: absolute;
// top: 0;
// right: 0;
// bottom: 0;
// left: 0;
// background-size: cover;
// background-repeat: no-repeat;
// z-index: -1;
// background-position-x: 70%;
// background-image: url("https://cdn3.vscdns.com/images/foty/2022/hp-sections/credit_level_guys.webp");
// `;


export const FotybuttonView = styled.div`
  
`;



export const FotycontesectionTile = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
z-index: 1;
`;
