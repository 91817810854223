import styled from 'styled-components';

import {
  ContentPadder,
} from '../../../../../components/Contents/ContentPadder';

import {
  Colors,
  Sizes,
} from '../../../../../shared/constants';



export const MediaChooseContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 550px;
  max-width: 550px;
  min-height: 560px;
  max-height: 560px;
  overflow: hidden;
  position: relative;
  background-color: ${Colors.appBackground};
  border-radius: ${Sizes.cardRadius}px;
`;

export const GCPictureContainer = styled(ContentPadder)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  width: 100%;
  padding-bottom: 5px;
`;

export const GCPictureTitle = styled.h3`
  margin-bottom: 20px;
  color: ${Colors.white};
  font-size: 22px;
  font-weight: 600;
  word-break: break-word;
  word-wrap: break-word;
  text-align: center;
`;


export const GCPictureImageView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
