import React from 'react';

import {
  Colors,
} from '../../../shared/constants';

import {
  IconCheck,
} from '../../Icons';

import {
  CardProButton,
  CardProHeader,
  CardProInfoHeader,
  PriceText,
  UnityText,
  ItemQuantityView,
  AtualPlanIcon,
  CardProTitle,
  CardProBody,
  DescriptionPlanText,
} from './styled';



interface IProps {
  choosed?: boolean;
  active?: boolean;

  title: string;
  subtitle?: string;
  description?: string;
  price?: string | null;

  onPress?: any;
};



const CardPro: React.FC<IProps> = (props: any) => {
  function renderColorBackground() {
    if (props.choosed) {
      return Colors.primaryDark;
    }
    else if (props.active) {
      // return Colors.tableBackground;
      return Colors.primary;
    }
    else {
      return '#d9d9d9';
    }
  }


  function renderColorBorder() {
    if (props.choosed) {
      return Colors.primaryDark;
    }
    else if (props.active) {
      return Colors.primary;
    }
    else {
      return Colors.transparent;
    }
  }



  return (

    <CardProButton
      // disabled={props.options}
      borderColor={renderColorBorder()}
      onClick={() => {
        if (!props.active && props.onPress) {
          props.onPress();
        }
      }}>

      <CardProHeader
        style={{
          backgroundColor: renderColorBackground(),
        }}>

        <CardProInfoHeader>
          <CardProTitle
            choosed={props.choosed}>
            {props.title}
          </CardProTitle>


          {props.price && (
            <PriceText
              choosed={props.choosed}>
              {props.price}
            </PriceText>
          )}


          {props.subtitle && (
            <UnityText
              choosed={props.choosed}>
              {props.subtitle}
            </UnityText>
          )}
        </CardProInfoHeader>



        {props.active && (
          <ItemQuantityView>

            <AtualPlanIcon>
              <IconCheck
                color={Colors.white}
                size={20}
              />
            </AtualPlanIcon>

          </ItemQuantityView>
        )}

      </CardProHeader>



      {props.description && (
        <CardProBody>

          <DescriptionPlanText>
            {props.description}
          </DescriptionPlanText>

        </CardProBody>
      )}

    </CardProButton>

  );
};



export default CardPro;
