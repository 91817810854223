import React from 'react';

import ButtonAction from '../../components/Buttons/ButtonAction';

import {
  Overlay,
} from '../../components/Composh/web';

import {
  Colors,
} from '../../shared/constants';

import {
  CategoryChooseContainer,
  VerifiedModalTitle,
  VerifiedModalTextsView,
  VerifiedModalDescription,
  VerifiedModalMoreText,
} from './styled';



export interface IProps {
  visible?: boolean;
  onClose?: any;
}



const ModeleServicesModal: React.FC<IProps> = (props: IProps) => {
  const visibleModal = props.visible;
  if (!visibleModal) {
    return null;
  }



  return (

    <Overlay
      visible={visibleModal}
      showBackground={true}
      overlayColor={Colors.menuOverlay}
      onOverlayPress={props.onClose}>

      <CategoryChooseContainer>

        <VerifiedModalTitle>
          Serviços
        </VerifiedModalTitle>



        <VerifiedModalTextsView>

          <VerifiedModalDescription>
            Aqui você encontra os serviços que o acompanhante oferece e não oferece.
          </VerifiedModalDescription>

          <VerifiedModalDescription>
            Serviços marcados com minha especialidade são favoritos pelo acompanhante.
          </VerifiedModalDescription>

          <VerifiedModalDescription>
            Serviços não oferecidos aparecem riscados na lista.
          </VerifiedModalDescription>


          <VerifiedModalMoreText>
            Por favor, não insista!
          </VerifiedModalMoreText>

        </VerifiedModalTextsView>



        <ButtonAction
          title={'Fechar'}
          onPress={() => {
            if (props.onClose) {
              props.onClose();
            }
          }}
        />

      </CategoryChooseContainer>

    </Overlay>

  );
};



export default ModeleServicesModal;
