import React,
{
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
} from 'react-redux';

import InputChat from '../../../../../components/Chat/InputChat';
import UserAvatar from '../../../../../components/Contents/UserAvatar';

import {
  IconBlock,
  IconFaClub,
  IconFavoriteOff,
  IconFavoriteOn,
  IconNotificationOn,
  IconNotificationOff,
  IconRentOn,
  IconStar,
  IconUser,
  IconVerifiedPromoter,
} from '../../../../../components/Icons';

import {
  RootState,
} from '../../../../../redux';

import {
  Colors,
} from '../../../../../shared/constants';

import {
  EPlansUser,
} from '../../../../../shared/enums';

import {
  TranslateConfig,
} from '../../../../../shared/translations';

import {
  MiniRoomActionsView,
  MiniLinkInfoView,
  MiniImage,
  UserActionLinksView,
  InfoBioModeleView,
  BioModeleNameView,
  BioModeleUserNameText,
  BioModeleNameText,
  BioModeleInfoText,
  BioModeleLastViewText,
  ActionModeleView,
  ModeleReportView,
  ModeleStarReportView,
  ReportModeleText,
  ReportModeleInfoText,
  ReportModeleReviewText,
  ActionBioModeleView,
  ActionBioButton,
  ActionBioInfoIcon,
  ActionBioInfoText,
  ActionTextCompView,
} from './styled';



const RoomActions: React.FC = () => {
  const { t: translate } = useTranslation();


  const genderColorChoose = useSelector((state: RootState) => state.choose.genderColorApp);


  const [favorite, setFavorite] = useState(false);
  const [notify, setNotify] = useState(false);



  function returnLastAccess() {
    if (false) {
      return `${translate(TranslateConfig.ACCESS_LAST)}: ${'14 de janeiro de 2022 - 22:36'}`;
    }

    return translate(TranslateConfig.LIVE_ROOM_OPENED);
  }



  return (

    <MiniRoomActionsView>

      <MiniLinkInfoView>
        <MiniImage
          onClick={() => {
            window.open('/user/name34', '_blank').focus();
          }}>
          <UserAvatar
            plan={EPlansUser.GOLD}
            width={110}
            image={null}
          />
        </MiniImage>


        <UserActionLinksView>
          <ActionModeleView>

            <InfoBioModeleView>

              <BioModeleNameView>
                <BioModeleUserNameText>
                  @{'juandres'}
                </BioModeleUserNameText>

                <IconVerifiedPromoter
                  size={15}
                  color={Colors.white}
                />
              </BioModeleNameView>

              <BioModeleNameText>
                {'Juandres'}
              </BioModeleNameText>


              <BioModeleInfoText>
                &quot;
                {'Hot Latino procurando diversão!!'}
                &quot;
              </BioModeleInfoText>

              <BioModeleLastViewText>
                {returnLastAccess()}
              </BioModeleLastViewText>

            </InfoBioModeleView>

          </ActionModeleView>



          <ModeleReportView>

            <ModeleStarReportView>
              <IconStar
                size={21}
                color={Colors.primary}
              />

              <ReportModeleText>
                {'4.8'}
              </ReportModeleText>
            </ModeleStarReportView>


            {/* Juandres está avaliado em 4.8 de 5 baseados em comentários */}
            <ReportModeleInfoText>
              {`${'Juandres'} ${translate(TranslateConfig.COMMENT_LIVE_INTERPOLATE_1)} ${'4.8'} ${translate(TranslateConfig.COMMENT_LIVE_INTERPOLATE_2)}`} <ReportModeleReviewText
                onClick={() => {
                  // target="_blank"
                  // href="/models/bios/juandres/reviews"
                }}>
                {'45'} {translate(TranslateConfig.COMMENTS)}
              </ReportModeleReviewText>.
            </ReportModeleInfoText>

          </ModeleReportView>

        </UserActionLinksView>



        <ActionBioModeleView>

          <ActionBioButton
            onClick={() => {
              setFavorite(!favorite);
            }}>

            <ActionBioInfoIcon>
              {favorite
                ? (
                  <IconFavoriteOn
                    color={genderColorChoose}
                    size={19}
                  />
                )
                : (
                  <IconFavoriteOff
                    color={Colors.white}
                    size={19}
                  />
                )
              }
            </ActionBioInfoIcon>


            <ActionBioInfoText
              color={favorite ? genderColorChoose : Colors.white}>
              {favorite
                ? translate(TranslateConfig.ACTION_REMOVE_FAVORITE)
                : translate(TranslateConfig.ACTION_ADD_FAVORITE)
              }
            </ActionBioInfoText>

          </ActionBioButton>



          <ActionBioButton
            onClick={() => {
              setNotify(!notify);
            }}>

            <ActionBioInfoIcon>
              {notify
                ? (
                  <IconNotificationOff
                    color={genderColorChoose}
                    size={19}
                  />
                )
                : (
                  <IconNotificationOn
                    color={Colors.white}
                    size={19}
                  />
                )
              }
            </ActionBioInfoIcon>


            <ActionBioInfoText
              color={notify ? genderColorChoose : Colors.white}>
              {notify
                ? translate(TranslateConfig.ACTION_REMOVE_NOTIFY_MODELE_ONLINE)
                : translate(TranslateConfig.ACTION_ADD_NOTIFY_MODELE_ONLINE)
              }
            </ActionBioInfoText>

          </ActionBioButton>



          <ActionBioButton
            onClick={() => {
              window.open('/user/name34', '_blank').focus();
            }}>

            <ActionBioInfoIcon>
              <IconUser
                color={Colors.white}
                size={16}
              />
            </ActionBioInfoIcon>


            <ActionBioInfoText
              color={Colors.white}>
              {translate(TranslateConfig.ACTION_PROFILE_SEE)}
            </ActionBioInfoText>

          </ActionBioButton>



          <ActionBioButton
            disabled={true}>

            <ActionBioInfoIcon>
              <IconRentOn
                color={Colors.white}
                size={17}
              />
            </ActionBioInfoIcon>


            <ActionBioInfoText
              color={Colors.white}>
              {translate(TranslateConfig.ACTION_BE_YOUR_ESCORT)}
            </ActionBioInfoText>

          </ActionBioButton>



          <ActionBioButton>
            <ActionBioInfoIcon>
              <IconBlock
                color={Colors.white}
                size={19}
              />
            </ActionBioInfoIcon>

            <ActionBioInfoText
              color={Colors.white}>
              {translate(TranslateConfig.ACTION_BLOCK)}
            </ActionBioInfoText>
          </ActionBioButton>



          <ActionBioButton>
            <ActionBioInfoIcon>
              <IconFaClub
                color={Colors.white}
                size={17}
              />
            </ActionBioInfoIcon>

            <ActionBioInfoText
              color={Colors.white}>
              {translate(TranslateConfig.ACTION_FAN_CLUB_SUBSCRIBE)}
            </ActionBioInfoText>
          </ActionBioButton>

        </ActionBioModeleView>

      </MiniLinkInfoView>



      {/* Input Chat */}
      <ActionTextCompView>
        <InputChat
          hideOptions
        />
      </ActionTextCompView>

    </MiniRoomActionsView>

  );
};



export default RoomActions;
