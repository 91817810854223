import React from 'react';

import {
  LoaderContainer,
  LoaderDotsContainer,
  DotLoader,
  DotsLoader,
  DotItem,
} from './styled';



export interface IProps {
  backgroundColor?: string;
  color?: string;
  size?: number;
}



const LoadingProgress: React.FC<IProps> = (props: IProps) => {
  return (

    <LoaderContainer>
      <LoaderDotsContainer
        backgroundColor={props.backgroundColor}>

        <DotLoader
          color={props.color}
        />



        <DotsLoader>

          <DotItem
            color={props.color}
          />

          <DotItem
            color={props.color}
          />

          <DotItem
            color={props.color}
          />

        </DotsLoader>

      </LoaderDotsContainer>
      
    </LoaderContainer>

  );
};



export default LoadingProgress;
