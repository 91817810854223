import styled from 'styled-components';

export const AppearanceTabRoot = styled.div`
  display: grid;
  gap: 48px;
`;



export const ContentBox = styled.div`
  background-color: ${'#fff'};;
  border-radius: ${({ theme }) => '12px'};
  padding: 20px;
  box-shadow: 0px 4px 4px ${'#D7DCE1'};
`;

export const HeadingBox = styled.div`
  & > div {
    margin-top: 12px;
    display: grid;
    gap: 16px;
  }

  label {
    cursor: pointer;

    button {
      pointer-events: none;
    }
  }
`;

/* @media screen and (min-width: ${({ theme }) => '960px'}) {
  display: flex;
  align-items: center;
  gap: 20px;

  & > div {
    flex: 1;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 0;
  }
} */



export const InputsBox = styled.div`
  display: grid;
  gap: 16px;
`;


export const ThemesGrid = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 26px;
  text-align: center;

  li {
    cursor: pointer;
  }
`;

/* @media screen and (min-width: ${({ theme }) => theme.screens.sm}) {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
@media screen and (min-width: ${({ theme }) => theme.screens.lg}) {
  grid-template-columns: repeat(4, minmax(0, 1fr));
} */



export const ThemeMediabox = styled.div<{ src?: string; isActive?: boolean }>`
  padding-top: 150%;
  border-radius: ${({ theme }) => '12px'};
  background-color: ${'#D7DCE1'};
  background-size: cover;
  background-position: center;
  margin-bottom: 4px;
  transition-property: box-shadow;
  transition-duration: 250ms;

  ${({ src }) =>
    src &&
    `
    background-image: url(${src});
  `}

  ${({ isActive, theme }) =>
    isActive &&
    `
    box-shadow: 0 0 0 4px ${'#7C41FF'};  
  `}
`;
