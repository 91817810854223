import React from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  RootState,
} from '../../../redux';

import {
  TipCardContainer,
  TipCardImage,
  TipCardText,
} from './styled';



export interface IProps {
  text?: string;
  image?: any;
  onPress?: any;
}



const CardTips: React.FC<IProps> = (props: IProps) => {
  const genderColorChoose = useSelector((state: RootState) => state.choose.genderColorApp);



  return (

    <TipCardContainer
      backgroundColor={genderColorChoose}
      onClick={() => {
        if (props.onPress) {
          props.onPress();
        }
      }}>

      <TipCardImage
        alt={'tip-image'}
        src={props.image}
      />


      <TipCardText>
        {props.text}
      </TipCardText>

    </TipCardContainer>

  );
};



export default CardTips;
