import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';



export const Error404Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 10px;
`;



export const Error404ColunsOptionsView = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 50px;
`;


export const Error404ColunsContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-left: 10px;
  padding-right: 10px;
`;


export const Error404Title = styled.p`
  color: ${Colors.white};
  font-size: 32px;
  font-weight: bold;
`;

export const Error404BoxTitle = styled.span`
  color: ${Colors.white};
  background-color: ${Colors.primaryDark};
  border-radius: 20px;
  margin-left: 8px;
  padding-top: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 4px;
`;


export const Error404Description = styled.span`
  margin-top: 5px;
  color: ${Colors.white};
  font-size: 16px;
`;


export const Error404ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
`;


export const Error404OrText = styled.p`
  margin-left: 16px;
  margin-right: 16px;
  color: ${Colors.white};
  font-size: 16px;
  font-weight: 400;
`;



export const Error404ChoosesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`;

export const Erro404LogoAppImage = styled.img`
  width: 35px;
  height: 35px;
  margin-right: 12px;
  object-fit: contain;
`;

export const Error404ChoosesText = styled.p`
  color: ${Colors.white};
  font-size: 26px;
  font-weight: bold;
`;

export const Error404ChoosesTypesView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
`;


export const Error404SponsorsView = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Error404SponsorsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const Error404SponsorsAllButon = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Error404SponsorsAllText = styled.p`
  margin-left: 7px;
  color: ${Colors.white};
  font-size: 16px;
  font-weight: 500;
`;

export const Error404SponsorSwiperView = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: ${Colors.cardBackground};
  border-radius: 10px;
`;

export const Error404SponsorSwiperContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
  overflow-x: auto;
  width: 100%;
  padding-top: 16px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 16px;
`;
