import React from 'react';

import ButtonAction from '../../../../components/Buttons/ButtonAction';

import {
  IconAds,
  IconVerifiedPromoter,
} from '../../../../components/Icons';

import {
  Colors,
} from '../../../../shared/constants';

import {
  RentManOfTheDayContainer,
  RentManOfTheDayPreview,
  RentManOfTheDayImageExample,
  RentManOfTheDayInfo,
  RentManDayHeader,
  RentManDayTitle,
  RentManDayrulContees,
  RentManDayrules,
  FakeUploadVerifiedView,
  FakeUploadVerifiedIcon,
  RentManDayApartirrules,
  RentManDaysize,
  RentMandescription,
} from './styled';



const TabsRentSponsor: React.FC = () => {
  return (

    <RentManOfTheDayContainer>

      <RentManOfTheDayPreview>

        <RentManOfTheDayImageExample
        />

        {/* <AvrtExampleHeader/>
                
                
                <Avrtuserexample>
                  <Avrtuserfooter>
                    <Avrtuserflabel>RentMan Of The Day</Avrtuserflabel>
                    <Avrtuseisflexel>
                      <span className="jsx-2512053135 username"><span className="jsx-2512053135 text-capitalize">miguelsims</span> • 20</span><span className="jsx-2512053135 location">Sao Paulo, Brazil</span>
                    </Avrtuseisflexel>
                  </Avrtuserfooter>
                </Avrtuserexample> */}

      </RentManOfTheDayPreview>



      <RentManOfTheDayInfo>

        <RentManDayHeader>

          <i className="icon-font icon-calendar-box-star-solid title-icon"></i>

          <RentManDayTitle>
            Rent Sponsor
          </RentManDayTitle>

        </RentManDayHeader>


        <RentManDayrulContees>

          <RentManDayrules>
            Entre no círculo fechado de RentMen Sponsors para apresentar seu perfil nos principais canais de anúncios e tirar o melhor proveito de sua associação!
          </RentManDayrules>

          <RentManDayrules>
            Você será promovido na seção RentMen Sponsors na página inicial e nas páginas de perfil dos artistas com associação Básica.
          </RentManDayrules>

          <RentManDayrules>
            Tornar-se um RentMen Sponsor lhe dará a melhor visibilidade em todo o mundo e com base na localização.
          </RentManDayrules>

          <FakeUploadVerifiedView>
            <RentManDayrules>
              Anúncios com Sponsor recebem nosso selo de verificação
            </RentManDayrules>

            <FakeUploadVerifiedIcon>
              <IconVerifiedPromoter />
            </FakeUploadVerifiedIcon>
          </FakeUploadVerifiedView>

        </RentManDayrulContees>


        <RentManDayApartirrules>
          A partir de
        </RentManDayApartirrules>

        <RentManDaysize>
          US$99.00 por mês
        </RentManDaysize>


        {/* <RentMandescription>
          O pedido mínimo é de 12 horas
        </RentMandescription> */}


        <ButtonAction
          iconLeft={
            <IconAds
              color={Colors.white}
            />
          }
          title={'Selecionar'}
        />

      </RentManOfTheDayInfo>

    </RentManOfTheDayContainer>

  );
};



export default TabsRentSponsor;
