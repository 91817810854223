import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';



export interface IProps {
  color?: string;
}



export const InputSelectButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 42px;
  padding-top: 8px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 8px;
  background-color: ${Colors.inputBackground};
  border-radius: ${Sizes.cardRadius}px;
`;

export const InputSelectText = styled.p`
  color: ${(props: IProps) => props.color || Colors.gray};
  font-size: 14px;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  word-break: break-word;
`;
