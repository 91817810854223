import styled,
{
  keyframes,
} from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';



const Wiggle = keyframes`
  0%, 7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-13deg);
  }
  20% {
    transform: rotateZ(9deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(7deg);
  }
  35% {
    transform: rotateZ(-2deg);
  }
  40%, 100% {
    transform: rotateZ(0);
  }
`;



export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 200px;
  padding: 15px;
  background-color: ${Colors.menuBackground};
`;



export const CpLinksLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const HeaderActionsButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: ${Sizes.buttonHeight}px;
`;

export const HeaderActionsText = styled.p`
  color: ${Colors.white};
  font-size: 12px;
  font-weight: 300;
  text-align: center;
`;

export const BeModelActionsText = styled.p`
  color: ${Colors.primary};
  font-size: 12px;
  font-weight: 300;
  animation: ${Wiggle} 1.5s ease-in infinite;
`;



export const HeaderActionsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
