import React,
{
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
  useDispatch,
} from 'react-redux';

import {
  useNavigate,
} from 'react-router-dom';

import {
  SwipeableDrawer,
  makeStyles,
} from '@material-ui/core';

import Color from 'color';

import UserAvatar from '../../../components/Contents/UserAvatar';
import UserGenre from '../../../components/Contents/UserGenre';

import {
  IconArrowDown,
  IconCalendar,
  IconDashboard,
  IconAccessExit,
  IconHelp,
  IconHome,
  IconLive,
  IconMessages,
  IconNotification,
  IconRentOn,
  IconSettings,
  IconThemeDark,
  IconThemeLight,
  IconVerified,
  IconVerifiedPromoter,
  IconWallet,
  IconFavoriteList,
  IconMyPrive,
} from '../../../components/Icons';

import LoadingProgress from '../../../components/Loadings/LoadingProgress';
import DrawerTitle from '../../../components/Titles/DrawerTitle';

import {
  SetLightGenreText,
} from '../../../config/genre.config';

import {
  RootState,
} from '../../../redux';

import {
  APP_DRAWER_ACTION,
  APP_MENU_SELECTED_ACTION,
  USER_BRAND_PROMOTER_MODAL,
  USER_VERIFIED_MODAL,
} from '../../../redux/reducers/app-modals.store';

import {
  USER_LOGOUT_STATE,
} from '../../../redux/reducers/user.store';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import EDrawerMenu from '../../../shared/enums/App/drawer-menu.enum';
import EUserType from '../../../shared/enums/Users/user-type.enum';

import {
  TranslateConfig,
} from '../../../shared/translations';

import NameRoutes from '../../names';

import {
  MenuContainer,
  MenuHeaderContainer,
  MenuImageHeaderView,
  MenuThemeContent,
  MenuThemeButton,
  MenuVerifiedContent,
  MenuUserNameText,
  IconVerifiedView,
  MenuHeaderName,
  MenuHeaderStatusView,
  MenuOnlineContent,
  MenuHeaderStatusText,
  MenuHeaderStatusIcon,
  MenuListContent,
  ActiveIndicatorContent,
  MenuContentPlace,
  CardUpgradeStyled,
  MenuItemList,
  ItemMenuInfoItem,
  ItemMenuText,
  ItemMenuSubText,
  ItemMenuIcon,
  ItemMenuContainer,
  MenuImageContent,
  MenuHeaderInfo,
  MenuSeparator,
  MenuInfoNameText,
} from './styled';



export interface IProps {
  open?: boolean;

  onOpen?: any;
  onClose?: any;
}



const useStyles = makeStyles(() => ({
  backdrop: {
    backgroundColor: Colors.menuOverlay,
  },
}));



const DrawerUserOn: React.FC<IProps> = (props: any) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const classes = useStyles();
  const { t: translate } = useTranslation();


  const selectedIndex = useSelector((state: RootState) => state.appModals.menuSelected);

  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;

  const genderChoose = useSelector((state: RootState) => state.choose.genderChoose);
  const genderColorChoose = useSelector((state: RootState) => Color(state.choose.genderColorApp).lighten(0.2).toString());

  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);


  const [fixMenu] = useState(false);



  const routesHighlights = [
    {
      index: EDrawerMenu.HOME,
      icon: (
        <IconHome
          size={24}
          color={selectedIndex === EDrawerMenu.HOME ? genderColorChoose : Colors.textSubtitle}
        />
      ),
      name: translate(TranslateConfig.HOME),
      router: NameRoutes.HomeScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
    {
      index: EDrawerMenu.MY_PRIVE,
      icon: (
        <IconMyPrive
          size={24}
          color={selectedIndex === EDrawerMenu.MY_PRIVE ? genderColorChoose : Colors.textSubtitle}
        />
      ),
      name: translate(TranslateConfig.MY_PRIVE),
      router: NameRoutes.MyPriveScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
    {
      index: EDrawerMenu.RENT,
      icon: (
        <IconRentOn
          size={22}
          color={selectedIndex === EDrawerMenu.RENT ? genderColorChoose : Colors.textSubtitle}
        />
      ),
      name: translate(TranslateConfig.ESCORTS),
      router: NameRoutes.RentHomeScreen,
      visible: projectParams?.showRent,
      enable: true,
      enableMessage: translate(SetLightGenreText(genderChoose)),
    },
    {
      index: EDrawerMenu.LIVE,
      icon: (
        <IconLive
          size={22}
          color={selectedIndex === EDrawerMenu.LIVE ? genderColorChoose : Colors.textSubtitle}
        />
      ),
      name: translate(TranslateConfig.LIVE_ROOMS_ONDEMAND),
      router: NameRoutes.LiveHomeScreen,
      visible: projectParams?.showLive,
      enable: true,
      enableMessage: translate(SetLightGenreText(genderChoose)),
    },
    {
      index: EDrawerMenu.ALL_MODELES,
      icon: (
        <UserGenre
          genre={genderChoose}
          size={22}
          color={selectedIndex === EDrawerMenu.ALL_MODELES ? genderColorChoose : Colors.textSubtitle}
        />
      ),
      name: translate(TranslateConfig.ALL_MODELES),
      router: NameRoutes.AllModeleScreen,
      visible: true,
      enable: true,
      enableMessage: translate(SetLightGenreText(genderChoose)),
    },
  ];


  const routesProfile = [
    {
      index: EDrawerMenu.DASHBOARD,
      icon: (
        <IconDashboard
          size={24}
          color={selectedIndex === EDrawerMenu.DASHBOARD ? genderColorChoose : Colors.textSubtitle}
        />
      ),
      name: translate(TranslateConfig.DASHBOARD),
      router: NameRoutes.DashboardModeleScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
    {
      index: EDrawerMenu.FAVORITES,
      icon: (
        <IconFavoriteList
          size={24}
          color={selectedIndex === EDrawerMenu.FAVORITES ? genderColorChoose : Colors.textSubtitle}
        />
      ),
      name: translate(TranslateConfig.COLLECTIONS),
      router: NameRoutes.FavoriteScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
    {
      index: EDrawerMenu.NOTIFICATIONS,
      icon: (
        <IconNotification
          size={24}
          color={selectedIndex === EDrawerMenu.NOTIFICATIONS ? genderColorChoose : Colors.textSubtitle}
        />
      ),
      name: translate(TranslateConfig.NOTIFICATIONS),
      router: NameRoutes.NotificationsScreen,
      visible: projectParams?.showNotification,
      enable: true,
      enableMessage: null,
    },
    {
      index: EDrawerMenu.MESSAGES,
      icon: (
        <IconMessages
          size={20}
          color={selectedIndex === EDrawerMenu.MESSAGES ? genderColorChoose : Colors.textSubtitle}
        />
      ),
      name: translate(TranslateConfig.MESSAGES),
      router: NameRoutes.MessagesScreen,
      visible: projectParams?.showChatMessages,
      enable: true,
      enableMessage: null,
    },
  ];


  const routesTools = [
    {
      index: EDrawerMenu.ADM_MODEL,
      icon: (
        <IconWallet
          size={24}
          color={selectedIndex === EDrawerMenu.ADM_MODEL ? genderColorChoose : Colors.textSubtitle}
        />
      ),
      name: translate(TranslateConfig.PANEL_PROFISSIONAL),
      router: NameRoutes.PanelModeleScreen,
      visible: user?.type === EUserType.MODELE,
      enable: true,
      enableMessage: null,
    },
    {
      index: EDrawerMenu.ADM_FINANCIAL,
      icon: (
        <IconWallet
          size={24}
          color={selectedIndex === EDrawerMenu.ADM_FINANCIAL ? genderColorChoose : Colors.textSubtitle}
        />
      ),
      name: translate(TranslateConfig.FINANCIAL),
      router: NameRoutes.FinancialScreen,
      visible: projectParams?.showFinancial && user?.type === EUserType.MODELE,
      enable: true,
      enableMessage: null,
    },
    {
      index: EDrawerMenu.ADM_CALENDAR,
      icon: (
        <IconCalendar
          size={22}
          color={selectedIndex === EDrawerMenu.ADM_CALENDAR ? genderColorChoose : Colors.textSubtitle}
        />
      ),
      name: translate(TranslateConfig.CALENDAR),
      router: NameRoutes.CalendarScreen,
      visible: projectParams?.showCalendar,
      enable: false,
      enableMessage: translate(TranslateConfig.VALIDATE_HAS_PLAN),
    },
  ];


  const routesOptions = [
    {
      index: EDrawerMenu.SETTINGS,
      icon: (
        <IconSettings
          size={24}
          color={selectedIndex === EDrawerMenu.SETTINGS ? genderColorChoose : Colors.textSubtitle}
        />
      ),
      name: translate(TranslateConfig.SETTINGS),
      router: NameRoutes.SettingsAppScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
    {
      index: EDrawerMenu.HELP,
      icon: (
        <IconHelp
          size={24}
          color={selectedIndex === EDrawerMenu.HELP ? genderColorChoose : Colors.textSubtitle}
        />
      ),
      name: translate(TranslateConfig.HELP),
      router: NameRoutes.HelpFaqScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
    {
      index: EDrawerMenu.EXIT,
      icon: (
        <IconAccessExit
          size={24}
          color={selectedIndex === EDrawerMenu.EXIT ? Colors.white : Colors.textSubtitle}
        />
      ),
      name: translate(TranslateConfig.EXIT),
      router: NameRoutes.AccessScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
  ];


  const handleListItemClick = (index: EDrawerMenu) => {
    dispatch({ type: APP_DRAWER_ACTION, payload: false });
    if (index !== EDrawerMenu.EXIT) {
      dispatch({ type: APP_MENU_SELECTED_ACTION, payload: index });
    }
  };


  function pushButtonCLick(route: any) {
    if (route === NameRoutes.AccessScreen) {
      dispatch({ type: USER_LOGOUT_STATE, payload: true });
    }
    else {
      history(route);
    }
  }


  function renderItem(item: any) {
    const focused = selectedIndex === item.index;
    const colorFocused = focused ? genderColorChoose : Colors.textSubtitle;



    if (item.visible) {
      return (

        <ItemMenuContainer
          key={item.index}
          id={item.index}
          disabled={!item.enable}
          selected={focused}
          onClick={() => {
            if (item.enable) {
              pushButtonCLick(item.router);
              handleListItemClick(item.index);
            }
          }}>

          <ItemMenuIcon>
            {item.icon}
          </ItemMenuIcon>


          <ItemMenuInfoItem>
            <ItemMenuText
              selected={focused}
              color={colorFocused}>
              {item.name}
            </ItemMenuText>


            <ItemMenuSubText
              color={colorFocused}>
              {item.enableMessage}
            </ItemMenuSubText>
          </ItemMenuInfoItem>

        </ItemMenuContainer>

      );
    }
  };



  return (

    <SwipeableDrawer
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      anchor={'left'}
      disableSwipeToOpen={true}
      open={props.open}
      onOpen={props.onOpen}
      onClose={props.onClose}>

      <MenuContainer
        style={{
          width: !fixMenu ? 300 : 60,
        }}>

        <MenuHeaderContainer
          backgroundColor={genderColorChoose}>

          <MenuImageContent
            style={{
              flexDirection: !fixMenu ? 'row' : 'column',
            }}>

            <UserAvatar
              plan={user?.plan?.planCurrent}
              image={user?.picture}
              width={!fixMenu ? Sizes.imageDrawer : 45}
            />


            <MenuImageHeaderView>

              <MenuThemeContent>

                <MenuOnlineContent>

                  {projectParams?.canBeOnline && (
                    <MenuHeaderStatusView>
                      <MenuHeaderStatusText>
                        Online
                      </MenuHeaderStatusText>

                      <MenuHeaderStatusIcon>
                        <IconArrowDown
                          color={Colors.white}
                          size={20}
                        />
                      </MenuHeaderStatusIcon>
                    </MenuHeaderStatusView>
                  )}

                </MenuOnlineContent>



                {/* FIXME */}
                {false && (
                  <MenuThemeButton
                    onClick={() => {
                      // TODO
                    }}>
                    {true
                      ? (
                        <IconThemeLight
                          color={Colors.white}
                          size={18}
                        />
                      )
                      : (
                        <IconThemeDark
                          color={Colors.white}
                          size={18}
                        />
                      )
                    }
                  </MenuThemeButton>
                )}

              </MenuThemeContent>



              <MenuVerifiedContent>

                <MenuUserNameText>
                  @{user?.useralias || '-'}
                </MenuUserNameText>


                {user?.verified && (
                  <IconVerifiedView
                    onClick={() => {
                      dispatch({ type: USER_VERIFIED_MODAL, payload: true });
                    }}>
                    <IconVerified
                      size={13}
                    />
                  </IconVerifiedView>
                )}


                {user?.brandPromoter && (
                  <IconVerifiedView
                    onClick={() => {
                      dispatch({ type: USER_BRAND_PROMOTER_MODAL, payload: true });
                    }}>
                    <IconVerifiedPromoter
                      size={13}
                    />
                  </IconVerifiedView>
                )}

              </MenuVerifiedContent>

            </MenuImageHeaderView>

          </MenuImageContent>



          {!fixMenu && (
            <MenuHeaderInfo>
              <MenuHeaderName>
                {user?.alias || '-'}
              </MenuHeaderName>


              <MenuSeparator
                height={1.5}
                width={110}
                color={Colors.white}
                style={{ opacity: availableUser ? 1 : 0.2 }}
              />


              <MenuInfoNameText>
                {user?.type === EUserType.MODELE
                  ? translate(TranslateConfig.MODELE)
                  : translate(TranslateConfig.USER)
                }
              </MenuInfoNameText>
            </MenuHeaderInfo>
          )}

        </MenuHeaderContainer>



        <MenuListContent>

          {!availableUser && (
            <ActiveIndicatorContent>
              <LoadingProgress
                color={Colors.primary}
                size={20} // 'large'
              />
            </ActiveIndicatorContent>
          )}


          {availableUser && (
            <MenuContentPlace>

              {projectParams?.canAccess && projectParams?.showAds && (
                <CardUpgradeStyled
                  type={user?.type}
                  onUserPress={() => {
                    history(NameRoutes.UpgradeUserShowScreen);
                    dispatch({ type: APP_DRAWER_ACTION, payload: false });
                  }}
                  onModelePress={() => {
                    history(NameRoutes.UpgradeModeleShowScreen);
                    dispatch({ type: APP_DRAWER_ACTION, payload: false });
                  }}
                />
              )}



              {!routesHighlights.every((currentValue) => !currentValue.visible) && (
                <MenuItemList>
                  <DrawerTitle
                    title={!fixMenu
                      ? translate(TranslateConfig.HIGHLIGHTS)
                      : ' '
                    }
                    backgroundColor={genderColorChoose}
                  />

                  {routesHighlights.map((item) => (
                    renderItem(item)
                  ))}
                </MenuItemList>
              )}



              {!routesProfile.every((currentValue) => !currentValue.visible) && (
                <MenuItemList>
                  <DrawerTitle
                    title={!fixMenu
                      ? translate(TranslateConfig.PROFILE)
                      : ' '
                    }
                    backgroundColor={genderColorChoose}
                  />

                  {routesProfile.map((item) => (
                    renderItem(item)
                  ))}
                </MenuItemList>
              )}



              {!routesTools.every((currentValue) => !currentValue.visible) && (
                <MenuItemList>
                  <DrawerTitle
                    title={!fixMenu
                      ? translate(TranslateConfig.TOOLS)
                      : ' '
                    }
                    backgroundColor={genderColorChoose}
                  />

                  {routesTools.map((item) => (
                    renderItem(item)
                  ))}
                </MenuItemList>
              )}



              {!routesOptions.every((currentValue) => !currentValue.visible) && (
                <MenuItemList>
                  <DrawerTitle
                    title={!fixMenu
                      ? translate(TranslateConfig.OPTIONS)
                      : ' '
                    }
                    backgroundColor={genderColorChoose}
                  />

                  {routesOptions.map((item) => (
                    renderItem(item)
                  ))}
                </MenuItemList>
              )}


            </MenuContentPlace>
          )}
        </MenuListContent>

      </MenuContainer>

    </SwipeableDrawer>

  );
};



export default DrawerUserOn;
