import styled from 'styled-components';

import {
  Sizes,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';

import {
  HomeScrollbarVertival,
} from '../../../styles/scrolls.layout';



export const HomeCenterFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  width: 100%;
  padding-bottom: ${Sizes.paddingContainer + 7}px;

  @media ${PlatformDevices.minLaptopL} {
    ${HomeScrollbarVertival};
  
    padding-top: ${Sizes.paddingContainer + 7}px;
    padding-right: ${Sizes.paddingContainer}px;
    padding-left: ${Sizes.paddingContainer}px;
  }
`;


export const HomePostCenterView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
`;
