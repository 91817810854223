import React from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  RootState,
} from '../../../redux';

import {
  Colors,
} from '../../../shared/constants';

import {
  UserShortcutButton,
  UserShortcutIcon,
  UserShortcutText,
} from './styled';



export interface IProps {
  disabled?: boolean;
  title?: string;
  icon?: any;
  onPress?: any;
}



const ButtonActionType: React.FC<IProps> = (props: IProps) => {
  const genderColorChoose = useSelector((state: RootState) => state.choose.genderColorApp);



  return (

    <UserShortcutButton
      clickable={Boolean(props.onPress)}
      disabled={props.disabled}>

      <UserShortcutIcon
        backgroundColor={props.disabled ? Colors.disabled : genderColorChoose}
        onClick={() => {
          if (!props.disabled && props.onPress) {
            props.onPress();
          }
        }}>
        {props.icon}
      </UserShortcutIcon>

      <UserShortcutText>
        {props.title}
      </UserShortcutText>

    </UserShortcutButton>

  );
};



export default ButtonActionType;
