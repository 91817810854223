import React from 'react';

import ButtonAction from '../../Buttons/ButtonAction';

import {
  CardPackagesAddText,
  CardPackagesOptionsContent,
  CardPackagesOptionsItem,
  CardPackagesPriceText,
  CardInfoPriceView,
  CardPackagesPriceView,
  CardPackagesSaldoText,
  CardPackagesToText,
  CardPackagesTryText,
  CardPackagesTryView,
  CardPackagesValueText,
} from './styled';



export interface IProps {
  promotion: any;
  credit: any;
  price: any;
}



const CardPackagePay: React.FC<IProps> = (props) => {
  return (

    <CardPackagesOptionsItem>

      <CardPackagesOptionsContent>
        <CardPackagesAddText>
          {'Adicionar'.toUpperCase()}
        </CardPackagesAddText>

        <CardPackagesPriceText>
          {props.credit}
        </CardPackagesPriceText>

        <CardPackagesSaldoText>
          {'de saldo'.toUpperCase()}
        </CardPackagesSaldoText>
      </CardPackagesOptionsContent>


      {props.promotion && (
        <CardPackagesTryView>
          <CardPackagesTryText>
            Experimente por apenas
          </CardPackagesTryText>
        </CardPackagesTryView>
      )}


      <CardInfoPriceView>
        <CardPackagesPriceView>
          <CardPackagesToText>
            Por
          </CardPackagesToText>

          <CardPackagesValueText>
            {props.price}
          </CardPackagesValueText>
        </CardPackagesPriceView>


        <ButtonAction
          title={'Selecionar'}
        />
      </CardInfoPriceView>

    </CardPackagesOptionsItem>

  );
};



export default CardPackagePay;
