import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../shared/constants';

import {
  PlatformDevices,
} from '../../shared/utils/platforms.utils';

const widthAds = 250;
const heightBannerAds = 450;
const heightAppsAds = 330;



export const AdsSuggestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${widthAds}px;
  margin-left: ${Sizes.contentMargin + 10}px;

  @media ${PlatformDevices.maxLaptopL} {
    display: none;
  }
`;

export const AdsWrapperContentItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 25px;
`;

export const AdsSuggestionHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const AdsBannerImage = styled.img`
  width: ${widthAds}px;
  height: ${heightBannerAds}px;
  background-color: ${Colors.inputBackground};
  object-fit: cover;
  object-position: top center;
`;

export const AdsStreamAppsButton = styled.a`
  display: flex;
  flex-direction: column;
  width: ${widthAds}px;
  height: ${heightAppsAds}px;
  background-color: ${Colors.inputBackground};
`;

export const AdsAppsImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
`;



export const LastDetailsAdsList = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
