import styled from 'styled-components';

import Color from 'color';

import {
  Scrolls,
} from '../../../../../components/Composh/web';

import {
  ContentPadder,
} from '../../../../../components/Contents/ContentPadder';

import {
  GetPlatform,
} from '../../../../../config/plataform.config';

import {
  Colors,
  Sizes,
} from '../../../../../shared/constants';

import {
  PlatformDevices,
} from '../../../../../shared/utils/platforms.utils';

import {
  ScrollbarHideStyle,
  ScrollbarHorizontalStyle,
} from '../../../../../styles/scrolls.layout';



export interface IProps {
  backgroundColor?: string;
}



export const ContentRowWrapperAuto = styled(ContentPadder)`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  max-width: ${Sizes.contentMaxWidth}px;

  @media ${PlatformDevices.maxLaptop} {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const ContentCenterFlex = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

export const LiveMyChatsView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 25px;

  @media ${PlatformDevices.maxLaptop} {
    padding-left: ${Sizes.paddingFlatList}px;
    padding-right: ${Sizes.paddingFlatList}px;
  }
`;



export const WrapperItemContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 25px;
`;

export const MySchedulesView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${PlatformDevices.maxLaptop} {
    padding-left: ${Sizes.paddingFlatList}px;
    padding-right: ${Sizes.paddingFlatList}px;
  }
`;


export const FeedTipsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const FeedTipsIconHeader = styled.a`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
`;

export const UserChatTypesView = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 13px;
  margin-bottom: 7px;
`;
export const UserChatTypesInfoView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
`;

export const UserChatTypeIcon = styled.div`
  width: 15px;
  height: 15px;
  margin-right: 10px;
  background-color: ${(props: IProps) => props.backgroundColor || Colors.disabled};
  border-radius: 50%;
`;

export const UserChatTypeText = styled.p`
  color: ${Colors.white};
  font-size: 14px;
`;

export const UserAboutMeText = styled.p`
  margin-bottom: 5px;
  color: ${Colors.gray};
  font-size: 14px;
  line-height: 22px;
`;



export const ScheduleTimezoneView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 25px;

  @media ${PlatformDevices.maxTablet} {
    flex-direction: column;
  }
`;

export const SchedulePlaceView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

export const SchedulePlaceText = styled.p`
  margin-bottom: 5px;
  color: ${Colors.white};
  font-size: 16px;
  font-weight: bold;
`;

export const ScheduleTimezoneText = styled.p`
  color: ${Colors.gray};
  font-size: 14px;
`;

export const SchedulePlaceButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  @media ${PlatformDevices.maxTablet} {
    width: 100%;
    margin-top: 15px;
    justify-content: center;
  }
`;


export const ModelScheduleScroll = styled(Scrolls.Horizontal)`
  width: 100%;

  ${GetPlatform() === 'WEB' ? ScrollbarHorizontalStyle : ScrollbarHideStyle}
`;

export const ModelGrindSchedule = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 3px;
  flex: 1;
  min-width: 760px;

  @media ${PlatformDevices.maxLaptop} {
    padding-left: ${Sizes.paddingFlatList}px;
    padding-right: ${Sizes.paddingFlatList}px;
  }
`;

export const DayWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${Color(Colors.cardBackground).darken(0.35).toString()};
`;

export const TheDay = styled.p`
  margin: 0;
  padding: 0;
  color: ${Colors.grayLight};
  background-color: ${Colors.cardBackground};
  font-size: 14px;
  line-height: 40px;
  text-align: center;
`;

export const ScheduleTime = styled.p`
  margin: 0;
  padding: 20px 8px 20px 8px;
  color: ${Colors.grayLight};
  font-size: 14px;
  line-height: 1.4em;
  text-align: center;
`;
