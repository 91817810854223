import React from 'react';

import {
  Images,
} from '../../../shared/constants';

import {
  validateString,
} from '../../../shared/utils/string.utils';

import {
  DdiItemButton,
  DdiModalImage,
  DdiInfoContainer,
  DdiInfoText,
  DdiNameText,
} from './styled';



export interface IProps {
  value: string;
  label: string;
  image: string;
  onPress?: any;
}



const CardDdi: React.FC<IProps> = (props: IProps) => {
  return (

    <DdiItemButton
      onClick={() => {
        if (props.onPress) {
          props.onPress();
        }
      }}>

      <DdiModalImage
        alt={'flag'}
        src={validateString(props.image) || Images.avatarUserImage}
      />


      <DdiInfoContainer>
        <DdiInfoText>
          +{props.value}
        </DdiInfoText>

        <DdiNameText>
          {props.label}
        </DdiNameText>
      </DdiInfoContainer>

    </DdiItemButton>

  );
};



export default CardDdi;
