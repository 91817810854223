import styled from 'styled-components';

import color from 'color';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

const buttonHeight = Sizes.buttonHeight;



export interface IProps {
  backgroundColor?: string;
}



export const ButtonCityContainer = styled.a`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  position: relative;
  min-width: 210px;
  height: ${buttonHeight}px;
  background-color: #383641;
  border-radius: ${Sizes.cardRadius}px;

  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
`;


export const CountryStyleView = styled.div`
  display: flex;
  flex-direction: column;
  width: ${buttonHeight + 4}px;
  height: ${buttonHeight}px;
`;

export const DivisorCountry = styled(CountryStyleView)`
  background-color: ${(props: IProps) => props.backgroundColor
    ? color(props.backgroundColor).darken(0.3).toString()
    : Colors.primary};
`;

export const CountryView = styled(CountryStyleView)`
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  height: ${buttonHeight}px;
`;

export const CountryImage = styled.img`
  width: ${buttonHeight - 5}px;
  height: ${buttonHeight - 5}px;
  margin-left: 2px;
  object-fit: center;
`;


export const DivisorView = styled.div`
  display: flex;
  flex-direction: column;
  width: 3px;
  background-color: ${(props: IProps) => props.backgroundColor
    ? color(props.backgroundColor).darken(0.6).toString()
    : Colors.primary};
`;


export const DivisorShadow = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  height: 18.5px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
`;


export const CityView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 100px;
  margin-left: 8px;
  padding-right: 10px;
`;

export const CityText = styled.p`
  margin-top: 2px;
  margin-bottom: 2px;
  color: white;
  font-size: 15px;
  font-style: normal;
  line-height: 17px;
`;

export const StateView = styled.p`
  min-height: 13px;
  color: #979797;
  font-size: 10.5px;
  line-height: 13px;
`;
