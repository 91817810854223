import styled from 'styled-components';
import { Colors, Sizes } from '../../shared/constants';



export const CategoryChooseContainer = styled.div`
    margin: 13px 0 28px;
    max-width: 500px;
    min-width: 320px;
    padding: 16px 20px 12px;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background-color: ${Colors.appBackground};
  border-radius: ${Sizes.cardRadius}px;
`;

export const VerifiedModalItemView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
`;

export const VerifiedModalTitle = styled.p`
  color: ${Colors.white};
  font-size: 20px;
`;



export const VerifiedModalTextsView = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

export const VerifiedModalDescription = styled.p`
  color: ${Colors.white};
  font-size: 14px;
`;

export const VerifiedModalMoreButton = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;
