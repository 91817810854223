import axios,
{
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

import {
  storePersisted,
} from '../redux';

import {
  USER_LOGGED,
  USER_LOGOUT_STATE,
  USER_SET,
} from '../redux/reducers/user.store';

import {
  ETokenAsync,
} from '../shared/enums';

import AppEnvironments from '../shared/environments/application';

import {
  getLocalStorageItem,
} from '../shared/utils/local-storage.utils';



function SecretioWS() {
  const baseURL = AppEnvironments().apiUrl;

  const typeWS = 'SITE'; // 'SITE' | 'MOBILE'
  let id: string | null;
  let token: string | null;
  let clientNumber: string | null;


  const secretioAppServer = axios.create({
    baseURL,
  });



  secretioAppServer.interceptors.request.use((config: AxiosRequestConfig) => {
    if (typeWS === 'SITE') {
      id = getLocalStorageItem(ETokenAsync.USER_ID);
      token = getLocalStorageItem(ETokenAsync.USER_TOKEN);
      clientNumber = getLocalStorageItem(ETokenAsync.USER_CLIENT_NUMBER);
    }
    // else if (typeWS === 'MOBILE') {
    //   token = AsyncStorage.getItem(ETokenAsync.TOKEN);
    //   console.log('MOBILE-TOKEN', token);
    // }
    // else {
    //   console.error('Error');
    // }

    if (id) {
      if (config.headers) {
        config.headers['id'] = id;
      }
    }

    if (token) {
      if (config.headers) {
        config.headers['authorization'] = token;
      }
    }

    if (clientNumber) {
      if (config.headers) {
        config.headers['client-Number'] = clientNumber;
      }
    }

    return config;
  });



  secretioAppServer.interceptors.response.use(
    (response: AxiosResponse<any>) => {
      return response;
    },
    (error: any) => {
      const { status } = error?.response;

      if (typeWS === 'SITE') {
        if (status === 401) {
          storePersisted.dispatch({ type: USER_SET, payload: {} });
          storePersisted.dispatch({ type: USER_LOGGED, payload: false });
          storePersisted.dispatch({ type: USER_LOGOUT_STATE, payload: false });
        }
      }

      throw error?.response?.data;
    },
  );



  return secretioAppServer;
};



export default SecretioWS();
