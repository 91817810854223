import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
} from 'react-redux';

import {
  IUserMedia,
} from '../../../../../apis/interfaces/Medias/user-media.interface';

import {
  IViewMediaModal,
} from '../../../../../apis/interfaces/Medias/view-media-modal.interface';

import BadgeApp from '../../../../../components/Badges/BadgeApp';
import CardVideo from '../../../../../components/Cards/CardMedia/CardVideo';
import ChipApp from '../../../../../components/Chip';

import EmptyContent from '../../../../../components/Empty';

import {
  IconWorld,
  IconFaClub,
  IconLocked,
  IconMediaVideo,
} from '../../../../../components/Icons';

import {
  RootState,
} from '../../../../../redux';

import {
  Colors,
  Images,
} from '../../../../../shared/constants';

import EAppMediaVisibility from '../../../../../shared/enums/Status/app-media-visibility.enum';

import {
  TranslateConfig,
} from '../../../../../shared/translations';

import {
  MaskNotificationsBadge,
} from '../../../../../shared/utils/masks.utils';

import {
} from '../../../../../styles/styled.layout';

import RightContentUser from '../../Right';

import {
  CharContentCenterRowFlex,
  ContentCenterFlex,
  MediaHeaderPadderTop,
  MediaPhotoGridView,
} from './styled';



export interface IProps {
  showActions?: boolean;
  modele?: any;
  dataMedia?: Array<IUserMedia>;
  suggestions?: Array<any>;
  onShowMedia?: any;
}



const TabVideosScreen: React.FC<IProps> = (props: IProps) => {
  const modeleReceived = props.modele;
  const suggestionsArray = props.suggestions;
  const showActions = props.showActions;


  const { t: translate } = useTranslation();


  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);

  const genderColorChoose = useSelector((state: RootState) => state.choose.genderColorApp);
  const accentGender = genderColorChoose;


  const videosArray = props.dataMedia || [];

  const [filteredVideos, setFilteredVideos] = useState<any[]>([]);
  const [typeImage, setTypeImage] = useState<EAppMediaVisibility | ''>('');


  const videosOptions = [
    {
      visible: true,
      title: translate(TranslateConfig.ALL),
      typeImage: '',
      icon: (
        <IconMediaVideo
          color={Colors.white}
          size={18}
        />
      ),
      badgeCount: videosArray?.length,
    },
    {
      visible: true,
      title: translate(TranslateConfig.PUBLIC_OPEN),
      typeImage: EAppMediaVisibility.PUBLIC,
      icon: (
        <IconWorld
          color={Colors.white}
          size={18}
        />
      ),
      badgeCount: videosArray?.filter((media: any) => media?.visibility === EAppMediaVisibility.PUBLIC).length,
    },
    {
      visible: projectParams?.showFanClub,
      title: translate(TranslateConfig.FAN_CLUB),
      typeImage: EAppMediaVisibility.FAN_CLUB,
      icon: (
        <IconFaClub
          color={Colors.white}
          size={18}
        />
      ),
      badgeCount: videosArray?.filter((media: any) => media?.visibility === EAppMediaVisibility.FAN_CLUB).length,
    },
    {
      visible: projectParams?.showFanClub,
      title: translate(TranslateConfig.PRIVATES),
      typeImage: EAppMediaVisibility.PRIVATE,
      icon: (
        <IconLocked
          color={Colors.white}
          size={18}
        />
      ),
      badgeCount: videosArray?.filter((media: any) => media?.visibility === EAppMediaVisibility.PRIVATE).length,
    },
  ];



  function renderChipChooses(item: any, index: number) {
    if (!item?.visible) {
      return;
    }


    return (

      <ChipApp
        key={index}
        title={item?.title}
        selected={typeImage === item?.typeImage}
        selectedBackgroundColor={accentGender}
        thumb={item?.icon}
        icon={
          <BadgeApp
            text={MaskNotificationsBadge(item?.badgeCount)}
            backgroundColor={Colors.black}
            color={Colors.white}
          />
        }
        onPress={() => {
          setTypeImage(item?.typeImage);
        }}
      />

    );
  }


  function renderItem(item: any, index: number) {
    return (

      <CardVideo
        key={index}
        image={item?.picture}
        visibility={item?.visibility}
        canBuy={item?.canBuy}
        onPress={() => {
          const itemToSender: IViewMediaModal = {
            indexSelected: index,
            type: 'VIDEOS',
          };

          if (props.onShowMedia) {
            props.onShowMedia(itemToSender);
          }
        }}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noMediaVideo}
        title={translate(TranslateConfig.EMPTY_GALLERY_VIDEOS)}
      />

    );
  }



  useEffect(() => {
    if (typeImage === '') {
      setFilteredVideos(videosArray);
    }
    else {
      const filtered = videosArray.filter((media) => media?.visibility === typeImage);
      setFilteredVideos(filtered);
    }
  }, [typeImage, videosArray]);



  return (

    <CharContentCenterRowFlex>

      <ContentCenterFlex>

        <MediaHeaderPadderTop>
          {videosOptions.map((chip, index) => (
            renderChipChooses(chip, index)
          ))}
        </MediaHeaderPadderTop>



        <MediaPhotoGridView
          noScroll
          data={filteredVideos}
          renderItem={renderItem}
          listEmptyComponent={listEmptyComponent()}
        />

      </ContentCenterFlex>



      <RightContentUser
        showActions={showActions}
        modele={modeleReceived}
        suggestions={suggestionsArray}
      />

    </CharContentCenterRowFlex>

  );
};



export default TabVideosScreen;
