import React from 'react';

import {
  Images,
} from '../../../shared/constants';

import {
  TipMeterWrapContainer,
  TipMeterWinners,
  TipMeterTrophy,
  TipMeterInfoWin,
  TipMeterTopText,
  TipMeterUsernameText,
  TipMeterStatusView,
  TipMeterCurrentlyText,
  TipMeterSuccessText,
  TipMeterCreditsText,
  TipMeterContent,
  TipShadow1,
  TipShadow2,
  TipShadow3,
  TipMeterBar,
  TipMeterGradientBar,
  TipMeterTopBar,
  TipMeterShineBar,
  TipSection1,
  TipSection2,
  TipSection3,
  TipSectionTitle,
  TipSectionAmountView,
  TipSectionAmount,
  TipSectionCredits,
} from './styled';



const PartyTermometer: React.FC = () => {
  return (

    <TipMeterWrapContainer>

      {/* Winner */}
      <TipMeterWinners>

        <TipMeterTrophy
          alt={''}
          src={Images.trophy}
        />


        <TipMeterInfoWin>
          <TipMeterTopText>
            TOP TIPPER
          </TipMeterTopText>


          <TipMeterUsernameText>
            shawndavid21
          </TipMeterUsernameText>


          <TipMeterStatusView>
            <TipMeterCurrentlyText>
              Medidor atual com
            </TipMeterCurrentlyText>


            <TipMeterSuccessText>
              70
            </TipMeterSuccessText>


            <TipMeterCreditsText>
              créditos
            </TipMeterCreditsText>
          </TipMeterStatusView>

        </TipMeterInfoWin>

      </TipMeterWinners>



      {/* Tip Meter */}
      <TipMeterContent>

        {/* <!-- Info Bubble --> */}
        {/* <div className="tip-info-bubble">
          <div className="tip-info-bubble-header">Party Goal Meter</div>

          <div className="tip-info-buble-section" id="bubbleSectionOne" >
            <b>@ <span className="goal-amt">2222</span> Credits</b>
            <span className="goal-description"><span className="icon squirt"></span></span>
          </div>

          <div className="tip-info-buble-section" id="bubbleSectionTwo" >
            <b>@ <span className="goal-amt">170</span> Credits</b>
            <span className="goal-description">masturbate<span className="icon wink"></span></span>
          </div>

          <div className="tip-info-buble-section" id="bubbleSectionThree"
          //  style="display: none;"
          >
            <b>@ <span className="goal-amt">1,500</span> Credits</b>
            <span className="goal-description">I Squirt</span>
          </div>
        </div> */}



        {/* Goal Shadows */}
        <TipShadow1 />
        <TipShadow2 />
        <TipShadow3 />



        {/* Meter Bar */}
        <TipMeterBar>

          <TipMeterGradientBar />
          <TipMeterTopBar />
          <TipMeterShineBar />


          {/* First Goal */}
          <TipSection1>
            <TipSectionTitle>
              Show Boobs and Butt!
            </TipSectionTitle>

            <TipSectionAmountView>
              <TipSectionAmount>
                100
              </TipSectionAmount>

              <TipSectionCredits>
                {'Créditos'.toUpperCase()}
              </TipSectionCredits>
            </TipSectionAmountView>
          </TipSection1>


          {/* Second Goal */}
          <TipSection2>
            <TipSectionTitle>
              See Cock
            </TipSectionTitle>

            <TipSectionAmountView>
              <TipSectionAmount>
                400
              </TipSectionAmount>

              <TipSectionCredits>
                {'Créditos'.toUpperCase()}
              </TipSectionCredits>
            </TipSectionAmountView>
          </TipSection2>


          {/* Third Goal */}
          <TipSection3>
            <TipSectionTitle>
              Masturbate
            </TipSectionTitle>

            <TipSectionAmountView>
              <TipSectionAmount>
                1000
              </TipSectionAmount>

              <TipSectionCredits>
                {'Créditos'.toUpperCase()}
              </TipSectionCredits>
            </TipSectionAmountView>
          </TipSection3>

        </TipMeterBar>

      </TipMeterContent>

    </TipMeterWrapContainer>

  );
};



export default PartyTermometer;
