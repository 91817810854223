import styled from 'styled-components';



export const ToolbarViewContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderEndContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
