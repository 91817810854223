import React from 'react';

import {
  Colors,
} from '../../../shared/constants';

import {
  CheckBox,
  Input,
} from '../../Composh/web';



export interface IProps {
  disabled?: boolean;
  title?: string;
  helpText?: string;
  checked?: boolean;
  color?: string;
  onPress?: any;
}



const InputCheckbox: React.FC<IProps> = (props: IProps) => {
  const className: any = { ...props };
  const accentGender = props.color || Colors.secondaryDark;



  return (

    <>
      <CheckBox
        {...className}
        disabled={props.disabled}
        title={props.title}
        checked={props.checked}
        // checkedIcon={'dot-circle-o'}
        // uncheckedIcon={'circle-o'}
        checkedColor={accentGender}
        uncheckedColor={Colors.inputPlaceholder}
        titleStyle={{
          color: props.checked ? Colors.white : Colors.inputLabel,
        }}
        onPress={() => {
          if (props.onPress) {
            props.onPress();
          }
        }}
      />



      <Input.Help
        helpText={props.helpText}
        helpTextStyle={{
          color: Colors.danger,
        }}
      />

    </>

  );
};


export default InputCheckbox;
