import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import ButtonAction from '../../../../../components/Buttons/ButtonAction';

import {
  IconReport,
} from '../../../../../components/Icons';

import SectionTitle from '../../../../../components/Titles/SectionTitle';

import {
  SetLiveRoomStatusColor,
} from '../../../../../config/app-live-room-status.config';

import {
  RootState,
} from '../../../../../redux';

import {
  USER_BAD_REPORT_MODAL,
} from '../../../../../redux/reducers/app-modals.store';

import {
  Colors,
  Sizes,
} from '../../../../../shared/constants';

import {
  ERoomType,
} from '../../../../../shared/enums';

import {
  TranslateConfig,
} from '../../../../../shared/translations';

import RightContentUser from '../../Right';

import {
  ContentCenterFlex,
  ContentRowWrapperAuto,
  WrapperItemContent,
  FeedTipsHeader,
  FeedTipsIconHeader,
  UserChatTypesView,
  UserChatTypesInfoView,
  UserChatTypeIcon,
  UserChatTypeText,
  UserAboutMeText,
  ScheduleTimezoneView,
  SchedulePlaceView,
  SchedulePlaceText,
  ScheduleTimezoneText,
  SchedulePlaceButton,
  ModelGrindSchedule,
  DayWrap,
  TheDay,
  ScheduleTime,
  LiveMyChatsView,
  MySchedulesView,
  ModelScheduleScroll,
} from './styled';



export interface IProps {
  showActions?: boolean;
  modele?: any;
  suggestions?: Array<any>;
}



const TabLiveScreen: React.FC<IProps> = (props: IProps) => {
  const modeleReceived = props.modele;
  const suggestionsArray = props.suggestions;
  const showActions = props.showActions;


  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const genderColorChoose = useSelector((state: RootState) => state.choose.genderColorApp);
  const accentGender = genderColorChoose;



  function renderDayLive(day: string, hour: string) {
    return (

      <DayWrap>
        <TheDay>
          {day}
        </TheDay>

        <ScheduleTime>
          {hour}
        </ScheduleTime>
      </DayWrap>

    );
  }



  return (

    <ContentRowWrapperAuto>

      <ContentCenterFlex>

        <LiveMyChatsView>

          <FeedTipsHeader>
            <SectionTitle
              title={translate(TranslateConfig.CHATS_MY)}
            />

            {/* FIXME */}
            {false && (
              <FeedTipsIconHeader
                onClick={() => {
                  dispatch({ type: USER_BAD_REPORT_MODAL, payload: true });
                }}>
                <IconReport
                  color={Colors.white}
                  size={18}
                />
              </FeedTipsIconHeader>
            )}

          </FeedTipsHeader>



          <UserChatTypesView>
            <UserChatTypesInfoView>
              <UserChatTypeIcon
                backgroundColor={SetLiveRoomStatusColor(ERoomType.BASIC)}
              />

              <UserChatTypeText>
                {translate(TranslateConfig.CHATS_SIMPLE)}
              </UserChatTypeText>
            </UserChatTypesInfoView>

            <UserAboutMeText>
              {'No simples você pode me conhecer um pouco mais de mim. Eu falo como sou, o que faço e o que gosto de fazer.'}
            </UserAboutMeText>
          </UserChatTypesView>



          <UserChatTypesView>
            <UserChatTypesInfoView>
              <UserChatTypeIcon
                backgroundColor={SetLiveRoomStatusColor(ERoomType.PRIVATE)}
              />

              <UserChatTypeText>
                {translate(TranslateConfig.CHATS_PRIVATE)}
              </UserChatTypeText>
            </UserChatTypesInfoView>

            <UserAboutMeText>
              {'A nudez é permitida, após um bom papo com interação, você consegue tudo de mim.'}
            </UserAboutMeText>
          </UserChatTypesView>

        </LiveMyChatsView>



        <WrapperItemContent>

          <MySchedulesView>

            <SectionTitle
              title={translate(TranslateConfig.SHOW_MY)}
            />



            <ScheduleTimezoneView>

              <SchedulePlaceView>
                <SchedulePlaceText>
                  {translate(TranslateConfig.TIMEZONE_LOCATION)}
                </SchedulePlaceText>

                <ScheduleTimezoneText>
                  {'Santiago, Buenos Aires, Brasilia, Greenland'}
                </ScheduleTimezoneText>
              </SchedulePlaceView>


              <SchedulePlaceButton>
                <ButtonAction
                  title={translate(TranslateConfig.ACTION_CHANGE_LOCATION_TIMEZONE)}
                  height={Sizes.buttonHeight}
                  backgroundColor={accentGender}
                />
              </SchedulePlaceButton>

            </ScheduleTimezoneView>

          </MySchedulesView>



          <ModelScheduleScroll>
            <ModelGrindSchedule>

              {renderDayLive(
                translate(TranslateConfig.SUNDAY),
                '4:30 pm to 12:30 am',
              )}


              {renderDayLive(
                translate(TranslateConfig.MONDAY),
                '4:30 pm to 12:30 am',
              )}


              {renderDayLive(
                translate(TranslateConfig.TUESDAY),
                '4:30 pm to 12:30 am',
              )}


              {renderDayLive(
                translate(TranslateConfig.WEDNESDAY),
                '4:30 pm to 12:30 am',
              )}


              {renderDayLive(
                translate(TranslateConfig.THURSDAY),
                '4:30 pm to 12:30 am',
              )}


              {renderDayLive(
                translate(TranslateConfig.FRIDAY),
                '4:30 pm to 12:30 am',
              )}


              {renderDayLive(
                translate(TranslateConfig.SATURDAY),
                '4:30 pm to 12:30 am',
              )}

            </ModelGrindSchedule>
          </ModelScheduleScroll>

        </WrapperItemContent>

      </ContentCenterFlex>



      <RightContentUser
        showActions={showActions}
        modele={modeleReceived}
        suggestions={suggestionsArray}
      />

    </ContentRowWrapperAuto>

  );
};



export default TabLiveScreen;
