import styled from 'styled-components';

import ButtonPublish from '../../../components/Buttons/ButtonPublish';

import {
  ListFlat,
} from '../../../components/Composh/web';

import {
  Colors,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';



export const TitleModeleReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 45px;
  margin-bottom: 15px;
`;

export const TitleModeleReviewLengthView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2px;
  padding-left: 3px;
  padding-right: 3px;

  @media ${PlatformDevices.maxTablet} {
    flex-direction: column-reverse;
    justify-content: center;
  }
`;

export const TitleModeleReviewLengthText = styled.p`
  color: ${Colors.white};
  font-size: 20px;
  line-height: 20px;

  @media ${PlatformDevices.maxTablet} {
    margin-bottom: 10px;
  }
`;

export const TitleModeleReviewLengthHelpText = styled.p`
  padding-left: 3px;
  padding-right: 3px;
  color: ${Colors.gray};
  font-size: 15px;
`;

export const TitleModeleReviewAverageText = styled.p`
  margin-top: 7px;
  color: ${Colors.gray};
  font-size: 13px;
`;



export const ButtonAutomaticPublishStyled = styled(ButtonPublish)`
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
`;


export const ModeleReviewScrollFlatList = styled(ListFlat).attrs({
  style: {
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainerStyle: {
    display: 'flex',
    flexDirection: 'column',
  },
})`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
