import React,
{
  useState,
} from 'react';

import ButtonAction from '../../components/Buttons/ButtonAction';

import {
  Overlay,
} from '../../components/Composh/web';

import {
  ContentButtonForm,
} from '../../components/Contents/ContentPadder';

import EmptyContent from '../../components/Empty';
import HeaderModal from '../../components/Headers/HeaderModal';
import LoadingScreen from '../../components/Loadings/LoadingScreen';

import {
  Colors,
  Sizes,
} from '../../shared/constants';

import {
  ModeleBadReportContainer,
  ModeleBadReportContent,
  ModeleBadReportText,
  ModeleBadReportInput,
  ModeleBadReportCaptchaView,
  ModeleBadReportCaptchaImageView,
} from './styled';



export interface IProps {
  visible?: boolean;
  onClose?: any;
}



const ModeleBadReportModal: React.FC<IProps> = (props: IProps) => {
  const visibleModal = props.visible;
  if (!visibleModal) {
    return null;
  }


  const [loading, setLoading] = useState(false);
  const [badReportDone, setBadReportDone] = useState(false);



  return (

    <Overlay
      visible={visibleModal}
      showBackground={true}
      overlayColor={Colors.menuOverlay}
      onOverlayPress={props.onClose}>

      <ModeleBadReportContainer>

        <HeaderModal
          title={`Denunciar ${'NOME'}`}
          onPress={props.onClose}
        />



        {loading && (
          <ModeleBadReportContent>

            <LoadingScreen
              type={'SCREEN'}
            />

          </ModeleBadReportContent>
        )}



        {!loading && !badReportDone && (
          <ModeleBadReportContent>

            <ModeleBadReportText>
              Por favor, deixe-nos saber por que você deseja denunciar este perfil. Sinta-se à vontade para colar links em seu relatório.
            </ModeleBadReportText>


            <ModeleBadReportInput
              type={'TEXT'}
              labelText={'Descrição da denúncia'}
              countLimit={2000}
            />


            <ModeleBadReportCaptchaView>

              <ModeleBadReportCaptchaImageView>

              </ModeleBadReportCaptchaImageView>


              <ModeleBadReportInput
                type={'TEXT'}
                labelText={'Código captcha'}
                countLimit={10}
              />

            </ModeleBadReportCaptchaView>



            <ContentButtonForm>
              <ButtonAction
                width={Sizes.buttonMinWidth}
                title={'Enviar'}
                backgroundColor={Colors.primaryDark}
                color={Colors.white}
                onPress={() => {
                  setBadReportDone(true);
                }}
              />
            </ContentButtonForm>

          </ModeleBadReportContent>
        )}



        {!loading && badReportDone && (
          <ModeleBadReportContent>

            <EmptyContent
              title={'Denúncia feita com sucesso'}
            />

          </ModeleBadReportContent>
        )}

      </ModeleBadReportContainer>

    </Overlay>

  );
};



export default ModeleBadReportModal;
