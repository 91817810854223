import React from 'react';

import {
  Images,
} from '../../../shared/constants';

import {
  ModelRecordContainer,
  ModelContainerButton,
  ModelContainerImage,
  ModelContentInfoView,
  ModelContentCategoryText,
  ModelContentNameView,
  ModelContentNameText,
  ModelContentUserInfoView,
  ModelContentQuantityText,
  ModelContentDateText,
} from './styled';



const CardModeleHallRecord: React.FC = () => {
  return (

    <ModelRecordContainer>

      <ModelContainerButton
      // onClick={
      //   // href="/vod/models/977854/"
      // }
      >
        <ModelContainerImage
          alt={'Usermame'}
          src={Images.avatarUserImage}
        />
      </ModelContainerButton>



      <ModelContentInfoView>
        <ModelContentCategoryText>
          Most Distinct Users in Shows in a Month
        </ModelContentCategoryText>

        <ModelContentNameView>
          <ModelContentNameText>
            {('Paolo Jonas')?.toUpperCase()}
          </ModelContentNameText>
        </ModelContentNameView>
      </ModelContentInfoView>



      <ModelContentUserInfoView>
        <ModelContentQuantityText>
          1,102 users
        </ModelContentQuantityText>

        <ModelContentDateText>
          July 31, 2019
        </ModelContentDateText>
      </ModelContentUserInfoView>

    </ModelRecordContainer>

  );
};



export default CardModeleHallRecord;
