import styled,
{
  css,
  keyframes,
} from 'styled-components';

import Color from 'color';

import ButtonPublish from '../../../components/Buttons/ButtonPublish';

import {
  Card,
} from '../../../components/Composh/web';

import WarningItemContent from '../../../components/Contents/WarningItem';
import ButtonPanelHover from '../../../components/Details/ButtonPanelHover';

import {
  Colors,
  Images,
  Sizes,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';



export interface IProps {
  comeNow?: boolean;
  color?: string;
  backgroundColor?: string;
}



export const PanelContentCenterFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
  width: 100%;

  @media ${PlatformDevices.maxTabletM} {
    align-items: center;
  }
`;



export const WarningTextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
`;

export const WarningItemStyled = styled(WarningItemContent)`
  margin-top: 0px;
  margin-bottom: 20px;
`;



export const DetailsApproveViewBackground = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 230px;
  border-radius: ${Sizes.cardRadius}px;
`;

export const HeaderUserBackgroundOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(${Images.backgroundDot}) repeat scroll 0 0 rgba(0, 0, 0, 0);
  z-index: 1;
`;

export const DetailsApproveBackground = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const DetailsApproveViewImage = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-top: -120px;
  border-radius: ${Sizes.cardRadius}px;
  z-index: 10;

  @media ${PlatformDevices.minTabletM} {
    margin-right: 20px;
  }
`;



export const PanelPadderContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: ${Sizes.paddingFlatList}px;

  @media ${PlatformDevices.maxTabletM} {
    margin-top: 10px;
  }

  @media ${PlatformDevices.minTabletM} {
    margin-top: -120px;
  }
`;


export const PanelAvatarItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 12px;
  margin-bottom: 25px;
`;

export const PanelAvatarName = styled.p`
  color: ${Colors.primary};
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  word-break: break-word;
  text-overflow: ellipsis;
`;

export const PanelUsernameValuesView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 7px;
`;

export const PanelAvatarUsername = styled.p`
  color: ${Colors.gray};
  font-size: 19px;
  
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  word-break: break-word;
  text-overflow: ellipsis;
`;

export const HomeIdentiyIcon = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 8px;
`;



export const PanelWrapperItemContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 25px;
`;

export const PanelWrapperLastItemContent = styled(PanelWrapperItemContent)`
  margin-bottom: 0;

  @media ${PlatformDevices.maxLaptop} {
    margin-bottom: 35px;
  }
`;



export const PanelActiveItemContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 5px;
`;

export const ButtonPublishStyled = styled(ButtonPublish)`
  margin-top: 3px;
  margin-bottom: 10px;

  @media ${PlatformDevices.maxTablet} {
    width: 100%;
  }

  @media ${PlatformDevices.minTablet} {
    width: calc(50% - 10px);
  }

  @media ${PlatformDevices.minLaptopL} {
    width: calc(25% - 10px);
  }
`;



export const PanelResumeContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const PanelResumeTitle = styled.p`
  margin-bottom: 2px;
  color: ${Colors.grayLight};
  font-size: 15px;
  line-height: 17px;
`;

export const PanelLocationsFlag = styled.img`
  margin-right: 8px;
  width: 20px;
  height: 20px;
  object-fit: contain;
`;

export const PanelResumeText = styled.p`
  margin-bottom: 2px;
  color: ${Colors.white};
  font-size: 18px;
  line-height: 20px;
  font-weight: bold;
`;


export const ModeleTravelBadgeView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  margin-top: -2px;
  margin-left: 12px;
  padding-left: 2px;
  background-color: ${Colors.tertiary};
  border-color: ${Colors.white};
  border-style: solid;
  border-width: 0.5px;
  border-radius: 50%;
`;

export const PanelResumeLocationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 10px;
`;

export const PanelResumeLevelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
`;

export const PanelResumeLevelView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  max-height: 24px;
  aspect-ratio: 1 / 1;
  margin-left: 3px;
  margin-right: 3px;
`;

export const PanelResumeLevelImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;


export const PanelValuesContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media ${PlatformDevices.maxTabletL} {
    flex-direction: column;
  }
`;

export const PanelValuesContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const PanelValuesLabel = styled.p`
  margin-top: 3px;
  margin-bottom: 3px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: ${Colors.gray};
`;

export const PanelValuesText = styled.span`
  color: ${(props: IProps) => props.color || Colors.white};
  font-weight: normal;
`;

export const PanelValuesPublishView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
`;

export const PanelValuesPublishIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 6px;
`;

export const PanelValuesPublishText = styled.p`
  color: ${(props: IProps) => props.color};
  font-size: 16px;
  line-height: 1.5;
`;


export const PanelOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 5px;
`;

export const PanelUpgradeContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
  margin-top: 2px;
`;



export const PanelItemsButton = styled(ButtonPanelHover)`
  min-width: 270px;

  @media ${PlatformDevices.maxLaptop} {
    min-width: 150px;
  }
`;


const LongPulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 ${Colors.pulseAnimation};
  }

  70% {
    box-shadow: 0 0 0 8px rgba(0, 255, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 255, 0, 0);
  }
`;

const LongPulseStyle = css`
  border-color: ${Colors.pulse};
  border-style: solid;
  border-width: 3px;
  animation: ${LongPulseAnimation} 2s infinite;

  :hover {
    background-color: ${Color(Colors.pulse).darken(0.9).toString()};
    border-color: ${Colors.pulse};
  }
`;

export const PanelHighlightButton = styled(ButtonPanelHover)`
  min-width: 270px;
  ${(props: IProps) => props.comeNow && LongPulseStyle}

  @media ${PlatformDevices.maxLaptop} {
    min-width: 150px;
  }
`;

export const PanelItemsIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3px;
  margin-bottom: 12px;
`;

export const PanelItemsText = styled.p`
  color: ${Colors.white};
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
`;

export const PanelInputHelpText = styled.p`
  margin-top: 4px;
  color: ${(props: IProps) => props.color || Colors.danger};
  font-size: 12px;
  font-style: italic;
  line-height: 15px;
  text-align: center;
`;

export const CommentsCountContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CommentsCountIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`;

export const CommentsCountText = styled.p`
  color: ${Colors.white};
  font-size: 28px;
  font-weight: 500;
`;



export const FinancialRightSuggestion = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  margin-left: ${30}px;

  @media ${PlatformDevices.maxLaptop} {
    width: 100%;
    min-width: 100%;
    margin-left: 0;
  }
`;



export const SuggestionViewButton = styled.a`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const SuggestionTitle = styled.h3`
  margin: 0;
  color: ${Colors.white};
  font-size: 18px;
  font-weight: 500;
`;

export const SuggestionText = styled.p`
  margin-top: 5px;
  color: ${Colors.textDescription};
  font-size: 12.5px;
`;



export const FinancialAdsImageButton = styled(Card.View)`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 15px;
`;

export const FinancialAdsAlButton = styled(FinancialAdsImageButton)`
  margin-bottom: 0;
`;

export const FinancialAdsTextBold = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3px;
`;

export const FinancialTitle = styled.p`
  margin-left: 8px;
  color: ${Colors.attention};
  font-size: 16px;
  font-weight: bold;
`;

export const FinancialAdsBold = styled.a`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-bottom: 4px;
  color: ${Colors.white};
  font-size: 12px;
  line-height: 17px;
  font-weight: bold;
  transition: all 0.2s ease;

  :hover {
    color: ${Colors.primary};
  }
`;

export const FinancialAdsNormalText = styled.p`
  margin-top: 4px;
  margin-bottom: 4px;
  color: ${Colors.white};
  font-size: 12px;
  line-height: 17px;
`;
