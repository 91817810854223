import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  useSelector,
} from 'react-redux';

import data from '@emoji-mart/data';
import {
  Picker,
} from 'emoji-mart';

import {
  RootState,
} from '../../../redux';

import {
  Colors,
} from '../../../shared/constants';

import {
  IconAdd,
  IconEmoji,
  IconSend,
} from '../../Icons';

import InputText from '../../Inputs/InputText';
import MessageSuggestion from '../../Messages/MessageSuggestion';

import {
  InputChatView,
  SuggestionTextChatView,
  InputTextChatView,
  EmojiChatButton,
  EmojiChatView,
  SendChatButton,
  SendChatTextButton,
} from './styled';



interface IProps {
  hideOptions?: boolean;
  hideSuggestion?: boolean;
  backgroundColor?: string;
}



const InputChat: React.FC<IProps> = (props) => {
  const genderColorChoose = useSelector((state: RootState) => state.choose.genderColorApp);


  const [input, setInput] = useState('');
  const [showEmojis, setShowEmojis] = useState(false);



  const addEmoji = (event) => {
    const sym = event.unified.split('-');
    const codesArray = [];
    sym.forEach((element) => codesArray.push('0x' + element));
    const emoji = String.fromCodePoint(...codesArray);
    setInput(input + emoji);
  };


  function EmojiPicker(props) {
    const ref = useRef();

    useEffect(() => {
      new Picker({ ...props, data, ref });
    }, []);


    return (

      <EmojiChatView
        ref={ref}
      />

    );
  };



  return (

    <InputChatView
      backgroundColor={props.backgroundColor}>

      {!props.hideSuggestion && (
        <SuggestionTextChatView>
          <MessageSuggestion />
          <MessageSuggestion />
        </SuggestionTextChatView>
      )}


      <InputTextChatView>

        <EmojiChatButton
          onClick={() => {
            setShowEmojis(!showEmojis);
          }}>
          <IconEmoji
            color={Colors.white}
            size={19}
          />
        </EmojiChatButton>


        {/* ENVIAR ARQUIVO [IMAGEM/VIDEO] */}


        {!props.hideOptions && (
          <EmojiChatButton>
            <IconAdd
              color={Colors.white}
              size={26}
            />
          </EmojiChatButton>
        )}


        {/* GPS */}


        {showEmojis && (
          <EmojiPicker
            onEmojiSelect={addEmoji}
          />
        )}


        <InputText
          type={'text'}
          // value={input}
          placeholderText={'Diga algo legal'}
          onChange={(event) => {
            // setInput(event.target.value);
          }}
        />


        <SendChatButton
          backgroundColor={genderColorChoose}
          onClick={() => {
            setShowEmojis(false);
          }}>
          <IconSend
            color={Colors.white}
            size={17}
          />

          <SendChatTextButton>
            Enviar
          </SendChatTextButton>
        </SendChatButton>

      </InputTextChatView>

    </InputChatView>

  );
};



InputChat.defaultProps = {
  hideOptions: false,
};



export default InputChat;
