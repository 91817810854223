import styled from 'styled-components';



export const GroupTypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px 15px;
  background-color: #f4cf01;
`;

export const GroupTypeImage = styled.img`
  width: 60px;
  height: 60px;
  margin-right: 15px;
  object-fit: center;
`;



export const GroupInfoRoomView = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;

export const GroupInfoTitle = styled.h3`
  margin-bottom: 3px;
  color: #000000;
  font-size: 17px;
  font-weight: bold;
`;

export const GroupInfoText = styled.p`
  color: #000000;
  font-size: 15px;
`;



export const GroupAmountView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
`;

export const GroupAmountTitle = styled.h3`
  margin-bottom: 5px;
  color: #000000;
  font-size: 14px;
  font-weight: bold;
`;

export const GroupAmountText = styled.p`
  padding: 4px 10px;
  color: #FFFFFF;
  background-color: #000000;
  font-size: 17px;
  border-radius: 4px;
`;
