import styled from 'styled-components';

import {
  Chip,
} from '../Composh/web';



export interface IProps {
  clickable?: boolean;
}



export const ChipStyled = styled(Chip)`
  cursor: ${(props: IProps) => !props.clickable ? 'default' : 'pointer'};
`;
