import React,
{
  useState,
} from 'react';

import {
  useNavigate,
} from 'react-router-dom';

import {
  Screen,
  StepIndicator,
  Title,
} from '../../components/Composh/web';

import {
  ButtonBack,
} from '../../components/Controls';

import HeaderScreen from '../../components/Headers/HeaderScreen';

import {
  Colors,
} from '../../shared/constants';

import TabCheckData from './Tabs/TabCheckData';
import TabMeansCharge from './Tabs/TabMeansCharge';
import TabPackages from './Tabs/TabPackages';
import TabPaymentScreen from './Tabs/TabPayment';

// import { Container } from './styles';



const AddMoneyScreen: React.FC = () => {
  const history = useNavigate();


  // const event = useSelector((state: RootState) => state.event.selected);

  // const tickets = event.event_tickets;

  const [currentStep, setCurrentStep] = useState(0);
  const [steps] = useState([
    'Pacotes',
    'Meio de Cobrança',
    'Coferir dados',
    // 'Pagamento',
  ]);



  function returnLabelOkButton() {
    if (currentStep === 2) {
      return 'Finalizar';
    }
    else if (currentStep === 3) {
      return 'Fechar';
    }
    else {
      return 'Avançar';
    }
  }

  function okButtonPress() {
    let stepValue = currentStep;

    // if ((currentStep + 1) < steps.length) {
    if (currentStep !== 3) {
      setCurrentStep(++stepValue);
    }
    else {
      history(-1);
      setTimeout(() => {
        setCurrentStep(0);
      }, 1000);
    }
  }



  function returnLabelCancelButton() {
    if (currentStep === 0) {
      return 'Cancelar';
    }
    else {
      return 'Voltar';
    }
  }

  function cancelButtonPress() {
    let stepValue = currentStep;

    if (currentStep === 0) {
      history(-1);
      setCurrentStep(0);
    }
    else {
      // if (currentStep > 0) {
      setCurrentStep(--stepValue);
    }
  }



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        disableGender
        disableBalance
        showBalance
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            close
            color={Colors.white}
          />
        }
        centerContent={
          <Title>
            Adicionar crédito
          </Title>
        }>

        {currentStep !== 3 && (
          <StepIndicator
            colorAccent={Colors.primary}
            colorMoreStep={Colors.white}
            textColorPassed={Colors.white}
            colorPassed={Colors.secondary}
            data={steps}
            stepCurrent={currentStep}
          />
        )}

      </HeaderScreen>



      {currentStep === 0 &&
        <TabPackages
        // selectedId={entrance?.id}
        // tickets={tickets}
        // onPress={(id: number) => setEntrance(tickets.find((ticket) => ticket.id === id))}
        />
      }

      {currentStep === 1 && (
        <TabMeansCharge />
      )}

      {currentStep === 2 && (
        <TabCheckData />
      )}

      {currentStep === 3 && (
        <TabPaymentScreen />
      )}

    </Screen>

  );
};



export default AddMoneyScreen;
