const accessAppoint = true;
const devAppoint = false;
// const devAppoint = process.env.REACT_APP_NODE_ENV === 'DEV';

const environment = {
  production: false,
  environment: 'LOCAL',
  title: 'Local Environment Heading',
  apiAccountGlobalUrl: accessAppoint ? 'https://api-dev.noitada.app' : 'http://localhost:3333',
  apiUrl: devAppoint ? 'https://api-dev.cartoir.com' : 'http://localhost:6960',
  firebaseWebConfig: {
    apiKey: 'AIzaSyBo1QOAiqTimhdmb29NkV5jIWhijDXoeZk',
    authDomain: 'secretio-app-dev.firebaseapp.com',
    projectId: 'secretio-app-dev',
    storageBucket: 'secretio-app-dev.appspot.com',
    messagingSenderId: '145722738192',
    appId: '1:145722738192:web:d3e23c926dca8895a5ec95',
    measurementId: 'G-P0L0J8WW2F',
  },
};



export default environment;
