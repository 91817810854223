import React from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  RootState,
} from '../../../redux';

import {
  USER_BRAND_PROMOTER_MODAL,
  USER_VERIFIED_MODAL,
} from '../../../redux/reducers/app-modals.store';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import {
  EPlansUser,
} from '../../../shared/enums';

import UserAvatar from '../../Contents/UserAvatar';

import {
  IconVerified,
  IconVerifiedPromoter,
} from '../../Icons';

import {
  CardHomeProfileContainer,
  CardProfileButton,
  HomeProfileBackgroundImage,
  HomeProfileUserBackground,
  HomeProfileLabelItem,
  HomeProfileContent,
  HomeProfileInfoContainer,
  HomeProfileAvatarContainer,
  HomeProfileInfoContent,
  HomeProfileNameText,
  HomeProfileUserIcon,
  HomeProfileStatusView,
  HomeProfileUsername,
  BadgeOnline,
} from './styled';



export interface IProps {
  online?: boolean;
  exclusive?: boolean;
  plan?: EPlansUser;
  image?: string;
  backgroundImage?: string;
  username?: string;
  name?: string;
  verified?: boolean;
  promoter?: boolean;
  onPress?: any;
}



const CardModeleSuggestion: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();


  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);


  const userImage = (props.image === '' || props.image === null || props.image === undefined)
    ? Images.avatarUserImage
    : props.image;

  const userBackground = (props.backgroundImage === '' || props.backgroundImage === null || props.backgroundImage === undefined)
    ? Images.bannerDetails
    : props.backgroundImage;



  return (

    <CardHomeProfileContainer>

      <CardProfileButton>

        <HomeProfileUserBackground
          onClick={props.onPress}>
          <HomeProfileBackgroundImage
            alt={`${props.username}-banner`}
            src={userBackground}
          />
        </HomeProfileUserBackground>



        {props.exclusive && (
          <HomeProfileLabelItem
            backgroundColor={Colors.menuOverlay}
            color={Colors.primary}>
            Exclusivo
          </HomeProfileLabelItem>
        )}



        <HomeProfileInfoContainer>

          <HomeProfileAvatarContainer
            onClick={props.onPress}>
            <UserAvatar
              width={85}
              image={userImage}
              plan={props.plan}
            />
          </HomeProfileAvatarContainer>



          <HomeProfileContent>

            <HomeProfileStatusView>

              <HomeProfileInfoContent>

                <HomeProfileUsername
                  onClick={props.onPress}>
                  @{props.username || '-'}
                </HomeProfileUsername>


                {props.verified && (
                  <HomeProfileUserIcon
                    onClick={() => {
                      dispatch({ type: USER_VERIFIED_MODAL, payload: true });
                    }}>
                    <IconVerified
                      size={13}
                      color={Colors.accent}
                    />
                  </HomeProfileUserIcon>
                )}


                {props.promoter && (
                  <HomeProfileUserIcon
                    onClick={() => {
                      dispatch({ type: USER_BRAND_PROMOTER_MODAL, payload: true });
                    }}>
                    <IconVerifiedPromoter
                      size={13}
                      color={Colors.white}
                    />
                  </HomeProfileUserIcon>
                )}

              </HomeProfileInfoContent>



              {projectParams?.canBeOnline && props.online && (
                <BadgeOnline
                  onClick={props.onPress}>
                  Online
                </BadgeOnline>
              )}

            </HomeProfileStatusView>



            <HomeProfileNameText
              onClick={props.onPress}>
              {props.name || '-'}
            </HomeProfileNameText>

          </HomeProfileContent>

        </HomeProfileInfoContainer>

      </CardProfileButton>

    </CardHomeProfileContainer>

  );
};



export default CardModeleSuggestion;
