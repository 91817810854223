import React from 'react';

import {
  Images,
} from '../../../../shared/constants';

import EAppMediaVisibility from '../../../../shared/enums/Status/app-media-visibility.enum';

import {
  validateString,
} from '../../../../shared/utils/string.utils';

import MediaFanClub from '../MediaFanClub';
import MediaPrivate from '../MediaPrivate';
import MediaSpecial from '../MediaSpecial';

import {
  MediaGridContainer,
  MediaGridContent,
  PhotoGridImage,
} from './styled';



export interface IProps {
  image: string;
  visibility: EAppMediaVisibility;
  canBuy?: boolean;
  onPress?: any;
}



const CardPhoto: React.FC<IProps> = (props: IProps) => {
  const userImage = validateString(props.image) || Images.noImage;



  return (

    <MediaGridContainer>
      <MediaGridContent
        onClick={() => {
          if (props.onPress) {
            props.onPress();
          }
        }}>

        <PhotoGridImage
          alt={''}
          src={userImage}
        />



        {props?.visibility?.toString() === EAppMediaVisibility.FAN_CLUB && !props.canBuy && (
          <MediaFanClub />
        )}



        {props?.visibility?.toString() === EAppMediaVisibility.FAN_CLUB && props.canBuy && (
          <MediaSpecial />
        )}



        {props?.visibility?.toString() === EAppMediaVisibility.PRIVATE && (
          <MediaPrivate />
        )}

      </MediaGridContent>
    </MediaGridContainer>

  );
};



export default CardPhoto;
