import styled from 'styled-components';

import {
  Colors,
  Images,
  Sizes,
} from '../../../../shared/constants';

import {
  PlatformDevices,
} from '../../../../shared/utils/platforms.utils';

const heightHeader = 375;



export interface IProps {
  disabled?: boolean;
  backgroundColor?: string;
}


export const HeaderUserHeaderView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  width: 100%;
  min-height: ${heightHeader}px;
`;



export const HeaderUserBackgroundView = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

export const HeaderUserBackgroundOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(${Images.backgroundDot}) repeat scroll 0 0 rgba(0, 0, 0, 0);
  z-index: 1;
`;

export const HeaderUserBackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;


export const HeaderUserInfoView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 25px;
  background-color: ${Colors.menuOverlay};
  z-index: 5;

  @media ${PlatformDevices.maxTabletM} {
    margin-top: 100px;
  }
`;

export const HeaderUserInfoContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: ${Sizes.contentMaxWidth}px;
  padding-left: ${Sizes.paddingContainer}px;
  padding-right: ${Sizes.paddingContainer}px;
  padding-bottom: 5px;

  @media ${PlatformDevices.maxLaptop} {
    padding-left: ${Sizes.paddingFlatList}px;
    padding-right: ${Sizes.paddingFlatList}px;
  }

  @media ${PlatformDevices.maxTabletM} {
    flex-direction: column;
    align-items: center;
  }
`;



export const HeaderUserAvatarImage = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -75px;
  padding-bottom: 10px;
`;

export const HeaderUserStatusView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
`;

export const HeaderStatusUsersBadge = styled.p`
  padding: 3px 13px;
  color: ${Colors.white};
  background-color: ${(props: IProps) => props.backgroundColor || Colors.black};
  font-size: 12px;
  border-radius: 50px;
  text-align: center;
`;

export const HeaderUserLastAccessText = styled.p`
  margin-top: 7px;
  color: ${Colors.grayLight};
  font-size: 12px;
`;



export const HeaderInfoContentView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  margin-left: 20px;
  padding-top: 15px;
  padding-bottom: 15px;

  @media ${PlatformDevices.maxTabletM} {
    align-items: center;
    width: 100%;
    margin-left: 0;
    padding-top: 5px;
    padding-bottom: 0;
  }
`;



export const HeaderUserExclusiveText = styled.p`
  margin-bottom: 3px;
  color: ${Colors.primary};
  font-size: 13px;

  @media ${PlatformDevices.maxMobileL} {
    text-align: center;
  }
`;

export const HeaderNameText = styled.h2`
  margin-top: 4px;
  margin-bottom: 4px;
  color: ${Colors.white};
  font-size: 28px;
  line-height: 30px;

  @media ${PlatformDevices.maxMobileL} {
    text-align: center;
  }
`;

export const SubtitleLongView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${PlatformDevices.maxMobileL} {
    justify-content: center;
  }
`;

export const HeaderUserNameText = styled.h2`
  margin-top: 3px;
  color: ${Colors.white};
  font-size: 14px;
`;

export const IconVerifiedButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-top: 2px;
  margin-left: 4px;
`;



export const HeaderUserFollowItemView = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;

  @media ${PlatformDevices.maxMobileL} {
    justify-content: center;
  }
`;

export const HeaderUserFollowView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 15px;
`;

export const HeaderUserAvaliatinsView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const HeaderUserFollowIconText = styled.p`
  display: flex;
  flex-direction: column;
  margin-right: 8px;
`;

export const HeaderUserFollowText = styled.p`
  margin-top: 2px;
  color: ${Colors.white};
  font-size: 13px;
`;



export const HeaderUserFollowContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 15px;
  padding-left: 25px;
  padding-bottom: 15px;

  @media ${PlatformDevices.maxTabletL} {
    padding-left: 10px;
  }

  @media ${PlatformDevices.maxTabletM} {
    align-items: center;
    padding-left: 0;
  }
`;


export const HeaderUserActionsBadgeView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media ${PlatformDevices.maxMobileL} {
    margin-top: 10px;
    align-items: center;
  }
`;



export const HeaderUserTravelView = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
`;



export const HeaderUserColumnActionView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  flex: 1;
  gap: 10px;

  @media ${PlatformDevices.maxTabletM} {
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 10px;
  }
`;

export const HeaderUserRowActionView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  gap: 10px;

  @media ${PlatformDevices.maxTabletM} {
    margin-top: 5px;
    margin-bottom: 0;
  }
`;

export const HeaderUserActionButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  background-color: ${(props: IProps) => props.backgroundColor || Colors.shadow};
  border-color: ${Colors.grayDark};
  border-style: solid;
  border-width: 0.5px;
  border-radius: 50%;
  opacity: ${(props: IProps) => props.disabled ? Sizes.opacityOff : Sizes.opacityOn};
`;
