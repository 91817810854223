import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';



export const DashboardClientMembership = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  padding: 20px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;

export const DashboardClientTopRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  @media ${PlatformDevices.maxLaptop} {
    flex-direction: column;
    margin-bottom: 25px;
  }
`;


export const ClientMemberhipTypeBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 160px;

  @media ${PlatformDevices.maxLaptop} {
    width: 100%;
    margin-bottom: 15px;
  }
`;


export const ClientMemberhipTypeValid = styled.div`
  display: flex;
  flex-direction: column;
  width: 160px;
  margin-left: 16px;

  @media ${PlatformDevices.maxLaptop} {
    width: 100%;
    margin-left: 0;
  }
`;


export const ClientMemberhipTitle = styled.h4`
  margin-bottom: 8px;
  color: ${Colors.grayLight};
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
`;


export const ClientMemberhipBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  height: 40px;
  background-color: #434343;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  border-radius: ${Sizes.cardRadius}px;
`;

export const ClientMemberhipText = styled.p`
  color: ${Colors.white};
  font-size: 15px;
`;

export const ClientMemberhipPlanBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 16px;

  @media ${PlatformDevices.maxLaptop} {
    width: 100%;
    margin-left: 0;
    margin-bottom: 15px;
  }
`;

export const DashboardClientBottomRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DashboardClientButtonsContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 16px;
`;
