import React,
{
  useEffect,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
} from 'react-redux';

import {
  Body,
  Content,
  Screen,
  SubTitle,
  Title,
} from '../../components/Composh/web';

import {
  ButtonDrawer,
} from '../../components/Controls';

import FooterApp from '../../components/Footers/FooterApp';
import HeaderScreen from '../../components/Headers/HeaderScreen';

import NameRoutes from '../../navigation/names';

import {
  APP_MENU_SELECTED_ACTION,
} from '../../redux/reducers/app-modals.store';

import {
  Colors,
  Images,
} from '../../shared/constants';

import {
  EEnvsName,
} from '../../shared/enums';

import EDrawerMenu from '../../shared/enums/App/drawer-menu.enum';
import Vars from '../../shared/environments/variables';

import Seo from '../../shared/seo';

import {
  TranslateConfig,
} from '../../shared/translations';

import HomeDetailsSection from './Details';
import HomeHeroSection from './Hero';



const HomeScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();



  const urlJsonLd = Vars(EEnvsName.PROD).secretioApp;

  const jsonLd = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    '@id': urlJsonLd,
    'url': urlJsonLd,
    'name': translate(TranslateConfig.NAME_APP),
    'image': Images.bannerAppLink,
    'description': Seo.descriptionGlobal,
    'breadcrumb': {
      '@type': 'BreadcrumbList',
      'itemListElement': [
        {
          '@type': 'ListItem',
          'position': 1,
          'name': translate(TranslateConfig.NAME_APP),
          'item': Vars(EEnvsName.PROD).secretioApp,
        },
        {
          '@type': 'ListItem',
          'position': 2,
          'name': translate(TranslateConfig.ESCORTS),
          'item': `${Vars(EEnvsName.PROD).secretioApp}${NameRoutes.RentHomeScreen}`,
        },
        {
          '@type': 'ListItem',
          'position': 3,
          'name': translate(TranslateConfig.BE_MODELE),
          'item': `${Vars(EEnvsName.PROD).secretioApp}${NameRoutes.BeModeleScreen}`,
        },
        {
          '@type': 'ListItem',
          'position': 4,
          'name': translate(TranslateConfig.REGISTER_FREE),
          'item': `${Vars(EEnvsName.PROD).secretioApp}${NameRoutes.AdsRegisterBothScreen}`,
        },
        {
          '@type': 'ListItem',
          'position': 5,
          'name': translate(TranslateConfig.PANEL_PROFISSIONAL),
          'item': `${Vars(EEnvsName.PROD).secretioApp}${NameRoutes.PanelModeleScreen}`,
        },
      ],
    },
  };



  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.HOME });
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet prioritizeSeoTags>
        <title>
          {translate(TranslateConfig.NAME_APP)}
        </title>

        <meta name="robots" content="index, follow" />
        <meta name="description" content={Seo.descriptionGlobal} />

        <meta name="keywords" content={Seo.keywords} />

        <meta property="og:site_name" content={translate(TranslateConfig.NAME_APP)} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={Images.logo512} />
        <meta property="og:url" content={urlJsonLd} />
        <meta property="og:title" content={translate(TranslateConfig.NAME_APP)} />
        <meta property="og:description" content={Seo.descriptionGlobal} />

        <link rel="canonical" href={urlJsonLd} />

        <script type="application/ld+json">
          {JSON.stringify(jsonLd)}
        </script>
      </Helmet>



      <HeaderScreen
        screenKey={EDrawerMenu.HOME}
        showBalance
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonDrawer
            color={Colors.white}
            size={27}
          />
        }
        centerContent={
          <Body>
            <Title
              color={Colors.white}>
              {translate(TranslateConfig.NAME_APP)}
            </Title>

            <SubTitle>
              {translate(TranslateConfig.SLOGAN_APP)}
            </SubTitle>
          </Body>
        }
      />



      <Content>

        <HomeHeroSection />

        <HomeDetailsSection />


        <FooterApp />

      </Content>

    </Screen>

  );
};



export default HomeScreen;
