import React,
{
  useState,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  Content,
  Screen,
  Title,
} from '../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../components/Controls';

import HeaderScreen from '../../../components/Headers/HeaderScreen';

import {
  IconMoney,
  IconRentOn,
  IconService,
} from '../../../components/Icons';

import TabBarStyled from '../../../components/TabBar';

import {
  Colors,
} from '../../../shared/constants';

import {
  TranslateConfig,
} from '../../../shared/translations';

import TabPricesScreen from './Tabs/Prices';
import ModeleRentsEditScreen from './Tabs/Rents';
import ServicesScreen from './Tabs/Services';



const RentProfileModeleScreen: React.FC = () => {
  const { t: translate } = useTranslation();


  const accentGender = Colors.primary;
  const iconTabBar = 23;


  const [stateIndex, setStateIndex] = useState(0);



  const screenTabs = [
    {
      visible: true,
      name: 'PREFERENCES',
      title: translate(TranslateConfig.ESCORT),
      icon: (
        <IconRentOn
          color={stateIndex === 0 ? accentGender : Colors.inputCount}
          size={iconTabBar}
        />
      ),
      component: (
        <ModeleRentsEditScreen />
      ),
    },
    {
      visible: true,
      name: 'SERVICES',
      title: translate(TranslateConfig.SERVICES),
      icon: (
        <IconService
          color={stateIndex === 1 ? accentGender : Colors.inputCount}
          size={iconTabBar}
        />
      ),
      component: (
        <ServicesScreen />
      ),
    },
    {
      visible: true,
      name: 'PRICES',
      title: translate(TranslateConfig.PAYMENT_AND_VALUES),
      icon: (
        <IconMoney
          color={stateIndex === 2 ? accentGender : Colors.inputCount}
          size={iconTabBar}
        />
      ),
      component: (
        <TabPricesScreen />
      ),
    },
  ];



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet prioritizeSeoTags>
        <title>
          {`${translate('Perfil acompanhante')} | ${translate(TranslateConfig.NAME_APP)}`}
        </title>

        <meta name="robots" content="noindex, nofollow" />
      </Helmet>



      <HeaderScreen
        disableGender
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            color={Colors.white}
            size={27}
          />
        }
        centerContent={
          <Title
            color={Colors.white}>
            Perfil acompanhante
          </Title>
        }
        noSubToolbarPadding>


        <TabBarStyled
          color={accentGender}
          tabs={screenTabs}
          selected={stateIndex}
          onPress={(tab) => {
            setStateIndex(tab?.index);
          }}
        />

      </HeaderScreen>



      <Content>

        {stateIndex === 0 && screenTabs[0].component}

        {stateIndex === 1 && screenTabs[1].component}

        {stateIndex === 2 && screenTabs[2].component}

      </Content>

    </Screen>

  );
};



export default RentProfileModeleScreen;
