import React from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  useNavigate,
} from 'react-router-dom';

import NameRoutes from '../../../navigation/names';

import {
  RootState,
} from '../../../redux';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import EUserType from '../../../shared/enums/Users/user-type.enum';

import ButtonAction from '../../Buttons/ButtonAction';

import {
  IconUpgrade,
  IconUser,
} from '../../Icons';

import {
  DashboardClientMembership,
  DashboardClientTopRow,
  ClientMemberhipTypeBox,
  ClientMemberhipTypeValid,
  ClientMemberhipTitle,
  ClientMemberhipBox,
  ClientMemberhipText,
  ClientMemberhipPlanBox,
  DashboardClientBottomRow,
  DashboardClientButtonsContent,
} from './styled';



const InfoPlan: React.FC = () => {
  const history = useNavigate();


  const user = useSelector((state: RootState) => state.user.actualUser);



  return (

    <DashboardClientMembership>

      <DashboardClientTopRow>

        <ClientMemberhipTypeBox>
          <ClientMemberhipTitle>
            Tipo de Membro:
          </ClientMemberhipTitle>

          <ClientMemberhipBox>
            <ClientMemberhipText>
              {user?.type === EUserType.MODELE ? 'Modelo' : 'Cliente'}
            </ClientMemberhipText>
          </ClientMemberhipBox>
        </ClientMemberhipTypeBox>



        <ClientMemberhipPlanBox>
          <ClientMemberhipTitle>
            Plano:
          </ClientMemberhipTitle>

          <ClientMemberhipBox>
            <ClientMemberhipText>
              Básico - Gratuito
            </ClientMemberhipText>
          </ClientMemberhipBox>
        </ClientMemberhipPlanBox>



        <ClientMemberhipTypeValid>
          <ClientMemberhipTitle>
            Válido até:
          </ClientMemberhipTitle>

          <ClientMemberhipBox>
            <ClientMemberhipText>
              {/* 21/02/2024 */}
              Ilimitado
            </ClientMemberhipText>
          </ClientMemberhipBox>
        </ClientMemberhipTypeValid>

      </DashboardClientTopRow>



      <DashboardClientBottomRow>

        <DashboardClientButtonsContent>

          <ButtonAction
            height={Sizes.buttonHeight}
            title={'Upgrade'}
            iconLeft={
              <IconUpgrade
                color={Colors.white}
                size={22}
              />
            }
            onPress={() => {
              if (user?.type === EUserType.MODELE) {
                history(NameRoutes.UpgradeModeleShowScreen);
              }
              else {
                history(NameRoutes.UpgradeUserShowScreen);
              }
            }}
          />



          <ButtonAction
            height={Sizes.buttonHeight}
            title={'Ver Perfil [Servidor]'}
            iconLeft={
              <IconUser
                color={Colors.white}
                size={22}
              />
            }
            onPress={() => {
              // history(NameRoutes.UpgradeUserScreen);
            }}
          />

        </DashboardClientButtonsContent>

      </DashboardClientBottomRow>

    </DashboardClientMembership>

  );
};



export default InfoPlan;
