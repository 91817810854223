import styled,
{
  css,
} from 'styled-components';

import {
  Card,
} from '../../../components/Composh/web';

import InputBoolean from '../../../components/Inputs/InputBoolean';
import InputSelect from '../../../components/Inputs/InputSelect';
import InputText from '../../../components/Inputs/InputText';

import {
  Sizes,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';



export const CardCharsForm = styled(Card.View)`
  padding-top: ${Sizes.paddingContainer}px;
  padding-left: ${Sizes.paddingFlatList}px;
  padding-right: ${Sizes.paddingFlatList}px;
  padding-bottom: ${Sizes.paddingContainer}px;
`;

export const CharsInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const CharsInputsFirstContainer = styled(CharsInputsContainer)`
  margin-top: 0;
`;

export const CharsInputsLastContainer = styled(CharsInputsContainer)`
  margin-bottom: 0;
`;


export const CharsInputsContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 5px;
`;

export const InputResponse = css`
  @media ${PlatformDevices.maxMobileM} {
    width: 100%;
  }

  @media ${PlatformDevices.minMobileM} {
    width: calc(50% - 10px);
  }

  @media ${PlatformDevices.minTablet} {
    width: calc(calc(100% / 3) - 10px);
  }

  @media ${PlatformDevices.minTabletM} {
    width: calc(25% - 10px);
  }
`;

export const InputStyle = css`
  margin-left: 5px;
  margin-right: 5px;
`;

export const InputBooleanStyle = css`
  width: calc(15% - 10px); 
  margin-left: 5px;
  margin-right: 5px;
`;

export const InputTextAreaStyled = styled(InputText)`
  min-width: 100%;
`;

export const InputTextStyled = styled(InputText)`
  ${InputStyle};
  ${InputResponse};
`;

export const InputSelectStyled = styled(InputSelect)`
  ${InputStyle};
  ${InputResponse};
`;

export const InputBooleanStyled = styled(InputBoolean)`
  ${InputBooleanStyle};
  ${InputResponse};
`;
