export const reviewMediasArray = [
  {
    id: 1,
    label: 'Sim, elas estão',
    value: 'PHOTOS_OK',
  },
  {
    id: 2,
    label: 'Sim, mas estão desatualizados',
    value: 'PHOTOS_OUTDATED',
  },
  {
    id: 3,
    label: 'As fotos são fortemente editadas',
    value: 'PHOTOS_EDITED',
  },
  {
    id: 4,
    label: 'Não, eles não são',
    value: 'PHOTOS_NOT_OK',
  },
];
