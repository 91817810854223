import React from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useLocation,
} from 'react-router-dom';

import {
  Body,
  Screen,
} from '../../components/Composh/web';

import {
  ButtonBack,
} from '../../components/Controls';

import EmptyContent from '../../components/Empty';
import FooterChoose from '../../components/Footers/FooterChoose';
import HeaderScreen from '../../components/Headers/HeaderScreen';

import {
  openSite,
} from '../../config/linking.config';

import {
  ArrayColors,
} from '../../shared/arrays';

import {
  Colors,
  Images,
} from '../../shared/constants';

import {
  ETokenAsync,
} from '../../shared/enums';

import {
  TranslateConfig,
} from '../../shared/translations';

import {
  getLocalStorageItem,
} from '../../shared/utils/local-storage.utils';

import {
  ButtonText,
} from '../../styles/styled.layout';

import {
  WebViewTitle,
  WebViewSubTitle,
  WebViewComponent,
  WebViewContainer,
} from './styled';



export interface IRouteLocation {
  title: string;
  link: string;
  action?: string;
  paramCustom?: string;
}



const WebViewScreen: React.FC = () => {
  const { t: translate } = useTranslation();

  const routeLocation = useLocation();
  const params = routeLocation?.state as IRouteLocation;

  const token = getLocalStorageItem(ETokenAsync.USER_TOKEN);

  const showLinkView = false;
  const hideHeader = true;

  const title = params?.title || '-';
  const link = params?.link || '-';
  const action = params?.action || null;
  const paramCustom = params?.paramCustom || '';

  const commonParams = `?toolbar=false&token=${token}${paramCustom}`;



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet prioritizeSeoTags>
        <title>
          {`${title} | ${translate(TranslateConfig.NAME_APP)}`}
        </title>

        <meta name="robots" content="noindex, nofollow" />
      </Helmet>



      <HeaderScreen
        disableGender
        hideHeaderFixed={hideHeader}
        backgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            close
            color={Colors.white}
          />
        }
        centerContent={
          <Body>
            <WebViewTitle
              color={Colors.white}>
              {title}
            </WebViewTitle>

            {showLinkView && (
              <WebViewSubTitle
                color={Colors.white}>
                {link}
              </WebViewSubTitle>
            )}
          </Body>
        }
      />



      {(link === '' || link === '-' || link === null || link === undefined) && (
        <EmptyContent
          image={Images.logoIconApp}
          title={translate(TranslateConfig.EMPTY_LINK_SEE)}
        />
      )}



      {(link !== '' && link !== '-' && link !== null && link !== undefined) && (
        <WebViewContainer>
          <WebViewComponent
            src={`${link}${commonParams}`}
          />
        </WebViewContainer>
      )}



      {action && (
        <FooterChoose
          hideCancelButton

          okColor={ArrayColors.arrayOk}
          okPress={() => {
            openSite(link);
          }}
          okLabel={
            <ButtonText
              color={Colors.white}>
              {action}
            </ButtonText>
          }
        />
      )}

    </Screen>

  );
};



export default WebViewScreen;
