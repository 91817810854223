import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';



export interface IProps {
  color?: string;
}



export const WrapperItemTitleView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
`;

export const BeforeTitleView = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 3px;
  height: 100%;
  min-height: 26px;
  border-radius: 4px;
  background-color: ${(props: IProps) => props.color};
`;

export const WrapperItemTitleTextView = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`;

export const WrapperItemTitle = styled.h3`
  color: ${Colors.white};
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
`;

export const WrapperItemSubtitle = styled.h4`
  margin-top: 3px;
  color: #fff9;
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
`;
