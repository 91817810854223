import React from 'react';

import {
  Images,
} from '../../../shared/constants';

import {
  GroupTypeContainer,
  GroupTypeImage,
  GroupInfoRoomView,
  GroupInfoTitle,
  GroupInfoText,
  GroupAmountView,
  GroupAmountTitle,
  GroupAmountText,
} from './styled';



const TermometerGroup: React.FC = () => {
  return (

    <GroupTypeContainer>

      <GroupTypeImage
        alt={''}
        src={Images.iconChatGroup}
      />



      <GroupInfoRoomView>

        <GroupInfoTitle>
          {'O que vou fazer na minha sala de chat em grupo:'.toUpperCase()}
        </GroupInfoTitle>

        <GroupInfoText>
          Mostrar o que quiserem
        </GroupInfoText>

      </GroupInfoRoomView>



      <GroupAmountView>

        <GroupAmountTitle>
          Créditos recebidos
        </GroupAmountTitle>

        <GroupAmountText>
          500 de 1700
        </GroupAmountText>

      </GroupAmountView>



      <GroupAmountView>

        <GroupAmountTitle>
          Pessoas para a sala
        </GroupAmountTitle>

        <GroupAmountText>
          15
        </GroupAmountText>

      </GroupAmountView>

    </GroupTypeContainer>

  );
};



export default TermometerGroup;
