import styled,
{
  css,
} from 'styled-components';



// Header

export const YearMediaTypeContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const YearlyContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
`;

export const FotyCountdownImage = styled.img`
  width: 100%;
  max-width: 100%;
`;



// Divider

export const FotyDividerImage = styled.img`
  width: 100%;
  height: 12px;
`;



// Presentation

export const FotyCountdownSection = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  width: 100%;
  height: 460px;
`;

export const FotyCountdownBackground = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

export const FotyCountdownContent = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 42px;
  padding-bottom: 50px;
  z-index: 1;
`;

export const FotyCountdownVideo = styled.video`
  aspect-ratio: 16/9;
  width: 650px;
`;

export const FotyCountdownInfoView = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 700px;
  margin-left: 3rem;
`;

export const FotyCountInfoTitle = styled.h3`
  margin-top: 10px;
  margin-bottom: 20px;
  color: #eee;
  font-size: calc(34px - .2vw);
  font-weight: 700;
  line-height: 1.3;
`;

export const FotyCountInfoText = styled.p`
  margin-top: 1.3rem;
  color: #fff;
  line-height: 1.8rem;
  font-size: 1.3rem;
`;



// Modeles from year

export const YearModeleFromWinView = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 40px;
  background-color: #0a0c21;
`;

export const YearWinContestView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  margin-bottom: 15px;
`;

export const YearWinContestBadgeView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 25%;
`;

export const YearWinContestBadgeImage = styled.img`
  width: 220px;
`;

export const YearWinContestTextView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
`;

export const YearWinContestTitle = styled.h3`
  margin-bottom: 15px;
  color: #fff;
  font-family: parkside, cursive;
  line-height: 1.5;
  font-size: calc(55px - .1vw);
  font-style: normal;
  font-weight: 700;
`;

export const YearWinContestText = styled.p`
  color: #fff;
  font-size: 19px;
  line-height: 1.23;
  text-align: center;
`;

export const YearWinPrizeAmountTitle = styled.h4`
  margin: 0;
  color: #fff;
  font-size: calc(14px + .3vw);
  font-weight: 700;
`;

export const YearWinPrizeAmountText = styled.p`
  margin-top: 5px;
  color: #98db7e;
  font-size: 35px;
  font-weight: 700;
`;


export const YearWinContestGridView = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 5px;
`;



// Informations Views

export const YearCreditStyle = css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 400px;
`;

export const YearCreditLevelView = styled.div`
  ${YearCreditStyle};
  background: linear-gradient(0deg, #1f59b3 0%, #080e30 100%);
`;

export const YearCreditBadgeView = styled.div`
  ${YearCreditStyle};
  background: linear-gradient(0deg, #250f34 0%, #140921 72%);
`;

export const YearCreditWorldView = styled.div`
  ${YearCreditStyle};
  background: linear-gradient(180deg, #0e101c 0%, #005761 100%);
`;

export const YearCreditPersonView = styled.div`
  ${YearCreditStyle};
  background: radial-gradient(ellipse at center bottom, #c41010 0%, transparent 67%);
`;

export const YearCreditAutoView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
`;

export const YearCreditRelativeView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 30px;
  margin-right: 30px;
  width: 35%;
  height: 100%;
`;

export const YearCreditRelativeTitle = styled.h3`
  margin-bottom: 25px;
  color: #fff;
  font-family: parkside, cursive;
  font-size: calc(55px - .1vw);
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
`;

export const YearCreditRelativeText = styled.p`
  color: #fff;
  font-size: calc(19px - .1vw);
  line-height: 1.5;
  text-align: center;
`;

export const YearCreditRelativeImage = styled.img`
  height: 95%;
`;
