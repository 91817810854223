import React from 'react';

import {
  Content,
  Screen,
  Title,
} from '../../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../../components/Controls';

import FooterApp from '../../../../components/Footers/FooterApp';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';

import {
  Colors,
} from '../../../../shared/constants';



const ModeleLiveOpenScreen: React.FC = () => {
  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        disableGender
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            color={Colors.white}
            size={27}
          />
        }
        centerContent={
          <Title
            color={Colors.white}>
            Minha sala ao vivo
          </Title>
        }
      />



      <Content>

        <p>
          OpenLiveScreen
        </p>

        <FooterApp />

      </Content>

    </Screen>

  );
};



export default ModeleLiveOpenScreen;
