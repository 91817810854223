import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

import CardAdsSuggestion from '../../components/Ads';
import CardNotifications from '../../components/Cards/CardNotifications';
import ChipApp from '../../components/Chip';

import {
  Content,
  Screen,
  Title,
} from '../../components/Composh/web';

import {
  ButtonDrawer,
} from '../../components/Controls';

import EmptyContent from '../../components/Empty';
import FooterApp from '../../components/Footers/FooterApp';
import HeaderScreen from '../../components/Headers/HeaderScreen';

import {
  IconCheck,
} from '../../components/Icons';

import {
  APP_MENU_SELECTED_ACTION,
} from '../../redux/reducers/app-modals.store';

import {
  Colors,
  Images,
} from '../../shared/constants';

import EDrawerMenu from '../../shared/enums/App/drawer-menu.enum';

import {
  MaskNotificationsBadge,
} from '../../shared/utils/masks.utils';

import {
  NotificationBody,
  ContentCenterFlex,
  ContentWrapperRowFull,
  NotificationsControlsContent,
  MediaChooseTypeHeader,
  NotificationBadgeContainer,
  NotificationReadButton,
  NotificationReadIcon,
  NotificationReadText,
  NotificationFlatListStyled,
} from './styled';



const NotificationsScreen: React.FC = () => {
  const dispatch = useDispatch();


  const [typeImage, setTypeImage] = useState('ALL');


  const accentGender = Colors.primaryDark;

  const notificationArray = [
    {
      user: 'Garoto Novo',
      username: 'garotonovo',
      date: '22/10/2022',
      type: '',
      description: 'Foto para um novo dia',
      action: '',
    },
    {
      user: 'Garoto Talentoso',
      username: 'talentoso',
      date: '22/10/2022',
      type: '',
      description: 'Video para te deixar maluco',
      action: '',
    },
    {
      user: 'Secretio',
      username: 'secretio',
      date: '22/10/2022',
      type: '',
      description: 'Acesse Agora',
      action: '',
    },
    {
      user: 'Menina má',
      username: 'meninama',
      date: '22/10/2022',
      type: '',
      description: 'Um novo video premium pra vocês',
      action: '',
    },
  ];


  // Publicou um video
  // Publicou uma foto
  // Publicou um video premium
  // Publicou uma foto premium
  // Desbloqueou uma nova conquista



  function renderItem(item: any, index: number) {
    return (

      <CardNotifications
        key={index}
        title={item?.user}
        username={item?.username}
        date={item?.date}
        description={item?.description}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noImage}
        title={'Não há itens ainda!'}
      />

    );
  }



  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.NOTIFICATIONS });
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        disableGender
        showBalance
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonDrawer
            color={Colors.white}
            size={27}
          />
        }
        centerContent={
          <NotificationBody>
            <Title
              color={Colors.white}>
              Notificações
            </Title>

            {notificationArray.length > 0 && (
              <NotificationBadgeContainer>
                {MaskNotificationsBadge(notificationArray.length)}
              </NotificationBadgeContainer>
            )}
          </NotificationBody>
        }
      />



      <Content>

        <ContentWrapperRowFull>

          <ContentCenterFlex>

            <NotificationsControlsContent>

              <MediaChooseTypeHeader>

                <ChipApp
                  hideIcon
                  title={'Tudo'}
                  selected={typeImage === 'ALL'}
                  selectedBackgroundColor={accentGender}
                  onPress={() => {
                    setTypeImage('ALL');
                  }}
                />


                <ChipApp
                  hideIcon
                  title={'Comentários'}
                  selected={typeImage === 'COMMENTS'}
                  selectedBackgroundColor={accentGender}
                  onPress={() => {
                    setTypeImage('COMMENTS');
                  }}
                />


                <ChipApp
                  hideIcon
                  title={'Menções'}
                  selected={typeImage === 'MENTIONS'}
                  selectedBackgroundColor={accentGender}
                  onPress={() => {
                    setTypeImage('MENTIONS');
                  }}
                />


                <ChipApp
                  hideIcon
                  title={'Assinaturas'} /// Billing
                  selected={typeImage === 'SIGNATURES'}
                  selectedBackgroundColor={accentGender}
                  onPress={() => {
                    setTypeImage('SIGNATURES');
                  }}
                />


                <ChipApp
                  hideIcon
                  title={'Promoções'}
                  selected={typeImage === 'PROMOTIONS'}
                  selectedBackgroundColor={accentGender}
                  onPress={() => {
                    setTypeImage('PROMOTIONS');
                  }}
                />

              </MediaChooseTypeHeader>



              <NotificationReadButton>

                <NotificationReadIcon>
                  <IconCheck
                    size={20}
                    color={Colors.white}
                  />
                </NotificationReadIcon>


                <NotificationReadText>
                  Marcar todos como lido
                </NotificationReadText>

              </NotificationReadButton>

            </NotificationsControlsContent>



            <NotificationFlatListStyled
              noScroll
              data={notificationArray}
              renderItem={renderItem}
              listEmptyComponent={listEmptyComponent()}
            />

          </ContentCenterFlex>



          <CardAdsSuggestion />

        </ContentWrapperRowFull>



        <FooterApp />

      </Content>

    </Screen>

  );
};



export default NotificationsScreen;
