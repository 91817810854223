import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
} from 'react-redux';

import {
  useNavigate,
  useLocation,
} from 'react-router-dom';

import {
  GetGlobalAppParams,
} from '../../apis/endpoints/params-global.endpoint';

import {
  Screen,
} from '../../components/Composh/web';

import {
  getSystemCurrentLanguage,
  setCurrentLanguage,
} from '../../config/language.config';

import NameRoutes from '../../navigation/names';

import {
  PROJECT_CONFIGS_MODULES_SET,
} from '../../redux/reducers/app-configs.store';

import {
  APP_DRAWER_ACTION,
  MODAL_LOCATION_APP_ACTION,
  USER_BRAND_PROMOTER_MODAL,
  USER_GENDER_MODAL,
  USER_SEND_GIFT_MODAL,
} from '../../redux/reducers/app-modals.store';

import {
  USER_GENDER_CHOOSE_ACTION,
} from '../../redux/reducers/choose.store';

import {
  APP_ALL_LOCALITIES,
  USER_LANGUAGE,
  USER_LOCATION,
} from '../../redux/reducers/localities.store';

import {
  MODELES_HIGHLIGHTS,
  MODELES_RENT_SERVICES,
} from '../../redux/reducers/modeles.store';

import {
  USER_LOGGED,
  USER_LOGOUT_STATE,
  USER_SET,
} from '../../redux/reducers/user.store';

import {
  arrayModelesHighlights,
} from '../../shared/arrays/Gender/app-gender-choose..array';

import {
  Colors,
  Images,
} from '../../shared/constants';

import {
  WorldPlaceResponse,
} from '../../shared/service/address.service';

import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '../../shared/utils/local-storage.utils';

import {
  AbsoluteFillView,
  ContainerGlue,
  BackgroundColorView,
  FlexContent,
  // ImageBackgroundStyle,
  LogoStyle,
  LogoImageStyle,
} from './styled';



export interface IProps {
  children?: any;
}



const AnimatedSplash: React.FC<IProps> = (props: any) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { i18n } = useTranslation();

  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);

  const [showSecret, setShowSecret] = useState(false);

  const [animationDone, setAnimationDone] = useState(false);

  const [isLoaded, setIsLoaded] = useState(false);

  const logoWidth = 120;
  const logoHeight = 120;
  const backgroundColor = Colors.primaryDark;
  const logoImage = Images.logoIconApp;
  // const imageBackgroundSource = Images.backgroundSplashBubble;



  function clearValues(): void {
    try {
      dispatch({ type: USER_LOGGED, payload: false });
      dispatch({ type: USER_LOGOUT_STATE, payload: false });
      dispatch({ type: USER_SET, payload: {} });
    }
    catch (error: any) {
      console.error(error);
    }
  }


  function appProjectParamsValues(dataParams: any) {
    if (!dataParams?.canAccess) {
      clearValues();
    }

    dispatch({ type: PROJECT_CONFIGS_MODULES_SET, payload: dataParams });

    // Verificar se nenhum dos parâmetros está presente
    setShowSecret((prevShowSecret) => {
      const shouldShowSecret = !dataParams?.showRent && !dataParams?.showLive && !dataParams?.showFanClub;
      if (prevShowSecret !== shouldShowSecret) {
        return shouldShowSecret;
      }
      return prevShowSecret;
    });
  }


  async function loadLocalities() {
    const response = WorldPlaceResponse();
    const responseData = response?.data;

    dispatch({ type: APP_ALL_LOCALITIES, payload: responseData });
  }


  async function loadGlobalParams() {
    try {
      const response = await GetGlobalAppParams();

      if (!response?.data) {
        appProjectParamsValues(null);

        dispatch({ type: MODELES_RENT_SERVICES, payload: [] });
        dispatch({ type: MODELES_HIGHLIGHTS, payload: arrayModelesHighlights });
      }

      appProjectParamsValues(response?.data?.configs?.modules);


      dispatch({ type: MODELES_RENT_SERVICES, payload: response?.data?.configs?.rentServices });


      // Gêneros destaques
      const gendersArray = response?.data?.genders;
      if (!Array.isArray(gendersArray) || gendersArray?.length <= 0) {
        dispatch({ type: MODELES_HIGHLIGHTS, payload: arrayModelesHighlights });
        return;
      }
      dispatch({ type: MODELES_HIGHLIGHTS, payload: gendersArray });
    }
    catch (error: any) {
      console.error(error);
      appProjectParamsValues(null);

      dispatch({ type: MODELES_HIGHLIGHTS, payload: arrayModelesHighlights });
    }
  };


  function renderChildren() {
    // if (props.preload || props.preload === null) {
    //   return props.children;
    // }
    // else {
    if (isLoaded) {
      return props.children;
    }
    // }

    return null;
  }


  function initializeLanguage() {
    const systemLang = getSystemCurrentLanguage();
    setCurrentLanguage(systemLang);
    i18n.changeLanguage(systemLang);
    dispatch({ type: USER_LANGUAGE, payload: systemLang });
  }


  function setLocationFunc(location: any) {
    setLocalStorageItem(USER_LOCATION, location);
    dispatch({ type: USER_LOCATION, payload: location });
  }


  function initializeLocation() {
    const cityParams = queryParams?.get('city');
    const stateParams = queryParams?.get('state');
    const countryParams = queryParams?.get('country');

    if (cityParams && stateParams && countryParams) {
      const paramsLocationPayload = {
        city: cityParams,
        state: stateParams,
        country: countryParams,
      };

      setLocationFunc(paramsLocationPayload);
      return;
    }


    const getLocationSave = getLocalStorageItem(USER_LOCATION);
    const citySave = getLocationSave?.city;
    const stateSave = getLocationSave?.state;
    const countrySave = getLocationSave?.country;

    if (citySave && stateSave && countrySave) {
      const storageLocationPayload = {
        city: citySave,
        state: stateSave,
        country: countrySave,
      };

      setLocationFunc(storageLocationPayload);
      return;
    }


    const locationPayload = {
      city: 'SAO_PAULO',
      state: 'SP',
      country: 'BRA',
    };

    setLocationFunc(locationPayload);
  }


  function setGenderFunc(gender: string) {
    setLocalStorageItem(USER_GENDER_CHOOSE_ACTION, gender);
    dispatch({ type: USER_GENDER_CHOOSE_ACTION, payload: gender });
  }


  function initializeGender() {
    const gender = queryParams?.get('gender');

    if (gender) {
      setGenderFunc(gender);
      return;
    }


    const getLocalGender = getLocalStorageItem(USER_GENDER_CHOOSE_ACTION);

    if (getLocalGender) {
      setGenderFunc(getLocalGender);
      return;
    }


    const firstGender = arrayModelesHighlights[0]?.gender;
    setGenderFunc(firstGender);
  }


  function resetModalStates() {
    dispatch({ type: APP_DRAWER_ACTION, payload: false });

    dispatch({ type: USER_SEND_GIFT_MODAL, payload: false });
    dispatch({ type: USER_BRAND_PROMOTER_MODAL, payload: false });
    dispatch({ type: MODAL_LOCATION_APP_ACTION, payload: false });
    dispatch({ type: USER_GENDER_MODAL, payload: false });
  }



  useEffect(() => {
    // console.log(getCurrentLanguage());

    if (!isLoaded) {
      loadLocalities();
      loadGlobalParams();

      initializeLanguage();
      initializeGender();
      initializeLocation();
      resetModalStates();
    }


    setTimeout(async () => {
      setIsLoaded(true);
    }, 2000);


    if (isLoaded) {
      setAnimationDone(true);

      if (showSecret) {
        history(NameRoutes.AppDownScreen, { replace: true });
      }
    }
  }, [isLoaded, showSecret]);



  return (

    <Screen
      backgroundColor={Colors.transparent}>


      {!animationDone && (
        <AbsoluteFillView />
      )}


      <ContainerGlue>

        {!animationDone && (
          <BackgroundColorView
            backgroundColor={backgroundColor}
          // style={[
          //   logoOpacity,
          // ]}
          />
        )}


        <FlexContent
        // style={[
        //   !isLoaded && appScale,
        //   opacityClearToVisible,
        // ]}
        >
          {renderChildren()}
        </FlexContent>



        {/* {!animationDone && (
          <ImageBackgroundStyle
            alt={'imageBackground'}
            src={imageBackgroundSource}
            tintColor={tintBlackColor()}
          // style={[
          //   imageScale,
          //   logoOpacity,
          //   {
          //     width,
          //     height,
          //     tintColor: backgroundColor,
          //   },
          // ]}
          />
        )} */}


        {!animationDone && (
          <LogoStyle>
            <LogoImageStyle
              alt={'logo'}
              src={logoImage}
              width={logoWidth}
              height={logoHeight}
            // style={[
            //   logoScale,
            //   logoOpacity,
            // ]}
            />
          </LogoStyle>
        )}

      </ContainerGlue>

    </Screen>

  );
};



export default AnimatedSplash;
