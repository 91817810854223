enum EGenderUser {
  WOMAN = 'WOMAN',
  MAN = 'MAN',
  TRANS_WOMAN = 'TRANS_WOMAN',
  TRANS_MAN = 'TRANS_MAN',
  COUPLE = 'COUPLE',
  GROUP = 'GROUP',
  USER_LIKE_COMPANY = 'USER_LIKE_COMPANY',
}



export default EGenderUser;
