import {
  Images,
} from '../../constants';



const TipGiftsArray = [
  {
    tip: 5,
    image: Images.gift5,
  },
  {
    tip: 10,
    image: Images.gift10,
  },
  {
    tip: 15,
    image: Images.gift15,
  },
  {
    tip: 20,
    image: Images.gift20,
  },
  {
    tip: 25,
    image: Images.gift25,
  },
  {
    tip: 30,
    image: Images.gift30,
  },
  {
    tip: 35,
    image: Images.gift35,
  },
  {
    tip: 40,
    image: Images.gift40,
  },
  {
    tip: 45,
    image: Images.gift45,
  },
  {
    tip: 50,
    image: Images.gift50,
  },
  {
    tip: 55,
    image: Images.gift55,
  },
  {
    tip: 60,
    image: Images.gift60,
  },
  {
    tip: 65,
    image: Images.gift65,
  },
  {
    tip: 70,
    image: Images.gift70,
  },
  {
    tip: 75,
    image: Images.gift75,
  },
  {
    tip: 80,
    image: Images.gift80,
  },
  {
    tip: 85,
    image: Images.gift85,
  },
  {
    tip: 90,
    image: Images.gift90,
  },
  {
    tip: 95,
    image: Images.gift95,
  },
  {
    tip: 100,
    image: Images.gift100,
  },
  {
    tip: 150,
    image: Images.gift150,
  },
  {
    tip: 200,
    image: Images.gift200,
  },
  {
    tip: 250,
    image: Images.gift250,
  },
  {
    tip: 300,
    image: Images.gift300,
  },
];



export default TipGiftsArray;
