export enum EPaymentType {
  CASH = 'CASH',
  CHEQUE = 'CHEQUE',
  PIX = 'PIX',

  DEBIT_ELO = 'DEBIT_ELO',
  DEBIT_MASTERCARD = 'DEBIT_MASTERCARD',
  DEBIT_VISA = 'DEBIT_VISA',

  CREDIT_AMERICAN = 'CREDIT_AMERICAN',
  CREDIT_DINERS = 'CREDIT_DINERS',
  CREDIT_ELO = 'CREDIT_ELO',
  CREDIT_MASTERCARD = 'CREDIT_MASTERCARD',
  CREDIT_VISA = 'CREDIT_VISA',
}



export default EPaymentType;
