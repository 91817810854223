import React from 'react';

import {
  EGenderUser,
} from '../../../shared/enums';

import {
  IconItemMan,
  IconItemTransMan,
  IconItemTransWoman,
  IconItemWoman,
  IconLogoSecretio,
} from '../../Icons';



export interface IProps {
  genre: string;
  size: number;
  color: string;
}



const UserGenre: React.FC<IProps> = (props) => {
  const colorIconGenre = props.color;
  const sizeIconGenre = props.size;


  switch (props.genre) {
    case EGenderUser.MAN:
      return (
        <IconItemMan
          color={colorIconGenre}
          size={sizeIconGenre}
        />
      );

    case EGenderUser.WOMAN:
      return (
        <IconItemWoman
          color={colorIconGenre}
          size={sizeIconGenre}
        />
      );

    case EGenderUser.TRANS_MAN:
      return (
        <IconItemTransMan
          color={colorIconGenre}
          size={sizeIconGenre}
        />
      );

    case EGenderUser.TRANS_WOMAN:
      return (
        <IconItemTransWoman
          color={colorIconGenre}
          size={sizeIconGenre}
        />
      );

    default:
      return (
        <IconLogoSecretio
          color={colorIconGenre}
          size={sizeIconGenre}
        />
      );
  }
};



export default UserGenre;
