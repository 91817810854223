import React from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  RootState,
} from '../../../redux';

import {
  USER_BRAND_PROMOTER_MODAL,
  USER_VERIFIED_MODAL,
} from '../../../redux/reducers/app-modals.store';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import {
  validateString,
} from '../../../shared/utils/string.utils';

import UserAvatar from '../../Contents/UserAvatar';

import {
  IconVerified,
  IconVerifiedPromoter,
} from '../../Icons';

import {
  HomeIdentiyContent,
  HomeIdentiyBanner,
  HomeIdentiyImageBanner,
  HomeIdentiyOVerlay,
  HomeIdentiyuserButton,
  HomeIdentiyAvatarImage,
  HomeIdentiyAvatarInfoView,
  HomeIdentiyViewUsername,
  HomeIdentiyUsername,
  HomeIdentiyIcon,
  HomeIdentiyFullName,
  HomeIdentiyDescription,
} from './styled';



const CardHomeInfoUser: React.FC = () => {
  const dispatch = useDispatch();


  const user = useSelector((state: RootState) => state.user.actualUser);

  const userImage = validateString(user?.picture) || Images.avatarUserImage;
  const usercover = validateString(user?.pictureCover) || Images.avatarUserImage;



  return (

    <HomeIdentiyContent>

      <HomeIdentiyBanner>
        <HomeIdentiyImageBanner
          src={usercover}
        />

        <HomeIdentiyOVerlay />
      </HomeIdentiyBanner>



      <HomeIdentiyuserButton>

        <HomeIdentiyAvatarImage>
          <UserAvatar
            width={90}
            image={userImage}
            plan={user?.plan?.planCurrent}
          />
        </HomeIdentiyAvatarImage>



        <HomeIdentiyAvatarInfoView>

          <HomeIdentiyViewUsername>

            <HomeIdentiyUsername>
              @{user?.useralias || '-'}
            </HomeIdentiyUsername>


            {user?.verified && (
              <HomeIdentiyIcon
                onClick={() => {
                  dispatch({ type: USER_VERIFIED_MODAL, payload: true });
                }}>
                <IconVerified
                  size={13}
                  color={Colors.accent}
                />
              </HomeIdentiyIcon>
            )}


            {user?.brandPromoter && (
              <HomeIdentiyIcon
                onClick={() => {
                  dispatch({ type: USER_BRAND_PROMOTER_MODAL, payload: true });
                }}>
                <IconVerifiedPromoter
                  size={13}
                />
              </HomeIdentiyIcon>
            )}

          </HomeIdentiyViewUsername>



          <HomeIdentiyFullName>
            {user?.alias || '-'}
          </HomeIdentiyFullName>

        </HomeIdentiyAvatarInfoView>

      </HomeIdentiyuserButton>



      {user?.appAbout?.aboutMe && (
        <HomeIdentiyDescription>
          {user?.appAbout?.aboutMe}
        </HomeIdentiyDescription>
      )}

    </HomeIdentiyContent>

  );
};



export default CardHomeInfoUser;
