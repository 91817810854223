import React from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  RootState,
} from '../../../redux';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import {
  HeaderUserOpenActionButton,
  HeaderUserOpenContent,
  HeaderUserOpenActionIcon,
  HeaderUserOpenActionText,
  HeaderUserFanclubText,
  HeaderUserFanclubImage,
  HeaderDisabledFanclubText,
} from './styled';



export interface IProps {
  type: 'ACTION' | 'FAN_CLUB';
  disabled?: boolean;
  icon?: any;
  title?: string;
  onPress?: any;
}



const ButtonDoModules: React.FC<IProps> = (props: IProps) => {
  const genderColorChoose = useSelector((state: RootState) => state.choose.genderColorApp);



  return (

    <HeaderUserOpenActionButton
      disabled={props.disabled}
      backgroundColor={!props.disabled ? genderColorChoose : Colors.disabled}
      onClick={() => {
        if (!props.disabled && props.onPress) {
          props.onPress();
        }
      }}>

      {props.type === 'ACTION' && (
        <HeaderUserOpenContent>

          <HeaderUserOpenActionIcon>
            {props.icon}
          </HeaderUserOpenActionIcon>


          <HeaderUserOpenActionText>
            {props.title}
          </HeaderUserOpenActionText>

        </HeaderUserOpenContent>
      )}



      {props.type === 'FAN_CLUB' && (
        <HeaderUserOpenContent>

          {!props.disabled && (
            <HeaderUserFanclubText>
              Assinar
            </HeaderUserFanclubText>
          )}


          <HeaderUserFanclubImage
            src={Images.fanclubLogo}
          />


          {props.disabled && (
            <HeaderDisabledFanclubText>
              Indisponível
            </HeaderDisabledFanclubText>
          )}

        </HeaderUserOpenContent>
      )}

    </HeaderUserOpenActionButton>

  );
};



export default ButtonDoModules;
