import SecretioWS from '../index';



export const GetModeleReviews = async (idModele: string) => {
  const url = `/app/modele/review/${idModele}`;

  const response = await SecretioWS.get(url);
  return response.data;
};



export const UpdateAutoVisibleComments = async (useralias: string, payload: any) => {
  const url = `/app/modele/review/auto-visible-comments/${useralias}`;

  const response = await SecretioWS.put(url, payload);
  return response.data;
};



export const ChangeVisibleReview = async (idComment: number) => {
  const url = `/app/modele/review/visible/${idComment}`;

  const response = await SecretioWS.put(url);
  return response.data;
};



export const ModeleRespondReview = async (idComment: number, payload: any) => {
  const url = `/app/modele/review/respond/${idComment}`;

  const response = await SecretioWS.put(url, payload);
  return response.data;
};
