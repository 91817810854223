import styled from 'styled-components';

import {
  Colors,
} from '../shared/constants';



export const FooterStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
`;

export const Footer = styled(FooterStyle)`
  height: 58px;
`;

export const FooterMini = styled(FooterStyle)`
  height: 35px;
  min-height: 35px;
`;



export const FooterDescriptionStyle = styled.p`
  color: ${Colors.textSubDescription};
  font-style: italic;
  font-size: 12px;
`;

export const FooterDescription = styled(FooterDescriptionStyle)`
  margin-left: 10px;
  margin-bottom: 15px;
  text-align: right;
`;
