import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import io from 'socket.io-client';

import {
  getBasicInfo,
  getMessages,
  linkedUsers,
  sendMessage,
} from '../../apis/endpoints/messages.endpoints';

import InputChat from '../../components/Chat/InputChat';

import {
  Container,
  Title,
  Screen,
} from '../../components/Composh/web';

import {
  ButtonDrawer,
} from '../../components/Controls';

import EmptyContent from '../../components/Empty';
import HeaderScreen from '../../components/Headers/HeaderScreen';

import {
  IconAdd,
  IconBack,
  IconBlock,
  IconClose,
  IconDelete,
  IconSearch,
  IconUser,
} from '../../components/Icons';

import Input from '../../components/Inputs/InputText';
import MessageUser from '../../components/Messages/MessageUser';
import ProgressLoader from '../../components/ProgressLoader';

import {
  RootState,
} from '../../redux';

import { APP_MODAL_LOADING_ACTION } from '../../redux/reducers/app-modals.store';

import {
  Colors,
  Images,
} from '../../shared/constants';

import AppEnvironments from '../../shared/environments/application';

import { TranslateConfig } from '../../shared/translations';
import {
  MessageContentWrapper,
  MessageLeftContent,
  MessageHeaderLeftContent,
  MessageHeaderQuantityText,
  MessageHeaderSearchButton,
  MessageRightContent,
  FirstSayChatContainer,
  FirstChatContent,
  FirstChatTitle,
  CircleMessageContent,
  CircleMessageIcon,
  CircleMessageText,
  UserChatHeaderContent,
  UserChatHeaderInfoContent,
  UserChatHeaderImage,
  ChatInnerInfoContent,
  ChatInnerUsernameButton,
  ChatInnerUsernameText,
  ChatInnerActivityText,
  ChatInnerActionButton,
  ChatInnerActionIcon,
  ChatInnerActionText,
  UserChatMessagesContent,
} from './styled';

const socket = io(AppEnvironments().apiUrl);



const MessagesScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);


  const [isConnected, setIsConnected] = useState(socket.connected);
  const [users, setUsers] = useState([]);
  const [messagesState, setMessagesState] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedUserBasic, setSelectedUserBasic] = useState<any>({});

  const [text, setText] = useState('');


  function onSocketAppCall() {
    socket.on('connect', () => {
      setIsConnected(true);
    });

    socket.on('disconnect', () => {
      setIsConnected(false);
    });

    socket.on(
      user._id, (data) => {
        const { from, messages } = data;
        if (from === selectedUserBasic?._id) {
          const tmp = { senderId: from, text: messages, isRead: false };
          const tmp2 = [...messagesState, tmp];
          setMessagesState(tmp2);
        }
      });
    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off(user._id);
    };
  }


  const pressEnter = (event) => {
    if (event.keyCode === 13) {
      sendMessage(selectedUser, text);
      socket.emit('messages', { from: user._id, to: selectedUserBasic._id, messages: text });
      const tmp = { senderId: user._id, text, isRead: false };
      const tmp2 = [...messagesState, tmp];
      setMessagesState(tmp2);
      setText('');
      event.preventDefault();
    }
  };


  const getMessageByUsername = (userName) => {
    getMessages(userName).then((data) => {
      const dataArray: any = Object.values(data);
      setMessagesState([...dataArray[0]]);
    });
    getBasicInfo({ userName: selectedUser }).then((data) => {
      setSelectedUserBasic(data);
    });
  };



  // useEffect(() => {
  //   dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });
  //   linkedUsers().then((data) => {
  //     const dataArray = Object.values(data);
  //     setUsers(dataArray);
  //     dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
  //   });
  // }, [dispatch]);


  // useEffect(() => {
  //   if (selectedUser.length !== 0) {
  //     getMessageByUsername(selectedUser);
  //   }
  //   else {
  //     setSelectedUser('');
  //   }
  // }, [selectedUser]);


  // useEffect(() => {
  //   onSocketAppCall();
  // }, [user, selectedUserBasic, messagesState]);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        disableGender
        showBalance
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonDrawer
            color={Colors.white}
            size={27}
          />
        }
        centerContent={
          <Title>
            {translate(TranslateConfig.CONVERSATIONS)}
          </Title>
        }
      />



      <Container>
        <MessageContentWrapper>

          <MessageLeftContent>

            <MessageHeaderLeftContent>
              {/* <Input
                // icon={false
                //   ? (
                //     <MessageHeaderSearchButton>
                //       <IconBack
                //         color={Colors.white}
                //         size={24}
                //       />
                //     </MessageHeaderSearchButton>
                //   )
                //   : (
                //     <MessageHeaderSearchButton
                //       cursorDefault>
                //       <IconSearch
                //         color={Colors.white}
                //         size={17}
                //       />
                //     </MessageHeaderSearchButton>
                //   )
                // }
                placeholderText={'Pesquisar ou começar uma nova conversa'}
                // title={'Caixa de texto de pesquisa'}
                // right={true && (true
                //   ? (
                //     <MessageHeaderSearchButton>
                //       <IconClose
                //         color={Colors.white}
                //         size={24}
                //       />
                //     </MessageHeaderSearchButton>
                //   )
                //   : (
                //     <MessageHeaderSearchButton
                //       cursorDefault>
                //       <ProgressLoader
                //         color={genderColorChoose}
                //         size={19}
                //       />
                //     </MessageHeaderSearchButton>
                //   )
                // )}
              /> */}


              <MessageHeaderQuantityText>
                55 {translate(TranslateConfig.CONVERSATIONS)}
              </MessageHeaderQuantityText>
            </MessageHeaderLeftContent>


            {false && (
              <FirstSayChatContainer>
                <EmptyContent
                  title={translate(TranslateConfig.EMPTY_CONVERSATIONS_1)}
                />
              </FirstSayChatContainer>
            )}


            {true && (
              <div>
                <MessageUser
                  active
                />

                <MessageUser
                />
              </div>
            )}

          </MessageLeftContent>



          <MessageRightContent>

            {false && (
              <FirstSayChatContainer>
                <FirstChatContent>

                  <FirstChatTitle>
                    {translate(TranslateConfig.EMPTY_CONVERSATIONS_2)}
                  </FirstChatTitle>

                  <CircleMessageContent>
                    <CircleMessageIcon>
                      <IconAdd
                        color={'#000000'}
                        size={26}
                      />
                    </CircleMessageIcon>

                    <CircleMessageText>
                      {translate(TranslateConfig.ACTION_SEE_ALL_MODELES)}
                    </CircleMessageText>
                  </CircleMessageContent>

                </FirstChatContent>
              </FirstSayChatContainer>
            )}


            <UserChatHeaderContent>

              <UserChatHeaderInfoContent>
                <UserChatHeaderImage
                  alt={''}
                  src={Images.avatarUserImage}
                />


                <ChatInnerInfoContent>
                  <ChatInnerUsernameButton
                    onClick={() => {
                      // href="/GueroCDNMXN"
                    }}>
                    <ChatInnerUsernameText>
                      {'GueroCDNMXN'}
                    </ChatInnerUsernameText>
                  </ChatInnerUsernameButton>

                  <ChatInnerActivityText>
                    {'31 min atrás'}
                  </ChatInnerActivityText>
                </ChatInnerInfoContent>
              </UserChatHeaderInfoContent>



              <ChatInnerActionButton>
                <ChatInnerActionIcon>
                  <IconUser
                    color={Colors.white}
                    size={20}
                  />
                </ChatInnerActionIcon>

                <ChatInnerActionText>
                  {translate(TranslateConfig.ACTION_PROFILE_SEE)}
                </ChatInnerActionText>
              </ChatInnerActionButton>



              {/* TODO */}
              {/* <ChatInnerActionButton>
                <ChatInnerActionIcon>
                  <IconSearch
                    color={Colors.white}
                    size={20}
                  />
                </ChatInnerActionIcon>

                <ChatInnerActionText>
                  Buscar na conversa
                </ChatInnerActionText>
              </ChatInnerActionButton> */}



              <ChatInnerActionButton>
                <ChatInnerActionIcon>
                  <IconDelete
                    color={Colors.white}
                    size={19}
                  />
                </ChatInnerActionIcon>

                <ChatInnerActionText>
                  {translate(TranslateConfig.ACTION_CHAT_DELETE)}
                </ChatInnerActionText>
              </ChatInnerActionButton>



              <ChatInnerActionButton>
                <ChatInnerActionIcon>
                  <IconBlock
                    color={Colors.white}
                    size={24}
                  />
                </ChatInnerActionIcon>

                <ChatInnerActionText>
                  {translate(TranslateConfig.ACTION_BLOCK)}
                </ChatInnerActionText>
              </ChatInnerActionButton>

            </UserChatHeaderContent>



            <UserChatMessagesContent>

            </UserChatMessagesContent>



            <InputChat
              backgroundColor={'#202c33'}
            />

          </MessageRightContent>

        </MessageContentWrapper>


      </Container>

    </Screen>

  );
};



export default MessagesScreen;
