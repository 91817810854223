import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';

import {
  Card,
} from '../../Composh/web';



export const CardPromoLiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 4px;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 4px;

  @media ${PlatformDevices.maxMobileL} {
    width: 50%;
    min-width: 50%;
    max-width: 50%;
  }

  @media ${PlatformDevices.minMobileL} {
    width: 25%;
    min-width: 25%;
    max-width: 25%;
  }
  
  @media ${PlatformDevices.minLaptop} {
    width: calc(100% / 5);
    min-width: calc(100% / 5);
    max-width: calc(100% / 5);
  }
  
  @media ${PlatformDevices.minDesktop} {
    width: calc(100% / 6);
    min-width: calc(100% / 6);
    max-width: calc(100% / 6);
  }
`;

export const PromoCardContainer = styled(Card.View)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;


export const PromoCardViewImage = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  border-radius: ${Sizes.cardRadius}px;
`;

export const PromoCardImage = styled.img`
  width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
`;


export const PromoCardInfoContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
`;

export const PromoCardInfoView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 12px;
  margin-bottom: 10px;
`;

export const PromoCardDate = styled.h3`
  margin-bottom: 6px;
  color: ${Colors.white};
  font-size: 15.5px;
  font-weight: bold;
`;

export const PromoCardHor = styled.p`
  color: ${Colors.textDescription};
  font-size: 13px;
`;

export const PromoCardInfoIcon = styled.a`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;
