import React from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  RootState,
} from '../../redux';

import {
  Colors,
  Sizes,
} from '../../shared/constants';

import {
  TabBar,
} from '../Composh/web';

import {
  ScrollHorizontalStyled,
  TabComposhStyled,
} from './styled';



export interface IProps {
  tabs: Array<any>;
  selected: number;
  height?: number;
  color?: string;
  backgroundColor?: string;
  onPress?: (index: {
    index: number;
    key: string;
  }) => void;
}



const TabBarStyled: React.FC<IProps> = (props: IProps) => {
  const genderColorChoose = useSelector((state: RootState) => state.choose.genderColorApp);
  const accentColor = props.color || genderColorChoose; // Color(genderColorChoose).lighten(0.2).toString();



  return (

    <ScrollHorizontalStyled
      backgroundColor={props.backgroundColor || Colors.transparent}>

      <TabBar
        style={{
          width: '100%',
          maxWidth: Sizes.contentMaxWidth,
        }}
        height={props.height || 46}
        backgroundColor={Colors.transparent}
        // indicatorStyle={tabStyle.indicatorTabStyle}
        selectedIndex={props.selected}
        onSelect={(returnTabProps: { index: number, key: string }) => {
          if (props.onPress) {
            props.onPress(returnTabProps);
          }
        }}>

        {props.tabs.map((tab: any, index: number) => {
          if (tab?.visible !== false) {
            return (
              <TabComposhStyled
                left
                key={tab.name}
                icon={tab.icon}
                title={tab.title}
                titleStyle={{
                  color: props.selected === index ? accentColor : Colors.inputCount,
                  fontWeight: props.selected === index ? 'bold' : 'normal',
                  whiteSpace: 'nowrap',
                }}
              />
            );
          }
        })}

      </TabBar>

    </ScrollHorizontalStyled>

  );
};



export default TabBarStyled;
