import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  GetFavoriteModeles,
} from '../../apis/endpoints/users-favorites.endpoints';

import {
  IViewMediaModal,
} from '../../apis/interfaces/Medias/view-media-modal.interface';

import {
  Container,
  Screen,
  Title,
} from '../../components/Composh/web';

import {
  ButtonDrawer,
} from '../../components/Controls';

import HeaderScreen from '../../components/Headers/HeaderScreen';

import {
  IconMediaPhoto,
  IconMediaVideo,
  IconModele,
} from '../../components/Icons';

import TabBarStyled from '../../components/TabBar';

import ViewMediaModal from '../../modals/ViewMedia';

import {
  RootState,
} from '../../redux';

import {
  APP_MENU_SELECTED_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../redux/reducers/app-modals.store';

import {
  FAVORITES_SET,
} from '../../redux/reducers/favorites.store';

import {
  Colors,
} from '../../shared/constants';

import EDrawerMenu from '../../shared/enums/App/drawer-menu.enum';

import {
  EUserFavoritesType,
} from '../../shared/enums/Users/user-favorites.enum';

import {
  TranslateConfig,
} from '../../shared/translations';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../shared/utils/errors.utils';

import TabModelesBookmark from './Tabs/TabModeles';
import TabPhotosBookmark from './Tabs/TabPhotos';
import TabVideosBookmark from './Tabs/TabVideos';



const FavoriteScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const accentGender = Colors.primary;
  const iconTabBar = 23;


  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;


  const [modeleFavArray, setModeleFavArray] = useState<Array<any>>([]);
  const [photosFavArray, setPhotosFavArray] = useState<Array<any>>([]);
  const [videosFavArray, setVideosFavArray] = useState<Array<any>>([]);

  const [stateIndex, setStateIndex] = useState(0);

  const [showMediaView, setShowMediaView] = useState<any>(null);

  const [loading, setLoading] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  const getFavoritesProfilesFunction = async () => {
    try {
      setLoading(true);

      const response = await GetFavoriteModeles(availableUser);
      const responseData = response?.data;

      if (!responseData) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, translate(TranslateConfig.EMPTY_HOME));
        return;
      }

      // Separando os itens de acordo com os tipos
      const modeleArray = [];
      const photosArray = [];
      const videosArray = [];

      if (responseData?.length > 0) {
        responseData?.forEach((item: any) => {
          switch (item?.typeCollection) {
            case EUserFavoritesType.MODELE:
              modeleArray.push(item);
              break;

            case EUserFavoritesType.PHOTOS:
              photosArray.push(item);
              break;

            case EUserFavoritesType.VIDEOS:
              videosArray.push(item);
              break;

            default:
              break;
          }
        });
      }

      setModeleFavArray(modeleArray);
      setPhotosFavArray(photosArray);
      setVideosFavArray(videosArray);

      dispatch({ type: FAVORITES_SET, payload: response?.data || [] });
    }
    catch (error: any) {
      console.error(error);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
    }
  };


  function returnArrayChange(): Array<any> {
    if (showMediaView?.type === 'PHOTOS') {
      return photosFavArray;
    }

    if (showMediaView?.type === 'VIDEOS') {
      return videosFavArray;
    }

    return [];
  }



  const screenTabs = [
    {
      visible: true,
      name: 'MODELES',
      title: translate(TranslateConfig.MODELES),
      icon: (
        <IconModele
          color={stateIndex === 0 ? accentGender : Colors.inputCount}
          size={iconTabBar}
        />
      ),
      component: (
        <TabModelesBookmark
          loading={loading}
          favoriteArray={modeleFavArray}
        />
      ),
    },
    {
      visible: true,
      name: 'PHOTOS',
      title: translate(TranslateConfig.PICTURES),
      icon: (
        <IconMediaPhoto
          color={stateIndex === 1 ? accentGender : Colors.inputCount}
          size={iconTabBar}
        />
      ),
      component: (
        <TabPhotosBookmark
          loading={loading}
          favoriteArray={photosFavArray}
          onShowMedia={(mediaObject: IViewMediaModal) => {
            setShowMediaView(mediaObject);
          }}
        />
      ),
    },
    {
      visible: true,
      name: 'VIDEOS',
      title: translate(TranslateConfig.VIDEOS),
      icon: (
        <IconMediaVideo
          color={stateIndex === 2 ? accentGender : Colors.inputCount}
          size={iconTabBar}
        />
      ),
      component: (
        <TabVideosBookmark
          loading={loading}
          favoriteArray={videosFavArray}
          onShowMedia={(mediaObject: IViewMediaModal) => {
            setShowMediaView(mediaObject);
          }}
        />
      ),
    },
  ];



  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.FAVORITES });
    getFavoritesProfilesFunction();
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet prioritizeSeoTags>
        <title>
          {`${translate(TranslateConfig.COLLECTIONS)} | ${translate(TranslateConfig.NAME_APP)}`}
        </title>

        <meta name="robots" content="noindex, nofollow" />
      </Helmet>



      <Container>

        <HeaderScreen
          disableGender
          showBalance
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={
            <ButtonDrawer
              color={Colors.white}
              size={27}
            />
          }
          centerContent={
            <Title
              color={Colors.white}>
              {translate(TranslateConfig.COLLECTIONS)}
            </Title>
          }
          noSubToolbarPadding>


          <TabBarStyled
            color={accentGender}
            tabs={screenTabs}
            selected={stateIndex}
            onPress={(tab) => {
              setStateIndex(tab?.index);
            }}
          />

        </HeaderScreen>



        {stateIndex === 0 && screenTabs[0].component}

        {stateIndex === 1 && screenTabs[1].component}

        {stateIndex === 2 && screenTabs[2].component}

      </Container>



      <ViewMediaModal
        showModele
        visible={Boolean(showMediaView)}
        indexSelected={showMediaView?.indexSelected}
        data={returnArrayChange()}
        onClose={() => {
          setShowMediaView(null);
        }}
      />

    </Screen>

  );
};



export default FavoriteScreen;
