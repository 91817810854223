import React from 'react';

import {
  useSelector,
} from 'react-redux';

import ButtonAction from '../../../../../components/Buttons/ButtonAction';

import {
  Overlay,
} from '../../../../../components/Composh/web';

import {
  RootState,
} from '../../../../../redux';

import {
  Colors,
} from '../../../../../shared/constants';

import {
  EPlansUser,
} from '../../../../../shared/enums';

import {
  CategoryChooseContainer,
  TravelInfoTitle,
  TravelInfoContainer,
  TravelInfoTextView,
  TravelInfoText,
} from './styled';



export interface IProps {
  visible?: boolean;
  onCancelPress?: any;
}



const HelpTravelModal: React.FC<IProps> = (props: IProps) => {
  const visibleModal = props.visible;
  if (!visibleModal) {
    return null;
  }


  const user = useSelector((state: RootState) => state.user.actualUser);
  const getUserPlan = user?.plan?.planCurrent;


  function renderLinesHelp() {
    let helpTravelArray = [];

    // TODO
    // if (getUserPlan === EPlansUser.NONE) {
    //   helpTravelArray = [
    //     ...helpTravelArray,
    //     'Se Anuncie em até 2 localizaçoes de viagem',
    //   ];
    // }

    // if (getUserPlan !== EPlansUser.NONE) {
    //   helpTravelArray = [
    //     ...helpTravelArray,
    //     'Se Anuncie em várias localizaçoes de viagem',
    //   ];
    // }

    helpTravelArray = [
      ...helpTravelArray,
      'Se tiver nível de detaque ou outro anúncio ativo, esse anúncio também será mostrado na cidade da viagem',
    ];

    return helpTravelArray;
  }


  return (

    <Overlay
      visible={visibleModal}
      showBackground={true}
      overlayColor={Colors.menuOverlay}
      onOverlayPress={props.onCancelPress}>

      <CategoryChooseContainer>

        <TravelInfoContainer>
          <TravelInfoTitle>
            Recursos e regras de localização de viagens:
          </TravelInfoTitle>

          <TravelInfoTextView>
            {renderLinesHelp()?.map((item, index) => {
              return (
                <TravelInfoText
                  key={index}>
                  {item}
                </TravelInfoText>
              );
            })}
          </TravelInfoTextView>
        </TravelInfoContainer>


        <ButtonAction
          title={'Fechar'}
          onPress={() => {
            if (props.onCancelPress) {
              props.onCancelPress();
            }
          }}
        />

      </CategoryChooseContainer>

    </Overlay>

  );
};



export default HelpTravelModal;
