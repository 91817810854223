import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../../../shared/constants';



interface IProps {
  active?: boolean;
  disabled?: boolean;
  color?: string;
  backgroundColor?: string;
}



export const MiniRoomActionsView = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #151515;
`;

export const MiniLinkInfoView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  background-color: #212640;
`;

export const MiniImage = styled.a`
  margin-left: 10px;
  margin-right: 10px;
`;


export const UserActionLinksView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  height: 110px;
  padding: 5px 13px;
  background-color: rgba(0, 0, 0, .3);
  color: #ccc;
  border-radius: 5px;
`;

export const InfoBioModeleView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100px;
`;

export const BioModeleNameView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1px;
`;

export const BioModeleUserNameText = styled.h2`
  font-size: 16px;
  color: #FFFFFF;
`;

export const BioModeleNameText = styled.p`
  margin: 3px 0 6px 0;
  color: #FFFFFF;
  font-size: 17px;
`;

export const BioModeleInfoText = styled.p`
  display: flex;
  flex: 1;
  color: #e6e6e6;
  font-size: 13px;
  line-clamp: 2; 
`;

export const BioModeleLastViewText = styled.p`
  color: #969696;
  font-size: 13px;
`;


export const ActionModeleView = styled.div`
  display: flex;
  flex: 1;
`;

export const ModeleReportView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 180px;
  margin-left: 10px;
`;

export const ModeleStarReportView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
`;

export const ReportModeleText = styled.p`
  margin-left: 10px;
  color: #e6e6e6;
  font-size: 30px;
  line-height: 35px;
`;

export const ReportModeleInfoText = styled.p`
  color: #e6e6e6;
  font-size: 13px;
  line-height: 17px;
  text-align: center;
`;

export const ReportModeleReviewText = styled.span`
  color: ${Colors.white};
  font-weight: bold;
`;


export const ActionBioModeleView = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 300px;
  height: 100%;
  margin-left: 10px;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const ActionBioButton = styled.a`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  padding: 7px 5px 5px;
  cursor: ${(props: IProps) => props.disabled ? 'default' : 'pointer'};
  opacity: ${(props: IProps) => props.disabled ? Sizes.opacityOff : Sizes.opacityOn};
`;

export const ActionBioInfoIcon = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 27px;
  height: 27px;
  margin-bottom: 5px;
  background-color: #444444;
  border-radius: 50%;
`;

export const ActionBioInfoText = styled.p`
  color: ${(props: IProps) => props.color || Colors.white};
  font-size: 11px;
  line-height: 15px;
  text-align: center;
`;


export const ActionTextCompView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 540px;
`;
