import styled,
{
  css,
} from 'styled-components';



interface IProps {
  type?: string;
}



export const FooterStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 25px;
`;

export const ScreenStyle = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const LoadingContainer = styled.div`
  ${(props: IProps) => props.type === 'FOOTER' && FooterStyle};
  ${(props: IProps) => props.type === 'SCREEN' && ScreenStyle};
`;
