import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useNavigate,
} from 'react-router-dom';

import CardAdsSuggestion from '../../../../components/Ads';
import CardModeleRanking from '../../../../components/Cards/CardModeleRanking';
import Content from '../../../../components/Composh/web/Content';
import FooterApp from '../../../../components/Footers/FooterApp';

import {
  IconCrown,
} from '../../../../components/Icons';

import RankingTitle from '../../../../components/Titles/RankingTitle';
import SectionTitle from '../../../../components/Titles/SectionTitle';
import YearSelect from '../../../../components/Titles/YearSelect';

import {
  Colors,
  Images,
} from '../../../../shared/constants';

import {
  ContentCenterFlex,
  ContentWrapperRowFull,
  WrapperItemContent,
} from '../../../../styles/styled.layout';

import {
  MonthlyContainer,
  MonthlyDescriptionText,
  MonthlyDescriptionTextView,
  MonthlyImage,
  MonthlyImageContent,
  MonthlyModeleItemGrid,
  MonthlyTableBigPriceSpan,
  MonthlyTableBigRankSpan,
  MonthlyTableBodyIcon,
  MonthlyTableBodyItem,
  MonthlyTableComponent,
  MonthlyTableContent,
  MonthlyTablePriceSpan,
  MonthlyTableRankSpan,
  MonthlyTableTbody,
  MonthlyTableTd,
  MonthlyTableTh,
  MonthlyTablethead,
  MonthlyTableTr,
  MonthlyTypeContent,
} from './styled';



const TabMonthlyCrush: React.FC = () => {
  const history = useNavigate();


  const [loading, setLoading] = useState(false);

  const [modeles, setModeles] = useState(null);


  // Se for mês atual, mostrar até 50
  const monthlyAllDates = {
    year: '2021',
    months: [
      {
        month: 'February',
        year: '2021',
        current: true,
        modeles: [
          {
            rank: 1,
            top10: true,
            status: null,
          },
          {
            rank: 2,
            top10: true,
            status: null,
          },
          {
            rank: 3,
            top10: true,
            status: 'UP',
          },
          {
            rank: 4,
            top10: true,
            status: 'UP',
          },
          {
            rank: 5,
            top10: true,
            status: null,
          },
          {
            rank: 6,
            top10: true,
            status: 'DOWN',
          },
          {
            rank: 7,
            top10: true,
            status: null,
          },
          {
            rank: 8,
            top10: true,
            status: 'DOWN',
          },
          {
            rank: 9,
            top10: true,
            status: null,
          },
          {
            rank: 10,
            top10: true,
            status: null,
          },
          {
            rank: 11,
            top10: false,
            status: 'UP',
          },
          {
            rank: 12,
            top10: false,
            status: null,
          },
          {
            rank: 13,
            top10: false,
            status: 'UP',
          },
          {
            rank: 14,
            top10: false,
            status: 'DOWN',
          },
          {
            rank: 15,
            top10: false,
            status: 'DOWN',
          },
        ],
      },
      {
        month: 'January',
        year: '2021',
        current: false,
        modeles: [
          {
            rank: 1,
            top10: true,
            status: null,
          },
          {
            rank: 2,
            top10: true,
            status: null,
          },
          {
            rank: 3,
            top10: true,
            status: null,
          },
          {
            rank: 4,
            top10: true,
            status: null,
          },
          {
            rank: 5,
            top10: true,
            status: null,
          },
          {
            rank: 6,
            top10: true,
            status: null,
          },
          {
            rank: 7,
            top10: true,
            status: null,
          },
          {
            rank: 8,
            top10: true,
            status: null,
          },
          {
            rank: 9,
            top10: true,
            status: null,
          },
          {
            rank: 10,
            top10: true,
            status: null,
          },
        ],
      },
    ],
  };


  function renderRankMonthFirstTable(rank: string) {
    return (

      <MonthlyTableTd>
        <MonthlyTableBodyItem>

          <MonthlyTableBodyIcon>
            <IconCrown
              size={20}
              color={'#cead14'}
            />
          </MonthlyTableBodyIcon>


          <MonthlyTableBigRankSpan>
            {rank}
          </MonthlyTableBigRankSpan>

        </MonthlyTableBodyItem>
      </MonthlyTableTd>

    );
  }


  function renderRankMonthLineTable(rank: string) {
    return (

      <MonthlyTableTd>
        <MonthlyTableBodyItem>

          <MonthlyTableBodyIcon>
            <IconCrown
              size={18}
              color={Colors.attention}
            />
          </MonthlyTableBodyIcon>


          <MonthlyTableRankSpan>
            {rank}
          </MonthlyTableRankSpan>

        </MonthlyTableBodyItem>
      </MonthlyTableTd>

    );
  }


  function renderPriceFirstLineTable(price) {
    return (

      <MonthlyTableTd>
        <MonthlyTableBigPriceSpan>
          {price}
        </MonthlyTableBigPriceSpan>
      </MonthlyTableTd>

    );
  }


  function renderPriceMonthLineTable(price) {
    return (

      <MonthlyTableTd>
        <MonthlyTablePriceSpan>
          {price}
        </MonthlyTablePriceSpan>
      </MonthlyTableTd>

    );
  }


  function renderMonthItem(item, index) {
    return (

      <WrapperItemContent
        key={index}>
        <RankingTitle
          title={'Mister'}
          spanTitle={item?.current && 'Current Standings'}
          text={`${item?.month} ${item?.year}`}
        />

        <MonthlyModeleItemGrid>
          {item?.modeles?.map((modele, index) => (
            <CardModeleRanking
              key={index}
              rank={modele?.rank}
              top10={modele?.top10}
              status={modele?.status}
              image={modele?.picture}
            />
          ))}
        </MonthlyModeleItemGrid>
      </WrapperItemContent>

    );
  }



  useEffect(() => {
    // loadHome();
  }, []);



  return (

    <Content>

      <ContentWrapperRowFull>

        <ContentCenterFlex>

          <MonthlyTypeContent>
            <SectionTitle
              title={'Crushs do mês'}
            />

            <YearSelect
            />
          </MonthlyTypeContent>


          <MonthlyContainer>
            <MonthlyImageContent>
              <MonthlyImage
                src={Images.crushsMontlyGuys}
              />
            </MonthlyImageContent>



            <MonthlyTableContent>
              <MonthlyTableComponent>

                <MonthlyTablethead>
                  <tr>
                    <MonthlyTableTh>Rank</MonthlyTableTh>
                    <MonthlyTableTh>Prize</MonthlyTableTh>
                  </tr>
                </MonthlyTablethead>


                <MonthlyTableTbody>
                  <MonthlyTableTr>

                    {renderRankMonthFirstTable('1st')}


                    {renderPriceFirstLineTable('$5000')}


                    {renderRankMonthLineTable('6th')}


                    {renderPriceMonthLineTable('$250')}

                  </MonthlyTableTr>



                  <MonthlyTableTr>

                    {renderRankMonthLineTable('2nd')}


                    {renderPriceMonthLineTable('$2500')}


                    {renderRankMonthLineTable('7th')}


                    {renderPriceMonthLineTable('$250')}

                  </MonthlyTableTr>



                  <MonthlyTableTr>

                    {renderRankMonthLineTable('3rd')}


                    {renderPriceMonthLineTable('$1000')}


                    {renderRankMonthLineTable('8th')}


                    {renderPriceMonthLineTable('$200')}

                  </MonthlyTableTr>



                  <MonthlyTableTr>

                    {renderRankMonthLineTable('4th')}


                    {renderPriceMonthLineTable('$750')}


                    {renderRankMonthLineTable('9th')}


                    {renderPriceMonthLineTable('$200')}

                  </MonthlyTableTr>



                  <MonthlyTableTr>

                    {renderRankMonthLineTable('5th')}


                    {renderPriceMonthLineTable('$500')}



                    {renderRankMonthLineTable('10th')}


                    {renderPriceMonthLineTable('$200')}

                  </MonthlyTableTr>

                </MonthlyTableTbody>

              </MonthlyTableComponent>
            </MonthlyTableContent>
          </MonthlyContainer>



          <MonthlyDescriptionTextView>
            <MonthlyDescriptionText>
              Top flirts are more than just eye catching. They are your companions, confidants, and masters who bring your fantasies to life. We will honor their excellence with the new, improved Flirt of the Month. All prizes have increases, with $5,000 for the #1 model!
            </MonthlyDescriptionText>
          </MonthlyDescriptionTextView>


          {monthlyAllDates?.months?.map((itemMonth, index) => (
            renderMonthItem(itemMonth, index)
          ))}

        </ContentCenterFlex>



        <CardAdsSuggestion />

      </ContentWrapperRowFull>



      <FooterApp />

    </Content>

  );
};



export default TabMonthlyCrush;
