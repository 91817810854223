import styled from 'styled-components';

import {
  ListFlat,
} from '../../components/Composh/web';

import {
  Colors,
  Sizes,
} from '../../shared/constants';



export const PaymentModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background-color: ${Colors.appBackground};
  border-radius: ${Sizes.cardRadius}px;

  width: 340px;
  height: 480px;
`;

export const PaymentPadderHorizontal = styled(ListFlat).attrs({
  contentContainerStyle: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: Sizes.paddingContainer,
    paddingLeft: Sizes.paddingContainer,
    paddingRight: Sizes.paddingContainer,
    paddingBottom: Sizes.paddingContainer,
  },
})`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const PaymentModalText = styled.p`
  margin-bottom: 15px;
  color: ${Colors.textApp};
  font-size: 16px;
  line-height: 22px;
  text-align: center;
`;
