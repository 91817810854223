export const reviewRecommendArray = [
  {
    id: 1,
    label: 'Sim, definitivamente',
    value: 'DEFINITELY_YES',
  },
  {
    id: 2,
    label: 'Não sei',
    value: 'I_DONT_KNOW',
  },
  {
    id: 3,
    label: 'Não, eu não penso assim',
    value: 'NO_I_DONT_THINK_SO',
  },
];
