import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../../../shared/constants';



export const CategoryChooseContainer = styled.div`
    max-width: 500px;
    min-width: 320px;

    height: 600px;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background-color: ${Colors.appBackground};
  border-radius: ${Sizes.cardRadius}px;
`;


export const TravelInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;
