import React from 'react';

import {
  Images,
} from '../../../shared/constants';

import {
  CardStarContainer,
  CardStarImage,
  CardStarImageContainer,
  CardStarUserInfoView,
  CardStarUserText,
  CardStartUsernameText,
} from './styled';



interface IProps {
  name?: string;
  username?: string;
  image?: string;
}



const CardModeleHallPhoto: React.FC<IProps> = (props) => {
  return (

    <CardStarContainer>

      <CardStarImageContainer
      // onClick={
      //   href="/models/bios/izzi/about.php"
      // }
      >
        <CardStarImage
          src={props.image && props.image !== '' && props.image !== undefined
            ? props.image
            : Images.avatarModeleImage
          }
        />
      </CardStarImageContainer>


      <CardStarUserInfoView>
        <CardStarUserText>
          {props.name ? props.name.toUpperCase() : '--'}
        </CardStarUserText>

        <CardStartUsernameText>
          @{props.username || '--'}
        </CardStartUsernameText>
      </CardStarUserInfoView>

    </CardStarContainer>

  );
};



export default CardModeleHallPhoto;
