import SecretioWS from '../index';



export const UpdateLocationModele = async (idModele: string, payload: any) => {
  const url = `/app/modele/location/${idModele}`;

  const response = await SecretioWS.post(url, payload);
  return response.data;
};



export const GetAllTravelsModele = async (idModele: string) => {
  const url = `/app/modele/location/travels/${idModele}`;

  const response = await SecretioWS.get(url);
  return response.data;
};



export const CreateTravelsModele = async (idTravel: string, payload: any) => {
  const url = `/app/modele/location/travels/${idTravel}`;

  const response = await SecretioWS.post(url, payload);
  return response.data;
};



export const DeleteTravelsModele = async (idTravel: number | string, idUserReceived: string) => {
  const url = `/app/modele/location/travels/${idTravel}`;

  const payload = {
    idUser: idUserReceived,
  };

  const response = await SecretioWS.delete(url, {
    data: payload,
  });
  return response.data;
};
