enum EAppMediaVisibility {
  PUBLIC = 'PUBLIC',
  FAN_CLUB = 'FAN_CLUB',
  PRIVATE = 'PRIVATE',
  INTERN = 'INTERN',
}



export default EAppMediaVisibility;
