export function MaskCPFNumbers(cpf: string): string {
  const digits = cpf.match(/\d/g);
  if (!digits || digits.length !== 11) {
    return '-';
  }

  const maskedDigits = digits.map((digit, index) => {
    if (index === 0 || index === 1 || index === 9 || index === 10) {
      return digit;
    }
    else {
      return '*';
    }
  });

  return cpf.replace(/\d/g, () => maskedDigits.shift() as string);
};



export function maskCNPJNumbers(cnpj: string): string {
  const digits = cnpj.match(/\d/g);
  if (!digits || digits.length !== 14) {
    return '-';
  }

  const maskedDigits = digits.map((digit, index) => {
    if (index === 0 || index === 1 || index === 13 || index === 9 || index === 10 || index === 11) {
      return digit;
    }
    if (index === 2 || index === 5) {
      return '.*';
    }
    else if (index === 8) {
      return `/${digit}`;
    }
    if (index === 12) {
      return '-*';
    }
    return '*';
  });

  return cnpj.replace(/\d|\//g, () => maskedDigits.shift() as string);
};



export const MaskNotificationsBadge = (badgeValue: any): string => {
  return badgeValue > 99 ? '+99' : String(badgeValue);
};
