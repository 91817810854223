import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  EditPrivacityModele,
  GetPrivacityModele,
} from '../../../apis/endpoints/modeles.endpoints';

import {
  Content,
  Screen,
  Title,
} from '../../../components/Composh/web';

import {
  ContentPadder,
} from '../../../components/Contents/ContentPadder';

import {
  ButtonBack,
} from '../../../components/Controls';

import FooterApp from '../../../components/Footers/FooterApp';
import HeaderScreen from '../../../components/Headers/HeaderScreen';
import InputSwitch from '../../../components/Inputs/InputSwitch';
import LoadingScreen from '../../../components/Loadings/LoadingScreen';

import {
  RootState,
} from '../../../redux';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../redux/reducers/app-modals.store';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  EPlansUser,
} from '../../../shared/enums';

import {
  TranslateConfig,
} from '../../../shared/translations';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../shared/utils/errors.utils';

import {
  FooterDescription,
} from '../../../styles/footer.layout';

import {
  CardVisibilityForm,
  CardVisibilityView,
} from './styled';



const VisibilityModeleScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);
  const userIdSearch = user?.useralias;


  const [showLastLogin, setShowLastLogin] = useState(false);
  const [showMyProfileSearch, setShowMyProfileSearch] = useState(false);
  const [showMyLocations, setShowMyLocations] = useState(false);

  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function setStatesParams(paramsReceived: any) {
    setShowLastLogin(paramsReceived?.showLastLogin);
    setShowMyProfileSearch(paramsReceived?.showMyProfileSearch);
    setShowMyLocations(paramsReceived?.showMyLocations);
  }


  async function getActualModele() {
    try {
      setLoading(true);

      const response = await GetPrivacityModele(userIdSearch);
      const responseModele = response?.data;

      if (!responseModele) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, translate(TranslateConfig.ERROR_GET_DATA));
        return;
      }

      setStatesParams(responseModele);
    }
    catch (error: any) {
      console.error(error);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
    }
  }


  async function saveItemGroup(payload: any) {
    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await EditPrivacityModele(userIdSearch, payload);

      if (!response?.data) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, translate(TranslateConfig.ERROR_SAVE));
        return;
      }

      setStatesParams(response?.data);

      showSnackBarProps(Colors.accept, translate(TranslateConfig.SUCCESS_SAVE));
    }
    catch (error: any) {
      console.error(error);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  async function updateForm() {
    await getActualModele();
    setMounted(true);
  }



  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.white}
      />

    );
  }



  useEffect(() => {
    updateForm();
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet prioritizeSeoTags>
        <title>
          {`${translate(TranslateConfig.VISIBILITY_PRIVACY)} | ${translate(TranslateConfig.NAME_APP)}`}
        </title>

        <meta name="robots" content="noindex, nofollow" />
      </Helmet>



      <HeaderScreen
        disableGender
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            color={Colors.white}
            size={27}
          />
        }
        centerContent={
          <Title
            color={Colors.white}>
            {translate(TranslateConfig.VISIBILITY_PRIVACY)}
          </Title>
        }
      />

      <Content>
        {!mounted && (
          renderLoading()
        )}

        {mounted && (
          <ContentPadder>

            <CardVisibilityForm
              backgroundColor={Colors.formBackground}
              borderRadius={Sizes.cardRadius}>

              <CardVisibilityView>

                <InputSwitch
                  disabled={loading}
                  selected={showLastLogin}
                  title={translate(TranslateConfig.ACCESS_LAST)}
                  description={translate(TranslateConfig.ACCESS_LAST_SHOW)}
                  onPress={() => {
                    if (!loading) {
                      saveItemGroup({ showLastLogin: !showLastLogin });
                    }
                  }}
                />

              </CardVisibilityView>



              <CardVisibilityView>

                <InputSwitch
                  disabled={loading}
                  selected={showMyLocations}
                  title={translate(TranslateConfig.MY_TRAVEL_LOCATION)}
                  description={translate(TranslateConfig.MY_TRAVEL_LOCATION_SHOW)}
                  onPress={() => {
                    if (!loading) {
                      saveItemGroup({ showMyLocations: !showMyLocations });
                    }
                  }}
                />

              </CardVisibilityView>



              {(user?.plan?.planCurrent === EPlansUser.PLATINUM || user?.plan?.planCurrent === EPlansUser.DIAMOND) && (
                <CardVisibilityView>

                  <InputSwitch
                    disabled={loading}
                    selected={showMyProfileSearch}
                    title={translate(TranslateConfig.PROFILE_ON_SEARCH)}
                    description={translate(TranslateConfig.PROFILE_ON_SEARCH_SHOW)}
                    onPress={() => {
                      if (!loading) {
                        saveItemGroup({ showMyProfileSearch: !showMyProfileSearch });
                      }
                    }}
                  />

                  <FooterDescription>
                    {translate(TranslateConfig.PROFILE_ON_SEARCH_TEXT)}
                  </FooterDescription>

                </CardVisibilityView>
              )}

            </CardVisibilityForm>

          </ContentPadder>
        )}



        <FooterApp />

      </Content>

    </Screen>

  );
};



export default VisibilityModeleScreen;
