import styled from 'styled-components';



// interface IProps {
//   rank?: string;
//   top10?: boolean;
// }



export const CardYearModeleContainer = styled.a`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  aspect-ratio: 1.25;
  background: #3b3a4a;
`;


export const ModeleYearImageBackground = styled.img`
  width: 100%; 
  height: 100%;
  object-fit: cover;
  z-index: 0;
`;


export const ModeleYearRankingView = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 5px 9px;
  background: linear-gradient(90deg, #639297 0%, #014883 100%);
`;

export const ModeleYearRankingText = styled.span`
  color: #fff;
  font-size: 19px;
  font-weight: 700;
`;


export const ModeleYearUserView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  padding: 4% 0 3%;
  width: 100%;
  background: linear-gradient(180deg, rgba(255,255,255,0) 12%, rgba(0,0,0,0.6) 94%);
`;

export const ModeleYearUserText = styled.p`
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  text-shadow: 0 1px 3px #333;
`;

export const ModeleYearUserPrize = styled.p`
  margin-top: 3px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  text-shadow: 0 1px 3px #333;
`;
