import {
  configureStore,
} from '@reduxjs/toolkit';

import {
  combineReducers,
} from 'redux';

import {
  persistReducer,
} from 'redux-persist';

import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import appConfigReducer from './reducers/app-configs.store';
import appModalsReducer from './reducers/app-modals.store';
import messagesReducer from './reducers/chat-messages.store';
import chooseReducer from './reducers/choose.store';
import favoritesReducer from './reducers/favorites.store';
import likesReducer from './reducers/likes.store';
import linkbioReducer from './reducers/linkbio.store';
import localitiesReducer from './reducers/localities.store';
import modelesReducer from './reducers/modeles.store';
import userReducer from './reducers/user.store';



const reducers = combineReducers({
  appConfigs: appConfigReducer,
  appModals: appModalsReducer,
  choose: chooseReducer,
  favorites: favoritesReducer,
  likes: likesReducer,
  linkbio: linkbioReducer,
  localities: localitiesReducer,
  messages: messagesReducer,
  modeles: modelesReducer,
  user: userReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  // blacklist: ['navigation'], // navigation will not be persisted
  // whitelist: ['navigation'], // only navigation will be persisted
};

const persistedReducer = persistReducer(persistConfig, reducers);



const storeReducers = configureStore({
  reducer: reducers,
});

const storePersisted = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});



export type RootState = ReturnType<typeof storeReducers.getState>;

export {
  // storeReducers,
  storePersisted,
};
