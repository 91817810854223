import styled from 'styled-components';



export const CharWrapperFirstContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 40px;
`;


export const CharItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
