import styled from 'styled-components';

export const PreviewRoot = styled.div<{ srcBg?: string }>`
  border: 14px solid ${'#131415'};
  background-size: cover;
  background-position: center;
  width: 100%;
  max-width: 300px;
  height: 600px;
  border-radius: 42px;
  text-align: center;
  overflow: hidden;

  ${({ srcBg }) =>
    srcBg &&
    `
    background-image: url(${srcBg});
  `}

  & > div {
    padding: 28px 16px;
    overflow-y: auto;
    max-height: 100%;
  }
`;
