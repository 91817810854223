import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { Content } from '../../../../../components/Composh/web';

import UserAvatar from '../../../../../components/Contents/UserAvatar';

import { IconCopy, IconShare } from '../../../../../components/Icons';

import TabBarStyled from '../../../../../components/TabBar';

import { openSite } from '../../../../../config/linking.config';

import NameRoutes from '../../../../../navigation/names';

import { RootState } from '../../../../../redux';

import { Images } from '../../../../../shared/constants';

import { validateString } from '../../../../../shared/utils/string.utils';

import {
  EditLinkBioContainer,
  EditLinkBioHeader,
  DetailsApproveViewBackground,
  HeaderUserBackgroundOverlay,
  DetailsApproveBackground,
  UserLinkBioInfoView,
  UserLinkBioAvatarView,
  UserLinkBioDataView,
  UserLinkBioCountryText,
  UserLinkBioNameText,
  UserLinkBioUsernameText,
  UserLinkBioLinkView,
  UserLinkBioRowLineView,
  UserLinkBioLabelView,
  UserLinkSeeLinkButton,
  UserLinkActionsButton,
} from './styled';

import AppearanceTab from './Tabs/Appearance';
import TabLinks from './Tabs/Links';
import SettingsTab from './Tabs/Settings';



const TabEditing: React.FC = () => {
  const user = useSelector((state: RootState) => state.user.actualUser);
  const userIdSearch = user?.useralias;


  const [stateIndex, setStateIndex] = useState(0);


  const screenTabs = [
    {
      visible: true,
      name: 'LINKS',
      title: 'Links',
      // icon: (
      //   <IconDashboard
      //     color={stateIndex === 0 ? accentGender : Colors.inputCount}
      //     size={iconTabBar}
      //   />
      // ),
      component: (
        <TabLinks />
      ),
    },
    {
      visible: true,
      name: 'APPEARENCE',
      title: 'Apariencia',
      // icon: (
      //   <IconDashboard
      //     color={stateIndex === 1 ? accentGender : Colors.inputCount}
      //     size={iconTabBar}
      //   />
      // ),
      component: (
        <AppearanceTab />
      ),
    },
    {
      visible: true,
      name: 'SETTINGS',
      title: 'Configuracion',
      // icon: (
      //   <IconDashboard
      //     color={stateIndex === 2 ? accentGender : Colors.inputCount}
      //     size={iconTabBar}
      //   />
      // ),
      component: (
        <SettingsTab />
      ),
    },
  ];



  return (

    <EditLinkBioContainer>

      <EditLinkBioHeader>

        <DetailsApproveViewBackground>
          <HeaderUserBackgroundOverlay />

          <DetailsApproveBackground
            src={validateString(user?.pictureCover) || Images.bannerDetails}
          />
        </DetailsApproveViewBackground>



        <UserLinkBioInfoView>

          <UserLinkBioAvatarView>
            <UserAvatar
              plan={user?.plan?.planCurrent}
              image={user?.picture}
              width={100}
              height={100}
            />
          </UserLinkBioAvatarView>


          <UserLinkBioDataView>

            <UserLinkBioCountryText>
              Brasil
            </UserLinkBioCountryText>

            <UserLinkBioNameText>
              Nome
            </UserLinkBioNameText>

            <UserLinkBioUsernameText>
              @username
            </UserLinkBioUsernameText>

          </UserLinkBioDataView>



          <UserLinkBioLinkView>

            <UserLinkBioRowLineView>

              <UserLinkBioLabelView>
                LinkBio Secretio
              </UserLinkBioLabelView>


              <UserLinkSeeLinkButton
                onClick={() => {
                  const newUrl = NameRoutes.ViewLinkBioScreen.replace(':id', userIdSearch);
                  openSite(newUrl);
                }}>
                Ver linkBio
              </UserLinkSeeLinkButton>

            </UserLinkBioRowLineView>



            <UserLinkBioRowLineView>

              <p style={{ color: 'white' }}>
                Link
              </p>


              <UserLinkActionsButton>
                <IconCopy />
              </UserLinkActionsButton>

              <UserLinkActionsButton>
                <IconShare />
              </UserLinkActionsButton>

            </UserLinkBioRowLineView>

          </UserLinkBioLinkView>

        </UserLinkBioInfoView>



        <TabBarStyled
          tabs={screenTabs}
          selected={stateIndex}
          onPress={(tab) => {
            setStateIndex(tab?.index);
          }}
        />

      </EditLinkBioHeader>



      <Content>

        {stateIndex === 0 && screenTabs[0].component}

        {stateIndex === 1 && screenTabs[1].component}

        {stateIndex === 2 && screenTabs[2].component}

      </Content>

    </EditLinkBioContainer>

  );
};

export default TabEditing;
