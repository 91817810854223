import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
} from 'react-redux';

import {
  Content,
} from '../../../../../components/Composh/web';

import FooterApp from '../../../../../components/Footers/FooterApp';
import HeaderHelp from '../../../../../components/Headers/HeaderHelp';

import {
  IconEdit,
  IconMediaPhoto,
} from '../../../../../components/Icons';

import SectionTitle from '../../../../../components/Titles/SectionTitle';

import {
  RootState,
} from '../../../../../redux';

import {
  Colors,
  Images,
} from '../../../../../shared/constants';

import {
  TranslateConfig,
} from '../../../../../shared/translations';

import InfoPrincipal from '../../Infos/InfoPrincipal';

import {
  PrincipalContentPadder,
  ContentCenterFlex,
  PrincipalImagesContainer,
  PrincipalBackgroundContainer,
  PrincipalBackgroundContent,
  PrincipalBackgroundButton,
  PrincipalBackgroundIcon,
  PrincipalBackgroundText,
  PrincipalBackgroundImage,
  PrincipalAvatarContainer,
  PrincipalAvatarContent,
  PrincipalAvatarImage,
  PrincipalAvatarIcon,
  MediaTypeContent,
} from './styled';



export interface IProps {
  onImagePress?: any;
  onCoverPress?: any;
  onShowModal?: any;
}



const TabPrincipal: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);

  const userPicture = user?.picture || Images.avatarUserImage;
  const userCover = user?.pictureCover || Images.bannerDetails;



  return (

    <Content>

      <PrincipalContentPadder>

        <ContentCenterFlex>

          <HeaderHelp
            title={'Publicar mídias do perfil'}
            onHelpPress={() => {
              props.onShowModal();
            }}
          />



          <PrincipalImagesContainer>

            <PrincipalAvatarContainer>

              <SectionTitle
                title={translate(TranslateConfig.PROFILE_PICTURE)}
                color={Colors.primary}
              />


              <PrincipalAvatarContent
                onClick={() => {
                  if (props.onImagePress) {
                    props.onImagePress();
                  }
                }}>
                <PrincipalAvatarImage
                  src={userPicture}
                />

                <PrincipalAvatarIcon>
                  <IconEdit
                    color={Colors.white}
                    size={20}
                  />
                </PrincipalAvatarIcon>
              </PrincipalAvatarContent>

            </PrincipalAvatarContainer>



            <PrincipalBackgroundContainer>

              <SectionTitle
                title={'Imagem da capa'}
                color={Colors.primary}
              />


              <PrincipalBackgroundContent
                onClick={() => {
                  if (props.onCoverPress) {
                    props.onCoverPress();
                  }
                }}>

                <PrincipalBackgroundButton>

                  <PrincipalBackgroundIcon>
                    <IconMediaPhoto
                      color={Colors.white}
                      size={22}
                    />
                  </PrincipalBackgroundIcon>


                  <PrincipalBackgroundText>
                    Editar foto da capa
                  </PrincipalBackgroundText>

                </PrincipalBackgroundButton>



                <PrincipalBackgroundImage
                  src={userCover}
                />

              </PrincipalBackgroundContent>

            </PrincipalBackgroundContainer>



            {false && (
              <p>
                Você pode anexar um vídeo de capa na horizontal que ele vai carregar 6 segundos, dando uma interação melhor com os usuários. Obs: não pode o vídeo na vertical.
              </p>
            )}

          </PrincipalImagesContainer>

        </ContentCenterFlex>



        <MediaTypeContent>
          <InfoPrincipal />
        </MediaTypeContent>

      </PrincipalContentPadder>



      <FooterApp />

    </Content>

  );
};



export default TabPrincipal;
