import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';



export const DashboardProfileHeaderBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
  min-width: 320px;
  max-width: 370px;
  padding-left: 0;
  padding-right: 16px;
  background-color: ${Colors.black};
  border-radius: ${Sizes.cardRadius}px;
  /* box-shadow: 0 8px 4px #00000026; */

  @media ${PlatformDevices.maxLaptopL} {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
`;

export const DashboardProfileImageContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 85px;
  min-height: 95px;
  height: 100%;
  margin-right: 16px;
`;

export const DashboardProfileImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const DashboardProfileInfoContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DashboardProfileNameText = styled.p`
  margin-bottom: 3px;
  color: ${Colors.white};
  font-size: 17px;
  font-weight: 500;
`;

export const DashboardProfileUsernameText = styled.p`
  margin-bottom: 12px;
  color: ${Colors.white};
  font-size: 14px;
  font-weight: 500;
`;

export const DashboardProfilePlaceContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ModelesPlaceAtText = styled.p`
  margin-right: 6px;
  color: #B4B4B4;
  font-size: 14.5px;
  text-shadow: ${Colors.black} 1px 1px 2px;
`;

export const DashboardProfilePlaceIcon = styled.div`
  display: flex;
  flex-direction: column;
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

export const ModelesPlaceCountry = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const DashboardProfilePlaceText = styled.p`
  color: ${Colors.gray};
  font-size: 13px;
  font-weight: 400;
`;
