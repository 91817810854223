import styled from 'styled-components';

import {
  Toggle,
} from '../../Composh/web';



export interface IProps {
  clickable?: boolean;
}



export const ToggleStyled = styled(Toggle)`
  cursor: ${(props: IProps) => !props.clickable ? 'default' : 'pointer'};
`;
