import React,
{
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
} from 'react-redux';

import {
  useLocation,
} from 'react-router-dom';

import ButtonAction from '../../../components/Buttons/ButtonAction';
import CardSuggestionSmall from '../../../components/Cards/CardSuggestions/CardSuggestionSmall';
import CardTips from '../../../components/Cards/CardTips';

import {
  Body,
  Content,
  Screen,
  SubTitle,
  Title,
} from '../../../components/Composh/web';

import ChatTypesSubtitles from '../../../components/Contents/ChatTypesSubtitles';

import {
  ButtonBack,
} from '../../../components/Controls';

import HeaderScreen from '../../../components/Headers/HeaderScreen';

import {
  IconScreenFull,
  IconInformation,
  IconMegaphone,
  IconRefresh,
  IconSoundOn,
  IconTipCredits,
  IconTipGifts,
} from '../../../components/Icons';

import MessageTipAmount from '../../../components/Messages/MessageTipAmount';
import ModelePlayer from '../../../components/WebCam/ModelePlayer';

import {
  RootState,
} from '../../../redux';

import {
  TipCreditsArray,
  TipGiftsArray,
} from '../../../shared/arrays';

import {
  Colors,
  Images,
} from '../../../shared/constants';
import {

  ERoomType,
} from '../../../shared/enums';

import {
  TranslateConfig,
} from '../../../shared/translations';

import RoomActions from './Sections/RoomActions';
import RoomResize from './Sections/RoomResize';

import {
  WrapperRoomContainer,
  VideoModeleView,
  ChatOptionsView,
  ButtonChatContentView,
  OptionChatContentView,
  MarginChatTypeButton,
  PartyChatTypeButton,
  PartyChatTypeIcon,
  GroupChatTypeButton,
  GroupChatTypeTitle,
  GroupChatTypeStartText,
  GroupChatTypeTimeText,
  ChatOptionsTextButton,
  ChatOptionsButton,
  OfflinePanels,
  ChatPanels,
  TopicChatHeader,
  TopicChatText,
  ChatView,
  TipChatView,
  TipHeaderView,
  TipTypeHeaderButton,
  TipTypeHeaderTextButton,
  TipItemsContent,
} from './styled';



export interface IRouteLocation {
  status?: ERoomType;
  promo?: boolean;
}



const LiveRoomCamScreen: React.FC = () => {
  const { t: translate } = useTranslation();

  const routeLocation = useLocation();
  const params = routeLocation?.state as IRouteLocation;


  const modeleStatus = params?.status;
  const modelePromo = params?.promo;


  const genderColorChoose = useSelector((state: RootState) => state.choose.genderColorApp);


  const [roomStatus, setRoomStatus] = useState<ERoomType | null>(modeleStatus);
  const [showTypeRoomModal, setShowTypeRoomModal] = useState(false);
  const [tabTipSelected, setTabTipSelected] = useState('CREDIT');



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        disableGender
        showBalance
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            color={Colors.white}
            size={27}
          />
        }
        centerContent={
          <Body>
            <Title>
              {translate(TranslateConfig.LIVE_ROOM)}
            </Title>

            <SubTitle>
              @{'username'}
            </SubTitle>
          </Body>
        }
      />



      <Content>

        <WrapperRoomContainer>

          <VideoModeleView>

            <ModelePlayer
              status={roomStatus}
            />


            <ChatOptionsView>

              {roomStatus === ERoomType.BASIC && (
                <ButtonChatContentView>
                  <MarginChatTypeButton>
                    <ButtonAction
                      disabled={!(roomStatus === ERoomType.BASIC || roomStatus === ERoomType.PARTY)}
                      backgroundColor={genderColorChoose}
                      height={30}
                      title={translate(TranslateConfig.LIVE_CHAT_EXCLUSIVE)}
                      // iconLeft={
                      // <IconServer
                      //   color={'white'}
                      //   size={20}
                      // />
                      // }
                      onPress={() => {
                        console.log('Botão Exclusivo');
                      }}
                    />
                  </MarginChatTypeButton>


                  <MarginChatTypeButton>
                    <ButtonAction
                      disabled={!(roomStatus === ERoomType.BASIC || roomStatus === ERoomType.PARTY)}
                      backgroundColor={genderColorChoose}
                      height={30}
                      title={translate(TranslateConfig.LIVE_CHAT_PARTICULAR)}
                      // iconLeft={
                      // <IconServer
                      //   color={'white'}
                      //   size={20}
                      // />
                      // }
                      onPress={() => {
                        console.log('Botão Particular');
                      }}
                    />
                  </MarginChatTypeButton>
                </ButtonChatContentView>
              )}



              {roomStatus === ERoomType.PARTY && (
                <PartyChatTypeButton>

                  <PartyChatTypeIcon
                    alt={''}
                    src={Images.iconChatParty}
                  />


                  <ChatOptionsTextButton>
                    {translate(TranslateConfig.LIVE_CHAT_PARTY).toUpperCase()}
                  </ChatOptionsTextButton>

                </PartyChatTypeButton>
              )}



              {(roomStatus === ERoomType.PREGROUP || roomStatus === ERoomType.GROUP) && (
                <ButtonChatContentView>
                  <GroupChatTypeButton
                    backgroundColor={genderColorChoose}>

                    <GroupChatTypeTitle>
                      {translate(TranslateConfig.LIVE_GROUP_JOIN)}
                    </GroupChatTypeTitle>


                    {roomStatus === ERoomType.GROUP && (
                      <GroupChatTypeStartText>
                        {translate(TranslateConfig.LIVE_GROUP_STARTED)}
                      </GroupChatTypeStartText>
                    )}

                    {roomStatus === ERoomType.PREGROUP && (
                      <GroupChatTypeStartText>
                        {translate(TranslateConfig.LIVE_GROUP_STARTED)}
                      </GroupChatTypeStartText>
                    )}


                    {roomStatus === ERoomType.PREGROUP && (
                      <GroupChatTypeTimeText>
                        {'03:53'}
                      </GroupChatTypeTimeText>
                    )}

                  </GroupChatTypeButton>
                </ButtonChatContentView>
              )}



              <OptionChatContentView>
                <ChatOptionsButton
                  backgroundColor={genderColorChoose}
                  onClick={() => {
                    setShowTypeRoomModal(!showTypeRoomModal);
                  }}>
                  <ChatTypesSubtitles
                    show={showTypeRoomModal}
                  />

                  <IconInformation
                    size={20}
                    color={Colors.white}
                  />
                </ChatOptionsButton>



                <ChatOptionsButton
                  backgroundColor={genderColorChoose}>
                  <IconRefresh
                    size={20}
                    color={Colors.white}
                  />
                </ChatOptionsButton>



                <ChatOptionsButton
                  backgroundColor={genderColorChoose}>
                  <IconSoundOn
                    size={20}
                    color={Colors.white}
                  />
                </ChatOptionsButton>



                <ChatOptionsButton
                  backgroundColor={genderColorChoose}>
                  <IconScreenFull
                    size={20}
                    color={Colors.white}
                  />
                </ChatOptionsButton>
              </OptionChatContentView>

            </ChatOptionsView>

          </VideoModeleView>



          <RoomResize />



          {roomStatus === ERoomType.OFFLINE && (
            <ChatPanels>

              <TopicChatHeader>
                <TopicChatText>
                  {translate(TranslateConfig.LIVE_ROOMS_OPENED)}
                </TopicChatText>
              </TopicChatHeader>



              <OfflinePanels>

                <CardSuggestionSmall />
                <CardSuggestionSmall />
                <CardSuggestionSmall />
                <CardSuggestionSmall />

              </OfflinePanels>

            </ChatPanels>
          )}



          {roomStatus !== ERoomType.OFFLINE && (
            <ChatPanels>

              {/* Topic */}
              <TopicChatHeader>
                <IconMegaphone
                  size={19}
                  color={Colors.white}
                />

                <TopicChatText>
                  {false
                    ? `${translate(TranslateConfig.LIVE_TOPIC)}: ${'NOME_DO_TOPICO'}`
                    : translate(TranslateConfig.EMPTY_LIVE_TOPIC)
                  }
                </TopicChatText>
              </TopicChatHeader>



              {/* Chat */}
              <ChatView>

                <MessageTipAmount />
                <MessageTipAmount />

              </ChatView>



              {/* Tips */}
              <TipChatView>

                <TipHeaderView>

                  <TipTypeHeaderButton
                    active={tabTipSelected === 'CREDIT'}
                    onClick={() => {
                      setTabTipSelected('CREDIT');
                    }}>
                    <IconTipCredits
                      size={17}
                      color={Colors.white}
                    />

                    <TipTypeHeaderTextButton>
                      {translate(TranslateConfig.CREDITS)}
                    </TipTypeHeaderTextButton>
                  </TipTypeHeaderButton>



                  <TipTypeHeaderButton
                    active={tabTipSelected === 'GIFT'}
                    onClick={() => {
                      setTabTipSelected('GIFT');
                    }}>
                    <IconTipGifts
                      size={17}
                      color={Colors.white}
                    />

                    <TipTypeHeaderTextButton>
                      {translate(TranslateConfig.GIFTS)}
                    </TipTypeHeaderTextButton>
                  </TipTypeHeaderButton>



                  <TipTypeHeaderButton>
                    <p style={{ color: 'white' }}>
                      [] {translate(TranslateConfig.ACTION_GIFT_ANONYMOUS)}
                    </p>
                  </TipTypeHeaderButton>

                </TipHeaderView>



                {tabTipSelected === 'CREDIT' && (
                  <TipItemsContent>
                    {TipCreditsArray.map((item: any, index: number) => (
                      <CardTips
                        key={index}
                        text={item.tip.toString()?.toUpperCase() === 'CUSTOM'
                          ? translate(TranslateConfig.CUSTOM)
                          : item.tip.toString()}
                        image={item.image}
                        onPress={() => {
                          // TODO
                        }}
                      />
                    ))}
                  </TipItemsContent>
                )}



                {tabTipSelected === 'GIFT' && (
                  <TipItemsContent>
                    {TipGiftsArray.map((item: any, index: number) => (
                      <CardTips
                        key={index}
                        text={item.tip.toString()}
                        image={item.image}
                        onPress={() => {
                          // TODO
                        }}
                      />
                    ))}
                  </TipItemsContent>
                )}

              </TipChatView>

            </ChatPanels>
          )}

        </WrapperRoomContainer>



        <RoomActions
        />

      </Content>

    </Screen>

  );
};



export default LiveRoomCamScreen;
