enum ETokenAsync {
  USER_TOKEN = 'SECRETIO-TOKEN',
  USER_ID = 'SECRETIO-ID',
  USER_CLIENT_NUMBER = 'SECRETIO-CLIENT-NUMBER',
  USER_DATA = 'SECRETIO-USER',
}



export default ETokenAsync;
