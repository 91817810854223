import styled from 'styled-components';



export const SuggestionModeleItemGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 5px;
  margin-top: 10px;
`;

// export const SuggestionRoomOnlineList = styled.div`
//   display: grid;
//   grid-template-columns: repeat(2, 1fr);
//   justify-items: center;
//   align-items: center;
//   position: relative;
//   margin: 5px 0 20px;
// `;
