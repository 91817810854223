import React,
{
  useEffect,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
} from 'react-redux';

import {
  useNavigate,
} from 'react-router-dom';

import ErrorContainer from '../../../components/Containers/ErrorContainer';

import NameRoutes from '../../../navigation/names';

import {
  RootState,
} from '../../../redux';

import {
  Images,
} from '../../../shared/constants';

import {
  TranslateConfig,
} from '../../../shared/translations';



const AppDownScreen: React.FC = () => {
  const history = useNavigate();
  const { t: translate } = useTranslation();


  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);



  function verifyParams() {
    if (projectParams?.showRent || projectParams?.showLive || projectParams?.showFanClub) {
      history(NameRoutes.HomeScreen, { replace: true });
      return null;
    }
  }



  useEffect(() => {
    verifyParams();
  }, []);



  return (

    <ErrorContainer
      title={translate(TranslateConfig.ERROR_SORRY)}
      description={translate(TranslateConfig.ERROR_ACCESS_MAINTENANCE)}
      image={Images.errorAppDown}
    />

  );
};



export default AppDownScreen;
