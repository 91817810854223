import styled from 'styled-components';



export const RatingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RatingButton = styled.a`
  display: flex;
  flex-direction: column;
  margin-left: 3px;
  margin-right: 3px;
`;
