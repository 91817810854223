import React from 'react';

import {
  Screen,
  Title,
} from '../../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../../components/Controls';

import HeaderScreen from '../../../../components/Headers/HeaderScreen';

import {
  Colors,
} from '../../../../shared/constants';

import TabEditing from './Edit';
import TabPreviewLive from './Preview';

import {
  LinkBioContainer,
  DashboardContent,
  PreviewContent,
} from './styled';



const LinkBioDashboardScreen: React.FC = () => {
  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        disableGender
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            color={Colors.white}
            size={27}
          />
        }
        centerContent={
          <Title>
            LinkBio
          </Title>
        }
      />



      <LinkBioContainer>

        <DashboardContent>
          <TabEditing />
        </DashboardContent>



        <PreviewContent>
          <TabPreviewLive />
        </PreviewContent>

      </LinkBioContainer>

    </Screen>

  );
};



export default LinkBioDashboardScreen;
