import React from 'react';

import {
  Overlay,
} from '../../components/Composh/web';

import EmptyContent from '../../components/Empty';
import FooterChoose from '../../components/Footers/FooterChoose';
import HeaderModal from '../../components/Headers/HeaderModal';
import CreditCardDash from '../../components/Payments/CreditCardDash';

import {
  openSite,
} from '../../config/linking.config';

import {
  ArrayColors,
} from '../../shared/arrays';

import {
  Colors,
  Images,
} from '../../shared/constants';

import {
  ETokenAsync,
} from '../../shared/enums';

import Vars from '../../shared/environments/variables';

import {
  getLocalStorageItem,
} from '../../shared/utils/local-storage.utils';

import {
  ButtonText,
} from '../../styles/styled.layout';

import {
  PaymentModalContainer,
  PaymentPadderHorizontal,
} from './styled';



export interface IProps {
  visible: boolean;
  data: Array<any>;
  onCancelPress?: any;
  onPress?: any;
}



const PaymentsModal: React.FC<IProps> = (props: IProps) => {
  const visibleModal = props.visible;
  if (!visibleModal) {
    return null;
  }


  const token = getLocalStorageItem(ETokenAsync.USER_TOKEN);

  const paymentsData = props.data || [];



  function goBack() {
    if (props.onCancelPress) {
      props.onCancelPress();
    }
  };


  function openExternalSite(link: string) {
    const commonParams = `?token=${token}`;
    openSite(`${link}${commonParams}`);
  }


  function renderItem(item: any, index: any) {
    return (

      <CreditCardDash
        key={index} // key={item?.pagarme_card_id}
        current={item?.main}
        cardBrand={item?.card_brand}
        name={item?.name}
        last4={item?.last4}
        onPress={() => {
          if (props.onPress) {
            props.onPress(item);
          }
        }}
      />

    );
  }


  function listEmptyComponent() {
    // if (loading) {
    //   return null;
    // }

    return (

      <EmptyContent
        image={Images.noImage}
        title={'Não há formas de pagamento cadastrado!'}
      />

    );
  }


  // async function refreshList() {
  //   setRefreshing(true);
  //   await getRules();
  //   setRefreshing(false);
  // }



  return (

    <Overlay
      visible={visibleModal}
      showBackground={true}
      overlayColor={Colors.menuOverlay}
      onOverlayPress={props.onCancelPress}>

      <PaymentModalContainer>

        <HeaderModal
          title={'Cartões'}
          subtitle={'Cartões de crédito e débito'}
          onPress={() => {
            goBack();
          }}
        />



        <PaymentPadderHorizontal
          data={paymentsData}
          renderItem={renderItem}
          listEmptyComponent={listEmptyComponent()}
        />



        <FooterChoose
          cancelColor={ArrayColors.arrayCancel}
          cancelLabel={
            <ButtonText
              color={Colors.white}>
              Fechar
            </ButtonText>
          }
          cancelPress={() => {
            goBack();
          }}

          okColor={ArrayColors.arrayOk}
          okLabel={
            <ButtonText
              color={Colors.white}>
              Adicionar cartão
            </ButtonText>
          }
          okPress={() => {
            openExternalSite(Vars().globalAccountCreditCard);
          }}
        />

      </PaymentModalContainer>

    </Overlay>

  );
};



export default PaymentsModal;
