import styled from 'styled-components';

// import {
//   Sizes,
// } from '../shared/constants';



export interface IProps {
  active?: boolean;
  backgroundColor?: string;
  color?: string;
}



// Wrapper Content

// export const ContentRowWrapperAuto = styled.div`
//   display: flex;
//   flex-direction: row;
//   flex: 1;
//   width: 100%;
//   max-width: ${Sizes.contentMaxWidth}px;
//   margin: 30px auto;
// `;

// export const ContentColumnWrapperAuto = styled.div`
//   display: flex;
//   flex-direction: column;
//   flex: 1;
//   width: 100%;
//   max-width: ${Sizes.contentMaxWidth}px;
//   margin: 30px auto;
// `;

export const ContentWrapperRowFull = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  padding: 30px 20px;
`;

// export const ContentWrapperColumnFull = styled.div`
//   display: flex;
//   flex-direction: column;
//   flex: 1;
//   width: 100%;
//   padding: 30px 20px;
// `;

export const WrapperItemContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 25px;
`;


// Left Content

// export const ContentLeft = styled.div`
//   display: flex;
//   flex-direction: column;
//   width: ${Sizes.contentLeftWidth}px;
//   margin-right: ${Sizes.contentMargin}px;
// `;

// export const ContentLeftMessage = styled.div`
//   display: flex;
//   flex-direction: column;
//   width: ${Sizes.contentRightHomeWidth}px;
//   margin-right: ${Sizes.contentMargin}px;
// `;


// Center Content

// export const ContentLoadingCenterFlex = styled.div`
//   display: flex;
//   flex-direction: column;
//   flex: 1;
//   height: calc(100vh - 48px - 55px);
// `;

export const ContentCenterFlex = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

// export const ContentCenterRowFlex = styled.div`
//   display: flex;
//   flex-direction: row;
//   flex: 1;
//   width: 100%;
// `;


// Right Content

// export const ContentRightProfile = styled.div`
//   display: flex;
//   flex-direction: column;
//   width: ${Sizes.contentRightProfileWidth}px;
//   margin-left: ${Sizes.contentMargin}px;
// `;

// export const ContentRightHome = styled.div`
//   display: flex;
//   flex-direction: column;
//   width: ${Sizes.contentRightHomeWidth}px;
//   margin-left: ${Sizes.contentMargin}px;

//   @media ${PlatformDevices.minLaptop} {
//     margin-left: 0;
//   }
// `;

// export const ContentRightSuggestion = styled.div`
//   display: flex;
//   flex-direction: column;
//   width: ${Sizes.contentLeftWidth}px;
//   margin-left: ${Sizes.contentMargin}px;
// `;



export const ButtonText = styled.p`
  color: ${(props: IProps) => props.color || '#ffffff'};
  font-size: 16px;
  text-align: center;
`;
