import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useNavigate,
} from 'react-router-dom';

import ButtonAction from '../../../../../components/Buttons/ButtonAction';
import CardComment from '../../../../../components/Cards/CardComment';
import EmptyContent from '../../../../../components/Empty';

import {
  IconStar,
} from '../../../../../components/Icons';

import SectionTitle from '../../../../../components/Titles/SectionTitle';

import NameRoutes from '../../../../../navigation/names';

import {
  RootState,
} from '../../../../../redux';

import {
  APP_SNACKBAR_ACTION,
} from '../../../../../redux/reducers/app-modals.store';

import {
  Colors,
  Images,
} from '../../../../../shared/constants';

import EUserType from '../../../../../shared/enums/Users/user-type.enum';

import {
  TranslateConfig,
} from '../../../../../shared/translations';

import RightContentUser from '../../Right';

import {
  ContentCenterFlex,
  ContentLeft,
  ContentRowWrapperAuto,
  WrapperItemCommentContent,
  CommentsCountContainer,
  CommentsCountIcon,
  CommentsCountText,
  CommentsCountReviewText,
  CommentsButtonContainer,
  CommentListFlat,
} from './styled';



export interface IProps {
  showActions?: boolean;
  modele?: any;
  suggestions?: Array<any>;
}



const TabCommentsScreen: React.FC<IProps> = (props: IProps) => {
  const modeleReceived = props.modele;
  const suggestionsArray = props.suggestions;
  const showActions = props.showActions;


  const history = useNavigate();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const genderColorChoose = useSelector((state: RootState) => state.choose.genderColorApp);
  const accentGender = genderColorChoose;

  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;

  const commentsList = modeleReceived?.appReviewsReceived?.length > 0 ? modeleReceived?.appReviewsReceived : [];
  const commentsMediaList = modeleReceived?.appReviewsAverage;



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function renderItem(item: any, index: number) {
    return (

      <CardComment
        key={index}
        alias={item?.userMake?.alias}
        plan={item?.userMake?.plan?.planCurrent}
        image={item?.userMake?.picture}
        createdAt={item?.createdAt}
        rating={item?.rating}
        dateMeet={item?.date}
        comment={item?.comment}
        meet={item?.meet}
        ambience={item?.ambience}
        medias={item?.medias}
        recommend={item?.recommend}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noComments}
        title={translate(TranslateConfig.EMPTY_COMMENTS)}
        description={availableUser && user?.type === EUserType.USER
          ? translate(TranslateConfig.EMPTY_COMMENTS_TEXT)
          : ''
        }
      />

    );
  }



  return (

    <ContentRowWrapperAuto>

      <ContentLeft>

        <WrapperItemCommentContent>

          <CommentsCountContainer>
            <CommentsCountIcon>
              <IconStar
                color={accentGender}
                size={36}
              />
            </CommentsCountIcon>


            <CommentsCountText>
              {commentsMediaList}
            </CommentsCountText>
          </CommentsCountContainer>


          <CommentsCountReviewText>
            {commentsList?.length <= 0
              ? translate(TranslateConfig.EMPTY_DASH_COMMENT)
              : commentsList?.length === 1
                ? `1 ${translate(TranslateConfig.COMMENT)}`
                : `${commentsList?.length} ${translate(TranslateConfig.COMMENTS)}`
            }
          </CommentsCountReviewText>


          {!showActions && (
            <CommentsButtonContainer>
              <ButtonAction
                title={translate(TranslateConfig.ACTION_COMMENT_MAKE)}
                backgroundColor={(!availableUser || user?.type === EUserType.MODELE) ? Colors.disabled : accentGender}
                onPress={() => {
                  if (availableUser) {
                    if (user?.type === EUserType.MODELE) {
                      showSnackBarProps(Colors.attention, translate(TranslateConfig.HELP_COMMENT_ONLY_USER));
                    }
                    else {
                      history(NameRoutes.DoCommentModeleScreen.replace(
                        ':id',
                        modeleReceived?.useralias,
                      ));
                    }
                  }
                  else {
                    history(NameRoutes.AccessScreen);
                  }
                }}
              />
            </CommentsButtonContainer>
          )}

        </WrapperItemCommentContent>

      </ContentLeft>



      <ContentCenterFlex>

        <SectionTitle
          title={translate(TranslateConfig.COMMENTS)}
        />


        <CommentListFlat>
          {commentsList?.length <= 0 && listEmptyComponent()}

          {commentsList?.length > 0 && commentsList?.map((comment: any, index: number) => (
            renderItem(comment, index)
          ))}
        </CommentListFlat>

      </ContentCenterFlex>



      <RightContentUser
        showActions={showActions}
        modele={modeleReceived}
        suggestions={suggestionsArray}
      />

    </ContentRowWrapperAuto>

  );
};



export default TabCommentsScreen;
