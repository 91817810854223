import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  GetMediasLikedUser,
} from '../../../apis/endpoints/users-medias-liked.endpoints';

import CardAdsSuggestion from '../../../components/Ads';
import BadgeApp from '../../../components/Badges/BadgeApp';
import CardPhoto from '../../../components/Cards/CardMedia/CardPhoto';
import ChipApp from '../../../components/Chip';

import {
  Content,
  Screen,
  Title,
} from '../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../components/Controls';

import EmptyContent from '../../../components/Empty';
import FooterApp from '../../../components/Footers/FooterApp';
import HeaderScreen from '../../../components/Headers/HeaderScreen';

import {
  IconMediaComplete,
  IconMediaPhoto,
  IconMediaVideo,
} from '../../../components/Icons';

import LoadingScreen from '../../../components/Loadings/LoadingScreen';

import {
  RootState,
} from '../../../redux';

import {
  APP_SNACKBAR_ACTION,
} from '../../../redux/reducers/app-modals.store';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import {
  TranslateConfig,
} from '../../../shared/translations';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../shared/utils/errors.utils';

import {
  MaskNotificationsBadge,
} from '../../../shared/utils/masks.utils';

import {
  ContentWrapperRowFull,
  ContentCenterFlex,
  MediaHeaderPadderTop,
  MediaPhotoGridView,
} from './styled';



const MediaLikedScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;


  const accentGender = Colors.primaryDark;

  const [photosArray, setPhotosArray] = useState<Array<any>>([]);

  const [filteredPhotos, setFilteredPhotos] = useState<any[]>([]);
  const [typeImage, setTypeImage] = useState<any | ''>('');

  const [loading, setLoading] = useState<boolean>(false);


  const photosOptions = [
    {
      visible: true,
      title: translate(TranslateConfig.ALL),
      typeImage: '',
      icon: (
        <IconMediaComplete
          color={Colors.white}
          size={18}
        />
      ),
      badgeCount: photosArray?.length,
    },
    {
      visible: true,
      title: translate(TranslateConfig.PICTURES),
      typeImage: 'PHOTOS',
      icon: (
        <IconMediaPhoto
          color={Colors.white}
          size={18}
        />
      ),
      badgeCount: photosArray?.filter((media: any) => media?.type === 'PHOTOS')?.length,
    },
    {
      visible: true,
      title: translate(TranslateConfig.VIDEOS),
      typeImage: 'VIDEOS',
      icon: (
        <IconMediaVideo
          color={Colors.white}
          size={18}
        />
      ),
      badgeCount: photosArray?.filter((media: any) => media?.type === 'VIDEOS')?.length,
    },
  ];



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  const getLikedMediasUser = async () => {
    try {
      setLoading(true);

      const response = await GetMediasLikedUser(availableUser);
      const responseData = response?.data;

      if (!responseData) {
        console.error('Error', response);
        setPhotosArray([]);
        showSnackBarProps(Colors.danger, translate(TranslateConfig.EMPTY_HOME));
        return;
      }
      if (Array.isArray(responseData)) {
        setPhotosArray(responseData.map((e) => e?.media));
      }
      else {
        setPhotosArray([]);
      }
    }
    catch (error: any) {
      console.error(error);

      setPhotosArray([]);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
    }
  };


  function renderChipChooses(item: any, index: number) {
    if (!item?.visible) {
      return;
    }


    return (

      <ChipApp
        key={index}
        title={item?.title}
        selected={typeImage === item?.typeImage}
        selectedBackgroundColor={accentGender}
        thumb={item?.icon}
        icon={
          <BadgeApp
            text={MaskNotificationsBadge(item?.badgeCount)}
            backgroundColor={Colors.black}
            color={Colors.white}
          />
        }
        onPress={() => {
          setTypeImage(item?.typeImage);
        }}
      />

    );
  }


  function renderItem(item: any, index: number) {
    return (

      <CardPhoto
        key={index}
        image={item?.picture}
        visibility={item?.visibility}
        canBuy={item?.canBuy}
        onPress={() => {
          // const itemToSender: IViewMediaModal = {
          //   indexSelected: index,
          //   type: 'PHOTOS',
          // };

          // if (props.onShowMedia) {
          //   props.onShowMedia(itemToSender);
          // }
        }}
      />

    );
  }


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.white}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noFeedMediaLike}
        title={translate(TranslateConfig.EMPTY_ITEMS)}
      />

    );
  }



  useEffect(() => {
    if (typeImage === '' || !typeImage) {
      console.log('photosArray', photosArray);
      setFilteredPhotos(photosArray);
    }
    else {
      console.log(typeImage, 'typeImage');
      const filtered = photosArray?.filter((media) => media?.type === typeImage);
      setFilteredPhotos(filtered);
    }
  }, [typeImage, photosArray]);



  useEffect(() => {
    getLikedMediasUser();
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet prioritizeSeoTags>
        <title>
          {`${translate(translate(TranslateConfig.MEDIAS_LIKED))} | ${translate(TranslateConfig.NAME_APP)}`}
        </title>

        <meta name="robots" content="noindex, nofollow" />
      </Helmet>



      <HeaderScreen
        disableGender
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            color={Colors.white}
            size={27}
          />
        }
        centerContent={
          <Title>
            {translate(TranslateConfig.MEDIAS_LIKED)}
          </Title>
        }
      />



      <Content>

        <ContentWrapperRowFull>

          <ContentCenterFlex>

            <MediaHeaderPadderTop>
              {photosOptions.map((chip, index) => (
                renderChipChooses(chip, index)
              ))}
            </MediaHeaderPadderTop>



            <MediaPhotoGridView
              noScroll
              loading={loading}
              data={filteredPhotos}
              renderItem={renderItem}
              listLoadingComponent={renderLoading()}
              listEmptyComponent={listEmptyComponent()}
            />

          </ContentCenterFlex>



          <CardAdsSuggestion />

        </ContentWrapperRowFull>



        <FooterApp />

      </Content>

    </Screen>

  );
};



export default MediaLikedScreen;
