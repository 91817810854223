import React from 'react';

import {
  Images,
} from '../../../shared/constants';

import {
  TipÁmountContainer,
  TipImage,
  TipMessageView,
  TipMessageText,
} from './styled';



const MessageTipAmount: React.FC = () => {
  return (

    <TipÁmountContainer>
      <TipImage
        alt={'tip-image'}
        src={Images.gencoin}
      />

      <TipMessageView>
        <TipMessageText>
          {'Anonymous customer'} has tipped 25 credits!
        </TipMessageText>
      </TipMessageView>
    </TipÁmountContainer>

  );
};



export default MessageTipAmount;
