export const interestArray = [
  { id: 0, label: 'Anal', value: 'ANAL' },
  { id: 1, label: 'Axilas', value: 'ARMPITS' },
  { id: 2, label: 'Ursos', value: 'BEARS' },
  { id: 3, label: 'Bondage', value: 'BONDAGE' },
  { id: 4, label: 'Gozo', value: 'CUM' },
  { id: 5, label: 'Daddy', value: 'DADDY' },
  // { id: 6, label: 'Pés', value: 'FEET' },
  { id: 7, label: 'Fisting', value: 'FISTING' },
  // { id: 8, label: 'Prepúcio', value: 'FORESKIN' },
  { id: 9, label: 'Grupos', value: 'GROUPS' },
  // { id: 10, label: 'Pelos no Corpo', value: 'HAIR' },
  { id: 11, label: 'Jocks', value: 'JOCKS' },
  { id: 12, label: 'Beijos', value: 'KISSING' },
  { id: 13, label: 'Látex', value: 'LATEX' },
  { id: 14, label: 'Couro', value: 'LEATHER' },
  // { id: 15, label: 'Músculos', value: 'MUSCLE' },
  { id: 16, label: 'Mamilos', value: 'NIPPLES' },
  { id: 17, label: 'Oral', value: 'ORAL' },
  // { id: 18, label: 'Piercing', value: 'PIERCING' },
  { id: 19, label: 'PNP', value: 'PNP' },
  { id: 20, label: 'Rimming', value: 'RIMMING' },
  { id: 21, label: 'Role Play', value: 'ROLEPLAY' },
  { id: 22, label: 'Barbear', value: 'SHAVING' },
  { id: 23, label: 'S & M', value: 'S&M' },
  // { id: 24, label: 'Fumar', value: 'SMOKING' },
  { id: 25, label: 'Meias', value: 'SOCKS' },
  { id: 26, label: 'Spanking', value: 'SPANKING' },
  // { id: 27, label: 'Tatuagens', value: 'TATTOOS' },
  { id: 28, label: 'Cócegas', value: 'TICKLING' },
  { id: 29, label: 'Brinquedos', value: 'TOYS' },
  // { id: 30, label: 'Twinks', value: 'TWINKS' },
  { id: 31, label: 'Verbal', value: 'VERBAL' },
  { id: 32, label: 'Luta Livre', value: 'WRESTLING' },
  { id: 33, label: 'WS', value: 'WS' },
];
