import React from 'react';

import {
  RenderRibbonImage,
} from '../../../config/plan.config';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import {
  IconVerifiedPromoter,
} from '../../Icons';

import {
  ModeleBoostContainer,
  ModeleBoostLogoImage,
  ModeleBoostWrapperContainer,
  ModeleBoostImageContent,
  ModeleBoostImage,
  ModeleBoostRibbonImage,
  ModeleBoostInfoContent,
  ModeleBoostTitleContent,
  ModeleBoostTitle,
  ModeleBoostSubtitleContent,
  ModeleBoostOnlineBadge,
  ModeleBoostSubtitle,
  ModeleBoostVerifiedIcon,
} from './styled';



export interface IProps {
  modele: any;
  onPress?: any;
};



const CardModeleBoost: React.FC<IProps> = (props) => {
  const modeleBoostReceived = props.modele;



  return (

    <ModeleBoostContainer
      onClick={props.onPress}>

      <ModeleBoostLogoImage
        alt={'boost'}
        src={Images.boostLogo}
      />


      <ModeleBoostWrapperContainer>

        <ModeleBoostImageContent>
          <ModeleBoostImage
            src={
              modeleBoostReceived.cover === '' || modeleBoostReceived.cover === null
                ? Images.avatarUserImage
                : modeleBoostReceived.cover
            }
          />


          <ModeleBoostRibbonImage
            src={RenderRibbonImage(modeleBoostReceived?.plan?.planCurrent)}
          />
        </ModeleBoostImageContent>



        <ModeleBoostInfoContent>

          <ModeleBoostTitleContent>
            {!modeleBoostReceived.available && (
              <ModeleBoostOnlineBadge />
            )}

            <ModeleBoostTitle>
              {modeleBoostReceived.alias}
            </ModeleBoostTitle>
          </ModeleBoostTitleContent>


          <ModeleBoostSubtitleContent>
            <ModeleBoostSubtitle>
              @{modeleBoostReceived.useralias}
            </ModeleBoostSubtitle>

            {modeleBoostReceived.verified && (
              <ModeleBoostVerifiedIcon>
                <IconVerifiedPromoter
                  color={Colors.white}
                  size={14}
                />
              </ModeleBoostVerifiedIcon>
            )}
          </ModeleBoostSubtitleContent>

        </ModeleBoostInfoContent>

      </ModeleBoostWrapperContainer>

    </ModeleBoostContainer>

  );
};



export default CardModeleBoost;
