import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

const paddingLeft = 20;
const paddingRight = 20;


export interface IProps {
  choosed?: boolean;
  borderColor?: string;
}



export const CardProButton = styled.a`
  display: flex;
  flex-direction: column;
  margin-top: 7px;
  margin-bottom: 7px;
  background-color: ${Colors.white};
  border-radius: ${Sizes.cardRadius}px;

  border-color: ${(props: IProps) => props.borderColor || Colors.transparent};
  border-style: solid;
  border-width: 3px;

  elevation: 4;

  shadow-offset: 4px 4px;
  shadow-color: gray;
  shadow-radius: 4px;
  shadow-opacity: 0.5;
`;



export const CardProHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 8px;
  padding-left: ${paddingLeft}px;
  padding-right: ${paddingRight}px;
  padding-bottom: 8px;
`;

export const CardProInfoHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardProTitle = styled.p`
  color: ${(props: IProps) => props.choosed ? Colors.white : Colors.black};
  font-size: 21px;
  font-weight: bold;
`;

export const PriceText = styled.p`
  margin-top: 3px;
  color: ${(props: IProps) => props.choosed ? Colors.white : Colors.black};
  font-size: 16px;
`;

export const UnityText = styled.p`
  margin-top: 3px;
  color: ${(props: IProps) => props.choosed ? Colors.white : Colors.black};
  font-size: 13px;
  font-style: italic;
`;

export const ItemQuantityView = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
`;

export const AtualPlanIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  background-color: ${Colors.primary};
  border-radius: 50px;
`;


export const CardProBody = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  padding-left: ${paddingLeft}px;
  padding-right: ${paddingRight}px;
  padding-bottom: 8px;
`;

export const DescriptionPlanText = styled.p`
  color: ${Colors.black};
  font-size: 15px;
`;
