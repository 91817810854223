import React from 'react';

import ButtonAction from '../../../../components/Buttons/ButtonAction';

import {
  IconAds,
} from '../../../../components/Icons';

import {
  Colors,
} from '../../../../shared/constants';

import {
  RentManOfTheDayContainer,
  RentManOfTheDayPreview,
  RentManOfTheDayImageExample,
  RentManOfTheDayInfo,
  RentManDayHeader,
  RentManDayTitle,
  RentManDayrulContees,
  RentManDayrules,
  RentManDayApartirrules,
  RentManDaysize,
  RentMandescription,
} from './styled';



const TabsModeleBoost: React.FC = () => {
  return (

    <RentManOfTheDayContainer>

      <RentManOfTheDayPreview>

        <RentManOfTheDayImageExample
        />

        {/* <AvrtExampleHeader/>
                
                
                <Avrtuserexample>
                  <Avrtuserfooter>
                    <Avrtuserflabel>RentMan Of The Day</Avrtuserflabel>
                    <Avrtuseisflexel>
                      <span className="jsx-2512053135 username"><span className="jsx-2512053135 text-capitalize">miguelsims</span> • 20</span><span className="jsx-2512053135 location">Sao Paulo, Brazil</span>
                    </Avrtuseisflexel>
                  </Avrtuserfooter>
                </Avrtuserexample> */}

      </RentManOfTheDayPreview>



      <RentManOfTheDayInfo>

        <RentManDayHeader>

          <i className="icon-font icon-calendar-box-star-solid title-icon"></i>

          <RentManDayTitle>
            Secretio Boost
          </RentManDayTitle>

        </RentManDayHeader>


        <RentManDayrulContees>

          <RentManDayrules>
            Impulsione seu perfil para ser exibido acima dos perfis Gold e Basic nos resultados de pesquisa, independentemente do seu plano de associação.
          </RentManDayrules>

          <RentManDayrules>
            Mostre seu perfil na seção Secretio Boost na página inicial!
          </RentManDayrules>

          <RentManDayrules>
            Embora você possa não ser versátil, versatilidade é exatamente o que oferecemos a você com uma ampla variedade de períodos de tempo para escolher para que seu Secretio Boost melhor atenda às suas necessidades.
          </RentManDayrules>

        </RentManDayrulContees>


        <RentManDayApartirrules>
          A partir de
        </RentManDayApartirrules>

        <RentManDaysize>
          US$1.00 por hora
        </RentManDaysize>


        <RentMandescription>
          O pedido mínimo é de 12 horas
        </RentMandescription>


        <ButtonAction
          iconLeft={
            <IconAds
              color={Colors.white}
            />
          }
          title={'Selecionar'}
        />

      </RentManOfTheDayInfo>

    </RentManOfTheDayContainer>

  );
};



export default TabsModeleBoost;
