export const reviewAmbienceArray = [
  {
    id: 1,
    label: 'Muito relaxante',
    value: 'VERY_RELAXING',
  },
  {
    id: 2,
    label: 'Relaxante',
    value: 'RELAXING',
  },
  {
    id: 3,
    label: 'Um pouco relaxante',
    value: 'A_BIT_RELAXING',
  },
  {
    id: 4,
    label: 'Não relaxante',
    value: 'NOT_RELAXING',
  },
  {
    id: 5,
    label: 'Não aplicável (chamada externa)',
    value: 'NOT_APPLICABLE_EXTERNAL_CALL',
  },
];
