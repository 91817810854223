import styled,
{
  css,
} from 'styled-components';

import {
  ListFlat,
} from '../../../components/Composh/web';

import {
  ContentPadder,
} from '../../../components/Contents/ContentPadder';

import SectionTitle from '../../../components/Titles/SectionTitle';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';



export interface IProps {
  noMarginBottom?: boolean;
  backgroundColor?: string;
}



export const RentContentPadder = styled(ContentPadder)`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-left: ${Sizes.paddingContainer - 5}px;

  @media ${PlatformDevices.maxLaptop} {
    padding-left: ${Sizes.paddingFlatList - 5}px;
    padding-right: ${Sizes.paddingFlatList - 5}px;
  }
`;

export const RentViewContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;



export const RentScreenHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;
  padding-left: 5px;
  padding-right: 5px;

  @media ${PlatformDevices.maxTablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const SectionTitleStyled = styled(SectionTitle)`
  margin-bottom: 0;
`;



export const MessageRentHeader = styled.div`
  display: flex;
  flex-direction: row;

  @media ${PlatformDevices.maxTablet} {
    justify-content: space-between;
    width: 100%;
    margin-top: 15px;
    margin-left: 0;
  }

  @media ${PlatformDevices.minTablet} {
    width: auto;
    margin-left: 10px;
  }
`;

export const RentWrapperActionsView = styled.div`
  display: flex;
  flex-direction: row;
`;

export const RentWrapSortableButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: ${Sizes.buttonHeight}px;
  margin-left: 5px;
  margin-right: 5px;
`;



export const HowRentButtonAction = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: ${Sizes.buttonHeight}px;
  margin-left: 10px;
  padding-left: 14px;
  padding-right: 14px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;

export const HowRentButtonText = styled.p`
  margin-left: 8px;
  color: ${Colors.white};
  font-size: 14px;
  text-align: center;

  @media ${PlatformDevices.maxTablet} {
    display: none;
  }
`;



export const SectionLevelTitleStyled = styled(SectionTitle)`
  margin-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
`;

export const RentItemView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RentItemTitle = styled.p`
  margin-right: 4px;
  color: ${Colors.white};
  font-weight: 500;
  font-size: 22px;
`;

export const RentImageInfiniteContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 64px;
  height: 25px;
  margin-left: 3px;
  margin-right: 3px;
  padding-bottom: 3px;
`;

export const RentInfiniteImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const RentImageLevelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  max-height: 24px;
  aspect-ratio: 1 / 1;
  margin-left: 3px;
  margin-right: 3px;
`;

export const RentItemImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const HomeHighlightsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 35px;

  @media ${PlatformDevices.maxTabletL} {
    flex-direction: column;
    gap: 20px;
  }
`;



export const ModeleItemGrid = styled(ListFlat).attrs({
  contentContainerStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
})`
  display: flex;
  flex-direction: column;
  flex: 1;
`;



export const RentMarginBottom = css`
  margin-bottom: 40px;
`;

export const RentItemContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${(props: IProps) => !props.noMarginBottom && RentMarginBottom}
`;
