enum ERoomType {
  BASIC = 'BASIC',
  BREAK = 'BREAK',
  PARTY_AUCTION = 'PARTY_AUCTION',
  PARTY_QUESTION = 'PARTY_QUESTION',
  PREGROUP = 'PREGROUP',
  PARTY = 'PARTY',
  GROUP = 'GROUP',
  PRIVATE = 'PRIVATE',
  EXCLUSIVE = 'EXCLUSIVE',
  OFFLINE = 'OFFLINE',
  PROMO = 'PROMO',
};



export default ERoomType;
