import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import ButtonAction from '../../../../../../../components/Buttons/ButtonAction';

import { RootState } from '../../../../../../../redux';

import { LINKBIO_ADD_ITEM, LINKBIO_DELETE_ITEM, LINKBIO_UPDATE_ITEM } from '../../../../../../../redux/reducers/linkbio.store';

import ItemCard, { Editable } from '../../../../components/ItemCard';

import {
  ActionsBox,
  ItemsGrid,
} from './styled';



const TabLinks: React.FC = () => {
  const dispatch = useDispatch();


  const items = useSelector((state: RootState) => state.linkbio.items);



  const handleCreateItem = () =>
    dispatch({
      type: LINKBIO_ADD_ITEM,
      payload: { type: 'network' },
    });

  const handleChangeEditable =
    (idx: number) => (ev: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = ev.target;
      dispatch({
        type: LINKBIO_UPDATE_ITEM,
        payload: { index: idx, data: { [name]: value } },
      });
    };


  const handleChangeEnabled = (idx: number, isEnabled: boolean) =>
    dispatch({
      type: LINKBIO_UPDATE_ITEM,
      payload: { index: idx, data: { enabled: isEnabled } },
    });

  function removeItem(idx) {
    dispatch({
      type: LINKBIO_DELETE_ITEM,
      payload: { index: idx },
    });
  }



  return (

    <div>

      <ActionsBox>
        <ButtonAction
          title={'Adicionar novo link'}
          onPress={() => {
            handleCreateItem();
          }}
        />

        {/* <IconButton colorScheme='primary' disabled>
          <IconThunder color={Colors.white} size={24} />
        </IconButton> */}
      </ActionsBox>



      <ItemsGrid>
        {items.map(({ id, props }, idx) => (
          <li key={id}>
            <ItemCard
              centerAxisY
              isEnabled={props.enabled}
              onEnable={(isEnabled) => handleChangeEnabled(idx, isEnabled)}
              onRequestRemove={() =>
                removeItem(idx)
              }
            >
              <Editable
                placeholder='Titulo'
                value={props.title}
                onChange={handleChangeEditable(idx)}
                name='title'
              />
              <Editable
                placeholder='url'
                value={props.url}
                onChange={handleChangeEditable(idx)}
                name='url'
              />
            </ItemCard>
          </li>
        ))}
      </ItemsGrid>
    </div>

  );
};



export default TabLinks;
