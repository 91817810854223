import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
} from 'react-redux';

import ButtonPhone from '../../../../../components/Buttons/ButtonPhone';
import ChipApp from '../../../../../components/Chip';
import ItemDescription from '../../../../../components/Details/ItemDescription';
import RentServices from '../../../../../components/Details/RentServices';

import {
  IconInformation,
} from '../../../../../components/Icons';

import SectionTitle from '../../../../../components/Titles/SectionTitle';
import ToggleApp from '../../../../../components/Toggles/Toggle';

import {
  returnIconHotel,
  returnIconMotel,
  returnIconMyLocation,
  returnIconTravel,
  returnIconYourLocation,
} from '../../../../../config/app-icons-bool.config';

import {
  returnIconGenre,
} from '../../../../../config/genre-icon.config';

import {
  FormatMoney,
} from '../../../../../config/mask.config';

import {
  RootState,
} from '../../../../../redux';

import {
  attendanceModele,
} from '../../../../../shared/arrays/Rent/modele-attendance.array';

import {
  availableModele,
} from '../../../../../shared/arrays/Rent/modele-available.array';

import {
  safeSexArray,
} from '../../../../../shared/arrays/User/user-safe-sex-options.array';

import {
  Colors,
  Images,
} from '../../../../../shared/constants';

import EPaymentType from '../../../../../shared/enums/Financial/payment-types.enum';

import {
  TranslateConfig,
} from '../../../../../shared/translations';

import RightContentUser from '../../Right';

import {
  CharContentCenterRowFlex,
  RentContentCenterFlex,
  RentWrapperFirstContent,
  RentWrapperSecondContent,
  RentHoursContainer,
  RentHoursImage,
  RentHoursText,
  RentTipsContainer,
  RentTipsHeader,
  RentInfoServiceButton,
  RentServicesContainer,
  ToggleModeleMeet,
  RentPriceContainer,
  RentNoPricesText,
  RentPriceValueContent,
  RentPriceValueItem,
  RentPricePeriodText,
  RentPriceValueText,
  RentPaymentsContainer,
  RentPaymentsText,
  RentPaymentsContent,
  RentPaymentsImage,
} from './styled';



export interface IProps {
  showActions?: boolean;
  modele?: any;
  suggestions?: Array<any>;
  onServiceModalPress?: any;
}



const TabRentScreen: React.FC<IProps> = (props: IProps) => {
  const modeleReceived = props.modele;
  const suggestionsArray = props.suggestions;
  const showActions = props.showActions;

  const phoneAvaillable = modeleReceived?.contacts?.showPhoneNumber
    && modeleReceived?.contacts?.phoneDdi
    && modeleReceived?.contacts?.phoneNumber;

  const pricesAvailable = modeleReceived?.appRentPrices?.price15min
    || modeleReceived?.appRentPrices?.price30min
    || modeleReceived?.appRentPrices?.price1hour
    || modeleReceived?.appRentPrices?.price2hour
    || modeleReceived?.appRentPrices?.price4hour
    || modeleReceived?.appRentPrices?.priceOvernight
    || modeleReceived?.appRentPrices?.priceDaily
    || modeleReceived?.appRentPrices?.priceDailyTravel
    || modeleReceived?.appRentPrices?.priceWeekly
    || modeleReceived?.appRentPrices?.priceWeeklyTravel
    || modeleReceived?.appRentPrices?.priceMonthly
    || modeleReceived?.appRentPrices?.priceMonthlyTravel;

  const charIconsSize = 26;


  const { t: translate } = useTranslation();


  const rentServicesArray = useSelector((state: RootState) => state.modeles.modelesRentServices);


  const [genderMeet, setGenderMeet] = useState<Array<any>>([]);
  const [availableTo, setAvailableTo] = useState<Array<any>>([]);
  const [sexCondom, setSexCondom] = useState<any>(null);

  const [serviceDoModele, setServiceDoModele] = useState<Array<any>>([]);
  const [serviceDontModele, setServiceDontModele] = useState<Array<any>>([]);

  const [forms, setForms] = useState<Array<any>>([]);
  const [debitCards, setDebitCards] = useState<Array<any>>([]);
  const [creditCards, setCreditCards] = useState<Array<any>>([]);



  const paymentsMap = {
    [EPaymentType.CASH]: {
      name: translate(TranslateConfig.CASH),
      image: 'flagTypeCash',
    },
    [EPaymentType.CHEQUE]: {
      name: translate(TranslateConfig.BANK_CHECK),
      image: 'flagTypeCheque',
    },
    [EPaymentType.PIX]: {
      name: translate(TranslateConfig.PIX),
      image: 'flagTypePix',
    },
    [EPaymentType.DEBIT_ELO]: {
      name: translate(TranslateConfig.ELO),
      image: 'flagCardElo',
    },
    [EPaymentType.DEBIT_MASTERCARD]: {
      name: translate(TranslateConfig.MASTERCARD),
      image: 'flagCardMastercard',
    },
    [EPaymentType.DEBIT_VISA]: {
      name: translate(TranslateConfig.VISA),
      image: 'flagCardVisa',
    },
    [EPaymentType.CREDIT_AMERICAN]: {
      name: translate(TranslateConfig.AMERICAN),
      image: 'flagCardAmerican',
    },
    [EPaymentType.CREDIT_DINERS]: {
      name: translate(TranslateConfig.DINERS),
      image: 'flagCardDiners',
    },
    [EPaymentType.CREDIT_ELO]: {
      name: translate(TranslateConfig.ELO),
      image: 'flagCardElo',
    },
    [EPaymentType.CREDIT_MASTERCARD]: {
      name: translate(TranslateConfig.MASTERCARD),
      image: 'flagCardMastercard',
    },
    [EPaymentType.CREDIT_VISA]: {
      name: translate(TranslateConfig.VISA),
      image: 'flagCardVisa',
    },
  };



  function setServicesForms() {
    if (modeleReceived?.appRent?.genderMeet) {
      const splitItem: Array<string> = modeleReceived?.appRent?.genderMeet?.split(', ');
      const filteredItem = splitItem?.map((value) => {
        return attendanceModele.find((item) => item?.value === value);
      });

      setGenderMeet(filteredItem);
    }


    if (modeleReceived?.appRent?.availableTo) {
      const splitItem: Array<string> = modeleReceived?.appRent?.availableTo?.split(', ');
      const filteredItem = splitItem?.map((value) => {
        return availableModele.find((item) => item?.value === value);
      });

      setAvailableTo(filteredItem);
    }

    if (modeleReceived?.appRent?.sexCondom) {
      const splitItem = safeSexArray.find((item) => item?.value === modeleReceived?.appRent?.sexCondom);
      setSexCondom(splitItem);
    }
  }


  function setPaymentForms() {
    const forms: any = [];
    const debs: any = [];
    const creds: any = [];

    if (modeleReceived?.appRentPrices?.paymentTypes) {
      const splitItem = modeleReceived.appRentPrices.paymentTypes.split(', ');

      splitItem.forEach((paymentType: string) => {
        switch (paymentType) {
          case EPaymentType.CASH:
            forms.push(EPaymentType.CASH);
            break;

          case EPaymentType.CHEQUE:
            forms.push(EPaymentType.CHEQUE);
            break;

          case EPaymentType.PIX:
            forms.push(EPaymentType.PIX);
            break;

          case EPaymentType.DEBIT_ELO:
            debs.push(EPaymentType.DEBIT_ELO);
            break;

          case EPaymentType.DEBIT_MASTERCARD:
            debs.push(EPaymentType.DEBIT_MASTERCARD);
            break;

          case EPaymentType.DEBIT_VISA:
            debs.push(EPaymentType.DEBIT_VISA);
            break;

          case EPaymentType.CREDIT_AMERICAN:
            creds.push(EPaymentType.CREDIT_AMERICAN);
            break;

          case EPaymentType.CREDIT_DINERS:
            creds.push(EPaymentType.CREDIT_DINERS);
            break;

          case EPaymentType.CREDIT_ELO:
            creds.push(EPaymentType.CREDIT_ELO);
            break;

          case EPaymentType.CREDIT_MASTERCARD:
            creds.push(EPaymentType.CREDIT_MASTERCARD);
            break;

          case EPaymentType.CREDIT_VISA:
            creds.push(EPaymentType.CREDIT_VISA);
            break;
        }
      });
    }

    setForms(forms);
    setDebitCards(debs);
    setCreditCards(creds);
  }


  function setDoAndUndo() {
    // Filtrar os serviços com doItem true
    const doItems = rentServicesArray?.filter((service) =>
      modeleReceived?.appRentServices?.some((appService) =>
        appService.idRentServices === service.id && appService.doItem === true,
      ),
    );
    setServiceDoModele(doItems);

    // Filtrar os serviços com doItem false ou que não estão presentes em appRentServices
    const dontItems = rentServicesArray?.filter((service) =>
      !modeleReceived?.appRentServices?.some((appService) =>
        appService.idRentServices === service.id && appService.doItem === true,
      ),
    );
    setServiceDontModele(dontItems);
  }


  function renderAttendanceGenre(item: any, index: number) {
    return (

      <ToggleModeleMeet
        hideBorder
        key={index}
        backgroundColor={Colors.cardBackground}
        icon={returnIconGenre(
          item?.value,
          Colors.white,
          30,
        )}
        label={item?.label}
      />

    );
  }


  function renderChipAvailableTo(item: any, index: number) {
    return (

      <ChipApp
        hideIcon
        key={index}
        title={item?.label}
        backgroundColor={Colors.cardBackground}
        color={Colors.white}
      />

    );
  }


  function renderRentPlaceItem(icon: any, title: string, status: string) {
    return (

      <ItemDescription
        disabled
        icon={icon}
        title={title}
        text={status}
      />

    );
  }


  function renderPriceValueItem(title: any, value: string) {
    const formatValue = FormatMoney(value);


    return (

      <RentPriceValueItem>

        <RentPricePeriodText>
          {title}
        </RentPricePeriodText>


        <RentPriceValueText
          color={value ? Colors.white : Colors.disabled}>
          {value
            ? formatValue
            : translate(TranslateConfig.SERVICE_DONT_DO)
          }
        </RentPriceValueText>

      </RentPriceValueItem>

    );
  }


  function renderToggle(
    index: any,
    image: any,
    value: string,
    displayValue: string,
  ) {
    return (

      <ToggleApp
        key={index}
        noToggle
        selected
        iconContent={
          <RentPaymentsImage
            alt={'PaymentImage'}
            src={image}
          />
        }
        value={value}
        displayValue={displayValue}
        borderTintColor={Colors.toolbarLine}
        backgroundTintColor={Colors.cardBackground}
        textTintColor={Colors.white}
      />

    );
  }



  useEffect(() => {
    setServicesForms();
    setPaymentForms();
    setDoAndUndo();
  }, []);



  return (
    <CharContentCenterRowFlex>

      <RentContentCenterFlex>

        {phoneAvaillable && (
          <RentWrapperFirstContent>
            <SectionTitle
              title={translate(TranslateConfig.PHONE)}
            />


            <ButtonPhone
              flexDirection={'ROW'}
              idModele={modeleReceived?.id}
              comeNow={modeleReceived?.appRentHighlight?.length > 0}
              username={modeleReceived?.useralias}
              isWhatsApp={modeleReceived?.contacts?.phoneNumberWhatsapp}
              ddi={modeleReceived?.contacts?.phoneDdi}
              phone={modeleReceived?.contacts?.phoneNumber}
            />

          </RentWrapperFirstContent>
        )}



        {(genderMeet?.length > 0 || props.modele?.contacts?.phoneNumber24h) && (
          <RentWrapperFirstContent>
            <SectionTitle
              title={translate(TranslateConfig.ATTENDANCE)}
            />


            {genderMeet?.length > 0 && (
              <RentTipsContainer>
                {genderMeet.map((item: any, index: number) => (
                  renderAttendanceGenre(item, index)
                ))}
              </RentTipsContainer>
            )}



            {props.modele?.contacts?.phoneNumber24h && (
              <RentHoursContainer>
                <RentHoursImage
                  src={Images.badge24h}
                />

                <RentHoursText>
                  {translate(TranslateConfig.SERVICE_24H)}
                </RentHoursText>
              </RentHoursContainer>
            )}
          </RentWrapperFirstContent>
        )}



        {availableTo?.length > 0 && (
          <RentWrapperFirstContent>
            <SectionTitle
              title={translate(TranslateConfig.AVAILABLE_FOR)}
            />


            <RentTipsContainer>
              {availableTo.map((item: any, index: number) => (
                renderChipAvailableTo(item, index)
              ))}
            </RentTipsContainer>
          </RentWrapperFirstContent>
        )}



        <RentWrapperFirstContent>
          <SectionTitle
            title={translate(TranslateConfig.ATTENDANCE_PLACE)}
          />


          <RentTipsContainer>

            {renderRentPlaceItem(
              returnIconMyLocation(
                modeleReceived?.appRentPlaces?.myLocation,
                Colors.white,
                charIconsSize,
              ),
              translate(TranslateConfig.PLACE_MY),
              modeleReceived?.appRentPlaces?.myLocation
                ? translate(TranslateConfig.YES)
                : translate(TranslateConfig.NO),
            )}

            {renderRentPlaceItem(
              returnIconYourLocation(
                modeleReceived?.appRentPlaces?.yourLocation,
                Colors.white,
                charIconsSize,
              ),
              translate(TranslateConfig.PLACE_YOUR),
              modeleReceived?.appRentPlaces?.yourLocation
                ? translate(TranslateConfig.YES)
                : translate(TranslateConfig.NO),
            )}

            {renderRentPlaceItem(
              returnIconHotel(
                modeleReceived?.appRentPlaces?.hotel,
                Colors.white,
                charIconsSize,
              ),
              translate(TranslateConfig.HOTEL),
              modeleReceived?.appRentPlaces?.hotel
                ? translate(TranslateConfig.YES)
                : translate(TranslateConfig.NO),
            )}

            {renderRentPlaceItem(
              returnIconMotel(
                modeleReceived?.appRentPlaces?.motel,
                Colors.white,
                charIconsSize,
              ),
              translate(TranslateConfig.MOTEL),
              modeleReceived?.appRentPlaces?.motel
                ? translate(TranslateConfig.YES)
                : translate(TranslateConfig.NO),
            )}

            {renderRentPlaceItem(
              returnIconTravel(
                modeleReceived?.appRentPlaces?.travel,
                Colors.white,
                charIconsSize,
              ),
              translate(TranslateConfig.TRAVEL),
              modeleReceived?.appRentPlaces?.travel
                ? translate(TranslateConfig.YES)
                : translate(TranslateConfig.NO),
            )}

          </RentTipsContainer>
        </RentWrapperFirstContent>



        {(serviceDoModele?.length > 0 || serviceDontModele?.length > 0) && (
          <RentWrapperFirstContent>

            <RentTipsHeader>
              <SectionTitle
                title={translate(TranslateConfig.SERVICES)}
              />


              <RentInfoServiceButton
                onClick={() => {
                  props.onServiceModalPress();
                }}>
                <IconInformation
                  color={Colors.white}
                  size={20}
                />
              </RentInfoServiceButton>
            </RentTipsHeader>



            <RentServicesContainer>

              {serviceDoModele?.length > 0 && (
                <RentServices
                  title={translate(TranslateConfig.PARTNER_SERVICES)}
                  data={serviceDoModele}
                />
              )}


              {serviceDontModele?.length > 0 && (
                <RentServices
                  title={translate(TranslateConfig.PARTNER_SERVICES_NOT)}
                  data={serviceDontModele}
                />
              )}

            </RentServicesContainer>

          </RentWrapperFirstContent>
        )}



        {sexCondom && (
          <RentWrapperFirstContent>
            <SectionTitle
              title={translate(TranslateConfig.SEX_CONDOM)}
            />


            <RentTipsContainer>
              {renderChipAvailableTo(sexCondom, 0)}
            </RentTipsContainer>
          </RentWrapperFirstContent>
        )}



        <RentWrapperSecondContent>
          <SectionTitle
            title={translate(TranslateConfig.PAYMENT_AND_VALUES)}
          />


          {!pricesAvailable && (
            <RentPriceContainer>

              <RentNoPricesText>
                {translate(TranslateConfig.ASK_ME)}
              </RentNoPricesText>

            </RentPriceContainer>
          )}


          {pricesAvailable && (
            <RentPriceContainer>

              <RentPriceValueContent>

                {renderPriceValueItem(
                  `15 ${translate(TranslateConfig.MINUTES)}`,
                  modeleReceived?.appRentPrices?.price15min,
                )}

                {renderPriceValueItem(
                  `30 ${translate(TranslateConfig.MINUTES)}`,
                  modeleReceived?.appRentPrices?.price30min,
                )}

                {renderPriceValueItem(
                  `1 ${translate(TranslateConfig.HOUR)}`,
                  modeleReceived?.appRentPrices?.price1hour,
                )}

                {renderPriceValueItem(
                  `2 ${translate(TranslateConfig.HOURS)}`,
                  modeleReceived?.appRentPrices?.price2hour,
                )}

                {renderPriceValueItem(
                  `4 ${translate(TranslateConfig.HOURS)}`,
                  modeleReceived?.appRentPrices?.price4hour,
                )}

                {renderPriceValueItem(
                  `${translate(TranslateConfig.OVERNIGHT_STAY)} (12 ${translate(TranslateConfig.HOURS)})`,
                  modeleReceived?.appRentPrices?.priceOvernight,
                )}

                {renderPriceValueItem(
                  translate(TranslateConfig.DAILY),
                  modeleReceived?.appRentPrices?.priceDaily,
                )}

                {renderPriceValueItem(
                  translate(TranslateConfig.DAILY_TRAVEL),
                  modeleReceived?.appRentPrices?.priceDailyTravel,
                )}

                {renderPriceValueItem(
                  translate(TranslateConfig.WEEKLY),
                  modeleReceived?.appRentPrices?.priceWeekly,
                )}

                {renderPriceValueItem(
                  translate(TranslateConfig.WEEKLY_TRAVEL),
                  modeleReceived?.appRentPrices?.priceWeeklyTravel,
                )}

                {renderPriceValueItem(
                  translate(TranslateConfig.MONTHLY),
                  modeleReceived?.appRentPrices?.priceMonthly,
                )}

                {renderPriceValueItem(
                  translate(TranslateConfig.MONTHLY_TRAVEL),
                  modeleReceived?.appRentPrices?.priceMonthlyTravel,
                )}

              </RentPriceValueContent>


              <RentPriceValueContent>
                {forms.length !== 0 && (
                  <RentPaymentsContainer>
                    <RentPaymentsText>
                      {translate(TranslateConfig.FORMS)}:
                    </RentPaymentsText>


                    <RentPaymentsContent>
                      {forms.map((card: any, index: any) => (
                        renderToggle(
                          index,
                          Images[paymentsMap[card].image],
                          '',
                          paymentsMap[card].name,
                        )
                      ))}
                    </RentPaymentsContent>
                  </RentPaymentsContainer>
                )}


                {debitCards.length !== 0 && (
                  <RentPaymentsContainer>
                    <RentPaymentsText>
                      {translate(TranslateConfig.DEBIT_CARD)}:
                    </RentPaymentsText>


                    <RentPaymentsContent>
                      {debitCards.map((card: any, index: any) => (
                        renderToggle(
                          index,
                          Images[paymentsMap[card].image],
                          '',
                          paymentsMap[card].name,
                        )
                      ))}
                    </RentPaymentsContent>
                  </RentPaymentsContainer>
                )}


                {creditCards.length !== 0 && (
                  <RentPaymentsContainer>
                    <RentPaymentsText>
                      {translate(TranslateConfig.CREDIT_CARD)}:
                    </RentPaymentsText>


                    <RentPaymentsContent>
                      {creditCards.map((card: any, index: any) => (
                        renderToggle(
                          index,
                          Images[paymentsMap[card].image],
                          '',
                          paymentsMap[card].name,
                        )
                      ))}
                    </RentPaymentsContent>
                  </RentPaymentsContainer>
                )}
              </RentPriceValueContent>

            </RentPriceContainer>
          )}

        </RentWrapperSecondContent>

      </RentContentCenterFlex>



      <RightContentUser
        showActions={showActions}
        modele={modeleReceived}
        suggestions={suggestionsArray}
      />

    </CharContentCenterRowFlex>

  );
};



export default TabRentScreen;
