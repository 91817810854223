import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useNavigate,
  useLocation,
  useParams,
} from 'react-router-dom';

import {
  Formik,
  FormikProps,
} from 'formik';

import {
  GetModeleToReview,
  DoReviewModele,
} from '../../../apis/endpoints/reviews-user-do.endpoints';

import {
  UserEditReview,
} from '../../../apis/endpoints/reviews-user.endpoints';

import {
  IInputSelect,
} from '../../../apis/interfaces/App/input-select.interface';

import ButtonAction from '../../../components/Buttons/ButtonAction';

import {
  Body,
  Container,
  Content,
  Screen,
  SubTitle,
  Title,
} from '../../../components/Composh/web';

import {
  ContentButtonForm,
  ContentPadder,
} from '../../../components/Contents/ContentPadder';

import {
  ButtonBack,
} from '../../../components/Controls';

import FooterApp from '../../../components/Footers/FooterApp';
import HeaderScreen from '../../../components/Headers/HeaderScreen';

import {
  IconInformation,
  IconStar,
} from '../../../components/Icons';

import LoadingScreen from '../../../components/Loadings/LoadingScreen';
import Rating from '../../../components/Rating';
import SectionTitle from '../../../components/Titles/SectionTitle';

import DataSelectModal from '../../../modals/DataSelect';

import {
  RootState,
} from '../../../redux';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../redux/reducers/app-modals.store';

import {
  USER_GENDER_CHOOSE_ACTION,
} from '../../../redux/reducers/choose.store';

import { reviewAmbienceArray } from '../../../shared/arrays/Reviews/review-ambience.array';
import { reviewMediasArray } from '../../../shared/arrays/Reviews/review-medias.array';
import { reviewMeetArray } from '../../../shared/arrays/Reviews/review-meet.array';
import { reviewRecommendArray } from '../../../shared/arrays/Reviews/review-recommend.array';

import {
  Colors,
  Images,
  Sizes,
  Values,
} from '../../../shared/constants';

import {
  EGenderUser,
} from '../../../shared/enums';

import {
  TranslateConfig,
} from '../../../shared/translations';

import {
  FormatIsoDateWithPattern,
} from '../../../shared/utils/date.utils';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../shared/utils/errors.utils';

import {
  CardReviewForm,
  DoReviewHeader,
  DoReviewTitleHeader,
  DoReviewInfoHeader,
  DoReviewImageInfoHeader,
  DoReviewHeaderUserImage,
  DoReviewHeaderUserInfoView,
  DoReviewNameTitle,
  DoReviewNamePlaceTitle,
  DoReviewRateContainer,
  DoReviewRateHeader,
  InputFullSelectStyled,
  InputViewStyled,
  DoReviewRateStar,
  DoReviewRateRow,
  DoReviewRateCountIcon,
  DoReviewRateCountText,
  InputTextAreaStyled,
  InputPartSelectStyled,
  InputDateStyled,
} from './styled';



export interface IRouteParams {
  id: string;
}


export interface IRouteLocation {
  review?: any;
}



const DoCommentModeleScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { t: translate } = useTranslation();

  const route = useParams<keyof IRouteParams>();
  const paramsRoute = route as IRouteParams;

  const routeLocation = useLocation();
  const paramsLocation = routeLocation?.state as IRouteLocation;


  const genderChoose = useSelector((state: RootState) => state.choose.genderChoose);
  const genderColorChoose = useSelector((state: RootState) => state.choose.genderColorApp);
  const accentGender = genderColorChoose;

  const user = useSelector((state: RootState) => state.user.actualUser);


  const modeleId = paramsRoute?.id;
  const [modeleObject, setModeleObject] = useState<any>(null);

  const editForm: boolean = paramsLocation?.review;
  const [itemToEdit, setItemToEdit] = useState<any | any>(paramsLocation?.review);

  const formik = useRef<FormikProps<any>>(null);

  const [ratingValue, setRatingValue] = useState(0);

  const [selectedMeet, setSelectedMeet] = useState<IInputSelect | null>(null);
  const [modalMeet, setModalMeet] = useState<boolean>(false);

  const [selectedMedias, setSelectedMedias] = useState<IInputSelect | null>(null);
  const [modalMedias, setModalMedias] = useState<boolean>(false);

  const [selectedAmbience, setSelectedAmbience] = useState<IInputSelect | null>(null);
  const [modalAmbience, setModalAmbience] = useState<boolean>(false);

  const [selectedRecommend, setSelectedRecommend] = useState<IInputSelect | null>(null);
  const [modalRecommend, setModalRecommend] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [mounted, setMounted] = useState<boolean>(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function getSimpleModele() {
    try {
      setLoading(true);

      const response = await GetModeleToReview(modeleId);

      if (!response?.data) {
        setModeleObject(null);

        if (!genderChoose) {
          dispatch({ type: USER_GENDER_CHOOSE_ACTION, payload: EGenderUser.WOMAN });
        }

        return;
      }

      setModeleObject(response?.data);
      dispatch({ type: USER_GENDER_CHOOSE_ACTION, payload: response?.data?.gender });
    }
    catch (error: any) {
      console.error(error);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
    }
  }


  async function updateForm() {
    if (user && !modeleObject) {
      await getSimpleModele();
    }

    if (editForm && formik.current) {
      const findMeet = reviewMeetArray?.find((item) => item.value === itemToEdit?.meet);
      formik.current?.setFieldValue('meet', findMeet?.value);
      setSelectedMeet(findMeet || null);

      setRatingValue(itemToEdit?.rating);

      formik.current?.setFieldValue('comment', itemToEdit?.comment);
      formik.current?.setFieldValue('dateMeet', FormatIsoDateWithPattern(itemToEdit?.date, 'dd/MM/yyyy'));

      const findMedias = reviewMediasArray?.find((item) => item.value === itemToEdit?.medias);
      formik.current?.setFieldValue('medias', findMedias?.value);
      setSelectedMedias(findMedias || null);

      const findAmbiences = reviewAmbienceArray?.find((item) => item.value === itemToEdit?.ambience);
      formik.current?.setFieldValue('ambience', findAmbiences?.value);
      setSelectedAmbience(findAmbiences || null);

      const findRecommend = reviewRecommendArray?.find((item) => item.value === itemToEdit?.recommend);
      formik.current?.setFieldValue('recommend', findRecommend?.value);
      setSelectedRecommend(findRecommend || null);
    }

    setTimeout(() => {
      setMounted(true);
    }, Values.mountTime);
  }


  function mountPayload() {
    const values = formik?.current?.values;

    const dateStartSplit = String(values.dateMeet).split('/');
    const newStartDate = `${dateStartSplit[2]}-${dateStartSplit[1]}-${dateStartSplit[0]}T${'00:00'}:00.${'000Z'}`;

    const payload = {
      userMakeComment: user?.id,
      meet: values.meet,
      rating: ratingValue,
      comment: values.comment,
      date: newStartDate,
      medias: values.medias,
      ambience: values.ambience,
      recommend: values.recommend,
    };

    return payload;
  }


  async function createAvaliation() {
    const payload = mountPayload();

    const response = await DoReviewModele(modeleObject?.useralias, payload);

    if (!response?.data) {
      console.error('Error', response);
      showSnackBarProps(Colors.danger, translate(TranslateConfig.ERROR_COMMENT_CREATED));
      return;
    }

    showSnackBarProps(Colors.accept, translate(TranslateConfig.ERROR_COMMENT_CREATED));
  }


  async function editAvaliation() {
    const payload = mountPayload();

    const itemToSave = {
      ...payload,
      id: itemToEdit?.id,
    };

    setItemToEdit(itemToSave);

    const response = await UserEditReview(itemToEdit?.id, modeleObject?.useralias, itemToSave);

    if (!response?.data) {
      console.error('Error', response);
      showSnackBarProps(Colors.danger, translate(TranslateConfig.ERROR_COMMENT_EDITED));
      return;
    }

    showSnackBarProps(Colors.accept, translate(TranslateConfig.SUCCESS_COMMENT_EDITED));
  }


  async function saveItemGroup() {
    setLoading(true);
    dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

    try {
      if (editForm) {
        await editAvaliation();
      }
      else {
        await createAvaliation();
      }

      history(-1);
    }
    catch (error: any) {
      console.error(error);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }



  useEffect(() => {
    updateForm();
  }, [formik]);



  return (

    <Formik
      enableReinitialize
      validateOnMount={false}
      innerRef={formik}
      initialValues={{}}
      // validationSchema={CharactersProfileValidationSchema}
      onSubmit={() => {
        // KeyboardModal.dismiss();
        saveItemGroup();
      }}>
      {({
        // dirty,
        // isValid,
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        setFieldValue,
      }) => (

        <Screen
          backgroundColor={Colors.appBackground}>


          <Helmet prioritizeSeoTags>
            <title>
              {`${editForm
                ? translate(TranslateConfig.COMMENT_EDIT)
                : translate(TranslateConfig.COMMENT_DO)
                } | ${translate(TranslateConfig.NAME_APP)}`}
            </title>

            <meta name="robots" content="noindex, nofollow" />
          </Helmet>



          <Container>

            <HeaderScreen
              disableGender
              backgroundColor={Colors.toolbarBackground}
              subBackgroundColor={Colors.toolbarBackground}
              leftIcon={
                <ButtonBack
                  color={Colors.white}
                  size={27}
                />
              }
              centerContent={
                <Body>
                  <Title
                    color={Colors.white}>
                    {editForm
                      ? translate(TranslateConfig.COMMENT_EDIT)
                      : translate(TranslateConfig.COMMENT_DO)
                    }
                  </Title>

                  <SubTitle>
                    @{modeleObject?.useralias || '-'}
                  </SubTitle>
                </Body>
              }
            />



            <Content>

              {!mounted && (
                <LoadingScreen
                  type={'SCREEN'}
                  color={Colors.white}
                />
              )}



              {mounted && (
                <ContentPadder>

                  <CardReviewForm
                    backgroundColor={Colors.formBackground}
                    borderRadius={Sizes.cardRadius}>

                    <DoReviewHeader>

                      <DoReviewTitleHeader>
                        <SectionTitle
                          title={translate(TranslateConfig.MEETING)}
                        />


                        <ButtonAction
                          iconLeft={
                            <IconInformation
                              color={Colors.white}
                              size={20}
                            />
                          }
                          title={translate(TranslateConfig.HELP_TO_COMMENT)}
                          backgroundColor={Colors.cardBackground}
                          onPress={() => {
                            // history(NameRoutes.RentHowAds);
                          }}
                        />
                      </DoReviewTitleHeader>



                      <DoReviewInfoHeader>

                        <DoReviewImageInfoHeader>
                          <DoReviewHeaderUserImage
                            src={Images.avatarUserImage}
                          />
                        </DoReviewImageInfoHeader>



                        <DoReviewHeaderUserInfoView>

                          <DoReviewNameTitle>
                            {modeleObject?.alias || '-'}
                          </DoReviewNameTitle>

                          <DoReviewNamePlaceTitle>
                            {'XXX'}
                            {/* TODO: Sao Paulo, Brazil */}
                          </DoReviewNamePlaceTitle>

                        </DoReviewHeaderUserInfoView>

                      </DoReviewInfoHeader>

                    </DoReviewHeader>



                    <InputFullSelectStyled
                      disabled={loading}
                      labelText={translate(TranslateConfig.COMMENT_DID_YOU_MEET)}
                      placeholderText={selectedMeet?.label}
                      onPress={() => {
                        setModalMeet(true);
                      }}
                    />



                    <DoReviewRateContainer>

                      <SectionTitle
                        title={modeleObject?.alias
                          ? `${translate(TranslateConfig.COMMENT_PERFORMANCE_FROM)} ${modeleObject?.alias}`
                          : translate(TranslateConfig.COMMENT_PERFORMANCE)
                        }
                      />


                      <DoReviewRateHeader>

                        <InputViewStyled
                          noShadow
                          labelText={modeleObject?.alias
                            ? `${translate(TranslateConfig.COMMENT_NOTE_FROM)} ${modeleObject?.alias}`
                            : translate(TranslateConfig.COMMENT_NOTE)
                          }
                          labelColor={Colors.inputLabel}
                          backgroundColor={Colors.transparent}
                          helpText={translate(TranslateConfig.ACTION_CHOOSE)}
                          helpColor={Colors.textDescription}>

                          <DoReviewRateStar>

                            <Rating
                              rating={ratingValue}
                              colorAccent={accentGender}
                              size={31}
                              onPress={(value: number) => {
                                setRatingValue(value);
                              }}
                            />



                            <DoReviewRateRow>

                              <DoReviewRateCountIcon>
                                <IconStar
                                  color={ratingValue ? accentGender : Colors.disabled}
                                  size={31}
                                />
                              </DoReviewRateCountIcon>


                              <DoReviewRateCountText
                                color={ratingValue ? Colors.white : Colors.disabled}>
                                {ratingValue ? `${ratingValue}.0` : 0}
                              </DoReviewRateCountText>

                            </DoReviewRateRow>

                          </DoReviewRateStar>

                        </InputViewStyled>



                        <InputTextAreaStyled
                          disabled={loading}
                          type={'TEXT'}
                          labelText={modeleObject?.alias
                            ? `${translate(TranslateConfig.COMMENT_EXPERIENCE_FROM)} ${modeleObject?.alias}`
                            : translate(TranslateConfig.COMMENT_EXPERIENCE)
                          }
                          placeholderText={translate(TranslateConfig.ACTION_WRITE)}
                          errorText={errors.comment}
                          countLimit={Values.aboutMeCount}
                          value={values?.comment}
                          onChange={handleChange('comment')}
                          onBlur={handleBlur('comment')}
                        />



                        <InputDateStyled
                          disabled={loading}
                          type={'DATETIME'}
                          options={{
                            format: 'DD/MM/YYYY',
                          }}
                          labelText={translate(TranslateConfig.COMMENT_DATE_MEET)}
                          placeholderText={translate(TranslateConfig.DATE)}
                          errorText={errors.dateMeet}
                          countLimit={Values.dateCount}
                          value={values.dateMeet}
                          onChange={(_rawText: string, maskedText: string) => {
                            setFieldValue('dateMeet', maskedText);
                          }}
                          onBlur={handleBlur('dateMeet')}
                        />



                        <InputPartSelectStyled
                          disabled={loading}
                          labelText={translate(TranslateConfig.ENVIRONMENT)}
                          placeholderText={selectedMedias?.label}
                          onPress={() => {
                            setModalMedias(true);
                          }}
                        />



                        <InputPartSelectStyled
                          disabled={loading}
                          labelText={translate(TranslateConfig.ENVIRONMENT)}
                          placeholderText={selectedAmbience?.label}
                          onPress={() => {
                            setModalAmbience(true);
                          }}
                        />



                        <InputPartSelectStyled
                          disabled={loading}
                          labelText={translate(TranslateConfig.COMMENT_RECOMMEND_MODELE)}
                          placeholderText={selectedRecommend?.label}
                          onPress={() => {
                            setModalRecommend(true);
                          }}
                        />

                      </DoReviewRateHeader>

                    </DoReviewRateContainer>



                    <ContentButtonForm>
                      <ButtonAction
                        width={Sizes.buttonMinWidth}
                        title={translate(TranslateConfig.ACTION_COMMENT_MAKE)}
                        onPress={() => {
                          handleSubmit();
                        }}
                      />
                    </ContentButtonForm>

                  </CardReviewForm>

                </ContentPadder>
              )}



              <FooterApp />

            </Content>

          </Container>



          <DataSelectModal
            hideSearch
            title={translate(TranslateConfig.COMMENT_DID_YOU_MEET)}
            visible={modalMeet}
            data={reviewMeetArray}
            selectedValue={selectedMeet?.value}
            setData={(typeReturn: IInputSelect) => {
              setSelectedMeet(typeReturn);
              setFieldValue('meet', typeReturn?.value);
            }}
            onClose={() => {
              setModalMeet(false);
            }}
          />


          <DataSelectModal
            hideSearch
            title={translate(TranslateConfig.COMMENT_PICTURES)}
            visible={modalMedias}
            data={reviewMediasArray}
            selectedValue={selectedMedias?.value}
            setData={(typeReturn: IInputSelect) => {
              setSelectedMedias(typeReturn);
              setFieldValue('medias', typeReturn?.value);
            }}
            onClose={() => {
              setModalMedias(false);
            }}
          />


          <DataSelectModal
            hideSearch
            title={translate(TranslateConfig.ENVIRONMENT)}
            visible={modalAmbience}
            data={reviewAmbienceArray}
            selectedValue={selectedAmbience?.value}
            setData={(typeReturn: IInputSelect) => {
              setSelectedAmbience(typeReturn);
              setFieldValue('ambience', typeReturn?.value);
            }}
            onClose={() => {
              setModalAmbience(false);
            }}
          />


          <DataSelectModal
            hideSearch
            title={translate(TranslateConfig.COMMENT_RECOMMEND_MODELE)}
            visible={modalRecommend}
            data={reviewRecommendArray}
            selectedValue={selectedRecommend?.value}
            setData={(typeReturn: IInputSelect) => {
              setSelectedRecommend(typeReturn);
              setFieldValue('recommend', typeReturn?.value);
            }}
            onClose={() => {
              setModalRecommend(false);
            }}
          />

        </Screen>

      )}
    </Formik>

  );
};



export default DoCommentModeleScreen;
