import styled,
{
  css,
} from 'styled-components';

import {
  Colors,
} from '../../../../shared/constants';

import {
  PlatformDevices,
} from '../../../../shared/utils/platforms.utils';



export interface IProps {
  doItem?: boolean;
}



export const RentServiceDontDo = css`
  text-decoration-line: line-through;
`;



export const RentServiceAccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 0 solid;
  border-bottom-width: 1px;
  border-color: rgba(255, 255, 255, .15);
  
  min-width: 40%;
  flex: 1;
  margin-left: 8px;
  margin-right: 8px;

  @media ${PlatformDevices.maxMobileL} {
    min-width: 90%;
  }
`;

export const RentServiceAccordionHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 16px 13px 10px;
`;

export const RentServiceAccordionView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 38px;
`;

export const RentServiceAccordionTitle = styled.h2`
  color: ${Colors.white};
  font-size: 16px;
  line-height: 20px;

  ${(props: IProps) => !props.doItem && RentServiceDontDo};
`;

export const RentServiceSpecialityText = styled.p`
  margin-top: 3px;
  color: ${Colors.inputCount};
  font-size: 12px;
  line-height: 14px;
`;

export const RentServiceAccordionIcon = styled.div`
`;

export const RentServiceAccordionContent = styled.div`
  padding: 0 10px 16px;
`;

export const RentServiceAccordionText = styled.p`
  color: ${Colors.white};
  font-size: 14px;
  line-height: 20px;

  ${(props: IProps) => !props.doItem && RentServiceDontDo};
`;

export const RentServiceWarningText = styled.p`
  margin-top: 10px;
  color: ${Colors.gray};
  font-size: 13px;
  font-style: italic;
  line-height: 20px;
`;

export const RentServiceWarningButton = styled.p`
  color: ${Colors.primaryDark};
  cursor: pointer;
`;

export const RentServiceSpecialityView = styled.p`
  display: flex;
  flex-direction: row;
  margin-top: 3px;
`;
