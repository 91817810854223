import React from 'react';

import {
  Images,
} from '../../../shared/constants';

import {
  HallFameSctionTitleView,
  HallFameSctionArrowImage,
  HallFameSctionArrowTitle,
} from './styled';



interface IProps {
  title: string;
}



const TitleHallFame: React.FC<IProps> = (props) => {
  return (

    <HallFameSctionTitleView>

      <HallFameSctionArrowImage
        src={Images.lineLeft}
      />

      <HallFameSctionArrowTitle>
        {props.title}
      </HallFameSctionArrowTitle>

      <HallFameSctionArrowImage
        src={Images.lineRight}
      />

    </HallFameSctionTitleView>

  );
};



export default TitleHallFame;
