import styled,
{
  css,
} from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';

import {
  BadgeSkewX,
} from '../../Badges/BadgeVt';

import {
  Card,
} from '../../Composh/web';



export interface IProps {
  backgroundColor?: string;
  color?: string;
}



export const CardLiveModeleContent = styled(Card.View)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  height: 100%;
  width: 100%;
`;



export const CardLiveProgressView = styled.a`
  position: absolute;
  width: 100%;
  height: 100%;
  transition: visibility 0s, opacity 0.2s linear;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  background-color: ${Colors.cardOverlay};
  z-index: 10;
`;



export const CardModeleLiveCamView = styled.a`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #ffd700;
`;



export const CardModeleLiveButton = styled.a`
  position: absolute;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  z-index: 1;
`;

export const CardModeleLiveUserImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;



export const CardModeleActionsView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  top: 10px;
  right: 10px;
  gap: 6px;
  z-index: 15;
`;

export const CardModeleExclusiveView = styled(BadgeSkewX)`
`;

export const CardModeleNewScreenButton = styled.a`
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  background-color: ${Colors.cardOverlay};
  border-radius: 50%;
  transition: all 0.1s linear;

  ${CardLiveModeleContent}:hover & {
    display: flex;
  }
`;



export const CardModeleLiveContent = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 7px;
  padding-left: 7px;
  padding-right: 7px;
  padding-bottom: 5px;
  background-color: ${Colors.cardOverlay};
  z-index: 9;
`;


export const CardModeleLiveInfoContent = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

export const CardModeleStatusDot = styled.div`
  display: flex;
  flex-direction: column;
  width: 8px;
  height: 8px;
  background-color: ${(props: IProps) => props.backgroundColor};
  border-radius: 10px;
  transition: all 0.1s linear;
`;

export const CardModeleLiveEntranceButton = styled.p`
  display: block;
  position: relative;
  font-size: 14px;
  line-height: 14px;
  transition: all 0.1s linear;
  width: 100%;
`;

export const CardModeleLiveTextStyle = css`
  position: absolute;
  top: 0;
  transition: visibility 0s, opacity 0.1s linear;
`;

export const CardModeleLiveTitleText = styled.span`
  ${CardModeleLiveTextStyle};
  color: ${(props: IProps) => props.color};
  visibility: visible;
  opacity: 1;

  ${CardLiveModeleContent}:hover & {
    visibility: hidden;
    opacity: 0;
  }
`;

export const CardModeleLiveActionText = styled.span`
  ${CardModeleLiveTextStyle};
  color: ${(props: IProps) => props.color};
  visibility: hidden;
  opacity: 0;

  ${CardLiveModeleContent}:hover & {
    visibility: visible;
    opacity: 1;
  }
`;



export const CardModeleNameContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 18px;
  margin-top: 5px;
`;

export const CardModeleLiveFlag = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 7px;
  object-fit: contain;
`;

export const CardModeleLiveNameText = styled.p`
  color: ${Colors.white};
  font-size: 14px;
  text-shadow: 1px 1px 0px #000;
  white-space: nowrap;
`;

export const CardModeleLiveUserIcon = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-left: 7px;
`;
