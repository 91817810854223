import styled,
{
  css,
  keyframes,
} from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';

import {
  Card,
} from '../../Composh/web';



export interface IProps {
  comeNow?: boolean;
}



export const ProfileCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 6px;
  padding-bottom: 6px;

  @media ${PlatformDevices.maxMobileM} {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  @media ${PlatformDevices.minMobileM} {
    width: 50%;
    min-width: 50%;
    max-width: 50%;
    padding-left: 6px;
    padding-right: 6px;
  }

  @media ${PlatformDevices.minMobileL} {
    width: calc(100% / 3);
    min-width: calc(100% / 3);
    max-width: calc(100% / 3);
    padding-left: 6px;
    padding-right: 6px;
  }
  
  @media ${PlatformDevices.minTabletM} {
    width: 25%;
    min-width: 25%;
    max-width: 25%;
    padding-left: 6px;
    padding-right: 6px;
  }
  
  @media ${PlatformDevices.minLaptop} {
    width: 20%;
    min-width: 20%;
    max-width: 20%;
    padding-left: 6px;
    padding-right: 6px;
  }

  @media ${PlatformDevices.minLaptopL} {
    width: calc(100% / 6);
    min-width: calc(100% / 6);
    max-width: calc(100% / 6);
    padding-left: 6px;
    padding-right: 6px;
  }
`;



const LongPulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 ${Colors.pulseAnimation};
  }

  70% {
    box-shadow: 0 0 0 8px rgba(0, 255, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 255, 0, 0);
  }
`;

const LongPulseStyle = css`
  border-color: ${Colors.pulse};
  border-style: solid;
  border-width: 3px;
  animation: ${LongPulseAnimation} 2s infinite;
`;

export const CardModelesRentContainer = styled(Card.View)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 350px;

  ${(props: IProps) => props.comeNow && LongPulseStyle}
`;



export const RibbonLongImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  height: 60px;
  width: 60px;
  object-fit: cover;
`;


export const ModelesLongImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
  transition: 0.4s all ease-in-out;
  
  ${CardModelesRentContainer}:hover & {
    transform: scale(1.1);
  }
`;


export const InfoStyleView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  padding-top: 7px;
  padding-left: 7px;
  padding-right: 7px;
  padding-bottom: 7px;
  background-color: ${Colors.cardFooterOverlay};
  z-index: 1;
`;

export const TitleInfoLongView = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: 2px;
  margin-bottom: 2px;
`;

export const BadgeComponent = styled.div`
  width: 10px;
  height: 10px;
  margin-top: 5px;
  margin-right: 10px;
  background-color: ${Colors.accept};
  border-radius: 50%;
`;

export const ModelesTitle = styled.p`
  display: flex;
  flex: 1;
  color: ${Colors.white};
  font-size: 19px;
  font-weight: 400;
  text-shadow: ${Colors.black} 1px 1px 2px;
  
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  word-break: break-word;
  text-overflow: ellipsis;
`;

export const SubtitleLongView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;


export const ModeleTravelBadgeView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -15px;
  right: 3px;
  width: 25px;
  height: 25px;
  padding-left: 2px;
  background-color: ${Colors.tertiary};
  border-color: ${Colors.white};
  border-style: solid;
  border-width: 0.5px;
  border-radius: 50%;
`;


export const ModelesSubtitle = styled.p`
  color: ${Colors.white};
  font-size: 14px;
  text-shadow: ${Colors.black} 1px 1px 2px;
  
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  word-break: break-word;
  text-overflow: ellipsis;
`;

export const IconVerifiedAlias = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  margin-left: 7px;
`;



export const ModelesCharsContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 6px;
`;

export const ModelesCharsItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

export const ModelesCharsIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 6px;
`;

export const ModelesCharsText = styled.p`
  color: #B4B4B4;
  font-size: 15px;
  text-shadow: ${Colors.black} 1px 1px 2px;
  
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  word-break: break-word;
  text-overflow: ellipsis;
`;



export const ModelesPlaceView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 6px;
`;

export const ModelesPlaceAtText = styled.p`
  margin-right: 6px;
  color: #B4B4B4;
  font-size: 14.5px;
  text-shadow: ${Colors.black} 1px 1px 2px;
`;

export const ModelesPlaceFlagCountry = styled.div`
  display: flex;
  flex-direction: column;
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

export const ModelesPlaceCountry = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const ModelesPlaceText = styled.p`
  color: #B4B4B4;
  font-size: 14.5px;
  text-shadow: ${Colors.black} 1px 1px 2px;
  
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  word-break: break-word;
  text-overflow: ellipsis;
`;


export const BadgeModelsContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 3px;
  padding-top: 3px;
`;

export const ModeleHoursImage = styled.img`
  width: 35px;
  height: 35px;
  object-fit: contain;
`;



export const ModeleComeNowView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 4px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 1px;
  background-color: ${Colors.pulse};
  z-index: 1;
`;

export const ModeleComeNowText = styled.p`
  color: ${Colors.black};
  font-size: 12px;
  font-weight: bold;
`;
