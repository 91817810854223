import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useSelector,
} from 'react-redux';

import BadgeApp from '../../../../../components/Badges/BadgeApp';
import CardAddMedia from '../../../../../components/Cards/CardAddMedia/CardAddMedia';
import CardAddVideo from '../../../../../components/Cards/CardAddMedia/CardVideo';
import ChipApp from '../../../../../components/Chip';

import {
  Content,
} from '../../../../../components/Composh/web';

import EmptyContent from '../../../../../components/Empty';
import FooterApp from '../../../../../components/Footers/FooterApp';
import HeaderHelp from '../../../../../components/Headers/HeaderHelp';

import {
  IconWorld,
  IconFire,
  IconLocked,
  IconMediaVideo,
} from '../../../../../components/Icons';

import {
  RootState,
} from '../../../../../redux';

import {
  Colors,
  Images,
} from '../../../../../shared/constants';

import EAppMediaVisibility from '../../../../../shared/enums/Status/app-media-visibility.enum';

import {
  MaskNotificationsBadge,
} from '../../../../../shared/utils/masks.utils';

import RightVideoInfo from '../../Infos/InfoVideos';

import {
  ContentWrapperRowFull,
  ContentCenterFlex,
  MediaHeaderPadderTop,
  MediaPhotoGridView,
  MediaTypeContent,
} from './styled';



export interface IProps {
  onShowModal?: any;
  onOpenVideoModal?: any;
  tabEditPress?: any;
  tabShowHidePress?: any;
}



const TabAddVideos: React.FC<IProps> = (props: IProps) => {
  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);

  const user = useSelector((state: RootState) => state.user.actualUser);


  const photosArray = user?.pictureGalleryVideos || [];

  const [filteredPhotos, setFilteredPhotos] = useState<any[]>([]);
  const [typeImage, setTypeImage] = useState<EAppMediaVisibility | ''>('');


  const imagesOptions = [
    {
      visible: true,
      title: 'Todas',
      typeImage: '',
      icon: (
        <IconMediaVideo
          color={Colors.white}
          size={18}
        />
      ),
      badgeCount: photosArray?.length,
    },
    {
      visible: true,
      title: 'Abertas',
      typeImage: EAppMediaVisibility.PUBLIC,
      icon: (
        <IconWorld
          color={Colors.white}
          size={18}
        />
      ),
      badgeCount: photosArray?.filter((photo: any) => photo.visibility === EAppMediaVisibility.PUBLIC).length,
    },
    {
      visible: projectParams?.showFanClub,
      title: 'Fã-clube',
      typeImage: EAppMediaVisibility.FAN_CLUB,
      icon: (
        <IconFire
          color={Colors.white}
          size={18}
        />
      ),
      badgeCount: photosArray?.filter((photo: any) => photo.visibility === EAppMediaVisibility.FAN_CLUB).length,
    },
    {
      visible: projectParams?.showFanClub,
      title: 'Privadas',
      typeImage: EAppMediaVisibility.PRIVATE,
      icon: (
        <IconLocked
          color={Colors.white}
          size={18}
        />
      ),
      badgeCount: photosArray?.filter((photo: any) => photo.visibility === EAppMediaVisibility.PRIVATE).length,
    },
  ];



  function renderChipChooses(item: any, index: number) {
    if (!item?.visible) {
      return;
    }


    return (

      <ChipApp
        key={index}
        title={item?.title}
        selected={typeImage === item?.typeImage}
        selectedBackgroundColor={Colors.primaryDark}
        thumb={item?.icon}
        icon={
          <BadgeApp
            text={MaskNotificationsBadge(item?.badgeCount)}
            backgroundColor={Colors.black}
            color={Colors.white}
          />
        }
        onPress={() => {
          setTypeImage(item?.typeImage);
        }}
      />

    );
  }


  function renderItem(item: any, index: number) {
    return (

      <CardAddVideo
        key={index}
        image={item?.picture}
        visible={item?.visible}
        visibility={item?.visibility}
        revised={item?.revised}
        canBuy={item?.canBuy}
        duration={item?.duration}
        isHD={item?.isHD}
        onEditPress={() => {
          props.tabEditPress(item?.id);
        }}
        onShowHidePress={() => {
          props.tabShowHidePress(item?.id);
        }}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noMediaVideo}
        title={'Não há itens ainda!'}
      />

    );
  }



  useEffect(() => {
    if (typeImage === '') {
      setFilteredPhotos(photosArray);
    }
    else {
      const filtered = photosArray.filter((photo) => photo.visibility === typeImage);
      setFilteredPhotos(filtered);
    }
  }, [typeImage, photosArray]);



  return (

    <Content>

      <ContentWrapperRowFull>

        <ContentCenterFlex>

          <HeaderHelp
            title={'Publicar vídeo simples'}
            onHelpPress={() => {
              props.onShowModal();
            }}>


            <CardAddMedia
              title={'Adicionar vídeo simples'}
              onPress={() => {
                props.onOpenVideoModal();
              }}
            />

          </HeaderHelp>



          <MediaHeaderPadderTop>
            {imagesOptions.map((chip, index) => (
              renderChipChooses(chip, index)
            ))}
          </MediaHeaderPadderTop>



          <MediaPhotoGridView
            noScroll
            data={filteredPhotos}
            renderItem={renderItem}
            listEmptyComponent={listEmptyComponent()}
          />

        </ContentCenterFlex>



        <MediaTypeContent>
          <RightVideoInfo />
        </MediaTypeContent>

      </ContentWrapperRowFull>



      <FooterApp />

    </Content>

  );
};



export default TabAddVideos;
