import React from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  RootState,
} from '../../../redux';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import {
  IconMoreOptions,
} from '../../Icons';

import {
  MessageUserContainer,
  ChatListUserPhoto,
  ChatUserImage,
  ChatUserInfoContent,
  ChatUserUsernameText,
  ChatUserMessageText,
  ChatUserCountContent,
  ChatUserTodayText,
  ChatUserCountBadge,
  ChatUserCountText,
  ChatOptionsButton,
  ChatSwapControlsContent,
  ChatSwapButton,
  ChatSwapIcon,
  ChatSwapText,
} from './styled';



interface IProps {
  active?: boolean;
}



const MessageUser: React.FC<IProps> = (props) => {
  const genderColorChoose = useSelector((state: RootState) => state.choose.genderColorApp);



  return (

    <MessageUserContainer
      active={props.active}>

      <ChatListUserPhoto>
        <ChatUserImage
          alt={''}
          src={Images.avatarUserImage}
        />
      </ChatListUserPhoto>


      <ChatUserInfoContent>
        <ChatUserUsernameText>
          Alias
        </ChatUserUsernameText>

        <ChatUserMessageText>
          • Sem mensagens
        </ChatUserMessageText>
      </ChatUserInfoContent>


      <ChatUserCountContent>
        <ChatUserTodayText>
          Hoje, 16:06
        </ChatUserTodayText>

        <ChatUserCountBadge
          backgroundColor={genderColorChoose}>
          <ChatUserCountText>
            3
          </ChatUserCountText>
        </ChatUserCountBadge>
      </ChatUserCountContent>


      <ChatOptionsButton>
        <IconMoreOptions
          color={Colors.white}
          size={22}
        />
      </ChatOptionsButton>


      {false && (
        <ChatSwapControlsContent>

          <ChatSwapButton>
            <ChatSwapIcon>

            </ChatSwapIcon>

            <ChatSwapText>
              Pin
            </ChatSwapText>
          </ChatSwapButton>


          <ChatSwapButton>
            <ChatSwapIcon>

            </ChatSwapIcon>

            <ChatSwapText>
              Block
            </ChatSwapText>
          </ChatSwapButton>


          <ChatSwapButton>
            <ChatSwapIcon>

            </ChatSwapIcon>

            <ChatSwapText>
              Delete
            </ChatSwapText>
          </ChatSwapButton>
        </ChatSwapControlsContent>
      )}

    </MessageUserContainer>

  );
};



export default MessageUser;
