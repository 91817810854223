import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Content,
  Screen,
  Title,
} from '../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../components/Controls';

import EmptyContent from '../../../components/Empty';
import FooterApp from '../../../components/Footers/FooterApp';
import HeaderScreen from '../../../components/Headers/HeaderScreen';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import {
  TranslateConfig,
} from '../../../shared/translations';



const BuyMediasScreen: React.FC = () => {
  const { t: translate } = useTranslation();



  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noImage}
        title={translate(TranslateConfig.EMPTY_BUYED_ITEMS)}
      />

    );
  }



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        disableGender
        showBalance
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            color={Colors.white}
            size={27}
          />
        }
        centerContent={
          <Title>
            {translate(TranslateConfig.BUYED_ITEMS)}
          </Title>
        }
      />



      <Content>

        {listEmptyComponent()}


        <FooterApp />

      </Content>

    </Screen>

  );
};



export default BuyMediasScreen;
