export const zodiacArray = [
  { id: 1, label: 'Áries', value: 'ARIES' },
  { id: 2, label: 'Touro', value: 'TAURUS' },
  { id: 3, label: 'Gêmeos', value: 'GEMINI' },
  { id: 4, label: 'Câncer', value: 'CANCER' },
  { id: 5, label: 'Leão', value: 'LEO' },
  { id: 6, label: 'Virgem', value: 'VIRGO' },
  { id: 7, label: 'Libra', value: 'LIBRA' },
  { id: 8, label: 'Escorpião', value: 'SCORPIO' },
  { id: 9, label: 'Sagitário', value: 'SAGITTARIUS' },
  { id: 10, label: 'Capricórnio', value: 'CAPRICORN' },
  { id: 11, label: 'Aquário', value: 'AQUARIUS' },
  { id: 12, label: 'Peixes', value: 'PISCES' },
];
