import React from 'react';

import {
  IsentionPayContainer,
  IsentionPayTitle,
  IsentionPayDescription,
} from './styled';



const PaymentIsention: React.FC = () => {
  return (

    <IsentionPayContainer>

      <IsentionPayTitle>
        Você está isento dos pagamentos no Secretio
      </IsentionPayTitle>


      <IsentionPayDescription>
        Use com moderação pois a isenção é um gesto de confiança do Secretio para você!
      </IsentionPayDescription>

    </IsentionPayContainer>

  );
};



export default PaymentIsention;
