import React,
{
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import Color from 'color';

import {
  SaveContactModeleClick,
} from '../../../apis/endpoints/modeles-reports.endpoints';

import {
  openProfileWhatsApp,
  openPhone,
} from '../../../config/linking.config';

import {
  FormatPhone,
} from '../../../config/mask.config';

import {
  RootState,
} from '../../../redux';

import {
  APP_SNACKBAR_ACTION,
} from '../../../redux/reducers/app-modals.store';

import {
  Colors,
  Keys,
} from '../../../shared/constants';

import {
  EUserContactsType,
} from '../../../shared/enums/Users/user-contacts-type.enum';

import {
  TranslateConfig,
} from '../../../shared/translations';

import {
  IconCheck,
  IconCopy,
  IconPhone,
  IconSocialWhatsApp,
} from '../../Icons';

import {
  PhoneContainerButton,
  PhoneCallButton,
  PhoneButtonText,
  PhoneButtonIcon,
  CopyButton,
  WhatsappButton,
} from './styled';



export interface IProps {
  flexDirection: 'COLUMN' | 'ROW';
  idModele: string;
  comeNow: boolean;
  username: string;
  isWhatsApp?: boolean;
  ddi?: string;
  phone?: string;
}



const ButtonPhone: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const genderColorChoose = useSelector((state: RootState) => state.choose.genderColorApp);


  const modeleIdReceived = props.idModele;
  const fullNumber = `${props.ddi}${props.phone}`;

  const copiedGender = Color(genderColorChoose).darken(0.5).toString();

  const [copied, setCopied] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function copyPhone() {
    setCopied(true);
    navigator.clipboard.writeText(fullNumber);
    showSnackBarProps(Colors.accept, translate(TranslateConfig.SUCCESS_COPIED_NUMBER));

    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }


  async function saveClickContact(typeReceived: EUserContactsType) {
    try {
      const payload = {
        idUser: modeleIdReceived,
        type: typeReceived,
      };

      await SaveContactModeleClick(payload);
    }
    catch (error: any) {
      console.error(error);
    }
  }



  return (

    <PhoneContainerButton
      flexDirection={props.flexDirection}>

      <PhoneCallButton
        flexDirection={props.flexDirection}
        backgroundColor={props.phone ? genderColorChoose : Colors.disabled}
        onClick={() => {
          if (props.ddi && props.phone) {
            saveClickContact(EUserContactsType.PHONE);
            openPhone(fullNumber);
          }
        }}>

        <PhoneButtonText>
          {props.ddi && props.phone
            ? `+${props.ddi} ${FormatPhone(props.phone)}`
            : translate(TranslateConfig.PHONE_NONE)
          }
        </PhoneButtonText>


        <PhoneButtonIcon>
          <IconPhone
            color={Colors.white}
            size={19}
          />
        </PhoneButtonIcon>

      </PhoneCallButton>



      <CopyButton
        flexDirection={props.flexDirection}
        backgroundColor={
          props.phone
            ? copied ? Colors.accept : copiedGender
            : Colors.disabled
        }
        onClick={() => {
          if (props.ddi && props.phone) {
            copyPhone();
          }
        }}>


        {props.flexDirection === 'COLUMN' && (
          <PhoneButtonText>
            {translate(TranslateConfig.ACTION_COPY_PHONE)}
          </PhoneButtonText>
        )}


        <PhoneButtonIcon>
          {copied
            ? (
              <IconCheck
                size={25}
                color={Colors.white}
              />
            )
            : (
              <IconCopy
                color={Colors.white}
                size={19}
              />
            )
          }
        </PhoneButtonIcon>

      </CopyButton>



      <WhatsappButton
        flexDirection={props.flexDirection}
        backgroundColor={props.isWhatsApp ? '#009205' : Colors.grayDark}
        onClick={() => {
          if (props.ddi && props.phone) {
            saveClickContact(EUserContactsType.WHATSAPP);

            if (props.comeNow) {
              const newHighMessage = Keys.whatsAppProfileHighlightMessage.replace(
                'Secretio.app',
                `Secretio.app/${props.username}`,
              );

              openProfileWhatsApp(fullNumber, newHighMessage);
              return;
            }

            const newNormalMessage = Keys.whatsAppProfileNormalMessage.replace(
              'Secretio.app',
              `Secretio.app/${props.username}`,
            );

            openProfileWhatsApp(fullNumber, newNormalMessage);
          }
        }}>


        {props.flexDirection === 'COLUMN' && (
          <PhoneButtonText>
            {translate(TranslateConfig.WHATSAPP)}
          </PhoneButtonText>
        )}


        <PhoneButtonIcon>
          <IconSocialWhatsApp
            color={Colors.white}
            size={21}
          />
        </PhoneButtonIcon>

      </WhatsappButton>

    </PhoneContainerButton>

  );
};



export default ButtonPhone;
