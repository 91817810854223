export const cockSizesArray = [
  { id: 1, label: '10 cm - 4 In', value: 'COCK_SIZE_10CM' },
  { id: 2, label: '11 cm - 4.5 In', value: 'COCK_SIZE_11CM' },
  { id: 3, label: '12 cm - 5 In', value: 'COCK_SIZE_12CM' },
  { id: 4, label: '13 cm - 5.12 In', value: 'COCK_SIZE_13CM' },
  { id: 5, label: '14 cm - 5.5 In', value: 'COCK_SIZE_14CM' },
  { id: 6, label: '15 cm - 6 In', value: 'COCK_SIZE_15CM' },
  { id: 7, label: '16 cm - 6.5 In', value: 'COCK_SIZE_16CM' },
  { id: 8, label: '17 cm - 7 In', value: 'COCK_SIZE_17CM' },
  { id: 9, label: '18 cm - 7.1 In', value: 'COCK_SIZE_18CM' },
  { id: 10, label: '19 cm - 7.5 In', value: 'COCK_SIZE_19CM' },
  { id: 11, label: '20 cm - 8 In', value: 'COCK_SIZE_20CM' },
  { id: 12, label: '21 cm - 8.5 In', value: 'COCK_SIZE_21CM' },
  { id: 13, label: '22 cm - 9 In', value: 'COCK_SIZE_22CM' },
  { id: 14, label: '23 cm - 9.06 In', value: 'COCK_SIZE_23CM' },
  { id: 15, label: '24 cm - 9.5 In', value: 'COCK_SIZE_24CM' },
  { id: 16, label: '25 cm - 10 In', value: 'COCK_SIZE_25CM' },
  { id: 17, label: '26 cm - 10.5 In', value: 'COCK_SIZE_26CM' },
  { id: 18, label: '27 cm - 11 In', value: 'COCK_SIZE_27CM' },
  { id: 19, label: '28 cm - 11.02 In', value: 'COCK_SIZE_28CM' },
  { id: 20, label: '29 cm - 11.42 In', value: 'COCK_SIZE_29CM' },
  { id: 21, label: '30 cm - 12 In', value: 'COCK_SIZE_30CM' },
  { id: 22, label: '31 cm - 12.5 In', value: 'COCK_SIZE_31CM' },
  { id: 23, label: '32 cm - 13 In', value: 'COCK_SIZE_32CM' },
  { id: 24, label: '33 cm - 12.99 In', value: 'COCK_SIZE_33CM' },
  { id: 25, label: '34 cm - 13.39 In', value: 'COCK_SIZE_34CM' },
  { id: 26, label: '35 cm - 13.78 In', value: 'COCK_SIZE_35CM' },
];
