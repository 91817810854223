import styled from 'styled-components';

import {
  Badge as BadgeComposh,
} from '../../Composh/web';



export const BadgeComposhContainer = styled(BadgeComposh.View)`
  padding-top: 1.3px;
`;
