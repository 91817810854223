import React from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useNavigate,
  useLocation,
} from 'react-router-dom';

import {
  Content,
  Screen,
  Title,
} from '../../../../components/Composh/web';

import {
  ContentPadder,
} from '../../../../components/Contents/ContentPadder';

import {
  ButtonBack,
} from '../../../../components/Controls';

import FooterApp from '../../../../components/Footers/FooterApp';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';

import {
  IconArrowLightForward,
} from '../../../../components/Icons';

import NameRoutes from '../../../../navigation/names';

import {
  Colors,
} from '../../../../shared/constants';

import {
  TranslateConfig,
} from '../../../../shared/translations';

import {
  FaqSectionTitle,
  FaqCategoryView,
  FaqCategoryBullet,
} from '../styled';



export interface IRouteLocation {
  categories: any;
}



const FaqCategoryScreen: React.FC = () => {
  const history = useNavigate();
  const { t: translate } = useTranslation();

  const routeLocation = useLocation();
  const params = routeLocation?.state as IRouteLocation;


  const categories = params?.categories;



  function renderItem(item: any, index: number) {
    return (

      <FaqCategoryView
        key={index}
        // activeOpacity={Sizes.ButtonActiveOpacity}
        onClick={() => {
          history(
            NameRoutes.FaqBulletScreen,
            {
              state: {
                bullet: item,
                category: item?.category?.title,
              },
            },
          );
        }}>

        <FaqCategoryBullet
          color={Colors.white}>
          {item?.title}
        </FaqCategoryBullet>


        <IconArrowLightForward
          color={Colors.white}
          size={20}
        />

      </FaqCategoryView>

    );
  }



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet prioritizeSeoTags>
        <title>
          {`${translate(TranslateConfig.HELP)} | ${translate(TranslateConfig.NAME_APP)}`}
        </title>

        <meta name="robots" content="noindex, nofollow" />
      </Helmet>



      <HeaderScreen
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            color={Colors.white}
          />
        }
        centerContent={
          <Title
            color={Colors.white}>
            {translate(TranslateConfig.HELP)}
          </Title>
        }
      />



      <Content>

        <ContentPadder>

          <FaqSectionTitle
            color={Colors.buttonOkLight}>
            {categories?.title}
          </FaqSectionTitle>


          {categories.bullets.map((item: any, index: number) => (
            renderItem(item, index)
          ))}

        </ContentPadder>



        <FooterApp />

      </Content>

    </Screen>

  );
};



export default FaqCategoryScreen;
