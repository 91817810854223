import React from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  USER_BRAND_PROMOTER_MODAL,
  USER_VERIFIED_MODAL,
} from '../../../redux/reducers/app-modals.store';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import {
  EPlansUser,
} from '../../../shared/enums';

import UserAvatar from '../../Contents/UserAvatar';

import {
  IconVerified,
  IconVerifiedPromoter,
} from '../../Icons';

import {
  NotificationContainer,
  NotificationColumnFlex,
  NotificationHeaderContainer,
  NotificationImageContainer,
  NotificationInfoView,
  NotificationInfoTitleView,
  NotificationInfoTitle,
  NotificationInfoUsernameView,
  NotificationInfoUsername,
  NotificationInfoIcon,
  NotificationText,
  NotificationActionContent,
  NotificationActionTextButton,
  NotificationActionText,
  NotificationDateText,
  NotificationImageActionView,
  NotificationActionImage,
} from './styled';



export interface IProps {
  title?: string;
  username?: string;
  date?: string;
  description?: string;
}



const CardNotifications: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();



  return (

    <NotificationContainer>

      <NotificationColumnFlex>

        <NotificationHeaderContainer>

          <NotificationImageContainer>
            <UserAvatar
              width={80}
              image={null}
              plan={EPlansUser.GOLD}
            />
          </NotificationImageContainer>



          <NotificationInfoView>

            <NotificationInfoTitleView>

              <NotificationInfoTitle>
                {props.title || '-'}
              </NotificationInfoTitle>



              <NotificationInfoUsernameView>

                <NotificationInfoUsername>
                  @{props.username || '-'}
                </NotificationInfoUsername>


                <NotificationInfoIcon
                  onClick={() => {
                    dispatch({ type: USER_VERIFIED_MODAL, payload: true });
                  }}>
                  <IconVerified
                    size={18}
                    color={Colors.accent}
                  />
                </NotificationInfoIcon>


                <NotificationInfoIcon
                  onClick={() => {
                    dispatch({ type: USER_BRAND_PROMOTER_MODAL, payload: true });
                  }}>
                  <IconVerifiedPromoter
                    size={18}
                    color={Colors.white}
                  />
                </NotificationInfoIcon>

              </NotificationInfoUsernameView>

            </NotificationInfoTitleView>



            <NotificationText>
              {props.description}
            </NotificationText>

          </NotificationInfoView>

        </NotificationHeaderContainer>



        <NotificationActionContent>

          <NotificationActionTextButton>

            <NotificationActionText>
              Oferta de solicitação
            </NotificationActionText>

          </NotificationActionTextButton>

        </NotificationActionContent>



        <NotificationDateText>
          {props.date}
        </NotificationDateText>

      </NotificationColumnFlex>



      <NotificationImageActionView>
        <NotificationActionImage
          src={Images.avatarUserImage}
        />
      </NotificationImageActionView>


    </NotificationContainer>

  );
};



export default CardNotifications;
