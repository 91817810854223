import React from 'react';

import {
  Content,
  Screen,
  Title,
} from '../../../../components/Composh/web';

import {
  ContentPadder,
} from '../../../../components/Contents/ContentPadder';

import {
  ButtonBack,
} from '../../../../components/Controls';

import FooterApp from '../../../../components/Footers/FooterApp';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';

import {
  IconHelp,
} from '../../../../components/Icons';

import {
  Colors,
  Images,
} from '../../../../shared/constants';

import {
  Container1,
  Container2,
  Container3,
  Container4,
  Container5,
  Container8,
  Container9,
  Container10,
} from './styled';



const FinancialAffiliatesScreen: React.FC = () => {
  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        disableGender
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            color={Colors.white}
            size={27}
          />
        }
        centerContent={
          <Title>
            Afiliados
          </Title>
        }
      />



      <Content>

        <ContentPadder>

          <Container1>

            <Container2>
              <Container3>
                Inscrições
              </Container3>

              <Container4>
                <IconHelp />
              </Container4>
            </Container2>


            <Container5>

              <Container8>
                <Container9>
                  0
                </Container9>

                <Container10>
                  Total
                </Container10>
              </Container8>


              <Container8>
                <Container9>
                  0
                </Container9>

                <Container10>
                  Usuários
                </Container10>
              </Container8>


              <Container8>
                <Container9>
                  0
                </Container9>

                <Container10>
                  Modelos
                </Container10>
              </Container8>

            </Container5>

          </Container1>



          <Container1>
            <Container2>
              <Container3>
                Comissão
              </Container3>

              <Container4>
                <IconHelp />
              </Container4>
            </Container2>


            <div data-v-37b445b0="" className="cardMian">
              <div data-v-37b445b0="" className="vip">
                <div data-v-37b445b0="" className="level"><span data-v-37b445b0="" className="iconfont icon-vip2"></span><span data-v-37b445b0="" className="levelNum">1</span></div>
                <div data-v-37b445b0="">Nível de Agente <span data-v-37b445b0="" className="iconfont icon-notice"></span></div>
              </div>
              <div data-v-37b445b0="">
                <ul data-v-37b445b0="" className="commission">
                  <li data-v-37b445b0="">
                    <span data-v-37b445b0="" className="num"><span data-v-37b445b0="">R$</span><span data-v-37b445b0="" className="cash">0.00</span></span><span data-v-37b445b0=""> Total pago </span>
                  </li>
                  <li data-v-37b445b0="">
                    <span data-v-37b445b0="" className="num"><span data-v-37b445b0="">R$</span><span data-v-37b445b0="" className="cash">0.00</span></span><span data-v-37b445b0=""> Não pago </span>
                  </li>
                </ul>
                <div data-v-37b445b0="" className="wallet">
                  <div data-v-37b445b0="" className="btn">TRANSFERIR PARA A CARTEIRA</div>
                  <div data-v-37b445b0="" className="tip"><span data-v-37b445b0="" className="iconfont icon-tips2"></span>Valor mínimo de transferência de R$ 10,00</div>
                </div>
              </div>
            </div>
          </Container1>



          <Container1>
            <Container2>
              <Container3>
                Compartilhe com sua comunidade social
              </Container3>
            </Container2>

            <div data-v-37b445b0="" className="share">
              <ul data-v-346a1eda="" data-v-37b445b0="" className="sharePlatform">

                <li data-v-346a1eda="">
                  <a data-v-346a1eda="" href="javascript:;"><img data-v-346a1eda="" src="/img/facebook.f59b3fbc.png" /></a><span data-v-346a1eda="">Facebook</span>
                </li>
                <li data-v-346a1eda="">
                  <a data-v-346a1eda="" href="javascript:;"><img data-v-346a1eda="" src="/img/WhatsApp.c96f7286.png" /></a><span data-v-346a1eda="">WhatsApp</span>
                </li>
                <li data-v-346a1eda="">
                  <a data-v-346a1eda="" href="javascript:;">
                    <img
                      data-v-346a1eda=""
                      src=""
                    />
                  </a>
                  <span data-v-346a1eda="">Telegram</span>
                </li>
                <li data-v-346a1eda="">
                  <a data-v-346a1eda="" href="javascript:;"><img data-v-346a1eda="" src="/img/Twitter.ee6c269c.png" /></a><span data-v-346a1eda="">Twitter</span>
                </li>
                <li data-v-346a1eda="">
                  <a data-v-346a1eda="" href="mailto:?body=%20%20https%3A%2F%2F8pg.com%2Fr%2Fxxxxxx"><img data-v-346a1eda="" src="/img/email.76fd7e84.png" /></a><span data-v-346a1eda="">Email</span>
                </li>
              </ul>
            </div>
            <div data-v-37b445b0="" className="shareLink">
              <div data-v-37b445b0="" className="tip">Compartilhe este link de indicação com seus amigos</div>
              <div data-v-61e14726="" data-v-37b445b0="" className="copyUrl copyUrl">
                <span data-v-61e14726="" className="url omitWrap">https://8pg.com/r/xxxxxx</span><span data-v-61e14726="" id="copy" data-clipboard-action="copy" data-clipboard-text="https://8pg.com/r/xxxxxx">Cópia</span>
              </div>
            </div>
          </Container1>



          <Container1>
            <Container2>
              <Container3>
                Regras de recompensas por comissão
              </Container3>

              <Container4>
                <IconHelp />
              </Container4>
            </Container2>
            <div data-v-37b445b0="" className="hint">
              <div data-v-37b445b0="" className="hintTitle">Ganhe dinheiro indicando amigos para a 8PG, você ganha sempre que um convidado seu realiza uma aposta!</div>
              <div data-v-481dfcf0="" data-v-37b445b0="" className="imgContent">
                <img data-v-481dfcf0="" src={Images.gencoin} alt="" />
                <div data-v-481dfcf0="">
                  Número de indicações &gt; 100<br />
                  Comissão pode ser &gt; R$ 5,000 todo mês!
                </div>
                <div data-v-481dfcf0="" className="slider van-slider" >
                  <div className="van-slider__bar" >
                    <div aria-orientation="horizontal" className="van-slider__button-wrapper">
                      <div data-v-481dfcf0="" className="img">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div data-v-6ef91c1f="" data-v-37b445b0="" className="relationSchema">
                <div data-v-6ef91c1f="" className="groupTitle">Desenvolvimento ilimitado de indicados</div>
                <img data-v-6ef91c1f=""
                  src={Images.affiliateGroup}
                  alt="" className="groupImg" />
                <ul data-v-6ef91c1f="" className="rules">
                  <li data-v-6ef91c1f="">O indicante receberá uma porcentagem como comissão toda vez que um jogador indicado fizer uma aposta válida,<span >os ganhos ou perdas</span>.</li>
                  <li data-v-6ef91c1f="">O sistema calcula a comissão a cada mês.</li>
                </ul>
              </div>
            </div>
          </Container1>

        </ContentPadder>



        <FooterApp />

      </Content>

    </Screen>

  );
};



export default FinancialAffiliatesScreen;
