import styled from 'styled-components';

import {
  Input,
} from '../../Composh/web';



export interface IProps {
  color?: string;
}



export const InputSwitchViewStyled = styled(Input.View)`
`;


export const InputSwitchStyled = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 40px;
  padding-left: 10px;
  padding-right: 10px;
`;

export const InputSwitchTextStyled = styled.p`
  color: ${(props: IProps) => props.color};
  font-size: 14px;
  font-weight: 500;
`;
