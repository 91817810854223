import styled from 'styled-components';

import color from 'color';

import {
  Colors,
} from '../../../shared/constants';



interface IProps {
  active?: boolean;
  disabled?: boolean;
  color?: string;
  backgroundColor?: string;
}



export const WrapperRoomContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;


export const VideoModeleView = styled.div`
  display: flex;
  flex-direction: column;
`;



// Chat Options Styles

export const ChatOptionsView = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  background-color: #0f0f0f;
`;

export const ButtonChatContentView = styled.div`
  display: flex;
  flex: 1;
  padding: 5px;
`;

export const OptionChatContentView = styled.div`
  display: flex;
  padding: 5px;
`;


export const MarginChatTypeButton = styled.div`
  display: flex;
  flex: 1;
  margin-left: 5px;
  margin-right: 5px;
`;


export const PartyChatTypeButton = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  background: linear-gradient(to right, #ab0f8e 0%, #121317 70%);
`;

export const PartyChatTypeIcon = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 15px;
  object-fit: center;
`;


export const GroupChatTypeButton = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  height: 30px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 0 18px;
  background-color: ${(props: IProps) => props.backgroundColor
    ? color(props.backgroundColor).darken(0.6).toString()
    : Colors.primary};
  border-radius: 5px;
`;

export const GroupChatTypeTitle = styled.p`
  display: flex;
  flex: 1;
  color: #FFFFFF;
  font-size: 17px;
  font-weight: bold;
`;

export const GroupChatTypeStartText = styled.p`
  color: #FFFFFF;
`;

export const GroupChatTypeTimeText = styled.p`
  margin-left: 5px;
  padding: 3px 7px;
  color: #FFFFFF;
  background-color: #000000;
  border-radius: 5px;
`;


export const ChatOptionsTextButton = styled.p`
  margin: 0;
  font-size: 19px;
  color: white;
`;

export const ChatOptionsButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: ${(props: IProps) => props.backgroundColor
    ? color(props.backgroundColor).darken(0.65).toString()
    : Colors.primary};
  border-radius: 5px;
`;



// Chat Panel Styles

export const ChatPanels = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  background-color: #000000;
`;

export const OfflinePanels = styled.div`
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  flex-direction: row;
  padding: 5px;
`;

export const TopicChatHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  background-color: #191919;
`;

export const TopicChatText = styled.p`
  margin-left: 10px;
  color: #FFFFFF;
  font-size: 14px;
`;



// Chat Styles

export const ChatView = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
`;



// Tips Styles

export const TipChatView = styled.div`
  display: flex;
  flex-direction: column;
  height: 150px;
  width: 100%;
`;

export const TipHeaderView = styled.div`
  display: flex;
  flex-direction: row;
  height: 30px;
  width: 100%;
  padding-bottom: 30px;
  background-color: #121417;
`;

export const TipTypeHeaderButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 30px;
  background-color: ${(props: IProps) => props.active ? '#202123' : 'transparent'};
  border-top-left-radius: ${(props: IProps) => props.active ? '8px' : '0'};
  border-top-right-radius: ${(props: IProps) => props.active ? '8px' : '0'};
`;

export const TipTypeHeaderTextButton = styled.p`
  margin-top: 1px;
  margin-left: 10px;
  color: #FFFFFF;
  font-size: 14px;
`;

export const TipItemsContent = styled.div`
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  flex-direction: row;
  flex: 1;
  height: 120px;
  background-color: #202123;
`;
