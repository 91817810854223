import styled from 'styled-components';

type InputProps = {
  fullWidth?: boolean;
  size?: 'sm' | 'md';
  error?: boolean;
};

const Input = styled.input.attrs(() => ({ size: 'md' })) <InputProps>`
  background-color: ${'#F5F6F8'};
  color: ${'#263238'};
  border: none;
  border-radius: ${'12px'};

  ${({ fullWidth }) => fullWidth && 'width: 100%;'}

  ${({ error, theme }) =>
    error &&
    `
     box-shadow: 0 0 0 2px ${theme.colors.danger};
     `}

  ${({ disabled }) =>
    disabled &&
    `
        opacity: 0.4;
        & > * {
          cursor: not-allowed;
        }
      `}
  
  ${({ size }) =>
    size === 'sm' &&
    `
    min-height: 32px;
    padding: 6px 12px;
    font-size: ${'0.875rem'};
  `}

  ${({ size }) =>
    size === 'md' &&
    `
    font-size: ${'0.875rem'};
    min-height: 42px;
    padding: 8px 16px;
  `}
`;

export default Input;
