import React from 'react';

import {
  Colors,
} from '../../../shared/constants';

import {
  IconChat,
} from '../../Icons';

import {
  WhatsAppContainerButton,
  WhatsAppButtonText,
  WhatsAppButtonIcon,
} from './styled';



export interface IProps {
  size?: number;
  onPress?: any;
}



const ButtonSendMessage: React.FC<IProps> = (props: IProps) => {
  return (

    <WhatsAppContainerButton
      onClick={() => {
        console.log('CHAT');
      }}>

      <WhatsAppButtonText
        fontSize={(props.size - 3) || 16}>
        Mandar mensagem
      </WhatsAppButtonText>


      <WhatsAppButtonIcon>
        <IconChat
          color={Colors.white}
          size={props.size || 21}
        />
      </WhatsAppButtonIcon>

    </WhatsAppContainerButton>

  );
};



export default ButtonSendMessage;
