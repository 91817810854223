import styled from 'styled-components';



export const CardStarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CardStarImageContainer = styled.a`
  overflow: hidden;
  width: 90%;
  aspact-ratio: 1;
  border-radius: 5px;
  padding: 2px;
  background: linear-gradient(45deg, #f1e294, #ae7028);
`;

export const CardStarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;



export const CardStarUserInfoView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CardStarUserText = styled.p`
  color: #ffda70;
  font-size: calc(16px + .1vw);
  font-weight: 700;
  text-align: center;
`;

export const CardStartUsernameText = styled.p`
  margin-top: 3px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
`;
