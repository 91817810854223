export enum EStatusVisible {
  ARCHIVED = 'ARCHIVED',
  VISIBLE = 'VISIBLE',
  INVISIBLE = 'INVISIBLE',
  HIDE_BY_USER = 'HIDE_BY_USER',
  DELETED = 'DELETED',
}



export default EStatusVisible;
