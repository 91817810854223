import React from 'react';

import CreditFlag from '../CreditFlag';

import {
  PaymentChooseContainer,
  PaymentInfoChooseView,
  PaymentChooseTitle,
  PaymentChooseCardNumber,
  PaymentChooseSubtitle,
  PaymentCardView,
  PaymentParcelsCardView,
} from './styled';



export interface IProps {
  noChangeCard?: boolean;
  last4?: string | null;
  cardBrand?: string;
  parcels?: number;
  onPress?: any;
}



const PaymentChoose: React.FC<IProps> = (props: IProps) => {
  return (

    <PaymentChooseContainer
      disabled={props.noChangeCard}
      onClick={!props.noChangeCard ? props.onPress : null}>

      <PaymentInfoChooseView>

        <PaymentChooseTitle>
          Forma de Pagamento
        </PaymentChooseTitle>


        <PaymentChooseCardNumber>
          {props.last4 ? `**** **** **** ${props.last4}` : 'Sem cartão cadastrado'}
        </PaymentChooseCardNumber>


        {!props.noChangeCard && (
          <PaymentChooseSubtitle>
            {props.last4 ? 'Clique para Alterar' : 'Clique para Adicionar'}
          </PaymentChooseSubtitle>
        )}


        {props.noChangeCard && props.parcels && (
          <PaymentChooseSubtitle>
            {props.parcels === 1 ? 'À Vista' : `${props.parcels} Vezes`}
          </PaymentChooseSubtitle>
        )}

      </PaymentInfoChooseView>



      <PaymentCardView>
        <CreditFlag
          type={props.cardBrand}
        />

        <PaymentParcelsCardView>
          {props.cardBrand?.toString().toUpperCase() || ''}
        </PaymentParcelsCardView>
      </PaymentCardView>

    </PaymentChooseContainer>

  );
};



export default PaymentChoose;
