import {
  IInputPlaceSelect,
} from '../../../apis/interfaces/App/input-select-place.interface';



const CitiesFullBraRs: Array<IInputPlaceSelect> = [
  {
    id: 1,
    label: 'Caxias do Sul',
    value: 'CAXIAS_DO_SUL',
  },
  {
    id: 2,
    label: 'Porto Alegre',
    value: 'PORTO_ALEGRE',
  },
  {
    id: 3,
    label: 'Pelotas',
    value: 'PELOTAS',
  },
  {
    id: 4,
    label: 'Canoas',
    value: 'CANOAS',
  },
  {
    id: 5,
    label: 'Santa Maria',
    value: 'SANTA_MARIA',
  },
  {
    id: 6,
    label: 'Gravataí',
    value: 'GRAVATAI',
  },
  {
    id: 7,
    label: 'Viamão',
    value: 'VIAMAO',
  },
  {
    id: 8,
    label: 'Novo Hamburgo',
    value: 'NOVO_HAMBURGO',
  },
  {
    id: 9,
    label: 'São Leopoldo',
    value: 'SAO_LEOPOLDO',
  },
  {
    id: 10,
    label: 'Alvorada',
    value: 'ALVORADA',
  },
];



export default CitiesFullBraRs;
