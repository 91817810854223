import styled,
{
  css,
} from 'styled-components';

import Color from 'color';

import {
  GetPlatform,
} from '../../../config/plataform.config';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';



export interface IProps {
  bold?: boolean;
  disabled?: boolean;
  width?: number | string;
  height?: number;
  backgroundColor?: string;
  color?: string;
  borderColor?: string;
}


export const ButtonHover = css`
  :hover {
    transform: translateY(-3px);
    box-shadow: none;
  }
`;

export const ButtonWidth = css`
  width: ${(props: IProps) => typeof props.width === 'string' ? props.width : `${props.width}px`};
  min-width: ${(props: IProps) => typeof props.width === 'string' ? props.width : `${props.width}px`};
  max-width: ${(props: IProps) => typeof props.width === 'string' ? props.width : `${props.width}px`};
`;

export const BorderButtonStyle = css`
  border-color: ${(props: IProps) => props.borderColor};
  border-style: solid;
  border-width: 2px;
`;

export const ButtonActionContainer = styled.a`
  display: flex;
  flex-direction: row;
  /* flex: 1; */
  align-items: center;
  justify-content: center;
  
  width: ${(props: IProps) => props.width && ButtonWidth};

  height: ${(props: IProps) => props.height}px;
  min-height: ${(props: IProps) => props.height || Sizes.buttonHeight}px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: ${(props: IProps) => props.backgroundColor};
  border-radius: 5px;
  
  box-shadow: 0 10px 20px -10px ${(props: IProps) =>
    Color(props.backgroundColor === Colors.transparent ? props.borderColor : props.backgroundColor)
      .alpha(0.5)
      .toString()};

  transition: all 0.3s ease-out;

  opacity: ${(props: IProps) => props.disabled ? Sizes.opacityOff : Sizes.opacityOn};

  ${(props: IProps) => props.borderColor && BorderButtonStyle}

  ${(props: IProps) => !props.disabled && GetPlatform() === 'WEB' && ButtonHover}
`;


export const IconLeftView = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`;

export const IconRightView = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;


export const ButtonActionText = styled.p`
  padding-top: 1px;
  color: ${(props: IProps) => props.color};
  font-size: 15px;
  text-align: center;
  font-weight: ${(props: IProps) => props.bold ? 'bold' : 'normal'};
`;

export const ButtonChildrenAction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
