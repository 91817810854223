import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';



export const ModeleBoostContainer = styled.a`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  height: ${Sizes.contentRentHeight}px;
  padding: 10px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;

  @media ${PlatformDevices.maxTabletM} {
    width: 100%;
  }
`;

export const ModeleBoostLogoImage = styled.img`
  height: 30px;
  margin-top: 10px;
  margin-bottom: 25px;
  object-fit: contain;
`;



export const ModeleBoostWrapperContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;

  @media ${PlatformDevices.maxTabletM} {
    flex-direction: column;
    align-items: center;
  }
`;


export const ModeleBoostImageContent = styled.div`
  position: relative;
  height: 100%;
  width: 220px;
  background-color: ${Colors.black};
  box-shadow: 2px 2px 30px 0px rgba(0, 0, 0, 0.35);
`;

export const ModeleBoostImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
`;

export const ModeleBoostRibbonImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  height: 80px;
  width: 80px;
  object-fit: cover;
  object-position: top center;
`;



export const ModeleBoostInfoContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 20px;

  @media ${PlatformDevices.maxTabletM} {
    margin-top: 20px;
    align-items: center;
  }
`;

export const ModeleBoostTitleContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 5px;
`;

export const ModeleBoostOnlineBadge = styled.div`
  width: 15px;
  height: 15px;
  margin-right: 10px;
  background-color: ${Colors.accept};
  border-radius: 50%;
`;

export const ModeleBoostTitle = styled.p`
  display: flex;
  flex: 1;
  color: ${Colors.white};
  font-size: 30px;
  line-height: 33px;
`;


export const ModeleBoostSubtitleContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ModeleBoostSubtitle = styled.p`
  color: ${Colors.white};
  font-size: 16px;
`;

export const ModeleBoostVerifiedIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-left: 4px;
`;


