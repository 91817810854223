import {
  createAction,
  createReducer,
} from '@reduxjs/toolkit';



const INITIAL_STATE = {
  actualLikes: [] as any,
};



// Action Types

export const LIKES_SET = '@likes/SET';



// Action Creators

export const likesAction = createAction<object>(LIKES_SET);



// Handlers

const setLikes = (state = INITIAL_STATE, action: any) => ({
  ...state,
  actualLikes: action.payload,
});



// Reducers

export default createReducer(INITIAL_STATE, {
  [likesAction.type]: setLikes,
});
