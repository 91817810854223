import CitiesFullBraAc from '../arrays/Address/bra-ac.array';
import CitiesFullBraAl from '../arrays/Address/bra-al.array';
import CitiesFullBraAm from '../arrays/Address/bra-am.array';
import CitiesFullBraAp from '../arrays/Address/bra-ap.array';
import CitiesFullBraBa from '../arrays/Address/bra-ba.array';
import CitiesFullBraCe from '../arrays/Address/bra-ce.array';
import CitiesFullBraDf from '../arrays/Address/bra-df.array';
import CitiesFullBraEs from '../arrays/Address/bra-es.array';
import CitiesFullBraGo from '../arrays/Address/bra-go.array';
import CitiesFullBraMa from '../arrays/Address/bra-ma.array';
import CitiesFullBraMg from '../arrays/Address/bra-mg.array';
import CitiesFullBraMs from '../arrays/Address/bra-ms.array';
import CitiesFullBraMt from '../arrays/Address/bra-mt.array';
import CitiesFullBraPa from '../arrays/Address/bra-pa.array';
import CitiesFullBraPb from '../arrays/Address/bra-pb.array';
import CitiesFullBraPe from '../arrays/Address/bra-pe.array';
import CitiesFullBraPi from '../arrays/Address/bra-pi.array';
import CitiesFullBraPr from '../arrays/Address/bra-pr.array';
import CitiesFullBraRj from '../arrays/Address/bra-rj.array';
import CitiesFullBraRn from '../arrays/Address/bra-rn.array';
import CitiesFullBraRo from '../arrays/Address/bra-ro.array';
import CitiesFullBraRr from '../arrays/Address/bra-rr.array';
import CitiesFullBraRs from '../arrays/Address/bra-rs.array';
import CitiesFullBraSc from '../arrays/Address/bra-sc.array';
import CitiesFullBraSe from '../arrays/Address/bra-se.array';
import CitiesFullBraSp from '../arrays/Address/bra-sp.array';
import CitiesFullBraTo from '../arrays/Address/bra-to.array';
import StatesFullBra from '../arrays/Address/bra.array';
import CitiesFullItaVen from '../arrays/Address/ita-ven.array';
import StatesFullIta from '../arrays/Address/ita.array';



// Função para retornar as cidades do Brasil
function returnCityArrayBrazil(state: string) {
  switch (state) {
    case 'AC':
      return CitiesFullBraAc;
    case 'AL':
      return CitiesFullBraAl;
    case 'AP':
      return CitiesFullBraAp;
    case 'AM':
      return CitiesFullBraAm;
    case 'BA':
      return CitiesFullBraBa;
    case 'CE':
      return CitiesFullBraCe;
    case 'DF':
      return CitiesFullBraDf;
    case 'ES':
      return CitiesFullBraEs;
    case 'GO':
      return CitiesFullBraGo;
    case 'MA':
      return CitiesFullBraMa;
    case 'MT':
      return CitiesFullBraMt;
    case 'MS':
      return CitiesFullBraMs;
    case 'MG':
      return CitiesFullBraMg;
    case 'PA':
      return CitiesFullBraPa;
    case 'PB':
      return CitiesFullBraPb;
    case 'PR':
      return CitiesFullBraPr;
    case 'PE':
      return CitiesFullBraPe;
    case 'PI':
      return CitiesFullBraPi;
    case 'RJ':
      return CitiesFullBraRj;
    case 'RN':
      return CitiesFullBraRn;
    case 'RS':
      return CitiesFullBraRs;
    case 'RO':
      return CitiesFullBraRo;
    case 'RR':
      return CitiesFullBraRr;
    case 'SC':
      return CitiesFullBraSc;
    case 'SP':
      return CitiesFullBraSp;
    case 'SE':
      return CitiesFullBraSe;
    case 'TO':
      return CitiesFullBraTo;
    default:
      return [];
  }
}


// Função para retornar as cidades da Itália
function returnCityArrayItaly(state: string) {
  switch (state) {
    case 'VENETO':
      return CitiesFullItaVen;
    default:
      return [];
  }
}



// Função principal que chama as funções específicas de acordo com o país
export function returnCityArray(country: string, state: string) {
  switch (country) {
    case 'BRA':
      return returnCityArrayBrazil(state);
    case 'ITA':
      return returnCityArrayItaly(state);
    default:
      return [];
  }
}



// Função para retornar os estados de acordo com o país
export function returnStateArray(country: string) {
  switch (country) {
    case 'BRA':
      return StatesFullBra;
    case 'ITA':
      return StatesFullIta;
    default:
      return [];
  }
}
