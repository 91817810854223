import React,
{
  useRef,
  useState,
} from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  IInputSelect,
} from '../../../../apis/interfaces/App/input-select.interface';

import {
  RootState,
} from '../../../../redux';

import { picureVisibilityGenders } from '../../../../shared/arrays/Picture/visibility.array';

import {
  Colors,
  Images,
} from '../../../../shared/constants';

import EStatusVisible from '../../../../shared/enums/Status/status-visibility.enum';

import {
  validateString,
} from '../../../../shared/utils/string.utils';

import {
  IconEdit,
  IconEyeOff,
  IconEyeOn,
  IconMoney,
  IconPlay,
  IconSoundOff,
  IconSoundOn,
} from '../../../Icons';

import {
  VideoBoxContainer,
  VideoBoxContent,
  VideoContainer,
  LazyImage,
  RibbonPhotoLevel,
  RibbonPending,
  RibbonTopInfo,
  RibbonVideoDuration,
  RibbonHdVideoInserted,
  VideoIcon,
  CardControlsContent,
  CanBuyRibbon,
  ImageActionContent,
  ImageActionButton,
  HideVideoContent,
} from './styled';



export interface IProps {
  image?: string;
  visible?: EStatusVisible;
  visibility?: string;
  revised?: boolean;
  canBuy?: boolean;
  duration?: string;
  isHD?: boolean;
  onEditPress?: any;
  onShowHidePress?: any;
}



const CardAddVideo: React.FC<IProps> = (props: IProps) => {
  const userImage = validateString(props.image) || Images.noImage;


  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);


  const videoPlayerRef = useRef(null);

  const [isPlayed, setIsPlayed] = useState(false);
  const [isMuted, setIsMuted] = useState(false);



  const handleVideoClick = () => {
    const video = videoPlayerRef?.current;
    if (isPlayed) {
      video?.pause();
      setIsPlayed(false);
    }
    else {
      video?.play();
      setIsPlayed(true);
    }
  };


  const handleMute = (isMuted) => {
    videoPlayerRef.current.muted = isMuted;
    setIsMuted(isMuted);
  };


  const handleVideoEnded = () => {
    setIsPlayed(false);
  };



  return (

    <VideoBoxContainer>
      <VideoBoxContent>

        <VideoContainer>

          <LazyImage
            ref={videoPlayerRef}
            onClick={() => {
              handleVideoClick();
            }}
            onTouchStart={() => {
              handleVideoClick();
            }}
            onEnded={() => {
              handleVideoEnded();
            }}>
            <source src={userImage} />
            Seu navegador não suporta a reprodução de vídeos HTML5.
          </LazyImage>


          <RibbonPhotoLevel>
            {picureVisibilityGenders?.find((item: IInputSelect) => item.value === props.visibility)?.label || '-'}
          </RibbonPhotoLevel>



          {!props.revised && (
            <RibbonPending>
              Pendente de aprovação
            </RibbonPending>
          )}



          <RibbonTopInfo>
            {props.isHD && (
              <RibbonHdVideoInserted>
                HD
              </RibbonHdVideoInserted>
            )}

            <RibbonVideoDuration>
              {props.duration || '00:00'}
            </RibbonVideoDuration>
          </RibbonTopInfo>



          {!isPlayed && (
            <VideoIcon
              onClick={() => {
                handleVideoClick();
              }}>
              <IconPlay
                color={Colors.white}
                size={32}
              />
            </VideoIcon>
          )}



          <CardControlsContent>

            {props.canBuy && (
              <CanBuyRibbon>
                <IconMoney
                  color={Colors.white}
                  size={18}
                />
              </CanBuyRibbon>
            )}


            <ImageActionButton
              onClick={() => {
                if (isMuted) {
                  handleMute(false);
                  return;
                }

                handleMute(true);
              }}>
              {isMuted
                ? (
                  <IconSoundOff
                    color={Colors.white}
                    size={18}
                  />
                )
                : (
                  <IconSoundOn
                    color={Colors.white}
                    size={18}
                  />
                )
              }
            </ImageActionButton>

          </CardControlsContent>



          <ImageActionContent>

            {projectParams?.showFanClub && (
              <ImageActionButton
                onClick={() => {
                  if (props.onEditPress) {
                    props.onEditPress();
                  }
                }}>
                <IconEdit
                  color={Colors.white}
                  size={18}
                />
              </ImageActionButton>
            )}


            <ImageActionButton
              onClick={() => {
                if (props.onShowHidePress) {
                  props.onShowHidePress();
                }
              }}>
              {props.visible === EStatusVisible.VISIBLE
                ? (
                  <IconEyeOff
                    color={Colors.white}
                    size={18}
                  />
                )
                : (
                  <IconEyeOn
                    color={Colors.white}
                    size={18}
                  />
                )}
            </ImageActionButton>

          </ImageActionContent>

        </VideoContainer>



        {props.visible !== EStatusVisible.VISIBLE && (
          <HideVideoContent>
            Vídeo oculto
          </HideVideoContent>
        )}

      </VideoBoxContent>
    </VideoBoxContainer>

  );
};



export default CardAddVideo;
