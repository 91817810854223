import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  Body,
  Screen,
  SubTitle,
  Title,
} from '../../../components/Composh/web';

import {
  ButtonDrawer,
} from '../../../components/Controls';

import HeaderScreen from '../../../components/Headers/HeaderScreen';

import {
  IconDashboard,
  IconPromo,
} from '../../../components/Icons';

import TabBarStyled from '../../../components/TabBar';

import {
  SetLightGenreText,
} from '../../../config/genre.config';

import {
  RootState,
} from '../../../redux';

import {
  APP_MENU_SELECTED_ACTION,
} from '../../../redux/reducers/app-modals.store';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import EDrawerMenu from '../../../shared/enums/App/drawer-menu.enum';

import {
  TranslateConfig,
} from '../../../shared/translations';

import TabLivePage from './Tabs/TabLive';
import TabPromoPage from './Tabs/TabPromos';



const LiveHomeScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const genderChoose = useSelector((state: RootState) => state.choose.genderChoose);

  const genderColorChoose = useSelector((state: RootState) => state.choose.genderColorApp);
  const accentGender = genderColorChoose;

  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);


  const iconTabBar = Sizes.iconTabBar;

  const [stateIndex, setStateIndex] = useState(0);



  const screenTabs = [
    {
      visible: true,
      name: 'HOME',
      title: translate(TranslateConfig.HOME),
      icon: (
        <IconDashboard
          color={stateIndex === 0 ? accentGender : Colors.inputCount}
          size={iconTabBar}
        />
      ),
      component: (
        <TabLivePage />
      ),
    },
    {
      visible: true,
      name: 'PROMOS',
      title: translate(TranslateConfig.LIVE_PROMOS),
      icon: (
        <IconPromo
          color={stateIndex === 1 ? accentGender : Colors.inputCount}
          size={iconTabBar}
        />
      ),
      component: (
        <TabPromoPage />
      ),
    },
  ];



  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.LIVE });
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        showBalance
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={projectParams?.canAccess
          ? (
            <ButtonDrawer
              color={Colors.white}
              size={27}
            />
          )
          : null
        }
        centerContent={
          <Body>
            <Title
              color={Colors.white}>
              {translate(TranslateConfig.LIVE_ROOMS)}
            </Title>

            <SubTitle>
              {translate(SetLightGenreText(genderChoose))}
            </SubTitle>
          </Body>
        }
        noSubToolbarPadding>


        <TabBarStyled
          tabs={screenTabs}
          selected={stateIndex}
          onPress={(tab) => {
            setStateIndex(tab?.index);
          }}
        />

      </HeaderScreen>



      {stateIndex === 0 && screenTabs[0].component}

      {stateIndex === 1 && screenTabs[1].component}

    </Screen>

  );
};



export default LiveHomeScreen;
