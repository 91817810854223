import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../../../shared/constants';

import {
  PlatformDevices,
} from '../../../../../shared/utils/platforms.utils';

const sizeImage = 200;



export const PrincipalContentPadder = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  margin-bottom: 20px;
`;

export const ContentCenterFlex = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;


export const PrincipalImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 5px;
  padding-left: ${Sizes.paddingContainer}px;
  padding-right: ${Sizes.paddingContainer}px;

  @media ${PlatformDevices.maxLaptopL} {
    flex-direction: column;
  }
`;


export const PrincipalAvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  margin-bottom: 0px;

  @media ${PlatformDevices.maxLaptopL} {
    flex-direction: column;
    margin-right: 0px;
    margin-bottom: 30px;
  }
`;

export const PrincipalAvatarContent = styled.a`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${sizeImage}px;
  aspect-ratio: 9 / 16;
  margin-top: 5px;
  background-color: ${Colors.black};
  border-color: ${Colors.gray};
  border-style: solid;
  border-width: 3px;
  border-radius: ${Sizes.cardRadius}px;
  z-index: 5;

  @media ${PlatformDevices.maxTabletM} {
    margin-left: 0;
  }
`;

export const PrincipalAvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
`;

export const PrincipalAvatarIcon = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: -10px;
  bottom: -10px;
  padding: 8px;
  background-color: ${Colors.gray};
  border-radius: 50%;
  z-index: 10;
`;



export const PrincipalBackgroundContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${PlatformDevices.maxTabletM} {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  @media ${PlatformDevices.minTabletM} {
    width: 50%;
    min-width: 50%;
    max-width: 50%;
  }

  @media ${PlatformDevices.minLaptop} {
    width: calc(100% / 3);
    min-width: calc(100% / 3);
    max-width: calc(100% / 3);
  }
  
  @media ${PlatformDevices.minDesktop} {
    width: 25%;
    min-width: 25%;
    max-width: 25%;
  }
  
  @media ${PlatformDevices.minDesktopL} {
    width: 20%;
    min-width: 20%;
    max-width: 20%;
  }
`;

export const PrincipalBackgroundContent = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  height: ${sizeImage}px;
  margin-top: 5px;
  background-color: ${Colors.black};
  border-color: ${Colors.gray};
  border-style: solid;
  border-width: 3px;
  border-radius: ${Sizes.cardRadius}px;
  z-index: 0;
`;

export const PrincipalBackgroundButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  right: 10px;
  bottom: 10px;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  background-color: ${Colors.menuOverlay};

  @media ${PlatformDevices.maxTabletM} {
    top: 10px;
    bottom: auto;
  }
`;

export const PrincipalBackgroundIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 9px;
`;

export const PrincipalBackgroundText = styled.p`
  color: ${Colors.white};
  font-size: 16px;
`;

export const PrincipalBackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;



export const MediaTypeContent = styled.div`
  display: flex;
  flex-direction: column;
  width: ${Sizes.contentRightHomeWidth}px;
  margin-left: ${Sizes.contentMargin}px;
  padding-top: 20px;
  padding-left: 5px;
  padding-right: ${Sizes.paddingContainer}px;
  padding-bottom: ${Sizes.paddingContainer}px;

  @media ${PlatformDevices.maxLaptopL} {
    display: none;
  }
`;
