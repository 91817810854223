import {
  EPictureRelation,
} from '../../shared/enums';

import SecretioWS from '../index';



export const UpdateUserPicture = async (
  userId: string,
  picture: any,
  payload: any,
) => {
  const requestConfig = {
    headers: {
      'accept': 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  };

  const form = new FormData();
  form.append('name', picture?.name || 'UserProfile');
  form.append('type', picture?.type || '');
  form.append('image', picture);

  payload?.typeRelation && form.append('typeRelation', payload?.typeRelation);
  payload?.visibility && form.append('visibility', payload?.visibility);
  payload?.caption && form.append('caption', payload?.caption);
  payload?.canBuy && form.append('canBuy', payload?.canBuy);

  // form.append('image',
  //   {
  //     uri: picture.uri,
  //     name: 'user-profile-' + FilenameFromPath(picture.uri),
  //     type: 'image/jpg',
  //   } as any);

  const url = `/app/pictures/${EPictureRelation.USER}/image/${userId}`;

  const pictureResponse = await SecretioWS.post(url, form, requestConfig);
  return pictureResponse.data;
};



export const UpdateUserVideo = async (
  userId: string,
  picture: any,
  payload: any,
) => {
  const requestConfig = {
    headers: {
      'accept': 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  };

  const form = new FormData();
  form.append('name', picture?.name || 'UserProfile');
  form.append('type', picture?.type || '');
  form.append('image', picture);

  payload?.typeRelation && form.append('typeRelation', payload?.typeRelation);
  payload?.visibility && form.append('visibility', payload?.visibility);
  payload?.caption && form.append('caption', payload?.caption);
  payload?.canBuy && form.append('canBuy', payload?.canBuy);

  // form.append('image',
  //   {
  //     uri: picture.uri,
  //     name: 'user-profile-' + FilenameFromPath(picture.uri),
  //     type: 'image/jpg',
  //   } as any);

  const url = `/app/pictures/${EPictureRelation.USER}/video/${userId}`;

  const pictureResponse = await SecretioWS.post(url, form, requestConfig);
  return pictureResponse.data;
};



export const PostAntiFakeVideo = async (
  userId: string,
  picture: any,
  payload: any,
) => {
  const requestConfig = {
    headers: {
      'accept': 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  };

  const form = new FormData();
  form.append('name', picture?.name || 'UserProfile');
  form.append('type', picture?.type || '');
  form.append('image', picture);

  payload?.typeRelation && form.append('typeRelation', payload?.typeRelation);
  payload?.visibility && form.append('visibility', payload?.visibility);
  payload?.showFace && form.append('showFace', payload?.showFace);

  // form.append('image',
  //   {
  //     uri: picture.uri,
  //     name: 'user-profile-' + FilenameFromPath(picture.uri),
  //     type: 'image/jpg',
  //   } as any);

  const url = `/app/pictures/${EPictureRelation.USER}/anti-fake/${userId}`;

  const pictureResponse = await SecretioWS.post(url, form, requestConfig);
  return pictureResponse.data;
};



export const ShowHideUserPicture = async (userId: string, idPicture: any) => {
  const url = `/app/pictures/${EPictureRelation.USER}/visible/${idPicture}`;

  const payload = {
    idUser: userId,
  };

  const response = await SecretioWS.put(url, {
    data: payload,
  });
  return response.data;
};



export const DeleteUserPicture = async (userId: string, idPicture: any) => {
  const url = `/app/pictures/${EPictureRelation.USER}/${idPicture}`;

  const payload = {
    idUser: userId,
  };

  const response = await SecretioWS.delete(url, {
    data: payload,
  });
  return response.data;
};
