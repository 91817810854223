import {
  IInputSelect,
} from '../../../apis/interfaces/App/input-select.interface';



export const hipArray: Array<IInputSelect> = [
  { id: 1, label: '80 cm - US 36.1', value: 'HIP_80CM' },
  { id: 2, label: '81 cm - US 36.2', value: 'HIP_81CM' },
  { id: 3, label: '82 cm - US 36.3', value: 'HIP_82CM' },
  { id: 4, label: '83 cm - US 37.1', value: 'HIP_83CM' },
  { id: 5, label: '84 cm - US 37.2', value: 'HIP_84CM' },
  { id: 6, label: '85 cm - US 37.3', value: 'HIP_85CM' },
  { id: 7, label: '86 cm - US 37.4', value: 'HIP_86CM' },
  { id: 8, label: '87 cm - US 38.1', value: 'HIP_87CM' },
  { id: 9, label: '88 cm - US 38.2', value: 'HIP_88CM' },
  { id: 10, label: '89 cm - US 38.3', value: 'HIP_89CM' },
  { id: 11, label: '90 cm - US 39.1', value: 'HIP_90CM' },
  { id: 12, label: '91 cm - US 39.2', value: 'HIP_91CM' },
  { id: 13, label: '92 cm - US 39.3', value: 'HIP_92CM' },
  { id: 14, label: '93 cm - US 39.4', value: 'HIP_93CM' },
  { id: 15, label: '94 cm - US 40.1', value: 'HIP_94CM' },
  { id: 16, label: '95 cm - US 40.2', value: 'HIP_95CM' },
  { id: 17, label: '96 cm - US 40.3', value: 'HIP_96CM' },
  { id: 18, label: '97 cm - US 40.4', value: 'HIP_97CM' },
  { id: 19, label: '98 cm - US 41.1', value: 'HIP_98CM' },
  { id: 20, label: '99 cm - US 41.2', value: 'HIP_99CM' },
  { id: 21, label: '100 cm - US 41.3', value: 'HIP_100CM' },
  { id: 22, label: '101 cm - US 41.4', value: 'HIP_101CM' },
  { id: 23, label: '102 cm - US 42.1', value: 'HIP_102CM' },
  { id: 24, label: '103 cm - US 42.2', value: 'HIP_103CM' },
  { id: 25, label: '104 cm - US 42.3', value: 'HIP_104CM' },
  { id: 26, label: '105 cm - US 42.4', value: 'HIP_105CM' },
  { id: 27, label: '106 cm - US 43.1', value: 'HIP_106CM' },
  { id: 28, label: '107 cm - US 43.2', value: 'HIP_107CM' },
  { id: 29, label: '108 cm - US 43.3', value: 'HIP_108CM' },
  { id: 30, label: '109 cm - US 43.4', value: 'HIP_109CM' },
  { id: 31, label: '110 cm - US 44.1', value: 'HIP_110CM' },
  { id: 32, label: '111 cm - US 44.2', value: 'HIP_111CM' },
  { id: 33, label: '112 cm - US 44.3', value: 'HIP_112CM' },
  { id: 34, label: '113 cm - US 44.4', value: 'HIP_113CM' },
  { id: 35, label: '114 cm - US 45.1', value: 'HIP_114CM' },
  { id: 36, label: '115 cm - US 45.2', value: 'HIP_115CM' },
  { id: 37, label: '116 cm - US 45.3', value: 'HIP_116CM' },
  { id: 38, label: '117 cm - US 45.4', value: 'HIP_117CM' },
  { id: 39, label: '118 cm - US 46.1', value: 'HIP_118CM' },
  { id: 40, label: '119 cm - US 46.2', value: 'HIP_119CM' },
  { id: 41, label: '120 cm - US 46.3', value: 'HIP_120CM' },
];
