import _fanclubAvatar from '../assets/svgs/fanclub-avatar.svg';



export const FANCLUBS = {
  fanclubAvatar:                    _fanclubAvatar,
};



const Svgs = {
  ...FANCLUBS,
};



export default Svgs;
