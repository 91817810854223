import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../../shared/constants';



export const PostCreateContainer = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;


export const PostCreateAvatarView = styled.div`
  overflow: hidden;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: ${Sizes.cardRadius}px;
`;


export const PostCreateInputView = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  height: 45px;
  padding-left: 20px;
  background-color: ${Colors.cardOverlay};
  border-radius: 35px;
`;

export const PostCreateInputText = styled.p`
  margin-left: 10px;
  color: ${Colors.gray};
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
`;
