import React from 'react';

import {
  useSelector,
} from 'react-redux';

import Color from 'color';

import {
  RootState,
} from '../../../redux';

import {
  Colors,
} from '../../../shared/constants';

import {
  Button,
  Input,
} from '../../Composh/web';

import {
  InputBooleanContainer,
  InputBooleanButton,
  InputBooleanText,
} from './styled';



export interface IProps {
  disabled?: any;
  noShadow?: any;
  backgroundColor?: any;
  // iconLeft?: any;
  // iconRight?: any;
  labelText?: any;
  // placeholderColor?: any;
  // inputTextColor?: any;
  placeholderText?: any;
  helpText?: any;
  // countLimit?: any;
  borderColor?: any;
  value?: any;
  onChange?: any;
  // onBlur?: any;

  selected?: boolean;

  color?: string;

  name?: string;
  array?: Array<any>;
  onPress?: any;
}



const InputBoolean: React.FC<IProps> = (props: IProps) => {
  const genderColorChoose = useSelector((state: RootState) => state.choose.genderColorApp);
  const accentGender = props.color || Color(genderColorChoose).lighten(0.1).toString();

  const disabled = props.disabled;



  function renderBooleanButton(text: string, state: boolean) {
    return (

      <InputBooleanButton
        onClick={() => {
          if (!disabled) {
            props.onPress(state);
          }
        }}>

        <Button.Circle
          margin={false}
          active={Boolean(props.selected) === state}
          size={14}
          activeColor={accentGender}
          deactiveColor={Colors.inputPlaceholder}
        />

        <InputBooleanText
          color={Boolean(props.selected) === state ? accentGender : Colors.inputPlaceholder}>
          {text}
        </InputBooleanText>

      </InputBooleanButton>

    );
  }



  return (

    <Input.View
      {...props}

      disabled={props.disabled}
      noShadow={props.noShadow}

      backgroundColor={Colors.transparent}
      borderColor={props.borderColor}

      labelText={props.labelText}
      labelColor={Colors.inputLabel}

      helpText={props.helpText}
      helpColor={Colors.danger}

      countLimit={'Escolha um'}
      countColor={Colors.inputCount}>

      <InputBooleanContainer
        backgroundColor={props.backgroundColor || Colors.inputBackground}>

        {renderBooleanButton(
          'Sim',
          true,
        )}


        {renderBooleanButton(
          'Não',
          false,
        )}

      </InputBooleanContainer>

    </Input.View>

  );
};



export default InputBoolean;
