import React from 'react';

import {
  useSelector,
} from 'react-redux';
import {

  RenderRibbonImage,
} from '../../../config/plan.config';

import {
  RootState,
} from '../../../redux';

import {
  countriesOption,
} from '../../../shared/arrays/App/countries.array';

import { cockSizesArray } from '../../../shared/arrays/User/user-cock-size.array';
import { positionsArray } from '../../../shared/arrays/User/user-positions.array';

import {
  Colors,
  Images,
  Sizes,
} from '../../../shared/constants';

import {
  EPlansUser,
} from '../../../shared/enums';

import {
  stringRawDataText,
  validateString,
} from '../../../shared/utils/string.utils';

import {
  IconBed,
  IconPenis,
  IconPlane,
  IconVerified,
  IconVerifiedPromoter,
} from '../../Icons';

import {
  ProfileCardContainer,
  CardModelesRentContainer,
  RibbonLongImage,
  ModelesLongImage,
  InfoStyleView,
  TitleInfoLongView,
  BadgeComponent,
  ModelesTitle,
  SubtitleLongView,
  ModeleTravelBadgeView,
  ModelesSubtitle,
  IconVerifiedAlias,
  ModelesCharsContent,
  ModelesCharsItem,
  ModelesCharsIcon,
  ModelesCharsText,
  ModelesPlaceView,
  ModelesPlaceAtText,
  ModelesPlaceFlagCountry,
  ModelesPlaceCountry,
  ModelesPlaceText,
  BadgeModelsContainer,
  ModeleHoursImage,
  ModeleComeNowView,
  ModeleComeNowText,
} from './styled';



export interface IProps {
  relative?: boolean;
  disabled?: boolean;

  comeNow?: boolean;
  isOnTravel?: boolean;

  showFlag?: boolean;

  showCock?: boolean;
  cock?: string;

  showPosition?: boolean;
  position?: string;

  image?: any;
  plan?: string;

  name?: string;
  username?: string;
  online?: boolean;
  verified?: boolean;
  brandPromoter?: boolean;
  all24h?: boolean;

  labelPlace?: string;
  country?: string;
  place?: string;

  onPress?: any;
}



const CardModeles: React.FC<IProps> = (props: IProps) => {
  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);

  const countryValue = props.country;
  const filterCountry = countriesOption.find((country) => stringRawDataText(country?.code) === stringRawDataText(countryValue));



  function renderCardModele() {
    return (

      <CardModelesRentContainer
        comeNow={props.comeNow}
        backgroundColor={Colors.cardBackground}
        borderRadius={Sizes.cardRadius}
        onPress={() => {
          if (props.onPress) {
            props.onPress();
          }
        }}>

        <ModelesLongImage
          src={validateString(props.image) || Images.avatarUserImage}
        />



        <InfoStyleView>

          {props.isOnTravel && (
            <ModeleTravelBadgeView>
              <IconPlane
                color={Colors.white}
                size={14}
              />
            </ModeleTravelBadgeView>
          )}



          <TitleInfoLongView>
            {projectParams?.canBeOnline && props.online && (
              <BadgeComponent />
            )}

            <ModelesTitle>
              {props.name}
            </ModelesTitle>
          </TitleInfoLongView>



          <SubtitleLongView>

            <ModelesSubtitle>
              @{props.username}
            </ModelesSubtitle>


            {props.verified && (
              <IconVerifiedAlias>
                <IconVerified
                  color={Colors.white}
                  size={14}
                />
              </IconVerifiedAlias>
            )}


            {props.brandPromoter && (
              <IconVerifiedAlias>
                <IconVerifiedPromoter
                  color={Colors.white}
                  size={14}
                />
              </IconVerifiedAlias>
            )}

          </SubtitleLongView>



          {(props.showCock || props.showPosition) && (
            <ModelesCharsContent>

              {props.showCock && (
                <ModelesCharsItem>

                  <ModelesCharsIcon>
                    <IconPenis
                      color={'#B4B4B4'}
                      size={20}
                    />
                  </ModelesCharsIcon>

                  <ModelesCharsText>
                    {cockSizesArray.find((item) => item?.value === props.cock)?.label || '-'}
                  </ModelesCharsText>

                </ModelesCharsItem>
              )}



              {props.showPosition && (
                <ModelesCharsItem>

                  <ModelesCharsIcon>
                    <IconBed
                      color={'#B4B4B4'}
                      size={20}
                    />
                  </ModelesCharsIcon>

                  <ModelesCharsText>
                    {positionsArray.find((item) => item?.value === props.position)?.label || '-'}
                  </ModelesCharsText>

                </ModelesCharsItem>
              )}

            </ModelesCharsContent>
          )}



          {props.showFlag && !countryValue && (
            <ModelesPlaceView>

              <ModelesPlaceAtText>
                Localização indisponível
              </ModelesPlaceAtText>

            </ModelesPlaceView>
          )}



          {props.showFlag && countryValue && (
            <ModelesPlaceView>

              {props.labelPlace && (
                <ModelesPlaceAtText>
                  {props.labelPlace}:
                </ModelesPlaceAtText>
              )}


              <ModelesPlaceFlagCountry>
                <ModelesPlaceCountry
                  src={filterCountry?.image}
                />
              </ModelesPlaceFlagCountry>


              <ModelesPlaceText>
                {props.place || '-'}
              </ModelesPlaceText>

            </ModelesPlaceView>
          )}

        </InfoStyleView>



        {(props.plan && props.plan !== EPlansUser.NONE) && (
          <RibbonLongImage
            alt={'Ribbon'}
            src={RenderRibbonImage(props.plan as EPlansUser)}
          />
        )}


        <BadgeModelsContainer>

          {props.all24h && (
            <ModeleHoursImage
              src={Images.badge24h}
            />
          )}

        </BadgeModelsContainer>



        {props.comeNow && (
          <ModeleComeNowView>
            <ModeleComeNowText>
              Estou disponível
            </ModeleComeNowText>
          </ModeleComeNowView>
        )}

      </CardModelesRentContainer>

    );
  }



  if (props.relative) {
    return renderCardModele();
  }



  return (

    <ProfileCardContainer>
      {renderCardModele()}
    </ProfileCardContainer>

  );
};



CardModeles.defaultProps = {
  online: false,
};



export default CardModeles;
