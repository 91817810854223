import React from 'react';

import {
  useNavigate,
} from 'react-router-dom';

import NameRoutes from '../../../navigation/names';

import {
  Images,
} from '../../../shared/constants';

import {
  AddCreditView,
  AddCreditBalanceView,
  AddCreditCoinImage,
  AddCreditCountView,
  AddCreditSymbolText,
  AddCreditPriceText,
  AddCreditPriceSup,
  AddCreditButton,
  AddCreditTextButton,
} from './styled';



export interface IProps {
  hideAddMoney?: boolean;
  onPress?: any;
}



const AddCredit: React.FC<IProps> = (props) => {
  const history = useNavigate();



  return (

    <AddCreditView>

      <AddCreditBalanceView>

        <AddCreditCoinImage
          alt={'Coin'}
          src={Images.gencoin}
        />


        <AddCreditCountView>

          <AddCreditSymbolText>
            R$
          </AddCreditSymbolText>


          <AddCreditPriceText>
            00
            <AddCreditPriceSup>
              00
            </AddCreditPriceSup>
          </AddCreditPriceText>
        </AddCreditCountView>

      </AddCreditBalanceView>



      {!props.hideAddMoney && (
        <AddCreditButton
          onClick={() => {
            history(NameRoutes.AddMoneyScreen);

            if (props.onPress) {
              props.onPress();
            }
          }}>
          <AddCreditTextButton>
            {'Adicionar Saldo'.toUpperCase()}
          </AddCreditTextButton>
        </AddCreditButton>
      )}

    </AddCreditView>

  );
};



export default AddCredit;
