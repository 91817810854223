import React from 'react';

import {
  IconArrowLightBack,
  IconArrowLightForward,
} from '../../Icons';

import {
  Container,
  ArrowContainer,
  YearText,
} from './styled';



const YearSelect: React.FC = () => {
  return (

    <Container>

      <ArrowContainer>
        <IconArrowLightBack
          color={'#ffffff'}
          size={24}
        />
      </ArrowContainer>


      <YearText>
        2021
      </YearText>


      <ArrowContainer>
        <IconArrowLightForward
          color={'#ffffff'}
          size={24}
        />
      </ArrowContainer>

    </Container>

  );
};



export default YearSelect;
