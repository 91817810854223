import styled from 'styled-components';



export const ChipSuggestionChatView = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  margin-right: 5px;
  padding: 7px 15px 5px;
  background-color: #444444;
  border-radius: 40px;
`;

export const ChipSuggestionChatText = styled.p`
  color: #FFFFFF;
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
`;
