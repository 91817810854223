export const APP_COLORS = {
  primaryAccent:                    '#ffed94',
  primary:                          '#ffd60a',
  primaryDark:                      '#a38800',
  secondaryAccent:                  '#e15b5b',
  secondary:                        '#ff571f',
  secondaryDark:                    '#b82e00',
  tertiaryLight:                    '#1DA1F2',
  tertiary:                         '#185995',
  tertiaryDark:                     '#253649',
  accent:                           '#00FFFF',
  pulse:                            '#93ffaf',
  pulseAnimation:                   'rgba(0, 255, 0, 0.7)',
};


export const APP_DEFAULT = {
  black:                            '#000000',
  white:                            '#FFFFFF',
  grayLight:                        '#b5b5b5',
  gray:                             '#898989',
  grayDark:                         '#666666',
  muted:                            '#9FA5AA',
  unselected:                       '#6e7987',
  shadow:                           '#292929',
  transparent:                      'transparent',
  neutral:                          'rgba(255, 255, 255, 0.65)',
};


export const APP_GENRES = {
  male:                             '#3556f8',
  female:                           '#df2020',
  transMale:                        '#9035f8',
  transFemale:                      '#df07b3',
};


export const APP_STATUS = {
  danger:                           '#ef4e4e',
  warning:                          '#ffc600',
  attentionLight:                   '#ffa729',
  attention:                        '#FF7D35',
  accept:                           '#46c700',
  disabled:                         '#656565',
  sketch:                           '#d829ff',
};


export const APP_ITEMS = {
  appBackground:                    '#060818',
  appScrollbar:                     '#292d38',
};


export const ALERTS = {
  alertOverlay:                     'rgba(0, 0, 0, 0.65)',
  alertBackground:                  '#0c1022', // '#0d111c',
  alertTitleColor:                  '#FFFFFF',
};


export const BUTTONS = {
  buttonOkDark:                     '#947b00',
  buttonOk:                         '#e6bf00',
  buttonOkAccent:                   '#17d6cc',
  buttonOkLight:                    '#79bfb8',

  buttonAttentionDark:              '#502c11',
  buttonAttention:                  '#a35f1f',
  buttonAttentionAccent:            '#de7c21',

  buttonCancelDark:                 '#501111',
  buttonCancel:                     '#960A0A',
  buttonCancelAccent:               '#D61717',

  buttonActionDark:                 '#0b4a89',
  buttonAction:                     '#1266ba',
  buttonActionAccent:               '#208af3',

  buttonDisabledDark:               '#7d818c',
  buttonDisabled:                   '#a9adb7',

  buttonSolid:                      '#c6dbe7',
  buttonSolidDark:                  '#0C2238',
};


export const CARD = {
  cardBackground:                   '#191f2e',
  cardBackgroundHover:              '#121631',
  cardOverlay:                      'rgba(0, 0, 0, 0.55)',
  cardButton:                       '#79bfb8',
  cardFooterOverlay:                'rgba(0, 0, 0, 0.45)',
};


export const FORM = {
  formBackground:                   '#0e1726',
};


export const INPUTS = {
  inputBackground:                  '#1b2e4b', // '#1c2031',
  inputLabel:                       '#d3d3d3',
  inputPlaceholder:                 '#b0b0b0',
  inputCount:                       '#96a6b3',
  inputCard:                        '#2d2e3e',
  inputMediaAdded:                  '#5D5D5D',
};


export const LEVEL = {
  fanClubLevel:                      '#6d51a7',
  fanClubBackgroundLevel:            '#1e1231',
};


export const MENU = {
  menuBackground:                   '#0a101f',
  menuLine:                         '#162F40',
  menuOverlay:                      'rgba(0, 0, 0, 0.8)',
  menuSeparator:                    '#353535', // '#404040',
};


export const MODALS = {
  //
};


export const REFRESH = {
  refreshBackground:                '#e6e6ea',
};


export const TEXT = {
  textTitle:                        '#545460',
  textDescription:                  '#8a96a3',

  textSubtitle:                     '#797b82',
  textSubDescription:               '#696E7D',

  textApp:                          '#939393',
  subtextApp:                       'rgba(255, 255, 255, 0.5)',
};


export const TOOLBAR = {
  toolbarBackground:                '#1a1c2d',
  toolbarLine:                      '#303346',

  toolbarFixedBackground:           '#161827',

  tabBackground:                    '#1d2434',
  
  // Profile Colors
  // profileToolbar:                   '#0A0A23',
  // profileColorTop:                  '#131a1f',
  // profileColorBottom:               '#404d55',

  // Navigation Bottom Colors
  navigationBottomBackground:       '#0c141d',
  // navigationBottomLine:             '#101827',
};



const Colors = {
  ...APP_COLORS,
  ...APP_DEFAULT,
  ...APP_GENRES,
  ...APP_STATUS,
  ...APP_ITEMS,
  ...ALERTS,
  ...BUTTONS,
  ...CARD,
  ...FORM,
  ...INPUTS,
  ...LEVEL,
  ...MENU,
  ...MODALS,
  ...REFRESH,
  ...TEXT,
  ...TOOLBAR,
};



export default Colors;
