import React,
{
  useState,
} from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  RootState,
} from '../../../../redux';

import {
  Colors,
} from '../../../../shared/constants';

import {
  IconArrowDown,
} from '../../../Icons';

import InputCheckbox from '../../../Inputs/InputCheckbox';

import {
  RentServiceAccordionContainer,
  RentServiceAccordionHeader,
  RentServiceAccordionView,
  RentServiceAccordionTitle,
  RentServiceSpecialityText,
  RentServiceAccordionIcon,
  RentServiceAccordionContent,
  RentServiceAccordionText,
  RentServiceWarningText,
  RentServiceWarningButton,
  RentServiceSpecialityView,
} from './styled';



export interface IProps {
  edit: boolean;
  disabled?: boolean;
  doItem?: boolean;
  idService: number;
  colorService: string;
  speciallity?: boolean;
  title?: string;
  text?: string;
  warning?: string;
  onCheckboxChange?: (idService: number, doItem?: boolean, speciallity?: boolean) => void; // Função de callback
}



const SectionServices: React.FC<IProps> = (props: IProps) => {
  const colorService = props.colorService;


  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);

  const [open, setOpen] = useState(false);

  const [doItemChecked, setDoItemChecked] = useState(props.doItem);
  const [speciallityChecked, setSpeciallityChecked] = useState(props.speciallity);



  const handleDoItemChange = (checked: boolean) => {
    setDoItemChecked(checked);
    if (props.onCheckboxChange) {
      props.onCheckboxChange(props.idService, checked, speciallityChecked);
    }
  };


  const handleSpeciallityChange = (checked: boolean) => {
    setSpeciallityChecked(checked);
    if (props.onCheckboxChange) {
      props.onCheckboxChange(props.idService, doItemChecked, checked);
    }
  };



  function renderWarningMessage() {
    return (

      <RentServiceWarningText>
        O Secretio não o recomenda sem preservativo devido às chances de contágio de ISTs.
        {' '}
        {projectParams?.showBlog && (
          <RentServiceWarningButton
            onClick={() => {
              // TODO
            }}>
            Conheça os riscos e métodos de prevenção!
          </RentServiceWarningButton>
        )}
      </RentServiceWarningText>

    );
  }



  return (

    <RentServiceAccordionContainer
      onClick={() => {
        !props.edit && setOpen(!open);
      }}>

      <RentServiceAccordionHeader>

        <RentServiceAccordionView>

          <RentServiceAccordionTitle
            doItem={props.doItem}>
            {props.title}
          </RentServiceAccordionTitle>


          {!props.edit && props.doItem && props.speciallity && (
            <RentServiceSpecialityText>
              {'Minha especialidade'}
            </RentServiceSpecialityText>
          )}

        </RentServiceAccordionView>



        {!props.edit && (
          <RentServiceAccordionIcon>
            <IconArrowDown
              color={Colors.white}
              size={28}
            />
          </RentServiceAccordionIcon>
        )}

      </RentServiceAccordionHeader>



      {!props.edit && open && (
        <RentServiceAccordionContent>

          <RentServiceAccordionText
            doItem={props.doItem}>
            {props.text}
          </RentServiceAccordionText>


          {props.warning && renderWarningMessage()}

        </RentServiceAccordionContent>
      )}



      {props.edit && (
        <RentServiceAccordionContent>

          <RentServiceAccordionText
            doItem={props.doItem}>
            {props.text}
          </RentServiceAccordionText>


          {props.warning && renderWarningMessage()}


          <RentServiceSpecialityView>
            <InputCheckbox
              title={'Faço'}
              disabled={props.disabled}
              checked={doItemChecked}
              color={colorService}
              onPress={() => {
                handleDoItemChange(!doItemChecked);;
              }}
            />


            <InputCheckbox
              title={'Minha especialidade'}
              disabled={props.disabled}
              checked={speciallityChecked}
              color={colorService}
              onPress={() => {
                handleSpeciallityChange(!speciallityChecked);
              }}
            />
          </RentServiceSpecialityView>

        </RentServiceAccordionContent>
      )}

    </RentServiceAccordionContainer>

  );
};



export default SectionServices;
