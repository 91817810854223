import React from 'react';

import {
  Colors,
  Images,
} from '../../../../shared/constants';

import {
  IconLocked,
} from '../../../Icons';

import {
  PhotoGridOverlay,
  PhotoGridIconContent,
  PhotoGridSpecialText,
  PhotoGridFanClubContent,
  PhotoGridFanClubText,
  PhotoGridFanClubImagesContent,
  PhotoGridFanClubOrText,
  PhotoGridFanClubImage,
} from './styled';



const MediaFanClub: React.FC = () => {
  return (

    <PhotoGridOverlay>

      <PhotoGridIconContent>
        <IconLocked
          color={Colors.white}
          size={20}
        />

        <PhotoGridSpecialText>
          Fechada
        </PhotoGridSpecialText>
      </PhotoGridIconContent>


      <PhotoGridFanClubContent>
        <PhotoGridFanClubText>
          Disponível para
        </PhotoGridFanClubText>


        <PhotoGridFanClubImagesContent>
          <PhotoGridFanClubImage
            alt={''}
            src={Images.fanclubLogo}
          />

          <PhotoGridFanClubOrText>
            ou
          </PhotoGridFanClubOrText>

          <PhotoGridFanClubImage
            alt={''}
            src={Images.premiumWhiteMemberLogo}
          />
        </PhotoGridFanClubImagesContent>
      </PhotoGridFanClubContent>

    </PhotoGridOverlay>

  );
};



export default MediaFanClub;
