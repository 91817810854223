import React from 'react';

import {
  Content,
  Screen,
  Title,
} from '../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../components/Controls';

import EmptyContent from '../../../components/Empty';
import FooterApp from '../../../components/Footers/FooterApp';
import HeaderScreen from '../../../components/Headers/HeaderScreen';

import {
  Colors,
  Images,
} from '../../../shared/constants';



const ModelePrivateGalleryScreen: React.FC = () => {
  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noImage}
        title={'Nenhum pedido de acesso a galeria aqui ainda.'}
      />

    );
  }



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        disableGender
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            color={Colors.white}
            size={27}
          />
        }
        centerContent={
          <Title>
            Galeria privada
          </Title>
        }
      />



      <Content>

        {listEmptyComponent()}


        <FooterApp />

      </Content>

    </Screen>

  );
};



export default ModelePrivateGalleryScreen;
