// import Axios from 'axios';
// import AppEnvironments from '../../shared/environments/application';
import SecretioWS from '../index';

// const baseAccountGlobalUrl = AppEnvironments().apiAccountGlobalUrl;



// export const setAuthHeaders = (token: string) => {
//   (SecretioWS.defaults.headers as any).authorization = token;
// };



// export const AuthenticateUser = async (data: any) => {
//   const url = baseAccountGlobalUrl + '/auth';

//   const response = await Axios.post(url, data);

//   if (response.data.token) {
//     const newToken = response.data.token;

//     const userData = response.data.user;

//     return {
//       token: newToken,
//       user: userData,
//     };
//   }
//   else {
//     console.log('TOKEN NOT PRESENT');
//     console.log(response.data);
//   }
// };



// export const TryRevalidateSession = async (token: string | null) => {
//   if (token) {
//     const url = baseAccountGlobalUrl + '/auth/revalidate';

//     const response = await Axios.post(url, { token });

//     return response.data;
//   }
//   else {
//     return null;
//   }
// };



// export const GetUserByClientNumber = async (clientNumber: string) => {
//   const url = `/auth/${clientNumber}`;

//   const response = await SecretioWS.get(url);
//   return response.data;
// };



// export const CreateUserByClientNumber = async (paylaod: any) => {
//   const url = '/auth';

//   const response = await SecretioWS.post(url, paylaod);
//   return response.data;
// };



export const AccessAppByContaGlobal = async (paylaod: any) => {
  const url = '/app/auth';

  const response = await SecretioWS.post(url, paylaod);
  return response.data;
};



export const TryRevalidateSession = async (token: string | null) => {
  if (token) {
    const url = '/app/auth/revalidate';

    const response = await SecretioWS.post(url, { token });
    return response.data;
  }
  else {
    return null;
  }
};
