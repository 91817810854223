import styled from 'styled-components';

import {
  ListFlat,
} from '../../../../../components/Composh/web';

import {
  ContentPadder,
} from '../../../../../components/Contents/ContentPadder';

import SectionTitle from '../../../../../components/Titles/SectionTitle';



export const LiveWrapperItemContent = styled(ContentPadder)`
  display: flex;
  flex-direction: column;
`;



export const SectionTitleStyled = styled(SectionTitle)`
  margin-bottom: 20px;
`;



export const PromoItemGrid = styled(ListFlat).attrs({
  style: {
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainerStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
})`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
