import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useNavigate,
  useParams,
} from 'react-router-dom';

import {
  GetModeleDetails,
} from '../../../apis/endpoints/modeles.endpoints';

import {
  IViewMediaModal,
} from '../../../apis/interfaces/Medias/view-media-modal.interface';

import {
  Body,
  Container,
  Content,
  Screen,
  SubTitle,
  Title,
} from '../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../components/Controls';

import EmptyContent from '../../../components/Empty';
import FooterApp from '../../../components/Footers/FooterApp';
import HeaderScreen from '../../../components/Headers/HeaderScreen';

import HelmetLanguagesLinks from '../../../components/Helmet/LanguagesLinks';

import {
  IconMediaPhoto,
  IconMediaVideo,
  IconRentOn,
  IconCharacteristics,
  IconDashboard,
  IconRoomOpen,
  IconAvaliations,
} from '../../../components/Icons';

import LoadingScreen from '../../../components/Loadings/LoadingScreen';
import TabBarStyled from '../../../components/TabBar';

import FaClubModal from '../../../modals/FaClub';
import ModeleServicesModal from '../../../modals/ModeleServices';
import ShareModal from '../../../modals/Share';
import TravelScheduleModal from '../../../modals/TravelSchedule';
import ViewMediaModal from '../../../modals/ViewMedia';

import NameRoutes from '../../../navigation/names';

import {
  RootState,
} from '../../../redux';

import {
  APP_SNACKBAR_ACTION,
  USER_FACLUB_MODAL,
  USER_TRAVELS_MODAL,
} from '../../../redux/reducers/app-modals.store';

import {
  USER_GENDER_CHOOSE_ACTION,
} from '../../../redux/reducers/choose.store';

import {
  MODELES_RENT_SERVICES,
} from '../../../redux/reducers/modeles.store';

import {
  Colors,
  Images,
  Keys,
} from '../../../shared/constants';

import {
  EEnvsName,
  EGenderUser,
} from '../../../shared/enums';

import Vars from '../../../shared/environments/variables';

import Seo from '../../../shared/seo';

import {
  TranslateConfig,
} from '../../../shared/translations';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../shared/utils/errors.utils';

import { validateString } from '../../../shared/utils/string.utils';

import ModeleDetailHeader from './Headers';

import {
  DetailsContainer,
  CharRowWrapperAuto,
  CharRowWrapperCenterView,
} from './styled';

import TabCharacteristicsScreen from './Tabs/TabCharacteristics';
import TabCommentsScreen from './Tabs/TabComments';
import TabFeedScreen from './Tabs/TabFeed';
import TabLiveScreen from './Tabs/TabLive';
import TabPhotosScreen from './Tabs/TabMedia/TabPhotos';
import TabVideosScreen from './Tabs/TabMedia/TabVideos';
import TabRentScreen from './Tabs/TabRent';



export interface IRouteParams {
  id: string;
}



const ModeleDetailsScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { t: translate } = useTranslation();

  const route = useParams<keyof IRouteParams>();
  const paramsRoute = route as IRouteParams;


  const modeleId = paramsRoute?.id;

  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);

  const user = useSelector((state: RootState) => state.user.actualUser);
  const hideActions = modeleId === user?.useralias;

  const genderChoose = useSelector((state: RootState) => state.choose.genderChoose);
  const genderColorChoose = useSelector((state: RootState) => state.choose.genderColorApp);
  const accentGender = genderColorChoose; // Color(genderColorChoose).lighten(0.3).toString();

  const travelsModal = useSelector((state: RootState) => state.appModals.travelsModal);
  const showFaClub = useSelector((state: RootState) => state.appModals.faClubModal);

  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);


  const iconTabBar = 23;

  const [stateIndex, setStateIndex] = useState<{ index: number, key: string }>({ index: 0, key: 'HOME' });

  const [userModele, setUserModele] = useState(null);

  const [sortedFeed, setSortedFeed] = useState([]);
  const [sortedPhotos, setSortedPhotos] = useState([]);
  const [sortedVideos, setSortedVideos] = useState([]);

  const [suggestionList, setSuggestionList] = useState<Array<any>>([]);

  const [showShare, setShowShare] = useState<boolean>(false);
  const [showMediaView, setShowMediaView] = useState<any>(null);
  const [showModalService, setShowModalService] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function getEventByParams() {
    try {
      setLoading(true);

      const response = await GetModeleDetails(modeleId);

      if (!response?.data) {
        setUserModele(null);

        if (!genderChoose) {
          dispatch({ type: USER_GENDER_CHOOSE_ACTION, payload: EGenderUser.WOMAN });
        }

        return;
      }

      const modeleReceived = response?.data?.modele;
      setUserModele(modeleReceived);

      const recSortedFeed = [
        ...modeleReceived?.pictureGalleryImages,
        ...modeleReceived?.pictureGalleryVideos,
      ]?.slice().sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB.getTime() - dateA.getTime();
      });
      setSortedFeed(recSortedFeed);

      const recSortedPhotos = modeleReceived?.pictureGalleryImages?.slice().sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB.getTime() - dateA.getTime();
      });
      setSortedPhotos(recSortedPhotos);

      const recSortedVideos = modeleReceived?.pictureGalleryVideos?.slice().sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB.getTime() - dateA.getTime();
      });
      setSortedVideos(recSortedVideos);

      setSuggestionList(response?.data?.suggestions);
      dispatch({ type: MODELES_RENT_SERVICES, payload: response?.data?.configs?.rentServices });
      dispatch({ type: USER_GENDER_CHOOSE_ACTION, payload: response?.data?.modele?.gender });
    }
    catch (error: any) {
      console.error(error);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
    }
  }


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.white}
      />

    );
  }


  function onPressListEmpty() {
    if (loggedIn && user?.id) {
      history(NameRoutes.AllModeleScreen, { replace: true });
      return;
    }

    if (projectParams?.showRent) {
      history(NameRoutes.RentHomeScreen, { replace: true });
      return;
    }

    if (projectParams?.showLive) {
      history(NameRoutes.LiveHomeScreen, { replace: true });
      return;
    }

    history(NameRoutes.HomeScreen, { replace: true });
  }


  function listModelEmptyComponent() {
    return (

      // TODO: Colocar os tipos disponiveis, Rent e Live
      <EmptyContent
        image={Images.noMmodeleFound}
        title={translate(TranslateConfig.EMPTY_MODELES_FOUND)}
        buttonText={translate(TranslateConfig.ACTION_SEE_OTHERS_MODELES)}
        buttonColor={accentGender}
        onPress={() => {
          onPressListEmpty();
        }}
      />

    );
  }


  function listUnavailableEmptyComponent() {
    return (

      // TODO: Colocar os tipos disponiveis, Rent e Live
      <EmptyContent
        image={Images.noModeleAvailable}
        title={translate(TranslateConfig.EMPTY_MODELES_UNAVAILABLE)}
        description={translate(TranslateConfig.EMPTY_MODELES_UNAVAILABLE_TEXT)}
        buttonText={translate(TranslateConfig.ACTION_SEE_OTHERS_MODELES)}
        buttonColor={accentGender}
        onPress={() => {
          onPressListEmpty();
        }}
      />

    );
  }


  function returnArrayChange(): Array<any> {
    if (showMediaView?.type === 'FEED') {
      return sortedFeed;
    }

    if (showMediaView?.type === 'PHOTOS') {
      return sortedPhotos;
    }

    if (showMediaView?.type === 'VIDEOS') {
      return sortedVideos;
    }

    return [];
  }



  const screenTabs = [
    {
      visible: true, // HOME should always true
      name: 'HOME',
      title: translate(TranslateConfig.HOME),
      icon: (
        <IconDashboard
          color={stateIndex?.key === 'HOME' ? accentGender : Colors.inputCount}
          size={iconTabBar}
        />
      ),
      component: (
        <TabFeedScreen
          showActions={hideActions}
          modele={userModele}
          dataMedia={sortedFeed}
          suggestions={suggestionList}
          onShowMedia={(mediaObject: IViewMediaModal) => {
            setShowMediaView(mediaObject);
          }}
          showTabPhotos={() => {
            setStateIndex({
              index: 2,
              key: 'PHOTOS',
            });
          }}
          showTabVideos={() => {
            setStateIndex({
              index: 3,
              key: 'VIDEOS',
            });
          }}
        />
      ),
    },
    {
      visible: true, // CHARACTERS should always true
      name: 'CHARACTERS',
      title: translate(TranslateConfig.CHARACTERISTICS),
      icon: (
        <IconCharacteristics
          color={stateIndex?.key === 'CHARACTERS' ? accentGender : Colors.inputCount}
          size={iconTabBar}
        />
      ),
      component: (
        <TabCharacteristicsScreen
          showActions={hideActions}
          modele={userModele}
          suggestions={suggestionList}
        />
      ),
    },
    {
      visible: true, // PHOTOS should always true
      name: 'PHOTOS',
      title: translate(TranslateConfig.PICTURES),
      icon: (
        <IconMediaPhoto
          color={stateIndex?.key === 'PHOTOS' ? accentGender : Colors.inputCount}
          size={iconTabBar}
        />
      ),
      component: (
        <TabPhotosScreen
          showActions={hideActions}
          modele={userModele}
          dataMedia={sortedPhotos}
          suggestions={suggestionList}
          onShowMedia={(mediaObject: IViewMediaModal) => {
            setShowMediaView(mediaObject);
          }}
        />
      ),
    },
    {
      visible: true, // VIDEOS should always true
      name: 'VIDEOS',
      title: translate(TranslateConfig.VIDEOS),
      icon: (
        <IconMediaVideo
          color={stateIndex?.key === 'VIDEOS' ? accentGender : Colors.inputCount}
          size={iconTabBar}
        />
      ),
      component: (
        <TabVideosScreen
          showActions={hideActions}
          modele={userModele}
          dataMedia={sortedVideos}
          suggestions={suggestionList}
          onShowMedia={(mediaObject: IViewMediaModal) => {
            setShowMediaView(mediaObject);
          }}
        />
      ),
    },
    {
      visible: projectParams?.showRent && userModele?.params?.rent,
      name: 'RENT',
      title: translate(TranslateConfig.ESCORT),
      icon: (
        <IconRentOn
          color={stateIndex?.key === 'RENT' ? accentGender : Colors.inputCount}
          size={iconTabBar}
        />
      ),
      component: (
        <TabRentScreen
          showActions={hideActions}
          modele={userModele}
          suggestions={suggestionList}
          onServiceModalPress={() => {
            setShowModalService(true);
          }}
        />
      ),
    },
    {
      visible: projectParams?.showLive && userModele?.params?.live,
      name: 'SHOW',
      title: translate(TranslateConfig.LIVE),
      icon: (
        <IconRoomOpen
          color={stateIndex?.key === 'SHOW' ? accentGender : Colors.inputCount}
          size={iconTabBar}
        />
      ),
      component: (
        <TabLiveScreen
          showActions={hideActions}
          modele={userModele}
          suggestions={suggestionList}
        />
      ),
    },
    {
      visible: projectParams?.canAccess,
      name: 'AVALIATIONS',
      title: translate(TranslateConfig.COMMENTS),
      icon: (
        <IconAvaliations
          color={stateIndex?.key === 'AVALIATIONS' ? accentGender : Colors.inputCount}
          size={iconTabBar}
        />
      ),
      component: (
        <TabCommentsScreen
          showActions={hideActions}
          modele={userModele}
          suggestions={suggestionList}
        />
      ),
    },
  ];



  function renderDetailsFollowTitle(): string {
    const numberToCount = userModele?.totalFollow;

    return `${numberToCount} ${translate(TranslateConfig.FOLLOWERS)}`;
  }


  function renderDetailsPhotoTitle(): string {
    const numberToCount = sortedPhotos?.length;

    return `${numberToCount} ${translate(TranslateConfig.PICTURES)}`;
  }


  function renderDetailsVideoTitle(): string {
    const numberToCount = sortedVideos?.length;

    return `${numberToCount} ${translate(TranslateConfig.VIDEOS)}`;
  }


  function renderDetailsCommentTitle(): string {
    const numberToCount = userModele?.appReviewsReceived?.length;

    return `${numberToCount} ${translate(TranslateConfig.COMMENTS)}`;
  }


  function renderTitleDetailsHelmet(): string {
    let titleRender = `(@${modeleId})`;

    if (userModele?.alias) {
      titleRender = `${userModele?.alias} (@${modeleId})`;
    }

    return `${titleRender} | ${translate(TranslateConfig.NAME_APP)}`;
  }


  function renderDescriptionDetailsHelmet(): string {
    let descriptionRender = `Secretio.app • ${Seo.descriptionGlobal}`;

    if (userModele?.alias) {
      descriptionRender = `${userModele?.alias} (@${modeleId}): ${renderDetailsFollowTitle()}, ${renderDetailsPhotoTitle()}, ${renderDetailsVideoTitle()}, ${renderDetailsCommentTitle()} - Secretio.app • ${Seo.descriptionGlobal}`;
    }

    return descriptionRender;
  }

  function renderImageDetailsHelmet(): string {
    return validateString(userModele?.pictureCover) || Images.bannerAppLink;
  }



  useEffect(() => {
    getEventByParams();
  }, []);


  const urlJsonLd = `${Vars(EEnvsName.PROD).secretioApp}/${modeleId}`;

  const jsonLd = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    '@id': urlJsonLd,
    'url': urlJsonLd,
    'name': renderTitleDetailsHelmet(),
    'image': renderImageDetailsHelmet(),
    'description': renderDescriptionDetailsHelmet(),
    'telephone': `${Keys.whatsAppNumber}`,
    'isPartOf': {
      '@type': 'WebSite',
      'url': Vars(EEnvsName.PROD).secretioApp,
      'name': translate(TranslateConfig.NAME_APP),
    },
    'about': {
      '@type': 'Person',
      'name': userModele?.alias,
      'url': urlJsonLd,
      'image': renderImageDetailsHelmet(),
    },
    'breadcrumb': {
      '@type': 'BreadcrumbList',
      'itemListElement': [
        {
          '@type': 'ListItem',
          'position': 1,
          'name': translate(TranslateConfig.NAME_APP),
          'item': Vars(EEnvsName.PROD).secretioApp,
        },
        {
          '@type': 'ListItem',
          'position': 2,
          'name': modeleId,
          'item': urlJsonLd,
        },
      ],
    },
    'sameAs': [
      `https://www.facebook.com/${modeleId}`,
      `https://twitter.com/${modeleId}`,
      `https://www.linkedin.com/company/${modeleId}`,
    ],
  };



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet prioritizeSeoTags>
        <title>
          {renderTitleDetailsHelmet()}
        </title>

        <meta name="robots" content="index, follow" />
        <meta name="description" content={renderDescriptionDetailsHelmet()} />

        <meta name="keywords" content={Seo.keywords} />

        <meta property="og:site_name" content={translate(TranslateConfig.NAME_APP)} />
        <meta property="og:type" content="profile" />
        <meta property="og:image" content={userModele?.picture} />
        <meta property="og:url" content={urlJsonLd} />
        <meta property="og:title" content={renderTitleDetailsHelmet()} />
        <meta property="og:description" content={renderDescriptionDetailsHelmet()} />

        <link rel="canonical" href={urlJsonLd} />

        <link rel="alternate" href={urlJsonLd} hrefLang="x-default" />
        <link rel="alternate" href={`${urlJsonLd}?hl=en`} hrefLang="en" />

        <script type="application/ld+json">
          {JSON.stringify(jsonLd)}
        </script>
      </Helmet>



      <Container>

        <HeaderScreen
          disableGender
          showBalance
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={
            <ButtonBack
              color={Colors.white}
              size={27}
            />
          }
          centerContent={
            <Body>
              <Title>
                {translate(TranslateConfig.DETAILS)}
              </Title>

              <SubTitle>
                @{modeleId}
              </SubTitle>
            </Body>
          }
        />



        <Content>

          {loading && (
            renderLoading()
          )}



          {!loading && !userModele && (
            listModelEmptyComponent()
          )}



          {!loading && userModele && !userModele?.visible && (
            listUnavailableEmptyComponent()
          )}



          {!loading && userModele && userModele?.visible && (
            <DetailsContainer>

              <ModeleDetailHeader
                showActions={hideActions}
                modele={userModele}
                openShareModal={() => {
                  setShowShare(true);
                }}
              />



              <TabBarStyled
                tabs={screenTabs}
                selected={stateIndex?.index}
                backgroundColor={Colors.tabBackground}
                onPress={(tab) => {
                  setStateIndex(tab);
                }}
              />



              <CharRowWrapperCenterView>
                <CharRowWrapperAuto>

                  {stateIndex?.key === 'HOME' && screenTabs.find((tab) => tab?.name === stateIndex?.key).component}

                  {stateIndex?.key === 'CHARACTERS' && screenTabs.find((tab) => tab?.name === stateIndex?.key).component}

                  {stateIndex?.key === 'PHOTOS' && screenTabs.find((tab) => tab?.name === stateIndex?.key).component}

                  {stateIndex?.key === 'VIDEOS' && screenTabs.find((tab) => tab?.name === stateIndex?.key).component}

                  {stateIndex?.key === 'RENT' && screenTabs.find((tab) => tab?.name === stateIndex?.key).component}

                  {stateIndex?.key === 'SHOW' && screenTabs.find((tab) => tab?.name === stateIndex?.key).component}

                  {stateIndex?.key === 'AVALIATIONS' && screenTabs.find((tab) => tab?.name === stateIndex?.key).component}

                </CharRowWrapperAuto>
              </CharRowWrapperCenterView>

            </DetailsContainer>
          )}



          <FooterApp />

        </Content>

      </Container>



      <ViewMediaModal
        visible={Boolean(showMediaView)}
        indexSelected={showMediaView?.indexSelected}
        data={returnArrayChange()}
        onClose={() => {
          setShowMediaView(null);
        }}
      />



      <ShareModal
        type={'MODELE'}
        visible={showShare}
        name={userModele?.alias}
        idToShare={userModele?.useralias}
        image={userModele?.pictureCover}
        onClose={() => {
          setShowShare(false);
        }}
      />



      <TravelScheduleModal
        visible={travelsModal}
        onClose={() => {
          dispatch({ type: USER_TRAVELS_MODAL, payload: null });
        }}
      />



      <FaClubModal
        show={showFaClub}
        onClose={() => {
          dispatch({ type: USER_FACLUB_MODAL, payload: false });
        }}
      />



      <ModeleServicesModal
        visible={showModalService}
        onClose={() => {
          setShowModalService(false);
        }}
      />

    </Screen>

  );
};



export default ModeleDetailsScreen;
