import styled from 'styled-components';

import {
  Colors,
} from '../../../../shared/constants';



export const RentManOfTheDayContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const RentManOfTheDayPreview = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  height: 450px;
  width: 70%;
  margin-right: 20px;
  border-radius: 10px;
  border-color: ${Colors.white};
  border-style: solid;
  border-width: 1px;
`;

export const RentManOfTheDayImageExample = styled.img`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right 2.5rem;
`;



export const RentManOfTheDayInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 29%;
`;

export const RentManDayHeader = styled.div`
  display: flex;
  flex-direction: row;
`;

export const RentManDayTitle = styled.p`
  width: 70%;
  color: ${Colors.white};
  font-weight: 700;
  text-transform: uppercase;
  font-size: 28px;
`;

export const RentManDayrulContees = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const RentManDayrules = styled.p`
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
  color: ${Colors.white};
  font-size: 16px;
  line-height: 22px;
`;


export const FakeUploadVerifiedView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FakeUploadVerifiedIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

export const RentManDayApartirrules = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${Colors.white};
`;

export const RentManDaysize = styled.div`
  margin-top: 5px;
  color: ${Colors.white};
  font-size: 32px;
`;

export const RentMandescription = styled.p`
  margin-top: 3px;
  margin-bottom: 10px;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
  font-size: 14px;
`;
