import styled from 'styled-components';



export const TipÁmountContainer = styled.div`
  position: relative;
  display: flex;
  align-self: flex-start;
  flex-direction: row;
  margin: 10px 0;
`;

export const TipImage = styled.img`
  position: absolute;
  top: -5px;
  display: flex;
  width: 60px;
  height: 60px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #383838;
  border: 2px solid #444;
  object-fit: cover;
  z-index: 2;
`;

export const TipMessageView = styled.div`
  display: flex;
  align-items: center;
  min-height: 50px;
  margin-left: 20px;
  padding: 10px 15px 10px 50px;
  background-color: rgba(104, 0, 104, 0.7);
  border-radius: 5px;
  z-index: 1;
`;

export const TipMessageText = styled.p`
  color: white;
  font-size: 17px;
`;
