import styled from 'styled-components';

import Color from 'color';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  BadgeSkewX,
} from '../../Badges/BadgeVt';



export const CardHomeProfileContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 115px;
  margin: 6px 0;
  border-radius: ${Sizes.cardRadius}px;
  background-color: ${Colors.cardBackground};
`;



export const CardProfileButton = styled.a`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
`;



export const HomeProfileUserBackground = styled.a`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const HomeProfileBackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 0;
  object-fit: cover;
`;



export const HomeProfileLabelItem = styled(BadgeSkewX)`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 3;
`;



export const HomeProfileInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: ${Colors.menuOverlay};
  padding-left: 10px;
  padding-right: 10px;
  z-index: 2;
`;

export const HomeProfileAvatarContainer = styled.a`
  margin-top: -35px;
  margin-left: 5px;
  margin-right: 15px;
  margin-bottom: 12px;
  height: 90px;
`;

export const HomeProfileContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;


export const HomeProfileStatusView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 5px;
`;

export const HomeProfileInfoContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const HomeProfileUsername = styled.p`
  margin: 2px 0 0 0;
  color: ${Colors.white};
  font-size: 13px;
`;

export const HomeProfileUserIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-left: 8px;
`;


export const BadgeOnline = styled.p`
  padding: 4px 12px 2px;
  background-color: ${Color(Colors.accept).darken(0.3).toString()};
  color: ${Colors.white};
  font-size: 10px;
  border-radius: 50px;
`;


export const HomeProfileNameText = styled.p`
  margin: 0;
  color: ${Colors.white};
  font-size: 17px;
`;
