import styled from 'styled-components';

import {
  ContentPadder,
} from '../../../../../components/Contents/ContentPadder';

import {
  Colors,
  Sizes,
} from '../../../../../shared/constants';

import {
  PlatformDevices,
} from '../../../../../shared/utils/platforms.utils';



export const FeedContentCenterRowFlex = styled(ContentPadder)`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  max-width: ${Sizes.contentMaxWidth}px;

  @media ${PlatformDevices.maxTabletM} {
    flex-direction: column;
  }
`;

export const ContentLeft = styled.div`
  display: flex;
  flex-direction: column;

  @media ${PlatformDevices.maxTabletM} {
    margin-bottom: ${Sizes.contentMargin}px;
    width: 100%;
  }

  @media ${PlatformDevices.minTabletM} {
    margin-right: ${Sizes.contentMargin}px;
    width: ${Sizes.contentLeftWidth}px;
  }
`;

export const WrapperDetailItemContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const PhoneContentView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
`;



export const FeedHoursContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
`;

export const FeedHoursImage = styled.img`
  width: 45px;
  height: 45px;
  margin-right: 10px;
  object-fit: contain;
`;

export const FeedHoursText = styled.p`
  color: ${Colors.white};
  font-size: 17px;
`;



export const TabFeedContentFlex = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
`;



export const FeedGridMediaButtonsView = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;


export const FeedGridMediaButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 35px;
  padding-left: 12px;
  padding-right: 12px;
  margin: 4px 2px;
  background-color: ${Colors.inputBackground};
  border-radius: 16px;
`;

export const FeedGridMediaIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 9px;
`;

export const FeedGridMediaText = styled.p`
  font-size: 13px;
  color: ${Colors.white};
  white-space: nowrap;
`;
