import styled from 'styled-components';
import { Images } from '../../../../../shared/constants';



export const FotyContainerButton = styled.a`
line-height: 1.4em;
-webkit-text-size-adjust: 100%;
-webkit-font-smoothing: antialiased;
box-sizing: border-box;
font-family: 'Open Sans', sans-serif !important;
position: relative;
width: 210px;
height: 60px;
display: flex;
justify-content: center;
align-items: center;
background-image: url(${Images.foty_btn});
background-repeat: no-repeat;
background-color: transparent;
text-align: center;
text-transform: uppercase;
text-shadow: 0 1px 0 #FFF;
font-size: 16px;
font-weight: 700;
color: #002E41 !important;
padding-bottom: 5px;
transition: all 0.3s ease-in-out;
z-index: 10;
border: none;
text-decoration: none !important;
`;
