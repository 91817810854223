import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import CardLocationCountry from '../../../components/Cards/CardLocation/LocationCountry';
import EmptyContent from '../../../components/Empty';

import {
  countriesOption,
} from '../../../shared/arrays/App/countries.array';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import {
  stringRawDataText,
} from '../../../shared/utils/string.utils';

import {
  LocationCountryContent,
} from './styled';



export interface IProps {
  data?: Array<any>;
  onCountryPress?: any;
  countrySelected?: string;
}



const LocationCountriesScreen: React.FC<IProps> = (props: IProps) => {
  const arrayCountries = props.data;

  const { t: translate } = useTranslation();


  function renderCard(item: any, index: number) {
    const filterLangCountry = countriesOption.find((country) => stringRawDataText(country?.code) === stringRawDataText(item?.title));


    return (

      <CardLocationCountry
        key={index}
        selected={props.countrySelected === item?.title}
        title={translate(countriesOption.find((country) => country?.code === filterLangCountry?.code)?.label) || '-'}
        code={filterLangCountry?.code}
        currency={filterLangCountry?.moneyFull}
        image={filterLangCountry?.image}
        onPress={() => {
          props.onCountryPress(item?.title);
        }}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noLocationCountry}
        title={'Não há itens ainda!'}
      />

    );
  }



  return (

    <LocationCountryContent
      backgroundColor={Colors.appBackground}>

      {(!arrayCountries || arrayCountries?.length === 0) && (
        listEmptyComponent()
      )}

      {arrayCountries && arrayCountries?.length > 0 && arrayCountries?.map((item, index) => (
        renderCard(item, index)
      ))}

    </LocationCountryContent>

  );
};



export default LocationCountriesScreen;
