import styled from 'styled-components';



export const CardProfileOnlineContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 210px;
  margin: 6px 0;
  border-radius: 6px;
  background-color: #363636;
`;



export const ProfileOnlineButton = styled.a`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 120px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
`;


export const ProfileOnlineUserOnlineImage = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  margin-right: 15px;
  background-color: #fefefe;
  border: 4px solid #35d063;
  border-radius: 50%;
  z-index: 2;
`;

export const ProfileOnlineUserImage = styled.img`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-fit: cover;
`;



export const ProfileOnlineContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
`;



export const ProfileOnlineInfoViewContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 3px;
`;

export const ProfileOnlineInfoContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;


export const ProfileOnlineUsernameText = styled.p`
  margin: 0;
  color: #fefefe;
  font-size: 14px;
  line-height: 18px;
`;

export const ProfileOnlineUserIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-left: 8px;
`;

export const ProfileOnlineNameText = styled.p`
  margin: 2px 0 0 0;
  color: #fefefe;
  font-size: 19px;
  line-height: 24px;
`;



export const ProfileOnlineItemControls = styled.div`
  display: flex;
  flex-direction: row;
  height: 30px;
  z-index: 2;
`;
