import React from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useNavigate,
} from 'react-router-dom';

import {
  Content,
  Screen,
  Title,
} from '../../../components/Composh/web';

import {
  ContentPadderHorizontal,
} from '../../../components/Contents/ContentPadder';

import {
  ButtonBack,
} from '../../../components/Controls';

import HeaderScreen from '../../../components/Headers/HeaderScreen';

import {
  IconTermUse,
  IconTermLicences,
  IconTermPrivacity,
} from '../../../components/Icons';

import ItemsList from '../../../components/Items/ItemsList';

import NameRoutes from '../../../navigation/names';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import EDrawerMenu from '../../../shared/enums/App/drawer-menu.enum';
import Vars from '../../../shared/environments/variables';

import {
  TranslateConfig,
} from '../../../shared/translations';

import {
  DetailsSectionView,
} from './styled';



const TermsPolicyScreen: React.FC = () => {
  const history = useNavigate();
  const { t: translate } = useTranslation();



  function renderItemList(
    icon: any,
    title: string,
    onPress: any,
  ) {
    return (

      <ItemsList
        onPress={onPress}
        iconLeft={icon}
        title={title}
        titleColor={Colors.white}
        iconRight={null}
        border={false}
      />

    );
  }



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet prioritizeSeoTags>
        <title>
          {`${translate(TranslateConfig.TERMS_POLICY)} | ${translate(TranslateConfig.NAME_APP)}`}
        </title>

        <meta name="robots" content="noindex, nofollow" />
      </Helmet>



      <HeaderScreen
        screenKey={EDrawerMenu.SETTINGS}
        disableGender
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            color={Colors.white}
          />
        }
        centerContent={
          <Title>
            {translate(TranslateConfig.TERMS_POLICY)}
          </Title>
        }
      />



      <Content>
        <ContentPadderHorizontal>

          <DetailsSectionView>

            {renderItemList(
              <IconTermLicences
                size={Sizes.iconAndroidSize}
                color={Colors.white}
              />,
              translate(TranslateConfig.LICENCES),
              () => {
                history(
                  NameRoutes.WebViewScreen,
                  {
                    state: {
                      title: translate(TranslateConfig.LICENCES),
                      link: Vars().licencesLink,
                    },
                  },
                );
              },
            )}


            {renderItemList(
              <IconTermUse
                size={Sizes.iconAndroidSize}
                color={Colors.white}
              />,
              translate(TranslateConfig.ACCESS_USER_TERMS_USE),
              () => {
                history(
                  NameRoutes.WebViewScreen,
                  {
                    state: {
                      title: translate(TranslateConfig.ACCESS_USER_TERMS_USE),
                      link: Vars().termsUseLink,
                    },
                  },
                );
              },
            )}


            {renderItemList(
              <IconTermPrivacity
                size={Sizes.iconAndroidSize}
                color={Colors.white}
              />,
              translate(TranslateConfig.ACCESS_USER_TERMS_PRIVACY),
              () => {
                history(
                  NameRoutes.WebViewScreen,
                  {
                    state: {
                      title: translate(TranslateConfig.ACCESS_USER_TERMS_PRIVACY),
                      link: Vars().privacityPolicyLink,
                    },
                  },
                );
              },
            )}



            {/* TODO: DMCA */}

          </DetailsSectionView>

        </ContentPadderHorizontal>
      </Content>

    </Screen>

  );
};



export default TermsPolicyScreen;
