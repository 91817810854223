export enum EStatusProfile {
  IN_CONSTRUCTION = 'IN_CONSTRUCTION',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  HIDE_BY_USER = 'HIDE_BY_USER',
  DISABLED = 'DISABLED',
  BANNED = 'BANNED',
}



export default EStatusProfile;
