import React,
{
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
} from 'react-redux';

import {
  Overlay,
} from '../../components/Composh/web';

import CopiedItem from '../../components/Contents/CopiedItem';
import HeaderModal from '../../components/Headers/HeaderModal';

import {
  IconCopy,
  IconEmail,
  IconSocialTwitter,
  IconSocialWhatsApp,
} from '../../components/Icons';

import {
  openEmail,
  openWebApp,
} from '../../config/linking.config';

import NameRoutes from '../../navigation/names';

import {
  APP_SNACKBAR_ACTION,
} from '../../redux/reducers/app-modals.store';

import {
  Colors,
  Images,
} from '../../shared/constants';

import Vars from '../../shared/environments/variables';

import {
  TranslateConfig,
} from '../../shared/translations';

import {
  ShareEventContainer,
  ShareEventBannerView,
  ShareEventBannerImage,
  ShareEventContent,
  ShareEventItemButton,
  ShareEventItemIcon,
  ShareEventItemText,
} from './styled';



export interface IProps {
  type: 'MODELE';
  visible?: boolean;
  name?: string;
  idToShare: string;
  image?: string;
  onClose?: any;
  onPress?: any;
}



const ShareModal: React.FC<IProps> = (props: IProps) => {
  const visibleModal = props.visible;
  if (!visibleModal) {
    return null;
  }


  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const sizeIcon = 18;

  const imageBanner = props.image || Images.bannerDetails;


  const [copied, setCopied] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function renderShareButton(icon: any, title: string, onPress: any) {
    return (

      <ShareEventItemButton
        onClick={() => {
          if (onPress) {
            onPress();
          }
        }}>

        {icon}


        <ShareEventItemText>
          {title}
        </ShareEventItemText>

      </ShareEventItemButton>

    );
  }


  function controlLinkText(): { title: string, link: string } | null {
    if (props.type === 'MODELE') {
      return {
        title: 'Veja esse modelo no Secretio',
        link: `${Vars()?.secretioApp}${NameRoutes.ModeleDetailsScreen.replace(
          ':id',
          props.idToShare,
        )}`,
      };
    }

    return null;
  }


  function onCopyLinkPress() {
    setCopied(true);
    navigator.clipboard.writeText(controlLinkText()?.link || '');
    showSnackBarProps(Colors.accept, translate(TranslateConfig.SUCCESS_COPIED_LINK));

    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }


  function onWhatsAppPress() {
    const textEncoded = `${controlLinkText()?.title}: ${controlLinkText()?.link}`;
    const shareURL = `https://wa.me/?text=${textEncoded}`;
    openWebApp(shareURL);

    if (props.onClose) {
      props.onClose();
    }
  }


  function onTwittterPress() {
    const textEncoded = encodeURIComponent(`${controlLinkText()?.title}: ${controlLinkText()?.link}`);

    const messageURL = `https://twitter.com/intent/tweet?text=${textEncoded}`;
    openWebApp(messageURL);

    if (props.onClose) {
      props.onClose();
    }
  }


  function onEmailPress() {
    const messageURL = controlLinkText()?.link || '';
    openEmail(controlLinkText()?.title || '', messageURL);

    if (props.onClose) {
      props.onClose();
    }
  }



  return (

    <Overlay
      visible={props.visible}
      showBackground={true}
      overlayColor={Colors.menuOverlay}
      onOverlayPress={props.onClose}>

      <ShareEventContainer>

        <HeaderModal
          title={translate(TranslateConfig.SHARE)}
          subtitle={props.name}
          onPress={props.onClose}
        />


        <ShareEventBannerView>

          <ShareEventBannerImage
            src={imageBanner}
          />

        </ShareEventBannerView>


        <ShareEventContent>

          {renderShareButton(
            <CopiedItem
              copied={copied}
              icon={
                <IconCopy
                  color={Colors.white}
                  size={sizeIcon}
                />
              }
            />,
            translate(TranslateConfig.ACTION_COPY_LINK),
            () => {
              onCopyLinkPress();
            },
          )}


          {renderShareButton(
            <ShareEventItemIcon>
              <IconSocialWhatsApp
                color={Colors.white}
                size={sizeIcon}
              />
            </ShareEventItemIcon>,
            translate(TranslateConfig.WHATSAPP),
            () => {
              onWhatsAppPress();
            },
          )}


          {renderShareButton(
            <ShareEventItemIcon>
              <IconSocialTwitter
                color={Colors.white}
                size={sizeIcon}
              />
            </ShareEventItemIcon>,
            translate(TranslateConfig.TWITTER),
            () => {
              onTwittterPress();
            },
          )}


          {renderShareButton(
            <ShareEventItemIcon>
              <IconEmail
                color={Colors.white}
                size={sizeIcon}
              />
            </ShareEventItemIcon>,
            translate(TranslateConfig.EMAIL),
            () => {
              onEmailPress();
            },
          )}

        </ShareEventContent>

      </ShareEventContainer>

    </Overlay>

  );
};



export default ShareModal;
