import styled from 'styled-components';
import { Colors, Sizes } from '../../../../../shared/constants';



export const CategoryChooseContainer = styled.div`
    max-width: 500px;
    min-width: 320px;
    padding: 16px 20px 12px;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background-color: ${Colors.appBackground};
  border-radius: ${Sizes.cardRadius}px;
`;


export const TravelInfoTitle = styled.h3`
  margin-bottom: 10px;
  color: ${Colors.white};
  font-size: 18px;
`;


export const TravelInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const TravelInfoTextView = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const TravelInfoText = styled.p`
  position: relative;
  margin-bottom: 11px;
  color: ${Colors.white};
  padding-left: 15px;
  font-size: 15px;
  line-height: 20px;
  
  :before{
    content: '';
    position: absolute;
    top: 8px;
    left: -2px;
    width: 5px;
    height: 5px;
    background-color: #ff0000;
    border-radius: 9999px;
  }
`;
