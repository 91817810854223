export const APP_ACCOUNT = {
  globalAccountRegister:            'https://conta-dev.plugante.com/register-user',
  globalAccountForgotPassword:      'https://conta-dev.plugante.com/conta/password/forgot',
  globalAccountUser:                'https://conta-dev.plugante.com/conta',
  globalAccountAddress:             'https://conta-dev.plugante.com/conta/address',
  globalAccountProfile:             'https://conta-dev.plugante.com/conta/profiles/public/details',
  globalAccountCreditCard:          'https://conta-dev.plugante.com/conta/payments/cards',
};


export const LINKS = {
  secretioRankingApp:               'http://localhost:6969/ranking',
};


export const SOCIAL = {
  secretioApp:                      'http://localhost:6969',
  secretioStreamApp:                'http://localhost:6971',
  secretioSite:                     'http://localhost:6973',

  secretioAbout:                    'http://localhost:6973/about',
  secretioContact:                  'http://localhost:6973/contact',
};


export const TERMS = {
  licencesLink:                     'http://localhost:6973/terms-policy/secretio-license',
  privacityPolicyLink:              'http://localhost:6973/terms-policy/secretio-privacity',
  termCookiesLink:                  'http://localhost:6973/terms-policy/secretio-cookies',
  termsUseLink:                     'http://localhost:6973/terms-policy/secretio-use',
};



const Vars = {
  ...APP_ACCOUNT,
  ...LINKS,
  ...SOCIAL,
  ...TERMS,
};



export default Vars;
