import SecretioWS from '../index';



export const GetHomeFeed = async () => {
  const url = '/app/home';

  const response = await SecretioWS.get(url);
  return response.data;
};
