import styled from 'styled-components';

import {
  Input,
  Radio,
} from '../../../../../components/Composh/web';

import {
  ContentPadder,
} from '../../../../../components/Contents/ContentPadder';

import InputCheckbox from '../../../../../components/Inputs/InputCheckbox';
import InputText from '../../../../../components/Inputs/InputText';

import {
  Colors,
  Sizes,
} from '../../../../../shared/constants';



export const MediaChooseContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 550px;
  max-width: 550px;
  min-height: 560px;
  max-height: 560px;
  overflow: hidden;
  position: relative;
  background-color: ${Colors.appBackground};
  border-radius: ${Sizes.cardRadius}px;
`;

export const GCPictureContainer = styled(ContentPadder)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  width: 100%;
  padding-bottom: 5px;
`;

export const GCPictureTitle = styled.h3`
  margin-bottom: 20px;
  color: ${Colors.white};
  font-size: 22px;
  font-weight: 600;
  word-break: break-word;
  word-wrap: break-word;
  text-align: center;
`;


export const GCPictureImageView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const GCPictureOptionsView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1%;
  min-height: 250px;
  margin-left: 15px;
`;


export const InputViewStyled = styled(Input.View)`
  margin-top: 0;
  margin-bottom: 0;
`;

export const GenderUserRadio = styled(Radio)`
`;

export const InputButtonCheckboxStyled = styled(InputCheckbox)`
  margin-top: 5px;
  margin-bottom: 15px;
`;



export const InputTextStyled = styled(InputText)`
`;
